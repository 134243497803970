import axios from 'axios';
import { TournamentPrize } from '../models/tournament-prize';
import flatten from 'flat';

export default {
    namespaced: true,

    state: {
        tournamentPrizes: [],
        loadingTournamentPrizes: false,
        loadingError: null,
        savingTournamentPrize: false,
        savingError: null,
        deletingTournamentPrize: false,
        deletingError: null,
        options: {
            queryString: null,
            sortBy: 'prizeId',
            sortDir: 'ascending',
            page: 0,
            pageSize: 20,
        },
    },

    getters: {
        tournamentPrizes: (state) => state.tournamentPrizes,
        loadingTournamentPrizes: (state) => state.loadingTournamentPrizes,
        loadingError: (state) => state.loadingError,
        savingTournamentPrize: (state) => state.savingTournamentPrize,
        savingError: (state) => state.savingError,
        deletingTournamentPrize: (state) => state.deletingTournamentPrize,
        deletingError: (state) => state.deletingError,
        options: (state) => state.options,
    },

    mutations: {
        setTournamentPrizes(state, data) {
            state.tournamentPrizes = data;
        },

        startLoadingTournamentPrizes(state) {
            state.loadingTournamentPrizes = true;
        },

        stopLoadingTournamentPrizes(state) {
            state.loadingTournamentPrizes = false;
        },

        setLoadingError(state, error) {
            state.loadingError = error;
        },

        clearLoadingError(state) {
            state.loadingError = null;
        },

        startSavingTournamentPrize(state) {
            state.savingTournamentPrize = true;
        },

        stopSavingTournamentPrize(state) {
            state.savingTournamentPrize = false;
        },

        setSavingError(state, error) {
            state.savingError = error;
        },

        clearSavingError(state) {
            state.savingError = null;
        },

        startDeletingTournamentPrize(state) {
            state.deletingTournamentPrize = true;
        },

        stopDeletingTournamentPrize(state) {
            state.deletingTournamentPrize = false;
        },

        setDeletingError(state, error) {
            state.deletingError = error;
        },

        clearDeletingError(state) {
            state.deletingError = null;
        },
    },

    actions: {
        async findTournamentPrizes(context, options = {}) {
            context.commit('startLoadingTournamentPrizes');
            try {
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/TournamentPrize`, {
                    params: {
                        queryString: options.queryString || '',
                        sortBy: options.sortBy || 'Id',
                        sortDir: options.sortDir === 'descending' ? -1 : 1,
                        pageSize: options.pageSize || 50,
                        page: options.page,
                    },
                });
                context.commit('stopLoadingTournamentPrizes');
                context.commit('setTournamentPrizes', result.data);
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('stopLoadingTournamentPrizes');
                context.commit('setLoadingError', error);
                return Promise.reject(error);
            }
        },

        async findTournamentPrizesForTournament(context, options = {}) {
            let year = options.year;
            let tournamentId = options.id;
            context.commit('startLoadingTournamentPrizes');
            try {
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/TournamentPrize/Edition/${year}/${tournamentId}`);
                context.commit('setTournamentPrizes', result.data);
                context.commit('stopLoadingTournamentPrizes');
                return Promise.resolve();
            } catch (error) {
                context.commit('stopLoadingTournamentPrizes');
                context.commit('setLoadingError', error);
                return Promise.reject(error);
            }
        },

        async createTournamentPrize(context, tournamentPrize) {
            context.commit('startSavingTournamentPrize');
            try {
                const result = await axios.post(`${process.env.VUE_APP_API_URL}/TournamentPrize`, tournamentPrize.data);
                context.commit('stopSavingTournamentPrize');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('stopSavingTournamentPrize');
                context.commit('setSavingError', error);
                return Promise.reject(error);
            }
        },

        async updateTournamentPrize(context, tournamentPrize) {
            context.commit('startSavingTournamentPrize');
            try {
                const result = await axios.put(`${process.env.VUE_APP_API_URL}/TournamentPrize`, tournamentPrize.data);
                context.commit('stopSavingTournamentPrize');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('stopSavingTournamentPrize');
                context.commit('setSavingError', error);
                return Promise.reject(error);
            }
        },

        async deleteTournamentPrize(context, data) {
            context.commit('startDeletingTournamentPrize');
            try {
                const tournamentPrize = await axios.post(`${process.env.VUE_APP_API_URL}/TournamentPrize/Delete`, data.data);
                context.commit('stopDeletingTournamentPrize');
                // context.commit('setTournamentPrizes', new TournamentPrize(flatten(tournamentPrize.data)));
                return Promise.resolve(tournamentPrize.data);
            } catch (error) {
                context.commit('stopDeletingTournamentPrize');
                context.commit('setDeletingError', error);
                return Promise.reject(error);
            }
        },

        async exportCsv(context, options = {}) {
            context.commit('startLoadingTournamentPrizes');
            try {
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/TournamentPrize/export/csv`, {
                    params: {
                        queryString: options.queryString || '',
                        sortBy: options.sortBy || 'Id',
                        sortDir: options.sortDir === 'descending' ? -1 : 1,
                    },
                });
                context.commit('stopLoadingTournamentPrizes');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('stopLoadingTournamentPrizes');
                return Promise.reject(error);
            }
        },
    },
};
