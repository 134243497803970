<template>
    <div>
        <ErrorDisplay :requestError="loadingErrorHistoricSinglesShadowRankings" @closed="clearError"/>

        <div v-loading="loadingHistoricSinglesShadowRankings">
            <DataTable
                    :autoSort="false"
                    :fields="['rankSingles',  'playerId', 'fullName', 'nationality', 'playerBirthDate', 'pointsSingles']"
                    :items="historicSinglesShadowRankings.data"
                    :schema="schema"
                    @item-clicked="selectRanking"
                    @header-clicked="setSort"
            />

            <Paginator
                    v-if="historicSinglesShadowRankings.data"
                    :data="historicSinglesShadowRankings"
                    :elementsPerPage="options.pageSize"
                    @prev-page="prevPage"
                    @next-page="nextPage"
                    @set-page-size="setPageSize"
            />
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { retrieveDtos } from '@/utils/crud-confirm';
import { ShadowPlayerRanking } from "@/models/shadow-player-ranking";

export default {
    props: ['shadowRankId', 'rankingDate', 'playerId'],
    watch: {
        shadowRankId() {
            this.shadowRankIdSelected(this.shadowRankId);
        },
        rankingDate() {
            this.rankingDateSelected(this.rankingDate);
        },
        playerId() {
            this.playerIdSelected(this.playerId);
        },
    },
    data() {
        return {
            schema: ShadowPlayerRanking.schema,
            layout: ShadowPlayerRanking.layoutHistory,
            options: {
                shadowRankId: null,
                rankingDate: null,
                queryString: '',
                sortBy: '',
                sortDir: '',
                pageSize: process.env.VUE_APP_PAGE_SIZE,
                page: 0,
            },
        };
    },
    mounted() {
    },
    computed: mapGetters('shadowHistorySingles', [
        'historicSinglesShadowRankings',
        'loadingHistoricSinglesShadowRankings',
        'loadingErrorHistoricSinglesShadowRankings']),
    methods: {
        ...mapActions('shadowHistorySingles', ['findHistoricSinglesShadowRankings']),
        refresh() {
            let params = {
                self: this,
                fRetrieve: this.findHistoricSinglesShadowRankings,
                options: this.options,
                dtosName: 'Historic Singles Rankings',
            };
            retrieveDtos(params);
        },
        shadowRankIdSelected(value) {
            this.options.shadowRankId = value;
            if (this.options.rankingDate)
                this.reset();
        },
        rankingDateSelected(value) {
            this.options.rankingDate = value;
            if (this.options.shadowRankId)
                this.reset();
        },
        playerIdSelected(value) {
            this.options.queryString = value;
            if (this.options.shadowRankId && this.options.rankingDate)
                this.reset();
        },
        reset() {
            this.options.page = 0;
            this.refresh();
        },
        prevPage() {
            this.options.page--;
            this.refresh();
        },
        nextPage() {
            this.options.page++;
            this.refresh();
        },
        setPageSize(size) {
            this.options.pageSize = size;
            this.refresh();
        },
        setSort(event) {
            this.options.sortBy = event.property;
            this.options.sortDir = event.order;
            this.refresh();
        },
        selectRanking(ranking) {
            this.$emit('rankingSelected', ranking);
        },
        clearError() {
            this.loadingError = null;
        },
    },
};
</script>
