export const csvExporter = (schema, data, fields) => {
    let result = '';
    const separator = ';';
    const newLine = '\r\n';

    if (!fields) {
        fields = Object.keys(schema.fields);
    }

    result += fields.map((f) => schema.fields[f].displayName).join(separator) + newLine;

    for (const item of data) {
        result += fields.map((k) => item[k]).join(separator) + newLine;
    }

    return result;
};
