import axios from 'axios';
import { buildParams } from '@/utils/query-param-builder';

export default {
    namespaced: true,

    state: {
        shadowSinglesRankings: {},
        loadingShadowSinglesRanking: false,
        loadingErrorShadowSinglesRanking: null,
    },

    getters: {
        shadowSinglesRankings: (state) => state.shadowSinglesRankings,
        loadingShadowSinglesRanking: (state) => state.loadingShadowSinglesRanking,
        loadingErrorShadowSinglesRanking: (state) => state.loadingErrorShadowSinglesRanking,
    },

    mutations: {
        setShadowSinglesRanking(state, data) {
            state.shadowSinglesRankings = data;
        },

        startLoadingShadowSinglesRanking(state) {
            state.loadingShadowSinglesRanking = true;
        },

        stopLoadingShadowSinglesRanking(state) {
            state.loadingShadowSinglesRanking = false;
        },

        setLoadingErrorShadowSinglesRanking(state, error) {
            state.loadingErrorShadowSinglesRanking = error;
        },

        clearLoadingErrorShadowSinglesRanking(state) {
            state.loadingErrorShadowSinglesRanking = null;
        },
    },

    actions: {
        async findShadowSinglesRankings(context, options = {}) {
            let params = buildParams(options);
            context.commit('startLoadingShadowSinglesRanking');
            context.commit('clearLoadingErrorShadowSinglesRanking');
            try {
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/ShadowRankingCurrent/Singles`,
                    { params });
                context.commit('setShadowSinglesRanking', result.data);
                context.commit('stopLoadingShadowSinglesRanking');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('stopLoadingShadowSinglesRanking');
                context.commit('setLoadingErrorShadowSinglesRanking', error);
                return Promise.reject(error);
            }
        },
    },
};
