<template>
    <div class="mt-0 mb-16">
        <el-collapse>
            <el-collapse-item :title="getTitle()" name="1">
                <el-alert type="info" :closable="false" :show-icon="false">
                    <div class="font-13" v-for="(string, index) in informationArray" :key="index" v-html="string"></div>
                </el-alert>
            </el-collapse-item>
        </el-collapse>
    </div>
</template>

<script>
export default {
    props: ['screenName', 'informationArray'],
    data() {
        return {};
    },
    mounted() {},
    methods: {
        getTitle() {
            return 'Information about ' + this.screenName + '. Click to show or hide this area.';
        }
    }
};
</script>

<style scoped>
.font-13 {
    font-size: 1.3em;
}
.font-14 {
    font-size: 1.4em;
}
.font-15 {
    font-size: 1.5em;
}
</style>
