var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-dialog',{attrs:{"visible":_vm.visibleSync,"close-on-click-modal":false,"title":_vm.title,"width":"65%","show-close":false,"top":"10vh"},on:{"update:visible":function($event){_vm.visibleSync=$event},"open":_vm.onOpen}},[_c('div',{staticClass:"mt-0 mb-0"},[_c('h3',[_vm._v(_vm._s(_vm.fullName()))])]),_c('el-alert',{attrs:{"type":"info","closable":false,"show-icon":true}},[_vm._v(" Click on a tournament result in table below to change value in column 'Include'. This column indicates whether the tournament result is considered for calculating Intro and Rookie Eligibility Dates. ")]),_c('div',[(!_vm.loadingError)?_c('DataTable',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loadingTournamentResults),expression:"loadingTournamentResults"}],attrs:{"items":_vm.tournamentResults,"fields":[
                'season',
                'tournamentName',
                'startDate',
                'sdFlag',
                'qpmFlag',
                'entryMethod',
                'entryMethodQualifying',
                'points',
                'includeForPdEligibleDates',
            ],"schema":_vm.schema,"autoSort":false},on:{"item-clicked":_vm.onItemClicked}}):_vm._e()],1),_c('div',{staticClass:"dialog-footer"},[_c('el-button',{on:{"click":_vm.onClose}},[_vm._v("Close")]),_c('span',{staticClass:"spacer"}),_c('el-button',{attrs:{"plain":"","type":"primary"},on:{"click":_vm.onEditPlayerDevelopment}},[_vm._v("Open Player Development")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }