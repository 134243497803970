<template>
    <div class="mt-0 mb-32">
        <h2>Acceptance List Procedures</h2>
        <div>
            <el-alert type="info" :closable="false" :show-icon="true">
                This view shows you all available acceptance list procedures to run. The available procedures are based on processing them two (Main Doubles),
                three (Qualification), four (Grand-Slam) and six weeks (Main Singles) in advance. This list is NOT based on any deadline. If you want to
                generate an acceptance list after the time point has already passed then select how many weeks you want to go back.
            </el-alert>

            If you want to go back in time select one of the following options:
            <el-select v-model="weekSelection" placeholder="Select">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>

            <procedure :procedure="'Main'" :weekType="'sixweek'" style="" v-bind:weekToGoBack="weekSelection" />

            <procedure :procedure="'Grand Slam'" :weekType="'fourweek'" style="margin-top:2em;" v-bind:weekToGoBack="weekSelection" />

            <procedure :procedure="'Qualifying'" :weekType="'threeweek'" style="margin-top:2em;" v-bind:weekToGoBack="weekSelection" />

            <procedure :procedure="'Doubles'" :weekType="'twoweek'" style="margin-top:2em;" v-bind:weekToGoBack="weekSelection" />
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { getMondayOfWeek, getWeekNumber } from '../utils/date-utils';
import moment from 'moment';

export default {
    data() {
        return {
            weekSelection: 0,
            options: [
                {
                    value: 0,
                    label: 'Current Week',
                },
                {
                    value: 1,
                    label: 'One Week back',
                },
                {
                    value: 2,
                    label: 'Two Weeks back',
                },
                {
                    value: 3,
                    label: 'Three Weeks back',
                },
            ],
        };
    },
};
</script>

<style></style>
