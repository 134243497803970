import axios from 'axios';

export default {
    namespaced: true,

    state: {
        players: {},
        loadingPlayers: false,
        loadingError: null,
        options: {
            queryString: null,
            sortBy: 'singlesRank',
            sortDir: 'ascending',
            page: 0,
            pageSize: 20,
        },
    },

    getters: {
        players: (state) => state.players,
        loadingPlayers: (state) => state.loadingPlayers,
        loadingError: (state) => state.loadingError,
        options: (state) => state.options,
    },

    mutations: {
        setPlayers(state, data) {
            state.players = data;
        },

        startLoadingPlayers(state) {
            state.loadingPlayers = true;
        },

        stopLoadingPlayers(state) {
            state.loadingPlayers = false;
        },

        setLoadingError(state, error) {
            state.loadingError = error;
        },

        clearLoadingError(state) {
            state.loadingError = null;
        },
    },

    actions: {
        async findPlayers(context, options = {}) {
            context.commit('startLoadingPlayers');
            context.commit('clearLoadingError');
            try {
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/Player/Search`, {
                    params: {
                        queryString: options.queryString || '',
                        pageSize: options.pageSize || 50,
                        sortBy: options.sortBy || 'LastName',
                        sortDir: options.sortDir === 'descending' ? -1 : 1,
                        page: options.page,
                        nationality: options.nationality,
                        // recordType: 'PL',
                    },
                });
                context.commit('setPlayers', result.data);                
                context.commit('stopLoadingPlayers');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('setLoadingError', error);
                context.commit('stopLoadingPlayers');
                return Promise.reject(error);
            }
        },

        async exportCsv(context, options = {}) {
            context.commit('startLoadingPlayers');
            try {
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/Player/export/csv`, {
                    params: {
                        queryString: options.queryString || '',
                        sortBy: options.sortBy || 'LastName',
                        sortDir: options.sortDir === 'descending' ? -1 : 1,
                    },
                });
                context.commit('stopLoadingPlayers');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('stopLoadingPlayers');
                return Promise.reject(error);
            }
        },
    },
};
