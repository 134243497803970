<template>
    <div class="SelectModeAndRound">
        <el-select :disabled="disabled" v-model="mode" placeholder="Mode" class="mr-16">
            <el-option value="S" label="Singles"></el-option>
            <el-option value="D" label="Doubles"></el-option>
        </el-select>

        <el-select :disabled="disabled" v-if="mode === 'S'" v-model="round" placeholder="Round" class="mr-16">
            <el-option value="M" label="Main"></el-option>
            <el-option value="Q" label="Quali"></el-option>
        </el-select>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
    name: 'SelectModeAndRound',
    props: {
        disabled: Boolean
    },
    data() {
        return {
            mode: 'S',
            round: 'M',
        };
    },
    watch: {
        mode: function() {
            this.$emit('input', this.mode, this.round);
        },
        round: function() {
            this.$emit('input', this.mode, this.round);
        }
    }
};
</script>
