<template>
  <div>
    <div class="flex-row mt-0 mb-32">
      <h2>Age Eligibility</h2>
    </div>

    <el-tabs v-model="activeTab">
      <el-tab-pane :label="titleAerTracking" name="aerTracking">
        <AgeEligibilityTrackingReport @reportSelected="reportSelected" @aerTrackingComputed="aerTrackingComputed"/>
      </el-tab-pane>
      <el-tab-pane :label="titleAerTournamentTracking" name="aerTournamentTracking">
        <AgeEligibilityTournamentTrackingReport/>
      </el-tab-pane>
      <el-tab-pane :label="titleAerWarningViolation" name="aerWarningViolation">
        <AgeEligibilityWarningViolationReport ref="aerWarningViolationReport"/>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>


<script>
import AgeEligibilityTrackingReport from './AgeEligibilityTrackingReport.vue';
import AgeEligibilityTournamentTrackingReport from './AgeEligibilityTournamentTrackingReport.vue';
import AgeEligibilityWarningViolationReport from './AgeEligibilityWarningViolationReport.vue';
import {mapGetters, mapActions} from 'vuex';

export default {
  components: {
    AgeEligibilityTrackingReport,
    AgeEligibilityTournamentTrackingReport,
    AgeEligibilityWarningViolationReport
  },
  data() {
    return {
      activeTab: 'aerTracking',
    };
  },
  mounted() {
  },
  computed: {
    ...mapGetters('ageEligibilityTrackings', [
      'ageEligibilityUpToDate',
      'ageEligibilityTrackings',
      'ageEligibilityTournamentTrackings',
      'ageEligibilityWarningsViolations',
    ]),
    titleAerTracking() {
      return 'AER Tracking (' + this.ageEligibilityTrackings.total + ')';
    },
    titleAerTournamentTracking() {
      return 'AER Tournament Tracking (' + this.ageEligibilityTournamentTrackings.length + ')';
    },
    titleAerWarningViolation() {
      return 'AER Warnings/ Violations (' + this.ageEligibilityWarningsViolations.total + ')';
    },
  },
  methods: {
    ...mapActions('ageEligibilityTrackings', [
      'findAgeEligibilityTracking',
      'retrieveAgeEligibilityTournamentTrackings',
      'retrieveAgeEligibilityTournamentTrackingPlayer',
    ]),
    reportSelected(report) {
      this.activeTab = 'aerTournamentTracking';
      this.findAgeEligibilityTracking(report.data.playerNbr);
      this.retrieveAgeEligibilityTournamentTrackings(report.data.playerNbr);
      let options = {
        playerNbr: report.data.playerNbr,
        ageAt: this.ageEligibilityUpToDate,
      };
      this.retrieveAgeEligibilityTournamentTrackingPlayer(options);
    },
    aerTrackingComputed() {
      this.$refs['aerWarningViolationReport'].refresh();
    },
  },
};
</script>
