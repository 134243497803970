<template>
    <el-dialog :visible.sync="visibleSync" :close-on-click-modal="false" title="Prize Update" width="45%" :show-close="false">
        <ErrorDisplay :requestError="requestError" @closed="clearError" />
        <center>
            <div>
                <h4>Import Prize Update CSV File</h4>
                <div v-loading="loading">
                    <el-upload
                        class="mt-32"
                        :headers="getHeaders()"
                        drag
                        :multiple="false"
                        :action="action"
                        :show-file-list="false"
                        :file-list="fileList"
                        :auto-upload="true"
                        :on-success="onUploadSuccess"
                        :on-error="onUploadError"
                        :before-upload="beforeUpload"
                        :disabled="!currency"
                        :data="uploadData()"
                    >
                        <i class="el-icon-upload"></i>
                        <div v-if="currency" class="el-upload__text">Drop .csv file here or <em>click to upload</em></div>
                        <div v-if="!currency" class="el-upload__text">Please select currency for Import first</div>
                    </el-upload>
                </div>
            </div>
            <div>
                <h4>Select currency for Import</h4>
                <el-select v-model="currency" placeholder="Select Currency">
                    <el-option v-for="item in currencies" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                </el-select>
            </div>
        </center>

        <div class="dialog-footer">
            <el-button @click="onClose">Close Import</el-button>
            <el-button @click="onCloseReport">Close Report</el-button>
        </div>

        <div v-if="successData" title="Player Payments Import Report">
            <h3>Player Payments Import Report for {{ successData.tournYear }} {{ successData.tournName }}</h3>
            <div>
                <div>Imported Payments: {{ successData.importedPlayerPaymentCount }}</div>
                <div>Not imported Payments: {{ successData.notImportedPlayerPaymentCount }}</div>
                <div v-if="successData.notImportedPayments.length > 0">
                    <h5>Not imported Rounds</h5>
                    <DataTable
                        :items="successData.notImportedPayments"
                        :schema="schema"
                        :fields="[
                            'idp',
                            'playerNbr',
                            'playerName',
                            'nationality',
                            'singlesRound',
                            'doublesRound',
                            'singlePriceMoney',
                            'doublePriceMoney',
                            'subtotal',
                            'tax',
                            'totalDeduction',
                            'netPayment',
                        ]"
                        :autoSort="false"
                    />
                </div>
            </div>
        </div>
    </el-dialog>
</template>

<script>
import * as _ from 'lodash';
import { PlayerPayment } from '../models/player-payment';

export default {
    props: ['tournYear', 'tournNbr', 'visibleSync'],
    data() {
        return {
            action: `${process.env.VUE_APP_API_URL}/PlayerPayment/Edition/${this.tournYear}/${this.tournNbr}/Import`,

            fileList: [],
            loading: false,
            requestError: null,
            currencies: [
                { label: 'USD', value: 'D' },
                { label: 'EUR', value: 'E' },
                { label: 'AUD', value: 'U' },
                { label: 'GBP', value: 'P' },
            ],
            currency: null,
            successData: null,
            schema: PlayerPayment.schema,
            layout: PlayerPayment.layout,
        };
    },

    mounted() {
        this.loading = false;
    },

    methods: {
        getHeaders() {
            const token = localStorage.getItem('idToken');
            const accessToken = localStorage.getItem('accessToken');

            return {
                Authorization: `Bearer ${token}`,
                'X-Access-Token': `Bearer ${accessToken}`,
            };
        },

        onUploadSuccess(response, file, fileList) {
            this.$notify({
                title: 'Prize Update: Import successful',
                message: file.name + '\n' + response.importedPlayerPaymentCount + ' entries imported',
                type: 'success',
                duration: 7000,
            });
            this.successData = response;
            this.loading = false;
            this.$emit('success', response);
        },
        onUploadError(error, file, fileList) {
            let parsed = JSON.parse(error.message);
            this.requestError = {
                response: {
                    data: parsed,
                },
            };
            this.$notify({
                title: 'Prize Update: Import failed',
                message: file.name + ': ' + parsed.Message,
                type: 'error',
                duration: 7000,
            });
            this.loading = false;
        },
        beforeUpload(file) {
            console.log(file);
            const isExcelCsv = file.type === 'application/vnd.ms-excel' && _.lowerCase(file.name).endsWith('csv');
            const isCsv = file.type === 'text/csv';
            this.requestError = null;
            if (!isCsv && !isExcelCsv) {
                this.$notify({
                    title: 'Prize Update: Error',
                    message: file.name + ": File must be CSV format. File's MIME type is " + file.type,
                    type: 'error',
                    duration: 7000,
                });
            }
            if (isCsv || isExcelCsv) {
                this.loading = true;
                return true;
            }
            return false;
        },
        clearError() {
            this.requestError = null;
        },
        onClose() {
            this.successData = null;
            this.requestError = null;
            this.$emit('close');
        },
        onCloseReport() {
            this.successData = null;
        },
        handleSelect(item) {
            console.log(item);
        },
        uploadData() {
            let data = {
                currency: this.currency,
            };
            return data;
        },
    },
};
</script>

<style></style>
