<template>
    <div>
        <DialogAddItem
            :visibleSync="addDialogVisible"
            dtoName="Mixed Double"
            :selected="selectedMixedDouble"
            :schema="schemaAdd"
            :layout="layoutAdd"
            @save="saveChanges"
            @change="onChange"
            @close="addDialogVisible = false"
        />

        <DialogEditItem
            :visibleSync="editDialogVisible"
            dtoName="Mixed Double"
            :selected="selectedMixedDouble"
            :schema="schema"
            :layout="layout"
            @save="saveChanges"
            @change="onChange"
            @delete="onDelete"
            @close="editDialogVisible = false"
        />

        <ErrorDisplay :requestError="this.requestError" @closed="this.requestError = null" />

        <div class="flex-row mt-0 mb-8">
            <span class="spacer"></span>
            <el-button v-if="!isBaselineReader" @click="showAddDialog" size="mini" plain type="primary">Add Mixed Double</el-button>
        </div>

        <DataTable
            v-if="!requestError"
            v-loading="loadingMixedDoubles"
            :items="mixedDoubles"
            :fields="['fullName', 'nationality', 'partnerName', 'prizeMoneyMixed']"
            :schema="schema"
            @item-clicked="itemClicked"
            :autoSort="false"
        />
    </div>
</template>

<script>
import { MixedDouble } from '../models/mixed-double';
import { mapGetters, mapActions } from 'vuex';
import { retrieveDtos, deleteConfirmDto, createConfirmDto, updateConfirmDto } from '../utils/crud-confirm';
import { isBaselineReader } from '../services/authGroups';

export default {
    props: ['tournamentId', 'tournamentYear'],
    data() {
        return {
            schema: MixedDouble.schema,
            layout: MixedDouble.layout,
            schemaAdd: MixedDouble.schemaAdd,
            layoutAdd: MixedDouble.layoutAdd,
            addDialogVisible: false,
            editDialogVisible: false,
            importVisible: false,
            selectedMixedDouble: null,
            mode: null,
            queryString: '',
            requestError: null,
            isBaselineReader: isBaselineReader(),
        };
    },
    mounted() {
        this.refresh();
    },
    computed: {
        ...mapGetters('mixedDoubles', ['mixedDoubles', 'loadingMixedDoubles', 'loadingError']),
    },
    methods: {
        ...mapActions('mixedDoubles', ['findMixedDoubles', 'createMixedDoubles', 'updateMixedDoubles', 'deleteMixedDoubles']),
        refresh() {
            let options = {
                tournYear: this.tournamentYear,
                tournNbr: this.tournamentId,
            };
            let params = {
                self: this,
                fRetrieve: this.findMixedDoubles,
                options: options,
                dtosName: 'Mixed Doubles',
            };
            retrieveDtos(params);
        },
        itemClicked(item) {
            this.selectedMixedDouble = new MixedDouble(item);
            this.mode = 'edit';
            this.editDialogVisible = true;
        },
        showAddDialog() {
            this.selectedMixedDouble = new MixedDouble({
                tournamentYear: this.tournamentYear,
                tournamentId: this.tournamentId,
            });
            this.mode = 'add';
            this.addDialogVisible = true;
        },
        onChange() {},
        onDelete() {
            let params = {
                self: this,
                fDelete: this.deleteMixedDoubles,
                vConfirm: this.tournamentId,
                dto: this.selectedMixedDouble,
                dtoName: 'Mixed Double',
                vConfirmName: 'Tournament Id',
                callback: this.crudCallback,
            };
            deleteConfirmDto(params);
        },
        saveChanges() {
            if (this.mode === 'add') {
                let params = {
                    self: this,
                    fCreate: this.createMixedDoubles,
                    dto: this.selectedMixedDouble,
                    dtoName: 'Mixed Double',
                    callback: this.crudCallback,
                };
                createConfirmDto(params);
            } else {
                let params = {
                    self: this,
                    fUpdate: this.updateMixedDoubles,
                    dto: this.selectedMixedDouble,
                    dtoName: 'Mixed Double',
                    callback: this.crudCallback,
                };
                updateConfirmDto(params);
            }
        },
        crudCallback() {
            this.refresh();
            this.requestError = null;
            this.addDialogVisible = false;
            this.editDialogVisible = false;
        },
    },
};
</script>

<style scoped>
.input-search {
    max-width: 384px;
}
</style>
