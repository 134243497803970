<template>
    <div v-loading="loadingMostRecentAddedTournaments">
        <div class="flex-row mt-0 mb-32">
            <span class="spacer"></span>
        </div>
        <ErrorDisplay :requestError="this.mostRecentAddedTournamentsError" @closed="clearError" />
        <DataTable
            :items="mostRecentAddedTournaments"
            :schema="schemaTournament"
            :fields="['name', 'startDate', 'sponsor', 'tournamentId', 'season']"
            :autoSort="false"
        />
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { Tournament } from '../../models/tournament';

export default {
    props: ['rankingDate'],
    data() {
        return {
            schemaTournament: Tournament.schema,
        };
    },
    mounted() {},
    computed: {
        ...mapGetters('rankingProcedure', ['mostRecentAddedTournaments', 'loadingMostRecentAddedTournaments', 'mostRecentAddedTournamentsError']),
    },
    methods: {
        clearError() {
            this.mostRecentAddedTournamentsError = null;
        },
    },
};
</script>

<style scoped></style>
