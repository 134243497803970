import axios from 'axios';

export default {
    namespaced: true,

    state: {
        years: [],
        loadingYears: false,
        playerPayments: [],
        loadingPlayerPayments: false,
        downloadingPlayerPayments: false,
        loadingError: null,
    },

    getters: {
        playerPaymentYears: (state) => state.years,
        loadingPlayerPaymentYears: (state) => state.loadingYears,
        playerPayments: (state) => state.playerPayments,
        loadingPlayerPayments: (state) => state.loadingPlayerPayments,
        downloadingPlayerPayments: (state) => state.downloadingPlayerPayments,
        loadingError: (state) => state.loadingError,
    },

    mutations: {
        setPlayerPaymentYears(state, data) {
            state.years = data;
        },
        clearPlayerPaymentYears(state) {
            state.years = [];
        },

        startLoadingPlayerPaymentYears(state) {
            state.loadingYears = true;
        },

        stopLoadingPlayerPaymentYears(state) {
            state.loadingYears = false;
        },


        setPlayerPayments(state, data) {
            state.playerPayments = data;
        },

        startLoadingPlayerPayments(state) {
            state.loadingPlayerPayments = true;
        },

        stopLoadingPlayerPayments(state) {
            state.loadingPlayerPayments = false;
        },

        startDownloadingPlayerPayments(state) {
            state.downloadingPlayerPayments = true;
        },

        stopDownloadingPlayerPayments(state) {
            state.downloadingPlayerPayments = false;
        },

        setLoadingError(state, error) {
            state.loadingError = error;
        },

        clearLoadingError(state) {
            state.loadingError = null;
        },
    },

    actions: {
        async findPlayerPaymentYears(context, playerNbr) {
            if (!playerNbr) {
                context.commit('clearPlayerPaymentYears');
                return;
            }
            context.commit('startLoadingPlayerPaymentYears');
            try {
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/PlayerPayment/Player/${playerNbr}/Years`);
                context.commit('setPlayerPaymentYears', result.data);
                context.commit('stopLoadingPlayerPaymentYears');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('setLoadingError', error);
                context.commit('clearPlayerPaymentYears');
                context.commit('stopLoadingPlayerPaymentYears');
                return Promise.reject(error);
            }
        },
        async findPlayerPaymentYearsFrom2022(context, playerNbr) {
            if (!playerNbr) {
                context.commit('clearPlayerPaymentYears');
                return;
            }
            context.commit('startLoadingPlayerPaymentYears');
            try {
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/PlayerPayment/Player/${playerNbr}/YearsFrom2022`);
                context.commit('setPlayerPaymentYears', result.data);
                context.commit('stopLoadingPlayerPaymentYears');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('setLoadingError', error);
                context.commit('clearPlayerPaymentYears');
                context.commit('stopLoadingPlayerPaymentYears');
                return Promise.reject(error);
            }
        },
        async findPlayerPayments(context, options = {}) {
            if (!options.playerNbr || !options.year) {
                return;
            }
            context.commit('startLoadingPlayerPayments');
            let playerNbr = options.playerNbr;
            let year = options.year;
            try {
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/PlayerPayment/Player/${playerNbr}/Year/${year}`);
                context.commit('setPlayerPayments', result.data);
                context.commit('stopLoadingPlayerPayments');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('setLoadingError', error);
                context.commit('stopLoadingPlayerPayments');
                return Promise.reject(error);
            }
        },
        async downloadPdfPlayerPayments(context, options = {}) {
            if (!options.playerNbr || !options.year) {
                Promise.reject();
            }
            context.commit('startDownloadingPlayerPayments');
            let playerNbr = options.playerNbr;
            let year = options.year;
            try {
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/PlayerPayment/Player/${playerNbr}/Year/${year}/Export/Pdf`, { responseType: 'blob' });
                context.commit('stopDownloadingPlayerPayments');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('setLoadingError', error);
                context.commit('stopDownloadingPlayerPayments');
                return Promise.reject(error);
            }
        },
    },
};
