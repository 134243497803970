<template>
    <div>
        <el-dialog :visible.sync="visibleSync" :close-on-click-modal="false" title="Round Results Import" width="65%" :show-close="false">
            <ErrorDisplay :requestError="requestError" @closed="onErrorClose" />
            <div class="flex-row-space-around">
                <RoundImportSta @error="onError" @success="onSuccess" :tournYear="this.tournYear" :tournNbr="this.tournNbr" />
                <RoundImportXml @error="onError" @success="onSuccess" :tournYear="this.tournYear" :tournNbr="this.tournNbr" />
            </div>

            <el-scrollbar wrap-style="max-height: 400px;">
                <DataTable
                    :items="importEntries"
                    :schema="importEntrySchema"
                    :fields="['createdAt', 'fileType', 'tournamentId', 'tournamentName', 'tournamentYear', 'filename']"
                    :autoSort="false"
                />
            </el-scrollbar>

            <div class="dialog-footer">
                <el-button @click="onClose">Close Import</el-button>
                <el-button @click="onCloseReport">Close Report</el-button>
                <el-button @click="onClearTable">Clear Table</el-button>
            </div>

            <div v-if="this.successData" title="Round Results Import Report">
                <h3>Round Results Import Report for {{ this.successData.tournYear }} {{ this.successData.tournName }} {{ this.successData.drawName }}</h3>
                <div>
                    <div>Imported Rounds: {{ successData.importedRoundCount }}</div>
                    <div>Not imported Rounds: {{ successData.notImportedRoundCount }}</div>
                    <div v-if="this.successData.notImportedRounds.length > 0">
                        <h5>Not imported Rounds</h5>
                        <DataTable
                            :items="successData.notImportedRounds"
                            :schema="roundSchema"
                            :fields="['sdFlag', 'qpmFlag', 'roundNumber', 'numberOfMatchInRound', 'team1TeamName', 'team2TeamName', 'matchWinner', 'scores']"
                            :autoSort="false"
                        />
                    </div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { ImportEntry } from '../models/import-entry';
import { mapGetters, mapActions } from 'vuex';
import RoundImportSta from './RoundImportSta.vue';
import { TournamentRound } from '../models/tournament-round';

export default {
    components: { RoundImportSta },
    props: ['visibleSync', 'tournYear', 'tournNbr'],
    data() {
        return {
            importEntrySchema: ImportEntry.schema,
            layout: ImportEntry.layout,
            requestError: null,
            roundSchema: TournamentRound.schema,
            successData: null,
        };
    },
    mounted() {
        this.refresh();
    },
    computed: mapGetters('importEntries', ['importEntries', 'loadingImportEntries']),
    methods: {
        ...mapActions('importEntries', ['findImportEntries', 'deleteAllImportEntries']),
        refresh() {
            this.requestError = null;
            this.findImportEntries();
        },
        onError(error) {
            this.requestError = error;
        },
        onClose() {
            this.successData = null;
            this.requestError = null;
            this.$emit('close');
        },
        onErrorClose() {
            this.requestError = null;
        },
        onSuccess(data) {
            this.successData = data;
            this.refresh();
            this.$emit('success');
        },
        onCloseReport() {
            this.successData = null;
        },
        onClearTable() {
            this.$confirm('This will clear all Import Entries from Table. Please confirm.', {
                confirmButtonText: 'Clear Import Table',
                cancelButtonText: 'Cancel',
                type: 'warning',
            }).then(() => {
                this.deleteAllImportEntries()
                    .then(() => {
                        this.$notify({
                            title: 'Clear Import Table',
                            message: 'Import Table successfully cleared',
                            type: 'success',
                            duration: 5000,
                        });
                        this.findImportEntries();
                    })
                    .catch((error) => {
                        this.requestError = error;
                    });
            });
            this.successData = null;
        },
    },
};
</script>

<style scoped>
.input-search {
    max-width: 384px;
}
</style>
