<template>
  <div>
    <div class="flex-row mt-16 mb-16">
      <h3>Age Eligibility Tracking {{ this.formattedReportDate }}</h3>
      <span class="spacer"></span>

      <p>Up to Date</p>
      <el-date-picker
          v-model="options.ageAt"
          :firstDayOfWeek="1"
          type="date"
          format="yyyy-MM-dd"
          placeholder="Pick a day"
          class="ml-16 mr-32"
          @change="selectDate"
          @keydown.enter.native="selectDate"
      ></el-date-picker>
      <span class="spacer"></span>

      <el-button plain type="primary" @click="onExportCsv" v-loading="loadingCsvAgeEligibilityTrackings">Export CSV
      </el-button>
    </div>
    <div class="flex-row mt-16 mb-16">
      <el-button plain type="primary" @click="onExpandCollapseTable">Expand/ Collapse Table</el-button>
      <div class="spacer"></div>
      <div class="spacer"></div>
      <p>Player Age</p>
      <el-radio-group v-model="options.playerAge" @change="resetPage" size="mini" class="ml-16">
        <el-radio-button label="ALL">All</el-radio-button>
        <el-radio-button label="14"> 14</el-radio-button>
        <el-radio-button label="15"> 15</el-radio-button>
        <el-radio-button label="16"> 16</el-radio-button>
        <el-radio-button label="17"> 17</el-radio-button>
      </el-radio-group>
      <div class="spacer"></div>

      <SelectNationality
          v-model="options.nationality"
          :lookup="nationalities"
          :clearable="true"
          @select="resetPage"
          @keydown.enter.native="resetPage"/>
      <el-input
          placeholder="Filter by Id or Name"
          v-model="options.queryString"
          class="input-search ml-32"
          @change="resetPage"
          @keydown.esc.native="reset"
      >
        <el-button slot="append" icon="el-icon-search" @click="resetPage"></el-button>
      </el-input>
    </div>

    <el-alert
        title="info"
        type="info"
        :closable="false"
        description="This table contains only adjusted numbers after application of AER. Click on a player to show her AER Tournament Tracking Report."
        show-icon
    >
    </el-alert>

    <div v-loading="loadingTable">
      <DataTable
          v-if="!loadingErrorAgeEligibilityTrackings"
          :schema="schema"
          :items="ageEligibilityTrackings.data"
          :fields="tableFields"
          :autoSort="false"
          :sortField="options.sortBy"
          :sortDir="options.sortDir"
          @item-clicked="selectPlayer"
          @header-clicked="setSort"
          :key="componentKey"
      />

      <Paginator
          v-if="!loadingErrorAgeEligibilityTrackings"
          :data="ageEligibilityTrackings"
          :elementsPerPage="options.pageSize"
          @prev-page="prevPage"
          @next-page="nextPage"
          @set-page-size="setPageSize"
      />
    </div>
  </div>
</template>


<script>
import {mapGetters, mapActions} from 'vuex';
import {AgeEligibilityTracking} from '../../models/age-eligibility-tracking';
import {icoCountries} from '../../utils/country-codes';
import {retrieveDtos} from '../../utils/crud-confirm';
import moment from 'moment';
import FileSaver from 'file-saver';

export default {
  data() {
    return {
      schema: AgeEligibilityTracking.schema,
      tableFieldsFull: AgeEligibilityTracking.tableFieldsReportFull,
      tableFieldsPreview: AgeEligibilityTracking.tableFieldsReportPreview,
      tableFields: AgeEligibilityTracking.tableFieldsReportPreview,
      requestError: null,
      nationalities: Object.keys(icoCountries),
      selectedReport: null,
      options: {
        queryString: '',
        pageSize: 20,
        sortBy: null,
        sortDir: 'ascending',
        page: 0,
        nationality: '',
        ageAt: null,
        ppStatus: 'ALL',
        currentPhase: 'ALL',
        playerAge: 'ALL',
      },
      componentKey: 0,
    };
  },
  mounted() {
    this.refresh();
  },
  computed: {
    ...mapGetters('ageEligibilityTrackings', [
      'ageEligibilityUpToDate',
      'ageEligibilityTrackings',
      'loadingAgeEligibilityUpToDate',
      'loadingAgeEligibilityTrackings',
      'loadingCsvAgeEligibilityTrackings',
      'computingAgeEligibilityTrackings',
      'loadingErrorAgeEligibilityTrackings',
    ]),
    formattedReportDate() {
      return this.ageEligibilityUpToDate ? ' up to ' + moment(this.ageEligibilityUpToDate).format('YYYY-MM-DD') : ' - please select Up to Date';
    },
    loadingTable() {
      return this.loadingAgeEligibilityTrackings || this.computingAgeEligibilityTrackings;
    },
  },
  methods: {
    ...mapActions('ageEligibilityTrackings', [
      'findAgeEligibilityTrackings',
      'retieveAgeEligibilityUpToDate',
      'computeAgeEligibilityTrackings',
      'downloadCsvEligibilitTracking',
    ]),
    refresh() {
      this.search();
    },
    search() {
      let params = {
        self: this,
        fRetrieve: this.findAgeEligibilityTrackings,
        options: this.options,
        dtosName: 'AER Tracking',
      };
      retrieveDtos(params);
      this.retieveAgeEligibilityUpToDate();
    },
    selectDate() {
      this.computeAgeEligibilityTrackings(this.options.ageAt).then(() => {
        this.$emit('aerTrackingComputed', this.selectedReport);
        this.resetPage();
      });
    },
    selectPlayer(item, index) {
      this.playerPointer = index;
      this.selectedReport = new AgeEligibilityTracking(item);
      this.visibleSync = true;
      this.$emit('reportSelected', this.selectedReport);
    },
    setSort(event) {
      console.log(event);
      this.options.sortBy = event.property;
      this.options.sortDir = event.order;
      this.search();
    },
    prevPage() {
      this.options.page--;
      this.search();
    },
    nextPage() {
      this.options.page++;
      this.search();
    },
    setPageSize(size) {
      this.options.pageSize = size;
      this.search();
    },
    resetPage() {
      this.options.page = 0;
      this.search();
    },
    reset() {
      this.options.queryString = null;
      this.options.page = 0;
      this.search();
    },
    onExpandCollapseTable() {
      if (this.tableFields === this.tableFieldsFull) {
        this.tableFields = this.tableFieldsPreview;
      } else if (this.tableFields === this.tableFieldsPreview) {
        this.tableFields = this.tableFieldsFull;
      }
      this.componentKey++;
    },
    onChange() {
    },
    onExportCsv() {
      this.downloadAndSaveCsvFile(this.downloadCsvEligibilitTracking, `Age_Eligibility_Tracking_${this.formattedReportDate}.csv`, this.options);
    },
    downloadAndSaveCsvFile(fdownload, filename, options = {}) {
      fdownload(options)
          .then((result) => {
            const file = new File([result], filename, {type: 'text/csv'});
            FileSaver.saveAs(file);
            this.$notify({
              title: 'Age Eligibility Tracking: CSV Download successful',
              message: filename + ' downloaded',
              type: 'success',
              duration: 5000,
            });
          })
          .catch((error) => {
            this.requestError = error;
            this.$notify({
              title: 'Age Eligibility Tracking: CSV Download failed',
              message: filename + ': ' + error,
              type: 'error',
              duration: 7000,
            });
          });
    },
  },
};
</script>
