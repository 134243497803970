<template>
    <div>
        <DialogAddItem
            :visibleSync="addDialogVisible"
            dtoName="Corona Drop Date"
            :selected="selectedDropDate"
            :schema="schemaAdd"
            :layout="layoutAdd"
            @save="saveChanges"
            @change="onChange"
            @close="addDialogVisible = false"
        />

        <DialogEditItem
            :visibleSync="editDialogVisible"
            dtoName="Corona Drop Date"
            :selected="selectedDropDate"
            :schema="schema"
            :layout="layout"
            @save="saveChanges"
            @change="onChange"
            @delete="deleteItem"
            @close="editDialogVisible = false"
        />

        <div class="flex-row mb-32">
            <h2><i class="el-icon-medal-1 mr-16"></i>Better of Ranking: Drop Dates</h2>

            <span class="spacer"></span>

            <div :key="compenentKey">
                <div class="flex-row mb-32" v-if="this.dropDatesTableExists">
                    <p class="ml-32">Player</p>
                    <SelectPlayer class="ml-16" :value="selectedPlayerFullName" @palyer-selected="selectPlayer" />
                    <el-button plain type="primary" @click="reset" size="mini" class="ml-8">Reset</el-button>
                    <el-button v-if="!isBaselineReader" @click="addDropDate" plain type="primary" size="mini" class="ml-16">Add Drop Date</el-button>
                </div>

                <el-button v-if="!this.dropDatesTableExists && !isBaselineReader" @click="createTable" plain type="primary">Create Drop Dates Table</el-button>
            </div>
        </div>

        <ErrorDisplay :requestError="this.loadingError" @closed="clearError" />

        <DetailedInformation screenName="Better of Ranking: Drop Dates" :informationArray="this.informationArray()" />

        <div v-loading="loadingDropDates" :key="compenentKey">
            <DataTable
                v-if="dropDatesTableExists"
                :items="dropDates.data"
                :schema="schema"
                :fields="['playerId', 'fullName', 'season', 'tournamentId', 'tournamentName', 'sdFlag', 'startDate', 'useForRankingDate', 'dropAtDate']"
                :autoSort="false"
                @item-clicked="selectDropDate"
            />

            <Paginator
                v-if="dropDatesTableExists && dropDates.data"
                :data="dropDates"
                :elementsPerPage="dropDatesOptions.pageSize"
                @prev-page="prevPage"
                @next-page="nextPage"
                @set-page-size="setPageSize"
            />
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { TournamentResultCoronaDropDate } from '../models/tournament-result-corona-drop-date';
import { retrieveDtos, deleteConfirmDto, createConfirmDto, updateConfirmDto } from '../utils/crud-confirm';
import { isBaselineReader } from '../services/authGroups';

export default {
    data() {
        return {
            schema: TournamentResultCoronaDropDate.schema,
            schemaAdd: TournamentResultCoronaDropDate.schemaAdd,
            layout: TournamentResultCoronaDropDate.layout,
            layoutAdd: TournamentResultCoronaDropDate.layoutAdd,
            editDialogVisible: false,
            addDialogVisible: false,
            mode: 'add',
            selectedDropDate: null,
            dropDatesTableExists: false,
            compenentKey: 0,
            isBaselineReader: isBaselineReader(),
        };
    },
    mounted() {
        this.refresh();
    },
    computed: {
        ...mapGetters('coronaRankingDropDates', ['dropDates', 'loadingDropDates', 'loadingError', 'dropDatesOptions', 'selectedPlayerFullName']),
    },
    methods: {
        ...mapActions('coronaRankingDropDates', [
            'findDropDates',
            'createDropDate',
            'updateDropDate',
            'deleteDropDate',
            'setSelectedPlayerFullName',
            'checkIfDropDatesTableExists',
            'createDropDatesTable',
        ]),
        refresh() {
            this.checkIfDropDatesTableExists().then((data) => {
                if (data.data === true) {
                    this.dropDatesTableExists = true;
                    let params = {
                        self: this,
                        fRetrieve: this.findDropDates,
                        options: this.dropDatesOptions,
                        dtosName: 'Corona Drop Dates',
                    };
                    retrieveDtos(params);
                } else {
                    this.dropDatesTableExists = false;
                }
                this.compenentKey++; // Re-render components with :key="componentKey"
            });
        },
        reset() {
            this.dropDatesOptions.queryString = null;
            this.dropDatesOptions.page = 0;
            this.setSelectedPlayerFullName(null);
            this.refresh();
        },
        saveChanges() {
            if (this.mode === 'edit') {
                let params = {
                    self: this,
                    fUpdate: this.updateDropDate,
                    dto: this.selectedDropDate,
                    dtoName: 'Corona Drop Date',
                    callback: this.crudCallback,
                };
                updateConfirmDto(params);
            } else if (this.mode === 'add') {
                let params = {
                    self: this,
                    fCreate: this.createDropDate,
                    dto: this.selectedDropDate,
                    dtoName: 'Corona Drop Date',
                    callback: this.crudCallback,
                };
                createConfirmDto(params);
            }
        },
        onChange() {},
        deleteItem() {
            let params = {
                self: this,
                fDelete: this.deleteDropDate,
                vConfirm: this.selectedDropDate.data.playerId,
                dto: this.selectedDropDate,
                dtoName: 'Corona Drop Date',
                vConfirmName: 'Player ID',
                callback: this.crudCallback,
            };
            deleteConfirmDto(params);
        },
        selectPlayer(player) {
            this.dropDatesOptions.queryString = player ? player.id : null;
            this.dropDatesOptions.page = 0;
            this.setSelectedPlayerFullName(player.fullName);
            this.refresh();
        },
        crudCallback() {
            this.refresh();
            this.requestError = null;
            this.addDialogVisible = false;
            this.editDialogVisible = false;
        },
        informationArray() {
            if (this.dropDatesTableExists) {
                return [
                    'This screen is used for management of drop dates for Ranking of tourn_results from frozen Ranking 2020-03-16.',
                    'By default the drop rates of all that tourn_results is extended to 104 weeks.',
                    'Tourn_Results mapped in the table below have custom drop dates, also listed.',
                    'Each run of the Ranking Procedure takes into account custom drop dates.',
                    'Searching is possible by player id <b>or</b> last name <b>or</b> first name. Combined search is not possible.',
                    'Adding a new Drop Date is possible by clicking button "Add Drop Date". Adding a new Drop Date when entry is already present will throw an error.',
                    'Changing an existing Drop Date is possible by clicking an entry in the table below.',
                ];
            } else {
                return [
                    'This screen is used for management of drop dates for Ranking of tourn_results from frozen Ranking 2020-03-16.',
                    'By default the drop rates of all that tourn_results is extended to 104 weeks.',
                    'Ranking Procedure is internally configured for some results to use custom drop dates.',
                    'To start managing custom drop dates by yourself click the button "Create Drop Dates Table". Table will be filled with all currently used Adjusted Player Drop Dates.',
                    'Ranking Procedure will then start to use entries from this table.',
                ];
            }
        },
        addDropDate() {
            this.mode = 'add';
            this.addDialogVisible = true;
            this.selectedDropDate = new TournamentResultCoronaDropDate({});
        },
        selectDropDate(dropDate) {
            this.mode = 'edit';
            this.editDialogVisible = true;
            this.selectedDropDate = new TournamentResultCoronaDropDate(dropDate);
        },
        clearError() {
            this.loadingError = null;
        },
        prevPage() {
            this.dropDatesOptions.page--;
            this.refresh();
        },
        nextPage() {
            this.dropDatesOptions.page++;
            this.refresh();
        },
        setPageSize(size) {
            this.dropDatesOptions.pageSize = size;
            this.refresh();
        },
        createTable() {
            this.createDropDatesTable().then((data) => {
                console.log(data);
                this.refresh();
            });
        },
    },
};
</script>

<style scoped></style>
