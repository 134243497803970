<template>
    <div>
        <DialogAddItem
            :visibleSync="addDialogVisible"
            dtoName="Recipient"
            :selected="selectedRecipient"
            :schema="schema"
            :layout="layout"
            :widthPerc="60"
            :note="note()"
            @save="addItem"
            @change="onChange"
            @close="addDialogVisible = false"
        />

        <DialogEditItem
            :visibleSync="editDialogVisible"
            dtoName="Recipient"
            :selected="selectedRecipient"
            :schema="schema"
            :layout="layout"
            :widthPerc="60"
            :note="note()"
            @save="editItem"
            @change="onChange"
            @delete="deleteItem"
            @close="editDialogVisible = false"
            @prev="gotoPrevItem"
            @next="gotoNextItem"
        />

        <div class="flex-row mt-0 mb-32">
            <h3>Message Recipients</h3>
            <span class="spacer"></span>

            <el-button @click="openAddDialog" size="mini" plain type="primary" class="mr-8">Add Recipient</el-button>
            <el-input placeholder="Filter by Name" v-model="options.queryString" class="input-search mr-16" @change="resetPage" @keydown.esc.native="reset">
                <el-button slot="append" icon="el-icon-search" @click="resetPage"></el-button>
            </el-input>
        </div>

        <ErrorDisplay :requestError="this.requestError" @closed="clearError" />

        <div class="mt-0 mb-16">
            <span
                >The new WTA Manager Messaging System (MMS) intends to keep players and their support groups informed about tournaments specific information
                while competing in WTA events (not available for Grand Slams, Challengers or Futures). This will be possible by establishing a direct link
                between the WTA Supervisor's software and the registered e-mail accounts.</span
            >
        </div>

        <div class="mt-0 mb-32">
            <span
                >You and your registered contacts can subscribe to the Order of Play, Results, Schedule Updates and Tournament important announcements by e-mail
                for the WTA events where you are competing while still in the draw.</span
            >
        </div>

       <div class="flex-row-space-around">
            <div v-loading="loadingRecipients" class="table-container">
                <DataTable
                    :items="recipients.data"
                    :fields="['contactData', 'relationship', 'relFirstName', 'relLastName', 'active']"
                    :schema="schema"
                    :autoSort="false"
                    :rowPointer="false"
                    @item-clicked="onItemClick"
                />

                <Paginator
                    v-if="recipients.data"
                    :data="recipients"
                    :elementsPerPage="options.pageSize"
                    @prev-page="prevPage"
                    @next-page="nextPage"
                    @set-page-size="setPageSize"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { MessageRecipient } from '../models/message-recipient';
import { createConfirmDto, retrieveDtos, updateConfirmDto, deleteConfirmDto } from '../utils/crud-confirm';

export default {
    props: ['playerNbr'],    
    data() {
        return {
            schema: MessageRecipient.schema,
            layout: MessageRecipient.layout,
            requestError: null,
            addDialogVisible: false,
            editDialogVisible: false,
            selectedRecipient: null,
            options: {
                queryString: null,
                sortBy: 'recipientId',
                sortDir: 'desc',
                page: 0,
                pageSize: 20,
                playerNbr: this.playerNbr,
            },
        };
    },
    mounted() {
        this.refresh();
    },
    computed: {
        ...mapGetters('messageRecipients', ['recipients', 'loadingRecipients', 'loadingError']),
    },
    methods: {
        ...mapActions('messageRecipients', ['findRecipients', 'createRecipient', 'updateRecipient', 'deleteRecipient']),
        onChange() {},
        refresh() {
            let params = {
                self: this,
                fRetrieve: this.findRecipients,
                options: this.options,
                dtosName: 'Recipients',
            };            
            retrieveDtos(params);
        },
        openAddDialog() {
            this.selectedRecipient = new MessageRecipient({
                playerId: this.playerNbr,
                contactType: 'email',
            });
            this.addDialogVisible = true;
        },
        onItemClick(item, index) {
            this.selectedRecipient = new MessageRecipient(item);
            this.editDialogVisible = true;
        },
        addItem() {
            let params = {
                self: this,
                fCreate: this.createRecipient,
                dto: this.selectedRecipient,
                dtoName: 'Recipient',
                callback: this.saveCallback,
            };
            createConfirmDto(params);
        },
        editItem() {
            let params = {
                self: this,
                fUpdate: this.updateRecipient,
                dto: this.selectedRecipient,
                dtoName: 'Recipient',
                callback: this.saveCallback,
            };
            updateConfirmDto(params);
        },
        deleteItem() {
            let params = {
                self: this,
                fDelete: this.deleteRecipient,
                vConfirm: this.selectedRecipient.data.relLastName,
                dto: this.selectedRecipient,
                dtoName: 'Recipient',
                vConfirmName: 'Last Name',
                callback: this.saveCallback,
            };
            deleteConfirmDto(params);
        },
        resetSearch() {
            this.options.queryString = null;
            this.refresh();
        },
        resetPage() {
            this.options.page = 0;
            this.refresh();
        },
        setPageSize(size) {
            this.options.pageSize = size;
            this.refresh();
        },
        reset() {
            this.options.queryString = null;
            this.options.page = 0;
            this.refresh();
        },
        prevPage() {
            this.options.page--;
            this.refresh();
        },
        nextPage() {
            this.options.page++;
            this.refresh();
        },
        clearError() {
            this.requestError = null;
        },
        gotoNextItem() {},
        gotoPrevItem() {},
        saveCallback() {
            this.refresh();
            this.requestError = null;
            this.addDialogVisible = false;
            this.editDialogVisible = false;
        },
        note() {
            return (
                'As the PlayerZone account holder, the player is responsible for registering new contacts and selecting ' +
                'which type of message the specified contact will receive. Contacts that do not want to receive messages ' +
                'are not able to manage the type of message they receive or turn the messaging system off on their own. The ' +
                'PlayerZone account holder needs to make the changes within his account settings'
            );
        },
    },
};
</script>


<style scoped>
.input-search {
    max-width: 512px;
}
.table-container {
    max-width: 80%;
}
</style>
