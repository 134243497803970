import { Model, isValid } from './model';
import { icoCountries } from '../utils/country-codes';

export class Ranking extends Model {
    isValidForAdd() {
        return isValid(Ranking.schemaAdd, this);
    }

    isValidForEdit() {
        return isValid(Ranking.schema, this);
    }
}

Ranking.layout = {
    children: [
        {
            children: ['playerId', 'fullName', 'nationality', 'rankDate'],
        },
        {
            title: 'Singles',
            children: ['rankSingles', 'pointsSingles', 'rankSinglesCd'],
        },
        {
            title: 'Doubles',
            children: ['rankDoubles', 'pointsDoubles', 'rankDoublesCd'],
        },
        {
            title: 'Championship Singles',
            children: ['rankChampionshipSingles', 'pointsChampionshipSingles', 'rankChampionshipSinglesCd'],
        },
        {
            title: 'Championship Doubles',
            children: ['rankChampionshipDoubles', 'pointsChampionshipDoubles', 'rankChampionshipDoublesCd'],
        },
    ],
};

Ranking.layoutAdd = {
    children: [
        {
            children: ['playerId', 'rankDate'],
        },
        {
            title: 'Singles',
            children: ['rankSingles', 'pointsSingles', 'rankSinglesCd'],
        },
        {
            title: 'Doubles',
            children: ['rankDoubles', 'pointsDoubles', 'rankDoublesCd'],
        },
        {
            title: 'Championship Singles',
            children: ['rankChampionshipSingles', 'pointsChampionshipSingles', 'rankChampionshipSinglesCd'],
        },
        {
            title: 'Championship Doubles',
            children: ['rankChampionshipDoubles', 'pointsChampionshipDoubles', 'rankChampionshipDoublesCd'],
        },
    ],
};

Ranking.schema = {
    fields: {
        playerId: { type: 'number', displayName: 'Player Id', readonly: true, sortable: true, required: true },
        firstName: { type: 'string', displayName: 'FirstName', readonly: true },
        lastName: { type: 'string', displayName: 'LastName', readonly: true },
        nationality: { type: 'nationality', displayName: 'Nationality', lookup: Object.keys(icoCountries), readonly: true, sortable: true },
        rankDate: { type: 'date', displayName: 'Rank Date', readonly: true, required: true },
        rankSingles: { type: 'number', displayName: 'Rank Singles', sortable: true },
        rankSinglesCd: { type: 'string', displayName: 'Rank Singles Cd' },
        rankDoubles: { type: 'number', displayName: 'Rank Doubles', sortable: true },
        rankDoublesCd: { type: 'string', displayName: 'Rank Doubles Cd' },
        rankChampionshipSingles: { type: 'number', displayName: 'Rank Championship Singles', sortable: true },
        rankChampionshipSinglesCd: { type: 'string', displayName: 'Rank Championship Singles Cd' },
        rankChampionshipDoubles: { type: 'number', displayName: 'Rank Championship Doubles' },
        rankChampionshipDoublesCd: { type: 'string', displayName: 'Rank Championship Doubles Cd' },
        pointsSingles: { type: 'number', displayName: 'Points Singles', sortable: true },
        pointsDoubles: { type: 'number', displayName: 'Points Doubles', sortable: true },
        pointsChampionshipSingles: { type: 'number', displayName: 'Points Championship Singles', sortable: true },
        pointsChampionshipDoubles: { type: 'number', displayName: 'Points Championship Doubles' },
        fullName: { type: 'string', displayName: 'Player', readonly: true },
    },
};

Ranking.schemaAdd = {
    fields: {
        playerId: { type: 'player', displayName: 'Player Id', sortable: true, required: true },
        firstName: { type: 'string', displayName: 'FirstName', readonly: true },
        lastName: { type: 'string', displayName: 'LastName', readonly: true },
        nationality: { type: 'nationality', displayName: 'Nationality', lookup: Object.keys(icoCountries), readonly: true },
        rankDate: { type: 'date', displayName: 'Rank Date', required: true },
        rankSingles: { type: 'number', displayName: 'Rank Singles', sortable: true },
        rankSinglesCd: { type: 'string', displayName: 'Rank Singles Cd' },
        rankDoubles: { type: 'number', displayName: 'Rank Doubles', sortable: true },
        rankDoublesCd: { type: 'string', displayName: 'Rank Doubles Cd' },
        rankChampionshipSingles: { type: 'number', displayName: 'Rank Championship Singles' },
        rankChampionshipSinglesCd: { type: 'string', displayName: 'Rank Championship Singles Cd' },
        rankChampionshipDoubles: { type: 'number', displayName: 'Rank Championship Doubles' },
        rankChampionshipDoublesCd: { type: 'string', displayName: 'Rank Championship Doubles Cd' },
        pointsSingles: { type: 'number', displayName: 'Points Singles', sortable: true },
        pointsDoubles: { type: 'number', displayName: 'Points Doubles', sortable: true },
        pointsChampionshipSingles: { type: 'number', displayName: 'Points Championship Singles' },
        pointsChampionshipDoubles: { type: 'number', displayName: 'Points Championship Doubles' },
        fullName: { type: 'string', displayName: 'Player', readonly: true },
    },
};
