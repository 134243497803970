<template>
    <div>
        <div class="flex-row mt-16 mb-32">
            <h2>ITF Player Links</h2>
            <span class="spacer"></span>
            <el-checkbox v-model="options.onlyInsecureItfPlayerLinks"
                         class="mr-16"
                         @change="resetPage">Show only Insecure Links
            </el-checkbox>
            <el-input v-model="options.queryString"
                      class="input-search mr-16 ml-32"
                      placeholder="Filter by WTA Id or Name"
                      @change="resetPage"
                      @keydown.esc.native="reset"
            >
                <el-button slot="append" icon="el-icon-search" @click="resetPage"></el-button>
            </el-input>
        </div>

        <ErrorDisplay :requestError="this.loadingErrorItfPlayerLinks" @closed="clearError"/>
        <div v-loading="loadingItfPlayerLinks">
            <DataTable
                    :autoSort="false"
                    :fields="[
                            'itfPlayerNbr',
                            'itfNameLast',
                            'itfNameFirst',
                            'itfNatlCod',
                            'itfBirthDate',
                            'playerNbr',
                            'wtaNameLast',
                            'wtaNameFirst',
                            'wtaNationality',
                            'wtaBirthDate',
                    ]"
                    :items="itfPlayerLinks.data"
                    :schema="schema"
                    :sortDir="options.sortDir"
                    :sortField="options.sortBy"
                    @header-clicked="setSort"
            />

            <Paginator
                    v-if="itfPlayerLinks.data"
                    :data="itfPlayerLinks"
                    :elementsPerPage="options.pageSize"
                    @prev-page="prevPage"
                    @next-page="nextPage"
                    @set-page-size="setPageSize"
            />
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { ItfPlayerLink } from '@/models/itf-player-link';
import { retrieveDtos } from '@/utils/crud-confirm';

export default {
    data() {
        return {
            schema: ItfPlayerLink.schema,
            options: {
                queryString: null,
                sortDir: "ascending",
                sortBy: null,
                page: 0,
                pageSize: 20,
                onlyInsecureItfPlayerLinks: false,
            },
        };
    },
    mounted() {
        this.search();
    },
    computed: mapGetters('itfPlayerLinks', ['itfPlayerLinks', 'loadingItfPlayerLinks', 'loadingErrorItfPlayerLinks']),
    methods: {
        ...mapActions('itfPlayerLinks', ['retrieveItfPlayerLinks', 'updateItfPlayerLink', 'deleteItfPlayerLink']),
        search() {
            let params = {
                self: this,
                fRetrieve: this.retrieveItfPlayerLinks,
                options: this.options,
                dtosName: 'ITF Player Links',
            };
            retrieveDtos(params);
        },
        resetSearch() {
            this.options.queryString = null;
            this.search();
        },
        setPageSize(size) {
            this.options.pageSize = size;
            this.search();
        },
        resetPage() {
            this.options.page = 0;
            this.search();
        },
        reset() {
            this.options.queryString = null;
            this.options.page = 0;
            this.search();
        },
        setSort(event) {
            this.options.sortBy = event.property;
            this.options.sortDir = event.order;
            this.search();
        },
        prevPage() {
            this.options.page--;
            this.search();
        },
        nextPage() {
            this.options.page++;
            this.search();
        },
        clearError() {
            this.loadingErrorItfPlayerLinks = null;
        },
    },
};
</script>

<style scoped>
.input-search {
    max-width: 512px;
}
</style>
