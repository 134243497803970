var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('DialogAddItem',{attrs:{"visibleSync":_vm.addDialogVisible,"dtoName":"Team Points","selected":_vm.selectedTeamRanking,"schema":_vm.schemaAdd,"layout":_vm.layoutAdd},on:{"save":_vm.saveChanges,"change":_vm.onChange,"close":function($event){_vm.addDialogVisible = false}}}),_c('DialogEditItem',{attrs:{"visibleSync":_vm.editDialogVisible,"dtoName":"Team Points","selected":_vm.selectedTeamRanking,"schema":_vm.schema,"layout":_vm.layout},on:{"save":_vm.saveChanges,"change":_vm.onChange,"delete":_vm.deleteTeamPoints,"close":function($event){_vm.editDialogVisible = false}}}),_c('ErrorDisplay',{attrs:{"requestError":this.loadingError},on:{"closed":_vm.clearError}}),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loadingRanking),expression:"loadingRanking"}]},[_c('DataTable',{attrs:{"items":_vm.teamRankings.data,"schema":_vm.schema,"fields":[
                'player1Id',
                'player1ConcatName',
                'player1Nationality',
                'player2Id',
                'player2ConcatName',
                'player2Nationality',
                'tournamentCount',
                'points',
            ],"autoSort":false},on:{"item-clicked":_vm.selectTeamPoints,"header-clicked":_vm.setSort}}),(_vm.teamRankings.data)?_c('Paginator',{attrs:{"data":_vm.teamRankings,"elementsPerPage":_vm.options.pageSize},on:{"prev-page":_vm.prevPage,"next-page":_vm.nextPage,"set-page-size":_vm.setPageSize}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }