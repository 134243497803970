<template>
    <div>
        <h3 v-if="layout.title">{{ layout.title }}</h3>
        <div v-for="(group, i) in layout.children" :key="i">
            <h4 class="group-title" v-if="group.title">{{ group.title }}</h4>
            <div :class="group.id ? group.id + ' flex-row mb-4' : 'flex-row mb-4'">
                <template v-for="(control, j) in group.children">
                    <div
                        :key="j"
                        v-if="control.type === 'spacer'"
                        :style="{ flex: control.width || 1, 'padding-right': control.noPadding ? '0' : 16 * (control.width || 1) + 'px' }"
                        v-text="control.text ? control.text : ''"
                        :class="control.class || ''"
                    ></div>
                    <FormControl
                        v-else
                        class="dynamic-control"
                        :style="{ flex: control.width || 1, 'padding-right': control.noPadding ? '0' : (16 * (control.width || 1) )+ 'px' , 'min-height': '63.5px'}"
                        :key="j"
                        :model="model"
                        :schema="schema"
                        :fieldName="control.id ? control.id : control"
                        :ageAt="ageAt"
                        :rankAt="rankAt"
                        :readonlyForEdit="readonlyForEdit"
                        :allReadonly="allReadonly"
                        :suppressPlayerForSearch="suppressPlayerForSearch"
                        @change="change"
                        @player-selected="onPlayerSelected"
                    />
                </template>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'FormGroup',
    props: ['schema', 'layout', 'model', 'ageAt', 'rankAt', 'readonlyForEdit', 'allReadonly', 'suppressPlayerForSearch'],
    mounted() {
        if (true) return;
        console.log(this.schema);
        console.log(this.layout);
        console.log(this.model);
        console.log(this.ageAt);
        console.log(this.rankAt);
        console.log(this.readonlyForEdit);
        console.log(this.allReadonly);
    },
    methods: {
        change(field) {
            this.$emit('change', { field, value: this.model.data[field] });
        },
        onPlayerSelected(player) {
            this.$emit('player-selected', player);
        }
    },
};
</script>

<style scoped lang="scss">
.flex-row {
    display: flex;
}

.dynamic-control {
    padding-right: 16px;
    align-self: flex-start;
    overflow: hidden;
}

.group-title {
    margin-top: 24px;
    margin-bottom: 8px;
}
</style>
