var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"flex-row mt-16 mb-32"},[_c('h2',[_vm._v("ITF Player Links")]),_c('span',{staticClass:"spacer"}),_c('el-checkbox',{staticClass:"mr-16",on:{"change":_vm.resetPage},model:{value:(_vm.options.onlyInsecureItfPlayerLinks),callback:function ($$v) {_vm.$set(_vm.options, "onlyInsecureItfPlayerLinks", $$v)},expression:"options.onlyInsecureItfPlayerLinks"}},[_vm._v("Show only Insecure Links ")]),_c('el-input',{staticClass:"input-search mr-16 ml-32",attrs:{"placeholder":"Filter by WTA Id or Name"},on:{"change":_vm.resetPage},nativeOn:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;return _vm.reset.apply(null, arguments)}},model:{value:(_vm.options.queryString),callback:function ($$v) {_vm.$set(_vm.options, "queryString", $$v)},expression:"options.queryString"}},[_c('el-button',{attrs:{"slot":"append","icon":"el-icon-search"},on:{"click":_vm.resetPage},slot:"append"})],1)],1),_c('ErrorDisplay',{attrs:{"requestError":this.loadingErrorItfPlayerLinks},on:{"closed":_vm.clearError}}),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loadingItfPlayerLinks),expression:"loadingItfPlayerLinks"}]},[_c('DataTable',{attrs:{"autoSort":false,"fields":[
                        'itfPlayerNbr',
                        'itfNameLast',
                        'itfNameFirst',
                        'itfNatlCod',
                        'itfBirthDate',
                        'playerNbr',
                        'wtaNameLast',
                        'wtaNameFirst',
                        'wtaNationality',
                        'wtaBirthDate',
                ],"items":_vm.itfPlayerLinks.data,"schema":_vm.schema,"sortDir":_vm.options.sortDir,"sortField":_vm.options.sortBy},on:{"header-clicked":_vm.setSort}}),(_vm.itfPlayerLinks.data)?_c('Paginator',{attrs:{"data":_vm.itfPlayerLinks,"elementsPerPage":_vm.options.pageSize},on:{"prev-page":_vm.prevPage,"next-page":_vm.nextPage,"set-page-size":_vm.setPageSize}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }