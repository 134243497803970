<template>
    <el-aside width="246px">
        <el-menu
            :default-active="currentUrl"
            :default-openeds="['/ranking', '/acceptance-list', '/weekly-procedures']"
            active-text-color="#444"
            class="main-menu"
            mode="vertical"
            text-color="#666"
            @select="navigate"
        >
            <!-- <el-menu-item index="/">
                Home
            </el-menu-item> -->

            <el-menu-item class="weight600" index="/players">
                <template slot="title">
                    <i class="el-icon-user"></i>
                    <span>Players</span>
                </template>
            </el-menu-item>

            <el-menu-item class="weight600" index="/tournaments">
                <template slot="title">
                    <i class="el-icon-trophy-1"></i>
                    <span>Tournaments</span>
                </template>
            </el-menu-item>

            <el-divider></el-divider>

            <el-submenu index="/ranking">
                <template slot="title">
                    <i class="el-icon-medal-1"></i>
                    <span>Show Ranking</span>
                </template>
                <el-menu-item index="/ranking/current">Current Ranking</el-menu-item>
                <el-menu-item index="/ranking/by-date">Ranking History</el-menu-item>
                <el-menu-item index="/ranking/summaries">Ranking Summaries</el-menu-item>
                <el-menu-item index="/ranking/special-ranking">Special Rankings</el-menu-item>
            </el-submenu>

            <el-divider></el-divider>

            <el-submenu index="/shadow-ranking">
                <template slot="title">
                    <i class="el-icon-medal"></i>
                    <span>Shadow Ranking</span>
                </template>
                <el-menu-item index="/shadow-ranking-current">Current Shadow Ranking</el-menu-item>
                <el-menu-item index="/shadow-ranking-by-date">Shadow Ranking History</el-menu-item>
                <el-menu-item index="/shadow-ranking-summaries">Shadow Ranking Summaries</el-menu-item>
            </el-submenu>

            <el-divider></el-divider>

            <el-submenu index="/acceptance-list">
                <template slot="title">
                    <i class="el-icon-notebook-2"></i>
                    <span>Acceptance list</span>
                </template>

                <el-menu-item index="/acceptance-list">Show by tournament</el-menu-item>

                <el-menu-item index="/acceptance-list/procedures">
                    Execute procedures
                    <span class="spacer"></span>
                    <i v-if="runningProcedure" class="el-icon-loading"></i>
                </el-menu-item>
            </el-submenu>

            <el-divider></el-divider>

            <el-submenu index="/weekly-procedures">
                <template slot="title">
                    <i class="el-icon-wind-power"></i>
                    <span>Calc Ranking</span>
                    <span class="spacer"></span>
                    <i v-if="runningWeeklyProcedure || runningShadowProcedure" class="el-icon-loading"></i>
                </template>
                <el-menu-item index="/ranking-procedures">Weekly Procedures</el-menu-item>
                <el-menu-item index="/ranking/required-tournaments">Required Tournaments</el-menu-item>
                <el-menu-item index="/shadow-rankings">Shadow Rankings</el-menu-item>
                <el-menu-item index="/ranking/corona">Better of Ranking</el-menu-item>
            </el-submenu>

            <el-divider></el-divider>

            <el-menu-item class="weight600" index="/player-development">
                <template slot="title">
                    <i class="el-icon-user"></i>
                    <span>Player Development</span>
                    <span class="spacer"></span>
                    <i v-if="updatingEligibilityDates" class="el-icon-loading"></i>
                </template>
            </el-menu-item>

            <el-menu-item class="weight600" index="/age-eligibility">
                <template slot="title">
                    <i class="el-icon-user"></i>
                    <span>Age Eligibility</span>
                    <span class="spacer"></span>
                    <i v-if="computingAgeEligibilityTrackings" class="el-icon-loading"></i>
                </template>
            </el-menu-item>

            <el-divider></el-divider>

            <el-menu-item class="weight600" index="/sys-codes">
                <template slot="title">
                    <i class="el-icon-set-up"></i>
                    <span>System Table</span>
                </template>
            </el-menu-item>

            <el-menu-item class="weight600" index="/tournament-prizes">
                <template slot="title">
                    <i class="el-icon-coin"></i>
                    <span>Tournament Prizes</span>
                </template>
            </el-menu-item>

            <el-menu-item class="weight600" index="/tournament-points">
                <template slot="title">
                    <i class="el-icon-medal-1"></i>
                    <span>Tournament Points</span>
                </template>
            </el-menu-item>

            <el-menu-item class="weight600" index="/wta-tour-years">
                <template slot="title">
                    <i class="el-icon-date"></i>
                    <span>WTA Tour Years</span>
                </template>
            </el-menu-item>

            <el-menu-item index="/wta-player-zone-admin" class="weight600">
                <template slot="title">
                    <i class="el-icon-setting"></i>
                    <span>WTA Player Zone Admin</span>
                </template>
            </el-menu-item>

            <el-menu-item index="/player-change-requests/admin" class="weight600">
                <template slot="title">
                    <i class="el-icon-setting"></i>
                    <span>Player Change Requests</span>
                </template>
            </el-menu-item>

            <el-submenu index="/payment-report">
                <template slot="title">
                    <i class="el-icon-document"></i>
                    <span>Reports</span>
                </template>
                <el-menu-item index="/payment-reports">Payments Report</el-menu-item>
                <el-menu-item v-if="accessBankingReport" index="/banking-data-report">Banking Data Report</el-menu-item>
            </el-submenu>
        </el-menu>
    </el-aside>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    computed: {
        ...mapGetters('rankingProcedure', ['runningRankingProcedure']),
        ...mapGetters('tournamentRounds', ['runningTournRoundSplit']),
        ...mapGetters('playerStats', ['runningUpdateYtdTotals']),
        ...mapGetters('dataExporter', ['runningWeeklyDataExport']),
        ...mapGetters('procedure', ['runningProcedure']),
        ...mapGetters('playerDevelopments', ['updatingEligibilityDates']),
        ...mapGetters('ageEligibilityTrackings', ['computingAgeEligibilityTrackings']),
        ...mapGetters('shadowRankings', ['runningShadowRankingProcedure', 'creatingShadowTournamentResults']),

        ...mapGetters('bankingData', {
            accessBankingReport: 'access',
        }),

        runningWeeklyProcedure() {
            return this.runningRankingProcedure || this.runningUpdateYtdTotals || this.runningWeeklyDataExport || this.runningTournRoundSplit;
        },
        runningShadowProcedure() {
            return this.runningShadowRankingProcedure || this.creatingShadowTournamentResults;
        },
    },
    data() {
        return {
            currentUrl: this.$route.path,
        };
    },
    methods: {
        navigate(url) {
            this.$router.push(url).catch((error) => {});
        },
    },
};
</script>

<style lang="scss">
.main-menu {
    padding-top: 8px;
}

.el-menu {
    border-right: none !important;
}

.el-menu-item {
    font-size: 16px;
    display: flex;
    align-items: center;

    &.is-active {
        background-color: rgba(0, 0, 0, 0.2) !important;
    }
}

.el-submenu__title {
    font-weight: 600;
}

.el-divider--horizontal {
    margin: 16px 0 !important;
}
</style>
