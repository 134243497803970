import { render, staticRenderFns } from "./RankingSummaries.vue?vue&type=template&id=ccbfb8ce&scoped=true"
import script from "./RankingSummaries.vue?vue&type=script&lang=js"
export * from "./RankingSummaries.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ccbfb8ce",
  null
  
)

export default component.exports