<template>
    <div>
        <DialogAddItem
            :visibleSync="addDialogVisible"
            dtoName="Round Result"
            :selected="selectedTournamentRound"
            :schema="schemaAdd"
            :layout="layoutAdd"
            :widthPerc="90"
            @save="saveChanges"
            @change="onChange"
            @close="addDialogVisible = false"
        />

        <DialogEditItem
            :visibleSync="editDialogVisible"
            dtoName="Round Result"
            :selected="selectedTournamentRound"
            :schema="schema"
            :layout="layout"
            :widthPerc="90"
            :ageAt="startDate"
            :rankAt="pointsRankDate"
            showNav="true"
            @save="saveChanges"
            @change="onChange"
            @delete="deleteCurrentTournamentRound"
            @close="onClose"
            @prev="gotoPrevTournamentRound"
            @next="gotoNextTournamentRound"
        />

        <RoundImport
            :visibleSync="importVisible"
            :tournNbr="this.tournamentId"
            :tournYear="this.tournamentYear"
            @close="onImportClose"
            @success="onImportSuccess"
        />

        <ErrorDisplay :requestError="this.requestError" @closed="clearError" />

        <div class="flex-row mt-0 mb-8">
            <el-radio-group v-model="sDFlag" size="mini" class="mr-32">
                <el-radio-button label="SD">All</el-radio-button>
                <el-radio-button label="S">Singles</el-radio-button>
                <el-radio-button label="D">Doubles</el-radio-button>
            </el-radio-group>

            <el-radio-group v-model="qMFlag" size="mini" class="mr-32">
                <el-radio-button label="QM">All</el-radio-button>
                <el-radio-button label="Q">Quali</el-radio-button>
                <el-radio-button label="M">Main</el-radio-button>
            </el-radio-group>

            <span class="spacer"></span>

            <el-input
                size="mini"
                placeholder="Filter by Team or Round name"
                v-model="queryString"
                class="input-search mr-32"
                @keydown.esc.native="queryString = ''"
                :clearable="true"
            >
            </el-input>

            <span class="spacer"></span>

            <el-button @click="reset" size="mini" plain type="primary">Reset</el-button>
            <el-button v-if="!isBaselineReader" @click="createEmptyRounds" size="mini" plain type="primary">Add ALL round results</el-button>
            <el-button v-if="!isBaselineReader" @click="addTournamentRounds" size="mini" plain type="primary">Add round result</el-button>
            <el-button @click="exportCsv" plain class="ml-8" size="mini">Export CSV</el-button>
        </div>
        <div class="flex-row mt-0 mb-8">
            <span class="spacer"></span>
            <el-button v-if="!isBaselineReader" @click="importVisible = true" size="mini" plain type="primary">Import rounds</el-button>
            <el-button v-if="!isBaselineReader" @click="dataPropagation" size="mini" plain type="primary">Populate round data</el-button>
            <el-button @click="dataCheckClicked" size="mini" plain type="primary">Check round data</el-button>
            <el-button v-if="!isBaselineReader" @click="deleteRoundResults" size="mini" plain type="danger">Delete ALL round results </el-button>
            <el-button v-if="!isBaselineReader" @click="deleteAllTournamentRounds" size="mini" plain type="danger">Delete ALL results </el-button>
        </div>

        <DataTable
            v-loading="loadingTournamentRounds"
            :items="filteredRounds()"
            :fields="[
                'roundName',
                'numberOfMatchInRound',
                'qpmFlag',
                'sdFlag',
                'team1TeamName',
                'team2TeamName',
                'matchWinner',
                'scores',
                'reasonCode',
                'seed1',
                'team1TeamEntryType',
                'seed2',
                'team2TeamEntryType',
            ]"
            :schema="schema"
            @item-clicked="itemClicked"
            :autoSort="false"
            :key="componentKey"
        />
    </div>
</template>

<script>
import { TournamentRound } from '../models/tournament-round';
import { mapGetters, mapActions } from 'vuex';
import FileSaver from 'file-saver';
import { csvExporter } from '../utils/csv-exporter';
import moment from 'moment';
import { retrieveDtos, deleteConfirmDto, createConfirmDto, updateConfirmDto } from '../utils/crud-confirm';
import { isBaselineReader } from '../services/authGroups';

export default {
    props: ['tournamentId', 'tournamentYear', 'startDate', 'pointsRankDate'],
    data() {
        return {
            schema: TournamentRound.schema,
            schemaAdd: TournamentRound.schemaAdd,
            layout: TournamentRound.layout,
            layoutAdd: TournamentRound.layoutAdd,
            addDialogVisible: false,
            editDialogVisible: false,
            importVisible: false,
            selectedTournamentRound: null,
            tournamentRoundsPointer: null,
            mode: null,
            qMFlag: 'QM',
            sDFlag: 'SD',
            queryString: '',
            requestError: null,
            componentKey: 0,
            dataImported: false,
            isBaselineReader: isBaselineReader(),
        };
    },
    mounted() {
        this.refresh();
    },
    computed: {
        ...mapGetters('tournamentRounds', ['tournamentRounds', 'loadingTournamentRounds', 'loadingError']),
    },
    methods: {
        exportCsv() {
            const data = csvExporter(this.schema, this.tournamentRounds);
            const file = new File([data], `tourn_rounds_${moment().format('DD_MM_YYYY HH_mm_SS')}.csv`, { type: 'text/csv' });
            FileSaver.saveAs(file);
        },
        ...mapActions('tournamentRounds', [
            'findTournamentRounds',
            'createTournamentRound',
            'updateTournamentRound',
            'deleteTournamentRound',
            'deleteTournamentRounds',
            'deleteAllRoundResults',
            'propagateData',
            'checkTournamentRoundsData',
            'setTournamentRoundAtIndex',
            'createEmptyTournamentRounds',
        ]),
        filteredRounds() {
            if (!this.tournamentRounds) {
                return [];
            }

            return this.tournamentRounds.filter(
                (r) =>
                    (!this.queryString ||
                        !r.team1TeamName ||
                        r.team1TeamName.toLowerCase().includes(this.queryString.toLowerCase()) ||
                        !r.team2TeamName ||
                        r.team2TeamName.toLowerCase().includes(this.queryString.toLowerCase())) &&
                    (this.qMFlag === 'QM' || r.qpmFlag === this.qMFlag) &&
                    (this.sDFlag === 'SD' || r.sdFlag === this.sDFlag),
            );
        },
        createEmptyRounds() {
            this.$confirm('This will create empty Round Results for manual input. Please confirm.', {
                confirmButtonText: 'Create empty Round Results',
                cancelButtonText: 'Cancel',
                type: 'warning',
            }).then(() => {
                this.createEmptyTournamentRounds({ season: this.tournamentYear, tournamentId: this.tournamentId })
                    .then(() => {
                        this.$notify({
                            title: 'Create empty Round Results',
                            message: 'Empty Round Results successfully created',
                            type: 'success',
                            duration: 5000,
                        });
                        this.refresh();
                    })
                    .catch((error) => {
                        this.requestError = error;
                    });
            });
        },
        dataCheckClicked() {
            this.$confirm('This will check Round Results data. Please confirm.', {
                confirmButtonText: 'Check Round Result data',
                cancelButtonText: 'Cancel',
                type: 'warning',
            }).then(() => {
                this.checkTournamentRoundsData({ season: this.tournamentYear, tournamentId: this.tournamentId })
                    .then(() => {})
                    .catch((error) => {
                        this.requestError = error;
                    });
            });
        },
        dataPropagation() {
            this.$confirm('This will populate data into Round Results. Please confirm.', {
                confirmButtonText: 'Populate Round Result data',
                cancelButtonText: 'Cancel',
                type: 'warning',
            }).then(() => {
                this.propagateData({ season: this.tournamentYear, tournamentId: this.tournamentId }).catch((error) => {
                    this.requestError = error;
                });
            });
        },
        refresh() {
            let options = {
                id: this.tournamentId,
                year: this.tournamentYear,
            };
            let params = {
                self: this,
                fRetrieve: this.findTournamentRounds,
                options: options,
                dtosName: 'Tournament Rounds',
            };
            retrieveDtos(params);
        },
        reset() {
            this.sDFlag = 'SD';
            this.qMFlag = 'QM';
            this.queryString = '';
            this.refresh();
        },
        onImportSuccess() {
            this.dataImported = true;
            this.refresh();
        },
        onImportClose() {
            if (this.dataImported) {
                this.propagateData({ season: this.tournamentYear, tournamentId: this.tournamentId }).catch((error) => {
                    this.requestError = error;
                });
            }
            this.dataImported = false;
            this.importVisible = false;
        },
        itemClicked(item, index) {
            this.selectedTournamentRound = new TournamentRound(item);
            this.mode = 'edit';
            this.editDialogVisible = true;
            this.tournamentRoundsPointer = index;
        },
        gotoPrevTournamentRound() {
            if (this.tournamentRoundsPointer == 0) {
                return;
            }
            this.tournamentRoundsPointer -= 1;
            this.selectedTournamentRound = new TournamentRound(this.tournamentRounds[this.tournamentRoundsPointer]);
        },
        gotoNextTournamentRound() {
            if (this.tournamentRoundsPointer == this.tournamentRounds.length - 1) {
                return;
            }
            this.tournamentRoundsPointer += 1;
            this.selectedTournamentRound = new TournamentRound(this.tournamentRounds[this.tournamentRoundsPointer]);
        },
        addTournamentRounds() {
            this.selectedTournamentRound = new TournamentRound({
                year: this.tournamentYear,
                tournamentId: this.tournamentId,
            });
            this.mode = 'add';
            this.addDialogVisible = true;
        },
        onChange() {},
        saveChanges() {
            let round = this.selectedTournamentRound.data;
            let wSets = new Array(5);
            let lSets = new Array(5);
            let wTies = new Array(5);
            let lTies = new Array(5);
            for (let i = 0; i < 5; i++) {
                wSets[i] = parseInt(round['set' + (i + 1) + 'ScoreWinner']);
                lSets[i] = parseInt(round['set' + (i + 1) + 'ScoreLoser']);
                wTies[i] = parseInt(round['tie' + (i + 1) + 'ScoreWinner']);
                lTies[i] = parseInt(round['tie' + (i + 1) + 'ScoreLoser']);
            }
            round.scores = '';
            for (var i = 0; i < wSets.length; i++) {
                var wSet = wSets[i];
                var lSet = lSets[i];
                var wTie = wTies[i];
                var lTie = lTies[i];
                if ((wSet == 0 && lSet == 0) || isNaN(wSet) || isNaN(lSet)) {
                    break;
                }
                if (i > 0) {
                    round.scores += '  ';
                }
                round.scores += wSet + '-' + lSet;
                if ((wTie == 0 && lTie == 0) || isNaN(wTie) || isNaN(lTie)) {
                    continue;
                }
                round.scores += '(' + Math.min(wTie, lTie) + ')';
            }
            if (round.matchWinner == 1) {
                round.set1Score1 = round.set1ScoreWinner;
                round.set1Score2 = round.set1ScoreLoser;
                round.set2Score1 = round.set2ScoreWinner;
                round.set2Score2 = round.set2ScoreLoser;
                round.set3Score1 = round.set3ScoreWinner;
                round.set3Score2 = round.set3ScoreLoser;
                round.set4Score1 = round.set4ScoreWinner;
                round.set4Score2 = round.set4ScoreLoser;
                round.set5Score1 = round.set5ScoreWinner;
                round.set5Score2 = round.set5ScoreLoser;
                round.tie1Score1 = round.tie1ScoreWinner;
                round.tie1Score2 = round.tie1ScoreLoser;
                round.tie2Score1 = round.tie2ScoreWinner;
                round.tie2Score2 = round.tie2ScoreLoser;
                round.tie3Score1 = round.tie3ScoreWinner;
                round.tie3Score2 = round.tie3ScoreLoser;
                round.tie4Score1 = round.tie4ScoreWinner;
                round.tie4Score2 = round.tie4ScoreLoser;
                round.tie5Score1 = round.tie5ScoreWinner;
                round.tie5Score2 = round.tie5ScoreLoser;
            } else if (round.matchWinner == 2) {
                round.set1Score1 = round.set1ScoreLoser;
                round.set1Score2 = round.set1ScoreWinner;
                round.set2Score1 = round.set2ScoreLoser;
                round.set2Score2 = round.set2ScoreWinner;
                round.set3Score1 = round.set3ScoreLoser;
                round.set3Score2 = round.set3ScoreWinner;
                round.set4Score1 = round.set4ScoreLoser;
                round.set4Score2 = round.set4ScoreWinner;
                round.set5Score1 = round.set5ScoreLoser;
                round.set5Score2 = round.set5ScoreWinner;
                round.tie1Score1 = round.tie1ScoreLoser;
                round.tie1Score2 = round.tie1ScoreWinner;
                round.tie2Score1 = round.tie2ScoreLoser;
                round.tie2Score2 = round.tie2ScoreWinner;
                round.tie3Score1 = round.tie3ScoreLoser;
                round.tie3Score2 = round.tie3ScoreWinner;
                round.tie4Score1 = round.tie4ScoreLoser;
                round.tie4Score2 = round.tie4ScoreWinner;
                round.tie5Score1 = round.tie5ScoreLoser;
                round.tie5Score2 = round.tie5ScoreWinner;
            }
            if (this.mode === 'add') {
                let params = {
                    self: this,
                    fCreate: this.createTournamentRound,
                    dto: this.selectedTournamentRound,
                    dtoName: 'Tournament Round',
                    callback: this.addCallback,
                };
                createConfirmDto(params);
            } else {
                let params = {
                    self: this,
                    fUpdate: this.updateTournamentRound,
                    dto: this.selectedTournamentRound,
                    dtoName: 'Tournament Round',
                    callback: this.editCallback,
                };
                updateConfirmDto(params);
            }
        },
        addCallback() {
            this.refresh();
            this.requestError = null;
            this.addDialogVisible = false;
            this.editDialogVisible = false;
        },
        editCallback() {
            this.selectedTournamentRound.data.errors = [];
            this.selectedTournamentRound.data.errorFound = false;
            this.tournamentRounds[this.tournamentRoundsPointer] = this.selectedTournamentRound.data;
            this.componentKey += 1;
        },
        onClose() {
            this.editDialogVisible = false;
            this.componentKey += 1;
        },
        deleteCurrentTournamentRound() {
            let params = {
                self: this,
                fDelete: this.deleteTournamentRound,
                vConfirm: this.selectedTournamentRound.data.tournamentId,
                dto: this.selectedTournamentRound,
                dtoName: 'Round Result',
                vConfirmName: 'Tournament ID',
                callback: this.addCallback,
            };
            deleteConfirmDto(params);
        },
        deleteRoundResults() {
            let data = {
                season: this.tournamentYear,
                tournamentId: this.tournamentId,
            };
            let params = {
                self: this,
                fDelete: this.deleteAllRoundResults,
                vConfirm: this.tournamentId,
                dto: data,
                dtoName: 'ALL Round Results',
                vConfirmName: 'Tournament ID',
                callback: this.addCallback,
            };
            deleteConfirmDto(params);
        },
        deleteAllTournamentRounds() {
            let data = {
                season: this.tournamentYear,
                tournamentId: this.tournamentId,
            };
            let params = {
                self: this,
                fDelete: this.deleteTournamentRounds,
                vConfirm: this.tournamentId,
                dto: data,
                dtoName: 'ALL Round Results and ALL Tournament Results',
                vConfirmName: 'Tournament ID',
                callback: this.deleteAllCallback,
            };
            deleteConfirmDto(params);
        },
        deleteAllCallback() {
            this.refresh();
            this.$emit('deletedAllTournRounds');
        },
        clearError() {
            this.requestError = null;
        },
    },
};
</script>

<style scoped>
.input-search {
    max-width: 384px;
}
</style>
