<template>
    <div class="tournament_info_details">
        <table v-if="mode === 'S'">
            <tr>
                <th>
                    &nbsp;
                </th>
                <th>Draw</th>
                <th>QLF</th>
                <th>Byes</th>
                <th>WC</th>
                <th>SE</th>
                <th style="white-space: nowrap;">DI</th>
            </tr>
            <tr>
                <td style="background: rgb(254,254,198); font-weight: bold">Main Draw</td>
                <td>{{ editionData.single.main.size }}</td>
                <td>{{ editionData.single.main.numberOfQualifieds }}</td>
                <td>{{ editionData.single.main.numberOfByes }}</td>
                <td>{{ editionData.single.main.numberOfWildcards }}</td>
                <td>{{ editionData.single.main.numberOfExemptions }}</td>
                <td>{{ editionData.single.main.numberOfDirects }}</td>
            </tr>
            <tr v-if="editionData.single.qualification">
                <td v-if="editionData.single.qualification.size" style="background: rgb(254,254,198); font-weight: bold">Qualifying</td>
                <td>{{ editionData.single.qualification.size }}</td>
                <td>—</td>
                <td>{{ editionData.single.qualification.numberOfByes }}</td>
                <td>{{ editionData.single.qualification.numberOfWildcards }}</td>
                <td>{{ editionData.single.qualification.numberOfExemptions }}</td>
                <td>{{ editionData.single.qualification.numberOfDirects }}</td>
            </tr>
        </table>

        <table v-if="mode === 'D'">
            <tr>
                <th>
                    <span class="printText">{{ editionData.tournamentId }}</span>
                </th>
                <th>Draw</th>
                <th style="white-space: nowrap;">WC Regular</th>
                <th style="white-space: nowrap;">WC Exempt</th>
                <th>Byes</th>
                <th v-if="!editionData.double.qualification" style="white-space: nowrap;">Advanced In</th>
                <th v-if="!editionData.double.qualification" style="white-space: nowrap;">On Site In S/D-Rank</th>
            </tr>
            <tr>
                <td style="background: rgb(254,254,198); font-weight: bold">Main</td>
                <td>{{ editionData.double.main.size }}</td>
                <td>
                    {{ editionData.double.main.size === 32 ? 2 : editionData.double.main.size === 28 ? 2 : editionData.double.main.size === 16 ? 1 : 0 }}
                </td>
                <td>1</td>
                <td>{{ editionData.double.main.numberOfByes }}</td>
                <td>{{ editionData.double.main.numberOfDirects }}</td>
                <td>
                    {{ editionData.double.main.size === 32 ? 3 : editionData.double.main.size === 28 ? 4 : editionData.double.main.size === 16 ? 3 : 0 }}
                </td>
            </tr>
        </table>

        <table v-if="mode === 'S'" class="cutoffDates">
            <tr>
                <td style="white-space: nowrap; background: rgb(254,254,198); font-weight: bold">Main Draw Cutoff Rank Date</td>
                <td style="white-space: nowrap">{{ mainDrawCutoffRankDate }}</td>
            </tr>
            <tr>
                <td style="background: rgb(254,254,198); font-weight: bold">Qualifying Cutoff Rank Date</td>
                <td>{{ qualiDrawCutoffRankDate }}</td>
            </tr>
        </table>

        <table v-if="mode === 'D'" class="cutoffDates">
            <tr>
                <td colspan="2" style="background: rgb(254,254,198); font-weight: bold">Doubles Cutoff Rank Date</td>
                <td>{{ doublesDrawCutoffRankDate }}</td>
            </tr>
        </table>

        <table class="startDates">
            <tr>
                <td style="white-space: nowrap; background: rgb(254,254,198); font-weight: bold">Main Draw Start Date</td>
                <td style="white-space: nowrap">{{ mainDrawStartDate }}</td>
            </tr>
            <tr>
                <td style="background: rgb(254,254,198); font-weight: bold">Surface</td>
                <td>{{ editionData.surface }}</td>
            </tr>
            <tr>
                <td style="background: rgb(254,254,198); font-weight: bold">In/Out</td>
                <td>{{ editionData.setting }}</td>
            </tr>
            <tr>
                <td style="background: rgb(254,254,198); font-weight: bold">Ball</td>
                <td>{{ expandedBallType }}</td>
            </tr>
        </table>
    </div>
</template>
<script>
export default {
    name: 'tournament-info-details',
    props: {
        mainDrawCutoffRankDate: {},
        qualiDrawCutoffRankDate: {},
        mainDrawStartDate: {},
        doublesDrawCutoffRankDate: {},
        editionData: {},
        expandedBallType: {},
        mode: {},
    },
};
</script>
<style>
.AL h3 {
    margin-top: 25px;
}

.tournament_info_details table {
    border-collapse: collapse;
    display: inline-block;
    vertical-align: top;
    margin-right: 5%;
}

.tournament_info_details tr td {
    height: 25px;
    border-top: thin solid rgba(34, 36, 38, 0.1);
}

.tournament_info_details tr th {
    height: 25px;
}

.tournament_info_details th {
    color: #33135b;
    font-weight: 900;
}

.tournament_info_details tr th:first-child {
    border-right: thin solid rgba(34, 36, 38, 0.1);
}

.tournament_info_details tr td:first-child {
    border-right: thin solid rgba(34, 36, 38, 0.1);
    font-weight: 900;
}

.tournament_info_details td,
.tournament_info_details th {
    padding: 0px;
    text-align: center;
}

.cutoffDates {
    border-collapse: collapse;
    display: inline-block;
    vertical-align: top;
    margin-right: 5%;
}

.cutoffDates tr:nth-child(2) td {
    height: 25px;
    border-top: thin solid rgba(34, 36, 38, 0.1);
}

.cutoffDates tr td:first-child {
    font-weight: 900;
    color: #33135b;
    text-align: left;
}

.cutoffDates td,
.cutoffDates th {
    padding: 10px;
    text-align: center;
}

.startDates {
    border-collapse: collapse;
    display: inline-block;
    vertical-align: top;
}

.startDates tr:nth-child(1) td {
    height: 25px;
    border-top: 0;
}

.startDates tr td {
    height: 25px;
    border-top: thin solid rgba(34, 36, 38, 0.1);
}

.startDates tr td:first-child {
    font-weight: 900;
    color: #33135b;
    text-align: left;
}

.startDates td,
.startDates th {
    padding: 10px;
    text-align: center;
}

.list h3 {
    text-align: center;
    line-height: 1.28571429em;
    font-weight: 900;
    font-size: 24px;
}

.tournament_info_general .details span:not(:last-child) {
    margin-right: 10px;
    padding-right: 10px;
    border-right: thin solid #666;
}

.cutoffDates tr:first-child td {
    border-top: none;
}

.tournament_info_details {
    margin-top: 50px;
    display: flex;
    align-items: flex-start;
    justify-content: space-evenly;
    border-style: none;
}

.tournament_info_details .tableWrapper {
    display: inline-block;
}

#allLists h3 {
    margin-top: 50px !important;
}
</style>
