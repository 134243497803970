import axios from 'axios';
import { buildParams } from '../utils/query-param-builder';

export default {
    namespaced: true,

    state: {
        wtaTourYears: [],
        wtaTourYear: null,
        loading: false,
        loadingError: null,
        options: {
            queryString: '',
            sortBy: '',
            sortDir: '',
            pageSize: process.env.VUE_APP_PAGE_SIZE,
            page: 0,
        },
    },

    getters: {
        wtaTourYears: (state) => state.wtaTourYears,
        wtaTourYear: (state) => state.wtaTourYear,
        wtaTourYearsLoadingError: (state) => state.loadingError,
        loadingWtaTourYears: (state) => state.loading,
        wtaTourYearsOptions: (state) => state.options,
    },

    mutations: {
        setWtaTourYears(state, data) {
            state.wtaTourYears = data;
        },
        setWtaTourYear(state, data) {
            state.wtaTourYear = data;
        },
        startLoading(state) {
            state.loading = true;
        },
        stopLoading(state) {
            state.loading = false;
        },
        setLoadingError(state, error) {
            state.loadingError = error;
        },
        clearLoadingError(state) {
            state.loadingError = null;
        },
    },

    actions: {
        async retrieveValidWtaTourYears(context) {
            context.commit('startLoading');
            context.commit('clearLoadingError');
            try {
                var result = await axios.get(`${process.env.VUE_APP_API_URL}/wta-tour-years/valid`);
                context.commit('setWtaTourYears', result.data);
                context.commit('stopLoading');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('stopLoading');
                context.commit('setLoadingError', error);
                return Promise.reject(error);
            }
        },
        async retrieveWtaTourYears(context, options = {}) {
            context.commit('startLoading');
            context.commit('clearLoadingError');
            let params = buildParams(options);
            try {
                var result = await axios.get(`${process.env.VUE_APP_API_URL}/wta-tour-years`, { params });
                context.commit('setWtaTourYears', result.data);
                context.commit('stopLoading');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('stopLoading');
                context.commit('setLoadingError', error);
                return Promise.reject(error);
            }
        },
        async createWtaTourYear(context, data) {
            context.commit('startLoadingRankings');
            context.commit('clearLoadingError');
            try {
                await axios.post(`${process.env.VUE_APP_API_URL}/wta-tour-years`, data.data);
                context.commit('stopLoading');
                return Promise.resolve();
            } catch (error) {
                context.commit('setLoadingError', error);
                context.commit('stopLoading');
                return Promise.reject(error);
            }
        },
        async updateWtaTourYear(context, data) {
            context.commit('startLoadingRankings');
            context.commit('clearLoadingError');
            try {
                await axios.put(`${process.env.VUE_APP_API_URL}/wta-tour-years`, data.data);
                context.commit('stopLoading');
                return Promise.resolve();
            } catch (error) {
                context.commit('setLoadingError', error);
                context.commit('stopLoading');
                return Promise.reject(error);
            }
        },
        async deleteWtaTourYear(context, data) {
            context.commit('startLoadingRankings');
            context.commit('clearLoadingError');
            let tourYear = data.data.tourYear;
            try {
                await axios.delete(`${process.env.VUE_APP_API_URL}/wta-tour-years/${tourYear}`);
                context.commit('stopLoading');
                return Promise.resolve();
            } catch (error) {
                context.commit('setLoadingError', error);
                context.commit('stopLoading');
                return Promise.reject(error);
            }
        },
        setWtaTourYear(context, data) {
            context.commit('setWtaTourYear', data);
        },
    },
};
