import { isValid, Model } from './model';
import { icoCountries } from '../utils/country-codes';

export class SinglesEntry extends Model {
    isValid() {
        return isValid(SinglesEntry.schema, this);
    }
}

SinglesEntry.layout = {
    children: [
        {
            children: ['recordKey', { type: 'spacer', width: 4 }],
        },
        {
            children: ['playerId', 'playerNationality', { type: 'spacer' }],
        },
        {
            children: ['entryMode', 'entryStatus', 'entryDate'],
        },
        {
            children: ['entryPreference', 'within'],
        },
        {
            children: ['entryRank', 'entryType', 'orgStatus'],
        },
        {
            children: ['confirmedPlayer', { type: 'spacer', width: 4 }],
        },
    ],
};

SinglesEntry.schema = {
    fields: {
        recordKey: { displayName: 'Record key', type: 'number', group: 'general', readonly: true },
        tournamentId: { displayName: 'Tournament', type: 'number', group: 'general', required: true },
        season: { displayName: 'Year', type: 'number', group: 'general', required: true },
        playerId: { displayName: 'Player', type: 'player', group: 'general', required: true },
        playerFirstName: { displayName: 'First name', type: 'string', group: 'general', readonly: true },
        playerLastName: { displayName: 'Last name', type: 'string', group: 'general', readonly: true },
        playerFullName: { displayName: 'Full name', type: 'string', group: 'general', readonly: true },
        playerNationality: { displayName: 'Nationality', type: 'nationality', group: 'general', lookup: Object.keys(icoCountries), readonly: true },
        currentSinglesRank: { displayName: 'Singles Rank', type: 'int', group: 'general', readonly: true },
        playerEmail: { displayName: 'Email', type: 'string', group: 'general', readonly: true },
        playerDateOfBirth: { displayName: 'Date of birth', type: 'date', readonly: true },
        playerAgeCurrent: { displayName: 'Current age', type: 'number', readonly: true },
        tournamentStartDate: { displayName: 'Date of birth', type: 'date', readonly: true },
        playerAgeAtTournamentStart: { displayName: 'Player age', type: 'number', readonly: true },
        entryMode: {
            displayName: 'Entry mode',
            type: 'string',
            group: 'general',
            required: true,
            lookup: [
                { id: 'M', displayName: 'Main' },
                { id: 'Q', displayName: 'Quali' },
            ],
        },
        entryType: {
            displayName: 'Entry type',
            type: 'string',
            group: 'general',
            required: true,
            lookup: [
                { id: 'S', displayName: 'Entry Rank is Special Rank' },
                { id: ' ', displayName: 'Entry Rank is Normal Rank' },
            ],
        },
        entryStatus: {
            displayName: 'Entry Status',
            type: 'string',
            group: 'general',
            required: true,
            lookup: [
                { id: 'EN', displayName: 'Entered' },
                { id: 'NE', displayName: 'Not Entered' },
                { id: 'MD', displayName: 'Accepted Into Main Draw' },
                { id: 'QL', displayName: 'Accepted Into Qualification Draw' },
                { id: 'WM', displayName: 'Withdrawn From Main Draw (after deadline)' },
                { id: 'WQ', displayName: 'Withdrawn From Qualification Draw (after deadline)' },
                { id: 'WD', displayName: 'Withdrawn Before Entry Deadline' },
                { id: 'WE', displayName: 'Withdrawn After Entry Deadline' },
                { id: 'OW', displayName: 'Obsolete Withdraw' },
                { id: 'OE', displayName: 'Obsolete Enter' },
            ],
        },
        entryDate: { displayName: 'Entry date', type: 'date', group: 'general' },
        entryPreference: { displayName: 'Entry Preference', type: 'string' },
        within: { displayName: 'Within', type: 'string' },
        entryRank: { displayName: 'Entry Rank', type: 'number' },
        orgStatus: {
            displayName: 'Old Status',
            type: 'string',
            description:
                'This is the old status of the entry. It is very important to determine, where the entry is displayed in the Acceptance List. The status MM is a special one indicating that the entry moved in.',
        },
        confirmedPlayer: { 
            displayName: 'Is Confirmed Exemption Player (C = Yes)',
            clearable: true, 
            type: 'string', 
            lookup: [
                { id: 'C', displayName: "Confirmed Exemption Player" },
            ]
        },
    },
};
