import { isValid, Model } from './model';

export class ItfPlayerLink extends Model {
    constructor(data) {
        if (data && !data.selectedPlayerNbr) {
            data.selectedPlayerNbr = null;
        }
        if (data && !data.selectedNameLast) {
            data.selectedNameLast = null;
        }
        if (data && !data.selectedNameFirst) {
            data.selectedNameFirst = null;
        }
        if (data && !data.selectedNationality) {
            data.selectedNationality = null;
        }
        if (data && !data.selectedBirthDate) {
            data.selectedBirthDate = null;
        }
        super(data);
    }
    isValid() {
        return isValid(ItfPlayerLink.schema, this);
    }
}

ItfPlayerLink.layout = {
    children: [
        {
            children: ['itfPlayerNbr', 'itfNameFirst', 'itfNameLast', 'itfNatlCod', 'itfBirthDate'],
        },
        {
            children: ['playerNbr', 'wtaNameFirst', 'wtaNameLast', 'wtaNationality', 'wtaBirthDate'],
        },
        {
            children: ['isSecureLink', { type: 'spacer', width: 3 }],
        },
    ],
};

ItfPlayerLink.layoutMapPlayer = {
    children: [
        {
            children: ['itfPlayerNbr', 'itfNameFirst', 'itfNameLast', 'itfBirthDate'],
        },
        {
            children: ['selectedPlayerNbr', 'selectedNameFirst', 'selectedNameLast', 'selectedBirthDate'],
        },
    ],
};

ItfPlayerLink.schema = {
    fields: {
        playerNbr: { type: 'player2', displayName: 'WTA Id', required: true, sortable: true, readonly: true, },
        itfPlayerNbr: { type: 'string', displayName: 'ITF Id', clearable: true, sortable: true, readonly: true, },
        itfNameLast: { type: 'string', displayName: 'ITF Last Name', clearable: true, sortable: true, readonly: true, },
        itfNameFirst: {
            type: 'string',
            displayName: 'ITF First Name',
            clearable: true,
            sortable: true,
            readonly: true,
        },
        itfNatlCod: { type: 'nationality', displayName: 'ITF Nationality', clearable: true, readonly: true, },
        itfBirthDate: {
            type: 'us-date',
            displayName: 'ITF Birth Date',
            clearable: true,
            sortable: true,
            readonly: true,
        },
        wtaNameLast: { type: 'string', displayName: 'WTA Last Name', clearable: true, sortable: true, readonly: true, },
        wtaNameFirst: {
            type: 'string',
            displayName: 'WTA First Name',
            clearable: true,
            sortable: true,
            readonly: true,
        },
        wtaNationality: { type: 'nationality', displayName: 'WTA Nationality', clearable: true, readonly: true, },
        wtaBirthDate: {
            type: 'us-date',
            displayName: 'WTA Birth Date',
            clearable: true,
            sortable: true,
            readonly: true,
        },
        isSecureLink: { type: 'boolean', displayName: 'Is Secure Link', clearable: true, },
        mapToPlayerButton: {
            type: 'emit-button',
            displayName: 'Map to existing player',
            buttonText: 'Open Dialog',
            emitEvent: 'show-itf-link-dialog'
        },
        openPlayerButton: {
            type: 'emit-button',
            displayName: 'Edit player',
            buttonText: 'Open Player',
            emitEvent: 'edit-player'
        },
        selectedPlayerNbr: { type: 'player2', displayName: 'WTA Id', required: true, },
        selectedNameLast: { type: 'string', displayName: 'WTA Last Name', clearable: true, sortable: true, readonly: true, },
        selectedNameFirst: {
            type: 'string',
            displayName: 'WTA First Name',
            clearable: true,
            sortable: true,
            readonly: true,
        },
        selectedNationality: { type: 'nationality', displayName: 'WTA Nationality', clearable: true, readonly: true, },
        selectedBirthDate: {
            type: 'us-date',
            displayName: 'WTA Birth Date',
            clearable: true,
            sortable: true,
            readonly: true,
        },
    },
};