import axios from 'axios';

export default {
    namespaced: true,

    state: {
        recipients: {},
        loadingRecipients: false,
        savingRecipient: false,
        loadingError: null,
        savingError: null,
    },

    getters: {
        recipients: (state) => state.recipients,
        loadingRecipients: (state) => state.loadingRecipients,
        savingRecipient: (state) => state.savingRecipient,
        loadingError: (state) => state.loadingError,
        savingError: (state) => state.savingError,
    },

    mutations: {
        setRecipients(state, data) { state.recipients = data; },

        startLoadingRecipients(state) { state.loadingRecipients = true; },
        startSavingRecipient(state) { state.savingRecipient = true; },
        stopLoadingRecipients(state) { state.loadingRecipients = false; },
        stopSavingRecipient(state) { state.savingRecipient = false; },

        setLoadingError(state, error) { state.loadingError = error; },
        setSavingError(state, error) { state.savingError = error; },
        clearLoadingError(state) { state.loadingError = null; },
        clearSavingError(state) { state.savingError = null; },
    },

    actions: {
        async findRecipients(context, options = {}) {
            context.commit('startLoadingRecipients');
            context.commit('clearLoadingError');
            try {                
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/PlayerZone/Messaging/Recipient/${options.playerNbr}`, {
                    params: {
                        queryString: options.queryString || '',
                        pageSize: options.pageSize || 20,
                        page: options.page,
                        sortBy: options.sortBy || 'recipientId',
                        sortDir: options.sortDir === 'descending' ? -1 : 1,
                    },
                });
                context.commit('setRecipients', result.data);
                context.commit('stopLoadingRecipients');                
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('setLoadingError', error);
                context.commit('stopLoadingRecipients');
                return Promise.reject(error);
            }
        },
        async createRecipient(context, data = {}) {
                                    
            if(data.data.contactData !== null)
            {
                var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                if(!re.test(data.data.contactData))
                {
                    context.commit('setSavingError', "Valid email required");
                    context.commit('stopSavingRecipient');
                    return Promise.reject("Valid email required");
                }
            }
            context.commit('startSavingRecipient');
            context.commit('clearSavingError');
            try {
                const result = await axios.post(`${process.env.VUE_APP_API_URL}/PlayerZone/Messaging/Recipient/`, data.data);
                context.commit('stopSavingRecipient');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('setSavingError', error);
                context.commit('stopSavingRecipient');
                return Promise.reject(error);
            }
        },
        async updateRecipient(context, data = {}) {

            if (data.data.contactData !== null)
            {
                var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                if(!re.test(data.data.contactData))
                {
                    context.commit('setSavingError', "Valid email required");
                    context.commit('stopSavingRecipient');
                    return Promise.reject("Valid email required");
                }
            }
            context.commit('startSavingRecipient');
            context.commit('clearSavingError');
            try {
                const result = await axios.put(`${process.env.VUE_APP_API_URL}/PlayerZone/Messaging/Recipient/`, data.data);
                context.commit('stopSavingRecipient');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('setSavingError', error);
                context.commit('stopSavingRecipient');
                return Promise.reject(error);
            }
        },
        async deleteRecipient(context,  data = {}) {
            context.commit('startSavingRecipient');
            context.commit('clearSavingError');
            console.log(data);
            let recipientId = data.data.recipientId;
            try {
                const result = await axios.delete(`${process.env.VUE_APP_API_URL}/PlayerZone/Messaging/Recipient/${recipientId}`);
                context.commit('stopSavingRecipient');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('setSavingError', error);
                context.commit('stopSavingRecipient');
                return Promise.reject(error);
            }
        },
    },
};
