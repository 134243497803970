var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h4',[_vm._v(_vm._s(_vm.title))]),_c('DataTable',{attrs:{"items":_vm.items,"fields":[
            'tournamentEndDate',
            'tournamentName',
            'singlePriceMoney',
            'doublePriceMoney',
            'subtotal',
            'tax',
            'wtaMembership',
            'wtaAdminFee',
            'wtaMassageFee',
            'wtaFines',
            'stringerFees',
            'other',
            'totalDeduction',
            'netPayment',
        ],"schema":_vm.schema,"autoSort":false,"rowPointer":false}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }