<script src="../store/tournaments-player-payments.js"></script>
<template>
    <div class="table-wrapper mt-32">
        <table>
            <thead>
            <tr>
                <th
                        v-for="(field, j) of dataFields"
                        :key="j"
                        :class="{
                            sortable: autoSort || schema.fields[field].sortable,
                            'align-right': schema.fields[field].type === 'number' || schema.fields[field].type === 'currency',
                        }"
                        @click="headerClick(field)"
                >
                    <div v-if="schema.fields[field].columnNames">
                        <div v-for="(name, n) of schema.fields[field].columnNames" :key="n" class="align-center">
                            {{ schema.fields[field].columnNames[n] }}
                        </div>
                    </div>
                    <div v-else>
                        {{ schema.fields[field].columnName || schema.fields[field].displayName }}
                    </div>
                    <i v-if="currentSortField === field && currentSortDir === 'ascending'" class="el-icon-arrow-up"></i>
                    <i v-else-if="currentSortField === field && currentSortDir === 'descending'"
                       class="el-icon-arrow-down"></i>
                    <div v-else style="width: 13px !important; display: inline-block; height: 1px"></div>
                </th>
                <th v-if="showSearch">
                    <el-input
                            v-model="search"
                            clearable
                            placeholder="Type to search"
                            size="mini"
                            style="width: 128px"
                            @keydown.esc.native="search = null"
                    />
                </th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item, i) of sortedItems" :key="i" :class="clFormatter(item)" :style="trFormatter(item)"
                @click="itemClicked(item, i)">
                <td
                        v-for="(field, j) of dataFields"
                        :key="j"
                        :class="{
                            'align-right': schema.fields[field].type === 'number' || schema.fields[field].type === 'currency',
                        }"
                        :style="schema.fields[field].tdCss"
                >
                        <span v-if="schema.fields[field].type === 'date' || schema.fields[field].type === 'us-date'">
                            {{
                                item[field] ? momentDate(new Date(item[field])) : item.data && item.data[field] ? momentDate(new Date(item.data[field])) : ''
                            }}
                        </span>
                    <span v-else-if="schema.fields[field].type === 'datetime'">
                            {{ item[field] | moment('YYYY-MM-DD HH:mm:ss') }}
                        </span>
                    <div v-else-if="schema.fields[field].type === 'nationality'" class="nationality">
                    <template v-if="item[field] === 'TPE'">
                        <div>
                            <img src="@/assets/TPE-Olympic.png" alt="brand logo"  style="margin: 0 .1em -.6em .3em;width: 26px;height: 24px;display: inline-block;"/>
                            <span class="ml-8">{{ item[field] ? item[field] : item.data ? item.data[field] : '' }}</span>
                        </div>
                    </template>
                     <template v-else>
                        <country-flag
                                v-if="item[field] ? toIso3166(item[field]) : item.data && item.data[field] ? toIso3166(item.data[field]) : false"
                                :country="item[field] ? toIso3166(item[field]) : item.data && item.data[field] ? toIso3166(item.data[field]) : null"
                                size="normal"/>
                      <span class="ml-8">{{ item[field] ? item[field] : item.data ? item.data[field] : '' }}</span>
                      </template>
                    </div>
                    <span v-else-if="schema.fields[field].type === 'currency'">
                            {{ toCurrency(field, item) }}
                        </span>
                    <span v-else-if="schema.fields[field].lookup">
                            <!-- {{ schema.fields[field].lookup[item[field]].displayName }} -->
                            {{ toLookup(schema.fields[field].lookup, item[field]) }}
                        </span>
                    <span v-else-if="schema.fields[field].type === 'boolean'">
                            {{ item[field] === true || item[field] === 'true' ? 'Yes' : 'No' }}
                        </span>
                    <div v-else-if="schema.fields[field].type === 'round-player'">
                        <i v-if="isWinnerTeam(field, item)" class="el-icon-trophy"></i>
                        <i v-if="isQualifier(field, item)" class="el-icon-circle-check"></i>
                        {{ item[field] }}
                    </div>
                    <div v-else-if="schema.fields[field].type === 'emit-button'">
                        <el-button plain size="mini" type="primary" @click="emitEvent(field, item, i)">
                            {{ schema.fields[field].buttonText }}
                        </el-button>
                    </div>
                    <div
                            v-else-if="
                                schema.fields[field].type === 'string-array' &&
                                schema.fields[field].displayType === 'tag' &&
                                schema.fields[field].tagType
                            "
                    >
                        <el-tag v-for="string in item[field]" :key="string" :type="schema.fields[field].tagType">
                            {{ string }}
                        </el-tag>
                    </div>
                    <div
                            v-else-if="
                                schema.fields[field].type === 'string-array' &&
                                schema.fields[field].displayType === 'player-development' &&
                                schema.fields[field].tagType
                            "
                    >
                        <el-tag v-for="string in toDevelopmentRequirements(item[field])" :key="string"
                                :type="schema.fields[field].tagType">
                            {{ toDevelopmentRequirement(string) }}
                        </el-tag>
                    </div>
                    <div
                            v-else-if="
                                schema.fields[field].type === 'string-array' &&
                                schema.fields[field].displayType === 'support-development' &&
                                schema.fields[field].tagType
                            "
                    >
                        <el-tag v-for="string in toDevelopmentRequirements(item[field])" :key="string"
                                :type="schema.fields[field].tagType">
                            {{ toSupportRequirement(string) }}
                        </el-tag>
                    </div>
                    <span v-else>
                            {{ (item.data  && item.data[field] !== null) ? item.data[field] : item[field] !== null ? item[field] : '' }}
                        </span>
                </td>
                <td v-if="showSearch"></td>
            </tr>

            <tr v-if="showTotals" :class="clFormatter(totalsRow) + ' totals-row'" :style="trFormatter(totalsRow)">
                <td
                        v-for="(field, j) of dataFields"
                        :key="j"
                        :class="{
                            'align-right': schema.fields[field].type === 'number' || schema.fields[field].type === 'currency',
                        }"
                        :style="schema.fields[field].tdCss"
                >
                    <span v-if="!schema.fields[field].sumUpTotals && !schema.fields[field].sumUpLabel"> </span>
                    <span v-else-if="schema.fields[field].type === 'currency'">
                            {{ toCurrency(field, totalsRow) }}
                        </span>
                    <span v-else>
                            <b>{{ totalsRow[field] }} </b>
                        </span>
                </td>
            </tr>
            </tbody>
        </table>
        <p v-if="!sortedItems || !sortedItems.length" class="no-data-message">No data available</p>
    </div>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';
import { convertIocCode } from '../utils/country-codes';
import { PlayerDevelopment } from '../models/player-development';
import { PlayerSupport } from '../models/player-support';

export default {
    name: 'DataTable',
    props: {
        items: null,
        schema: {},
        fields: null,
        showSearch: { type: Boolean, default: false },
        searchFields: null,
        autoSort: { type: Boolean, default: true },
        sortField: null,
        sortDir: { type: String, default: 'ascending' },
        trFormatter: { type: Function, default: () => '' },
        clFormatter: { type: Function, default: (item) => (item.errorFound ? 'errorFound' : '') },
        rowPointer: { type: Boolean, default: true },
        showTotals: { type: Boolean, default: false },
    },
    data() {
        return {
            search: '',
            dataFields: this.fields || Object.keys(this.schema.fields),
            currentSortField: null,
            currentSortDir: 'ascending',
            forceRecomputeCounter: 0,
        };
    },
    watch: {
        sortField() {
            this.currentSortField = this.sortField;
        },
        sortDir() {
            this.currentSortDir = this.sortDir;
        },
    },
    mounted() {
        if (!this.sortField && this.autoSort) {
            const fields = Object.keys(this.schema.fields);
            if (fields && fields.length > 0) {
                this.currentSortField = fields[0];
            }
        }
        if (this.sortField) {
            this.currentSortField = this.sortField;
        }
        if (this.sortDir) {
            this.currentSortDir = this.sortDir;
        }
    },
    computed: {
        sortDirNumeric() {
            return this.currentSortDir === 'ascending' ? 1 : -1;
        },
        filteredData() {
            if (!this.search || !this.searchFields || this.searchFields.length < 1) {
                return this.items;
            }

            const result = [];
            for (const item of this.items) {
                for (const field of this.searchFields) {
                    if (('' + item[field]).toLowerCase().includes(this.search.toLowerCase())) {
                        result.push(item);
                        break;
                    }
                }
            }

            return result;
        },
        sortedItems() {
            this.forceRecomputeCounter; // this forces vue to recompute this
            const result = this.filteredData;
            if (!result) {
                return [];
            }

            if (!this.autoSort || !this.currentSortField) {
                return result;
            }

            return result.sort((i1, i2) => {
                if (!i1[this.currentSortField]) return this.sortDirNumeric;
                if (!i2[this.currentSortField]) return -this.sortDirNumeric;
                return i1[this.currentSortField] > i2[this.currentSortField] ? this.sortDirNumeric : -this.sortDirNumeric;
            });
        },
        totalsRow() {
            var row = {};
            if (!this.dataFields) return row;
            this.sortedItems.forEach((item) => {
                this.dataFields.forEach((key) => {
                    if (this.schema.fields[key].sumUpLabel && !row[key]) {
                        row[key] = this.schema.fields[key].sumUpLabel;
                    }
                    if (this.schema.fields[key].sumUpTotals) {
                        if (!row[key]) row[key] = 0;
                        row[key] += item[key];
                    }
                });
            });
            return row;
        },
    },
    methods: {
        toIso3166(code) {
            const result = convertIocCode(code);
            return result ? result.iso3 : '';
        },
        isWinnerTeam(fieldName, item) {
            let value = item[fieldName];
            let winner = this.schema.fields[fieldName].winner ? this.schema.fields[fieldName].winner : 'matchWinner';
            let winnerValue = item[winner] + '';
            if (!winnerValue) {
                return false;
            }
            if (winnerValue === this.schema.fields[fieldName].team && value) {
                return true;
            }
            return false;
        },
        classFormatter(item) {
            var klass = '';
            if (item.errorFound) klass *= ' errorFound';
            if (this.rowPointer) klass *= ' pointer';
        },
        toLookup(lookups, value) {
            var result = value;
            lookups.forEach((lookup) => {
                if (lookup.id === value) {
                    result = lookup.displayName;
                }
            });
            return result;
        },
        toDevelopmentRequirements(strings) {
            var result = [];
            let maxCount = 5;
            for (let i = 0; i <= maxCount && i < strings.length; i++) {
                if (i == maxCount) {
                    result.push('...');
                    return result;
                }
                result.push(strings[i]);
            }
            return result;
        },
        toDevelopmentRequirement(string) {
            if (string === '...') return string;
            return PlayerDevelopment.schema.fields[_.lowerFirst(string)].displayName;
        },
        toSupportRequirement(string) {
            if (string === '...') return string;
            return PlayerSupport.schema.fields[_.lowerFirst(string)].displayName;
        },
        isQualifier(fieldName, item) {
            let value = item[fieldName];
            let winner = this.schema.fields[fieldName].winner ? this.schema.fields[fieldName].winner : 'matchWinner';
            let winnerValue = item[winner] + '';
            let tournamentId = item.tournamentId;
            if (!winnerValue || tournamentId === 808) {
                return false;
            }
            if (winnerValue === '0' && value) {
                return true;
            }
            return false;
        },
        toCurrency(field, item) {
            let value = item[field];
            if (value == null) {
                return value;
            }
            let currency = 'currency';
            if (this.schema.fields[field].currency) {
                currency = this.schema.fields[field].currency;
            }
            let currValue = item[currency];
            if (!currValue) {
                return '';
            }
            let options = {
                style: 'decimal',
            };
            let lookups = this.schema.fields[currency].lookup;
            if (!lookups) {
                if (currValue !== 'No') {
                    options.style = 'currency';
                    options.currency = currValue;
                }
                let formatted = new Intl.NumberFormat('en-US', options).format(value);
                return formatted;
            }
            let lookup = '';
            for (let i in lookups) {
                if (lookups[i].id == currValue) {
                    lookup = lookups[i].displayName;
                }
            }
            if (lookup && lookup !== 'No') {
                options.currency = lookup;
                options.style = 'currency';
            }
            let formatted = new Intl.NumberFormat('en-US', options).format(value);
            return formatted;
        },
        momentDate(date) {
            return moment(date).format('YYYY-MM-DD');
        },
        itemClicked(row, index) {
            this.$emit('item-clicked', row, index);
        },
        emitEvent(field, item, index) {
            let event = {
                'item': item,
                'index': index
            };
            this.$emit(this.schema.fields[field].emitEvent, event);
        },
        headerClick(header, event) {
            if (this.autoSort) {
                if (this.currentSortField !== header) {
                    this.currentSortField = header;
                } else {
                    this.currentSortDir = this.currentSortDir === 'ascending' ? 'descending' : 'ascending';
                }
                this.forceRecomputeCounter++;
            } else {
                if (this.schema.fields[header].sortable) {
                    if (this.currentSortField !== header) {
                        this.currentSortField = header;
                        this.currentSortDir = 'ascending';
                    } else {
                        if (this.currentSortDir === 'ascending') {
                            this.currentSortDir = 'descending';
                        } else {
                            this.currentSortDir = 'ascending';
                            this.currentSortField = '';
                        }
                    }
                    this.$emit('header-clicked', {
                        property: this.currentSortField,
                        order: this.currentSortDir,
                    });
                }
            }
        },
        formatter(field) {
            switch (field.type) {
                case 'date':
                    return (row, col) => new Date(row[col.property]).toDateString();
                case 'currency':
                    return (row, col) => this.currencyFormatterUsd.format(row[col.property]);
                default:
                    return (row, col) => row[col.property];
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.nationality {
    display: flex;
    align-items: center;
}

.table-wrapper {
    overflow-x: auto;
}

table {
    width: 100%;
    border-collapse: collapse;
}

tbody tr {
    height: 32px;

    &.pointer {
        cursor: pointer;
    }

    &:nth-of-type(even) {
        background-color: #f8f8f8;

        &.errorFound {
            background-color: #ebc7c7;
        }
    }

    &:hover {
        background-color: #eee;

        &.errorFound {
            background-color: #ebb2b2;
        }
    }

    &:not(:last-of-type) {
        border-bottom: 1px solid #eee;
    }

    &.errorFound {
        background-color: #e8cccc;
    }
}

thead tr {
    height: 48px;
}

th {
    text-align: left;
    padding-right: 16px;
    padding-left: 16px;
    font-size: 13px;
    border-bottom: 2px solid #ddd;
    white-space: nowrap;
    vertical-align: middle;

    &.sortable {
        cursor: pointer;

        &:hover {
            background-color: #eaeaea;
        }
    }
}

td {
    padding-right: 16px;
    padding-left: 16px;
    font-size: 13px;
    white-space: nowrap;
}

.totals-row > td {
    height: 46px;
}

.no-data-message {
    text-align: center;
    padding: 16px;
    color: #aaa;
}

.el-icon-trophy,
.el-icon-circle-check,
.el-icon-success {
    color: #944cff;
}
</style>
