import axios from 'axios';
import { buildParams } from '../../utils/query-param-builder';
import * as moment from 'moment';

export default {
    namespaced: true,

    state: {
        rankings: [],
        loadingRanking: false,
        loadingError: null,
        options: {
            queryString: '',
            sortBy: '',
            sortDir: '',
            pageSize: process.env.VUE_APP_PAGE_SIZE,
            page: 0,
        },
    },

    getters: {
        rankings: (state) => state.rankings,
        loadingRanking: (state) => state.loadingRanking,
        loadingError: (state) => state.loadingError,
        options: (state) => state.options,
    },

    mutations: {
        setRankings(state, data) {
            state.rankings = data;
        },

        startLoadingRankings(state) {
            state.loadingRanking = true;
        },

        stopLoadingRankings(state) {
            state.loadingRanking = false;
        },

        setLoadingError(state, error) {
            state.loadingError = error;
        },

        clearLoadingError(state) {
            state.loadingError = null;
        },
    },

    actions: {
        async findTeamRankings(context, options = {}) {
            context.commit('startLoadingRankings');
            let date = options.date;
            let params = buildParams(options.options);
            try {
                var result = await axios.get(`${process.env.VUE_APP_API_URL}/team-points-history/by-date/${date}`, { params });
                context.commit('setRankings', result.data);
                context.commit('stopLoadingRankings');
                context.commit('setLoadingError', null);
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('setRankings', []);
                context.commit('stopLoadingRankings');
                context.commit('setLoadingError', error);
                return Promise.reject(error);
            }
        },
        async createTeamRanking(context, data) {
            context.commit('startLoadingRankings');
            try {
                const result = await axios.post(`${process.env.VUE_APP_API_URL}/team-points-history`, data.data);
                context.commit('stopLoadingRankings');
                context.commit('setLoadingError', null);
                return Promise.resolve(true);
            } catch (error) {
                context.commit('stopLoadingRankings');
                context.commit('setLoadingError', error);
                return Promise.reject(error);
            }
        },
        async updateTeamRanking(context, data) {
            context.commit('startLoadingRankings');
            try {
                const result = await axios.put(`${process.env.VUE_APP_API_URL}/team-points-history`, data.data);
                context.commit('stopLoadingRankings');
                context.commit('setLoadingError', null);
                return Promise.resolve(true);
            } catch (error) {
                context.commit('stopLoadingRankings');
                context.commit('setLoadingError', error);
                return Promise.reject(error);
            }
        },
        async deleteTeamRanking(context, data) {
            let date = moment(data.data.rankingDate).format('YYYY-MM-DD');
            let player1Id = data.data.player1Id;
            let player2Id = data.data.player2Id;
            context.commit('startLoadingRankings');
            try {
                await axios.delete(`${process.env.VUE_APP_API_URL}/team-points-history/${date}/${player1Id}/${player2Id}`);
                context.commit('stopLoadingRankings');
                context.commit('setLoadingError', null);
                return Promise.resolve(true);
            } catch (error) {
                context.commit('stopLoadingRankings');
                context.commit('setLoadingError', error);
                return Promise.reject(error);
            }
        },
        async exportCsv(context, options = {}) {
            context.commit('startLoadingRankings');
            try {
                let date = options.date;
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/team-points-history/by-date/${date}/export/csv`, {
                    params: {
                        queryString: options.queryString,
                        sortBy: options.sortBy,
                        sortDir: options.sortDir,
                    },
                });
                context.commit('stopLoadingRankings');
                context.commit('setLoadingError', null);
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('stopLoadingRankings');
                return Promise.reject(error);
            }
        },
    },
};
