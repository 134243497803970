import axios from 'axios';
import moment from 'moment';
import { buildParams } from '../utils/query-param-builder';

export default {
    namespaced: true,

    state: {
        specialRankings: [],
        options: {
            queryString: '',
            sortBy: '',
            sortDir: '',
            pageSize: process.env.VUE_APP_PAGE_SIZE,
            page: 0,
        },
        loadingSpecialRankings: false,
        loadingSpecialRankingsError: null,
    },

    getters: {
        options: (state) => state.options,
        specialRankings: (state) => state.specialRankings,
        loadingSpecialRankings: (state) => state.loadingSpecialRankings,
        loadingSpecialRankingsError: (state) => state.loadingSpecialRankingsError,
    },

    mutations: {
        setSpecialRankings(state, data) {
            state.specialRankings = data;
            state.loadingSpecialRankingsError = null;
        },

        startLoadingSpecialRankings(state) {
            state.loadingSpecialRankings = true;
        },

        stopLoadingSpecialRankings(state) {
            state.loadingSpecialRankings = false;
        },

        setLoadingSpecialRankingsError(state, error) {
            state.loadingError = error;
        },
    },

    actions: {
        async retrieveSpecialRankings(context, parameters) {
            let params = buildParams(parameters);
            context.commit('startLoadingSpecialRankings');
            try {
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/ranking/special`, { params });
                context.commit('setSpecialRankings', result.data);
                context.commit('stopLoadingSpecialRankings');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('setSpecialRankings', []);
                context.commit('stopLoadingSpecialRankings');
                context.commit('setLoadingSpecialRankingsError', error);
                return Promise.reject(error);
            }
        },
        async createSpecialRanking(context, data) {
            context.commit('startLoadingSpecialRankings');
            try {
                await axios.post(`${process.env.VUE_APP_API_URL}/ranking/special`, data.data);
                context.commit('stopLoadingSpecialRankings');
                return Promise.resolve();
            } catch (error) {
                context.commit('stopLoadingSpecialRankings');
                context.commit('setLoadingSpecialRankingsError', error);
                return Promise.reject(error);
            }
        },
        async updateSpecialRanking(context, data) {
            context.commit('startLoadingSpecialRankings');
            try {
                await axios.put(`${process.env.VUE_APP_API_URL}/ranking/special`, data.data);
                context.commit('stopLoadingSpecialRankings');
                return Promise.resolve();
            } catch (error) {
                context.commit('stopLoadingSpecialRankings');
                context.commit('setLoadingSpecialRankingsError', error);
                return Promise.reject(error);
            }
        },
        async deleteSpecialRanking(context, playerId) {
            context.commit('startLoadingSpecialRankings');
            try {
                await axios.delete(`${process.env.VUE_APP_API_URL}/ranking/special/${playerId}`);
                context.commit('stopLoadingSpecialRankings');
                return Promise.resolve();
            } catch (error) {
                context.commit('stopLoadingSpecialRankings');
                context.commit('setLoadingSpecialRankingsError', error);
                return Promise.reject(error);
            }
        },
        async exportCsv(context, options = {}) {
            context.commit('startLoadingSpecialRankings');
            try {
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/ranking/special/export/csv`, {
                    params: {
                        queryString: options.queryString || '',
                        sortBy: options.sortBy || 'playerId',
                        sortDir: options.sortDir === 'descending' ? -1 : 1,
                    },
                });
                context.commit('stopLoadingSpecialRankings');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('stopLoadingSpecialRankings');
                return Promise.reject(error);
            }
        },
    },
};
