import { isValid, Model } from "./model";

export class PzAccess extends Model {
    constructor(data) {
        super(data);
    }
    isValid() {
        return isValid(PzAccess.schema, this);
    };
    isValidForEdit() {
        return isValid(PzAccess.schema, this);
    };
    isValidForAdd() {
        return isValid(PzAccess.schemaAdd, this);
    };
}


PzAccess.layoutAdd = {
    title: 'Create Pz Access',
    children: [
        {
            children: [{ type: 'spacer', width: 2 }],
        },
        {
            children: ['firstName', 'lastName', 'email', { type: 'spacer' }],
        },
    ],
};

PzAccess.layout = {
    title: 'Pz Access',
    children: [
        {
            children: ['plyrNum', { type: 'spacer', width: 2 }],
        },
        {
            children: ['firstName', 'lastName', 'email', 'created', { type: 'spacer' }],
        },
        {
            children: ['owesMoney', 'role', 'lastAccess', { type: 'spacer' }],
        },
    ],
};


PzAccess.schema = {
    fields: {
        plyrNum: { displayName: 'Id', sortable: true, type: 'string', group: 'general', required: true, readonly: true },
        username: { displayName: 'Username', sortable: true, type: 'string', group: 'general', maxLength: 40, clearable: true, readonly: true },
        activeFlag: { displayName: 'Active Flag', type: 'string', group: 'general', required: true, clearable: true },
        created: { displayName: 'Created', type: 'date', group: 'general', clearable: true, sortable: true, readonly: true },
        lastAccess: { displayName: 'Last Access', sortable: false, type: 'date', group: 'general', clearable: true },
        firstName: { displayName: 'First Name', sortable: true, type: 'string', group: 'general', clearable: true },
        lastName: { displayName: 'Last Name', sortable: true, type: 'string', group: 'general', clearable: true },
        role: { displayName: 'Role', type: 'string', group: 'general', clearable: true },
        email: { displayName: 'Email', type: 'email', group: 'general', clearable: true },
        termsAccepted: { displayName: 'Terms Accepted', type: 'date', group: 'general', clearable: true },
        owesMoney: { displayName: 'Owes Money', type: 'date', group: 'general', clearable: true },
        integrityTrainingStatus: { displayName: 'Integrity Training Status', sortable: false, type: 'string', group: 'general', clearable: true },
        integrityTrainingCompDate: { displayName: 'Integrity Training Comp Date', type: 'date', group: 'general', clearable: true },
    }
};

PzAccess.schemaAdd = {
    fields: {
        username: { displayName: 'Username', sortable: true, type: 'string', group: 'general', maxLength: 40, clearable: true },
        activeFlag: { displayName: 'Active Flag', sortable: false, type: 'string', group: 'general', clearable: true },
        created: { displayName: 'Created', sortable: false, type: 'date', group: 'general', clearable: true },
        lastAccess: { displayName: 'Last Access', sortable: false, type: 'date', group: 'general', clearable: true },
        firstName: { displayName: 'First Name', sortable: false, type: 'string', group: 'general', clearable: true , required: true},
        lastName: { displayName: 'Last Name', sortable: true, type: 'string', group: 'general', clearable: true, required: true },
        role: { displayName: 'Role', sortable: false, type: 'string', group: 'general', clearable: true },
        email: { displayName: 'Email', sortable: false, type: 'email', group: 'general', clearable: true },
        termsAccepted: { displayName: 'Terms Accepted', sortable: false, type: 'date', group: 'general', clearable: true },
        owesMoney: { displayName: 'Owes Money', sortable: false, type: 'date', group: 'general', clearable: true },
        integrityTrainingStatus: { displayName: 'Integrity Training Status', sortable: false, type: 'string', group: 'general', clearable: true },
        integrityTrainingCompDate: { displayName: 'Integrity Training Comp Date', sortable: false, type: 'date', group: 'general', clearable: true },
    }
};