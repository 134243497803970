import { Model, isValid } from './model';

export class PlayerBonus extends Model {
    isValid() {
        return isValid(PlayerBonus.schema, this);
    }
}

PlayerBonus.layout = {
    children: [
        {
            children: ['playerId', { type: 'spacer', width: 2 }],
        },
        {
            children: ['bonusYear', 'bonusType', 'bonusDate'],
        },
        {
            children: ['bonusSingles', 'bonusDoubles', { type: 'spacer' }],
        },
    ],
};

PlayerBonus.schema = {
    fields: {
        playerId: { type: 'player', displayName: 'Player', required: true },
        bonusYear: { type: 'number', displayName: 'Year', required: true },
        bonusType: { type: 'string', displayName: 'Type', required: true },
        bonusDate: { type: 'date', displayName: 'Date', required: true },
        bonusSingles: { type: 'currency', displayName: 'Singles' },
        bonusDoubles: { type: 'currency', displayName: 'Doubles' },
        currency: { type: 'string', displayName: 'Currency', readonly: true, },
    },
};
