import axios from 'axios';
import { PlayerSupport } from '../models/player-support';

export default {
    namespaced: true,

    state: {
        playerSupporters: {},
        loadingPlayerSupporters: false,
        loadingError: null,
        options: {
            queryString: null,
            sortBy: 'name_first',
            sortDir: 'descending',
            page: 0,
            pageSize: 20,
        },
    },

    getters: {
        playerSupporters: (state) => state.playerSupporters,
        loadingPlayerSupporters: (state) => state.loadingPlayerSupporters,
        loadingError: (state) => state.loadingError,
        options: (state) => state.options,
    },

    mutations: {
        setPlayerSupportFound(state) {
            state.playerSupportFound = true;
        },

        clearPlayerSupportFound(state) {
            state.playerSupportFound = false;
        },

        setPlayerSupporters(state, data) {
            state.playerSupporters = data;
        },

        startLoadingPlayerSupporters(state) {
            state.loadingPlayerSupporters = true;
        },

        stopLoadingPlayerSupporters(state) {
            state.loadingPlayerSupporters = false;
        },

        setLoadingError(state, error) {
            state.loadingError = error;
        },

        clearLoadingError(state) {
            state.loadingError = null;
        },
    },

    actions: {
        async findPlayerSupporters(context, options = {}) {
            context.commit('startLoadingPlayerSupporters');
            try {
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/contacts/Find`, {
                    params: {
                        queryString: options.queryString || '',
                        pageSize: options.pageSize || 50,
                        sortBy: options.sortBy || 'name_first',
                        sortDir: options.sortDir === 'descending' ? -1 : 1,
                        page: options.page,
                        nationality: options.nationality,
                        recordType: 'PS',
                    },
                });
                context.commit('stopLoadingPlayerSupporters');
                context.commit('clearLoadingError');
                context.commit('setPlayerSupporters', result.data);
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('stopLoadingPlayerSupporters');
                context.commit('setLoadingError', error);
                return Promise.reject(error);
            }
        },

        async exportCsv(context, options = {}) {
            context.commit('startLoadingPlayerSupporters');
            try {
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/PlayerSupport/export/csv`, {
                    params: {
                        recordType: 'PS',
                    },
                });
                context.commit('stopLoadingPlayerSupporters');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('stopLoadingPlayerSupporters');
                return Promise.reject(error);
            }
        },
    },
};
