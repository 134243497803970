<template>
    <div>
        <div class="flex-row mt-16 mb-32">
            <h2>Supporters</h2>
            <span class="spacer"></span>

            <SelectNationality v-model="nationality" :lookup="nationalities" :clearable="true" />
            <el-input
                placeholder="Filter by Id or Name"
                v-model="options.queryString"
                class="input-search mr-16"
                @change="resetPage"
                @keydown.esc.native="reset"
            >
                <el-button slot="append" icon="el-icon-search" @click="resetPage"></el-button>
            </el-input>

            <el-button v-if="!isBaselineReader" @click="addPlayerSupport" plain type="primary">Add supporter</el-button>
            <el-button @click="exportCsvClick" plain class="ml-8" size="mini">Export CSV</el-button>
        </div>

        <ErrorDisplay :requestError="this.loadingError" @closed="clearError" />
        <div v-loading="loadingPlayerSupporters">
            <DataTable
                :items="playerSupporters.data"
                :schema="schema"
                :fields="['contactId', 'nameFirst', 'nameLast', 'pstNation', 'age', 'pstBDate']"
                :sortField="options.sortBy"
                :sortDir="options.sortDir"
                @item-clicked="selectPlayerSupport"
                @header-clicked="setSort"
                :autoSort="false"
            />

            <Paginator
                v-if="playerSupporters.data"
                :data="playerSupporters"
                :elementsPerPage="options.pageSize"
                @prev-page="prevPage"
                @next-page="nextPage"
                @set-page-size="setPageSize"
            />
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { PlayerSupport } from '../models/player-support';
import moment from 'moment';
import FileSaver from 'file-saver';
import { retrieveDtos } from '../utils/crud-confirm';
import { icoCountries } from '../utils/country-codes';
import { isBaselineReader } from '../services/authGroups';

export default {
    data() {
        return {
            schema: PlayerSupport.schema,
            nationality: null,
            nationalities: Object.keys(icoCountries),
            isBaselineReader: isBaselineReader(),
        };
    },
    mounted() {
        this.search();
    },
    computed: mapGetters('playerSupport', ['playerSupporters', 'loadingPlayerSupporters', 'options', 'loadingError']),
    methods: {
        ...mapActions('playerSupport', ['findPlayerSupporters', 'exportCsv']),
        exportCsvClick() {
            this.exportCsv(this.options)
                .then((result) => {
                    const file = new File([result], `player_supporters_${moment().format('DD_MM_YYYY HH_mm_SS')}.csv`, { type: 'text/csv' });
                    FileSaver.saveAs(file);
                })
                .catch((error) => {
                    this.$notify({
                        title: 'CSV export',
                        message: 'CSV export failed',
                        type: 'error',
                        duration: 5000,
                    });
                });
        },
        selectPlayerSupport(row) {
            this.$router.push(`/player-supporter/${row.contactId}`);
        },
        addPlayerSupport() {
            this.$router.push('/player-supporter/add');
        },
        search() {
            let params = {
                self: this,
                fRetrieve: this.findPlayerSupporters,
                options: this.options,
                dtosName: 'Player Supporters',
            };
            this.options.nationality = this.nationality;
            retrieveDtos(params);
        },
        resetSearch() {
            this.options.queryString = null;
            this.search();
        },
        setPageSize(size) {
            this.options.pageSize = size;
            this.search();
        },
        resetPage() {
            this.options.page = 0;
            this.search();
        },
        reset() {
            this.options.queryString = null;
            this.options.page = 0;
            this.search();
        },
        setSort(event) {
            this.options.sortBy = event.property;
            this.options.sortDir = event.order;
            this.search();
        },
        prevPage() {
            this.options.page--;
            this.search();
        },
        nextPage() {
            this.options.page++;
            this.search();
        },
        clearError() {
            this.loadingError = null;
        },
    },
};
</script>

<style scoped>
.input-search {
    max-width: 512px;
}
</style>
