<template>
    <div class="AL">
        <h2>Acceptance List</h2>
        <el-collapse class="collapsible-help">
            <el-collapse-item title="Information about Acceptance List Algorithms and Logic" name="1">
                <el-alert type="info" :closable="false">
                    This section contains descriptions for all logic implemented for Acceptance Lists.
                    <ul>
                        <li>The main draw, qualifying and doubles all contain a list of players that have entries in state Accepted.</li>
                        <li>The alternate draw is a list of all players that have entries in state Entered.</li>
                    </ul>

                    <h3>Singles</h3>
                    <div>
                        <h5>States of Lists</h5>

                        <p>
                            Before a list has been processed it is called a preliminary list and shows all players that have entered. If players withdraw (WD)
                            then they simply disappear from the list (no strike-through). After a list has been processed (on Tuesday) the list splits at the
                            cut-off into a main and alternate sub-list. The main list holds all accepted players and the alternates all those that might come
                            into the main. Both sub-lists will show players that have (or have been) withdrawn after the processing (WM, WQ, WE) with a
                            strike-through. Players having withdrawn before processing still stay off the list. For the print-out of a processed list, no
                            strike-throughs are shown at all. A processed list can then be published. The publishing activates display on the Playerzone and
                            also ensures that all strike-throughs (withdrawn players after entry deadline) show up also in print-out.
                        </p>

                        <h5>Position of Withdrawn Entries</h5>

                        <p>
                            The position of the withdrawn entry is determined by the entry rank. Entry rank is also used for withdrawn alternate players.
                            Furthermore, the position of a withdrawn entry on either main, qual, doubles or alternate is determined by the rank of the first
                            non-withdrawn player on the alternate list. Meaning, that a withdrawn entry that has a rank lower (e.g. 100 less than 150) than the
                            first player on the alternate list then it will be shown in the main draw with a strike-through and vice versa.
                        </p>

                        <h5>Entry Types</h5>
                        <el-tag size="mini">Entered <b>EN</b></el-tag>
                        <el-tag size="mini">NotEntered <b>NE</b></el-tag>
                        <el-tag size="mini">AcceptedIntoMain <b>MD</b></el-tag>
                        <el-tag size="mini">AcceptedIntoQualification <b>QL</b></el-tag>
                        <el-tag size="mini">WithdrawnFromMainDrawAfterEntryDeadline <b>WM</b></el-tag>
                        <el-tag size="mini">WithdrawnFromQualificationDrawAfterEntryDeadline <b>WQ</b></el-tag>
                        <el-tag size="mini">WithdrawnBeforeEntryDeadline <b>WD</b></el-tag>
                        <el-tag size="mini">WithdrawnAfterEntryDeadline <b>WE</b></el-tag>
                        <el-tag>ObsoleteWithdraw <b>OW</b></el-tag>
                        <el-tag size="mini">ObsoleteEnter <b>OE</b></el-tag>
                        <el-tag size="mini">Other <b>OT</b></el-tag>
                    </div>
                    <h3>Doubles</h3>
                    <div>
                        The doubles acceptance list columns are structured according to the name of the players. If the name of a player of the entry is longer
                        than the partner then the partner will be put in the first column.
                    </div>
                </el-alert>
            </el-collapse-item>
        </el-collapse>
        <div v-loading="loadingAcceptanceList">
            <el-select v-model="edition" placeholder="Select tournament" class="mr-16">
                <el-option v-for="(e, i) in editions" :key="i" :label="e.season + ' - ' + e.name" :value="e.season + '/' + e.tournamentId"></el-option>
            </el-select>

            <el-switch :value="useCurrentRanks" @input="updateUseCurrentRanks" class="mr-16" active-text="Use Current Ranks" />

            <el-button :disabled="!edition" v-if="!isBaselineReader" @click="run" type="primary" class="mr-16">Load acceptance list</el-button>

            <el-select :disabled="!edition || !dataLoaded" v-model="mode" placeholder="Mode" class="mr-16">
                <el-option value="S" label="Singles"></el-option>
                <el-option value="D" label="Doubles"></el-option>
            </el-select>

            <el-select :disabled="!edition || !dataLoaded" v-if="mode === 'S'" v-model="round" placeholder="Round" class="mr-16">
                <el-option value="M" label="Main"></el-option>
                <el-option value="Q" label="Quali"></el-option>
            </el-select>
        </div>

        <div v-if="edition && dataLoaded && !loadingAcceptanceList && !isBaselineReader" v-loading="publishLoading">
            <div v-if="mode === 'S' && round === 'M'">
                <div class="published" v-if="editionData.tournamentAcceptanceListSinglesMainIsPublished === true">
                    Published
                    <el-button class="publish_button" @click="unpublish">Unpublish</el-button>
                </div>

                <div class="not_published" v-if="!editionData.tournamentAcceptanceListSinglesMainIsPublished">
                    Not yet Published
                    <el-button class="publish_button" @click="publish">Publish Main</el-button>
                </div>
            </div>
            <div v-if="mode === 'S' && round === 'Q'">
                <div class="published" v-if="editionData.tournamentAcceptanceListSinglesQualiIsPublished === true">
                    Published
                    <el-button class="publish_button" @click="unpublish">Unpublish</el-button>
                </div>

                <div class="not_published" v-if="!editionData.tournamentAcceptanceListSinglesQualiIsPublished">
                    Not yet Published
                    <el-button class="publish_button" @click="publish">Publish QLF</el-button>
                </div>
            </div>
            <div v-if="mode === 'D' && round === 'M'">
                <div class="published" v-if="editionData.tournamentAcceptanceListDoublesMainIsPublished === true">
                    Published
                    <el-button class="publish_button" @click="unpublish">Unpublish</el-button>
                </div>

                <div class="not_published" v-if="!editionData.tournamentAcceptanceListDoublesMainIsPublished">
                    Not yet Published
                    <el-button class="publish_button" @click="publish">Publish Doubles</el-button>
                </div>
            </div>
        </div>

        <div id="forPrintOut">
            <div v-if="dataLoaded && !loadingAcceptanceList" id="tournData">
                <tournament-info-general
                    :prize-money="currencyFormat(editionData.prizeMoney)"
                    :total-commitment="currencyFormat(editionData.commitment)"
                    :start-date="dateFormat(editionData.startDate, 'mmm d, yyyy')"
                    :edition-data="editionData"
                    :expanded-currency="currencyIso4217"
                    :expanded-round="expandedRound"
                    :short-level="shortLevel"
                    :long-level="prettyLevel"
                />

                <tournament-info-details
                    :main-draw-cutoff-rank-date="dateFormat(editionData.single.main.entryDeadline, 'yyyy-mm-dd')"
                    :quali-draw-cutoff-rank-date="dateFormat(editionData.single.qualification.entryDeadline, 'yyyy-mm-dd')"
                    :main-draw-start-date="dateFormat(editionData.startDate, 'yyyy-mm-dd')"
                    :doubles-draw-cutoff-rank-date="dateFormat(editionData.double.main.entryDeadline, 'yyyy-mm-dd')"
                    :edition-data="editionData"
                    :expanded-ball-type="expandedBallType"
                    :mode="mode"
                />
            </div>

            <div v-if="dataLoaded && !loadingAcceptanceList" id="tournData" class="WildcardsAndExemptions">
                <table style="margin: auto; text-align: left; margin-top: 10px" v-if="round === 'M' && mode === 'S'">
                    <tr v-if="editionData.single.main.numberOfWildcards > 0">
                        <td class="WildcardHeader">Wildcard:</td>
                        <td class="WildcardName">{{ editionData.single.main.wildCards.entries[0] }}</td>
                        <td class="WildcardHeader">{{ editionData.single.main.wildCards.entries[1] != null ? 'Wildcard:' : '' }}</td>
                        <td class="WildcardName">{{ editionData.single.main.wildCards.entries[1] }}</td>
                    </tr>
                    <tr v-if="editionData.single.main.numberOfWildcards > 2">
                        <td class="WildcardHeader">Wildcard:</td>
                        <td class="WildcardName">{{ editionData.single.main.wildCards.entries[2] }}</td>
                        <td class="WildcardHeader">{{ editionData.single.main.wildCards.entries[3] != null ? 'Wildcard:' : '' }}</td>
                        <td class="WildcardName">{{ editionData.single.main.wildCards.entries[3] }}</td>
                    </tr>
                    <tr v-if="editionData.single.main.numberOfWildcards > 4">
                        <td class="WildcardHeader">Wildcard:</td>
                        <td class="WildcardName">{{ editionData.single.main.wildCards.entries[4] }}</td>
                        <td class="WildcardHeader">{{ editionData.single.main.wildCards.entries[5] != null ? 'Wildcard:' : '' }}</td>
                        <td class="WildcardName">{{ editionData.single.main.wildCards.entries[5] }}</td>
                    </tr>
                    <tr v-if="editionData.single.main.numberOfWildcards > 6">
                        <td class="WildcardHeader">Wildcard:</td>
                        <td class="WildcardName">{{ editionData.single.main.wildCards.entries[6] }}</td>
                        <td class="WildcardHeader">{{ editionData.single.main.wildCards.entries[7] != null ? 'Wildcard:' : '' }}</td>
                        <td class="WildcardName">{{ editionData.single.main.wildCards.entries[7] }}</td>
                    </tr>
                    <tr v-if="editionData.single.main.numberOfExemptions > 0">
                        <td class="ExemptHeader">Exempt:</td>
                        <td class="ExemptName">{{ editionData.single.main.exemptions.entries[0] }}</td>
                        <td class="ExemptHeader">{{ editionData.single.main.exemptions.entries[1] != null ? 'Exempt:' : '' }}</td>
                        <td class="ExemptName">{{ editionData.single.main.exemptions.entries[1] }}</td>
                    </tr>
                    <tr v-if="editionData.single.main.numberOfExemptions > 2">
                        <td class="ExemptHeader">Exempt:</td>
                        <td class="ExemptName">{{ editionData.single.main.exemptions.entries[2] }}</td>
                        <td class="ExemptHeader">{{ editionData.single.main.exemptions.entries[3] != null ? 'Exempt:' : '' }}</td>
                        <td class="ExemptName">{{ editionData.single.main.exemptions.entries[4] }}</td>
                    </tr>
                </table>

                <table style="margin: auto; text-align: left; margin-top: 10px" v-if="round === 'Q' && mode === 'S'">
                    <tr v-if="editionData.single.qualification.numberOfWildcards > 0">
                        <td class="WildcardHeader">Wildcard:</td>
                        <td class="WildcardName">{{ editionData.single.qualification.wildCards.entries[0] }}</td>
                        <td class="WildcardHeader">{{ editionData.single.qualification.wildCards.entries[1] != null ? 'Wildcard:' : '' }}</td>
                        <td class="WildcardName">{{ editionData.single.qualification.wildCards.entries[1] }}</td>
                    </tr>
                    <tr v-if="editionData.single.qualification.numberOfWildcards > 2">
                        <td class="WildcardHeader">Wildcard:</td>
                        <td class="WildcardName">{{ editionData.single.qualification.wildCards.entries[2] }}</td>
                        <td class="WildcardHeader">{{ editionData.single.qualification.wildCards.entries[3] != null ? 'Wildcard:' : '' }}</td>
                        <td class="WildcardName">{{ editionData.single.qualification.wildCards.entries[3] }}</td>
                    </tr>
                    <tr v-if="editionData.single.qualification.numberOfWildcards > 4">
                        <td class="WildcardHeader">Wildcard:</td>
                        <td class="WildcardName">{{ editionData.single.qualification.wildCards.entries[4] }}</td>
                        <td class="WildcardHeader">{{ editionData.single.qualification.wildCards.entries[5] != null ? 'Wildcard:' : '' }}</td>
                        <td class="WildcardName">{{ editionData.single.qualification.wildCards.entries[5] }}</td>
                    </tr>
                    <tr v-if="editionData.single.qualification.numberOfWildcards > 6">
                        <td class="WildcardHeader">Wildcard:</td>
                        <td class="WildcardName">{{ editionData.single.qualification.wildCards.entries[6] }}</td>
                        <td class="WildcardHeader">{{ editionData.single.qualification.wildCards.entries[7] != null ? 'Wildcard:' : '' }}</td>
                        <td class="WildcardName">{{ editionData.single.qualification.wildCards.entries[7] }}</td>
                    </tr>
                    <tr v-if="editionData.single.qualification.numberOfExemptions > 0">
                        <td class="ExemptHeader">Exempt:</td>
                        <td class="ExemptName">{{ editionData.single.qualification.exemptions.entries[0] }}</td>
                        <td class="ExemptHeader">{{ editionData.single.qualification.exemptions.entries[1] != null ? 'Exempt:' : '' }}</td>
                        <td class="ExemptName">{{ editionData.single.qualification.exemptions.entries[1] }}</td>
                    </tr>
                    <tr v-if="editionData.single.qualification.numberOfExemptions > 2">
                        <td class="ExemptHeader">Exempt:</td>
                        <td class="ExemptName">{{ editionData.single.qualification.exemptions.entries[2] }}</td>
                        <td class="ExemptHeader">{{ editionData.single.qualification.exemptions.entries[3] != null ? 'Exempt:' : '' }}</td>
                        <td class="ExemptName">{{ editionData.single.qualification.exemptions.entries[3] }}</td>
                    </tr>
                </table>

                <div v-if="tournamentTop10AcceptedPlayers(acceptanceList.singles.main.draw).length > 0" style="text-align: center; padding-top: 6px">
                    This tournament has Exception Players
                    {{ tournamentTop10AcceptedPlayers(acceptanceList.singles.main.draw).join(',') }}
                </div>
            </div>

            <div v-show="dataLoaded && !loadingAcceptanceList" class="list" id="allLists">
                <div v-show="this.mode === 'S' && this.round === 'M'">
                    <div v-show="!loadingAcceptanceList && acceptanceList.singles.main.draw.length > 0">
                        <h3>Main Draw</h3>
                        <div>
                            <data-table
                                v-show="!useCurrentRanks"
                                v-loading="loadingAcceptanceList"
                                :tr-formatter="(e) => (e.status !== 'AcceptedIntoMain' ? 'text-decoration: line-through;opacity: 0.5;' : '')"
                                :items="acceptanceList.singles.main.draw.filter((e) => e.orgStatus !== 'MM')"
                                :schema="singlesSchema"
                                class="SinglesList noprint"
                                :auto-sort="false"
                                :align-all="'left'"
                            />
                            <data-table
                                v-show="useCurrentRanks"
                                v-loading="loadingAcceptanceList"
                                :tr-formatter="(e) => (e.status !== 'AcceptedIntoMain' ? 'text-decoration: line-through;opacity: 0.5;' : '')"
                                :items="acceptanceList.singles.main.draw.filter((e) => e.orgStatus !== 'MM')"
                                :schema="singlesSchemaCurrentRanks"
                                class="SinglesList noprint"
                                :auto-sort="false"
                                :align-all="'left'"
                            />
                        </div>
                    </div>

                    <singles-entry-list
                        v-if="acceptanceList.singles.main.draw.length > 0"
                        :draw="acceptanceList.singles.main.draw.filter((e) => e.orgStatus !== 'MM')"
                        drawName="Main"
                        :edition="editionData"
                        :use-current-ranks="useCurrentRanks"
                        class="noscreen draw"
                        :slice-size="Math.floor((acceptanceList.singles.main.draw.length + 1) / 2)"
                    />

                    <div v-if="acceptanceList.singles.main.draw.filter((e) => e.orgStatus === 'MM').length > 0" class="noscreen divider" style="clear: both">
                        &nbsp;<br />
                    </div>
                    <div v-if="acceptanceList.singles.main.draw.filter((e) => e.orgStatus === 'MM').length > 0">
                        <h3>Moved In</h3>
                        <div>
                            <data-table
                                v-show="!useCurrentRanks"
                                v-loading="loadingAcceptanceList"
                                :tr-formatter="(e) => (e.status !== 'AcceptedIntoMain' ? 'text-decoration: line-through;opacity: 0.5;' : '')"
                                :items="acceptanceList.singles.main.draw.filter((e) => e.orgStatus === 'MM')"
                                :schema="singlesSchema"
                                class="SinglesList noprint"
                                :auto-sort="false"
                                :align-all="'left'"
                            />
                            <data-table
                                v-show="useCurrentRanks"
                                v-loading="loadingAcceptanceList"
                                :tr-formatter="(e) => (e.status !== 'AcceptedIntoMain' ? 'text-decoration: line-through;opacity: 0.5;' : '')"
                                :items="acceptanceList.singles.main.draw.filter((e) => e.orgStatus === 'MM')"
                                :schema="singlesSchemaCurrentRanks"
                                class="SinglesList noprint"
                                :auto-sort="false"
                                :align-all="'left'"
                            />
                        </div>
                    </div>

                    <singles-entry-list
                        v-if="acceptanceList.singles.main.draw.filter((e) => e.orgStatus === 'MM').length > 0"
                        :draw="acceptanceList.singles.main.draw.filter((e) => e.orgStatus === 'MM')"
                        drawName="Main"
                        :edition="editionData"
                        :use-current-ranks="useCurrentRanks"
                        class="noscreen draw"
                        :slice-size="Math.floor((acceptanceList.singles.main.draw.filter((e) => e.orgStatus === 'MM').length + 1) / 2)"
                    />

                    <div class="noscreen divider" style="clear: both">&nbsp;<br /></div>
                    <div v-show="!loadingAcceptanceList && acceptanceList.singles.main.alternates.length > 0">
                        <h3>Main Draw Alternates</h3>
                        <div>
                            <data-table
                                v-show="!useCurrentRanks"
                                v-loading="loadingAcceptanceList"
                                :tr-formatter="(e) => (e.status !== 'Entered' ? 'text-decoration: line-through;opacity: 0.5;' : '')"
                                :items="acceptanceList.singles.main.alternates"
                                :schema="singlesSchema"
                                class="SinglesList noprint"
                                :auto-sort="false"
                                :align-all="'left'"
                            />
                            <data-table
                                v-show="useCurrentRanks"
                                v-loading="loadingAcceptanceList"
                                :tr-formatter="(e) => (e.status !== 'Entered' ? 'text-decoration: line-through;opacity: 0.5;' : '')"
                                :items="acceptanceList.singles.main.alternates"
                                :schema="singlesSchemaCurrentRanks"
                                class="SinglesList noprint"
                                :auto-sort="false"
                                :align-all="'left'"
                            />
                        </div>
                    </div>

                    <singles-entry-list
                        :draw="acceptanceList.singles.main.alternates"
                        drawName="Main"
                        :use-current-ranks="useCurrentRanks"
                        class="noscreen draw"
                        :edition="editionData"
                    />
                </div>

                <div v-show="this.mode === 'S' && this.round === 'Q'">
                    <div v-show="loadingAcceptanceList || acceptanceList.singles.quali.draw.length > 0">
                        <h3>Qualifying Draw</h3>
                        <data-table
                            v-show="!useCurrentRanks"
                            v-loading="loadingAcceptanceList"
                            :tr-formatter="(e) => (e.status !== 'AcceptedIntoQualification' ? 'text-decoration: line-through;opacity: 0.5;' : '')"
                            :items="acceptanceList.singles.quali.draw.filter((e) => e.orgStatus !== 'MM')"
                            :schema="singlesSchema"
                            class="SinglesList noprint"
                            :auto-sort="false"
                            :align-all="'left'"
                        />
                        <data-table
                            v-show="useCurrentRanks"
                            v-loading="loadingAcceptanceList"
                            :tr-formatter="(e) => (e.status !== 'AcceptedIntoQualification' ? 'text-decoration: line-through;opacity: 0.5;' : '')"
                            :items="acceptanceList.singles.quali.draw.filter((e) => e.orgStatus !== 'MM')"
                            :schema="singlesSchemaCurrentRanks"
                            class="SinglesList noprint"
                            :auto-sort="false"
                            :align-all="'left'"
                        />
                    </div>

                    <singles-entry-list
                        v-if="acceptanceList.singles.quali.draw.length > 0"
                        :draw="acceptanceList.singles.quali.draw.filter((e) => e.orgStatus !== 'MM')"
                        drawName="Qualification"
                        :edition="editionData"
                        :use-current-ranks="useCurrentRanks"
                        class="noscreen draw"
                        :slice-size="Math.floor((acceptanceList.singles.quali.draw.length + 1) / 2)"
                    />

                    <div v-if="acceptanceList.singles.quali.draw.filter((e) => e.orgStatus === 'MM').length > 0" class="noscreen divider" style="clear: both">
                        &nbsp;<br />
                    </div>
                    <div v-if="acceptanceList.singles.quali.draw.filter((e) => e.orgStatus === 'MM').length > 0">
                        <h3>Moved In</h3>
                        <div>
                            <data-table
                                v-show="!useCurrentRanks"
                                v-loading="loadingAcceptanceList"
                                :tr-formatter="(e) => (e.status !== 'AcceptedIntoQualification' ? 'text-decoration: line-through;opacity: 0.5;' : '')"
                                :items="acceptanceList.singles.quali.draw.filter((e) => e.orgStatus === 'MM')"
                                :schema="singlesSchema"
                                class="SinglesList noprint"
                                :auto-sort="false"
                                :align-all="'left'"
                            />
                            <data-table
                                v-show="useCurrentRanks"
                                v-loading="loadingAcceptanceList"
                                :tr-formatter="(e) => (e.status !== 'AcceptedIntoQualification' ? 'text-decoration: line-through;opacity: 0.5;' : '')"
                                :items="acceptanceList.singles.quali.draw.filter((e) => e.orgStatus === 'MM')"
                                :schema="singlesSchemaCurrentRanks"
                                class="SinglesList noprint"
                                :auto-sort="false"
                                :align-all="'left'"
                            />
                        </div>
                    </div>

                    <singles-entry-list
                        v-if="acceptanceList.singles.quali.draw.filter((e) => e.orgStatus === 'MM').length > 0"
                        :draw="acceptanceList.singles.quali.draw.filter((e) => e.orgStatus === 'MM')"
                        drawName="Qualification"
                        :edition="editionData"
                        :use-current-ranks="useCurrentRanks"
                        class="noscreen draw"
                        :slice-size="Math.floor((acceptanceList.singles.quali.draw.filter((e) => e.orgStatus === 'MM').length + 1) / 2)"
                    />

                    <div class="noscreen divider" style="clear: both">&nbsp;<br /></div>
                    <div v-show="loadingAcceptanceList || acceptanceList.singles.quali.alternates.length > 0">
                        <h3>Qualifying Alternates</h3>
                        <data-table
                            v-show="!useCurrentRanks"
                            v-loading="loadingAcceptanceList"
                            :tr-formatter="(e) => (e.status !== 'Entered' ? 'text-decoration: line-through;opacity: 0.5;' : '')"
                            :items="acceptanceList.singles.quali.alternates"
                            :schema="singlesSchema"
                            class="SinglesList noprint"
                            :auto-sort="false"
                            :align-all="'left'"
                        />
                        <data-table
                            v-show="useCurrentRanks"
                            v-loading="loadingAcceptanceList"
                            :tr-formatter="(e) => (e.status !== 'Entered' ? 'text-decoration: line-through;opacity: 0.5;' : '')"
                            :items="acceptanceList.singles.quali.alternates"
                            :schema="singlesSchemaCurrentRanks"
                            class="SinglesList noprint"
                            :auto-sort="false"
                            :align-all="'left'"
                        />
                    </div>

                    <singles-entry-list
                        :draw="acceptanceList.singles.quali.alternates"
                        drawName="Qualification"
                        :edition="editionData"
                        :use-current-ranks="useCurrentRanks"
                        class="noscreen"
                    />
                </div>
                <div v-show="mode === 'D'" style="margin-bottom: 100px">
                    <div v-show="loadingAcceptanceList || acceptanceList.doubles.draw.length > 0">
                        <h3>Doubles Draw</h3>
                        <data-table
                            v-show="!useCurrentRanks"
                            v-loading="loadingAcceptanceList"
                            :items="acceptanceList.doubles.draw"
                            :schema="doublesSchema"
                            class="DoublesList noprint"
                            :auto-sort="false"
                            :align-all="'left'"
                            :tr-formatter="(e) => (e.status !== 'AcceptedIntoMain' ? 'text-decoration: line-through;opacity: 0.5;' : '')"
                        />
                        <data-table
                            v-show="useCurrentRanks"
                            v-loading="loadingAcceptanceList"
                            :items="acceptanceList.doubles.draw"
                            :schema="doublesSchemaCurrentRanks"
                            class="DoublesList noprint"
                            :auto-sort="false"
                            :align-all="'left'"
                            :tr-formatter="(e) => (e.status !== 'AcceptedIntoMain' ? 'text-decoration: line-through;opacity: 0.5;' : '')"
                        />
                    </div>

                    <DoublesEntryList
                        v-if="acceptanceList.doubles.draw.length > 0"
                        :draw="acceptanceList.doubles.draw"
                        class="draw noscreen"
                        :edition="editionData"
                    />

                    <div v-show="loadingAcceptanceList || acceptanceList.doubles.alternates.length > 0">
                        <h3>Doubles Alternates</h3>
                        <data-table
                            v-show="!useCurrentRanks"
                            v-loading="loadingAcceptanceList"
                            :items="acceptanceList.doubles.alternates"
                            :schema="doublesSchema"
                            class="DoublesList noprint"
                            :auto-sort="false"
                            :align-all="'left'"
                            :tr-formatter="(e) => (e.status !== 'Entered' ? 'text-decoration: line-through;opacity: 0.5;' : '')"
                        />
                        <data-table
                            v-show="useCurrentRanks"
                            v-loading="loadingAcceptanceList"
                            :items="acceptanceList.doubles.alternates"
                            :schema="doublesSchemaCurrentRanks"
                            class="DoublesList noprint"
                            :auto-sort="false"
                            :align-all="'left'"
                            :tr-formatter="(e) => (e.status !== 'Entered' ? 'text-decoration: line-through;opacity: 0.5;' : '')"
                        />
                    </div>

                    <DoublesEntryList :draw="acceptanceList.doubles.alternates" class="noscreen" :edition="editionData" />
                </div>
                <div style="clear: both; text-align: center; width: 100%; padding-top: 20px; font-weight: bold" v-if="dataLoaded && !loadingAcceptanceList">
                    <div style="float: right">Revised {{ dateFormat(new Date(), 'mmm dd yyyy HH:MM') }}</div>
                    <div style="float: left" v-if="this.mode === 'S' && this.round === 'M'">
                        Final Ranking {{ dateFormat(editionData.single.main.entryDeadline, 'mmm dd yyyy') }}
                    </div>
                    <div style="float: left" v-if="this.mode === 'S' && this.round === 'Q'">
                        Final Ranking {{ dateFormat(editionData.single.qualification.entryDeadline, 'mmm dd yyyy') }}
                    </div>
                </div>
                <div>
                    <el-button
                        @click="print"
                        style="display: block; margin-left: auto; margin-right: auto; margin-top: -20px"
                        type="primary"
                        icon="el-icon-printer"
                        v-show="dataLoaded && !loadingAcceptanceList"
                        class="noprint"
                        >Print
                    </el-button>
                </div>
            </div>
        </div>
        <div style="display: block; height: 50px">&nbsp;</div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { AcceptanceListDoublesEntry, AcceptanceListSinglesEntry } from '@/models/acceptance-list-entry';
import DateFormat from 'dateformat';
import { Printd } from 'printd';
import SinglesEntryList from './SinglesEntryList';
import TournamentInfoGeneral from './TournamentInfoGeneral';
import TournamentInfoDetails from './TournamentInfoDetails';
import DoublesEntryList from './DoublesEntryList';
import * as moment from 'moment';
import axios from 'axios';
import { isBaselineReader } from '../services/authGroups';

export default {
    components: {
        DoublesEntryList,
        TournamentInfoDetails,
        TournamentInfoGeneral,
        SinglesEntryList,
    },
    data() {
        return {
            edition: null,
            mode: 'S',
            round: 'M',
            singlesSchema: AcceptanceListSinglesEntry.schema,
            doublesSchema: AcceptanceListDoublesEntry.schema,
            singlesSchemaCurrentRanks: AcceptanceListSinglesEntry.schemaCurrentRanks,
            doublesSchemaCurrentRanks: AcceptanceListDoublesEntry.schemaCurrentRanks,
            loadedEdition: null,
            publishLoading: false,
            sliceSize: 60,
            isBaselineReader: isBaselineReader(),
        };
    },
    mounted() {
        this.findEditionsForAcceptanceList(
            moment(process.env.VUE_APP_DATABASE_DATE !== undefined ? process.env.VUE_APP_DATABASE_DATE : undefined).format('YYYY-MM-DD'),
        );
    },
    computed: {
        ...mapGetters('acceptanceList', ['editions', 'acceptanceList', 'loadingAcceptanceList', 'useCurrentRanks', 'editionData']),
        shortLevel: function () {
            switch (this.editionData.level) {
                case 'Challenger':
                    return 'C';
                case 'International':
                    return '250';
                case 'Premier':
                    return '500';
                case 'Premier5':
                    return '1000';
                case 'PremierMandatory':
                    return '1000';
                case 'Championship':
                    return 'CH';
                case 'GrandSlam':
                    return 'GS';
                default:
                    return this.editionData.level;
            }
        },
        prettyLevel: function () {
            switch (this.editionData.level) {
                case 'Premier5':
                    return 'WTA 1000';
                case 'PremierMandatory':
                    return 'WTA 1000';
                case 'Premier':
                    return 'WTA 500';
                case 'International':
                    return 'WTA 250';
                case 'Challenger':
                    return 'WTA 125';
                case 'GrandSlam':
                    return 'Grand Slam';
                default:
                    return this.editionData.level;
            }
        },
        currencyIso4217: function () {
            switch (this.editionData.currency) {
                case 'P':
                    return 'GBP';
                case 'D':
                    return 'USD';
                case 'E':
                    return 'EUR';
                default:
                    return 'USD';
            }
        },
        expandedRound: function () {
            switch (this.round) {
                case 'M':
                    return 'Main';
                case 'Q':
                    return 'Qualification';
                default:
                    return '';
            }
        },
        expandedCurrency: function () {
            switch (this.editionData.currency) {
                case 'P':
                    return 'Pounds';
                case 'D':
                    return 'US Dollars';
                case 'E':
                    return 'Euros';
                default:
                    return 'Dollars';
            }
        },
        expandedBallType: function () {
            switch (this.editionData.ball) {
                case 'W':
                    return 'Wilson';
                case 'B':
                    return 'Babolat';
                case 'P':
                    return 'Penn';
                case 'S':
                    return 'Slazenger';
                case 'T':
                    return 'Tretorn';
                default:
                    return '—';
            }
        },
        expandedMode: function () {
            return this.mode === 'S' ? 'Singles' : 'Doubles';
        },
        currencyFormatter: function () {
            return new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: this.currencyIso4217,
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
            });
        },
        selectedEdition: function () {
            return this.edition + this.useCurrentRanks;
        },
        dataLoaded: function () {
            return this.selectedEdition === this.loadedEdition;
        },
    },
    methods: {
        ...mapActions('acceptanceList', ['findEditionsForAcceptanceList', 'findByEdition', 'setUseCurrentRanks']),
        run() {
            this.findByEdition(this.edition);
            this.loadedEdition = this.edition + this.useCurrentRanks;
        },

        updateUseCurrentRanks(value) {
            this.$store.commit('acceptanceList/setUseCurrentRanks', value);
        },

        publish() {
            this.publishLoading = true;
            if (this.mode === 'S') {
                if (this.round === 'M') {
                    axios
                        .get(`${process.env.VUE_APP_API_URL}/Edition/PublishEdition/${this.editionData.season}/${this.editionData.tournamentId}/1/M`)
                        .then(() => {
                            this.publishLoading = false;
                            this.run();
                        });
                } else {
                    axios
                        .get(`${process.env.VUE_APP_API_URL}/Edition/PublishEdition/${this.editionData.season}/${this.editionData.tournamentId}/1/Q`)
                        .then(() => {
                            this.publishLoading = false;
                            this.run();
                        });
                }
            } else {
                axios.get(`${process.env.VUE_APP_API_URL}/Edition/PublishEdition/${this.editionData.season}/${this.editionData.tournamentId}/0/M`).then(() => {
                    this.publishLoading = false;
                    this.run();
                });
            }
        },

        unpublish() {
            this.publishLoading = true;
            if (this.mode === 'S') {
                if (this.round === 'M') {
                    axios
                        .get(`${process.env.VUE_APP_API_URL}/Edition/UnPublishEdition/${this.editionData.season}/${this.editionData.tournamentId}/1/M`)
                        .then(() => {
                            this.editionData.tournamentAcceptanceListSinglesMainIsPublished = false;
                            this.publishLoading = false;
                        });
                } else {
                    axios
                        .get(`${process.env.VUE_APP_API_URL}/Edition/UnPublishEdition/${this.editionData.season}/${this.editionData.tournamentId}/1/Q`)
                        .then(() => {
                            this.editionData.tournamentAcceptanceListSinglesQualiIsPublished = false;
                            this.publishLoading = false;
                        });
                }
            } else {
                axios
                    .get(`${process.env.VUE_APP_API_URL}/Edition/UnPublishEdition/${this.editionData.season}/${this.editionData.tournamentId}/0/M`)
                    .then(() => {
                        this.editionData.tournamentAcceptanceListDoublesMainIsPublished = false;
                        this.publishLoading = false;
                    });
            }
        },

        dateFormat(value, format) {
            return DateFormat(value, format);
        },

        currencyFormat(value) {
            return this.currencyFormatter.format(value);
        },
        print() {
            const d = new Printd();

            const css = [
                (this.$browserDetect.isFirefox ? '' : '') +
                    `
            #forPrintOut {
              font-size: 11pt;
              font-family: Arial, Helvetica, sans-serif;
              line-height: 13px;
            }

            .wtaLogo {
                height:79px;
                width:155px;
            }

            .tournament_info_general {
                font-size: 150%;
                font-weight: 600;
                line-height: 1.2;
                margin-top: 0 !important;
                padding-top: 0px !important;
            }

            .tournament_info_general .level {
                margin-left: 8px;
            }

            .noprint {
                display: none;
            }

            .entryList table {
                width: 80%;
                margin-left: auto;
                margin-right: auto;
            }

            .entryList td {
                text-align: left;
            }

            .entryList th {
                text-align: left;
            }

            .entryList td.pos {
              text-align: right;
              padding-right: 11px;
            }

            #allLists h3 {
                text-align: center;
                font-size: 120%;
                text-transform: uppercase;
            }

            .page-break {
              page-break-before: always;
            }

            .entryList.draw {
                border-bottom: 1px solid black;
            }

            .tournament_info_details {
                display: flex;
                align-items: start-flex;
                justify-content: space-between;
                font-weight: normal;
                margin-top: 20px;
                border: 0;
            }

            .tournament_info_details td {
                line-height: 9px;
            }

            .tournament_info_details table {
                border-collapse: collapse;
                margin-left: 5px;
                margin-right: 5px;
                border: 0;
            }

            .tournament_info_details td {
                border: 1pt solid black;
                white-space: nowrap;
                padding: 5px;
            }

            .tournament_info_details th {
                border: 1pt solid black;
                white-space: nowrap;
                background: rgb(254,254,198) !important;
                padding: 7px;
            }

            .tournament_info_general {
                display: block;
                width: 100%;
            }

            .tournament_info_general .details
            {
                display: flex;
                justify-content: space-between;
                flex-wrap: nowrap;

            }

            .printText {
                white-space: nowrap!;
            }

            .WildcardsAndExemptions .WildcardName,
            .WildcardsAndExemptions .ExemptName {
                padding-right: 5em;
                font-family: "Courier New", Courier, monospace;
            }

            .divider {
              width: 98%;
              padding-top: 5px;
              border-bottom: 1px solid black;
            }
            `,
            ];

            d.print(document.getElementById('forPrintOut'), css);
        },
        tournamentTop10AcceptedPlayers(entriesSelected) {
            let result = [];
            if (entriesSelected !== undefined) {
                entriesSelected.forEach((entry) => {
                    if (
                        entry.isConfirmedPlayerForInternationalTournaments !== undefined &&
                        entry.isConfirmedPlayerForInternationalTournaments !== '' &&
                        entry.isConfirmedPlayerForInternationalTournaments !== null
                    ) {
                        result.push(entry.playerName);
                    }
                });
            }

            return result;
        },
    },
};
</script>
<!--#33135b-->
<style>
.AL {
    font-family: 'Open Sans', Arial, sans-serif !important;
}

.AL h3 {
    margin-top: 25px;
}

.list {
    width: 95%;
    margin-left: auto;
    margin-right: auto;
}

.list h3 {
    text-align: center;
    line-height: 1.28571429em;
    font-weight: 900;
    font-size: 24px;
}

.SinglesList {
    margin-bottom: 75px;
}

.SinglesList tr td:nth-child(1),
.DoublesList tr td:nth-child(1) {
    font-weight: 900;
}

.SinglesList tr td:nth-child(3),
.DoublesList tr td:nth-child(4),
.DoublesList tr td:nth-child(8) {
    font-size: 20px;
    font-style: italic;
    font-weight: 900;
    color: #666666;
}

.WildcardsAndExemptions table {
    padding-bottom: 10px;
}

.WildcardsAndExemptions tr td:nth-child(1),
.WildcardsAndExemptions tr td:nth-child(3) {
    font-weight: 900;
    color: #33135b;
}

.WildcardsAndExemptions .WildcardHeader,
.WildcardsAndExemptions .ExemptHeader {
    width: 5em;
}

.WildcardsAndExemptions .WildcardName,
.WildcardsAndExemptions .ExemptName {
    padding-right: 3em;
}

.SinglesList tr td,
.DoublesList tr td {
    text-align: left;
}

.el-table th > .cell {
    color: #33135b;
    font-weight: 900;
}

#tournData {
    width: 100%;
    text-align: center;
}

#allLists h3 {
    margin-top: 50px !important;
}

#allLists {
    width: 90%;
}

.wtaLogo {
    display: none;
}

.not_published {
    padding: 20px;
    text-align: center;
    background-color: red;
    font-weight: bold;
    margin: 20px 10px 0 10px;
    font-size: 20px;
}

.published {
    padding: 20px;
    text-align: center;
    background-color: green;
    color: white;
    font-weight: bold;
    margin: 20px 10px 0 10px;
    font-size: 20px;
}

.publish_button {
    margin-left: 30px;
    top: -3px;
    position: relative;
}

.collapsible-help {
    margin-bottom: 25px;
}

.tournament_info_general {
    font-size: 110%;
    font-weight: 600;
    line-height: 1.2;
    margin-top: 25px;
}
</style>
