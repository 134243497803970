<template>
    <div v-if="requestError" class="flex-column mt-0 mb-8">
        <el-alert type="error" title="Error" :description="description" @close="clearError" show-icon> </el-alert>
        <el-collapse v-if="requestError.response && requestError.response.response && requestError.response.data.StatusCode === 500">
            <el-collapse-item title="Debug Information about error. Useful for developers. Click to show/ hide">
                <el-alert type="error" :closable="false">
                    <p class="preserve-whitespace">{{ requestError.response.data.StackTrace }}</p>
                </el-alert>
            </el-collapse-item>
        </el-collapse>
    </div>
</template>

<script>
export default {
    props: ['requestError'],
    data() {
        return {};
    },
    mounted() {},
    computed: {
        description() {
          const getErrorMessage = () => {
            if (this.requestError.response) {
              return this.requestError.response.data?.message || this.requestError.message;
            }

            if (this.requestError.request) {
              return 'No response from server';
            }

            return this.requestError.message;
          };

          return getErrorMessage() || 'An unspecified error occurred';
        },
    },
    methods: {
        clearError() {
            this.$emit('closed');
        },
    },
};
</script>

<style scoped>
.preserve-whitespace {
    white-space: pre-line;
}
</style>
