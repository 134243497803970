import { Model, isValid } from './model';

export class TournamentResult extends Model {
    constructor(data) {
        if (data && data.rank === 0) {
            data.rank = 9999;
        }
        super(data);
    }
    isValid() {
        return isValid(TournamentResult.schema, this);
    }
    isValidForAdd() {
        return isValid(TournamentResult.schema, this);
    }
    isValidForEdit() {
        return isValid(TournamentResult.schema, this);
    }
}


export class TournamentResultSums extends Model {
    isValid() {
        return isValid(TournamentResult.schema, this);
    }
}

TournamentResult.layout = {
    children: [
        {
            children: [{ id: 'playerId', width: 2 }, 'age', 'rank', { id: 'partnerId', width: 2 }, 'partnerAge',],
        },
        {
            children: ['sdFlag', 'qpmFlag', 'round', 'roundOfPlay', 'violation1', { type: 'spacer', width: 2 },],
        },
        {
            title: 'Main Draw Info',
            children: ['drawPosition', 'seed', 'entryMethod', 'points', 'pointsChampionship', { id: 'prizeMoney', width: 2 },],
        },
        {
            title: 'Qualification Draw Info',
            children: ['drawPositionQualifying', 'seedQualifying', 'entryMethodQualifying', 'pointsQualifying', { type: 'spacer' }, { id: 'prizeMoneyQualifying', width: 2 },],
        },
    ],
};

TournamentResult.schema = {
    fields: {
        tournamentId: { type: 'number', displayName: 'Tournament Id', required: true },
        season: { type: 'number', displayName: 'Year', required: true },
        playerId: { type: 'player', displayName: 'Player', required: true },
        sdFlag: {
            type: 'string', displayName: 'S/D', required: true, maxLength: 2,
            lookup: [
                { id: 'S', displayName: 'Singles' },
                { id: 'D', displayName: 'Doubles' },
            ],
        },
        status: { type: 'number', displayName: 'Status', clearable: true, },
        seed: { type: 'number', displayName: 'Seed', clearable: true, },
        drawPosition: { type: 'number', displayName: 'Draw', required: true, },
        entryMethod: {
            type: 'string', displayName: 'Entry', maxLength: 2, clearable: true,
            lookup: [
                { id: ' ', displayName: '-' },
                { id: 'P', displayName: 'Use Of Protected Rank' },
                { id: 'E', displayName: 'Elimination' },
                { id: 'L', displayName: 'Lucky Loser' },
                { id: 'Q', displayName: 'Qualifier' },
                { id: 'S', displayName: 'Special Exempt' },
                { id: 'A', displayName: 'Alternate' },
                { id: 'W', displayName: 'Wildcard' },
            ],
        },
        specialRankCd: { type: 'string', displayName: 'Special Rank Cd', maxLength: 2, clearable: true, },
        lateFlag: { type: 'string', displayName: 'Late Flag', maxLength: 2, clearable: true, },
        violation1: { type: 'string', displayName: 'Violation', maxLength: 2, clearable: true, },
        violation2: { type: 'string', displayName: 'Violation 2', maxLength: 2, clearable: true, },
        violation3: { type: 'string', displayName: 'Violation 3', maxLength: 2, clearable: true, },
        round: {
            type: 'sring', displayName: 'Round', maxLength: 4, clearable: true,
            lookup: [
                { id: '1', displayName: 'R128' },
                { id: '2', displayName: 'R64' },
                { id: '3', displayName: 'R32' },
                { id: '4', displayName: 'R16' },
                { id: '5', displayName: 'QF' },
                { id: '6', displayName: 'SF' },
                { id: '7', displayName: 'F' },
                { id: '8', displayName: 'Won' },
                { id: '11', displayName: 'R1' },
            ],
        },
        prizeMoney: { type: 'currency', displayName: 'Prize', min: 0, max: 9999999.99, clearable: true, },
        points: { type: 'number', displayName: 'Points', min: 0, max: 9999.9999, clearable: true, },
        pointsBonus: { type: 'number', displayName: 'Points Bonus', min: 0, max: 9999.9999, clearable: true, },
        pointsChampionship: { type: 'number', displayName: 'Points Champ', min: 0, max: 9999.9999, clearable: true, },
        rank: {
            type: 'number', displayName: 'Rank', min: 1, max: 9999, clearable: true,
            lookup: [
                { id: 0, displayName: 9999 },
            ],
        },
        rankSpecial: {
            type: 'number', displayName: 'Special Rank', min: 1, max: 9999, clearable: true,
            lookup: [
                { id: 0, displayName: 9999 },
            ],
        },
        rankCalc: {
            type: 'number', displayName: 'Rank Calc', min: 1, max: 9999, clearable: true,
            lookup: [
                { id: 0, displayName: 9999 },
            ],
        },
        processFlag: { type: 'string', displayName: 'Process Flag', maxLength: 2, clearable: true, },
        processDate: { type: 'us-date', displayName: 'Process Date', clearable: true, },
        comment: { type: 'string', displayName: 'Comment', maxLength: 80, clearable: true, },
        roundOfPlay: { type: 'number', displayName: 'Round #', clearable: true, min: 0, max: 65535, },
        partnerId: { type: 'player', displayName: 'Partner', clearable: true, },
        qpmFlag: {
            displayName: 'Q/M', type: 'string', group: 'general', clearable: true, maxLength: 2,
            lookup: [
                { id: 'M', displayName: 'Main' },
                { id: 'Q', displayName: 'Quali' },
            ],
        },
        age: { type: 'number', displayName: 'Age', clearable: true, min: 0, max: 255, },
        partnerAge: { type: 'number', displayName: 'Partner Age', clearable: true, min: 0, max: 255, },
        prizeRule: { type: 'number', displayName: 'Prize Rule', clearable: true, min: 0, max: 255, },
        entryMethodQualifying: {
            type: 'string', displayName: 'Entry Q', clearable: true, maxLength: 2,
            lookup: [
                { id: ' ', displayName: '-' },
                { id: 'P', displayName: 'Use Of Protected Rank' },
                { id: 'E', displayName: 'Elimination' },
                { id: 'L', displayName: 'Lucky Loser' },
                { id: 'Q', displayName: 'Qualifier' },
                { id: 'S', displayName: 'Special Exempt' },
                { id: 'A', displayName: 'Alternate' },
                { id: 'W', displayName: 'Wildcard' },
            ],
        },
        seedQualifying: { type: 'number', displayName: 'Seed Q', clearable: true, min: 0, max: 65535, },
        drawPositionQualifying: { type: 'number', displayName: 'Draw Q', clearable: true, min: 0, max: 65535, },
        roundQualifying: {
            type: 'number', displayName: 'Round Q', clearable: true, min: 0, max: 65535,
            lookup: [
                { id: 1, displayName: 'R128' },
                { id: 2, displayName: 'R64' },
                { id: 3, displayName: 'R32' },
                { id: 4, displayName: 'R16' },
                { id: 5, displayName: 'QF' },
                { id: 6, displayName: 'SF' },
                { id: 7, displayName: 'F' },
                { id: 8, displayName: 'Won' },
            ],
        },
        pointsQualifying: { type: 'number', displayName: 'Points Q', min: 0, max: 9999.9999, clearable: true, },
        pointsBonusQualifying: { type: 'number', displayName: 'Points Bonus Q', min: 0, max: 9999.9999, clearable: true, },
        prelimType: { type: 'string', displayName: 'Prelim Type', clearable: true, maxLength: 1, },
        pointsP: { type: 'number', displayName: 'Points P', min: 0, max: 9999.9999, clearable: true, },
        pointsBonusP: { type: 'number', displayName: 'Points Bonus P', min: 0, max: 9999.9999, clearable: true, },
        prizeMoneyQualifying: { type: 'currency', displayName: 'Prize Q', clearable: true, min: 0, max: 999999.99, },
        tournamentCategory: { type: 'string', displayName: 'Tournament Category', clearable: true, maxLength: 1, },
        tournamentCategoryR: { type: 'string', displayName: 'Tournament Category R', clearable: true, maxLength: 1, },
        tournamentCategoryM: { type: 'string', displayName: 'Tournament Category M', clearable: true, maxLength: 1, },
        includeForPdEligibleDates: { type: 'boolean', displayName: 'Include', clearable: true, },



        tournamentType: { type: 'string', displayName: 'Tournament Type', readonly: true, clearable: true, },
        tournamentLevel: { type: 'string', displayName: 'Tournament Level', readonly: true, clearable: true, },
        tournamentName: { type: 'string', displayName: 'Name', readonly: true, clearable: true, },
        startDate: { type: 'us-date', displayName: 'Start Date', readonly: true, clearable: true, },
        useForRankingDate: { type: 'us-date', displayName: ' Rank Date', readonly: true, clearable: true, },
        firstName: { type: 'string', displayName: 'First name', group: 'general', readonly: true, clearable: true, },
        lastName: { type: 'string', displayName: 'Last name', group: 'general', readonly: true, clearable: true, },
        middleName: { type: 'string', displayName: 'Middle name', group: 'general', readonly: true, clearable: true, },
        fullName: { displayName: 'Name', sortable: true, readonly: true, clearable: true, },
        partnerFullName: { displayName: 'Partner', readonly: true, clearable: true, },
        displayDrawPosition: { type: 'number', displayName: 'Draw', readonly: true, clearable: true, },
        originalPoints: {
            type: 'number', displayName: 'Original Points (ITF)', min: 0, max: 9999, readonly: true, clearable: true,
            columnNames: ['Original', 'Points', '(ITF)'],
        },
        droppedPoints: {
            type: 'number', displayName: 'Dropped Points (50% ITF)', min: 0, max: 9999, readonly: true, clearable: true,
            columnNames: ['Dropped', 'Points', '(50% from ITF)'],
        },
        tournamentPrizeMoney: { type: 'currency', displayName: 'Tournament Prize', readonly: true, clearable: true, },
        isIncludedIntoRanking: { type: 'boolean', displayName: 'Best 18/19', readonly: true, clearable: true, },
        isIncludedIntoRankingDoubles: { type: 'boolean', displayName: 'Best 12', readonly: true, clearable: true, },
        isRequired: { type: 'boolean', displayName: 'Must Count', readonly: true, clearable: true, },
        rankingLabel: { type: 'string', displayName: 'Description', readonly: true, clearable: true, },
        currency: {
            type: 'string', displayName: 'Currency', readonly: true, clearable: true,
            lookup: [
                { id: 'D', displayName: 'USD' },
                { id: 'E', displayName: 'EUR' },
                { id: 'A', displayName: 'AUD' },
                { id: 'P', displayName: 'GBP' },
                { id: 'No', displayName: 'No' },
            ],
        },
    },
};


TournamentResultSums.schema = {
    fields: {
        tournamentId: { type: 'number', displayName: 'Tournament Id', required: true },
        season: { type: 'number', displayName: 'Year', required: true },
        prizeMoney: { type: 'currency', displayName: 'Sum Prize', readonly: true, clearable: true, },
        points: { type: 'number', displayName: 'Sum Points', readonly: true, clearable: true, },
        pointsBonus: { type: 'number', displayName: 'Sum Points Bonus', readonly: true, clearable: true, },
        pointsChampionship: { type: 'number', displayName: 'Sum Points Champ', readonly: true, clearable: true, },
        pointsQualifying: { type: 'number', displayName: 'Sum Points Q', readonly: true, clearable: true, },
        pointsBonusQualifying: { type: 'number', displayName: 'Sum Points Bonus Q', readonly: true, clearable: true, },
        pointsP: { type: 'number', displayName: 'Sum Points P', readonly: true, clearable: true, },
        pointsBonusP: { type: 'number', displayName: 'Sum Points Bonus P', readonly: true, clearable: true, },
        prizeMoneyQualifying: { type: 'currency', displayName: 'Sum Prize Q', readonly: true, },
        currency: {
            type: 'string', displayName: 'Currency', readonly: true, clearable: true,
            lookup: [
                { id: 'D', displayName: 'USD' },
                { id: 'E', displayName: 'EUR' },
                { id: 'A', displayName: 'AUD' },
                { id: 'P', displayName: 'GBP' },
                { id: 'No', displayName: 'No' },
            ],
        },
        includeForPdEligibleDates: { type: 'boolean', displayName: 'Include' },
    },
};
