<template>
    <div>
        <el-dialog
            v-if="!isBaselineReader"
            :visible.sync="editDialogVisible"
            :close-on-click-modal="false"
            :title="mode === 'edit' ? 'Edit entry' : 'Add entry'"
            width="65%"
        >
            <FormGroup :model="selectedEntry" :schema="doublesEntriesSchema" :layout="layout" :readonlyForEdit="mode === 'edit'" @change="onChange" />
            <div class="dialog-footer">
                <el-button type="primary" @click="saveChanges" :disabled="!selectedEntry || !selectedEntry.hasChanges()" v-loading="savingDoublesEntry"
                    >Save changes</el-button
                >
                <el-button @click="editDialogVisible = false">Close</el-button>
                <span class="spacer"></span>
                <el-button v-if="mode === 'edit'" plain type="danger" @click="deleteEntry" v-loading="deletingDoublesEntry">Delete entry</el-button>
            </div>
        </el-dialog>

        <div class="flex-row mt-0">
            <span class="spacer"></span>
            <el-input
                size="mini"
                placeholder="Filter by player identifier"
                v-model="queryString"
                class="input-search mr-32"
                @keydown.esc.native="queryString = ''"
                :clearable="true"
            >
            </el-input>
            <el-button v-if="!isBaselineReader" @click="addEntry" plain type="primary" size="mini"> Add entry </el-button>
            <el-button @click="exportCsv" plain class="ml-8" size="mini">Export CSV</el-button>
        </div>

        <el-alert v-if="loadingError" :title="loadingError" type="error" class="mt-32" show-icon> </el-alert>

        <div v-loading="loadingDoublesEntries">
            <DataTable
                v-if="!loadingError"
                :items="filteredEntries"
                :fields="[
                    'combinedRank',
                    'playerFullName',
                    'playerCurrentDoublesRank',
                    'playerEntrySinglesRank',
                    'playerEntrySpecialRank',
                    'playerNationality',
                    'partnerFullName',
                    'partnerCurrentDoublesRank',
                    'partnerEntrySinglesRank',
                    'partnerEntrySpecialRank',
                    'partnerNationality',
                    'orgStatus',
                    'entryStatus',
                    'entryDate',
                    'enterTime',
                ]"
                :schema="doublesEntriesSchema"
                @item-clicked="itemClicked"
                :autoSort="true"
            />
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { DoublesEntry } from '../models/doubles-entry';
import { csvExporter } from '../utils/csv-exporter';
import moment from 'moment';
import FileSaver from 'file-saver';
import { isBaselineReader } from '../services/authGroups';

export default {
    props: ['tournamentId', 'tournamentYear'],
    data() {
        return {
            doublesEntriesSchema: DoublesEntry.schema,
            layout: DoublesEntry.layout,
            editDialogVisible: false,
            selectedEntry: null,
            mode: null,
            queryString: '',
            isBaselineReader: isBaselineReader(),
        };
    },
    mounted() {
        this.refresh();
    },
    computed: {
        ...mapGetters('doublesEntries', ['doublesEntries', 'loadingDoublesEntries', 'loadingError', 'deletingDoublesEntry', 'savingDoublesEntry']),
        filteredEntries() {
            if (!this.doublesEntries) {
                return [];
            }

            return this.doublesEntries.filter((r) => {
                return (
                    !this.queryString ||
                    !r.playerFullName ||
                    r.playerFullName.toLowerCase().includes(this.queryString.toLowerCase()) ||
                    r.partnerFullName.toLowerCase().includes(this.queryString.toLowerCase())
                );
            });
        },
    },
    methods: {
        exportCsv() {
            const data = csvExporter(this.doublesEntriesSchema, this.doublesEntries);
            const file = new File([data], `doubles_entries_${moment().format('DD_MM_YYYY HH_mm_SS')}.csv`, { type: 'text/csv' });
            FileSaver.saveAs(file);
        },
        ...mapActions('doublesEntries', ['findDoublesEntries', 'createDoublesEntry', 'updateDoublesEntry', 'deleteDoublesEntry']),
        refresh() {
            this.findDoublesEntries({
                id: this.tournamentId,
                year: this.tournamentYear,
            });
        },
        itemClicked(item) {
            this.selectedEntry = new DoublesEntry(item);
            this.mode = 'edit';
            this.editDialogVisible = true;
        },
        saveChanges() {
            if (this.mode === 'add') {
                this.createDoublesEntry(this.selectedEntry).then(() => {
                    this.refresh();
                    this.editDialogVisible = false;
                });
            } else {
                this.updateDoublesEntry(this.selectedEntry).then(() => {
                    this.refresh();
                    this.editDialogVisible = false;
                });
            }
        },
        onChange() {},
        deleteEntry() {
            this.$confirm('You are about to delete a current Tournament entry. Please confirm.', 'Delete tournament entry', {
                confirmButtonText: 'Delete tournament entry',
                cancelButtonText: 'Cancel',
                type: 'warning',
            })
                .then(() => {
                    this.$prompt(
                        'Warning! This will irrevocably delete the selected tournament entry. To continue, please enter the Tournament ID.',
                        'Delete tournament entry',
                        {
                            confirmButtonText: 'Delete tournament entry',
                            cancelButtonText: 'Cancel',
                            type: 'warning',
                        },
                    )
                        .then((entry) => {
                            if (entry.action === 'confirm' && entry.value === '' + this.selectedEntry.data.tournamentId) {
                                this.deleteDoublesEntry(this.selectedEntry)
                                    .then(() => {
                                        this.refresh();
                                        this.editDialogVisible = false;
                                        this.$notify({
                                            title: 'Delete tournament entry',
                                            message: 'Tournament entry deleted',
                                            type: 'success',
                                            duration: 5000,
                                        });
                                    })
                                    .catch(() => {
                                        this.$notify({
                                            title: 'Delete tournament entry',
                                            message: 'Tournament entry could not be deleted',
                                            type: 'error',
                                            duration: 5000,
                                        });
                                    });
                            } else {
                                this.$notify({
                                    title: 'Delete tournament entry',
                                    message: 'Tournament entry not deleted',
                                    type: 'info',
                                    duration: 5000,
                                });
                            }
                        })
                        .catch(() => {});
                })
                .catch(() => {});
        },
        addEntry() {
            this.selectedEntry = new DoublesEntry({
                season: this.tournamentYear,
                tournamentId: this.tournamentId,
            });
            this.mode = 'add';
            this.editDialogVisible = true;
        },
    },
};
</script>

<style></style>
