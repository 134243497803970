import axios from 'axios';
import moment from 'moment';
import { buildParams } from '../utils/query-param-builder';

export default {
    namespaced: true,

    state: {
        ranking: [],
        singlesRanking: [],
        doublesRanking: [],
        raceSinglesRanking: [],
        loadingRanking: false,
        loadingSinglesRanking: false,
        loadingDoublesRanking: false,
        loadingRaceSinglesRanking: false,
        loadingError: null,
        loadingSinglesError: null,
        loadingDoublesError: null,
        loadingRaceSinglesError: null,
        tourYears: null,
        rankingDates: [],
        queryString: '',
        options: {
            queryString: '',
            sortBy: '',
            sortDir: '',
            pageSize: 10,
            page: 0,
        },
        optionsSingles: {
            sortBy: '',
            sortDir: '',
            pageSize: 10,
            page: 0,
        },
        optionsDoubles: {
            sortBy: '',
            sortDir: '',
            pageSize: 10,
            page: 0,
        },
        optionsRaceSingles: {
            sortBy: '',
            sortDir: '',
            pageSize: 10,
            page: 0,
        },
    },

    getters: {
        ranking: (state) => state.ranking,
        singlesRanking: (state) => state.singlesRanking,
        doublesRanking: (state) => state.doublesRanking,
        raceSinglesRanking: (state) => state.raceSinglesRanking,
        loadingRanking: (state) => state.loadingRanking,
        loadingSinglesRanking: (state) => state.loadingSinglesRanking,
        loadingDoublesRanking: (state) => state.loadingDoublesRanking,
        loadingRaceSinglesRanking: (state) => state.loadingRaceSinglesRanking,
        loadingError: (state) => state.loadingError,
        loadingSinglesError: (state) => state.loadingSinglesError,
        loadingDoublesError: (state) => state.loadingDoublesError,
        loadingRaceSinglesError: (state) => state.loadingRaceSinglesError,
        tourYears: (state) => state.tourYears,
        rankingDates: (state) => state.rankingDates,
        options: (state) => state.options,
        optionsSingles: (state) => state.optionsSingles,
        optionsDoubles: (state) => state.optionsDoubles,
        optionsRaceSingles: (state) => state.optionsRaceSingles,
        queryString: (state) => state.queryString,
    },

    mutations: {
        setRanking(state, data) {
            state.ranking = data;
        },

        setSinglesRanking(state, data) {
            state.singlesRanking = data;
        },

        setDoublesRanking(state, data) {
            state.doublesRanking = data;
        },

        setRaceSinglesRanking(state, data) {
            state.raceSinglesRanking = data;
        },

        startLoadingRanking(state) {
            state.loadingRanking = true;
        },

        stopLoadingRanking(state) {
            state.loadingRanking = false;
        },

        startLoadingSinglesRanking(state) {
            state.loadingSinglesRanking = true;
        },

        stopLoadingSinglesRanking(state) {
            state.loadingSinglesRanking = false;
        },

        startLoadingDoublesRanking(state) {
            state.loadingDoublesRanking = true;
        },

        stopLoadingDoublesRanking(state) {
            state.loadingDoublesRanking = false;
        },

        startLoadingRaceSinglesRanking(state) {
            state.loadingRaceSinglesRanking = true;
        },

        stopLoadingRaceSinglesRanking(state) {
            state.loadingRaceSinglesRanking = false;
        },

        setLoadingError(state, error) {
            state.loadingError = error;
        },

        clearLoadingError(state) {
            state.loadingError = null;
        },

        setLoadingSinglesError(state, error) {
            state.loadingSinglesError = error;
        },

        clearLoadingSinglesError(state) {
            state.loadingSinglesError = null;
        },

        setLoadingDoublesError(state, error) {
            state.loadingDoublesError = error;
        },

        clearLoadingDoublesError(state) {
            state.loadingDoublesError = null;
        },

        setLoadingRaceSinglesError(state, error) {
            state.loadingRaceSinglesError = error;
        },

        clearLoadingRaceSinglesError(state) {
            state.loadingRaceSinglesError = null;
        },

        setTourYears(state, data) {
            state.tourYears = data;
        },

        setRankingDates(state, data) {
            state.rankingDates = data;
        },
    },

    actions: {
        async find(context, parameters) {
            let date = parameters.date;
            let params = buildParams(parameters.options);
            context.commit('startLoadingRanking');
            try {
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/Ranking/History/${date}`, { params });
                context.commit('setRanking', result.data);
                context.commit('stopLoadingRanking');
                return Promise.resolve();
            } catch (error) {
                context.commit('stopLoadingRanking');
                context.commit('setLoadingError', error);
                return Promise.reject(error);
            }
        },
        async retrieveSingles(context, parameters) {
            let date = parameters.date;
            let params = buildParams(parameters.options);
            context.commit('startLoadingSinglesRanking');
            try {
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/Ranking/History/${date}/Singles`, { params });
                context.commit('setSinglesRanking', result.data);
                context.commit('stopLoadingSinglesRanking');
                return Promise.resolve();
            } catch (error) {
                context.commit('stopLoadingSinglesRanking');
                context.commit('setLoadingSinglesError', error);
                return Promise.reject(error);
            }
        },
        async retrieveDoubles(context, parameters) {
            let date = parameters.date;
            let params = buildParams(parameters.options);
            context.commit('startLoadingDoublesRanking');
            try {
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/Ranking/History/${date}/Doubles`, { params });
                context.commit('setDoublesRanking', result.data);
                context.commit('stopLoadingDoublesRanking');
                return Promise.resolve();
            } catch (error) {
                context.commit('stopLoadingDoublesRanking');
                context.commit('setLoadingDoublesError', error);
                return Promise.reject(error);
            }
        },
        async retrieveRaceSingles(context, parameters) {
            let date = parameters.date;
            let params = buildParams(parameters.options);
            context.commit('startLoadingRaceSinglesRanking');
            try {
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/Ranking/History/${date}/ChampionshipSingles`, { params });
                context.commit('setRaceSinglesRanking', result.data);
                context.commit('stopLoadingRaceSinglesRanking');
                return Promise.resolve();
            } catch (error) {
                context.commit('stopLoadingRaceSinglesRanking');
                context.commit('setRaceSinglesLoadingError', error);
                return Promise.reject(error);
            }
        },
        async delete(context, selectedRanking) {
            let date = moment(selectedRanking.data.rankDate).format('YYYY-MM-DD');
            let playerId = selectedRanking.data.playerId;
            context.commit('startLoadingRanking');
            try {
                await axios.delete(`${process.env.VUE_APP_API_URL}/Ranking/History/${date}/${playerId}`);
                context.commit('stopLoadingRanking');
                return Promise.resolve();
            } catch (error) {
                context.commit('stopLoadingRanking');
                context.commit('setLoadingError', error);
                return Promise.reject(error);
            }
        },
        async create(context, data) {
            context.commit('startLoadingRanking');
            try {
                const result = await axios.post(`${process.env.VUE_APP_API_URL}/Ranking/History`, data.data);
                context.commit('stopLoadingRanking');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('stopLoadingRanking');
                context.commit('setLoadingError', error);
                return Promise.reject(error);
            }
        },
        async update(context, data) {
            context.commit('startLoadingRanking');
            try {
                const result = axios.put(`${process.env.VUE_APP_API_URL}/Ranking/History`, data.data);
                context.commit('stopLoadingRanking');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('stopLoadingRanking');
                context.commit('setLoadingError', error);
                return Promise.reject(error);
            }
        },
        async retrieveTourYears(context) {
            try {
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/Ranking/History/TourYears`);
                context.commit(
                    'setTourYears',
                    result.data.map((item) => ({
                        ...item,
                        htmlValue: item.startDate + ' ' + item.endDate,
                    })),
                );
                return Promise.resolve(result.data);
            } catch (error) {
                // context.commit('setLoadingError', error);
                return Promise.reject(error);
            }
        },
        async retrieveRankigDatesBetween(context, valueString) {
            try {
                let dates = valueString.split(' ');
                let startDate = moment(new Date(dates[0])).format('YYYY-MM-DD');
                let endDate = moment(new Date(dates[1])).format('YYYY-MM-DD');
                let url = `${process.env.VUE_APP_API_URL}/Ranking/History/RankingDatesBetween/${startDate}/${endDate}`;
                const result = await axios.get(url);
                context.commit(
                    'setRankingDates',
                    result.data.map((item) => ({
                        rankingDate: item,
                        htmlValue: moment(item).format('YYYY-MM-DD'),
                        displayDate: moment(item).format('YYYY-MM-DD'),
                    })),
                );
                return Promise.resolve(result.data);
            } catch (error) {
                // context.commit('setLoadingError', error);
                return Promise.reject(error);
            }
        },

        async exportCsv(context, options = {}) {
            context.commit('startLoadingRanking');
            try {
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/Ranking/History/${options.date}/${options.rankingContext}/export/csv`, {
                    params: {
                        queryString: options.queryString || '',
                        sortBy: options.sortBy || '',
                        sortDir: options.sortDir === 'descending' ? -1 : options.sortDir === 'ascending' ? 1 : 0,
                    },
                });
                context.commit('stopLoadingRanking');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('stopLoadingRanking');
                return Promise.reject(error);
            }
        },
    },
};
