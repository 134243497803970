import { isValid, Model } from './model';

export class PzPaymentDate extends Model {
    isValid() {
        return isValid(PzPaymentDate.schema, this);
    }
    isValidForAdd() {
        return isValid(PzPaymentDate.schema, this);
    }
    isValidForEdit() {
        return isValid(PzPaymentDate.schema, this);
    }
}

PzPaymentDate.layout = {
    children: [
        {
            children: ['name', 'date'],
        },
    ],
};

PzPaymentDate.schema = {
    fields: {
        id: { displayName: 'Id', type: 'number', clearable: true, readonly: true },
        name: { displayName: 'Name', type: 'string', required: true },
        date: { displayName: 'Date', type: 'date', required: true },
    },
};
