<template>
  <div>
    <div class="flex-row mt-16 mb-16">
      <h3>Age Eligibility Detail Tracking {{ this.formattedReportDate }} {{ this.formattedPlayerName }}</h3>
      <span class="spacer"></span>

      <!-- <el-button plain type="primary" @click="onExportCsv" v-loading="loadingCsvAgeEligibilityTrackings">Export CSV</el-button> -->
    </div>

    <div class="mb-32">
      <el-alert
          title="info"
          type="info"
          :closable="false"
          description="This table contains all tournaments the player has participated at with points earned and WCs used. Table contains real numbers and numbers after application of AER: adj - adjusted."
          show-icon
      >
      </el-alert>

      <div v-loading="loadingAgeEligibilityTournamentTrackings">
        <DataTable
            v-if="!loadingErrorAgeEligibilityTournamentTrackings"
            :schema="schema"
            :items="ageEligibilityTournamentTrackings"
            :fields="[
                        'tournamentStartDate',
                        'tournamentName',
                        'tournamentType',
                        'tournamentLevel',
                        'tournamentPrizeMoney',
                        'rankingPointsSingles',
                        'rankingPointsDoubles',
                        'tournamentCountReal',
                        'tournamentCountAdjusted',
                        'singlesWcCountReal',
                        'singlesWcCountAdjusted',
                        'doublesWcCountReal',
                        'doublesWcCountAdjusted',
                        'mainWcCountReal',
                        'mainWcCountAdjusted',
                        'qualiWcCountReal',
                        'qualiWcCountAdjusted',
                    ]"
            :autoSort="false"
            :showTotals="true"
        />
      </div>
    </div>

    <div class="mb-16">
      <el-alert
          title="info"
          type="info"
          :closable="false"
          description="This table contains an overview of left and used tournaments and WCs. It contains only adjusted numbers after application of AER. It is the same entry as in AER Tracking report."
          show-icon
      >
      </el-alert>
      <div v-loading="loadingAgeEligibilityTracking">
        <DataTable
            v-if="!loadingErrorAgeEligibilityTracking"
            :schema="schemaTracking"
            :items="ageEligibilityTracking.data"
            :fields="tableFieldsPreview"
            :autoSort="false"
        />
      </div>
    </div>


    <div class="mb-16">
      <el-alert
          title="info"
          type="info"
          :closable="false"
          description="This table contains an overview of Warnings and Violations for player. It contains only adjusted numbers after application of AER. It is the same entry as in AER Warning/ Violation report."
          show-icon
      >
      </el-alert>
      <div v-loading="loadingAgeEligibilityTracking">
        <DataTable
            :schema="schemaTracking"
            :items="ageEligibilityTracking.data"
            :fields="tableFieldsWarning"
            :autoSort="false"
        />
      </div>
    </div>

  </div>
</template>


<script>
import {mapGetters, mapActions} from 'vuex';
import {AgeEligibilityTracking} from '@/models/age-eligibility-tracking';
import {AgeEligibilityTournamentTracking} from '@/models/age-eligibility-tournament-tracking';
import {retrieveDtos} from '@/utils/crud-confirm';
import moment from 'moment';

export default {
  data() {
    return {
      schema: AgeEligibilityTournamentTracking.schema,
      schemaTracking: AgeEligibilityTracking.schema,
      tableFieldsPreview: AgeEligibilityTracking.tableFieldsReportPreview,
      tableFieldsWarning: AgeEligibilityTracking.warningViolationReport,
      requestError: null,
      playerNbr: 0,
    };
  },
  mounted() {
  },
  computed: {
    ...mapGetters('ageEligibilityTrackings', [
      'ageEligibilityUpToDate',
      'ageEligibilityTracking',
      'ageEligibilityTournamentTrackings',
      'ageEligibilityTournamentTrackingPlayer',
      'loadingAgeEligibilityUpToDate',
      'loadingAgeEligibilityTracking',
      'loadingAgeEligibilityTournamentTrackings',
      'loadingAgeEligibilityTournamentTrackingPlayer',
      'loadingErrorAgeEligibilityTracking',
      'loadingErrorAgeEligibilityTournamentTrackings',
    ]),
    formattedReportDate() {
      return this.ageEligibilityUpToDate ? ' up to ' + moment(this.ageEligibilityUpToDate).format('YYYY-MM-DD') : '';
    },
    formattedPlayerName() {
      return this.ageEligibilityTournamentTrackingPlayer.data.id
          ? `for ${this.ageEligibilityTournamentTrackingPlayer.data.fullName} - ${this.ageEligibilityTournamentTrackingPlayer.data.id} (${this.ageEligibilityTournamentTrackingPlayer.data.playerAge})`
          : '';
    },
  },
  methods: {
    ...mapActions('ageEligibilityTrackings', ['retrieveAgeEligibilityTournamentTrackings', 'retrieveAgeEligibilityTournamentTrackingPlayer']),
    refresh() {
      this.search();
    },
    search() {
      let params = {
        self: this,
        fRetrieve: this.retrieveAgeEligibilityTournamentTrackings,
        options: this.playerNbr,
        dtosName: 'AER Detail Tracking',
      };
      retrieveDtos(params);
      params = {
        self: this,
        fRetrieve: this.retrieveAgeEligibilityTournamentTrackingPlayer,
        options: this.playerNbr,
        dtosName: 'Player',
      };
      retrieveDtos(params);
    },
  },
};
</script>

