import { Model, isValid } from './model';
import { icoCountries } from '../utils/country-codes';

export class TeamRanking extends Model {
    isValidForAdd() {
        return isValid(TeamRanking.schemaAdd, this);
    }

    isValidForEdit() {
        return isValid(TeamRanking.schema, this);
    }
}

TeamRanking.layout = {
    children: [
        {
            children: ['player1Id', 'player1ConcatName', 'player1Nationality'],
        },
        {
            children: ['player2Id', 'player2ConcatName', 'player2Nationality'],
        },
        {
            children: ['rank', 'tournamentCount', 'points'],
        },
    ],
};

TeamRanking.layoutAdd = {
    children: [
        {
            children: ['player1Id', 'player2Id', { type: 'spacer', width: 1 }],
        },
        {
            children: ['rank', 'tournamentCount', 'points'],
        },
    ],
};

TeamRanking.layoutHistory = {
    children: [
        {
            children: ['rankingDate', 'teamName', { type: 'spacer', width: 2 }],
        },
        {
            children: ['player1Id', 'player1ConcatName', 'player1Nationality', 'player1AgeAtRankDate'],
        },
        {
            children: ['player2Id', 'player2ConcatName', 'player2Nationality', 'player2AgeAtRankDate'],
        },
        {
            children: ['rank', 'points', 'tournamentCount', 'rankCd'],
        },
        {
            children: ['rankPrev', 'pointsPrev', 'rankMove', { type: 'spacer', width: 1 }],
        },
    ],
};

TeamRanking.layoutHistoryAdd = {
    children: [
        {
            children: ['rankingDate', 'player1Id', 'player2Id', 'teamName'],
        },
        {
            children: ['rank', 'rankCd', 'tournamentCount', 'points'],
        },
    ],
};

TeamRanking.schema = {
    fields: {
        rankingDate: { type: 'us-date', displayName: 'Rank Date', required: true, readonly: true },
        player1Id: { type: 'player', displayName: 'Player 1 Id', readonly: true, sortable: true, required: true },
        player1ConcatName: { type: 'string', displayName: 'Player 1', readonly: true },
        player1Nationality: { type: 'nationality', displayName: 'Nationality', readonly: true, lookup: Object.keys(icoCountries) },
        player1BirthDate: { type: 'us-date', displayName: 'Player 1 Birthdate', readonly: true },
        player1AgeAtRankDate: { type: 'number', displayName: ' Player 1 Age at Rank Date', sortable: true, readonly: true },
        player2Id: { type: 'player', displayName: 'Player 2 Id', readonly: true, sortable: true, required: true },
        player2ConcatName: { type: 'string', displayName: 'Player 2', readonly: true },
        player2Nationality: { type: 'nationality', displayName: 'Nationality', readonly: true, lookup: Object.keys(icoCountries) },
        player2BirthDate: { type: 'us-date', displayName: 'Player 2 Birthdate', readonly: true },
        player2AgeAtRankDate: { type: 'number', displayName: ' Player 2 Age at Rank Date', sortable: true, readonly: true },
        teamName: { type: 'string', displayName: 'Team', required: true },
        rank: { type: 'number', displayName: 'Rank', sortable: true, required: true },
        rankCd: { type: 'string', displayName: 'Tie' },
        points: { type: 'number', displayName: 'Points', sortable: true, required: true },
        tournamentCount: { type: 'number', displayName: 'Tournament Count', sortable: true, required: true },
        pointsPrev: { type: 'number', displayName: 'Prior Points', readonly: true },
        rankPrev: { type: 'number', displayName: 'Prior Rank', readonly: true },
        rankMove: { type: 'number', displayName: 'Rank Move', readonly: true },
    },
};

TeamRanking.schemaAdd = {
    fields: {
        rankingDate: { type: 'us-date', displayName: 'Rank Date', required: true },
        player1Id: { type: 'player', displayName: 'Player 1 Id', sortable: true, required: true },
        player2Id: { type: 'player', displayName: 'Player 2 Id', sortable: true, required: true },
        teamName: { type: 'string', displayName: 'Team', required: true },
        rank: { type: 'number', displayName: ' Rank', sortable: true, required: true },
        rankCd: { type: 'string', displayName: 'Tie' },
        points: { type: 'number', displayName: ' Points', sortable: true, required: true },
        tournamentCount: { type: 'number', displayName: 'Tournament Count', sortable: true, required: true },
    },
};
