<template>
    <div v-loading="loadingSinglesRaceSummary">
        <ErrorDisplay :requestError="this.singlesRaceSummaryError" @closed="clearError" />

        <div class="flex-row">
            <RankingSummaryPointTable :summary="this.singlesRaceSummary" />
            <span class="spacer"></span>
            <RankingSummaryChampionshipTieBreakingPoints :summary="this.singlesRaceSummary" />
            <span class="spacer"></span>
            <span class="spacer"></span>
        </div>

        <DataTable
            v-if="singlesRaceSummary"
            :items="singlesRaceSummary.allResults"
            :schema="schemaTournResult"
            :fields="[
                'startDate',
                'useForRankingDate',
                'tournamentName',
                'season',
                'tournamentId',
                //'tournamentType',
                //'tournamentLevel',
                'isRequired',
                'points',
                'isIncludedIntoRanking',
                'rankingLabel',
            ]"
            :autoSort="false"
        />
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { TournamentResult } from '../../models/tournament-result';

export default {
    data() {
        return {
            schemaTournResult: TournamentResult.schema,
        };
    },
    mounted() {},
    computed: {
        ...mapGetters('rankingProcedure', ['singlesRaceSummary', 'loadingSinglesRaceSummary', 'singlesRaceSummaryError']),
    },
    methods: {
        clearError() {
            this.singlesRaceSummaryError = null;
        },
    },
};
</script>

<style scoped>
.mw {
    max-width: 748px;
}
</style>
