import { Model, isValid } from './model';

export class MessageRecipient extends Model {
    isValid() {
        return isValid(MessageRecipient.schema, this);
    }
    isValidForAdd() {
        return isValid(MessageRecipient.schema, this);
    }
    isValidForEdit() {
        return isValid(MessageRecipient.schema, this);
    }
}

MessageRecipient.layout = {
    children: [
        {
            children: ['contactData',],
        },
        {
            children: ['relationship'],
        },
        {
            children: ['relFirstName',],
        },
        {
            children: ['relLastName'],
        },
        {
            children: ['active', { type: 'spacer', width: 3 }],
        },
    ],
};

MessageRecipient.schema = {
    fields: {
        recipientId: { type: 'number', displayName: 'Recipient Id', readonly: true },
        playerId: { type: 'string', displayName: 'Player Id', readonly: true },
        contactType: { type: 'string', displayName: 'Contact Type', readonly: true },
        contactData: { type: 'email', displayName: 'Contact Email', required: true, maxLength: 150 },
        active: {
            type: 'string', displayName: 'Active', required: true,
            lookup: [
                 { id: 'A', displayName: 'Active' },
                 { id: 'I', displayName: 'Inactive' },
            ],
        },
        relationship: { type: 'string', displayName: 'Relationship', required: true, maxLength: 20 },
        relFirstName: { type: 'string', displayName: 'First Name', required: true, maxLength: 30 },
        relLastName: { type: 'string', displayName: 'Last Name', required: true, maxLength: 50 },
    },
};
