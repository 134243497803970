<template>
    <div>
        <DialogAddItem
            :visibleSync="addDialogVisible"
            dtoName="Special Ranking"
            :selected="selectedRanking"
            :schema="schemaAdd"
            :layout="layoutAdd"
            @save="saveChanges"
            @change="onChange"
            @close="addDialogVisible = false"
        />

        <DialogEditItem
            :visibleSync="editDialogVisible"
            dtoName="Special Ranking"
            :selected="selectedRanking"
            :schema="schema"
            :layout="layout"
            @save="saveChanges"
            @change="onChange"
            @delete="deleteRanking"
            @close="editDialogVisible = false"
        />

        <div class="flex-row mt-0 mb-32">
            <h2><i class="el-icon-medal-1 mr-16"></i>Special Rankings Overview</h2>
            <span class="spacer"></span>

            <SelectPlayer class="mr-8" @palyer-selected="playerSelected" v-model="selectedPlayerFullName" />

            <el-button plain type="primary" @click="reset" size="mini">Reset</el-button>

            <el-button v-if="!isBaselineReader" @click="addRanking" plain type="primary" size="mini">Add Special Ranking</el-button>

            <el-button @click="exportCsvClick" plain class="ml-8" size="mini">Export CSV</el-button>
        </div>

        <ErrorDisplay :requestError="this.requestError" @closed="clearError" />

        <DetailedInformation screenName="Required Tournaments" :informationArray="this.informationArray()" />

        <DataTable
            v-loading="loadingSpecialRankings"
            :items="specialRankings.data"
            :schema="schema"
            :fields="[
                'expired',
                'playerId',
                'fullName',
                'nationality',
                'singlesSpecialRank',
                'doublesSpecialRank',
                'singlesTournamentsAllowed',
                'singlesTournamentsPlayed',
                'doublesTournamentsAllowed',
                'doublesTournamentsPlayed',
                'singlesGrandSlamsAllowed',
                'singlesGrandSlamsPlayed',
                'doublesGrandSlamsAllowed',
                'doublesGrandSlamsPlayed',
                'lastTournamentBeforeBreak',
                'weeksAsFromDb',
                'weeksComputed',
                'firstTournamentAllowed',
                'expirationDate',
            ]"
            @item-clicked="selectRanking"
            @header-clicked="setSort"
            :autoSort="false"
        />

        <Paginator
            v-if="specialRankings.data"
            :data="specialRankings"
            :elementsPerPage="options.pageSize"
            @prev-page="prevPage"
            @next-page="nextPage"
            @set-page-size="setPageSize"
        />
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { SpecialRankingOverview } from '../models/special-ranking-overview';
import FileSaver from 'file-saver';
import moment from 'moment';
import { retrieveDtos, deleteConfirmDto, createConfirmDto, updateConfirmDto } from '../utils/crud-confirm';
import { isBaselineReader } from '../services/authGroups';

export default {
    data() {
        return {
            schema: SpecialRankingOverview.schema,
            schemaAdd: SpecialRankingOverview.schemaAdd,
            layout: SpecialRankingOverview.layout,
            layoutAdd: SpecialRankingOverview.layoutAdd,
            editDialogVisible: false,
            addDialogVisible: false,
            mode: 'add',
            selectedRanking: null,
            selectedPlayerFullName: null,
            requestError: null,
            isBaselineReader: isBaselineReader(),
        };
    },
    mounted() {
        if (this.$route.query.playerId) {
            this.playerSelected({ id: this.$route.query.playerId });
        } else {
            this.refresh();
        }
    },
    computed: mapGetters('specialRankingOverviews', ['specialRankings', 'options', 'loadingSpecialRankings', 'loadingSpecialRankingsError']),
    methods: {
        ...mapActions('specialRankingOverviews', [
            'retrieveSpecialRankings',
            'createSpecialRanking',
            'updateSpecialRanking',
            'deleteSpecialRanking',
            'exportCsv',
        ]),
        exportCsvClick() {
            this.exportCsv(this.options)
                .then((result) => {
                    const file = new File([result], `special_ranks_${moment().format('DD_MM_YYYY HH_mm_SS')}.csv`, { type: 'text/csv' });
                    FileSaver.saveAs(file);
                })
                .catch((error) => {
                    this.$notify({
                        title: 'CSV export',
                        message: 'CSV export failed',
                        type: 'error',
                        duration: 5000,
                    });
                });
        },
        refresh() {
            let params = {
                self: this,
                fRetrieve: this.retrieveSpecialRankings,
                options: this.options,
                dtosName: 'Special Rankings',
            };
            retrieveDtos(params);
        },
        playerSelected(player) {
            this.options.queryString = player.id;
            this.selectedPlayerFullName = player.fullName;
            this.options.page = 0;
            this.refresh();
        },
        reset() {
            this.selectedPlayerFullName = null;
            this.options.queryString = '';
            this.options.page = 0;
            this.options.pageSize = process.env.VUE_APP_PAGE_SIZE;
            this.refresh();
        },
        selectRanking(item) {
            this.selectedRanking = new SpecialRankingOverview(item);
            this.mode = 'edit';
            this.editDialogVisible = true;
        },
        prevPage() {
            this.options.page--;
            this.refresh();
        },
        nextPage() {
            this.options.page++;
            this.refresh();
        },
        setPageSize(size) {
            this.options.pageSize = size;
            this.refresh();
        },
        setSort(event) {
            this.options.sortBy = event.property;
            this.options.sortDir = event.order;
            this.refresh();
        },
        saveChanges() {
            if (this.mode === 'edit') {
                let params = {
                    self: this,
                    fUpdate: this.updateSpecialRanking,
                    dto: this.selectedRanking,
                    dtoName: 'Special Ranking',
                    callback: this.crudCallback,
                };
                updateConfirmDto(params);
            } else if (this.mode === 'add') {
                let params = {
                    self: this,
                    fCreate: this.createSpecialRanking,
                    dto: this.selectedRanking,
                    dtoName: 'Special Ranking',
                    callback: this.crudCallback,
                };
                createConfirmDto(params);
            }
        },
        onChange() {},
        deleteRanking() {
            let params = {
                self: this,
                fDelete: this.deleteSpecialRanking,
                vConfirm: this.selectedRanking.data.playerId,
                dto: this.selectedRanking,
                dtoName: 'Special Ranking',
                vConfirmName: 'Player ID',
                callback: this.crudCallback,
            };
            deleteConfirmDto(params);
        },
        addRanking() {
            this.selectedRanking = new SpecialRankingOverview({});
            this.mode = 'add';
            this.addDialogVisible = true;
        },
        crudCallback() {
            this.refresh();
            this.requestError = null;
            this.addDialogVisible = false;
            this.editDialogVisible = false;
        },
        clearError() {
            this.requestError = null;
        },
        informationArray() {
            return [
                'This screen is used to manage Special Ranks.',
                'Special Ranks are fetched from table player_master.',
                'Searching for players is possible by player id <b>or</b> last name <b>or</b> first name. Combined search is not possible.',
            ];
        },
    },
};
</script>

<style scoped>
.input-search {
    max-width: 256px;
}
</style>
