<template>
    <div>
        <div id="app" v-if="isAuthenticated === true && isLoading === false">
            <router-view />
        </div>

        <NotAllowed v-else-if="isAuthenticated === false && isLoading === false" />

        <div v-if="isLoading === true" class="loading-container">
            <div class="loader"></div>
            <p>Loading...</p>
        </div>
    </div>
</template>

<script>
import { logout } from './services/authService';
import NotAllowed from './pages/app/NotAllowed.vue';
import { mapGetters, mapState } from 'vuex';

export default {
    components: {
        NotAllowed,
    },

    data: () => ({
        mode: process.env.VUE_APP_APPLICATION_MODE,
        userActivityTimeout: null,
    }),

    computed: {
        ...mapState('auth', {
            account: 'account',
            profile: 'profile',
            error: 'error',
        }),
        ...mapGetters('auth', {
            isLoading: 'authIsLoading',
            authorized: 'authorized',
            isAuthenticated: 'isAuthenticated',
        }),

        minutesInactivityTimeout() {
            return process.env.VUE_APP_INACTIVITY_TIMEOUT || 30;
        },
    },

    async mounted() {
        if (this.mode !== 'local') {
            this.resetActivityTimer();
            window.addEventListener('mousemove', this.resetActivityTimer);
            window.addEventListener('keypress', this.resetActivityTimer);
        }

        const channel = new BroadcastChannel('auth_channel');

        channel.addEventListener('message', (event) => {
            if (event.data === 'logout') {
                this.signOut();
            }
        });
    },

    beforeDestroy() {
        window.removeEventListener('mousemove', this.resetActivityTimer);
        window.removeEventListener('keypress', this.resetActivityTimer);
    },

    methods: {
        resetActivityTimer() {
            clearTimeout(this.userActivityTimeout);
            this.userActivityTimeout = setTimeout(
                () => {
                    this.signOut();
                },
                this.minutesInactivityTimeout * 60 * 1000,
            );
        },

        async signOut() {
            logout();
        },
    },
};
</script>

<style lang="scss">
#app {
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
    height: 100vh;
    display: flex;
}

.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100vh;
}

.loader {
    border: 5px solid #f3f3f3;
    border-top: 5px solid #7b56f8;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.loading-container p {
    margin-top: 20px;
    font-size: 16px;
    color: #666;
}
</style>
