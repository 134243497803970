import {Model} from "@/models/model";
import {icoCountries} from "@/utils/country-codes";
import {PlayerPayment} from "@/models/player-payment";

export class PaymentReports extends Model {

}

PaymentReports.schema = {
    fields: {
        userId: { type: 'string', displayName: 'User Id', sortable: true, readonly: true },
        firstName: { type: 'string', displayName: 'First Name', sortable: true, readonly: true },
        lastName: { type: 'string', displayName: 'Last Name', sortable: true, readonly: true },
        paymentType: { type: 'string', displayName: 'Payment Type', sortable: true, readonly: true },
        insuranceType: { type: 'string', displayName: 'Insurance Type', sortable: true, readonly: true },
        paymentDate: { type: 'us-date', displayName: 'Payment Date', sortable: true, readonly: true },
        insurancePaymentDate: { type: 'us-date', displayName: 'Insurance Payment Date', sortable: true, readonly: true },
        insuranceCost: { type: 'string', displayName: 'Insurance Amount', sortable: true, readonly: true },
        memberCost: { type: 'string', displayName: 'Member Amount', sortable: true, readonly: true },
        role: {
            displayName: 'Role',
            type: 'string',
            required: false,
            lookup: [{
                value: 'C',
                label: 'Coach'
            }, {
                value: 'N',
                label: 'New Coach'
            }, {
                value: 'P',
                label: 'Player'
            }],
        },
    },
};