import { isValid, Model } from './model';

export class ShadowRanking extends Model {
    constructor(data) {
        super(data);
    }

    isValid() {
        return isValid(ShadowRanking.schema, this);
    }

    isValidForEdit() {
        return isValid(ShadowRanking.schema, this);
    }

    isValidForAdd() {
        return isValid(ShadowRanking.schema, this);
    }
}

ShadowRanking.layout = {
    children: [
        {
            children: ['shadowRankId', 'name', 'singlesRankingResultCount', 'doublesRankingResultCount',  { type: 'spacer' }],
        },
        {
            children: ['rankDateFirstTournament', 'rankDateLastTournament', 'shadowTournamentResultsCount',  { type: 'spacer' }],
        },

    ],
};

ShadowRanking.layoutAdd = {
    children: [
        {
            children: ['name', 'singlesRankingResultCount', 'doublesRankingResultCount', { type: 'spacer' }],
        },
    ],
};

ShadowRanking.schema = {
    fields: {
        shadowRankId: { type: 'number', displayName: 'Shadow Rank Id', readonly: true, },
        name: { type: 'string', displayName: 'Name', required: true, maxLength: 255, specialCharacterAllowed: false },
        singlesRankingResultCount: { type: 'number', displayName: 'Singles Best Of', clearable: true },
        doublesRankingResultCount: { type: 'number', displayName: 'Doubles Best Of', clearable: true },
        rankDateFirstTournament: {
            type: 'date',
            displayName: 'Rank Date First Tournament',
            clearable: true,
            readonly: true
        },
        rankDateLastTournament: {
            type: 'date',
            displayName: 'Rank Date Last Tournament',
            clearable: true,
            readonly: true
        },
        shadowTournamentResultsCount: {
            type: 'number',
            displayName: 'Tournament Results',
            clearable: true,
            readonly: true
        },
    }
};