<template>
    <div>        
        <table class="summary-table mb-16" v-if="summary && summary.allResults">
            <tr>
                <td>Tournament Count</td>
                <td>{{ summary.totalTournamentsCount }}</td>
            </tr>
            <tr>
                <td>Total Points</td>
                <td>{{ summary.totalPoints }}</td>
            </tr>
            <tr>
                <td>Ranking Points</td>
                <td>{{ summary.rankingPoints }}</td>
            </tr>
        </table>
    </div>

</template>

<script>
export default {
    props: ['summary'],
    data() {
        return {
        };
    },
    mounted() {},
    computed: {
    },
    methods: {
    },
};
</script>
