import axios from 'axios';
import { PlayerSupport } from '@/models/player-support';

export default {
    namespaced: true,

    state: {
        playerSupport: new PlayerSupport(),
        playerSupportFound: false,
        loadingPlayerSupport: false,
        loadingError: null,
    },

    getters: {
        playerSupport: (state) => state.playerSupport,
        playerSupportFound: (state) => state.playerSupportFound,
        loadingPlayerSupportParent: (state) => state.loadingPlayerSupport,
        loadingError: (state) => state.loadingError,
    },

    mutations: {
        setPlayerSupport(state, data) {
            state.playerSupport = data;
        },
        setPlayerSupportFound(state) {
            state.playerSupportFound = true;
        },
        clearPlayerSupportFound(state) {
            state.playerSupportFound = false;
        },

        startLoadingPlayerSupport(state) {
            state.loadingPlayerSupport = true;
        },

        stopLoadingPlayerSupport(state) {
            state.loadingPlayerSupport = false;
        },

        setLoadingError(state, error) {
            state.loadingError = error;
        },

        clearLoadingError(state) {
            state.loadingError = null;
        },
    },

    actions: {
        async findPlayerSupport(context, contactId) {
            if (!contactId) {
                context.commit('setPlayerSupport', new PlayerSupport());
                context.commit('clearPlayerSupportFound');
                return;
            }
            context.commit('startLoadingPlayerSupport');
            try {
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/PlayerSupport/Parent/${contactId}`);
                context.commit('setPlayerSupport', new PlayerSupport(result.data));
                context.commit('setPlayerSupportFound');
                context.commit('clearLoadingError');
                context.commit('stopLoadingPlayerSupport');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('stopLoadingPlayerSupport');
                context.commit('clearPlayerSupportFound');
                context.commit('setLoadingError', error);
                return Promise.reject(error);
            }
        },

        async updatePlayerSupport(context, data = {}) {
            if (!data.data.contactId) {
                return;
            }
            context.commit('startLoadingPlayerSupport');
            let contactId = data.data.contactId;
            try {
                const result = await axios.put(`${process.env.VUE_APP_API_URL}/PlayerSupport/${contactId}`, data.data);
                context.commit('stopLoadingPlayerSupport');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('stopLoadingPlayerSupport');
                context.commit('setLoadingError', error);
                return Promise.reject(error);
            }
        },
    },
}