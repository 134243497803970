import axios from 'axios';

export default {
    namespaced: true,

    state: {
        tournamentPoints: [],
        loadingTournamentPoints: false,
        loadingError: null,
        savingTournamentPoint: false,
        savingError: null,
        deletingTournamentPoint: false,
        deletingError: null,
        options: {
            queryString: null,
            sortBy: 'tournamentPointId',
            sortDir: 'ascending',
            page: 0,
            pageSize: 20,
        },
    },

    getters: {
        tournamentPoints: (state) => state.tournamentPoints,
        loadingTournamentPoints: (state) => state.loadingTournamentPoints,
        loadingError: (state) => state.loadingError,
        savingTournamentPoint: (state) => state.savingTournamentPoint,
        savingError: (state) => state.savingError,
        deletingTournamentPoint: (state) => state.deletingTournamentPoint,
        deletingError: (state) => state.deletingError,
        options: (state) => state.options,
    },

    mutations: {
        setTournamentPoints(state, data) {
            state.tournamentPoints = data;
        },

        startLoadingTournamentPoints(state) {
            state.loadingTournamentPoints = true;
        },

        stopLoadingTournamentPoints(state) {
            state.loadingTournamentPoints = false;
        },

        setLoadingError(state, error) {
            state.loadingError = error;
        },

        clearLoadingError(state) {
            state.loadingError = null;
        },

        startSavingTournamentPoint(state) {
            state.savingTournamentPoint = true;
        },

        stopSavingTournamentPoint(state) {
            state.savingTournamentPoint = false;
        },

        setSavingError(state, error) {
            state.savingError = error;
        },

        clearSavingError(state) {
            state.savingError = null;
        },

        startDeletingTournamentPoint(state) {
            state.deletingTournamentPoint = true;
        },

        stopDeletingTournamentPoint(state) {
            state.deletingTournamentPoint = false;
        },

        setDeletingError(state, error) {
            state.deletingError = error;
        },

        clearDeletingError(state) {
            state.deletingError = null;
        },
    },

    actions: {
        async findTournamentPoints(context, options = {}) {
            context.commit('startLoadingTournamentPoints');
            try {
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/TournamentPoint`, {
                    params: {
                        queryString: options.queryString || '',
                        sortBy: options.sortBy || 'rankingPointId',
                        sortDir: options.sortDir === 'descending' ? -1 : 1,
                        pageSize: options.pageSize || 50,
                        page: options.page,
                    },
                });
                context.commit('stopLoadingTournamentPoints');
                context.commit('setTournamentPoints', result.data);
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('stopLoadingTournamentPoints');
                context.commit('setLoadingError', error);
                return Promise.reject(error);
            }
        },

        async findTournamentPointsForTournament(context, options = {}) {
            let year = options.year;
            let tournamentId = options.id;
            context.commit('startLoadingTournamentPoints');
            try {
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/TournamentPoint/Edition/${year}/${tournamentId}`);
                context.commit('setTournamentPoints', result.data);
                context.commit('stopLoadingTournamentPoints');
                return Promise.resolve();
            } catch (error) {
                context.commit('stopLoadingTournamentPoints');
                context.commit('setLoadingError', error);
                return Promise.reject(error);
            }
        },

        async createTournamentPoint(context, tournamentPoint) {
            context.commit('startSavingTournamentPoint');
            try {
                const result = await axios.post(`${process.env.VUE_APP_API_URL}/TournamentPoint`, tournamentPoint.data);
                context.commit('stopSavingTournamentPoint');;
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('stopSavingTournamentPoint');
                context.commit('setSavingError', error);
                return Promise.reject(error);
            }
        },

        async updateTournamentPoint(context, tournamentPoint) {
            context.commit('startSavingTournamentPoint');
            try {
                const result = await axios.put(`${process.env.VUE_APP_API_URL}/TournamentPoint`, tournamentPoint.data);
                context.commit('stopSavingTournamentPoint');;
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('stopSavingTournamentPoint');
                context.commit('setSavingError', error);
                return Promise.reject(error);
            }
        },

        async deleteTournamentPoint(context, data) {
            context.commit('startDeletingTournamentPoint');
            try {
                const tournamentPoint = await axios.post(`${process.env.VUE_APP_API_URL}/TournamentPoint/Delete`, data.data);
                context.commit('stopDeletingTournamentPoint');
                return Promise.resolve(tournamentPoint.data);
            } catch (error) {
                context.commit('stopDeletingTournamentPoint');
                context.commit('setDeletingError', error);
                return Promise.reject(error);
            }
        },

        async exportCsv(context, options = {}) {
            context.commit('startLoadingTournamentPoints');
            try {
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/TournamentPoint/export/csv`, {
                    params: {
                        queryString: options.queryString || '',
                        sortBy: options.sortBy || 'Id',
                        sortDir: options.sortDir === 'descending' ? -1 : 1,
                    },
                });
                context.commit('stopLoadingTournamentPoints');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('stopLoadingTournamentPoints');
                return Promise.reject(error);
            }
        },
    },
};
