import { Model, isValid } from './model';

export class AgeEligibilityTournamentTracking extends Model {
    isValid() {
        return isValid(AgeEligibilityTournamentTracking.schema, this);
    }
    isValidForAdd() {
        return isValid(AgeEligibilityTournamentTracking.schemaAdd, this);
    }
    isValidForEdit() {
        return isValid(AgeEligibilityTournamentTracking.schema, this);
    }
}

AgeEligibilityTournamentTracking.schema = {
    fields: {
        tournamentNbr: { type: 'number', displayName: 'Tourn Nbr', columnNames: ['Tourn', 'Nbr'], readonly: true, },
        tournamentYear: { type: 'number', displayName: 'Year', readonly: true, },
        tournamentType: {
            type: 'string', displayName: 'Type', readonly: true, clearable: true, lookup: [
                { id: 'ST', displayName: 'ITF' },
                { id: 'FC', displayName: 'FedCup' },
                { id: 'GS', displayName: 'GrandSlam' },
                { id: 'CA', displayName: 'Cancelled' },
                { id: 'VS', displayName: 'WTA' },
            ],
        },
        tournamentLevel: {
            type: 'string', displayName: 'Level', readonly: true, clearable: true, lookup: [
                { id: 'GS', displayName: 'GrandSlam' },
                { id: 'CH', displayName: 'Championship' },
                { id: 'PM', displayName: 'WTA 1000M (PM)' },
                { id: 'P5', displayName: 'WTA 1000NM (P5)' },
                { id: 'P', displayName: 'WTA 500 (P)' },
                { id: 'IS', displayName: 'WTA 250 (IS)' },
                { id: 'C', displayName: 'WTA 125' },
                { id: 'R1', displayName: 'FedCupRound1' },
                { id: 'R2', displayName: 'FedCupRound2' },
                { id: 'R3', displayName: 'FedCupSemiFinal' },
                { id: 'R4', displayName: 'FedCupFinal' },
            ],
        },
        tournamentName: { type: 'string', displayName: 'Name', readonly: true, clearable: true, sumUpLabel: 'TOTAL',},
        tournamentStartDate: { type: 'us-date', displayName: 'Start', readonly: true, clearable: true, },
        tournamentPrizeMoney: { type: 'currency', displayName: 'Prize Money', columnNames: ['Total', 'Prize', 'Money'], readonly: true, clearable: true, },
        rankingPointsSingles: { type: 'number', displayName: 'Points Singles', columnNames: ['Points', 'Singles'], readonly: true, clearable: true, sumUpTotals: true, },
        rankingPointsDoubles: { type: 'number', displayName: 'Points Doubles', columnNames: ['Points', 'Doubles'], readonly: true, clearable: true, sumUpTotals: true, },
        tournamentCountReal: { type: 'number', displayName: 'Tourn Count', columnNames: ['Tourn', 'Count'], readonly: true, clearable: true, sumUpTotals: true, },
        singlesWcCountReal: { type: 'number', displayName: 'Singles WCs', columnNames: ['Singles', 'WCs'], readonly: true, clearable: true, sumUpTotals: true, },
        doublesWcCountReal: { type: 'number', displayName: 'Doubles WCs', columnNames: ['Doubles', 'WCs'], readonly: true, clearable: true, sumUpTotals: true, },
        qualiWcCountReal: { type: 'number', displayName: 'Quali WCs', columnNames: ['Quali', 'WCs'], readonly: true, clearable: true, sumUpTotals: true, },
        mainWcCountReal: { type: 'number', displayName: 'Main WCs', columnNames: ['Main', 'WCs'], readonly: true, clearable: true, sumUpTotals: true, },
        tournamentCountAdjusted: { type: 'number', displayName: 'Tourn Count adj', columnNames: ['Tourn', 'Count', 'adj'], readonly: true, clearable: true, sumUpTotals: true, },
        singlesWcCountAdjusted: { type: 'number', displayName: 'Singles WCs adj', columnNames: ['Singles', 'WCs', 'adj'], readonly: true, clearable: true,sumUpTotals: true,  },
        doublesWcCountAdjusted: { type: 'number', displayName: 'Doubles WCs adj', columnNames: ['Doubles', 'WCs', 'adj'], readonly: true, clearable: true, sumUpTotals: true, },
        qualiWcCountAdjusted: { type: 'number', displayName: 'Quali WCs adj', columnNames: ['Quali', 'WCs', 'adj'], readonly: true, clearable: true, sumUpTotals: true, },
        mainWcCountAdjusted: { type: 'number', displayName: 'Main WCs ads', columnNames: ['Main', 'WCs', 'adj'], readonly: true, clearable: true, sumUpTotals: true, },
        currency: {
            type: 'string', displayName: 'Currency', clearable: true, lookup: [
                { id: 'D', displayName: 'USD' },
                { id: 'E', displayName: 'EUR' },
                { id: 'A', displayName: 'AUD' },
                { id: 'P', displayName: 'GBP' },
                { id: 'No', displayName: 'No' },
            ],
        },
    },
};