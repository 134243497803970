import { isValid, Model } from './model';

export class ShadowRankingPoints extends Model {
    constructor(data) {
        super(data);
    }

    isValid() {
        return isValid(ShadowRankingPoints.schema, this);
    }

    isValidForEdit() {
        return isValid(ShadowRankingPoints.schema, this);
    }
}

ShadowRankingPoints.layout = {
    children: [
        {
            children: ['shadowRankId', 'pointId', 'tableName', 'useRegularPoints'],
        },
        {
            children: ['pointsWin', 'pointsRun', 'pointsSemi', 'pointsQtr', { type: 'spacer' }],
        },
        {
            children: ['points16', 'points32', 'points64', 'points128', 'points256'],
        },
    ],
};

ShadowRankingPoints.schema = {
    fields: {
        shadowRankId: { type: 'number', displayName: 'Shadow Rank Id', sortable: true, readonly: true, required: true },
        pointId: { type: 'string', displayName: 'Point Id', required: true, readonly: true, maxLength: 10 },
        tableName: { type: 'string', displayName: 'Table Name', maxLength: 40, clearable: true },
        pointsWin: { type: 'number', displayName: 'Points Win', min: 0, max: 9999.9999, clearable: true },
        pointsRun: { type: 'number', displayName: 'Points Run', min: 0, max: 9999.9999, clearable: true },
        pointsSemi: { type: 'number', displayName: 'Points Semi', min: 0, max: 9999.9999, clearable: true },
        pointsQtr: { type: 'number', displayName: 'Points Qtr', min: 0, max: 9999.9999, clearable: true },
        points16: { type: 'number', displayName: 'Points 16', min: 0, max: 9999.9999, clearable: true },
        points32: { type: 'number', displayName: 'Points 32', min: 0, max: 9999.9999, clearable: true },
        points64: { type: 'number', displayName: 'Points 64', min: 0, max: 9999.9999, clearable: true },
        points128: { type: 'number', displayName: 'Points 128', min: 0, max: 9999.9999, clearable: true },
        points256: { type: 'number', displayName: 'Points 256', min: 0, max: 9999.9999, clearable: true },
        useRegularPoints: { type: 'boolean', displayName: 'Use regular points', required: true },
    }
};