import {isValid, Model} from './model';

export class ItfImport extends Model {
    isValid() {
        return isValid(ItfImport.schema, this);
    }
}

ItfImport.layout = {
    children: [
        {
            children: ['calendarYear', 'providerTournamentId', 'officialName', 'startDate'],
        },
        {
            children: ['wtaTournamentYear', 'wtaTournamentNumber', 'wtaTournamentName', {type: 'spacer'}],
        },
    ],
};

ItfImport.schema = {
    fields: {
        calendarYear: {type: 'number', displayName: 'Calendar Year', clearable: true,},
        providerTournamentId: {type: 'string', displayName: 'Provider Tournament Id', sortable: true,},
        officialName: {type: 'string', displayName: 'Official Name', sortable: true,},
        startDate: {type: 'us-date', displayName: 'Start Date', clearable: true, sortable: true,},
        prizeMoney: {type: 'number', displayName: 'Prize Money',},
        wtaTournamentYear: {type: 'number', displayName: 'WTA Tournament Year', clearable: true, required: true,},
        wtaTournamentNumber: {type: 'number', displayName: 'WTA Tournament Number', clearable: true, required: true,},
        wtaTournamentName: {type: 'string', displayName: 'WTA Tournament Name', clearable: true, required: true,},
        tournamentDataPresent: {type: 'boolean', displayName: 'Tournament Data Present', clearable: true,},
        resultDataPresent: {type: 'boolean', displayName: 'Result Data Present', clearable: true,},
        isDryRun: {type: 'boolean', displayName: 'Dry Run'},
    },
};
