<template>
    <div>
        <div class="flex-row mb-32">
            <h2><i class="el-icon-date mr-16"></i>WTA Player Zone Admin</h2>
            <span class="spacer"></span>
            <SaveChanges :model="wtaSystem" @save="saveChanges" @discard="discardChanges" />
        </div>

        <ErrorDisplay :requestError="this.loadingWtaSystemError" @closed="clearError" />

        <el-tabs value="entry">
            <el-tab-pane label="Entry System" name="entry">
                <div class="mt-16">
                    <FormGroup :model="wtaSystem" :schema="schema" :layout="layoutEntrySystem" @change="onChange" class="mt-8" />
                </div>
            </el-tab-pane>
            <el-tab-pane label="Payment System" name="payment">
                <PzPayment class="mt-16" />
            </el-tab-pane>
            <el-tab-pane label="Coaches" name="coaches">
                <CoachIndex class="mt-16" />
            </el-tab-pane>
            <el-tab-pane label="Agents" name="agents">
                <AgentIndex class="mt-16" />
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { WtaSystem } from '../models/wta-system';
import { updateConfirmDto } from '../utils/crud-confirm';
import PzPayment from './PzPayment';

export default {
    data() {
        return {
            schema: WtaSystem.schema,
            layoutEntrySystem: WtaSystem.layoutEntrySystem,
        };
    },

    components: {
        PzPayment,
    },

    mounted() {
        this.refresh();
    },

    computed: {
        ...mapGetters('wtaSystem', ['wtaSystem', 'loadingWtaSystem', 'loadingWtaSystemError']),
    },

    methods: {
        ...mapActions('wtaSystem', ['retrieveWtaSystem', 'updateWtaSystem', 'clearWtaLoadingError']),

        refresh() {
            this.retrieveWtaSystem();
        },

        reset() {
            this.refresh();
        },

        saveChanges() {
            let params = {
                self: this,
                fUpdate: this.updateWtaSystem,
                dto: this.wtaSystem,
                dtoName: 'WTA System',
                callback: this.crudCallback,
            };
            updateConfirmDto(params);
        },

        discardChanges() {
            this.wtaSystem.discardChanges();
        },

        onChange() {},

        clearError() {
            this.clearWtaLoadingError();
        },

        crudCallback() {
            this.refresh();
            this.requestError = null;
            this.clearError();
        },
    },
};
</script>

<style scoped></style>
