import axios from 'axios';

export default {
    namespaced: true,

    state: {
        shadowSinglesSummary: {},
        shadowDoublesSummary: {},
        shadowSinglesRaceSummary: {},
        shadowTeamRaceSummaries: [],
        loadingShadowRankingSummaries: false,
        loadingErrorShadowRankingSummaries: null,
    },

    getters: {
        shadowSinglesSummary: (state) => state.shadowSinglesSummary,
        shadowDoublesSummary: (state) => state.shadowDoublesSummary,
        shadowSinglesRaceSummary: (state) => state.shadowSinglesRaceSummary,
        shadowTeamRaceSummaries: (state) => state.shadowTeamRaceSummaries,
        loadingShadowRankingSummaries: (state) => state.loadingShadowRankingSummaries,
        loadingErrorShadowRankingSummaries: (state) => state.loadingErrorShadowRankingSummaries,
    },

    mutations: {
        setShadowRankingSummaries(state, data) {
            state.shadowSinglesSummary = data.singlesComputationResults[0];
            state.shadowDoublesSummary = data.doublesComputationResults[0];
            state.shadowSinglesRaceSummary = data.raceSinglesComputationResults[0];
            state.shadowTeamRaceSummaries = data.raceDoublesComputationResults;
        },
        clearShadowRankingSummaries(state) {
            state.shadowSinglesSummary = {};
            state.shadowDoublesSummary = {};
            state.shadowSinglesRaceSummary = {};
            state.shadowTeamRaceSummaries = [];
        },
        startLoadingShadowRankingSummaries(state) {
            state.loadingShadowRankingSummaries = true;
        },
        stopLoadingShadowRankingSummaries(state) {
            state.loadingShadowRankingSummaries = false;
        },
        setLoadingErrorShadowRankingSummaries(state, error) {
            state.loadingErrorShadowRankingSummaries = error;
        },
        clearLoadingErrorShadowRankingSummaries(state) {
            state.loadingErrorShadowRankingSummaries = null;
        },
    },

    actions: {
        async dryRunShadowRankingProcedure(context, options) {
            context.commit('startLoadingShadowRankingSummaries');
            context.commit('clearLoadingErrorShadowRankingSummaries');
            try {
                let rankingDate = options.rankingDate;
                let playerId = options.playerId;
                let srId = options.shadowRankId;
                context.commit('clearShadowRankingSummaries');
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/ShadowRanking/RankingProcedure/DryRun/Date/${rankingDate}/PlayerNbr/${playerId}/ShadowRankId/${srId}`);
                context.commit('setShadowRankingSummaries', result.data[0]);
                context.commit('stopLoadingShadowRankingSummaries');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('setLoadingErrorShadowRankingSummaries', error);
                context.commit('stopLoadingShadowRankingSummaries');
                return Promise.reject(error);
            }
        },
    },
};
