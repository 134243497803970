import axios from 'axios';
import moment from 'moment';
import * as _ from 'lodash';
import { PlayerDevelopment } from '../models/player-development';

export default {
    namespaced: true,

    state: {
        playerDevelopment: new PlayerDevelopment(),
        playerDevelopmentFound: false,
        loadingPlayerDevelopment: false,
        loadingError: null,

        eligibilityDates: [],
        loadingEligibilityDates: false,
        updatingEligibilityDates: false,
        downloadingEligibilityDates: false,
        loadingDatesError: null,
        updateError: null,

        developmentRequirementsUpToDate: null,
        completedRequirements: [],
        outstandingRequirements: [],
        overdueRequirements: [],

        loadingDevelopmentRequirementsUpToDate: false,
        loadingCompletedRequirements: false,
        loadingOutstandingRequirements: false,
        loadingOverdueRequirements: false,
        computingDevelopmentRequirementsReports: false,

        loadingErrorDevelopmentRequirementsUpToDate: null,
        loadingCompletedRequirementsError: null,
        loadingOutstandingRequirementsError: null,
        loadingOverdueRequirementsError: null,
        computingErrorDevelopmentRequirementsReports: null,

        loadingPdfCompletedRequirements: false,
        loadingPdfOutstandingRequirements: false,
        loadingPdfOverdueRequirements: false,
        loadingPdfPdDetailTracking: false,
    },

    getters: {
        playerDevelopment: (state) => state.playerDevelopment,
        playerDevelopmentFound: (state) => state.playerDevelopmentFound,
        loadingPlayerDevelopment: (state) => state.loadingPlayerDevelopment,
        loadingError: (state) => state.loadingError,

        eligibilityDates: (state) => state.eligibilityDates,
        downloadingEligibilityDates: (state) => state.downloadingEligibilityDates,
        updatingEligibilityDates: (state) => state.updatingEligibilityDates,
        loadingEligibilityDates: (state) => state.loadingEligibilityDates,
        loadingDatesError: (state) => state.loadingDatesError,
        updateError: (state) => state.updateError,

        developmentRequirementsUpToDate: (state) => state.developmentRequirementsUpToDate,
        completedRequirements: (state) => state.completedRequirements,
        outstandingRequirements: (state) => state.outstandingRequirements,
        overdueRequirements: (state) => state.overdueRequirements,

        loadingDevelopmentRequirementsUpToDate: (state) => state.loadingDevelopmentRequirementsUpToDate,
        loadingCompletedRequirements: (state) => state.loadingCompletedRequirements,
        loadingOutstandingRequirements: (state) => state.loadingOutstandingRequirements,
        loadingOverdueRequirements: (state) => state.loadingOverdueRequirements,
        computingDevelopmentRequirementsReports: (state) => state.computingDevelopmentRequirementsReports,

        loadingErrorDevelopmentRequirementsUpToDate: (state) => state.loadingErrorDevelopmentRequirementsUpToDate,
        loadingCompletedRequirementsError: (state) => state.loadingCompletedRequirementsError,
        loadingOutstandingRequirementsError: (state) => state.loadingOutstandingRequirementsError,
        loadingOverdueRequirementsError: (state) => state.loadingOverdueRequirementsError,
        computingErrorDevelopmentRequirementsReports: (state) => state.computingErrorDevelopmentRequirementsReports,

        loadingPdfCompletedRequirements: (state) => state.loadingPdfCompletedRequirements,
        loadingPdfOutstandingRequirements: (state) => state.loadingPdfOutstandingRequirements,
        loadingPdfOverdueRequirements: (state) => state.loadingPdfOverdueRequirements,
        loadingPdfPdDetailTracking: (state) => state.loadingPdfPdDetailTracking,
    },

    mutations: {
        setPlayerDevelopment(state, data) { state.playerDevelopment = data; },
        setPlayerDevelopmentFound(state) { state.playerDevelopmentFound = true; },
        clearPlayerDevelopmentFound(state) { state.playerDevelopmentFound = false; },
        startLoadingPlayerDevelopment(state) { state.loadingPlayerDevelopment = true; },
        stopLoadingPlayerDevelopment(state) { state.loadingPlayerDevelopment = false; },
        setLoadingError(state, error) { state.loadingError = error; },
        clearLoadingError(state) { state.loadingError = null; },

        setEligibilityDates(state, data) { state.eligibilityDates = data; },
        startDownloadingEligibilityDates(state) { state.downloadingEligibilityDates = true; },
        stopDownloadingEligibilityDates(state) { state.downloadingEligibilityDates = false; },
        startUpdatingEligibilityDates(state) { state.updatingEligibilityDates = true; },
        stopUpdatingEligibilityDates(state) { state.updatingEligibilityDates = false; },
        startLoadingEligibilityDates(state) { state.loadingEligibilityDates = true; },
        stopLoadingEligibilityDates(state) { state.loadingEligibilityDates = false; },
        setLoadingDatesError(state, error) { state.loadingDatesError = error; },
        clearLoadingDatesError(state) { state.loadingDatesError = null; },
        setUpdateError(state, error) { state.updateError = error; },
        clearUpdateError(state) { state.updateError = null; },


        setDevelopmentRequirementsUpToDate(state, data) { state.developmentRequirementsUpToDate = data; },
        setCompletedRequirements(state, data) { state.completedRequirements = data; },
        setOutstandingRequirements(state, data) { state.outstandingRequirements = data; },
        setOverdueRequirements(state, data) { state.overdueRequirements = data; },

        startLoadingDevelopmentRequirementsUpToDate(state) { state.loadingDevelopmentRequirementsUpToDate = true; },
        startLoadingCompletedRequirements(state) { state.loadingCompletedRequirements = true; },
        startLoadingOutstandingRequirements(state) { state.loadingOutstandingRequirements = true; },
        startLoadingOverdueRequirements(state) { state.loadingOverdueRequirements = true; },
        startComputingDevelopmentRequirementsReports(state) { state.computingDevelopmentRequirementsReports = true; },

        stopLoadingDevelopmentRequirementsUpToDate(state) { state.loadingDevelopmentRequirementsUpToDate = false; },
        stopLoadingCompletedRequirements(state) { state.loadingCompletedRequirements = false; },
        stopLoadingOutstandingRequirements(state) { state.loadingOutstandingRequirements = false; },
        stopLoadingOverdueRequirements(state) { state.loadingOverdueRequirements = false; },
        stopComputingDevelopmentRequirementsReports(state) { state.computingDevelopmentRequirementsReports = false; },

        setLoadingErrorDevelopmentRequirementsUpToDate(state, error) { state.loadingErrorDevelopmentRequirementsUpToDate = error; },
        setLoadingCompletedRequirementsError(state, error) { state.loadingCompletedRequirementsError = error; },
        setLoadingOutstandingRequirementsError(state, error) { state.loadingOutstandingRequirementsError = error; },
        setLoadingOverdueRequirementsError(state, error) { state.loadingOverdueRequirementsError = error; },
        setComputingErrorDevelopmentRequirementsReports(state, error) { state.computingErrorDevelopmentRequirementsReports = error; },

        clearLoadingErrorDevelopmentRequirementsUpToDate(state) { state.loadingErrorDevelopmentRequirementsUpToDate = null; },
        clearLoadingCompletedRequirementsError(state) { state.loadingCompletedRequirementsError = null; },
        clearLoadingOutstandingRequirementsError(state) { state.loadingOutstandingRequirementsError = null; },
        clearLoadingOverdueRequirementsError(state) { state.loadingOverdueRequirementsError = null; },
        clearComputingErrorDevelopmentRequirementsReports(state) { state.computingErrorDevelopmentRequirementsReports = null; },

        startLoadingPdfCompletedRequirements(state) { state.loadingPdfCompletedRequirements = true; },
        startLoadingPdfOutstandingRequirements(state) { state.loadingPdfOutstandingRequirements = true; },
        startLoadingPdfOverdueRequirements(state) { state.loadingPdfOverdueRequirements = true; },
        startLoadingPdfPdDetailTracking(state) { state.loadingPdfPdDetailTracking = true; },

        stopLoadingPdfCompletedRequirements(state) { state.loadingPdfCompletedRequirements = false; },
        stopLoadingPdfOutstandingRequirements(state) { state.loadingPdfOutstandingRequirements = false; },
        stopLoadingPdfOverdueRequirements(state) { state.loadingPdfOverdueRequirements = false; },
        stopLoadingPdfPdDetailTracking(state) { state.loadingPdfPdDetailTracking = false; },
    },

    actions: {
        async findPlayerDevelopment(context, playerNbr) {
            if (!playerNbr) {
                return;
            }
            context.commit('startLoadingPlayerDevelopment');
            try {
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/Player/${playerNbr}/Development`);
                context.commit('stopLoadingPlayerDevelopment');
                context.commit('setPlayerDevelopment', new PlayerDevelopment(result.data));
                context.commit('setPlayerDevelopmentFound');
                context.commit('clearLoadingError');
                return Promise.resolve(result);
            } catch (error) {
                context.commit('stopLoadingPlayerDevelopment');
                context.commit('clearPlayerDevelopmentFound');
                context.commit('setLoadingError', error);
                return Promise.reject(error);
            }
        },

        async createPlayerDevelopment(context, data = {}) {
            if (!data.data.playerNbr) {
                return;
            }
            context.commit('startLoadingPlayerDevelopment');
            let playerNbr = data.data.playerNbr;
            try {
                const result = await axios.post(`${process.env.VUE_APP_API_URL}/Player/${playerNbr}/Development`, data.data);
                context.commit('stopLoadingPlayerDevelopment');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('setLoadingError', error);
                context.commit('stopLoadingPlayerDevelopment');
                return Promise.reject(error);
            }
        },

        async updatePlayerDevelopment(context, data = {}) {
            if (!data.data.playerNbr) {
                return;
            }
            context.commit('startLoadingPlayerDevelopment');
            let playerNbr = data.data.playerNbr;
            try {
                const result = await axios.put(`${process.env.VUE_APP_API_URL}/Player/${playerNbr}/Development`, data.data);
                context.commit('stopLoadingPlayerDevelopment');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('setLoadingError', error);
                context.commit('stopLoadingPlayerDevelopment');
                return Promise.reject(error);
            }
        },

        async deletePlayerDevelopment(context, data = {}) {
            if (!data.data.playerNbr) {
                return;
            }
            context.commit('startLoadingPlayerDevelopment');
            let playerNbr = data.data.playerNbr;
            try {
                const result = await axios.delete(`${process.env.VUE_APP_API_URL}/Player/${playerNbr}/Development`);
                context.commit('stopLoadingPlayerDevelopment');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('setLoadingError', error);
                context.commit('stopLoadingPlayerDevelopment');
                return Promise.reject(error);
            }
        },

        async updateEligibilityDates(context) {
            context.commit('startUpdatingEligibilityDates');
            try {
                const result = await axios.post(`${process.env.VUE_APP_API_URL}/Player/Development/CreateAndUpdateEligibilityDates/Run`);
                context.commit('stopUpdatingEligibilityDates');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('setLoadingError', error);
                context.commit('stopUpdatingEligibilityDates');
                return Promise.reject(error);
            }
        },

        async updateEligibilityDatesDryRun(context) {
            context.commit('startUpdatingEligibilityDates');
            try {
                const result = await axios.post(`${process.env.VUE_APP_API_URL}/Player/Development/CreateAndUpdateEligibilityDates/DryRun`);
                context.commit('stopUpdatingEligibilityDates');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('setLoadingError', error);
                context.commit('stopUpdatingEligibilityDates');
                return Promise.reject(error);
            }
        },

        async findEligibilityDates(context, options = {}) {
            context.commit('startLoadingEligibilityDates');
            context.commit('clearLoadingDatesError');
            try {
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/Player/Development/ComputedEligibilityDates`, {
                    params: {
                        queryString: options.queryString || '',
                        pageSize: options.pageSize || 50,
                        sortBy: options.sortBy || '',
                        sortDir: options.sortDir === 'descending' ? -1 : 1,
                        page: options.page,
                        nationality: options.nationality,
                        ageAt: options.ageAt,
                    },
                });
                context.commit('setEligibilityDates', result.data);
                context.commit('stopLoadingEligibilityDates');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('setLoadingDatesError', error);
                context.commit('setEligibilityDates', []);
                context.commit('stopLoadingEligibilityDates');
                return Promise.reject(error);
            }
        },

        async downloadEligibilityDatesCsv(context, options = {}) {
            context.commit('startDownloadingEligibilityDates');
            try {
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/Player/Development/ComputedEligibilityDates/Export/Csv`, {
                    params: {
                        queryString: options.queryString || '',
                        pageSize: options.pageSize || 50,
                        sortBy: options.sortBy || '',
                        sortDir: options.sortDir === 'descending' ? -1 : 1,
                        page: options.page,
                        nationality: options.nationality,
                        ageAt: options.ageAt,
                    },
                });
                context.commit('stopDownloadingEligibilityDates');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('stopDownloadingEligibilityDates');
                return Promise.reject(error);
            }
        },

        async findCompletedRequirements(context, options = {}) {
            context.commit('startLoadingCompletedRequirements');
            context.commit('clearLoadingCompletedRequirementsError');
            let paramOptions = {
                queryString: options.queryString,
                pageSize: options.pageSize,
                sortBy: options.sortBy ? _.upperFirst(options.sortBy) : null,
                sortDir: options.sortDir === 'ascending' ? '1' : '-1',
                page: options.page,
                nationality: options.nationality,
                ageAt: options.ageAt,
                ppStatus: options.ppStatus,
                currentPhase: options.currentPhase,
                playerAge: options.playerAge,
                requirementName: options.requirementName,
                requirementYear: options.requirementYear,
                requirementFrom: options.requirementFrom,
                requirementTo: options.requirementTo,
            };
            try {
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/Player/Development/CompletedRequirements`,
                    { params: paramOptions });
                context.commit('setCompletedRequirements', result.data);
                context.commit('stopLoadingCompletedRequirements');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('setLoadingCompletedRequirementsError', error);
                context.commit('setCompletedRequirements', []);
                context.commit('stopLoadingCompletedRequirements');
                return Promise.reject(error);
            }
        },

        async findOutstandingRequirements(context, options = {}) {
            context.commit('startLoadingOutstandingRequirements');
            context.commit('clearLoadingOutstandingRequirementsError');
            let paramOptions = {
                queryString: options.queryString,
                pageSize: options.pageSize,
                sortBy: options.sortBy ? _.upperFirst(options.sortBy) : null,
                sortDir: options.sortDir === 'ascending' ? '1' : '-1',
                page: options.page,
                nationality: options.nationality,
                ageAt: options.ageAt,
                ppStatus: options.ppStatus,
                currentPhase: options.currentPhase,
                playerAge: options.playerAge,
            };
            try {
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/Player/Development/OutstandingRequirements`,
                    { params: paramOptions });
                context.commit('setOutstandingRequirements', result.data);
                context.commit('stopLoadingOutstandingRequirements');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('setLoadingOutstandingRequirementsError', error);
                context.commit('setOutstandingRequirements', []);
                context.commit('stopLoadingOutstandingRequirements');
                return Promise.reject(error);
            }
        },

        async findOverdueRequirements(context, options = {}) {
            context.commit('startLoadingOverdueRequirements');
            context.commit('clearLoadingOverdueRequirementsError');
            let paramOptions = {
                queryString: options.queryString,
                pageSize: options.pageSize,
                sortBy: options.sortBy ? _.upperFirst(options.sortBy) : null,
                sortDir: options.sortDir === 'ascending' ? '1' : '-1',
                page: options.page,
                nationality: options.nationality,
                ageAt: options.ageAt,
                ppStatus: options.ppStatus,
                currentPhase: options.currentPhase,
                playerAge: options.playerAge,
            };
            try {
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/Player/Development/OverdueRequirements`,
                    { params: paramOptions });
                context.commit('setOverdueRequirements', result.data);
                context.commit('stopLoadingOverdueRequirements');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('setLoadingOverdueRequirementsError', error);
                context.commit('setOverdueRequirements', []);
                context.commit('stopLoadingOverdueRequirements');
                return Promise.reject(error);
            }
        },

        async retieveDevelopmentRequirementsUpToDate(context) {
            context.commit('startLoadingDevelopmentRequirementsUpToDate');
            context.commit('clearLoadingErrorDevelopmentRequirementsUpToDate');
            try {
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/Player/Development/RequirementsUpToDate`);
                context.commit('setDevelopmentRequirementsUpToDate', result.data);
                context.commit('stopLoadingDevelopmentRequirementsUpToDate');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('setLoadingErrorRequirementsUpToDate', error);
                context.commit('setDevelopmentRequirementsUpToDate', null);
                context.commit('stopLoadingDevelopmentRequirementsUpToDate');
                return Promise.reject(error);
            }
        },

        async computeDevelopmentRequirementsReports(context, upToDate) {
            context.commit('startComputingDevelopmentRequirementsReports');
            context.commit('clearComputingErrorDevelopmentRequirementsReports');
            let url = `${process.env.VUE_APP_API_URL}/Player/Development/ComputeRequirements/${moment(upToDate).format('YYYY-MM-DD')}`;
            try {
                const result = await axios.get(url);
                context.commit('stopComputingDevelopmentRequirementsReports');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('setComputingErrorDevelopmentRequirementsReports', error);
                context.commit('stopComputingDevelopmentRequirementsReports');
                return Promise.reject(error);
            }
        },

        async exportPdfCompletedRequirements(context, options = {}) {
            context.commit('startLoadingPdfCompletedRequirements');
            context.commit('clearLoadingCompletedRequirementsError');
            let paramOptions = {
                queryString: options.queryString,
                pageSize: null,
                sortBy: options.sortBy ? _.upperFirst(options.sortBy) : null,
                sortDir: options.sortDir === 'ascending' ? '1' : '-1',
                page: 0,
                nationality: options.nationality,
                ageAt: options.ageAt,
                ppStatus: options.ppStatus,
                currentPhase: options.currentPhase,
                playerAge: options.playerAge,
                requirementName: options.requirementName,
                requirementYear: options.requirementYear,
            };
            try {
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/Player/Development/CompletedRequirements/Export/Pdf`,
                    { params: paramOptions, responseType: 'blob' });
                context.commit('stopLoadingPdfCompletedRequirements');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('setLoadingCompletedRequirementsError', error);
                context.commit('stopLoadingPdfCompletedRequirements');
                return Promise.reject(error);
            }
        },

        async exportPdfOutstandingRequirements(context, options = {}) {
            context.commit('startLoadingPdfOutstandingRequirements');
            context.commit('clearLoadingOutstandingRequirementsError');
            let paramOptions = {
                queryString: options.queryString,
                pageSize: null,
                sortBy: options.sortBy ? _.upperFirst(options.sortBy) : null,
                sortDir: options.sortDir === 'ascending' ? '1' : '-1',
                page: 0,
                nationality: options.nationality,
                ageAt: options.ageAt,
                ppStatus: options.ppStatus,
                currentPhase: options.currentPhase,
                playerAge: options.playerAge,
            };
            try {
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/Player/Development/OutstandingRequirements/Export/Pdf`,
                    { params: paramOptions, responseType: 'blob' });
                context.commit('stopLoadingPdfOutstandingRequirements');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('setLoadingOutstandingRequirementsError', error);
                context.commit('stopLoadingPdfOutstandingRequirements');
                return Promise.reject(error);
            }
        },

        async exportPdfOverdueRequirements(context, options = {}) {
            context.commit('startLoadingPdfOverdueRequirements');
            context.commit('clearLoadingOverdueRequirementsError');
            let paramOptions = {
                queryString: options.queryString,
                pageSize: null,
                sortBy: options.sortBy ? _.upperFirst(options.sortBy) : null,
                sortDir: options.sortDir === 'ascending' ? '1' : '-1',
                page: 0,
                nationality: options.nationality,
                ageAt: options.ageAt,
                ppStatus: options.ppStatus,
                currentPhase: options.currentPhase,
                playerAge: options.playerAge,
            };
            try {
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/Player/Development/OverdueRequirements/Export/Pdf`,
                    { params: paramOptions, responseType: 'blob' });
                context.commit('stopLoadingPdfOverdueRequirements');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('setLoadingOverdueRequirementsError', error);
                context.commit('stopLoadingPdfOverdueRequirements');
                return Promise.reject(error);
            }
        },

        async exportPdfPdDetailTracking(context, playerId) {
            context.commit('startLoadingPdfPdDetailTracking');
            try {
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/Player/${playerId}/Development/Export/Pdf`,
                    { responseType: 'blob' });
                context.commit('stopLoadingPdfPdDetailTracking');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('stopLoadingPdfPdDetailTracking');
                return Promise.reject(error);
            }
        },
    },
}