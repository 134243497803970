<template>
  <div>
    <el-dialog :visible.sync="visibleSync" :close-on-click-modal="false" title="Overdue Requirements" width="65%"
               top="10vh" :show-close="false">
      <div class="dialog-main">
        <FormGroup :model="selectedPlayer" :schema="schema" :layout="layoutContact" @change="onChange"
                   :allReadonly="true"/>
        <FormGroup :model="selectedPlayer" :schema="schema" :layout="layout" @change="onChange" :allReadonly="true"/>
      </div>
      <div class="dialog-footer">
        <el-button @click="onClose" plain type="primary">Close</el-button>
        <span class="spacer"></span>
        <el-button @click="gotoPrevPlayer" plain type="primary">Prev</el-button>
        <el-button @click="gotoNextPlayer" plain type="primary">Next</el-button>
        <span class="spacer"></span>
        <el-button @click="onEditPlayerDevelopment" plain type="primary">Open Player Development</el-button>
      </div>
    </el-dialog>

    <div class="flex-row mt-16 mb-16">
      <h3>Overdue Requirements {{ this.formattedRankDate }}</h3>
      <span class="spacer"></span>

      <SelectNationality
          v-model="options.nationality"
          :lookup="nationalities"
          :clearable="true"
          @select="resetPage"
          @keydown.enter.native="resetPage"
      />
      <el-input
          placeholder="Filter by Id or Name"
          v-model="options.queryString"
          class="input-search mr-16"
          @change="resetPage"
          @keydown.esc.native="reset"
          @keydown.enter.native="resetPage"
      >
        <el-button slot="append" icon="el-icon-search" @click="resetPage"></el-button>
      </el-input>
    </div>
    <div class="flex-row mt-16 mb-16">
      <el-radio-group v-model="options.playerAge" @change="resetPage" size="mini" class="mr-32">
        <el-radio-button label="ALL">All</el-radio-button>
        <el-radio-button label="13">&lt; 14</el-radio-button>
        <el-radio-button label="14"> 14</el-radio-button>
        <el-radio-button label="15"> 15</el-radio-button>
        <el-radio-button label="16"> 16</el-radio-button>
        <el-radio-button label="17"> 17</el-radio-button>
        <el-radio-button label="18"> 18</el-radio-button>
      </el-radio-group>

      <el-radio-group v-model="options.ppStatus" @change="resetPage" size="mini" class="mr-32">
        <el-radio-button label="ALL">All</el-radio-button>
        <el-radio-button label="INTRO">Intro</el-radio-button>
        <el-radio-button label="PROPATH">Pro Path</el-radio-button>
      </el-radio-group>

      <el-radio-group v-model="options.currentPhase" @change="resetPage" size="mini" class="mr-32">
        <el-radio-button label="ALL">All</el-radio-button>
        <el-radio-button label="1">Intro</el-radio-button>
        <el-radio-button label="2">Rookie</el-radio-button>
        <el-radio-button label="3">Elite</el-radio-button>
        <el-radio-button label="4">Premier</el-radio-button>
      </el-radio-group>
      <span class="spacer"></span>
      <el-button icon="el-icon-document" @click="onClickExportPdf" v-loading="loadingPdfOverdueRequirements"
                 class="mr-16">Export PDF
      </el-button>
    </div>

    <div v-loading="loadingOverdueRequirements || computingDevelopmentRequirementsReports">
      <DataTable
          v-if="!loadingOverdueRequirementsError"
          :schema="schema"
          :items="overdueRequirements.data"
          :fields="['playerNbr', 'fullName', 'playerAge', 'birthDate', 'nationality', 'ppStatus', 'currPhase', 'overdueRequirements']"
          :autoSort="false"
          :sortField="options.sortBy"
          :sortDir="options.sortDir"
          @item-clicked="selectPlayer"
          @header-clicked="setSort"
      />

      <Paginator
          v-if="!loadingOverdueRequirementsError"
          :data="overdueRequirements"
          :elementsPerPage="options.pageSize"
          @prev-page="prevPage"
          @next-page="nextPage"
          @set-page-size="setPageSize"
      />
    </div>
  </div>
</template>


<script>
import {mapGetters, mapActions} from 'vuex';
import {PlayerDevelopment} from '../../models/player-development';
import {icoCountries} from '../../utils/country-codes';
import {retrieveDtos} from '../../utils/crud-confirm';
import moment from 'moment';

export default {
  data() {
    return {
      schema: PlayerDevelopment.schema,
      layoutContact: PlayerDevelopment.layoutContact,
      layout: PlayerDevelopment.layoutOverdueRequriements,
      requestError: null,
      nationalities: Object.keys(icoCountries),
      visibleSync: false,
      selectedPlayer: null,
      playerPointer: null,
      options: {
        queryString: '',
        pageSize: 20,
        sortBy: null,
        sortDir: 'ascending',
        page: 0,
        nationality: '',
        ageAt: null,
        ppStatus: 'ALL',
        currentPhase: 'ALL',
        playerAge: 'ALL',
      },
    };
  },
  mounted() {
    this.search();
  },
  computed: {
    ...mapGetters('playerDevelopments', [
      'overdueRequirements',
      'loadingOverdueRequirements',
      'loadingOverdueRequirementsError',
      'developmentRequirementsUpToDate',
      'computingDevelopmentRequirementsReports',
      'loadingPdfOverdueRequirements',
    ]),
    formattedRankDate() {
      return this.developmentRequirementsUpToDate
          ? ' up to ' + moment(this.developmentRequirementsUpToDate).format('YYYY-MM-DD')
          : ' - please select Up to Date';
    },
  },
  methods: {
    ...mapActions('playerDevelopments', ['findOverdueRequirements', 'retieveDevelopmentRequirementsUpToDate', 'exportPdfOverdueRequirements']),
    refresh() {
      this.retieveDevelopmentRequirementsUpToDate();
      this.search();
    },
    search() {
      let params = {
        self: this,
        fRetrieve: this.findOverdueRequirements,
        options: this.options,
        dtosName: 'Overdue Requirements',
      };
      retrieveDtos(params);
    },
    selectPlayer(item, index) {
      this.playerPointer = index;
      this.selectedPlayer = new PlayerDevelopment(item);
      this.visibleSync = true;
    },
    setSort(event) {
      console.log(event);
      this.options.sortBy = event.property;
      this.options.sortDir = event.order;
      this.search();
    },
    prevPage() {
      this.options.page--;
      this.search();
    },
    nextPage() {
      this.options.page++;
      this.search();
    },
    setPageSize(size) {
      this.options.pageSize = size;
      this.search();
    },
    resetPage() {
      this.options.page = 0;
      this.search();
    },
    reset() {
      this.options.queryString = null;
      this.options.page = 0;
      this.search();
    },
    onClose() {
      this.visibleSync = false;
    },
    gotoPrevPlayer() {
      if (this.playerPointer == 0) {
        return;
      }
      this.playerPointer -= 1;
      this.selectedPlayer = new PlayerDevelopment(this.overdueRequirements.data[this.playerPointer]);
    },
    gotoNextPlayer() {
      if (this.playerPointer == this.overdueRequirements.data.length - 1) {
        return;
      }
      this.playerPointer += 1;
      this.selectedPlayer = new PlayerDevelopment(this.overdueRequirements.data[this.playerPointer]);
    },
    onEditPlayerDevelopment() {
      let route = this.$router.resolve({path: `/players/${this.selectedPlayer.data.playerNbr}?activeTab=playerDevelopment`});
      window.open(route.href, '_blank');
    },
    onChange() {
    },

    onClickExportPdf() {
      this.downloadPdf(this.exportPdfOverdueRequirements, 'PD_Requirements_Overdue.pdf');
    },

    downloadPdf(fDownload, filename) {
      this.requestError = null;
      fDownload(this.options)
          .then((result) => {
            const file = new Blob([result], {type: 'application/pdf'});
            saveAs(file, filename);
            this.$notify({
              title: 'PDF Export: Overdue PD Requirements download successful',
              message: filename + ' downloaded',
              type: 'success',
              duration: 5000,
            });
          })
          .catch((error) => {
            this.requestError = error;
            this.$notify({
              title: 'PDF Export: Overdue PD Requirements download failed',
              message: filename + ': failed',
              type: 'error',
              duration: 7000,
            });
          });
    },
  },
};
</script>

<style>
.dialog-main {
  min-height: 530px;
}
</style>
