import axios from 'axios';

export default {
    namespaced: true,

    state: {
        pzPaymentDates: [],
        loadingPzPaymentDates: false,
        loadingErrorPzPaymentDates: null,
    },

    getters: {
        pzPaymentDates: (state) => state.pzPaymentDates,
        loadingPzPaymentDates: (state) => state.loadingPzPaymentDates,
        loadingErrorPzPaymentDates: (state) => state.loadingErrorPzPaymentDates,
    },

    mutations: {
        setPzPaymentDates(state, data) { state.pzPaymentDates = data; },

        startLoadingPzPaymentDates(state) { state.loadingPzPaymentDates = true; },
        stopLoadingPzPaymentDates(state) { state.loadingPzPaymentDates = false; },

        setLoadingErrorPzPaymentDates(state, error) { state.loadingErrorPzPaymentDates = error; },
        clearLoadingErrorPzPaymentDates(state) { state.loadingErrorPzPaymentDates = null; },
    },

    actions: {
        async getPzPaymentDates(context) {
            context.commit('startLoadingPzPaymentDates');
            context.commit('clearLoadingErrorPzPaymentDates');
            try {
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/PzPaymentDate/`);
                context.commit('setPzPaymentDates', result.data);
                context.commit('stopLoadingPzPaymentDates');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('setLoadingErrorPzPaymentDates', error);
                context.commit('stopLoadingPzPaymentDates');
                return Promise.reject(error);
            }
        },

        async createPzPaymentDate(context, data) {
            context.commit('startLoadingPzPaymentDates');
            try {
                const result = await axios.post(`${process.env.VUE_APP_API_URL}/PzPaymentDate/`, data.data);
                context.commit('stopLoadingPzPaymentDates');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('setLoadingErrorPzPaymentDates', error);
                context.commit('stopLoadingPzPaymentDates');
                return Promise.reject(error);
            }
        },

        async updatePzPaymentDate(context, data) {
            context.commit('startLoadingPzPaymentDates');
            try {
                const result = await axios.put(`${process.env.VUE_APP_API_URL}/PzPaymentDate/`, data.data);
                context.commit('stopLoadingPzPaymentDates');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('setLoadingErrorPzPaymentDates', error);
                context.commit('stopLoadingPzPaymentDates');
                return Promise.reject(error);
            }
        },

        async deletePzPaymentDate(context, id) {
            context.commit('startLoadingPzPaymentDates');
            try {
                const result = await axios.delete(`${process.env.VUE_APP_API_URL}/PzPaymentDate/${id}`);
                context.commit('stopLoadingPzPaymentDates');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('setLoadingErrorPzPaymentDates', error);
                context.commit('stopLoadingPzPaymentDates');
                return Promise.reject(error);
            }
        },
    },
};
