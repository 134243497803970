<template>
    <div>
        <div class="entryList" style="width: 100%;" v-if="sliceSize !== undefined">
            <div v-for="(chunk, cindex) in drawChunks(sliceSize)">
                <table class="SingleEntries entry" style="width: 50%; margin-left: auto; margin-right:auto;float:left">
                    <thead v-if="cindex < 2">
                        <tr>
                            <th style="width:1%">Pos</th>
                            <th style="width:2%"></th>
                            <th style="width:35%">Player Name</th>
                            <th style="width:5%">Nat</th>
                            <th style="width:7%">{{ useCurrentRanks ? 'Cur Rank' : 'Rank' }}</th>
                            <th style="width:2%">Pref</th>
                        </tr>
                    </thead>
                    <thead v-if="cindex >= 2">
                        <tr>
                            <th style="width:1%">&nbsp;</th>
                            <th style="width:2%"></th>
                            <th style="width:35%">&nbsp;</th>
                            <th style="width:5%">&nbsp;</th>
                            <th style="width:7%">&nbsp;</th>
                            <th style="width:2%">&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(entry, index) in chunk" :style="computeStyleForEntry(entry)">
                            <td class="pos">{{ cindex * sliceSize + index + 1 }}</td>
                            <td>
                                <b>{{ entry.membership }}</b>
                            </td>
                            <td>{{ entry.playerName }} {{ isAcceptedPlayerFromTop10(entry) ? '(EX)' : '' }}</td>
                            <td>{{ entry.nationality }}</td>
                            <td>{{ getRank(entry) }}</td>
                            <td>
                                <b>{{ computePreference(entry) }}</b>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div class="entryList" style="width: 100%;" v-if="sliceSize === undefined">
            <table class="SingleEntries entry" style="width: 50%; margin-left: auto; margin-right:auto; float:left;">
                <thead>
                    <tr>
                        <th style="width:1%">Pos</th>
                        <th style="width:2%"></th>
                        <th style="width:35%">Player Name</th>
                        <th style="width:5%">Nat</th>
                        <th style="width:7%">{{ useCurrentRanks ? 'Cur Rank' : 'Rank' }}</th>
                        <th style="width:2%">Pref</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(entry, index) in draw" :style="computeStyleForEntry(entry)">
                        <td class="pos">{{ 1 + index }}</td>
                        <td>
                            <b>{{ entry.membership }}</b>
                        </td>
                        <td>{{ entry.playerName }} {{ isAcceptedPlayerFromTop10(entry) ? '(EX)' : '' }}</td>
                        <td>{{ entry.nationality }}</td>
                        <td>{{ getRank(entry) }}</td>
                        <td>
                            <b>{{ computePreference(entry) }}</b>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>
<script>
export default {
    name: 'singles-entry-list',
    props: {
        edition: null,
        drawName: { type: String, default: '' },
        draw: {},
        useCurrentRanks: { type: Boolean, default: false },
        sliceSize: {},
    },
    methods: {
        getRank: function(entry) {
            return this.useCurrentRanks ? entry.currentRank : entry.entryRank;
        },
        computeStyleForEntry: function(entry) {
            if (entry.status === 'AcceptedIntoMain' || entry.status === 'AcceptedIntoQualification' || entry.status === 'Entered') {
                return '';
            } else if (
                entry.status === 'WithdrawnFromMainDrawAfterEntryDeadline' ||
                entry.status === 'WithdrawnFromQualificationDrawAfterEntryDeadline' ||
                entry.status === 'WithdrawnAfterEntryDeadline'
            ) {
                let cssStrikethrough = 'text-decoration: line-through;';
                if (this.drawName === 'Main') {
                    if (this.edition.tournamentAcceptanceListSinglesMainIsPublished === true) {
                        return cssStrikethrough;
                    }
                } else if (this.drawName === 'Qualification') {
                    if (this.edition.tournamentAcceptanceListSinglesQualiIsPublished === true) {
                        return cssStrikethrough;
                    }
                } else {
                    console.log('Not displaying ' + JSON.stringify(entry) + ' with status ' + entry.status + ' and draw ' + this.drawName);
                    return 'display: none;';
                }
            } else {
                console.log('Not displaying ' + JSON.stringify(entry) + ' with status ' + entry.status);
                return 'display: none;';
            }
        },
        isAcceptedPlayerFromTop10(entry) {
            if (
                entry.isConfirmedPlayerForInternationalTournaments !== undefined &&
                entry.isConfirmedPlayerForInternationalTournaments !== '' &&
                entry.isConfirmedPlayerForInternationalTournaments !== null
            ) {
                return true;
            }
            return false;
        },
        drawChunks: function(chunkSize) {
            const R = [];
            let i = 0,
                len = this.draw.length;
            for (; i < len; i += chunkSize) R.push(this.draw.slice(i, i + chunkSize));
            return R;
        },
        computePreference: function(entry) {
            switch (entry.preference) {
                case 'First':
                    return 1;
                case 'Second':
                    return 2;
                case 'Third':
                    return 3;
                default:
                    return 1;
            }
        },
    },
};
</script>
<style>
.entryList table {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
}

.entryList td {
    text-align: center;
}

.entryList .tr td:nth-child(1) {
    font-weight: 900;
}

.entryList .tr td:nth-child(2) {
    font-size: 20px;
    font-style: italic;
    font-weight: 900;
    color: #666666;
}
</style>
