<template>
    <div>
        <el-tabs :value="activeTab">
            <el-tab-pane name="players" label="Players">
                <div class="flex-row mt-16 mb-32">
                    <h2>Players</h2>
                    <span class="spacer"></span>

                    <SelectNationality v-model="nationality" :lookup="nationalities" :clearable="true" />
                    <el-input
                        placeholder="Filter by Id or Name"
                        v-model="options.queryString"
                        class="input-search mr-16"
                        @change="resetPage"
                        @keydown.esc.native="reset"
                    >
                        <el-button slot="append" icon="el-icon-search" @click="resetPage"></el-button>
                    </el-input>

                    <el-button v-if="!isBaselineReader" @click="addPlayer" plain type="primary"> Add player</el-button>
                    <el-button @click="exportCsvClick" plain class="ml-8" size="mini">Export CSV</el-button>
                </div>

                <ErrorDisplay :requestError="this.loadingError" @closed="clearError" />
                <div v-loading="loadingPlayers">
                    <DataTable
                        :items="players.data"
                        :schema="schema"
                        :fields="['id', 'singlesRank', 'doublesRank', 'firstName', 'lastName', 'nationality', 'memberType', 'playerAge', 'dateOfBirth']"
                        :sortField="options.sortBy"
                        :sortDir="options.sortDir"
                        @item-clicked="selectPlayer"
                        @header-clicked="setSort"
                        :autoSort="false"
                    />

                    <Paginator
                        v-if="players.data"
                        :data="players"
                        :elementsPerPage="options.pageSize"
                        @prev-page="prevPage"
                        @next-page="nextPage"
                        @set-page-size="setPageSize"
                    />
                </div>
            </el-tab-pane>
            <el-tab-pane name="player-supporters" label="Player Supporters">
                <PlayerSupporterIndex />
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { Player } from '@/models/player';
import moment from 'moment';
import FileSaver from 'file-saver';
import { retrieveDtos } from '@/utils/crud-confirm';
import { icoCountries } from '@/utils/country-codes';
import PlayerSupporterIndex from '@/components/PlayerSupporterIndex.vue';
import { isBaselineReader } from '../services/authGroups';

export default {
    components: { PlayerSupporterIndex },
    data() {
        return {
            schema: Player.schema,
            nationality: null,
            nationalities: Object.keys(icoCountries),
            activeTab: this.$route.query.activeTab || 'players',
            isBaselineReader: isBaselineReader(),
        };
    },
    mounted() {
        this.search();
    },
    computed: mapGetters('players', ['players', 'loadingPlayers', 'options', 'loadingError']),
    methods: {
        exportCsvClick() {
            this.exportCsv(this.options)
                .then((result) => {
                    const file = new File([result], `players_${moment().format('DD_MM_YYYY HH_mm_SS')}.csv`, { type: 'text/csv' });
                    FileSaver.saveAs(file);
                })
                .catch((error) => {
                    this.$notify({
                        title: 'CSV export',
                        message: 'CSV export failed',
                        type: 'error',
                        duration: 5000,
                    });
                });
        },
        ...mapActions('players', ['findPlayers', 'exportCsv']),
        selectPlayer(row) {
            this.$router.push(`/players/${row.id}`);
        },
        addPlayer() {
            this.$router.push('/players/add');
        },
        search() {
            let params = {
                self: this,
                fRetrieve: this.findPlayers,
                options: this.options,
                dtosName: 'Players',
            };
            this.options.nationality = this.nationality;
            retrieveDtos(params);
        },
        resetSearch() {
            this.options.queryString = null;
            this.search();
        },
        setPageSize(size) {
            this.options.pageSize = size;
            this.search();
        },
        resetPage() {
            this.options.page = 0;
            this.search();
        },
        reset() {
            this.options.queryString = null;
            this.options.page = 0;
            this.search();
        },
        setSort(event) {
            this.options.sortBy = event.property;
            this.options.sortDir = event.order;
            this.search();
        },
        prevPage() {
            this.options.page--;
            this.search();
        },
        nextPage() {
            this.options.page++;
            this.search();
        },
        clearError() {
            this.loadingError = null;
        },
    },
};
</script>

<style scoped>
.input-search {
    max-width: 512px;
}
</style>
