<template>
    <div>
        <h2>Profile</h2>
        <p>
            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Obcaecati placeat omnis molestiae! Autem odio fugit minus? Rem dolorum, quibusdam modi,
            sunt fugit labore, minus non harum ipsa nostrum quisquam impedit?
        </p>
        <p>
            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Obcaecati placeat omnis molestiae! Autem odio fugit minus? Rem dolorum, quibusdam modi,
            sunt fugit labore, minus non harum ipsa nostrum quisquam impedit?
        </p>
    </div>
</template>

<script>
export default {};
</script>
