import axios from 'axios';

export default {
    namespaced: true,

    state: {
        playerChangeRequests: [],
        loadingPlayerChangeRequests: false,
        loadingError: null,
        options: {
            queryString: null,
            sortBy: null,
            sortDir: -1,
            page: 0,
            pageSize: process.env.VUE_APP_PAGE_SIZE,
            statuses: '1',
            fromDate: null,
            status: null,
            requestDateFrom: null,
            requestDateTo: null,
        }
    },

    getters: {
        playerChangeRequests: (state) => state.playerChangeRequests,
        loadingPlayerChangeRequests: (state) => state.loadingPlayerChangeRequests,
        loadingError: (state) => state.loadingError,
        options: (state) => state.options,
    },

    mutations: {
        setPlayerChangeRequests(state, data) {
            state.playerChangeRequests = data;
        },

        startLoadingPlayerChangeRequests(state) {
            state.loadingPlayerChangeRequests = true;
        },

        stopLoadingPlayerChangeRequests(state) {
            state.loadingPlayerChangeRequests = false;
        },

        setLoadingError(state, error) {
            state.loadingError = error;
        },

        clearLoadingError(state) {
            state.loadingError = null;
        },

        clearPlayerChangeRequests(state) {
            state.playerChangeRequests = [];
        },
    },

    actions: {
        async fetchPlayerChangeRequests({ commit }, { options = {} }) {
            commit('startLoadingPlayerChangeRequests');
            commit('clearLoadingError');
            console.log('fetchPlayerChangeRequests', { options });
            try {
                const response = await axios.get(`${process.env.VUE_APP_API_URL}/playerprofilestaging/request`, {
                    params: {
                        queryString: options.queryString,
                        sortBy: options.sortBy || '',
                        sortDir: options.sortDir, //options.sortDir === 'descending' ? -1 : 1,
                        page: options.page,
                        pageSize: options.pageSize,
                        status: options.statuses,
                        requestDateFrom: options.requestDateFrom,
                        requestDateTo: options.requestDateTo,
                    }
                });
                commit('setPlayerChangeRequests', response.data);
            } catch (error) {
                commit('setLoadingError', error);
                commit('stopLoadingPlayerChangeRequests');
            } finally {
                commit('stopLoadingPlayerChangeRequests');
            }
        },

        async approvePlayerChangeRequest({ commit }, { id, playerNbr }) {
            commit('startLoadingPlayerChangeRequests');
            commit('clearLoadingError');
            try {
                await axios.put(`${process.env.VUE_APP_API_URL}/playerprofilestaging/${playerNbr}/request/${id}/approve`);
            } catch (error) {
                commit('setLoadingError', error);
                commit('stopLoadingPlayerChangeRequests');
            } finally {
                commit('stopLoadingPlayerChangeRequests');
            }
        },

        async rejectPlayerChangeRequest({ commit }, { id, playerNbr, reasonToReject }) {
            commit('startLoadingPlayerChangeRequests');
            commit('clearLoadingError');
            try {
                await axios.put(`${process.env.VUE_APP_API_URL}/playerprofilestaging/${playerNbr}/request/${id}/reject`, { comments: reasonToReject.value } );
            } catch (error) {
                commit('setLoadingError', error);
                commit('stopLoadingPlayerChangeRequests');
            } finally {
                commit('stopLoadingPlayerChangeRequests');
            }
        }
    }
}