import Vue from 'vue';

import ElementUI from 'element-ui';
import locale from 'element-ui/lib/locale/lang/en';
import 'element-ui/lib/theme-chalk/index.css';

import vueMoment from 'vue-moment';

import CountryFlag from 'vue-country-flag';
import browserDetect from 'vue-browser-detect-plugin';

import App from './App.vue';
import router from './router';
import store from './store';
import axios from 'axios';

import './messaging-hub';

import PlayerBonuses from './components/PlayerBonuses.vue';
import PlayerBioDet from './components/PlayerBioDet.vue';
import PlayerDevelopment from './components/player-development/PlayerDevelopment.vue';
import PlayerSupportAgent from './components/player-development/PlayerSupportAgent.vue';
import PlayerSupportCoach from './components/player-development/PlayerSupportCoach.vue';
import PlayerSupportParent from './components/player-development/PlayerSupportParent.vue';
import PlayersPlayerPayments from './components/PlayersPlayerPayments.vue';
import PlayerPaymentTable from './widgets/PlayerPaymentTable.vue';
import TournamentInfoGeneral from './widgets/TournamentInfoGeneral';
import PreliminaryList from './components/PreliminaryList.vue';
import TournamentResults from './components/TournamentResults.vue';
import TournamentRounds from './components/TournamentRounds.vue';
import TournamentRawEntries from './components/TournamentRawEntries.vue';
import TournamentSinglesEntries from './components/TournamentSinglesEntries.vue';
import TournamentDoublesEntries from './components/TournamentDoublesEntries.vue';
import PlayerPayments from './components/TournamentsPlayerPayments.vue';
import MixedDoubles from './components/MixedDoubles.vue';
import RoundImport from './widgets/RoundImport.vue';
import RoundImportSta from './widgets/RoundImportSta.vue';
import RoundImportXml from './widgets/RoundImportXml.vue';
import CsvExporter from './components/CsvExporter.vue';
import MessagingRecipients from './components/MessagingRecipients.vue';

import SinglesRankings from './components/ranking-current/SinglesRankings.vue';
import DoublesRankings from './components/ranking-current/DoublesRankings.vue';
import ChampSinglesRankings from './components/ranking-current/ChampSinglesRankings.vue';
import TeamPoints from './components/ranking-current/TeamPoints.vue';
import RaceSinglesWtaAndGsRankings from './components/ranking-current/RaceSinglesWtaAndGsRankings.vue';

import RankingHistorySingles from './components/ranking-history/RankingHistorySingles.vue';
import RankingHistoryDoubles from './components/ranking-history/RankingHistoryDoubles.vue';
import RankingHistoryChampSingles from './components/ranking-history/RankingHistoryChampSingles.vue';
import RankingHistoryTeamPoints from './components/ranking-history/RankingHistoryTeamPoints.vue';
import RankingHistoryRaceSinglesWtaAndGs from './components/ranking-history/RankingHistoryRaceSinglesWtaAndGs.vue';

import RankingSummaries from './components/ranking-summaries/RankingSummaries.vue';
import RankingSummarySingles from './components/ranking-summaries/RankingSummarySingles.vue';
import RankingSummaryDoubles from './components/ranking-summaries/RankingSummaryDoubles.vue';
import RankingSummaryChampSingles from './components/ranking-summaries/RankingSummaryChampSingles.vue';
import RankingSummaryChampTeams from './components/ranking-summaries/RankingSummaryChampTeams.vue';
import MostRecentAdded from './components/ranking-summaries/MostRecentAdded.vue';
import NextToGoOn from './components/ranking-summaries/NextToGoOn.vue';
import NextToDropOff from './components/ranking-summaries/NextToDropOff.vue';

import ShadowRankings from './components/shadow-ranking/ShadowRankings.vue';
import ShadowRankingPoints from './components/shadow-ranking/ShadowRankingPoints.vue';

import ShadowSinglesRankings from './components/shadow-ranking-current/ShadowSinglesRankings.vue';
import ShadowDoublesRankings from './components/shadow-ranking-current/ShadowDoublesRankings.vue';
import ShadowChampSinglesRankings from './components/shadow-ranking-current/ShadowChampSinglesRankings.vue';
import ShadowTeamPoints from './components/shadow-ranking-current/ShadowTeamPoints.vue';

import ShadowRankingHistorySingles from './components/shadow-ranking-history/ShadowRankingHistorySingles.vue';
import ShadowRankingHistoryDoubles from './components/shadow-ranking-history/ShadowRankingHistoryDoubles.vue';
import ShadowRankingHistoryChampSingles from './components/shadow-ranking-history/ShadowRankingHistoryChampSingles.vue';
import ShadowRankingHistoryTeamPoints from './components/shadow-ranking-history/ShadowRankingHistoryTeamPoints.vue';

import ShadowRankingSummarySingles from './components/shadow-ranking-summaries/ShadowRankingSummarySingles.vue';
import ShadowRankingSummaryDoubles from './components/shadow-ranking-summaries/ShadowRankingSummaryDoubles.vue';
import ShadowRankingSummaryChampSingles from './components/shadow-ranking-summaries/ShadowRankingSummaryChampSingles.vue';
import ShadowRankingSummaryChampTeams from './components/shadow-ranking-summaries/ShadowRankingSummaryChampTeams.vue';

import Procedures from './components/Procedures';
import Procedure from './components/Procedure';
import PlayerDevelopmentProcedures from './components/player-development/PlayerDevelopmentProcedures';
import OutstandingRequirements from './components/player-development/OutstandingRequirements';
import CompletedRequirements from './components/player-development/CompletedRequirements';
import EligibleDates from './components/player-development/EligibleDates';
import TournamentResultsForPlayerDevelopment from './components/player-development/TournamentResultsForPlayerDevelopment';
import AgeEligibilityReports from './components/age-eligibility/AgeEligibilityReports';

import SelectEditionAndListSection from './widgets/SelectEdition';
import SelectModeAndRound from './widgets/SelectModeAndRound';
import DataTable from './widgets/DataTable.vue';
import FormGroup from './widgets/FormGroup.vue';
import FormControl from './widgets/FormControl.vue';
import SaveChanges from './widgets/SaveChanges.vue';
import SelectNationality from './widgets/SelectNationality.vue';
import SelectPlayer from './widgets/SelectPlayer.vue';
import SelectPlayer2 from './widgets/SelectPlayer2.vue';
import SelectPlayerSupport from './widgets/SelectPlayerSupport.vue';
import Paginator from './widgets/Paginator.vue';
import DialogAddItem from './widgets/DialogAddItem.vue';
import DialogEditItem from './widgets/DialogEditItem.vue';
import ErrorDisplay from './widgets/ErrorDisplay.vue';
import DetailedInformation from './widgets/DetailedInformation.vue';
import RankingSummaryPointTable from './widgets/RankingSummaryPointTable.vue';
import RankingSummaryTieBreakingPoints from './widgets/RankingSummaryTieBreakingPoints.vue';
import RankingSummaryChampionshipTieBreakingPoints from './widgets/RankingSummaryChampionshipTieBreakingPoints.vue';
import WtaPlayerZoneAdmin from './components/WtaPlayerZoneAdmin.vue';
import CoachIndex from './components/pz-access/CoachIndex.vue';
import AgentIndex from './components/pz-access/AgentIndex.vue';
import ItfPlayerLink from './components/ItfPlayerLink.vue';
import './services/authInit';
import './style.scss';

Vue.config.productionTip = false;

Vue.use(ElementUI, { size: 'small', locale });
Vue.use(vueMoment);
Vue.use(browserDetect);

Vue.component('PlayerBonuses', PlayerBonuses);
Vue.component('PlayerBioDet', PlayerBioDet);
Vue.component('PlayerDevelopment', PlayerDevelopment);
Vue.component('PlayerSupportAgent', PlayerSupportAgent);
Vue.component('PlayerSupportCoach', PlayerSupportCoach);
Vue.component('PlayerSupportParent', PlayerSupportParent);
Vue.component('PlayersPlayerPayments', PlayersPlayerPayments);
Vue.component('PlayerPaymentTable', PlayerPaymentTable);
Vue.component('country-flag', CountryFlag);
Vue.component('DataTable', DataTable);
Vue.component('FormGroup', FormGroup);
Vue.component('FormControl', FormControl);
Vue.component('ErrorDisplay', ErrorDisplay);
Vue.component('DialogAddItem', DialogAddItem);
Vue.component('DialogEditItem', DialogEditItem);
Vue.component('DetailedInformation', DetailedInformation);
Vue.component('TournamentSinglesEntries', TournamentSinglesEntries);
Vue.component('TournamentDoublesEntries', TournamentDoublesEntries);
Vue.component('TournamentResults', TournamentResults);
Vue.component('TournamentRounds', TournamentRounds);
Vue.component('PlayerPayments', PlayerPayments);
Vue.component('MixedDoubles', MixedDoubles);
Vue.component('SaveChanges', SaveChanges);
Vue.component('SelectNationality', SelectNationality);
Vue.component('SelectPlayer', SelectPlayer);
Vue.component('SelectPlayer2', SelectPlayer2);
Vue.component('SelectPlayerSupport', SelectPlayerSupport);
Vue.component('ShadowRankings', ShadowRankings);
Vue.component('ShadowRankingPoints', ShadowRankingPoints);
Vue.component('ShadowRankingSummarySingles', ShadowRankingSummarySingles);
Vue.component('ShadowRankingSummaryDoubles', ShadowRankingSummaryDoubles);
Vue.component('ShadowRankingSummaryChampSingles', ShadowRankingSummaryChampSingles);
Vue.component('ShadowRankingSummaryChampTeams', ShadowRankingSummaryChampTeams);
Vue.component('ShadowRankingHistorySingles', ShadowRankingHistorySingles);
Vue.component('ShadowRankingHistoryDoubles', ShadowRankingHistoryDoubles);
Vue.component('ShadowRankingHistoryChampSingles', ShadowRankingHistoryChampSingles);
Vue.component('ShadowRankingHistoryTeamPoints', ShadowRankingHistoryTeamPoints);
Vue.component('RoundImport', RoundImport);
Vue.component('RoundImportSta', RoundImportSta);
Vue.component('RoundImportXml', RoundImportXml);
Vue.component('Paginator', Paginator);
Vue.component('PreliminaryList', PreliminaryList);
Vue.component('TournamentRawEntries', TournamentRawEntries);
Vue.component('ShadowDoublesRankings', ShadowDoublesRankings);
Vue.component('ShadowSinglesRankings', ShadowSinglesRankings);
Vue.component('ShadowChampSinglesRankings', ShadowChampSinglesRankings);
Vue.component('ShadowTeamPoints', ShadowTeamPoints);
Vue.component('DoublesRankings', DoublesRankings);
Vue.component('SinglesRankings', SinglesRankings);
Vue.component('ChampSinglesRankings', ChampSinglesRankings);
Vue.component('TeamPoints', TeamPoints);
Vue.component('RankingSummaries', RankingSummaries);
Vue.component('RankingSummarySingles', RankingSummarySingles);
Vue.component('RankingSummaryDoubles', RankingSummaryDoubles);
Vue.component('RankingSummaryChampSingles', RankingSummaryChampSingles);
Vue.component('RankingSummaryChampTeams', RankingSummaryChampTeams);
Vue.component('MostRecentAdded', MostRecentAdded);
Vue.component('NextToGoOn', NextToGoOn);
Vue.component('NextToDropOff', NextToDropOff);
Vue.component('Procedures', Procedures);
Vue.component('Procedure', Procedure);
Vue.component('PlayerDevelopmentProcedures', PlayerDevelopmentProcedures);
Vue.component('OutstandingRequirements', OutstandingRequirements);
Vue.component('CompletedRequirements', CompletedRequirements);
Vue.component('EligibleDates', EligibleDates);
Vue.component('AgeEligibilityReports', AgeEligibilityReports);
Vue.component('TournamentResultsForPlayerDevelopment', TournamentResultsForPlayerDevelopment);
Vue.component('SelectEdition', SelectEditionAndListSection);
Vue.component('SelectModeAndRound', SelectModeAndRound);
Vue.component('TournamentInfoGeneral', TournamentInfoGeneral);
Vue.component('RankingHistorySingles', RankingHistorySingles);
Vue.component('RankingHistoryDoubles', RankingHistoryDoubles);
Vue.component('RankingHistoryChampSingles', RankingHistoryChampSingles);
Vue.component('RankingHistoryTeamPoints', RankingHistoryTeamPoints);
Vue.component('RankingSummaryPointTable', RankingSummaryPointTable);
Vue.component('RankingSummaryTieBreakingPoints', RankingSummaryTieBreakingPoints);
Vue.component('RankingSummaryChampionshipTieBreakingPoints', RankingSummaryChampionshipTieBreakingPoints);
Vue.component('CsvExporter', CsvExporter);
Vue.component('WtaPlayerZoneAdmin', WtaPlayerZoneAdmin);
Vue.component('AgentIndex', AgentIndex);
Vue.component('CoachIndex', CoachIndex);
Vue.component('PlayerChangeRequests', () => import('./components/player-change-requests/PlayersChangeRequests.vue'));
Vue.component('ItfPlayerLink', ItfPlayerLink);
Vue.component('MessagingRecipients', MessagingRecipients);

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount('#app');

// eslint-disable-next-line no-console
console.log('Using environment: ', process.env.NODE_ENV);

// eslint-disable-next-line no-console
console.log('Using API URL: ', process.env.VUE_APP_API_URL);
