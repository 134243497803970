<template>
    <div>
        <el-select
            :value="value"
            filterable
            remote
            reserve-keyword
            placeholder="Search by name or ID"
            :remote-method="remoteMethod"
            :loading="loading"
            :disabled="disabled"
            :clearable="clearable"
            @clear="onClear"
            @change="onChange"
            @focus="onFocus"
        >
            <el-option v-for="(item, i) in options" :key="i" :label="item.fullName" :value="item.contactId"> </el-option>
        </el-select>
        <span style="color: #cecece; padding-left: 5px">{{ value }}</span>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    props: ['value', 'disabled', 'clearable'],
    data() {
        return {
            options: [],
            loading: false,
        };
    },
    mounted() {
        if (this.value) {
            this.remoteMethod(this.value);
        }
    },
    methods: {
        onChange(item) {
            let p = this.options.find((p) => p.contactId === item);
            this.$emit('support-selected', p);
            this.$emit('input', item);
            this.$emit('change', item);
        },
        remoteMethod(queryString) {
            if (typeof queryString === 'string' && queryString.length > 0) {
                this.loading = true;
                axios
                    .get(`${process.env.VUE_APP_API_URL}/Contacts/Find?recordType=PS&queryString=${queryString || ''}`)
                    .then((result) => {
                        this.options = result.data.data;
                        this.loading = false;
                    })
                    .catch((error) => {
                        this.loading = false;
                    });
            } else {
                this.options = [];
            }
        },
        onClear() {
            this.$emit('clear');
        },
        onFocus() {
            this.options = [];
        },
    },
};
</script>

<style>
</style>
