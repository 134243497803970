import axios from 'axios';
import { SinglesEntry } from '../models/singles-entry';
import flatten from 'flat';

export default {
    namespaced: true,

    state: {
        singlesEntries: [],
        loadingSinglesEntries: false,
        loadingError: null,
        savingSinglesEntry: false,
        savingError: null,
        deletingSinglesEntry: false,
        deletingError: null,
    },

    getters: {
        singlesEntries: (state) => state.singlesEntries,
        loadingSinglesEntries: (state) => state.loadingSinglesEntry,
        loadingError: (state) => state.loadingError,
        savingSinglesEntry: (state) => state.savingSinglesEntry,
        savingError: (state) => state.savingError,
        deletingSinglesEntry: (state) => state.deletingSinglesEntry,
        deletingError: (state) => state.deletingError,
    },

    mutations: {
        setSinglesEntries(state, data) {
            state.singlesEntries = data;
        },

        startLoadingSinglesEntries(state) {
            state.loadingSinglesEntries = true;
        },

        stopLoadingSinglesEntries(state) {
            state.loadingSinglesEntries = false;
        },

        setLoadingError(state, error) {
            state.loadingError = error;
        },

        clearLoadingError(state) {
            state.loadingError = null;
        },

        startSavingSinglesEntry(state) {
            state.savingSinglesEntry = true;
        },

        stopSavingSinglesEntry(state) {
            state.savingSinglesEntry = false;
        },

        setSavingError(state, error) {
            state.savingError = error;
        },

        clearSavingError(state) {
            state.savingError = null;
        },

        startDeletingSinglesEntry(state) {
            state.deletingSinglesEntry = true;
        },

        stopDeletingSinglesEntry(state) {
            state.deletingSinglesEntry = false;
        },

        setDeletingError(state, error) {
            state.deletingError = error;
        },

        clearDeletingError(state) {
            state.deletingError = null;
        },
    },

    actions: {
        async findSinglesEntries(context, tournament) {
            context.commit('startLoadingSinglesEntries');
            try {
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/Edition/${tournament.year}/${tournament.id}/Entry/Singles`);
                context.commit('stopLoadingSinglesEntries');
                context.commit('setSinglesEntries', result.data);
                return result.data;
            } catch (error) {
                context.commit('stopLoadingSinglesEntries');
                context.commit('setLoadingError', error);
                return null;
            }
        },

        async createSinglesEntry(context, data) {
            context.commit('startSavingSinglesEntry');
            try {
                const result = await axios.post(
                    `${process.env.VUE_APP_API_URL}/Edition/${data.data.season}/${data.data.tournamentId}/Entry/Singles`,
                    data.data,
                );
                context.commit('stopSavingSinglesEntry');
                // context.commit('setSinglesEntries', new SinglesEntry(flatten(result.data)));
                return result.data;
            } catch (error) {
                context.commit('stopSavingSinglesEntry');
                context.commit('setSavingError', error);
                return null;
            }
        },

        async updateSinglesEntry(context, data) {
            context.commit('startSavingSinglesEntry');
            try {
                const result = await axios.put(`${process.env.VUE_APP_API_URL}/Edition/${data.data.season}/${data.data.tournamentId}/Entry/Singles`, {
                    oldEntry: data.original,
                    newEntry: data.data,
                });
                context.commit('stopSavingSinglesEntry');
                // context.commit('setSinglesEntry', new SinglesEntry(flatten(result.data)));
                return result.data;
            } catch (error) {
                context.commit('stopSavingSinglesEntry');
                context.commit('setSavingError', error);
                return null;
            }
        },

        async withdrawSinglesEntry(context, data) {
            context.commit('startSavingSinglesEntry');
            try {
                const result = await axios.put(`${process.env.VUE_APP_API_URL}/Edition/${data.data.season}/${data.data.tournamentId}/Entry/Singles/Withdraw`, {
                    oldEntry: data.original,
                    newEntry: data.data,
                    playerToBeEnteredFromAlternates: data.playerFromAlternates,
                });
                context.commit('stopSavingSinglesEntry');
                await context.dispatch('findSinglesEntries', { id: data.data.tournamentId, year: data.data.season });
                return result.data;
            } catch (error) {
                context.commit('stopSavingSinglesEntry');
                context.commit('setSavingError', error);
                return null;
            }
        },

        async deleteSinglesEntry(context, data) {
            context.commit('startDeletingSinglesEntry');
            try {
                const result = await axios.post(
                    `${process.env.VUE_APP_API_URL}/Edition/${data.data.season}/${data.data.tournamentId}/Entry/Singles/Delete`,
                    data.data,
                );
                context.commit('stopDeletingSinglesEntry');
                // context.commit('setSinglesEntries', new SinglesEntry(flatten(result.data)));
                return result.data;
            } catch (error) {
                context.commit('stopDeletingSinglesEntry');
                context.commit('setDeletingError', error);
                return null;
            }
        },
    },
};
