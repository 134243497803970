import { isValid, Model } from './model';

export class PzMembershipSubscription extends Model {
    constructor(data) {
        if (data && !data.currency) {
            data.currency = 'USD';
        }
        super(data);
    }
    isValid() {
        return isValid(PzMembershipSubscription.schema, this);
    }
    isValidForAdd() {
        return isValid(PzMembershipSubscription.schema, this);
    }
    isValidForEdit() {
        return isValid(PzMembershipSubscription.schema, this);
    }
}

PzMembershipSubscription.layout = {
    children: [
        {
            children: ['role', 'code', 'name', 'fee', 'insurance', 'disabilityInsurance'],
        },
        {
            children: ['description'],
        },
    ],
};

PzMembershipSubscription.schema = {
    fields: {
        role: {
            displayName: 'Role', type: 'string', required: true, maxLength: 1, lookup: [
                { id: 'P', displayName: 'Player' },
                { id: 'N', displayName: 'New Coach' },
                { id: 'A', displayName: 'A' },
                { id: 'C', displayName: 'Coach' },
                { id: 'W', displayName: 'W' },
                { id: 'T', displayName: 'T' },
            ],
        },
        code: { displayName: 'Code', type: 'string', required: true, maxLength: 1, },
        name: { displayName: 'Name', type: 'string', required: true, maxLength: 63, },
        description: { displayName: 'Description', type: 'multiline', required: true, maxLength: 1023, },
        fee: { displayName: 'Fee', type: 'currency', required: true, min: 0, max: 9999999},
        insurance: { displayName: 'Insurance', type: 'currency', clearable: true, min: 0, max: 9999999 },
        currency: { type: 'string', displayName: 'Currency', readonly: true, },
        disabilityInsurance: { displayName: 'Disability', type: 'currency', clearable: true, min: 0, max: 9999999 },
    },
};
