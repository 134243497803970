import axios from 'axios';
import { buildParams } from '@/utils/query-param-builder';

export default {
    namespaced: true,

    state: {
        historicChampSinglesShadowRankings: {},
        loadingHistoricChampSinglesShadowRankings: false,
        loadingErrorHistoricChampSinglesShadowRankings: null,
    },

    getters: {
        historicChampSinglesShadowRankings: (state) => state.historicChampSinglesShadowRankings,
        loadingHistoricChampSinglesShadowRankings: (state) => state.loadingHistoricChampSinglesShadowRankings,
        loadingErrorHistoricChampSinglesShadowRankings: (state) => state.loadingErrorHistoricChampSinglesShadowRankings,
    },

    mutations: {
        setHistoricChampSinglesShadowRankings(state, data) {
            state.historicChampSinglesShadowRankings = data;
        },

        startLoadingHistoricChampSinglesShadowRankings(state) {
            state.loadingHistoricChampSinglesShadowRankings = true;
        },

        stopLoadingHistoricChampSinglesShadowRankings(state) {
            state.loadingHistoricChampSinglesShadowRankings = false;
        },

        setLoadingErrorHistoricChampSinglesShadowRankings(state, error) {
            state.loadingErrorHistoricChampSinglesShadowRankings = error;
        },

        clearLoadingErrorHistoricChampSinglesShadowRankings(state) {
            state.loadingErrorHistoricChampSinglesShadowRankings = null;
        },
    },

    actions: {
        async findHistoricChampSinglesShadowRankings(context, options) {
            context.commit('startLoadingHistoricChampSinglesShadowRankings');
            context.commit('clearLoadingErrorHistoricChampSinglesShadowRankings');
            let date = options.rankingDate;
            let params = buildParams(options);
            try {
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/ShadowRankingHistory/${date}/ChampionshipSingles`, { params });
                context.commit('setHistoricChampSinglesShadowRankings', result.data);
                context.commit('stopLoadingHistoricChampSinglesShadowRankings');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('setLoadingErrorHistoricChampSinglesShadowRankings', error);
                context.commit('stopLoadingHistoricChampSinglesShadowRankings');
                return Promise.reject(error);
            }
        },
    },
};
