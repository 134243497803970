<template>
    <div class="paginator mt-32">
        <p class="pr-32">{{ data.total }} {{ data.total === 1 ? 'element' : 'elements' }} found</p>
        <div class="spacer"></div>
        <p class="mr-32">Elements per page</p>
        <el-select :value="elementsPerPage" @change="setPageSize" size="mini" style="width: 96px" class="mr-32">
            <el-option v-for="item in elementsPerPageLookup" :key="item" :label="item" :value="item"> </el-option>
        </el-select>
        <div class="flex-row" v-if="data.pagesCount">
            <el-button size="mini" class="mr-16" icon="el-icon-arrow-left" :disabled="!data.hasPrevPage" @click="$emit('prev-page')"></el-button>
            <p>Page {{ data.page + 1 }} of {{ data.pagesCount }}</p>
            <el-button size="mini" class="ml-16" icon="el-icon-arrow-right" :disabled="!data.hasNextPage" @click="$emit('next-page')"></el-button>
        </div>
    </div>
</template>

<script>
export default {
    props: ['data', 'elementsPerPage'],
    data() {
        return {
            elementsPerPageLookup: ['10', '20', '50', '100'],
        };
    },
    methods: {
        setPageSize(size) {
            this.$emit('set-page-size', size);
        },
    },
};
</script>

<style scoped>
.paginator {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #666;
    font-size: 13px;
    margin-right: 32px;
}
</style>
