import axios from 'axios';
import { buildParams } from '../utils/query-param-builder';

export default {
    namespaced: true,

    state: {
        dropDates: [],
        loadingDropDates: false,
        loadingError: null,
        dropDatesOptions: {
            queryString: null,
            sortBy: null,
            sortDir: 'ascending',
            page: 0,
            pageSize: process.env.VUE_APP_PAGE_SIZE,
        },
        selectedPlayerFullName: null,
    },

    getters: {
        dropDates: (state) => state.dropDates,
        loadingDropDates: (state) => state.loadingDropDates,
        loadingError: (state) => state.loadingError,
        dropDatesOptions: (state) => state.dropDatesOptions,
        selectedPlayerFullName: (state) => state.selectedPlayerFullName,
    },

    mutations: {
        setDropDates(state, data) {
            state.dropDates = data;
        },

        startLoadingDropDates(state) {
            state.loadingDropDates = true;
        },

        stopLoadingDropDates(state) {
            state.loadingDropDates = false;
        },

        setLoadingError(state, error) {
            state.loadingError = error;
        },

        clearLoadingError(state) {
            state.loadingError = null;
        },

        setPlayerFullName(state, data) {
            state.selectedPlayerFullName = data;
        },
    },

    actions: {
        async findDropDates(context, options) {
            context.commit('startLoadingDropDates');
            let params = buildParams(options);
            try {
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/ranking/corona-drop-date`, { params });
                context.commit('setDropDates', result.data);
                context.commit('setLoadingError', null);
                context.commit('stopLoadingDropDates');
                return Promise.resolve(true);
            } catch (error) {
                context.commit('stopLoadingDropDates');
                context.commit('setDropDates', []);
                context.commit('setLoadingError', error);
                return Promise.reject(error);
            }
        },
        async createDropDate(context, data) {
            context.commit('startLoadingDropDates');
            try {
                const result = await axios.post(`${process.env.VUE_APP_API_URL}/ranking/corona-drop-date`, data.data);
                context.commit('stopLoadingDropDates');
                context.commit('setLoadingError', null);
                return Promise.resolve(result);
            } catch (error) {
                context.commit('stopLoadingDropDates');
                context.commit('setLoadingError', error);
                return Promise.reject(error);
            }
        },
        async updateDropDate(context, data) {
            context.commit('startLoadingDropDates');
            try {
                const result = await axios.put(`${process.env.VUE_APP_API_URL}/ranking/corona-drop-date`, data.data);
                context.commit('stopLoadingDropDates');
                context.commit('setLoadingError', null);
                return Promise.resolve(result);
            } catch (error) {
                context.commit('stopLoadingDropDates');
                context.commit('setLoadingError', error);
                return Promise.reject(error);
            }
        },
        async deleteDropDate(context, data) {
            let season = data.data.season;
            let tournamentId = data.data.tournamentId;
            let playerId = data.data.playerId;
            let sdFlag = data.data.sdFlag;
            context.commit('startLoadingDropDates');
            try {
                const result = await axios.delete(`${process.env.VUE_APP_API_URL}/ranking/corona-drop-date/${season}/${tournamentId}/${playerId}/${sdFlag}`);
                context.commit('stopLoadingDropDates');
                context.commit('setLoadingError', null);
                return Promise.resolve(result);
            } catch (error) {
                context.commit('stopLoadingDropDates');
                context.commit('setLoadingError', error);
                return Promise.reject(error);
            }
        },
        async checkIfDropDatesTableExists(context) {
            context.commit('startLoadingDropDates');
            try {
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/ranking/corona-drop-date/table`);
                context.commit('stopLoadingDropDates');
                context.commit('setLoadingError', null);
                return Promise.resolve(result);
            } catch (error) {
                context.commit('stopLoadingDropDates');
                context.commit('setLoadingError', error);
                return Promise.reject(error);
            }
        },
        async createDropDatesTable(context) {
            context.commit('startLoadingDropDates');
            try {
                const result = await axios.post(`${process.env.VUE_APP_API_URL}/ranking/corona-drop-date/table`);
                context.commit('stopLoadingDropDates');
                context.commit('setLoadingError', null);
                return Promise.resolve(result);
            } catch (error) {
                context.commit('stopLoadingDropDates');
                context.commit('setLoadingError', error);
                return Promise.reject(error);
            }
        },
        setSelectedPlayerFullName(context, data) {
            context.commit('setPlayerFullName', data);
        },
    },
};
