<template>
    <div>
        <div class="flex-row mt-0 mb-32">
            <h2>
                Shadow Ranking
            </h2>
        </div>

        <el-tabs value="shadowRankings">
            <el-tab-pane label="Shadow Rankings" name="shadowRankings">
                <ShadowRankings/>
            </el-tab-pane>

            <el-tab-pane v-for="(points, key, index) in shadowRankingPoints" :key="key"
                         :label="'Shadow Ranking Points ' + getShadowRankingById(key).name"
                         :name="'shadowRankingPoints_' + key">
                <ShadowRankingPoints :shadowRankId="key"/>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>

import { mapGetters } from "vuex";

export default {
    data() {
        return {};
    },
    mounted() {
    },
    computed: {
        ...mapGetters('shadowRankings', ['shadowRankings']),
        ...mapGetters('shadowRankingPoints', ['shadowRankingPoints']),
    },
    methods: {
        getShadowRankingById(id) {
            return this.shadowRankings.find(ranking => ranking.shadowRankId + '' === id);
        },
    },
};
</script>

<style scoped>
</style>>
   