import axios from 'axios';
import moment from 'moment';
import * as _ from 'lodash';
import { Player } from '../models/player';

export default {
    namespaced: true,

    state: {
        ageEligibilityUpToDate: null,
        ageEligibilityTrackings: [],
        ageEligibilityTracking: [],
        ageEligibilityTournamentTrackings: [],
        ageEligibilityTournamentTrackingPlayer: new Player(),
        ageEligibilityWarningsViolations: [],

        loadingAgeEligibilityUpToDate: false,
        loadingAgeEligibilityTrackings: false,
        loadingAgeEligibilityTournamentTrackings: false,
        loadingAgeEligibilityTournamentTrackingPlayer: false,
        loadingAgeEligibilityWarningsViolations: false,
        loadingPdfAgeEligibilityWarningsViolations: false,
        loadingCsvAgeEligibilityTrackings: false,
        computingAgeEligibilityTrackings: false,

        loadingErrorAgeEligibilityTrackings: null,
        loadingErrorAgeEligibilityTracking: null,
        loadingErrorAgeEligibilityTournamentTrackings: null,
        loadingErrorAgeEligibilityTournamentTrackingPlayer: null,
        loadingErrorAgeEligibilityWarningsViolations: null,

    },

    getters: {
        ageEligibilityUpToDate: (state) => state.ageEligibilityUpToDate,
        ageEligibilityTrackings: (state) => state.ageEligibilityTrackings,
        ageEligibilityTracking: (state) => state.ageEligibilityTracking,
        ageEligibilityTournamentTrackings: (state) => state.ageEligibilityTournamentTrackings,
        ageEligibilityTournamentTrackingPlayer: (state) => state.ageEligibilityTournamentTrackingPlayer,
        ageEligibilityWarningsViolations: (state) => state.ageEligibilityWarningsViolations,

        loadingAgeEligibilityUpToDate: (state) => state.loadingAgeEligibilityUpToDate,
        loadingAgeEligibilityTrackings: (state) => state.loadingAgeEligibilityTrackings,
        loadingAgeEligibilityTracking: (state) => state.loadingAgeEligibilityTracking,
        loadingAgeEligibilityTournamentTrackings: (state) => state.loadingAgeEligibilityTournamentTrackings,
        loadingAgeEligibilityTournamentTrackingPlayer: (state) => state.loadingAgeEligibilityTournamentTrackingPlayer,
        loadingCsvAgeEligibilityTrackings: (state) => state.loadingCsvAgeEligibilityTrackings,
        computingAgeEligibilityTrackings: (state) => state.computingAgeEligibilityTrackings,
        loadingAgeEligibilityWarningsViolations: (state) => state.loadingAgeEligibilityWarningsViolations,
        loadingPdfAgeEligibilityWarningsViolations: (state) => state.loadingPdfAgeEligibilityWarningsViolations,


        loadingErrorAgeEligibilityTrackings: (state) => state.loadingErrorAgeEligibilityTrackings,
        loadingErrorAgeEligibilityTracking: (state) => state.loadingErrorAgeEligibilityTracking,
        loadingErrorAgeEligibilityTournamentTrackings: (state) => state.loadingErrorAgeEligibilityTournamentTrackings,
        loadingErrorAgeEligibilityTournamentTrackingPlayer: (state) => state.loadingErrorAgeEligibilityTournamentTrackingPlayer,
        loadingErrorAgeEligibilityWarningsViolations: (state) => state.loadingErrorAgeEligibilityWarningsViolations,

    },

    mutations: {
        setAgeEligibilityUpToDate(state, data) { state.ageEligibilityUpToDate = data; },
        setAgeEligibilityTrackings(state, data) { state.ageEligibilityTrackings = data; },
        setAgeEligibilityTracking(state, data) { state.ageEligibilityTracking = data; },
        setAgeEligibilityTournamentTrackings(state, data) { state.ageEligibilityTournamentTrackings = data; },
        setAgeEligibilityTournamentTrackingPlayer(state, data) { state.ageEligibilityTournamentTrackingPlayer = data; },
        setAgeEligibilityWarningsViolations(state, data) { state.ageEligibilityWarningsViolations = data; },

        startLoadingAgeEligibilityUpToDate(state) { state.loadingAgeEligibilityUpToDate = true; },
        startLoadingAgeEligibilityTrackings(state) { state.loadingAgeEligibilityTrackings = true; },
        startLoadingAgeEligibilityTracking(state) { state.loadingAgeEligibilityTracking = true; },
        startLoadingAgeEligibilityTournamentTrackings(state) { state.loadingAgeEligibilityTournamentTrackings = true; },
        startLoadingAgeEligibilityTournamentTrackingPlayer(state) { state.loadingAgeEligibilityTournamentTrackingPlayer = true; },
        startLoadingCsvAgeEligibilityTrackings(state) { state.loadingCsvAgeEligibilityTrackings = true; },
        startComputingAgeEligibilityTrackings(state) { state.computingAgeEligibilityTrackings = true; },
        startLoadingAgeEligibilityWarningsViolations(state) { state.loadingAgeEligibilityWarningsViolations = true; },
        startLoadingPdfAgeEligibilityWarningsViolations(state) { state.loadingPdfAgeEligibilityWarningsViolations = true; },

        stopLoadingAgeEligibilityUpToDate(state) { state.loadingAgeEligibilityUpToDate = false; },
        stopLoadingAgeEligibilityTrackings(state) { state.loadingAgeEligibilityTrackings = false; },
        stopLoadingAgeEligibilityTracking(state) { state.loadingAgeEligibilityTracking = false; },
        stopLoadingAgeEligibilityTournamentTrackings(state) { state.loadingAgeEligibilityTournamentTrackings = false; },
        stopLoadingAgeEligibilityTournamentTrackingPlayer(state) { state.loadingAgeEligibilityTournamentTrackingPlayer = false; },
        stopLoadingCsvAgeEligibilityTrackings(state) { state.loadingCsvAgeEligibilityTrackings = false; },
        stopComputingAgeEligibilityTrackings(state) { state.computingAgeEligibilityTrackings = false; },
        stopLoadingAgeEligibilityWarningsViolations(state) { state.loadingAgeEligibilityWarningsViolations = false; },
        stopLoadingPdfAgeEligibilityWarningsViolations(state) { state.loadingPdfAgeEligibilityWarningsViolations = false; },

        setLoadingErrorAgeEligibilityTrackings(state, error) { state.loadingErrorAgeEligibilityTrackings = error; },
        setLoadingErrorAgeEligibilityTracking(state, error) { state.loadingErrorAgeEligibilityTracking = error; },
        setLoadingErrorAgeEligibilityTournamentTrackings(state, error) { state.loadingErrorAgeEligibilityTournamentTrackings = error; },
        setLoadingErrorAgeEligibilityTournamentTrackingPlayer(state, error) { state.loadingErrorAgeEligibilityTournamentTrackings = error; },
        setLoadingErrorAgeEligibilityWarningsViolations(state, error) { state.loadingErrorAgeEligibilityWarningsViolations = error; },

        clearLoadingErrorAgeEligibilityTrackings(state) { state.loadingErrorAgeEligibilityTrackings = null; },
        clearLoadingErrorAgeEligibilityTracking(state) { state.loadingErrorAgeEligibilityTracking = null; },
        clearLoadingErrorAgeEligibilityTournamentTrackings(state) { state.loadingErrorAgeEligibilityTournamentTrackings = null; },
        clearLoadingErrorAgeEligibilityTournamentTrackingPlayer(state) { state.loadingErrorAgeEligibilityTournamentTrackings = null; },
        clearLoadingErrorAgeEligibilityWarningsViolations(state) { state.loadingErrorAgeEligibilityWarningsViolations = null; },


    },

    actions: {
        async findAgeEligibilityTrackings(context, options = {}) {
            context.commit('startLoadingAgeEligibilityTrackings');
            context.commit('clearLoadingErrorAgeEligibilityTrackings');
            try {
                var paramOptions = {
                    page: options.page,
                    pageSize: options.pageSize,
                    queryString: options.queryString,
                    recordType: options.recordType,
                    ageAt: options.ageAt,
                    rankAt: options.rankAt,
                    nationality: options.nationality,
                    ppStatus: options.ppStatus,
                    currentPhase: options.currentPhase,
                    playerAge: options.playerAge
                }
                if (options.sortBy) {
                    paramOptions.sortBy = _.upperFirst(options.sortBy);
                    paramOptions.sortDir = options.sortDir === 'ascending' ? '1' : '-1';
                }
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/AgeEligibility/Tracking`, {
                    params: paramOptions
                });
                context.commit('setAgeEligibilityTrackings', result.data);
                context.commit('stopLoadingAgeEligibilityTrackings');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('setLoadingErrorAgeEligibilityTrackings', error);
                context.commit('setAgeEligibilityTrackings', []);
                context.commit('stopLoadingAgeEligibilityTrackings');
                return Promise.reject(error);
            }
        },

        async findAgeEligibilityWarningViolations(context, options = {}) {
            context.commit('startLoadingAgeEligibilityWarningsViolations');
            context.commit('clearLoadingErrorAgeEligibilityWarningsViolations');
            try {
                var paramOptions = {
                    page: options.page,
                    pageSize: options.pageSize,
                    queryString: options.queryString,
                    recordType: options.recordType,
                    ageAt: options.ageAt,
                    rankAt: options.rankAt,
                    aerLimit: options.aerLimit,
                    warningViolation: options.warningViolation,
                }
                if (options.sortBy) {
                    paramOptions.sortBy = _.upperFirst(options.sortBy);
                    paramOptions.sortDir = options.sortDir === 'ascending' ? '1' : '-1';
                }
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/AgeEligibility/Tracking`, {
                    params: paramOptions
                });
                context.commit('setAgeEligibilityWarningsViolations', result.data);
                context.commit('stopLoadingAgeEligibilityWarningsViolations');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('setLoadingErrorAgeEligibilityWarningsViolations', error);
                context.commit('setAgeEligibilityWarningsViolations', []);
                context.commit('stopLoadingAgeEligibilityWarningsViolations');
                return Promise.reject(error);
            }
        },

        async downloadPdfAgeEligibilityWarningViolations(context, options = {}) {
            context.commit('startLoadingPdfAgeEligibilityWarningsViolations');
            context.commit('clearLoadingErrorAgeEligibilityWarningsViolations');
            try {
                var paramOptions = {
                    queryString: options.queryString,
                    recordType: options.recordType,
                    ageAt: options.ageAt,
                    rankAt: options.rankAt,
                    aerLimit: options.aerLimit,
                }
                if (options.sortBy) {
                    paramOptions.sortBy = _.upperFirst(options.sortBy);
                    paramOptions.sortDir = options.sortDir === 'ascending' ? '1' : '-1';
                }
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/AgeEligibility/AerWarningViolationReport`, {
                    params: paramOptions,
                    responseType: 'blob',
                });
                context.commit('stopLoadingPdfAgeEligibilityWarningsViolations');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('setLoadingErrorAgeEligibilityWarningsViolations', error);
                context.commit('stopLoadingPdfAgeEligibilityWarningsViolations');
                return Promise.reject(error);
            }
        },

        async findAgeEligibilityTracking(context, playerNbr) {
            context.commit('startLoadingAgeEligibilityTracking');
            context.commit('clearLoadingErrorAgeEligibilityTracking');
            try {
                let paramOptions = {
                    page: 0,
                    pageSize: 1,
                    queryString: playerNbr + '',
                }
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/AgeEligibility/Tracking`, {
                    params: paramOptions
                });
                context.commit('setAgeEligibilityTracking', result.data);
                context.commit('stopLoadingAgeEligibilityTracking');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('setLoadingErrorAgeEligibilityTracking', error);
                context.commit('setAgeEligibilityTracking', []);
                context.commit('stopLoadingAgeEligibilityTracking');
                return Promise.reject(error);
            }
        },

        async retieveAgeEligibilityUpToDate(context, options = {}) {
            context.commit('startLoadingAgeEligibilityTrackings');
            context.commit('clearLoadingErrorAgeEligibilityTracking');
            try {
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/AgeEligibility/TrackingUpToDate`);
                context.commit('setAgeEligibilityUpToDate', result.data);
                context.commit('stopLoadingAgeEligibilityTrackings');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('setLoadingErrorAgeEligibilityTracking', error);
                context.commit('stopLoadingAgeEligibilityTrackings');
                return Promise.reject(error);
            }
        },

        async downloadCsvEligibilitTracking(context, options = {}) {
            context.commit('startLoadingCsvAgeEligibilityTrackings');
            try {
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/AgeEligibility/Tracking/Export/Csv`, {
                    params: {
                        queryString: options.queryString || '',
                        pageSize: options.pageSize || 50,
                        sortBy: options.sortBy || '',
                        sortDir: options.sortDir === 'descending' ? -1 : 1,
                        page: options.page,
                        nationality: options.nationality,
                        playerAge: options.playerAge,
                        ageAt: options.ageAt,
                    },
                    responseType: 'blob',
                });
                context.commit('stopLoadingCsvAgeEligibilityTrackings');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('stopLoadingCsvAgeEligibilityTrackings');
                return Promise.reject(error);
            }
        },

        async computeAgeEligibilityTrackings(context, ageAt) {
            context.commit('startComputingAgeEligibilityTrackings');
            context.commit('clearLoadingErrorAgeEligibilityTracking');
            try {
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/AgeEligibility/Tracking/Compute/${moment(ageAt).format('YYYY-MM-DD')}`);
                context.commit('stopComputingAgeEligibilityTrackings');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('setLoadingErrorAgeEligibilityTracking', error);
                context.commit('stopComputingAgeEligibilityTrackings');
                return Promise.reject(error);
            }
        },

        async retrieveAgeEligibilityTournamentTrackings(context, playerNbr) {
            context.commit('startLoadingAgeEligibilityTournamentTrackings');
            context.commit('clearLoadingErrorAgeEligibilityTournamentTrackings');
            try {
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/AgeEligibility/TournamentTracking/${playerNbr}`);
                context.commit('setAgeEligibilityTournamentTrackings', result.data);
                context.commit('stopLoadingAgeEligibilityTournamentTrackings');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('setLoadingErrorAgeEligibilityTournamentTrackings', error);
                context.commit('setAgeEligibilityTournamentTrackings', []);
                context.commit('stopLoadingAgeEligibilityTournamentTrackings');
                return Promise.reject(error);
            }
        },

        async retrieveAgeEligibilityTournamentTrackingPlayer(context, options = {}) {
            if (!options.playerNbr) return Promise.reject();
            let playerNbr = options.playerNbr;
            context.commit('startLoadingAgeEligibilityTournamentTrackingPlayer');
            context.commit('clearLoadingErrorAgeEligibilityTournamentTrackingPlayer');
            try {
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/Player/${playerNbr}`, { params: options });
                context.commit('setAgeEligibilityTournamentTrackingPlayer', new Player(result.data));
                context.commit('stopLoadingAgeEligibilityTournamentTrackingPlayer');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('setLoadingErrorAgeEligibilityTournamentTrackingPlayer', error);
                context.commit('setAgeEligibilityTournamentTrackingPlayer', new Player());
                context.commit('stopLoadingAgeEligibilityTournamentTrackingPlayer');
                return Promise.reject(error);
            }
        },
    },
}