import axios from 'axios';
import moment from 'moment';
import * as _ from 'lodash';

export default {
    namespaced: true,

    state: {
        requirementsUpToDate: null,
        completedRequirements: [],
        outstandingRequirements: [],
        overdueRequirements: [],

        loadingRequirementsUpToDate: false,
        loadingCompletedRequirements: false,
        loadingOutstandingRequirements: false,
        loadingOverdueRequirements: false,
        computingRequirementsReports: false,

        loadingErrorRequirementsUpToDate: null,
        loadingErrorCompletedRequirements: null,
        loadingErrorOutstandingRequirements: null,
        loadingErrorOverdueRequirements: null,
        computingErrorRequirementsReports: null,

        loadingPdfCompletedRequirements: false,
        loadingPdfOutstandingRequirements: false,
        loadingPdfOverdueRequirements: false,
    },

    getters: {
        supportRequirementsUpToDate: (state) => state.requirementsUpToDate,
        completedSupportRequirements: (state) => state.completedRequirements,
        outstandingSupportRequirements: (state) => state.outstandingRequirements,
        overdueSupportRequirements: (state) => state.overdueRequirements,

        loadingRequirementsUpToDate: (state) => state.loadingRequirementsUpToDate,
        loadingCompletedSupportRequirements: (state) => state.loadingCompletedRequirements,
        loadingOutstandingSupportRequirements: (state) => state.loadingOutstandingRequirements,
        loadingOverdueSupportRequirements: (state) => state.loadingOverdueRequirements,
        computingRequirementsReports: (state) => state.computingRequirementsReports,

        loadingErrorRequirementsUpToDate: (state) => state.loadingErrorRequirementsUpToDate,
        loadingErrorCompletedSupportRequirements: (state) => state.loadingErrorCompletedRequirements,
        loadingErrorOutstandingSupportRequirements: (state) => state.loadingErrorOutstandingRequirements,
        loadingErrorOverdueSupportRequirements: (state) => state.loadingErrorOverdueRequirements,
        computingErrorRequirementsReports: (state) => state.computingErrorRequirementsReports,

        loadingPdfCompletedSupportRequirements: (state) => state.loadingPdfCompletedRequirements,
        loadingPdfOutstandingSupportRequirements: (state) => state.loadingPdfOutstandingRequirements,
        loadingPdfOverdueSupportRequirements: (state) => state.loadingPdfOverdueRequirements,
    },

    mutations: {
        setRequirementsUpToDate(state, data) { state.requirementsUpToDate = data; },
        setCompletedRequirements(state, data) { state.completedRequirements = data; },
        setOutstandingRequirements(state, data) { state.outstandingRequirements = data; },
        setOverdueRequirements(state, data) { state.overdueRequirements = data; },

        startLoadingRequirementsUpToDate(state) { state.loadingRequirementsUpToDate = true; },
        startLoadingCompletedRequirements(state) { state.loadingCompletedRequirements = true; },
        startLoadingOutstandingRequirements(state) { state.loadingOutstandingRequirements = true; },
        startLoadingOverdueRequirements(state) { state.loadingOverdueRequirements = true; },
        startComputingRequirementsReports(state) { state.computingRequirementsReports = true; },

        stopLoadingRequirementsUpToDate(state) { state.loadingRequirementsUpToDate = false; },
        stopLoadingCompletedRequirements(state) { state.loadingCompletedRequirements = false; },
        stopLoadingOutstandingRequirements(state) { state.loadingOutstandingRequirements = false; },
        stopLoadingOverdueRequirements(state) { state.loadingOverdueRequirements = false; },
        stopComputingRequirementsReports(state) { state.computingRequirementsReports = false; },

        setLoadingErrorRequirementsUpToDate(state, error) { state.loadingErrorRequirementsUpToDate = error; },
        setLoadingErrorCompletedRequirements(state, error) { state.loadingErrorCompletedRequirements = error; },
        setLoadingErrorOutstandingRequirements(state, error) { state.loadingErrorOutstandingRequirements = error; },
        setLoadingErrorOverdueRequirements(state, error) { state.loadingErrorOverdueRequirements = error; },
        setComputingErrorRequirementsReports(state, error) { state.computingErrorRequirementsReports = error; },

        clearLoadingErrorRequirementsUpToDate(state) { state.loadingErrorRequirementsUpToDate = null; },
        clearLoadingErrorCompletedRequirements(state) { state.loadingErrorCompletedRequirements = null; },
        clearLoadingErrorOutstandingRequirements(state) { state.loadingErrorOutstandingRequirements = null; },
        clearLoadingErrorOverdueRequirements(state) { state.loadingErrorOverdueRequirements = null; },
        clearComputingErrorRequirementsReports(state) { state.computingErrorRequirementsReports = null; },

        startLoadingPdfCompletedRequirements(state) { state.loadingPdfCompletedRequirements = true; },
        startLoadingPdfOutstandingRequirements(state) { state.loadingPdfOutstandingRequirements = true; },
        startLoadingPdfOverdueRequirements(state) { state.loadingPdfOverdueRequirements = true; },

        stopLoadingPdfCompletedRequirements(state) { state.loadingPdfCompletedRequirements = false; },
        stopLoadingPdfOutstandingRequirements(state) { state.loadingPdfOutstandingRequirements = false; },
        stopLoadingPdfOverdueRequirements(state) { state.loadingPdfOverdueRequirements = false; },
    },

    actions: {
        async findCompletedSupportRequirements(context, options = {}) {
            context.commit('startLoadingCompletedRequirements');
            context.commit('clearLoadingErrorCompletedRequirements');
            let paramOptions = {
                queryString: options.queryString,
                pageSize: options.pageSize,
                sortBy: options.sortBy ? _.upperFirst(options.sortBy) : null,
                sortDir: options.sortDir === 'ascending' ? '1' : '-1',
                page: options.page,
                nationality: options.nationality,
                ageAt: options.ageAt,
                ppStatus: options.ppStatus,
                currentPhase: options.currentPhase,
                playerAge: options.playerAge,
            };
            try {
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/PlayerSupport/Report/CompletedRequirements`,
                    { params: paramOptions });
                context.commit('setCompletedRequirements', result.data);
                context.commit('stopLoadingCompletedRequirements');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('setCompletedRequirements', []);
                context.commit('setLoadingErrorCompletedRequirements', error);
                context.commit('stopLoadingCompletedRequirements');
                return Promise.reject(error);
            }
        },

        async findOutstandingSupportRequirements(context, options = {}) {
            context.commit('startLoadingOutstandingRequirements');
            context.commit('clearLoadingErrorOutstandingRequirements');
            let paramOptions = {
                queryString: options.queryString,
                pageSize: options.pageSize,
                sortBy: options.sortBy ? _.upperFirst(options.sortBy) : null,
                sortDir: options.sortDir === 'ascending' ? '1' : '-1',
                page: options.page,
                nationality: options.nationality,
                ageAt: options.ageAt,
                ppStatus: options.ppStatus,
                currentPhase: options.currentPhase,
                playerAge: options.playerAge,
            };
            try {
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/PlayerSupport/Report/OutstandingRequirements`,
                    { params: paramOptions });
                context.commit('setOutstandingRequirements', result.data);
                context.commit('stopLoadingOutstandingRequirements');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('setOutstandingRequirements', []);
                context.commit('setLoadingErrorOutstandingRequirements', error);
                context.commit('stopLoadingOutstandingRequirements');
                return Promise.reject(error);
            }
        },

        async findOverdueSupportRequirements(context, options = {}) {
            context.commit('startLoadingOverdueRequirements');
            context.commit('clearLoadingErrorOverdueRequirements');
            let paramOptions = {
                queryString: options.queryString,
                pageSize: options.pageSize,
                sortBy: options.sortBy ? _.upperFirst(options.sortBy) : null,
                sortDir: options.sortDir === 'ascending' ? '1' : '-1',
                page: options.page,
                nationality: options.nationality,
                ageAt: options.ageAt,
                ppStatus: options.ppStatus,
                currentPhase: options.currentPhase,
                playerAge: options.playerAge,
            };
            try {
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/PlayerSupport/Report/OverdueRequirements`,
                    { params: paramOptions });
                context.commit('setOverdueRequirements', result.data);
                context.commit('stopLoadingOverdueRequirements');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('setOverdueRequirements', []);
                context.commit('setLoadingErrorOverdueRequirements', error);
                context.commit('stopLoadingOverdueRequirements');
                return Promise.reject(error);
            }
        },

        async retieveSupportRequirementsUpToDate(context) {
            context.commit('startLoadingRequirementsUpToDate');
            context.commit('clearLoadingErrorRequirementsUpToDate');
            try {
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/PlayerSupport/Report/RequirementsUpToDate`);
                context.commit('setRequirementsUpToDate', result.data);
                context.commit('stopLoadingRequirementsUpToDate');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('setLoadingErrorRequirementsUpToDate', error);
                context.commit('setRequirementsUpToDate', null);
                context.commit('stopLoadingRequirementsUpToDate');
                return Promise.reject(error);
            }
        },

        async computeSupportRequirementsReports(context, upToDate) {
            context.commit('startComputingRequirementsReports');
            context.commit('clearLoadingErrorRequirementsUpToDate');
            let url = `${process.env.VUE_APP_API_URL}/PlayerSupport/Report/Compute/${moment(upToDate).format('YYYY-MM-DD')}`;
            try {
                const result = await axios.get(url);
                context.commit('stopComputingRequirementsReports');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('setComputingErrorRequirementsReports', error);
                context.commit('stopComputingRequirementsReports');
                return Promise.reject(error);
            }
        },
        async exportPdfCompletedSupportRequirements(context, options = {}) {
            context.commit('startLoadingPdfCompletedRequirements');
            context.commit('clearLoadingErrorCompletedRequirements');
            let paramOptions = {
                queryString: options.queryString,
                pageSize: null,
                sortBy: options.sortBy ? _.upperFirst(options.sortBy) : null,
                sortDir: options.sortDir === 'ascending' ? '1' : '-1',
                page: 0,
                nationality: options.nationality,
                ageAt: options.ageAt,
                ppStatus: options.ppStatus,
                currentPhase: options.currentPhase,
                playerAge: options.playerAge,
            };
            try {
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/PlayerSupport/Report/CompletedRequirements/Export/Pdf`,
                    { params: paramOptions, responseType: 'blob' });
                context.commit('stopLoadingPdfCompletedRequirements');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('setLoadingErrorCompletedRequirements', error);
                context.commit('stopLoadingPdfCompletedRequirements');
                return Promise.reject(error);
            }
        },

        async exportPdfOutstandingSupportRequirements(context, options = {}) {
            context.commit('startLoadingPdfOutstandingRequirements');
            context.commit('clearLoadingErrorOutstandingRequirements');
            let paramOptions = {
                queryString: options.queryString,
                pageSize: null,
                sortBy: options.sortBy ? _.upperFirst(options.sortBy) : null,
                sortDir: options.sortDir === 'ascending' ? '1' : '-1',
                page: 0,
                nationality: options.nationality,
                ageAt: options.ageAt,
                ppStatus: options.ppStatus,
                currentPhase: options.currentPhase,
                playerAge: options.playerAge,
            };
            try {
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/PlayerSupport/Report/OutstandingRequirements/Export/Pdf`,
                    { params: paramOptions, responseType: 'blob' });
                context.commit('stopLoadingPdfOutstandingRequirements');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('setLoadingErrorOutstandingRequirements', error);
                context.commit('stopLoadingPdfOutstandingRequirements');
                return Promise.reject(error);
            }
        },

        async exportPdfOverdueSupportRequirements(context, options = {}) {
            context.commit('startLoadingPdfOverdueRequirements');
            context.commit('clearLoadingErrorOverdueRequirements');
            let paramOptions = {
                queryString: options.queryString,
                pageSize: null,
                sortBy: options.sortBy ? _.upperFirst(options.sortBy) : null,
                sortDir: options.sortDir === 'ascending' ? '1' : '-1',
                page: 0,
                nationality: options.nationality,
                ageAt: options.ageAt,
                ppStatus: options.ppStatus,
                currentPhase: options.currentPhase,
                playerAge: options.playerAge,
            };
            try {
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/PlayerSupport/Report/OverdueRequirements/Export/Pdf`,
                    { params: paramOptions, responseType: 'blob' });
                context.commit('stopLoadingPdfOverdueRequirements');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('setLoadingErrorOverdueRequirements', error);
                context.commit('stopLoadingPdfOverdueRequirements');
                return Promise.reject(error);
            }
        },
    },
}