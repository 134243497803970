<template>
  <div>
    <div class="flex-row mt-0 mb-16">
      <h2>Player Development</h2>
    </div>

    <div class="flex-row mb-32">
      <p>Up to Date for all Requirement Reports</p>
      <el-date-picker
          v-model="options.reportsUpToDate"
          :firstDayOfWeek="1"
          type="date"
          format="yyyy-MM-dd"
          placeholder="Pick a day"
          class="ml-16 mr-32"
          @change="selectDate"
      ></el-date-picker>
      <span class="spacer"></span>
    </div>

    <el-tabs value="eligibleDates">

      <el-tab-pane :label="titleEligibilityDates" name="eligibleDates">
        <EligibleDates/>
      </el-tab-pane>

      <el-tab-pane :label="titlePlayerReuirements" name="playerRequirements">

        <el-tabs value="completedRequirements">
          <el-tab-pane :label="titleCompletedRequirements" name="completedRequirements">
            <CompletedRequirements/>
          </el-tab-pane>

          <el-tab-pane :label="titleOutstandingRequirements" name="outstandingRequirements">
            <OutstandingRequirements/>
          </el-tab-pane>

          <el-tab-pane :label="titleOverdueRequirements" name="overdueRequirements">
            <OverdueRequirements/>
          </el-tab-pane>
        </el-tabs>

      </el-tab-pane>

      <el-tab-pane :label="titleSupportReuirements" name="supportRequirements">

        <el-tabs value="completedSupportRequirements">
          <el-tab-pane :label="titleCompletedSupportRequirements" name="completedSupportRequirements">
            <CompletedSupportRequirements/>
          </el-tab-pane>

          <el-tab-pane :label="titleOutstandingSupportRequirements" name="outstandingSupportRequirements">
            <OutstandingSupportRequirements/>
          </el-tab-pane>

          <el-tab-pane :label="titleOverdueSupportRequirements" name="overdueSupportRequirements">
            <OverdueSupportRequirements/>
          </el-tab-pane>
        </el-tabs>
      </el-tab-pane>


    </el-tabs>
  </div>
</template>


<script>
import CompletedRequirements from '../../components/player-development/CompletedRequirements.vue';
import OverdueRequirements from '../../components/player-development/OverdueRequirements.vue';
import OutstandingRequirements from '../../components/player-development/OutstandingRequirements.vue';
import EligibleDates from '../../components/player-development/EligibleDates.vue';
import CompletedSupportRequirements from '../../components/player-development/CompletedSupportRequirements.vue';
import OutstandingSupportRequirements from '../../components/player-development/OutstandingSupportRequirements.vue';
import OverdueSupportRequirements from '../../components/player-development/OverdueSupportRequirements.vue';
import {mapGetters, mapActions} from 'vuex';

export default {
  components: {
    CompletedRequirements,
    OutstandingRequirements,
    OverdueRequirements,
    EligibleDates,
    CompletedSupportRequirements,
    OutstandingSupportRequirements,
    OverdueSupportRequirements,
  },
  data() {
    return {
      options: {
        queryString: '',
        pageSize: 20,
        sortBy: null,
        sortDir: 'ascending',
        page: 0,
        nationality: '',
        ageAt: null,
        reportsUpToDate: null,
      },
    };
  },
  mounted() {
    this.retieveDevelopmentRequirementsUpToDate().then(() => {
      this.options.reportsUpToDate = this.supportRequirementsUpToDate;
    });
    this.retieveSupportRequirementsUpToDate();
  },
  computed: {
    ...mapGetters('playerDevelopments', [
      'eligibilityDates',
      'completedRequirements',
      'outstandingRequirements',
      'overdueRequirements',
      'developmentRequirementsUpToDate',
      'loadingEligibilityDates',
      'loadingCompletedRequirements',
      'loadingOutstandingRequirements',
      'loadingOverdueRequirements',
      'loadingDevelopmentRequirementsUpToDate',
    ]),
    ...mapGetters('playerSupportReports', [
      'completedSupportRequirements',
      'outstandingSupportRequirements',
      'overdueSupportRequirements',
      'supportRequirementsUpToDate',
      'loadingCompletedSupportRequirements',
    ]),
    titleEligibilityDates() {
      return 'Eligibility Dates (' + this.eligibilityDates.total + ')';
    },
    titlePlayerReuirements() {
      return 'Player Requirements (' + (this.completedRequirements.total + this.outstandingRequirements.total
          + this.overdueRequirements.total) + ')';
    },
    titleCompletedRequirements() {
      return 'Completed Requirements (' + this.completedRequirements.total + ')';
    },
    titleOutstandingRequirements() {
      return 'Outstanding Requirements (' + this.outstandingRequirements.total + ')';
    },
    titleOverdueRequirements() {
      return 'Overdue Requirements (' + this.overdueRequirements.total + ')';
    },
    titleSupportReuirements() {
      return 'Support Requirements (' + (this.completedSupportRequirements.total
          + this.outstandingSupportRequirements.total + this.overdueSupportRequirements.total) + ')';
    },
    titleCompletedSupportRequirements() {
      return 'Completed Support Requirements (' + this.completedSupportRequirements.total + ')';
    },
    titleOutstandingSupportRequirements() {
      return 'Outstanding Support Requirements (' + this.outstandingSupportRequirements.total + ')';
    },
    titleOverdueSupportRequirements() {
      return 'Overdue Support Requirements (' + this.overdueSupportRequirements.total + ')';
    },
  },
  methods: {
    ...mapActions('playerDevelopments', [
      'findCompletedRequirements',
      'findOutstandingRequirements',
      'findOverdueRequirements',
      'retieveDevelopmentRequirementsUpToDate',
      'computeDevelopmentRequirementsReports',
    ]),
    ...mapActions('playerSupportReports', [
      'findCompletedSupportRequirements',
      'findOutstandingSupportRequirements',
      'findOverdueSupportRequirements',
      'retieveSupportRequirementsUpToDate',
      'computeSupportRequirementsReports',
    ]),
    selectDate(date) {
      this.computeDevelopmentRequirementsReports(this.options.reportsUpToDate).then((data) => {
        this.retieveDevelopmentRequirementsUpToDate();
        this.findCompletedRequirements(this.options);
        this.findOutstandingRequirements(this.options);
        this.findOverdueRequirements(this.options);
      });
      this.computeSupportRequirementsReports(this.options.reportsUpToDate).then((data) => {
        this.retieveSupportRequirementsUpToDate();
        this.findCompletedSupportRequirements(this.options);
        this.findOutstandingSupportRequirements(this.options);
        this.findOverdueSupportRequirements(this.options);
      });
    },
  },
};
</script>

<style></style>
