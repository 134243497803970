import { isValid, Model } from './model';
import { icoCountries } from '../utils/country-codes';

export class PlayerSupport extends Model {
    isValid() {
        return isValid(PlayerSupport.schema, this);
    };
    isValidForEdit() {
        return isValid(PlayerSupport.schema, this);
    };
    isValidForSupportEdit() {
        return isValid(PlayerSupport.supportSchema, this);
    };
};

PlayerSupport.layout = {
    title: 'Basic Information',
    children: [
        {
            children: ['nameFirst', 'nameMiddle', 'nameLast', { type: 'spacer' }],
        },
        {
            children: ['pstNation', 'pstBDate', { type: 'spacer' }],
        },
        {
            children: ['eMailAddress', 'phone1', 'phone2', 'phoneFax', { type: 'spacer', width: 2 },],
        },
        {
            children: ['city', 'state', 'nationCode', 'mailCode', { type: 'spacer', width: 2 },],
        },
    ],
};

PlayerSupport.layoutCoachRookie = {
    title: 'Player Support: Coach Requirements',
    children: [
        {
            title: 'Rookie Phase',
            children: ['coachEligible1Date', 'coachEnroll1Date', 'coachDeadl1Date', 'coachOrDate', 'coachCmpl1Date', 'coachCert',],
        },
        {
            children: ['szlDate', 'sztDate', 'pppDate', 'rzDate', 'safe1Date', 'introProsDate',],
        },
        {
            children: ['szlScore', 'sztScore', 'pppScore', 'rzScore', 'safe1Score', 'introProsScore',],
        },
        {
            children: ['ssmRhsDate', 'proPathDate', 'plyrRelDate', 'supsDate', 'historyDate', { type: 'spacer' },],
        },
        {
            children: ['ssmRhsScore', 'proPathScore', 'plyrRelScore', 'supsScore', 'historyScore', { type: 'spacer' },],
        },
        {
            children: ['comment1',],
        },
    ],
};

PlayerSupport.layoutCoachProPath = {
    title: 'Player Support: Coach Requirements',
    children: [
        {
            title: 'ProPath Phase',
            children: ['coachEligible2Date', 'coachEnroll2Date', 'coachDeadl2Date', 'coachCmpl2Date', { type: 'spacer', width: 2 },],
        },
        {
            children: ['szlDate', 'sztDate', 'szaDate', 'pppDate', 'rzDate', 'safe1Date',],
        },
        {
            children: ['szlScore', 'sztScore', { type: 'spacer' }, 'pppScore', 'rzScore', 'safe1Score',],
        },
        {
            children: ['ssmRhsDate', 'proPathDate', 'plyrRelDate', 'supsDate', 'historyDate', 'introProsDate',],
        },
        {
            children: ['ssmRhsScore', 'proPathScore', 'plyrRelScore', 'supsScore', 'historyScore', 'introProsScore',],
        },
        {
            children: ['comment2',],
        },
    ],
};

PlayerSupport.layoutAgent = {
    title: 'Player Support: Agent Requirements',
    children: [
        {
            children: ['agentEligibleDate', 'agentEnrollDate', 'agentDeadlDate', 'agentOrDate', 'agentCmplDate', 'agency',],
        },
        {
            children: ['introProsDate', 'proPathDate', 'safe1Date', 'plyrRelDate', 'supsDate', 'historyDate',],
        },
        {
            children: ['introProsScore', 'proPathScore', 'safe1Score', 'plyrRelScore', 'supsScore', 'historyScore',],
        },
        {
            children: ['pickPstDate', 'szlDate', 'sztDate', 'ssmRhsDate', { type: 'spacer', width: 2 },],
        },
        {
            children: ['pickPstScore', 'szlScore', 'sztScore', 'ssmRhsScore', { type: 'spacer', width: 2 },],
        },
        {
            children: ['comment3',],
        },
    ],
};

PlayerSupport.layoutParent = {
    title: 'Player Support: Parent Requirements',
    children: [
        {
            children: ['parentEligibleDate', 'parentEnrollDate', 'parentDlineDate', 'parentOrDate', 'parentCmplDate', { type: 'spacer' },],
        },
        {
            children: ['introProsDate', 'proPathDate', 'safety1StDate', 'plyrRelDate', 'supsDate', 'historyDate',],
        },
        {
            children: ['introProsScore', 'proPathScore', 'safety1StScore', 'plyrRelScore', 'supsScore', 'historyScore',],
        },
        {
            children: ['pickPstDate', 'szlDate', 'sztDate', 'ssmRhsDate', { type: 'spacer', width: 2 },],
        },
        {
            children: ['pickPstScore', 'szlScore', 'sztScore', 'ssmRhsScore', { type: 'spacer', width: 2 },],
        },
        {
            children: ['comment4',],
        },
    ],
};

PlayerSupport.layoutContact = {
    title: 'Contact Details',
    children: [
        {
            children: ['contactId', 'userId', 'password', { type: 'spacer', width: 3 },],
        },
        {
            children: ['nameLast', 'nameFirst', 'nameMiddle', 'nameTitle', 'pstNation', 'pstBDate',],
        },
        {
            children: ['eMailAddress', 'phone1', 'phone2', 'phoneFax', { type: 'spacer', width: 2 },],
        },
        {
            children: [{ id: 'addrLine1', width: 2 }, { type: 'spacer', width: 4 },],
        },
        {
            children: [{ id: 'addrLine2', width: 2 }, { type: 'spacer', width: 4 },],
        },
        {
            children: [{ id: 'addrLine3', width: 2 }, { type: 'spacer', width: 4 },],
        },
        {
            children: ['city', 'state', 'nationCode', 'mailCode', { type: 'spacer', width: 2 },],
        },
    ],
};

PlayerSupport.layoutRequirementsContact = {
    title: 'Contact Details',
    children: [
        {
            children: ['contactId', 'supporterType', 'eligibleDate', 'deadlineDate', { type: 'spacer', width: 2 },],
        },
        {
            children: ['nameLast', 'nameFirst', 'nameMiddle', 'nameTitle', 'pstNation', 'pstBDate',],
        },
        {
            children: [{ id: 'eMailAddress', width: 2 }, 'phone1', 'phone2', 'phoneFax', { type: 'spacer', width: 1 },],
        },
        {
            children: [{ id: 'addrLine1', width: 2 }, { id: 'addrLine2', width: 2 }, { id: 'addrLine3', width: 2 },],
        },
        {
            children: ['city', 'state', 'nationCode', 'mailCode', { type: 'spacer', width: 2 },],
        },
    ],
};

PlayerSupport.layoutDevelopmentCompletedRequriements = {
    children: [
        {
            children: ['completedRequirements'],
        },
    ],
};

PlayerSupport.layoutDevelopmentOutstandingRequriements = {
    children: [
        {
            children: ['outstandingRequirements'],
        },
    ],
};

PlayerSupport.layoutDevelopmentOverdueRequriements = {
    children: [
        {
            children: ['overdueRequirements'],
        },
    ],
};


PlayerSupport.schema = {
    fields: {
        //
        // CONTACTS
        //
        contactId: { type: 'string', sortable: true, displayName: 'Contact Id', readonly: true }, // used
        nameLast: { type: 'string', sortable: true, displayName: 'Last Name', maxLength: 40, required: true, clearable: true }, // used
        nameFirst: { type: 'string', sortable: true, displayName: 'First Name', maxLength: 15, required: true, clearable: true }, // used
        nameMiddle: { type: 'string', displayName: 'Middle Name', maxLength: 15, clearable: true }, // used
        nameTitle: { type: 'string', displayName: 'Title', maxLength: 6, clearable: true }, // used
        recType: { type: 'string', displayName: 'Rec Type', maxLength: 2, clearable: true },
        comment: { type: 'string', displayName: 'Comment', maxLength: 60, clearable: true },
        taxId: { type: 'string', displayName: 'Tax Id', maxLength: 15, clearable: true },
        age: { type: 'number', displayName: 'Age', clearable: true },
        daysAfter: { type: 'number', displayName: 'DaysA fter', clearable: true },
        daysTo: { type: 'number', displayName: 'Days To', clearable: true },
        rank: { type: 'number', displayName: 'Rank', clearable: true },
        rankDate: { type: 'date', displayName: 'Rank Date', clearable: true },
        userId: { type: 'string', displayName: 'User Id', maxLength: 10, clearable: true }, // used
        password: { type: 'string', displayName: 'Password', maxLength: 10, clearable: true }, // used
        passChanged: { type: 'date', displayName: 'Pass Changed', clearable: true },
        isAdmin: { type: 'number', displayName: 'IsAdmin', clearable: true },
        pstBDate: { type: 'date', displayName: 'Birthdate', clearable: true, required: true }, // used
        pstNation: { type: 'nationality', displayName: 'Nationality', maxLength: 10, clearable: true, lookup: Object.keys(icoCountries) }, // used

        //
        // CONTACT_DATA
        //
        contactNbr: { type: 'number', displayName: 'ContactNbr', clearable: true },
        contactType: { type: 'string', displayName: 'ContactType', maxLength: 4, clearable: true },
        addrLine1: { type: 'string', displayName: 'Postal Address 1', maxLength: 40, clearable: true }, // used
        addrLine2: { type: 'string', displayName: 'Postal Address 2', maxLength: 40, clearable: true }, // used
        addrLine3: { type: 'string', displayName: 'Postal Address 3', maxLength: 40, clearable: true }, // used
        addrLine4: { type: 'string', displayName: 'addrLine4', maxLength: 40, clearable: true },
        phone1: { type: 'string', displayName: 'Mobile Phone', maxLength: 15, clearable: true }, // used
        phone1Type: { type: 'string', displayName: 'Phone1Type', maxLength: 15, clearable: true },
        phone2: { type: 'string', displayName: 'Home Phone', maxLength: 15, clearable: true }, // used
        phone2Type: { type: 'string', displayName: 'Phone2Type', maxLength: 15, clearable: true },
        phone3: { type: 'string', displayName: 'Phone3', maxLength: 15, clearable: true },
        phone3Type: { type: 'string', displayName: 'Phone3Type', maxLength: 15, clearable: true },
        phoneFax: { type: 'string', displayName: 'Fax', maxLength: 15, clearable: true },
        mailCode: { type: 'string', displayName: 'Postal Code', maxLength: 15, clearable: true }, // used
        status: { type: 'string', displayName: 'Status', maxLength: 4, clearable: true },
        statusDate: { type: 'date', displayName: 'StatusDate', clearable: true },
        dataComment: { type: 'string', displayName: 'Comment', maxLength: 60, clearable: true },
        eMailAddress: { type: 'string', displayName: 'E-Mail Address', maxLength: 40, clearable: true }, // used
        nationCode: { type: 'string', displayName: 'Country Nation Code', maxLength: 4, clearable: true }, // used
        agentName: { type: 'string', displayName: 'AgentName', maxLength: 40, clearable: true },
        agentEmail: { type: 'string', displayName: 'AgentEmail', maxLength: 40, clearable: true },
        coachName: { type: 'string', displayName: 'CoachName', maxLength: 40, clearable: true },
        coachEmail: { type: 'string', displayName: 'CoachEmail', maxLength: 40, clearable: true },
        city: { type: 'string', displayName: 'City', maxLength: 40, clearable: true }, // used
        state: { type: 'string', displayName: 'State', maxLength: 2, clearable: true }, // used
        fax: { type: 'string', displayName: 'Fax', maxLength: 25, clearable: true },
        agentPhone: { type: 'string', displayName: 'AgentPhone', maxLength: 15, clearable: true },
        coachPhone: { type: 'string', displayName: 'CoachPhone', maxLength: 15, clearable: true },
        finRepName: { type: 'string', displayName: 'FinRepName', maxLength: 40, clearable: true },
        finRepEmail: { type: 'string', displayName: 'FinRepEmail', maxLength: 40, clearable: true },
        finRepPhone: { type: 'string', displayName: 'FinRepPhone', maxLength: 15, clearable: true },
        finRepAuth: { type: 'string', displayName: 'FinRepAuth', maxLength: 1, clearable: true },
        agentAuth: { type: 'string', displayName: 'AgentAuth', maxLength: 1, clearable: true },
        rcoachName: { type: 'string', displayName: 'RcoachName', maxLength: 40, clearable: true },
        rcoachEmail: { type: 'string', displayName: 'RcoachEmail', maxLength: 40, clearable: true },
        rcoachPhone: { type: 'string', displayName: 'RcoachPhone', maxLength: 15, clearable: true },
        rcoachType: { type: 'string', displayName: 'RcoachType', maxLength: 1, clearable: true },

        //
        // PLAYER_DEVELOPMENT_SUPPORT
        //
        coachEligible1Date: { type: 'date', displayName: 'Eligible', clearable: true }, // used
        coachEnroll1Date: { type: 'date', displayName: 'Enrolled', clearable: true }, // used
        coachDeadl1Date: { type: 'date', displayName: 'Deadline', clearable: true }, // used
        coachCmpl1Date: { type: 'date', displayName: 'Completed', clearable: true }, // used
        coachEligible2Date: { type: 'date', displayName: 'Eligible', clearable: true }, // used
        coachEnroll2Date: { type: 'date', displayName: 'Enrolled', clearable: true }, // used
        coachDeadl2Date: { type: 'date', displayName: 'Deadline', clearable: true }, // used
        coachCmpl2Date: { type: 'date', displayName: 'Completed', clearable: true }, // used
        agentEligibleDate: { type: 'date', displayName: 'Eligible', clearable: true }, // used
        agentEnrollDate: { type: 'date', displayName: 'Enrolled', clearable: true }, // used
        agentDeadlDate: { type: 'date', displayName: 'Deadline', clearable: true }, // used
        agentCmplDate: { type: 'date', displayName: 'Completed', clearable: true }, // used
        parentEligibleDate: { type: 'date', displayName: 'Eligible', clearable: true }, // used
        parentEnrollDate: { type: 'date', displayName: 'Enrolled', clearable: true }, // used
        parentDlineDate: { type: 'date', displayName: 'Deadline', clearable: true }, // used
        parentCmplDate: { type: 'date', displayName: 'Completed', clearable: true }, // used
        szlDate: { type: 'date', displayName: 'SZ Lesson', clearable: true, developmentRequirement: true }, // used
        szlScore: { type: 'decimal', displayName: 'SZ Lesson Score', clearable: true }, // used
        sztDate: { type: 'date', displayName: 'SZ Tool', clearable: true, developmentRequirement: true }, // used
        sztScore: { type: 'decimal', displayName: 'SZ Tool Score', clearable: true }, // used
        szaDate: { type: 'date', displayName: 'SZ Agree', clearable: true, developmentRequirement: true }, // used
        pppDate: { type: 'date', displayName: 'PPP', clearable: true, developmentRequirement: true }, // used
        pppScore: { type: 'decimal', displayName: 'PPP Score', clearable: true }, // used
        rzDate: { type: 'date', displayName: 'RZ', clearable: true, developmentRequirement: true }, // used
        rzScore: { type: 'decimal', displayName: 'RZ Score', clearable: true }, // used
        safe1Date: { type: 'date', displayName: 'Safety 1', clearable: true, developmentRequirement: true }, // used
        safe1Score: { type: 'decimal', displayName: 'Safety 1 Score', clearable: true }, // used
        introProsDate: { type: 'date', displayName: 'Intro to Pros', clearable: true, developmentRequirement: true }, // used
        introProsScore: { type: 'decimal', displayName: 'Intro to Pros Score', clearable: true }, // used
        ssmRhsDate: { type: 'date', displayName: 'SS&M', clearable: true, developmentRequirement: true }, // used
        ssmRhsScore: { type: 'decimal', displayName: 'SS&M Score', clearable: true }, // used
        proPathDate: { type: 'date', displayName: 'Pro Path', clearable: true, developmentRequirement: true }, // used
        proPathScore: { type: 'decimal', displayName: 'Pro Path Score', clearable: true }, // used
        plyrRelDate: { type: 'date', displayName: 'Player Rel', clearable: true, developmentRequirement: true }, // used
        plyrRelScore: { type: 'decimal', displayName: 'Player Rel Score', clearable: true }, // used
        opsDate: { type: 'date', displayName: 'Ops', clearable: true },
        opsScore: { type: 'decimal', displayName: 'Ops Score', clearable: true },
        historyDate: { type: 'date', displayName: 'History', clearable: true, developmentRequirement: true }, // used
        historyScore: { type: 'decimal', displayName: 'History Score', clearable: true }, // used
        safety1StDate: { type: 'date', displayName: 'Safety 1st', clearable: true, developmentRequirement: true }, // used
        safety1StScore: { type: 'decimal', displayName: 'Safety 1st Score', clearable: true }, // used
        pickPstDate: { type: 'date', displayName: 'Pick PST', clearable: true, developmentRequirement: true }, // used
        pickPstScore: { type: 'decimal', displayName: 'Pick PST Score', clearable: true }, // used
        comment1: { type: 'string', displayName: 'Comment', maxLength: 100, clearable: true }, // used
        comment2: { type: 'string', displayName: 'Comment', maxLength: 100, clearable: true }, // used
        comment3: { type: 'string', displayName: 'Comment', maxLength: 100, clearable: true }, // used
        comment4: { type: 'string', displayName: 'Comment', maxLength: 100, clearable: true }, // used
        coachOrDate: { type: 'date', displayName: 'Verbal Or', clearable: true, developmentRequirement: true }, // used
        agentOrDate: { type: 'date', displayName: 'Verbal Or', clearable: true, developmentRequirement: true }, // used
        parentOrDate: { type: 'date', displayName: 'Verbal Or', clearable: true, developmentRequirement: true }, // used
        coachCert: { type: 'string', displayName: 'Certification', maxLength: 10, clearable: true }, // used
        supsDate: { type: 'date', displayName: 'Sups', clearable: true, developmentRequirement: true }, // used
        supsScore: { type: 'decimal', displayName: 'Sups Score', clearable: true }, // used
        agency: { type: 'string', displayName: 'Agency', maxLength: 10, clearable: true }, // used

        //
        // OWN
        // 
        fullName: { displayName: 'Name', type: 'string', readonly: true, sortable: true },
        supporterType: { type: 'string', displayName: 'Type', clearable: true, readonly: true, sortable: true }, // used
        supporterForPlayerNbr: { type: 'number', displayName: 'Support For', clearable: true, readonly: true },
        eligibleDate: { type: 'date', displayName: 'Eligible', clearable: true, readonly: true, sortable: true }, // used
        deadlineDate: { type: 'date', displayName: 'Deadline', clearable: true, readonly: true, sortable: true }, // used
        completedRequirements: { displayName: 'Completed Requirements', type: 'string-array', displayType: 'support-development', tagType: 'success', alertType: 'success', readonly: true },
        outstandingRequirements: { displayName: 'Outstanding Requirements', type: 'string-array', displayType: 'support-development', tagType: 'warning', alertType: 'warning', readonly: true },
        overdueRequirements: { displayName: 'Overdue Requirements', type: 'string-array', displayType: 'support-development', tagType: 'danger', alertType: 'error', readonly: true },
    },
};


PlayerSupport.supportSchema = {
    fields: {
        //
        // PLAYER_DEVELOPMENT_SUPPORT
        //
        coachEligible1Date: { type: 'date', displayName: 'Eligible', clearable: true }, // used
        coachEnroll1Date: { type: 'date', displayName: 'Enrolled', clearable: true }, // used
        coachDeadl1Date: { type: 'date', displayName: 'Deadline', clearable: true }, // used
        coachCmpl1Date: { type: 'date', displayName: 'Completed', clearable: true }, // used
        coachEligible2Date: { type: 'date', displayName: 'Eligible', clearable: true }, // used
        coachEnroll2Date: { type: 'date', displayName: 'Enrolled', clearable: true }, // used
        coachDeadl2Date: { type: 'date', displayName: 'Deadline', clearable: true }, // used
        coachCmpl2Date: { type: 'date', displayName: 'Completed', clearable: true }, // used
        agentEligibleDate: { type: 'date', displayName: 'Eligible', clearable: true }, // used
        agentEnrollDate: { type: 'date', displayName: 'Enrolled', clearable: true }, // used
        agentDeadlDate: { type: 'date', displayName: 'Deadline', clearable: true }, // used
        agentCmplDate: { type: 'date', displayName: 'Completed', clearable: true }, // used
        parentEligibleDate: { type: 'date', displayName: 'Eligible', clearable: true }, // used
        parentEnrollDate: { type: 'date', displayName: 'Enrolled', clearable: true }, // used
        parentDlineDate: { type: 'date', displayName: 'Deadline', clearable: true }, // used
        parentCmplDate: { type: 'date', displayName: 'Completed', clearable: true }, // used
        szlDate: { type: 'date', displayName: 'SZ Lesson', clearable: true, developmentRequirement: true }, // used
        szlScore: { type: 'decimal', displayName: 'SZ Lesson Score', clearable: true }, // used
        sztDate: { type: 'date', displayName: 'SZ Tool', clearable: true, developmentRequirement: true }, // used
        sztScore: { type: 'decimal', displayName: 'SZ Tool Score', clearable: true }, // used
        szaDate: { type: 'date', displayName: 'SZ Agree', clearable: true, developmentRequirement: true }, // used
        pppDate: { type: 'date', displayName: 'PPP', clearable: true, developmentRequirement: true }, // used
        pppScore: { type: 'decimal', displayName: 'PPP Score', clearable: true }, // used
        rzDate: { type: 'date', displayName: 'RZ', clearable: true, developmentRequirement: true }, // used
        rzScore: { type: 'decimal', displayName: 'RZ Score', clearable: true }, // used
        safe1Date: { type: 'date', displayName: 'Safety 1', clearable: true, developmentRequirement: true }, // used
        safe1Score: { type: 'decimal', displayName: 'Safety 1 Score', clearable: true }, // used
        introProsDate: { type: 'date', displayName: 'Intro to Pros', clearable: true, developmentRequirement: true }, // used
        introProsScore: { type: 'decimal', displayName: 'Intro to Pros Score', clearable: true }, // used
        ssmRhsDate: { type: 'date', displayName: 'SS&M', clearable: true, developmentRequirement: true }, // used
        ssmRhsScore: { type: 'decimal', displayName: 'SS&M Score', clearable: true }, // used
        proPathDate: { type: 'date', displayName: 'Pro Path', clearable: true, developmentRequirement: true }, // used
        proPathScore: { type: 'decimal', displayName: 'Pro Path Score', clearable: true }, // used
        plyrRelDate: { type: 'date', displayName: 'Player Rel', clearable: true, developmentRequirement: true }, // used
        plyrRelScore: { type: 'decimal', displayName: 'Player Rel Score', clearable: true }, // used
        opsDate: { type: 'date', displayName: 'Ops', clearable: true },
        opsScore: { type: 'decimal', displayName: 'Ops Score', clearable: true },
        historyDate: { type: 'date', displayName: 'History', clearable: true, developmentRequirement: true }, // used
        historyScore: { type: 'decimal', displayName: 'History Score', clearable: true }, // used
        safety1StDate: { type: 'date', displayName: 'Safety 1st', clearable: true, developmentRequirement: true }, // used
        safety1StScore: { type: 'decimal', displayName: 'Safety 1st Score', clearable: true }, // used
        pickPstDate: { type: 'date', displayName: 'Pick PST', clearable: true, developmentRequirement: true }, // used
        pickPstScore: { type: 'decimal', displayName: 'Pick PST Score', clearable: true }, // used
        comment1: { type: 'string', displayName: 'Comment', maxLength: 100, clearable: true }, // used
        comment2: { type: 'string', displayName: 'Comment', maxLength: 100, clearable: true }, // used
        comment3: { type: 'string', displayName: 'Comment', maxLength: 100, clearable: true }, // used
        comment4: { type: 'string', displayName: 'Comment', maxLength: 100, clearable: true }, // used
        coachOrDate: { type: 'date', displayName: 'Verbal Or', clearable: true, developmentRequirement: true }, // used
        agentOrDate: { type: 'date', displayName: 'Verbal Or', clearable: true, developmentRequirement: true }, // used
        parentOrDate: { type: 'date', displayName: 'Verbal Or', clearable: true, developmentRequirement: true }, // used
        coachCert: { type: 'string', displayName: 'Certification', maxLength: 10, clearable: true }, // used
        supsDate: { type: 'date', displayName: 'Sups', clearable: true, developmentRequirement: true }, // used
        supsScore: { type: 'decimal', displayName: 'Sups Score', clearable: true }, // used
        agency: { type: 'string', displayName: 'Agency', maxLength: 10, clearable: true }, // used
    },
};