import axios from 'axios';

export default {
    namespaced: true,

    state: {
        importEntries: [],
        loadingImportEntries: false,
        loadingError: null,
    },

    getters: {
        importEntries: (state) => state.importEntries,
        loadingImportEntries: (state) => state.loadingImportEntries,
        loadingError: (state) => state.loadingError,
    },

    mutations: {
        setImportEntries(state, data) {
            state.importEntries = data;
        },

        startLoadingImportEntries(state) {
            state.loadingImportEntries = true;
        },

        stopLoadingImportEntries(state) {
            state.loadingImportEntries = false;
        },

        setLoadingError(state, error) {
            state.loadingError = error;
        },

        clearLoadingError(state) {
            state.loadingError = null;
        },
    },

    actions: {
        async findImportEntries(context, options = {}) {
            context.commit('startLoadingImportEntries');
            try {
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/FileImport/History`);
                context.commit('stopLoadingImportEntries');
                context.commit('setImportEntries', result.data);
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('stopLoadingImportEntries');
                context.commit('setLoadingError', error);
                return Promise.reject();
            }
        },
        async deleteAllImportEntries(context, options = {}) {
            context.commit('startLoadingImportEntries');
            try {
                const result = await axios.delete(`${process.env.VUE_APP_API_URL}/FileImport/History/DeleteAll`);
                context.commit('stopLoadingImportEntries');
                return Promise.resolve(result);
            } catch (error) {
                context.commit('stopLoadingImportEntries');
                context.commit('setLoadingError', error);
                return Promise.reject();
            }
        },
    },
};
