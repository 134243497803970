var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('DialogAddItem',{attrs:{"visibleSync":_vm.addDialogVisible,"dtoName":"Special Ranking","selected":_vm.selectedRanking,"schema":_vm.schemaAdd,"layout":_vm.layoutAdd},on:{"save":_vm.saveChanges,"change":_vm.onChange,"close":function($event){_vm.addDialogVisible = false}}}),_c('DialogEditItem',{attrs:{"visibleSync":_vm.editDialogVisible,"dtoName":"Special Ranking","selected":_vm.selectedRanking,"schema":_vm.schema,"layout":_vm.layout},on:{"save":_vm.saveChanges,"change":_vm.onChange,"delete":_vm.deleteRanking,"close":function($event){_vm.editDialogVisible = false}}}),_c('div',{staticClass:"flex-row mt-0 mb-32"},[_vm._m(0),_c('span',{staticClass:"spacer"}),_c('SelectPlayer',{staticClass:"mr-8",on:{"palyer-selected":_vm.playerSelected},model:{value:(_vm.selectedPlayerFullName),callback:function ($$v) {_vm.selectedPlayerFullName=$$v},expression:"selectedPlayerFullName"}}),_c('el-button',{attrs:{"plain":"","type":"primary","size":"mini"},on:{"click":_vm.reset}},[_vm._v("Reset")]),(!_vm.isBaselineReader)?_c('el-button',{attrs:{"plain":"","type":"primary","size":"mini"},on:{"click":_vm.addRanking}},[_vm._v("Add Special Ranking")]):_vm._e(),_c('el-button',{staticClass:"ml-8",attrs:{"plain":"","size":"mini"},on:{"click":_vm.exportCsvClick}},[_vm._v("Export CSV")])],1),_c('ErrorDisplay',{attrs:{"requestError":this.requestError},on:{"closed":_vm.clearError}}),_c('DetailedInformation',{attrs:{"screenName":"Required Tournaments","informationArray":this.informationArray()}}),_c('DataTable',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loadingSpecialRankings),expression:"loadingSpecialRankings"}],attrs:{"items":_vm.specialRankings.data,"schema":_vm.schema,"fields":[
            'expired',
            'playerId',
            'fullName',
            'nationality',
            'singlesSpecialRank',
            'doublesSpecialRank',
            'singlesTournamentsAllowed',
            'singlesTournamentsPlayed',
            'doublesTournamentsAllowed',
            'doublesTournamentsPlayed',
            'singlesGrandSlamsAllowed',
            'singlesGrandSlamsPlayed',
            'doublesGrandSlamsAllowed',
            'doublesGrandSlamsPlayed',
            'lastTournamentBeforeBreak',
            'weeksAsFromDb',
            'weeksComputed',
            'firstTournamentAllowed',
            'expirationDate',
        ],"autoSort":false},on:{"item-clicked":_vm.selectRanking,"header-clicked":_vm.setSort}}),(_vm.specialRankings.data)?_c('Paginator',{attrs:{"data":_vm.specialRankings,"elementsPerPage":_vm.options.pageSize},on:{"prev-page":_vm.prevPage,"next-page":_vm.nextPage,"set-page-size":_vm.setPageSize}}):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h2',[_c('i',{staticClass:"el-icon-medal-1 mr-16"}),_vm._v("Special Rankings Overview")])
}]

export { render, staticRenderFns }