import axios from 'axios';

export default {
    namespaced: true,

    state: {
        loading: false,
        loadingError: null,
        runningUpdateYtdTotals: false,
        progress: 0
    },

    getters: {
        loadingPlayerStats: (state) => state.loading,
        playerStatsLoadingError: (state) => state.loadingError,
        runningUpdateYtdTotals: (state) => state.runningUpdateYtdTotals,
        updateYtdTotalsProgress: (state) => state.progress
    },

    mutations: {
        startLoading(state) {
            state.loading = true;
        },
        stopLoading(state) {
            state.loading = false;
        },
        setLoadingError(state, error) {
            state.loadingError = error;
        },
        clearLoadingError(state) {
            state.loadingError = null;
        },
        startUpdateYtdTotals(state) {
            state.runningUpdateYtdTotals = true;
        },
        stopUpdateYtdTotals(state) {
            state.runningUpdateYtdTotals = false;
        },
        setProgress(state, progress) {
            state.progress = progress;
        },
        clearProgress(state) {
            state.progress = 0;
        },
    },

    actions: {
        async updateYtdTotals(context, data) {
            context.commit('startLoading');
            context.commit('startUpdateYtdTotals');
            context.commit('clearProgress');
            try {
                var result = await axios.post(`${process.env.VUE_APP_API_URL}/player-stats/update-ytd-totals`, data);
                context.commit('setLoadingError', null);
                context.commit('stopLoading');
                context.commit('stopUpdateYtdTotals');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('setLoadingError', error);
                context.commit('stopLoading');
                context.commit('stopUpdateYtdTotals');
                return Promise.reject(error);
            }
        },
    },
};
