import { isValid, Model } from './model';

export class PlayerBioDet extends Model {
    isValid() {
        return isValid(PlayerBioDet.schema, this);
    }
    isValidForEdit() {
        return isValid(PlayerBioDet.schema, this);
    }
}

PlayerBioDet.layout = {
    children: [
        {
            children: ['playerNbr', { type: 'spacer' }, 'updateDate', 'updateBy'],
        },
        {
            children: ['pronounciation'],
        },
        {
            children: ['additional'],
        },
        {
            children: ['awards'],
        },
        {
            children: ['careerHl'],
        },
        {
            children: ['careerHlDbls'],
        },
        {
            children: ['careerHlSngls'],
        },
        {
            children: ['carreerReview'],
        },
        {
            children: ['currYrDetail'],
        },
        {
            children: ['personal'],
        },
        {
            children: ['birthplace', 'residence'],
        },
        {
            children: ['height', 'weight'],
        },
        {
            children: ['plays', 'status'],
        },
        {
            children: ['racquet', 'racquetLink'],
        },
        {
            children: ['apparel', 'apperalLink'],
        },
        {
            children: ['shoes', 'shoesLink'],
        },
        {
            children: ['activeFlag', 'photoCr'],
        },
    ],
};

PlayerBioDet.schema = {
    fields: {
        playerNbr: { fieldName: 'playerNbr', displayName: 'PlayerNbr', type: 'string', readonly: true },
        pronounciation: { fieldName: 'pronounciation', displayName: 'Pronounciation', type: 'string' },
        additional: { fieldName: 'additional', displayName: 'Additional', type: 'multiline' },
        awards: { fieldName: 'awards', displayName: 'Awards', type: 'multiline' },
        careerHl: { fieldName: 'careerHl', displayName: 'Highlights', type: 'multiline' },
        careerHlDbls: { fieldName: 'careerHlDbls', displayName: 'Highlights Doubles', type: 'multiline' },
        careerHlSngls: { fieldName: 'careerHlSngls', displayName: 'Highlights Singles', type: 'multiline' },
        carreerReview: { fieldName: 'carreerReview', displayName: 'Carreer Review', type: 'multiline' },
        currYrDetail: { fieldName: 'currYrDetail', displayName: 'Current Year Detail', type: 'multiline' },
        personal: { fieldName: 'personal', displayName: 'Personal', type: 'multiline' },
        residence: { fieldName: 'residence', displayName: 'Residence', type: 'string' },
        birthplace: { fieldName: 'birthplace', displayName: 'Birthplace', type: 'string' },
        height: { fieldName: 'height', displayName: 'Height', type: 'string' },
        weight: { fieldName: 'weight', displayName: 'Weight', type: 'string' },
        plays: { fieldName: 'plays', displayName: 'Plays', type: 'string' },
        status: { fieldName: 'status', displayName: 'Status', type: 'string' },
        racquet: { fieldName: 'racquet', displayName: 'Racquet', type: 'string' },
        racquetLink: { fieldName: 'racquetLink', displayName: 'RacquetLink', type: 'string' },
        apparel: { fieldName: 'apparel', displayName: 'Apparel', type: 'string' },
        apperalLink: { fieldName: 'apperalLink', displayName: 'ApperalLink', type: 'string' },
        shoes: { fieldName: 'shoes', displayName: 'Shoes', type: 'string' },
        shoesLink: { fieldName: 'shoesLink', displayName: 'ShoesLink', type: 'string' },
        activeFlag: { fieldName: 'activeFlag', displayName: 'ActiveFlag', type: 'string' },
        photoCr: { fieldName: 'photoCr', displayName: 'PhotoCr', type: 'string' },
        updateDate: { fieldName: 'updateDate', displayName: 'Updated', type: 'us-date', readonly: true },
        updateBy: { fieldName: 'updateBy', displayName: 'By', type: 'string', readonly: true },
    },
};
