import axios from 'axios';

export default {
    namespaced: true,

    state: {
        pzCoachAccesses: {},
        loadingPzCoachAccesses: false,
        loadingErrorPzCoachAccesses: null,
    },

    getters: {
        pzCoachAccesses: (state) => state.pzCoachAccesses,
        loadingPzCoachAccesses: (state) => state.loadingPzCoachAccesses,
        loadingErrorPzCoachAccesses: (state) => state.loadingErrorPzCoachAccesses,
    },

    mutations: {
        setPzCoachAccesses(state, data) {
            state.pzCoachAccesses = data;
        },

        startLoadingPzCoachAccesses(state) {
            state.loadingPzCoachAccesses = true;
        },

        stopLoadingPzCoachAccesses(state) {
            state.loadingPzCoachAccesses = false;
        },

        setLoadingErrorPzCoachAccesses(state, error) {
            state.loadingErrorPzCoachAccesses = error;
        },

        clearLoadingErrorPzCoachAccesses(state) {
            state.loadingErrorPzCoachAccesses = null;
        },
    },

    actions: {
        async findPzAccessesForCoaches(context, options = {}) {
            context.commit('startLoadingPzCoachAccesses');
            context.commit('clearLoadingErrorPzCoachAccesses');
            try {
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/access/RetrieveCoaches`, {
                    params: {
                        queryString: options.queryString || '',
                        pageSize: options.pageSize || 50,
                        sortBy: options.sortBy || 'first_name',
                        sortDir: options.sortDir === 'descending' ? -1 : 1,
                        page: options.page
                    },
                });
                context.commit('setPzCoachAccesses', result.data);
                context.commit('stopLoadingPzCoachAccesses');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('setLoadingErrorPzCoachAccesses', error);
                context.commit('stopLoadingPzCoachAccesses');
                return Promise.reject(error);
            }
        },

        async createPzAccessForCoach(context, data) {
            context.commit('startLoadingPzCoachAccesses');
            context.commit('clearLoadingErrorPzCoachAccesses');
            try {
                const result = await axios.post(`${process.env.VUE_APP_API_URL}/access/CreatePzAccessForCoaches`, data.data)
                context.commit('stopLoadingPzCoachAccesses');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('setLoadingError', error);
                context.commit('stopLoadingPzCoachAccesses');
                return Promise.reject(error);
            }
        },

        async updatePzCoachAccess(context, data) {
            context.commit('startLoadingPzCoachAccesses');
            context.commit('clearLoadingErrorPzCoachAccesses');
            try {
                const result = axios.put(`${process.env.VUE_APP_API_URL}/access/UpdatePzAccess`, data.data);
                context.commit('stopLoadingPzCoachAccesses');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('setLoadingError', error);
                context.commit('stopLoadingPzCoachAccesses');
                return Promise.reject(error);
            }
        },

        async deletePzCoachAccess(context, data) {
            context.commit('startLoadingPzCoachAccesses');
            context.commit('clearLoadingErrorPzCoachAccesses');
            try {
                axios.delete(`${process.env.VUE_APP_API_URL}/access/DeletePzAccess/${data.data.username}`);
                context.commit('stopLoadingPzCoachAccesses');
                return Promise.resolve();
            } catch (error) {
                context.commit('setLoadingError', error);
                context.commit('stopLoadingPzCoachAccesses');
                return Promise.reject(error);
            }
        }
    }
}