<template>
    <div>

        <ErrorDisplay :requestError="this.loadingErrorShadowSinglesRanking" @closed="clearError"/>

        <div v-loading="loadingShadowSinglesRanking">
            <DataTable
                    :autoSort="false"
                    :fields="[
                    'rankSingles',
                    'priorRankSingles',
                    'playerId',
                    'fullName',
                    'nationality',
                    'playerBirthDate',
                    'pointsSingles',
                    'tournamentCountSingles',
                    'nextOnSingles',
                    'nextOffSingles',
                    'points16ThSingles',
                    'points17ThSingles',
                    'highestSinglesRank',
                    'highestSinglesRankDate',
                ]"
                    :items="shadowSinglesRankings.data"
                    :schema="schema"
                    @header-clicked="setSort"
            />

            <Paginator
                    v-if="shadowSinglesRankings.data"
                    :data="shadowSinglesRankings"
                    :elementsPerPage="options.pageSize"
                    @prev-page="prevPage"
                    @next-page="nextPage"
                    @set-page-size="setPageSize"
            />
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { retrieveDtos } from '@/utils/crud-confirm';
import { ShadowPlayerRanking } from "@/models/shadow-player-ranking";

export default {
    props: ['playerId', 'shadowRankId'],
    watch: {
        playerId() {
            this.playerSelected({ id: this.playerId });
        },
        shadowRankId() {
            this.shadowRankingSelected({ shadowRankId: this.shadowRankId });
        },
    },
    data() {
        return {
            schema: ShadowPlayerRanking.schema,
            layout: ShadowPlayerRanking.layoutCurrent,
            options: {
                shadowRankId: null,
                queryString: '',
                sortBy: '',
                sortDir: '',
                pageSize: process.env.VUE_APP_PAGE_SIZE,
                page: 0,
            },
        };
    },
    mounted() {
    },
    computed: {
        ...mapGetters('shadowCurrentSingles', [
            'shadowSinglesRankings',
            'loadingShadowSinglesRanking',
            'loadingErrorShadowSinglesRanking'
        ]),
    },
    methods: {
        ...mapActions('shadowCurrentSingles', ['findShadowSinglesRankings']),
        refresh() {
            let params = {
                self: this,
                fRetrieve: this.findShadowSinglesRankings,
                options: this.options,
                dtosName: 'Shadow Singles Rankings',
            };
            retrieveDtos(params);
        },
        playerSelected(player) {
            this.options.page = 0;
            this.options.queryString = player.id;
            if (this.options.shadowRankId)
                this.refresh();
        },
        shadowRankingSelected(ranking) {
            this.options.page = 0;
            this.options.shadowRankId = ranking.shadowRankId;
            this.refresh();
        },
        reset() {
            this.options.page = 0;
            this.options.queryString = '';
            this.refresh();
        },
        prevPage() {
            this.options.page--;
            this.refresh();
        },
        nextPage() {
            this.options.page++;
            this.refresh();
        },
        setPageSize(size) {
            this.options.pageSize = size;
            this.refresh();
        },
        setSort(event) {
            this.options.sortBy = event.property;
            this.options.sortDir = event.order;
            this.refresh();
        },
        clearError() {
            this.loadingErrorShadowSinglesRanking = null;
        },
    },
};
</script>
