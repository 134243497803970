<template>
    <div>
        <DialogEditItem
            :layout="layout"
            :schema="schema"
            :selected="selectedShadowRanking"
            :visibleSync="editDialogVisible"
            dtoName="Shadow Ranking"
            showNav="true"
            @change="onChange"
            @close="onClose"
            @delete="deleteShadowRankingClick"
            @next="gotoNextShadowRanking"
            @prev="gotoPrevShadowRanking"
            @save="saveChanges"
        />

        <DialogAddItem
            :layout="layoutAdd"
            :schema="schema"
            :selected="selectedShadowRanking"
            :visibleSync="addDialogVisible"
            dtoName="Shadow Ranking"
            @change="onChange"
            @close="addDialogVisible = false"
            @save="saveChanges"
        />

        <ErrorDisplay :requestError="requestError" @closed="requestError = null" />

        <div class="flex-row mt-0 mb-32">
            <div slot="header" class="clearfix">
                <span><b>Manage Shadow Rankings</b></span>
            </div>
            <span class="spacer"></span>
            <el-button plain size="mini" type="primary" v-if="!isBaselineReader" @click="addShadowRanking">Add Shadow Ranking</el-button>
        </div>

        <div class="mt-32 mb-32">
            <DataTable
                v-loading="loadingShadowRankingData"
                :autoSort="false"
                :fields="[
                    'shadowRankId',
                    'name',
                    'singlesRankingResultCount',
                    'doublesRankingResultCount',
                    'rankDateFirstTournament',
                    'rankDateLastTournament',
                    'shadowTournamentResultsCount',
                ]"
                :items="shadowRankings"
                :schema="schema"
                @item-clicked="itemClicked"
            />
        </div>

        <el-card class="box-card mb-32 mt-32" shadow="hover">
            <div slot="header" class="clearfix">
                <span><b>Create Shadow Tournament Results</b></span>
            </div>
            <div class="flex-row mt-0 mb-0">
                <el-alert :closable="false" :show-icon="true" class="mr-32" type="info">
                    <div>Creates new shadow tournament results between two given rank dates (inclusively) for all Shadow Rankings.</div>
                    <div>Updates database table shadow_ranking_tourn_results.</div>
                    <div>Per default, dates are set to previous and current Regular Ranking Date but can be changed.</div>
                </el-alert>
                <div class="flex-row">
                    <div>
                        <el-tooltip :disabled="true" :tabindex="-1" placement="top-start">
                            <label>From Date </label>
                        </el-tooltip>
                        <el-date-picker
                            v-model="createShadowTournamentResults.fromDate"
                            :firstDayOfWeek="1"
                            class="mr-16"
                            format="yyyy-MM-dd"
                            placeholder="Select Rank Date"
                            type="date"
                        ></el-date-picker>
                    </div>
                    <div>
                        <el-tooltip :disabled="true" :tabindex="-1" placement="top-start">
                            <label>To Date</label>
                        </el-tooltip>
                        <el-date-picker
                            v-model="createShadowTournamentResults.toDate"
                            :firstDayOfWeek="1"
                            format="yyyy-MM-dd"
                            placeholder="Select Rank Date"
                            type="date"
                        ></el-date-picker>
                    </div>
                </div>
            </div>
            <div class="flex-row mt-0 mb-32">
                <span class="spacer"></span>
                <el-button
                    v-loading="creatingShadowTournamentResults"
                    plain
                    size="big"
                    type="primary"
                    v-if="!isBaselineReader"
                    @click="onClickCreateTournamentResults"
                >
                    Create Shadow Tournament Results
                </el-button>
            </div>
            <ErrorDisplay :requestError="createShadowTournamentResults.requestError" @closed="createShadowTournamentResults.requestError = null" />
        </el-card>

        <el-card class="box-card mb-32 mt-32" shadow="hover">
            <div slot="header" class="clearfix">
                <span><b>Shadow Ranking Procedure</b></span>
            </div>
            <div class="flex-row mt-0 mb-0">
                <el-alert :closable="false" :show-icon="true" class="mr-32" type="info">
                    <div>Creates new ranking for selected ranking date for all Shadow Rankings.</div>
                    <div>Updates database table shadow_rank_current, shadow_rank_history, shadow_team_points, shadow_team_points_history.</div>
                    <div>
                        Per default, Shadow Ranking Dates are set to current and next Regular Ranking Dates but can be changed. Monday will be chosen by
                        default.
                    </div>
                </el-alert>
                <div class="flex-row">
                    <div>
                        <el-tooltip :disabled="true" :tabindex="-1" placement="top-start">
                            <label>Shadow Ranking Date </label>
                        </el-tooltip>
                        <el-date-picker
                            v-model="shadowRankingProcedure.rankingDate"
                            :firstDayOfWeek="1"
                            class="mr-16"
                            format="yyyy-MM-dd"
                            placeholder="Select Current Ranking Date"
                            type="week"
                        ></el-date-picker>
                    </div>
                    <div>
                        <el-tooltip :disabled="true" :tabindex="-1" placement="top-start">
                            <label>Next Shadow Ranking Date</label>
                        </el-tooltip>
                        <el-date-picker
                            v-model="shadowRankingProcedure.nextRankingDate"
                            :firstDayOfWeek="1"
                            format="yyyy-MM-dd"
                            placeholder="Select Next Ranking Date"
                            type="week"
                        ></el-date-picker>
                    </div>
                </div>
            </div>
            <div class="flex-row mt-0 mb-32">
                <span class="spacer"></span>
                <el-button
                    v-loading="runningShadowRankingProcedure"
                    plain
                    size="big"
                    type="primary"
                    v-if="!isBaselineReader"
                    @click="onClickShadowRankingProcedure"
                >
                    Run Shadow Ranking Procedure
                </el-button>
            </div>
        </el-card>

        <el-card class="box-card mb-32 mt-32" shadow="hover">
            <div slot="header" class="clearfix">
                <span><b>Shadow Ranking PDFs</b></span>
            </div>
            <div class="flex-row mt-0 mb-32">
                <el-alert :closable="false" :show-icon="true" class="mr-32" type="info">
                    <div>Download Shadow Ranking PDFs for selected Shadow Rankings: Singles_Numeric.pdf, Doubles_Numeric.pdf, Championship_Leaders.pdf.</div>
                    <div>Downloads are stored in default Download folder. Please allow downloads when asked by Browser.</div>
                </el-alert>
                <span class="spacer"></span>
                <el-select v-model="shadowRankingPdfs.shadowRankId" v-loading="loadingShadowRankings" class="mr-16" placeholder="Select Shadow Ranking">
                    <el-option v-for="item in shadowRankingPdfOptions" :key="item.shadowRankId" :label="item.name" :value="item.shadowRankId"> </el-option>
                </el-select>
                <div>
                    <el-button v-loading="loadingShadowRankingPdfs" plain size="big" type="primary" @click="onClickShadowRankingsPdfs">
                        Download Shadow Ranking PDFs
                    </el-button>
                </div>
            </div>
        </el-card>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { ShadowRanking } from '@/models/shadow-ranking';
import { createConfirmDto, deleteConfirmDto, retrieveDtos, updateConfirmDto } from '@/utils/crud-confirm';
import { isBaselineReader } from '../../services/authGroups';

export default {
    data() {
        return {
            schema: ShadowRanking.schema,
            layout: ShadowRanking.layout,
            layoutAdd: ShadowRanking.layoutAdd,
            editDialogVisible: false,
            addDialogVisible: false,
            tournamentResultsDialogVisible: false,
            selectedShadowRanking: null,
            requestError: null,
            shadowRankingPointer: null,
            mode: null,
            createShadowTournamentResults: {
                fromDate: null,
                toDate: null,
                requestError: null,
            },
            shadowRankingProcedure: {
                rankingDate: null,
                nextRankingDate: null,
                requestError: null,
            },
            shadowRankingPdfs: {
                shadowRankId: 'ALL',
                allOption: {
                    shadowRankId: 'ALL',
                    name: 'ALL',
                },
            },
            isBaselineReader: isBaselineReader(),
        };
    },
    mounted() {
        this.refreshAll();
        this.retrieveWtaSystem()
            .then(() => {
                this.createShadowTournamentResults.fromDate = this.wtaSystem.data.previousRankDate;
                this.createShadowTournamentResults.toDate = this.wtaSystem.data.currentRankDate;
                this.shadowRankingProcedure.rankingDate = this.wtaSystem.data.currentRankDate;
                this.shadowRankingProcedure.nextRankingDate = this.wtaSystem.data.nextRankDate;
            })
            .catch((error) => {
                this.requestError = error;
                this.$notify({
                    title: 'Get WTA System Error',
                    message: error.response.data.Message,
                    type: 'error',
                    duration: 7000,
                });
            });
    },
    computed: {
        ...mapGetters('shadowRankings', [
            'shadowRankings',
            'loadingShadowRankings',
            'loadingErrorShadowRankings',
            'runningShadowRankingProcedure',
            'creatingShadowTournamentResults',
            'creatingErrorShadowTournamentResults',
        ]),
        ...mapGetters('shadowRankingPoints', ['shadowRankingPoints', 'loadingShadowRankingPoints']),
        ...mapGetters('shadowRankingPdfReports', [
            'loadingShadowSinglesNumeric',
            'loadingShadowDoublesNumeric',
            'loadingShadowChampionshipLeaders',
            'loadingError',
        ]),
        ...mapGetters('wtaSystem', ['wtaSystem', 'loadingWtaSystem']),
        loadingShadowRankingData() {
            return this.loadingShadowRankings || this.loadingShadowRankingPoints;
        },
        loadingShadowRankingPdfs() {
            return (
                Object.keys(this.loadingShadowSinglesNumeric).length > 0 ||
                Object.keys(this.loadingShadowDoublesNumeric).length > 0 ||
                Object.keys(this.loadingShadowChampionshipLeaders).length > 0
            );
        },
        shadowRankingPdfOptions() {
            return [this.shadowRankingPdfs.allOption].concat(this.shadowRankings);
        },
    },
    methods: {
        ...mapActions('shadowRankings', [
            'getAllShadowRankings',
            'createShadowRanking',
            'updateShadowRanking',
            'deleteShadowRanking',
            'runShadowRankingProcedure',
            'createShadowRankingTournamentResults',
        ]),
        ...mapActions('shadowRankingPoints', ['findShadowRankingPoints', 'clearShadowRankingPoints']),
        ...mapActions('shadowRankingPdfReports', ['getShadowSinglesNumeric', 'getShadowDoublesNumeric', 'getShadowChampionshipLeaders']),
        ...mapActions('wtaSystem', ['retrieveWtaSystem']),

        refreshAll() {
            this.clearShadowRankingPoints();
            let params = {
                self: this,
                fRetrieve: this.getAllShadowRankings,
                dtosName: 'Shadow Rankings',
                callback: this.retrieveCallback,
            };
            retrieveDtos(params);
        },
        retrieveCallback() {
            this.shadowRankings.forEach((rankings) => {
                let params = {
                    self: this,
                    fRetrieve: this.findShadowRankingPoints,
                    options: rankings,
                    dtosName: 'Shadow Ranking Points',
                };
                retrieveDtos(params);
            });
        },

        refresh() {
            let params = {
                self: this,
                fRetrieve: this.getAllShadowRankings,
                dtosName: 'Shadow Rankings',
            };
            retrieveDtos(params);
        },

        itemClicked(item, index) {
            this.selectedShadowRanking = new ShadowRanking(item);
            this.mode = 'edit';
            this.editDialogVisible = true;
            this.shadowRankingPointer = index;
        },

        addShadowRanking() {
            this.selectedShadowRanking = new ShadowRanking();
            this.mode = 'add';
            this.addDialogVisible = true;
        },

        onClickCreateTournamentResults() {
            this.createShadowTournamentResults.requestError = null;
            this.$confirm('You are about to create Shadow Tournament Results. Please confirm.', 'Create Shadow Tournament Results', {
                confirmButtonText: 'Create Shadow Tournament Results',
                cancelButtonText: 'Cancel',
                type: 'warning',
            })
                .then(() => {
                    this.createShadowRankingTournamentResults(this.createShadowTournamentResults)
                        .then((result) => {
                            this.$notify({
                                title: 'Create Shadow Tournament Results',
                                message: result + ' Shadow Tournament Results created successfully',
                                type: 'success',
                                duration: 7000,
                            });
                            this.refresh();
                        })
                        .catch((error) => {
                            this.createShadowTournamentResults.requestError = error;
                            this.$notify({
                                title: 'Create Shadow Tournament Results',
                                message: error.response.data.Message || error,
                                type: 'error',
                                duration: 9000,
                            });
                        });
                })
                .catch((e) => {});
        },

        onClickShadowRankingProcedure() {
            this.requestError = null;
            this.$confirm('You are about to run Shadow Ranking procedure. Please confirm.', 'Shadow Ranking procedure', {
                confirmButtonText: 'Run Shadow Ranking procedure',
                cancelButtonText: 'Cancel',
                type: 'warning',
            })
                .then(() => {
                    this.runShadowRankingProcedure(this.shadowRankingProcedure)
                        .then((result) => {
                            this.$notify({
                                title: 'Shadow Ranking procedure',
                                message: 'Shadow Ranking procedure finished successfully',
                                type: 'success',
                                duration: 7000,
                            });
                            this.refresh();
                        })
                        .catch((error) => {
                            this.$notify({
                                title: 'Shadow Ranking procedure',
                                message: error.response.data.Message || error,
                                type: 'error',
                                duration: 9000,
                            });
                        });
                })
                .catch((e) => {});
        },

        onClickShadowRankingsPdfs() {
            if (this.loadingShadowRankingPdfs) return;
            this.shadowRankings
                .filter((shadowRanking) => this.shadowRankingPdfs.shadowRankId === 'ALL' || shadowRanking.shadowRankId === this.shadowRankingPdfs.shadowRankId)
                .forEach((shadowRanking) => {
                    this.downloadPdf(shadowRanking.shadowRankId, this.getShadowSinglesNumeric, `Shadow_Singles_Numeric_${shadowRanking.name}.pdf`);
                    this.downloadPdf(shadowRanking.shadowRankId, this.getShadowDoublesNumeric, `Shadow_Doubles_Numeric_${shadowRanking.name}.pdf`);
                    this.downloadPdf(shadowRanking.shadowRankId, this.getShadowChampionshipLeaders, `Shadow_Championship_Leaders_${shadowRanking.name}.pdf`);
                });
        },

        downloadPdf(shadowRankId, fDownload, filename) {
            let options = {
                shadowRankId: shadowRankId,
            };
            this.requestError = null;
            fDownload(options)
                .then((result) => {
                    const file = new Blob([result], { type: 'application/pdf' });
                    saveAs(file, filename);
                    this.$notify({
                        title: 'Shadow Rankings PDFs download successful',
                        message: filename + ' downloaded',
                        type: 'success',
                        duration: 5000,
                    });
                })
                .catch((error) => {
                    this.requestError = error;

                    this.$notify({
                        title: 'Shadow Rankings PDFs download failed',
                        message: this.loadingError.Message,
                        type: 'error',
                        clickToHide: true,
                        duration: 80000,
                    });
                });
        },

        saveChanges() {
            if (this.mode === 'edit') {
                let params = {
                    self: this,
                    fUpdate: this.updateShadowRanking,
                    dto: this.selectedShadowRanking,
                    dtoName: 'Shadow Ranking',
                    callback: this.editCallback,
                };
                updateConfirmDto(params);
            } else if (this.mode === 'add') {
                let params = {
                    self: this,
                    fCreate: this.createShadowRanking,
                    dto: this.selectedShadowRanking,
                    dtoName: 'Shadow Ranking',
                    callback: this.crudCallback,
                };
                createConfirmDto(params);
            }
        },

        deleteShadowRankingClick() {
            let params = {
                self: this,
                fDelete: this.deleteShadowRanking,
                vConfirm: this.selectedShadowRanking.data.shadowRankId,
                dto: this.selectedShadowRanking,
                dtoName: 'ShadowRanking',
                vConfirmName: 'Shadow Rank ID',
                callback: this.crudCallback,
            };
            deleteConfirmDto(params);
        },

        editCallback() {
            this.requestError = null;
            this.addDialogVisible = false;
            this.editDialogVisible = false;
            this.refresh();
        },

        crudCallback() {
            this.requestError = null;
            this.addDialogVisible = false;
            this.editDialogVisible = false;
            this.refreshAll();
        },

        onClose() {
            this.editDialogVisible = false;
        },
        onChange() {},
        clearError() {
            this.requestError = null;
        },

        gotoPrevShadowRanking() {
            if (this.shadowRankingPointer === 0) {
                return;
            }
            this.shadowRankingPointer -= 1;
            this.selectedShadowRanking = new ShadowRanking(this.shadowRankings[this.shadowRankingPointer]);
        },

        gotoNextShadowRanking() {
            if (this.shadowRankingPointer === this.shadowRankings.length - 1) {
                return;
            }
            this.shadowRankingPointer += 1;
            this.selectedShadowRanking = new ShadowRanking(this.shadowRankings[this.shadowRankingPointer]);
        },
    },
};
</script>

<style scoped>
.w-80 {
    margin: auto;
    width: 80%;
}
</style>
