import axios from 'axios';

export default {
    namespaced: true,

    state: {
        tournamentRounds: [],
        loadingTournamentRounds: false,
        loadingError: null,
        savingTournamentRound: false,
        savingError: null,
        deletingTournamentRound: false,
        deletingError: null,
        runningTournRoundSplit: false,
        progress: 0
    },

    getters: {
        tournamentRounds: (state) => state.tournamentRounds,
        loadingTournamentRounds: (state) => state.loadingTournamentRounds,
        loadingError: (state) => state.loadingError,
        savingTournamentRound: (state) => state.savingTournamentRound,
        savingError: (state) => state.savingError,
        deletingTournamentRound: (state) => state.deletingTournamentRound,
        deletingError: (state) => state.deletingError,
        runningTournRoundSplit: (state) => state.runningTournRoundSplit,
        tournResultProgress: (state) => state.progress
    },

    mutations: {
        setTournamentRounds(state, data) {
            state.tournamentRounds = data;
        },

        startLoadingTournamentRounds(state) {
            state.loadingTournamentRounds = true;
        },

        stopLoadingTournamentRounds(state) {
            state.loadingTournamentRounds = false;
        },

        setLoadingError(state, error) {
            state.loadingError = error;
        },

        setTournamentRoundAtIndex(state, data) {
            state.tournamentRounds[data.index] = data.tournamentRound;
        },

        clearLoadingError(state) {
            state.loadingError = null;
        },

        startSavingTournamentRound(state) {
            state.savingTournamentRound = true;
        },

        stopSavingTournamentRound(state) {
            state.savingTournamentRound = false;
        },

        setSavingError(state, error) {
            state.savingError = error;
        },

        clearSavingError(state) {
            state.savingError = null;
        },

        startDeletingTournamentRound(state) {
            state.deletingTournamentRound = true;
        },

        stopDeletingTournamentRound(state) {
            state.deletingTournamentRound = false;
        },

        setDeletingError(state, error) {
            state.deletingError = error;
        },

        clearDeletingError(state) {
            state.deletingError = null;
        },
        startTournRoundSplit(state) {
            state.runningTournRoundSplit = true;
        },
        stopTournRoundSplit(state) {
            state.runningTournRoundSplit = false;
        },
        setProgress(state, progress) {
            state.progress = progress;
        },
        clearProgress(state) {
            state.progress = 0;
        },
    },

    actions: {
        async findTournamentRounds(context, tournament) {
            context.commit('startLoadingTournamentRounds');
            try {
                const round = await axios.get(`${process.env.VUE_APP_API_URL}/Edition/${tournament.year}/${tournament.id}/Round`);
                context.commit('stopLoadingTournamentRounds');
                context.commit('setTournamentRounds', round.data);
                context.commit('setLoadingError', null);
                return Promise.resolve(round.data);
            } catch (error) {
                context.commit('stopLoadingTournamentRounds');
                context.commit('setTournamentRounds', []);
                context.commit('setLoadingError', error);
                return Promise.reject(error);
            }
        },

        async createTournamentRound(context, data) {
            context.commit('startSavingTournamentRound');
            try {
                const round = await axios.post(`${process.env.VUE_APP_API_URL}/Edition/Round`, data.data);
                context.commit('stopSavingTournamentRound');
                return Promise.resolve(round.data);
            } catch (error) {
                context.commit('stopSavingTournamentRound');
                context.commit('setSavingError', error);
                return Promise.reject(error);
            }
        },

        async updateTournamentRound(context, data) {
            context.commit('startSavingTournamentRound');
            if(data.data.roundName === undefined || data.data.roundName === null || data.data.roundName === '')
            {
                context.commit('stopSavingTournamentRound');
                return Promise.reject({ message: 'Round Name required' });
            }
            const keys = Object.keys(data.data);
            try {
                const round = await axios.put(`${process.env.VUE_APP_API_URL}/Edition/Round`, data.data);
                context.commit('stopSavingTournamentRound');
                return Promise.resolve(round.data);
            } catch (error) {
                context.commit('stopSavingTournamentRound');
                context.commit('setSavingError', error);
                return Promise.reject(error);
            }
        },

        async deleteTournamentRound(context, data) {
            context.commit('startDeletingTournamentRound');
            try {
                const round = await axios.post(`${process.env.VUE_APP_API_URL}/Edition/Round/Delete`, data.data);
                context.commit('stopDeletingTournamentRound');
                return Promise.resolve(round.data);
            } catch (error) {
                context.commit('stopDeletingTournamentRound');
                context.commit('setDeletingError', error);
                return Promise.reject(error);
            }
        },

        async deleteTournamentRounds(context, data) {
            context.commit('startDeletingTournamentRound');
            try {
                const count = await axios.delete(`${process.env.VUE_APP_API_URL}/Edition/${data.season}/${data.tournamentId}/Round`);
                context.commit('stopDeletingTournamentRound');
                return Promise.resolve(count.data);
            } catch (error) {
                context.commit('stopDeletingTournamentRound');
                context.commit('setDeletingError', error);
                return Promise.reject(error);
            }
        },

        async deleteAllRoundResults(context, data) {
            context.commit('startDeletingTournamentRound');
            try {
                const count = await axios.delete(`${process.env.VUE_APP_API_URL}/Edition/${data.season}/${data.tournamentId}/AllRoundResults`);
                context.commit('stopDeletingTournamentRound');
                return Promise.resolve(count.data);
            } catch (error) {
                context.commit('stopDeletingTournamentRound');
                context.commit('setDeletingError', error);
                return Promise.reject(error);
            }
        },

        async propagateData(context, data) {
            context.commit('startLoadingTournamentRounds');
            try {
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/Edition/${data.season}/${data.tournamentId}/Round/DataPropagation`);
                context.commit('stopLoadingTournamentRounds');
                context.commit('setLoadingError', null);
                context.commit('setTournamentRounds', result.data);
                return Promise.resolve(result);
            } catch (error) {
                context.commit('stopLoadingTournamentRounds');
                context.commit('setLoadingError', error);
                return Promise.reject(error);
            }
        },

        async checkTournamentRoundsData(context, data) {
            context.commit('startLoadingTournamentRounds');
            try {
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/Edition/${data.season}/${data.tournamentId}/Round/DataCheck`);
                context.commit('stopLoadingTournamentRounds');
                context.commit('setLoadingError', null);
                context.commit('setTournamentRounds', result.data);
                return Promise.resolve(result);
            } catch (error) {
                context.commit('stopLoadingTournamentRounds');
                context.commit('setLoadingError', error);
                return Promise.reject(error);
            }
        },

        async downloadItfFile(context, data) {
            context.commit('startLoadingTournamentRounds');
            try {
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/Edition/${data.season}/${data.tournamentId}/Round/Import/DownloadItfFile`);
                context.commit('stopLoadingTournamentRounds');
                context.commit('setLoadingError', null);
                return Promise.resolve(result);
            } catch (error) {
                context.commit('stopLoadingTournamentRounds');
                context.commit('setLoadingError', error);
                return Promise.reject(error);
            }
        },
        setTournamentRoundAtIndex(context, data) {
            context.commit('setTournamentRoundAtIndex', data);
        },

        async createEmptyTournamentRounds(context, data) {
            context.commit('startLoadingTournamentRounds');
            try {
                const round = await axios.get(`${process.env.VUE_APP_API_URL}/Edition/${data.season}/${data.tournamentId}/Round/CreateEmpty`);
                context.commit('stopLoadingTournamentRounds');
                context.commit('setLoadingError', null);
                return Promise.resolve(round.data);
            } catch (error) {
                context.commit('stopLoadingTournamentRounds');
                context.commit('setLoadingError', error);
                return Promise.reject(error);
            }
        },

        async runTournRoundSplit(context, data) {
            context.commit('startTournRoundSplit');
            context.commit('clearProgress');
            try {
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/Edition/${data.season}/Round/Split`);
                context.commit('stopTournRoundSplit');
                context.commit('setLoadingError', null);
                return Promise.resolve(result);
            } catch (error) {
                context.commit('stopTournRoundSplit');
                context.commit('setLoadingError', error);
                return Promise.reject(error);
            }
        },
    },
};
