var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('ErrorDisplay',{attrs:{"requestError":this.loadingErrorShadowChampionshipSinglesRankings},on:{"closed":_vm.clearError}}),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loadingShadowChampionshipSinglesRankings),expression:"loadingShadowChampionshipSinglesRankings"}]},[_c('DataTable',{attrs:{"autoSort":false,"fields":[
                    'rankChampionshipSingles',
                    'playerId',
                    'fullName',
                    'nationality',
                    'playerBirthDate',
                    'pointsChampionshipSingles'
                ],"items":_vm.shadowChampionshipSinglesRankings.data,"schema":_vm.schema},on:{"header-clicked":_vm.setSort}}),(_vm.shadowChampionshipSinglesRankings.data)?_c('Paginator',{attrs:{"data":_vm.shadowChampionshipSinglesRankings,"elementsPerPage":_vm.options.pageSize},on:{"prev-page":_vm.prevPage,"next-page":_vm.nextPage,"set-page-size":_vm.setPageSize}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }