export default {
    namespaced: true,

    state: {
        selectedPlayerFullName: null,
    },

    getters: {
        selectedPlayerFullName: (state) => state.selectedPlayerFullName,
    },

    mutations: {
        setPlayerFullName(state, name) {
            state.selectedPlayerFullName = name;
        },
    },

    actions: {
        setSelectedPlayerFullName(context, name) {
            context.commit('setPlayerFullName', name);
        },
    },
};
