<template>
    <div>
        <table class="summary-table mb-16" v-if="summary && summary.allResults">
            <tr>
                <td>
                    <div>Combined Total Points</div>
                    <div>from Grand Slams, Premier Mandatory, Premier 5 and WTA Finals</div>
                </td>
                <td>{{ summary.combinedPointsGsPmP5Finals }}</td>
            </tr>
            <tr>
                <td>
                    <div>Total WTA Points</div>
                    <div>from Grand Slams, WTA Tournaments and WTA Finals</div>
                </td>
                <td>{{ summary.totalWtaPoints }}</td>
            </tr>
        </table>
    </div>
</template>

<script>
export default {
    props: ['summary'],
    data() {
        return {};
    },
    mounted() {},
    computed: {},
    methods: {},
};
</script>
