var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('DialogAddItem',{attrs:{"visibleSync":_vm.addDialogVisible,"dtoName":"Tournament Point","selected":_vm.selectedTournamentPoint,"schema":_vm.schemaAdd,"layout":_vm.layout},on:{"save":_vm.addItem,"change":_vm.onChange,"close":function($event){_vm.addDialogVisible = false}}}),_c('DialogEditItem',{attrs:{"visibleSync":_vm.editDialogVisible,"dtoName":"Tournament Point","selected":_vm.selectedTournamentPoint,"schema":_vm.schema,"layout":_vm.layout},on:{"save":_vm.updateItem,"change":_vm.onChange,"delete":_vm.deleteItem,"close":function($event){_vm.editDialogVisible = false}}}),_c('div',{staticClass:"flex-row mt-0 mb-32"},[_vm._m(0),_c('span',{staticClass:"spacer"}),_c('el-input',{staticClass:"input-search mr-16",attrs:{"placeholder":"Filter by Id or table name","size":"mini"},on:{"change":_vm.resetPage},nativeOn:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;return _vm.reset.apply(null, arguments)}},model:{value:(_vm.options.queryString),callback:function ($$v) {_vm.$set(_vm.options, "queryString", $$v)},expression:"options.queryString"}},[_c('el-button',{attrs:{"slot":"append","icon":"el-icon-search"},on:{"click":_vm.resetPage},slot:"append"})],1),(!_vm.isBaselineReader)?_c('el-button',{attrs:{"plain":"","size":"mini","type":"primary"},on:{"click":_vm.addTournamentPoint}},[_vm._v(" Add Tournament Point ")]):_vm._e(),_c('el-button',{staticClass:"ml-8",attrs:{"plain":"","size":"mini"},on:{"click":_vm.exportCsvClick}},[_vm._v("Export CSV")])],1),_c('ErrorDisplay',{attrs:{"requestError":this.requestError},on:{"closed":_vm.clearError}}),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loadingTournamentPoints),expression:"loadingTournamentPoints"}]},[_c('DataTable',{attrs:{"items":_vm.tournamentPoints.data,"schema":_vm.schema,"autoSort":false,"fields":[
                'pointId',
                'tableName',
                'pointsWin',
                'pointsRun',
                'pointsSemi',
                'pointsQtr',
                'points16',
                'points32',
                'points64',
                'points128',
                'points256',
            ],"sortField":_vm.options.sortBy,"sortDir":_vm.options.sortDir},on:{"header-clicked":_vm.setSort,"item-clicked":_vm.itemClicked}}),(_vm.tournamentPoints.data)?_c('Paginator',{attrs:{"data":_vm.tournamentPoints,"elementsPerPage":_vm.options.pageSize},on:{"prev-page":_vm.prevPage,"next-page":_vm.nextPage,"set-page-size":_vm.setPageSize}}):_vm._e()],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h2',[_c('i',{staticClass:"el-icon-medal mr-16"}),_vm._v("Tournament Points")])
}]

export { render, staticRenderFns }