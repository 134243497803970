<template>
    <div>
        <h4>{{ title }}</h4>
        <DataTable
            :items="items"
            :fields="[
                'tournamentEndDate',
                'tournamentName',
                'singlePriceMoney',
                'doublePriceMoney',
                'subtotal',
                'tax',
                'wtaMembership',
                'wtaAdminFee',
                'wtaMassageFee',
                'wtaFines',
                'stringerFees',
                'other',
                'totalDeduction',
                'netPayment',
            ]"
            :schema="schema"
            :autoSort="false"
            :rowPointer="false"
        />
    </div>
</template>

<script>
import { PlayerPayment } from '../models/player-payment';

export default {
    props: ['title', 'items', 'tournNbr'],
    data() {
        return {
            schema: PlayerPayment.schema,
        };
    },
};
</script>