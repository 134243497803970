import axios from 'axios';
import { buildParams } from '../../utils/query-param-builder';

export default {
    namespaced: true,

    state: {
        rankingsWtaAndGs: {},
        loadingRankingWtaAndGs: false,
        loadingErrorWtaAndGs: null,
    },

    getters: {
        rankingsWtaAndGs: (state) => state.rankingsWtaAndGs,
        loadingRankingWtaAndGs: (state) => state.loadingRankingWtaAndGs,
        loadingErrorWtaAndGs: (state) => state.loadingErrorWtaAndGs,
    },

    mutations: {
        setRankingsWtaAndGs(state, data) {
            state.rankingsWtaAndGs = data;
        },

        startLoadingRankingsWtaAndGs(state) {
            state.loadingRankingWtaAndGs = true;
        },

        stopLoadingRankingsWtaAndGs(state) {
            state.loadingRankingWtaAndGs = false;
        },

        startLoadingRankings(state) {
            state.loadingRankingWtaAndGs = true;
        },

        stopLoadingRankings(state) {
            state.loadingRankingWtaAndGs = false;
        },

        setLoadingErrorWtaAndGs(state, error) {
            state.loadingErrorWtaAndGs = error;
        },

        clearLoadingErrorWtaAndGs(state) {
            state.loadingErrorWtaAndGs = null;
        },

        setLoadingError(state, error) {
            state.loadingErrorWtaAndGs = error;
        },

        clearLoadingError(state) {
            state.loadingErrorWtaAndGs = null;
        },
    },

    actions: {
        async findRaceSinglesRankingsWtaAndGs(context, options = {}) {
            context.commit('startLoadingRankingsWtaAndGs');
            context.commit('clearLoadingErrorWtaAndGs');
            let params = buildParams(options);
            try {
                var result = await axios.get(`${process.env.VUE_APP_API_URL}/RaceRanking/Current`, { params });
                context.commit('setRankingsWtaAndGs', result.data);
                context.commit('stopLoadingRankingsWtaAndGs');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('setRankingsWtaAndGs', {});
                context.commit('setLoadingErrorWtaAndGs', error);
                context.commit('stopLoadingRankingsWtaAndGs');
                return Promise.reject(error);
            }
        },
        async createRaceSinglesRanking(context, data) {
            context.commit('startLoadingRankings');
            context.commit('clearLoadingError');
            try {
                await axios.post(`${process.env.VUE_APP_API_URL}/RaceRanking/Current`, data.data);
                context.commit('stopLoadingRankings');
                return Promise.resolve();
            } catch (error) {
                context.commit('setLoadingError', error);
                context.commit('stopLoadingRankings');
                return Promise.reject(error);
            }
        },
        async update(context, data) {
            context.commit('startLoadingRankings');
            context.commit('clearLoadingError');
            try {
                await axios.put(`${process.env.VUE_APP_API_URL}/RaceRanking/Current`, data.data);
                context.commit('stopLoadingRankings');
                return Promise.resolve();
            } catch (error) {
                context.commit('setLoadingError', error);
                context.commit('stopLoadingRankings');
                return Promise.reject(error);
            }
        },
        async delete(context, data) {
            context.commit('startLoadingRankings');
            context.commit('clearLoadingError');
            let playerId = data.data.playerId;
            let raceId = data.data.raceId;
            try {
                await axios.delete(`${process.env.VUE_APP_API_URL}/RaceRanking/Current/PlayerId/${playerId}/RaceId/${raceId}`);
                context.commit('stopLoadingRankings');
                return Promise.resolve(true);
            } catch (error) {
                context.commit('setLoadingError', error);
                context.commit('stopLoadingRankings');
                return Promise.reject(error);
            }
        },
    },
};
