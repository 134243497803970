import { Model } from './model';
import { icoCountries } from '../utils/country-codes';

export class AcceptanceListSinglesEntry extends Model {}

export class AcceptanceListDoublesEntry extends Model {}

AcceptanceListSinglesEntry.schema = {
    fields: {
        position: {
            type: 'number',
            displayName: 'Pos',
            width: '100%',
        },
        membership: {
            type: 'string',
            displayName: ' ',
            width: '100%',
        },
        playerName: {
            type: 'string',
            displayName: 'Player',
            width: '500%',
        },
        nationality: {
            displayName: 'Nationality',
            type: 'nationality',
            lookup: Object.keys(icoCountries),
        },
        entryRank: {
            type: 'number',
            displayName: 'Entry rank',
        },
        preference: {
            type: 'string',
            displayName: 'Preference',
        },
        within: {
            type: 'string',
            displayName: 'Within',
        },
        comment: {
            type: 'string',
            displayName: 'Comment',
            tdCss: 'white-space: unset !important; text-overflow:ellipsis; overflow: hidden;max-width: 10em;',
        },
    },
};

AcceptanceListSinglesEntry.schemaCurrentRanks = {
    fields: {
        position: {
            type: 'number',
            displayName: 'Pos',
            width: '100%',
        },
        membership: {
            type: 'string',
            displayName: ' ',
            width: '100%',
        },
        playerName: {
            type: 'string',
            displayName: 'Player',
            width: '500%',
        },
        nationality: {
            displayName: 'Nationality',
            type: 'nationality',
            lookup: Object.keys(icoCountries),
        },
        currentRank: {
            type: 'number',
            displayName: 'Current rank',
        },
        preference: {
            type: 'string',
            displayName: 'Preference',
        },
        within: {
            type: 'string',
            displayName: 'Within',
        },
        comment: {
            type: 'string',
            displayName: 'Comment',
            tdCss: 'white-space: nowrap; text-overflow:ellipsis; overflow: hidden;max-width: 10em;',
        },
    },
};

AcceptanceListDoublesEntry.schema = {
    fields: {
        position: {
            type: 'number',
            displayName: 'Pos',
            width: '100%',
        },
        combinedRank: {
            type: 'number',
            displayName: 'Combined rank',
            width: '150%',
        },
        playerMembership: {
            type: 'string',
            displayName: ' ',
            width: '100%',
        },
        playerName: {
            type: 'string',
            displayName: 'Player',
        },
        playerNationality: {
            displayName: 'Nat.',
            type: 'nationality',
            lookup: Object.keys(icoCountries),
            width: '100%',
        },
        playerRank: {
            type: 'number',
            displayName: 'Doubles rank',
            width: '150%',
        },
        partnerMembership: {
            type: 'string',
            displayName: ' ',
            width: '100%',
        },
        partnerName: {
            type: 'string',
            displayName: 'Partner',
        },
        partnerNationality: {
            displayName: 'Nat.',
            type: 'nationality',
            lookup: Object.keys(icoCountries),
            width: '100%',
        },
        partnerRank: {
            type: 'number',
            displayName: 'Doubles rank',
            width: '150%',
        },
    },
};

AcceptanceListDoublesEntry.schemaCurrentRanks = {
    fields: {
        position: {
            type: 'number',
            displayName: 'Pos',
            width: '100%',
        },
        currentCombinedRank: {
            type: 'number',
            displayName: 'Combined Rank',
            width: '150%',
        },
        playerMembership: {
            type: 'string',
            displayName: ' ',
            width: '100%',
        },
        playerName: {
            type: 'string',
            displayName: 'Player',
        },
        playerNationality: {
            displayName: 'Nat.',
            type: 'nationality',
            lookup: Object.keys(icoCountries),
            width: '100%',
        },
        playerRank: {
            type: 'number',
            displayName: 'Doubles rank',
            width: '150%',
        },
        partnerMembership: {
            type: 'string',
            displayName: ' ',
            width: '100%',
        },
        partnerName: {
            type: 'string',
            displayName: 'Partner',
        },
        partnerNationality: {
            displayName: 'Nat.',
            type: 'nationality',
            lookup: Object.keys(icoCountries),
            width: '100%',
        },
        partnerRank: {
            type: 'number',
            displayName: 'Doubles rank',
            width: '150%',
        },
    },
};
