<template>
    <div class="tournament_info_general" style="margin-top:1em;padding-top:3em">
        <div class="title">{{ editionData.name }}</div>
        <div class="printText level">{{ editionData.level }}</div>
        <div class="details1">
            <span>{{ dateFormat(editionData.startDate, 'yyyy-mm-dd') }}</span>
            <span class="printText">{{ expandedMode }} Draw</span>
            <span class="printText">{{ currencyFormat(editionData.prizeMoney) }} ({{ currencyFormat(editionData.commitment) }}) {{ expandedCurrency }}</span>
        </div>
        <div class="details2">
            <span>{{ editionData.countryCode }}</span>
            <span>Director: {{ editionData.director }}</span>
        </div>
    </div>
</template>

<script>
import DateFormat from 'dateformat';

export default {
    props: {
        editionData: null,
        mode: null
    },
    data() {
        return {};
    },
    computed: {
        currencyIso4217: function() {
            if (this.editionData == null) return null;

            switch (this.editionData.currency) {
                case 'P':
                    return 'GBP';
                case 'D':
                    return 'USD';
                case 'E':
                    return 'EUR';
                default:
                    return 'USD';
            }
        },
        expandedMode: function() {
            return this.mode == 'S' ? 'Singles' : 'Doubles';
        },
        expandedCurrency: function() {
            switch (this.editionData.currency) {
                case 'P':
                    return 'Pounds';
                case 'D':
                    return 'Dollars';
                case 'E':
                    return 'Euros';
                default:
                    return 'Dollars';
            }
        },
    },
    methods: {
        currencyFormat(value) {
            if (value == null || this.currencyIso4217 == null) return null;

            return new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: this.currencyIso4217,
            }).format(value);
        },
        dateFormat(value, format) {
            if (value == undefined || format == undefined || value == null || format == null) return null;

            return DateFormat(value, format);
        }
    },
};
</script>

<style>
    .tournament_info_general {
        margin-top: 25px;
        margin-bottom: 20px;
        padding-top: 25px;
    }
    .tournament_info_general .title {
        font-size: 1.875rem;
        color: #666;
        font-weight: 700;
        padding-bottom: 0px;
    }
    .tournament_info_general .level {
        margin-top: 10px;
    }
    .tournament_info_general .details2 {
        font-size: 1rem;
        margin-top: 10px;
    }

    .tournament_info_general .details1 {
        font-size: 1rem;
        margin-top: 30px;
    }
    .tournament_info_general .details1 span:not(:last-child) {
        margin-right: 10px;
        padding-right: 10px;
        border-right: thin solid #666;
    }

    .tournament_info_general .details2 span:not(:last-child) {
        margin-right: 10px;
        padding-right: 10px;
        border-right: thin solid #666;
    }
</style>
