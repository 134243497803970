import axios from 'axios';

export default {
    namespaced: true,

    state: {
        playerBonuses: [],
        loadingPlayerBonuses: false,
        loadingError: null,
        savingPlayerBonus: false,
        savingError: null,
        deletingPlayerBonus: false,
        deletingError: null,
    },

    getters: {
        playerBonuses: (state) => state.playerBonuses,
        loadingPlayerBonuses: (state) => state.loadingPlayerBonuses,
        loadingError: (state) => state.loadingError,
        savingPlayerBonus: (state) => state.savingPlayerBonus,
        savingError: (state) => state.savingError,
        deletingPlayerBonus: (state) => state.deletingPlayerBonus,
        deletingError: (state) => state.deletingError,
    },

    mutations: {
        setPlayerBonuses(state, data) {
            state.playerBonuses = data;
        },

        startLoadingPlayerBonuses(state) {
            state.loadingPlayerBonuses = true;
        },

        stopLoadingPlayerBonuses(state) {
            state.loadingPlayerBonuses = false;
        },

        setLoadingError(state, error) {
            state.loadingError = error;
        },

        clearLoadingError(state) {
            state.loadingError = null;
        },

        startSavingPlayerBonus(state) {
            state.savingPlayerBonus = true;
        },

        stopSavingPlayerBonus(state) {
            state.savingPlayerBonus = false;
        },

        setSavingError(state, error) {
            state.savingError = error;
        },

        clearSavingError(state) {
            state.savingError = null;
        },

        startDeletingPlayerBonus(state) {
            state.deletingPlayerBonus = true;
        },

        stopDeletingPlayerBonus(state) {
            state.deletingPlayerBonus = false;
        },

        setDeletingError(state, error) {
            state.deletingError = error;
        },

        clearDeletingError(state) {
            state.deletingError = null;
        },
    },

    actions: {
        async findPlayerBonuses(context, playerId) {
            context.commit('startLoadingPlayerBonuses');
            try {
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/PlayerBonus`, {
                    params: {
                        playerId,
                    },
                });
                context.commit('stopLoadingPlayerBonuses');
                context.commit('setPlayerBonuses', result.data);
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('stopLoadingPlayerBonuses');
                context.commit('setLoadingError', error);
                return Promise.reject(error);
            }
        },

        async createPlayerBonus(context, data) {
            context.commit('startSavingPlayerBonus');
            try {
                const result = await axios.post(`${process.env.VUE_APP_API_URL}/PlayerBonus`, data.data);
                context.commit('stopSavingPlayerBonus');
                // context.commit('setPlayerBonus', new PlayerBonus(flatten(result.data)));
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('stopSavingPlayerBonus');
                context.commit('setSavingError', error);
                return Promise.reject(error);
            }
        },

        async updatePlayerBonus(context, data) {
            context.commit('startSavingPlayerBonus');
            try {
                const result = await axios.put(`${process.env.VUE_APP_API_URL}/PlayerBonus`, {
                    oldPlayerBonus: data.original,
                    newPlayerBonus: data.data,
                });
                context.commit('stopSavingPlayerBonus');
                // context.commit('setPlayerBonus', new PlayerBonus(flatten(result.data)));
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('stopSavingPlayerBonus');
                context.commit('setSavingError', error);
                return Promise.reject(error);
            }
        },

        async deletePlayerBonus(context, data) {
            context.commit('startDeletingPlayerBonus');
            try {
                await axios.post(`${process.env.VUE_APP_API_URL}/PlayerBonus/Delete`, data.data);
                context.commit('stopDeletingPlayerBonus');
                // context.commit('setPlayerBonuses', new PlayerBonus(flatten(playerBonus.data)));
                return Promise.resolve();
            } catch (error) {
                context.commit('stopDeletingPlayerBonus');
                context.commit('setDeletingError', error);
                return Promise.reject(error);
            }
        },

        async exportCsv(context, options = {}) {
            context.commit('startLoadingPlayerBonuses');
            try {
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/PlayerBonus/export/csv`, {
                    params: {
                        playerId: options.playerId,
                    },
                });
                context.commit('stopLoadingPlayerBonuses');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('stopLoadingPlayerBonuses');
                return Promise.reject(error);
            }
        },
    },
};
