import AcceptanceList from './components/AcceptanceList.vue';
import AddPlayer from './components/AddPlayer.vue';
import AddPlayerSupport from './components/AddPlayerSupport.vue';
import AddTournament from './components/AddTournament.vue';
import AgeEligibilityReports from './components/age-eligibility/AgeEligibilityReports';
import CoronaRanking from './components/RankingCoronaDropDates.vue';
import CurrentRanking from './components/ranking-current/CurrentRanking.vue';
import Home from './components/Home.vue';
import MainWrapper from './components/MainWrapper.vue';
import PaymentDataReports from './components/PaymentDataReports.vue';
import PlayerDetails from './components/PlayerDetails.vue';
import PlayerDevelopmentProcedures from './components/player-development/PlayerDevelopmentProcedures.vue';
import PlayerIndex from './components/PlayerIndex.vue';
import PlayerSupporterDetails from './components/PlayerSupporterDetails.vue';
import PlayersChangeRequests from '@/components/player-change-requests/PlayersChangeRequests.vue';
import Procedures from './components/Procedures.vue';
import ProceduresByDeadline from './components/ProceduresByDeadline';
import Profile from './components/Profile.vue';
import RankingHistory from './components/ranking-history/RankingHistory.vue';
import RankingSummaries from './components/ranking-summaries/RankingSummaries.vue';
import RequiredTournamentPlayerList from './components/ranking-required-tournaments/RequiredTournamentPlayerList.vue';
import RequiredTournamentsMain from './components/ranking-required-tournaments/RequiredTournamentsMain.vue';
import ShadowCurrentRanking from './components/shadow-ranking-current/ShadowCurrentRanking.vue';
import ShadowRanking from './components/shadow-ranking/ShadowRanking.vue';
import ShadowRankingHistory from './components/shadow-ranking-history/ShadowRankingHistory.vue';
import ShadowRankingSummaries from './components/shadow-ranking-summaries/ShadowRankingSummaries.vue';
import SpecialRankingWorksheet from './components/SpecialRankingWorksheet.vue';
import SysCodeIndex from './components/SysCodeIndex.vue';
import TournamentDetails from './components/TournamentDetails.vue';
import TournamentIndex from './components/TournamentIndex.vue';
import TournamentPointIndex from './components/TournamentPointIndex.vue';
import TournamentPrizeIndex from './components/TournamentPrizeIndex.vue';
import Vue from 'vue';
import VueRouter from 'vue-router';
import WeeklyRankingProcedures from './components/WeeklyRankingProcedures';
import WtaPlayerZoneAdmin from './components/WtaPlayerZoneAdmin.vue';
import WtaTourYears from './components/WtaTourYears';

Vue.use(VueRouter);

const routes = [
    {
        path: '/unauthorized',
        name: 'unauthorized',
        component: () => import('./pages/app/NotAllowed.vue'),
    },
    {
        path: '',
        component: MainWrapper,
        children: [
            {
                path: '/',
                name: 'home',
                component: Home,
            },
            {
                path: '/profile',
                name: 'profile',
                component: Profile,
            },
            {
                path: '/players',
                name: 'player-index',
                component: PlayerIndex,
            },
            {
                path: '/players/add',
                name: 'add-player',
                component: AddPlayer,
            },
            {
                path: '/players/:id',
                name: 'player-details',
                component: PlayerDetails,
            },
            {
                path: '/player-supporter/add',
                name: 'add-player-supporter',
                component: AddPlayerSupport,
            },
            {
                path: '/player-supporter/:id',
                name: 'player-supporter-details',
                component: PlayerSupporterDetails,
            },
            {
                path: '/tournaments',
                name: 'edition-index',
                component: TournamentIndex,
            },
            {
                path: '/tournaments/add',
                name: 'add-edition',
                component: AddTournament,
            },
            {
                path: '/tournaments/:year/:id',
                name: 'edition-details',
                component: TournamentDetails,
            },
            {
                path: '/ranking/current',
                name: 'current-ranking',
                component: CurrentRanking,
            },
            {
                path: '/ranking/by-date',
                name: 'ranking-by-date',
                component: RankingHistory,
            },
            {
                path: '/ranking/summaries',
                name: 'ranking-summaries',
                component: RankingSummaries,
            },
            {
                path: '/ranking/required-tournaments',
                name: 'required-tournaments',
                component: RequiredTournamentsMain,
            },
            {
                path: '/ranking/required-tournaments/:year/:id',
                name: 'required-tournament-player-list',
                component: RequiredTournamentPlayerList,
            },
            {
                path: '/ranking/special-ranking',
                name: 'special-ranking',
                component: SpecialRankingWorksheet,
            },
            {
                path: '/ranking/corona',
                name: 'corona-ranking',
                component: CoronaRanking,
            },
            {
                path: '/shadow-rankings',
                name: 'shadow-rankings',
                component: ShadowRanking,
            },
            {
                path: '/shadow-ranking-summaries',
                name: 'shadow-ranking-summaries',
                component: ShadowRankingSummaries,
            },
            {
                path: '/shadow-ranking-by-date',
                name: 'shadow-ranking-by-date',
                component: ShadowRankingHistory,
            },
            {
                path: '/shadow-ranking-current',
                name: 'shadow-current-ranking',
                component: ShadowCurrentRanking,
            },
            {
                path: '/acceptance-list',
                name: 'acceptance-list',
                component: AcceptanceList,
            },
            {
                path: '/acceptance-list/procedures',
                name: 'run-procedure',
                component: ProceduresByDeadline,
            },
            {
                path: '/sys-codes',
                name: 'sys-codes',
                component: SysCodeIndex,
            },
            {
                path: '/tournament-prizes',
                name: 'tournament-prizes',
                component: TournamentPrizeIndex,
            },
            {
                path: '/tournament-points',
                name: 'tournament-points',
                component: TournamentPointIndex,
            },
            {
                path: '/wta-tour-years',
                name: 'wta-tour-years',
                component: WtaTourYears,
            },
            {
                path: '/ranking-procedures',
                name: 'ranking-procedures',
                component: WeeklyRankingProcedures,
            },
            {
                path: '/player-development',
                name: 'player-development',
                component: PlayerDevelopmentProcedures,
            },
            {
                path: '/age-eligibility',
                name: 'age-eligibility',
                component: AgeEligibilityReports,
            },
            {
                path: '/wta-player-zone-admin',
                name: 'wta-player-zone-admin',
                component: WtaPlayerZoneAdmin,
            },
            {
                path: '/payment-reports',
                name: 'payment-reports',
                component: PaymentDataReports,
            },
            {
                path: '/player-change-requests/admin',
                name: 'player-change-requests',
                component: PlayersChangeRequests,
            },
            {
                path: '/banking-data-report',
                name: 'banking-data-report',
                component: () => import('./pages/reports/BankingDataReport.vue'),
            },
        ],
    },
];

const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes,
});

router.beforeEach(async (to, from, next) => {
    const isAuthorized = localStorage.getItem('authorized') === 'true';

    if (to.path === '/unauthorized' && isAuthorized) {
        next({ path: '/' });
    } else if (to.path === '/unauthorized' && !isAuthorized) {
        next();
    } else if (to.path !== '/unauthorized' && isAuthorized) {
        next();
    } else {
        next({ path: '/unauthorized' });
    }
});

router.afterEach((toRoute, fromRoute) => {
    window.document.title = 'Baseline ' + process.env.VUE_APP_APPLICATION_MODE.toUpperCase();
});

export default router;
