var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loadingSinglesRaceSummary),expression:"loadingSinglesRaceSummary"}]},[_c('ErrorDisplay',{attrs:{"requestError":this.singlesRaceSummaryError},on:{"closed":_vm.clearError}}),_c('div',{staticClass:"flex-row"},[_c('RankingSummaryPointTable',{attrs:{"summary":this.singlesRaceSummary}}),_c('span',{staticClass:"spacer"}),_c('RankingSummaryChampionshipTieBreakingPoints',{attrs:{"summary":this.singlesRaceSummary}}),_c('span',{staticClass:"spacer"}),_c('span',{staticClass:"spacer"})],1),(_vm.singlesRaceSummary)?_c('DataTable',{attrs:{"items":_vm.singlesRaceSummary.allResults,"schema":_vm.schemaTournResult,"fields":[
            'startDate',
            'useForRankingDate',
            'tournamentName',
            'season',
            'tournamentId',
            //'tournamentType',
            //'tournamentLevel',
            'isRequired',
            'points',
            'isIncludedIntoRanking',
            'rankingLabel',
        ],"autoSort":false}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }