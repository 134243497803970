<template>
    <div>
        <div v-if="playerPaymentYears.length > 0">
            <div class="flex-row mt-16 mb-32">
                <div>
                    <h3>{{ title() }}</h3>
                </div>
                <span class="spacer"></span>
                <el-button type="primary" @click="onExportPdf" v-loading="downloadingPlayerPayments">Export PDF</el-button>
                <el-select v-model="year" placeholder="Select Year" v-loading="loadingPlayerPaymentYears" @change="loadPayments">
                    <el-option v-for="item in playerPaymentYears" :key="item" :label="item" :value="item"> </el-option>
                </el-select>
            </div>

            <ErrorDisplay :requestError="this.requestError" @closed="clearError" class="mt-16" />

            <div v-if="dollarTournaments().length">
                <h4>USD</h4>
                <el-table v-loading="loadingPlayerPayments || loadingPrizeMoneyReceipt" :data="dollarTournaments()" >
                    <el-table-column label="Actions" width="80">
                        <template slot-scope="scope">
                            <el-button type="primary" size="mini" v-if="scope.row.tournamentEndDate" @click="onClickDownload(scope.row.tournNbr)"
                                ><i class="el-icon-download"></i></el-button
                            >
                        </template>
                    </el-table-column>
                    <el-table-column label="Date" width="100">
                        <template slot-scope="scope">
                            <span style="margin-left: 10px">{{ scope.row.tournamentEndDate | moment('YYYY-MM-DD') }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="Tournament" width="180">
                        <template slot-scope="scope">
                            <span style="margin-left: 10px">{{ scope.row.tournamentName }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="Single Prize Money" width="180">
                        <template slot-scope="scope">
                            <span style="margin-left: 10px">{{ toCurrency(scope.row.singlePriceMoney, scope.row.dlrEuro) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="Double Prize Money" width="180">
                        <template slot-scope="scope">
                            <span style="margin-left: 10px">{{ toCurrency(scope.row.doublePriceMoney, scope.row.dlrEuro) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="Lucky Loser Qualifying Round" width="220">
                        <template slot-scope="scope">
                            <span style="margin-left: 10px">{{ scope.row.luckyLoserQualifyingRound }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="Lucky Loser Qualifying Amount" width="230">
                        <template slot-scope="scope">
                            <span style="margin-left: 10px">{{ toCurrency(scope.row.luckyLoserQualifyingAmount, scope.row.dlrEuro) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="Lucky Loser Deduct Round" width="200">
                        <template slot-scope="scope">
                            <span style="margin-left: 10px">{{ scope.row.luckyLoserDeductRound }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="Lucky Loser Deduct Amount" width="220">
                        <template slot-scope="scope">
                            <span style="margin-left: 10px">{{ toCurrency(scope.row.luckyLoserDeductAmount, scope.row.dlrEuro) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="Subtotal" width="100">
                        <template slot-scope="scope">
                            <span style="margin-left: 10px">{{ toCurrency(scope.row.subtotal, scope.row.dlrEuro) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="Tax" width="100">
                        <template slot-scope="scope">
                            <span style="margin-left: 10px">{{ toCurrency(scope.row.tax, scope.row.dlrEuro) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="WTA Membership" width="180">
                        <template slot-scope="scope">
                            <span style="margin-left: 10px">{{ toCurrency(scope.row.wtaMembership, scope.row.dlrEuro) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="WTA Admin Fee" width="180">
                        <template slot-scope="scope">
                            <span style="margin-left: 10px">{{ toCurrency(scope.row.wtaAdminFee, scope.row.dlrEuro) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="WTA Massage Fee" width="180">
                        <template slot-scope="scope">
                            <span style="margin-left: 10px">{{ toCurrency(scope.row.wtaMassageFee, scope.row.dlrEuro) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="WTA Fines" width="180">
                        <template slot-scope="scope">
                            <span style="margin-left: 10px">{{ toCurrency(scope.row.wtaFines, scope.row.dlrEuro) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="Stringer Fees" width="180">
                        <template slot-scope="scope">
                            <span style="margin-left: 10px">{{ toCurrency(scope.row.stringerFees, scope.row.dlrEuro) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="Tournament Other" width="180">
                        <template slot-scope="scope">
                            <span style="margin-left: 10px">{{ toCurrency(scope.row.other, scope.row.dlrEuro) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="Total Deduction" width="180">
                        <template slot-scope="scope">
                            <span style="margin-left: 10px">{{ toCurrency(scope.row.totalDeduction, scope.row.dlrEuro) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="Net Payment" width="180">
                        <template slot-scope="scope">
                            <span style="margin-left: 10px">{{ toCurrency(scope.row.netPayment, scope.row.dlrEuro) }}</span>
                        </template>
                    </el-table-column>
                </el-table>
            </div>

            <div v-if="euroTournaments().length">
                <h4>EUR</h4>
                <el-table v-loading="loadingPlayerPayments || loadingPrizeMoneyReceipt" :data="euroTournaments()" >
                    <el-table-column label="Actions" width="80">
                        <template slot-scope="scope">
                            <el-button type="primary" size="mini" v-if="scope.row.tournamentEndDate" @click="onClickDownload(scope.row.tournNbr)"
                                ><i class="el-icon-download"></i></el-button
                            >
                        </template>
                    </el-table-column>
                    <el-table-column label="Date" width="100">
                        <template slot-scope="scope">
                            <span style="margin-left: 10px">{{ scope.row.tournamentEndDate | moment('YYYY-MM-DD') }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="Tournament" width="180">
                        <template slot-scope="scope">
                            <span style="margin-left: 10px">{{ scope.row.tournamentName }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="Single Prize Money" width="180">
                        <template slot-scope="scope">
                            <span style="margin-left: 10px">{{ toCurrency(scope.row.singlePriceMoney, scope.row.dlrEuro) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="Double Prize Money" width="180">
                        <template slot-scope="scope">
                            <span style="margin-left: 10px">{{ toCurrency(scope.row.doublePriceMoney, scope.row.dlrEuro) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="Lucky Loser Qualifying Round" width="220">
                        <template slot-scope="scope">
                            <span style="margin-left: 10px">{{ scope.row.luckyLoserQualifyingRound }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="Lucky Loser Qualifying Amount" width="230">
                        <template slot-scope="scope">
                            <span style="margin-left: 10px">{{ toCurrency(scope.row.luckyLoserQualifyingAmount, scope.row.dlrEuro) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="Lucky Loser Deduct Round" width="200">
                        <template slot-scope="scope">
                            <span style="margin-left: 10px">{{ scope.row.luckyLoserDeductRound }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="Lucky Loser Deduct Amount" width="220">
                        <template slot-scope="scope">
                            <span style="margin-left: 10px">{{ toCurrency(scope.row.luckyLoserDeductAmount, scope.row.dlrEuro) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="Subtotal" width="100">
                        <template slot-scope="scope">
                            <span style="margin-left: 10px">{{ toCurrency(scope.row.subtotal, scope.row.dlrEuro) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="Tax" width="100">
                        <template slot-scope="scope">
                            <span style="margin-left: 10px">{{ toCurrency(scope.row.tax, scope.row.dlrEuro) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="WTA Membership" width="180">
                        <template slot-scope="scope">
                            <span style="margin-left: 10px">{{ toCurrency(scope.row.wtaMembership, scope.row.dlrEuro) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="WTA Admin Fee" width="180">
                        <template slot-scope="scope">
                            <span style="margin-left: 10px">{{ toCurrency(scope.row.wtaAdminFee, scope.row.dlrEuro) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="WTA Massage Fee" width="180">
                        <template slot-scope="scope">
                            <span style="margin-left: 10px">{{ toCurrency(scope.row.wtaMassageFee, scope.row.dlrEuro) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="WTA Fines" width="180">
                        <template slot-scope="scope">
                            <span style="margin-left: 10px">{{ toCurrency(scope.row.wtaFines, scope.row.dlrEuro) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="Stringer Fees" width="180">
                        <template slot-scope="scope">
                            <span style="margin-left: 10px">{{ toCurrency(scope.row.stringerFees, scope.row.dlrEuro) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="Tournament Other" width="180">
                        <template slot-scope="scope">
                            <span style="margin-left: 10px">{{ toCurrency(scope.row.other, scope.row.dlrEuro) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="Total Deduction" width="180">
                        <template slot-scope="scope">
                            <span style="margin-left: 10px">{{ toCurrency(scope.row.totalDeduction, scope.row.dlrEuro) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="Net Payment" width="180">
                        <template slot-scope="scope">
                            <span style="margin-left: 10px">{{ toCurrency(scope.row.netPayment, scope.row.dlrEuro) }}</span>
                        </template>
                    </el-table-column>
                </el-table>
            </div>

            <div v-if="australianDollarTournaments().length">
                <h4>AUD</h4>
                <el-table v-loading="loadingPlayerPayments || loadingPrizeMoneyReceipt" :data="australianDollarTournaments()" >
                    <el-table-column label="Actions" width="80">
                        <template slot-scope="scope">
                            <el-button type="primary" size="mini" v-if="scope.row.tournamentEndDate" @click="onClickDownload(scope.row.tournNbr)"
                                ><i class="el-icon-download"></i></el-button
                            >
                        </template>
                    </el-table-column>
                    <el-table-column label="Date" width="100">
                        <template slot-scope="scope">
                            <span style="margin-left: 10px">{{ scope.row.tournamentEndDate | moment('YYYY-MM-DD') }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="Tournament" width="180">
                        <template slot-scope="scope">
                            <span style="margin-left: 10px">{{ scope.row.tournamentName }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="Single Prize Money" width="180">
                        <template slot-scope="scope">
                            <span style="margin-left: 10px">{{ toCurrency(scope.row.singlePriceMoney, scope.row.dlrEuro) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="Double Prize Money" width="180">
                        <template slot-scope="scope">
                            <span style="margin-left: 10px">{{ toCurrency(scope.row.doublePriceMoney, scope.row.dlrEuro) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="Lucky Loser Qualifying Round" width="220">
                        <template slot-scope="scope">
                            <span style="margin-left: 10px">{{ scope.row.luckyLoserQualifyingRound }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="Lucky Loser Qualifying Amount" width="230">
                        <template slot-scope="scope">
                            <span style="margin-left: 10px">{{ toCurrency(scope.row.luckyLoserQualifyingAmount, scope.row.dlrEuro) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="Lucky Loser Deduct Round" width="200">
                        <template slot-scope="scope">
                            <span style="margin-left: 10px">{{ scope.row.luckyLoserDeductRound }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="Lucky Loser Deduct Amount" width="220">
                        <template slot-scope="scope">
                            <span style="margin-left: 10px">{{ toCurrency(scope.row.luckyLoserDeductAmount, scope.row.dlrEuro) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="Subtotal" width="100">
                        <template slot-scope="scope">
                            <span style="margin-left: 10px">{{ toCurrency(scope.row.subtotal, scope.row.dlrEuro) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="Tax" width="100">
                        <template slot-scope="scope">
                            <span style="margin-left: 10px">{{ toCurrency(scope.row.tax, scope.row.dlrEuro) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="WTA Membership" width="180">
                        <template slot-scope="scope">
                            <span style="margin-left: 10px">{{ toCurrency(scope.row.wtaMembership, scope.row.dlrEuro) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="WTA Admin Fee" width="180">
                        <template slot-scope="scope">
                            <span style="margin-left: 10px">{{ toCurrency(scope.row.wtaAdminFee, scope.row.dlrEuro) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="WTA Massage Fee" width="180">
                        <template slot-scope="scope">
                            <span style="margin-left: 10px">{{ toCurrency(scope.row.wtaMassageFee, scope.row.dlrEuro) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="WTA Fines" width="180">
                        <template slot-scope="scope">
                            <span style="margin-left: 10px">{{ toCurrency(scope.row.wtaFines, scope.row.dlrEuro) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="Stringer Fees" width="180">
                        <template slot-scope="scope">
                            <span style="margin-left: 10px">{{ toCurrency(scope.row.stringerFees, scope.row.dlrEuro) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="Tournament Other" width="180">
                        <template slot-scope="scope">
                            <span style="margin-left: 10px">{{ toCurrency(scope.row.other, scope.row.dlrEuro) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="Total Deduction" width="180">
                        <template slot-scope="scope">
                            <span style="margin-left: 10px">{{ toCurrency(scope.row.totalDeduction, scope.row.dlrEuro) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="Net Payment" width="180">
                        <template slot-scope="scope">
                            <span style="margin-left: 10px">{{ toCurrency(scope.row.netPayment, scope.row.dlrEuro) }}</span>
                        </template>
                    </el-table-column>
                </el-table>
            </div>

            <div v-if="pundTournaments().length">
                <h4>GBP</h4>
                <el-table v-loading="loadingPlayerPayments || loadingPrizeMoneyReceipt" :data="pundTournaments()" >
                    <el-table-column label="Actions" width="80">
                        <template slot-scope="scope">
                            <el-button type="primary" size="mini" v-if="scope.row.tournamentEndDate" @click="onClickDownload(scope.row.tournNbr)"
                                ><i class="el-icon-download"></i></el-button
                            >
                        </template>
                    </el-table-column>
                    <el-table-column label="Date" width="100">
                        <template slot-scope="scope">
                            <span style="margin-left: 10px">{{ scope.row.tournamentEndDate | moment('YYYY-MM-DD') }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="Tournament" width="180">
                        <template slot-scope="scope">
                            <span style="margin-left: 10px">{{ scope.row.tournamentName }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="Single Prize Money" width="180">
                        <template slot-scope="scope">
                            <span style="margin-left: 10px">{{ toCurrency(scope.row.singlePriceMoney, scope.row.dlrEuro) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="Double Prize Money" width="180">
                        <template slot-scope="scope">
                            <span style="margin-left: 10px">{{ toCurrency(scope.row.doublePriceMoney, scope.row.dlrEuro) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="Lucky Loser Qualifying Round" width="220">
                        <template slot-scope="scope">
                            <span style="margin-left: 10px">{{ scope.row.luckyLoserQualifyingRound }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="Lucky Loser Qualifying Amount" width="230">
                        <template slot-scope="scope">
                            <span style="margin-left: 10px">{{ toCurrency(scope.row.luckyLoserQualifyingAmount, scope.row.dlrEuro) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="Lucky Loser Deduct Round" width="200">
                        <template slot-scope="scope">
                            <span style="margin-left: 10px">{{ scope.row.luckyLoserDeductRound }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="Lucky Loser Deduct Amount" width="220">
                        <template slot-scope="scope">
                            <span style="margin-left: 10px">{{ toCurrency(scope.row.luckyLoserDeductAmount, scope.row.dlrEuro) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="Subtotal" width="100">
                        <template slot-scope="scope">
                            <span style="margin-left: 10px">{{ toCurrency(scope.row.subtotal, scope.row.dlrEuro) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="Tax" width="100">
                        <template slot-scope="scope">
                            <span style="margin-left: 10px">{{ toCurrency(scope.row.tax, scope.row.dlrEuro) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="WTA Membership" width="180">
                        <template slot-scope="scope">
                            <span style="margin-left: 10px">{{ toCurrency(scope.row.wtaMembership, scope.row.dlrEuro) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="WTA Admin Fee" width="180">
                        <template slot-scope="scope">
                            <span style="margin-left: 10px">{{ toCurrency(scope.row.wtaAdminFee, scope.row.dlrEuro) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="WTA Massage Fee" width="180">
                        <template slot-scope="scope">
                            <span style="margin-left: 10px">{{ toCurrency(scope.row.wtaMassageFee, scope.row.dlrEuro) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="WTA Fines" width="180">
                        <template slot-scope="scope">
                            <span style="margin-left: 10px">{{ toCurrency(scope.row.wtaFines, scope.row.dlrEuro) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="Stringer Fees" width="180">
                        <template slot-scope="scope">
                            <span style="margin-left: 10px">{{ toCurrency(scope.row.stringerFees, scope.row.dlrEuro) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="Tournament Other" width="180">
                        <template slot-scope="scope">
                            <span style="margin-left: 10px">{{ toCurrency(scope.row.other, scope.row.dlrEuro) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="Total Deduction" width="180">
                        <template slot-scope="scope">
                            <span style="margin-left: 10px">{{ toCurrency(scope.row.totalDeduction, scope.row.dlrEuro) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="Net Payment" width="180">
                        <template slot-scope="scope">
                            <span style="margin-left: 10px">{{ toCurrency(scope.row.netPayment, scope.row.dlrEuro) }}</span>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <p v-else class="no-data-message">No data available</p>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { PlayerPayment } from '../models/player-payment';
import { retrieveDtos } from '../utils/crud-confirm';

export default {
    props: ['playerNbr'],
    data() {
        return {
            schema: PlayerPayment.schema,
            layout: PlayerPayment.layout,
            requestError: null,
            year: null,
        };
    },
    mounted() {
        this.loadYears();
    },
    computed: {
        ...mapGetters('playersPlayerPayments', [
            'playerPayments',
            'loadingPlayerPayments',
            'playerPaymentYears',
            'loadingPlayerPaymentYears',
            'loadingError',
            'downloadingPlayerPayments',
        ]),
        ...mapGetters('pdfReports', [
            'loadingPrizeMoneyReceipt'
        ]),
    },
    methods: {
        ...mapActions('playersPlayerPayments', ['findPlayerPaymentYears', 'findPlayerPayments', 'downloadPdfPlayerPayments']),
        ...mapActions('pdfReports', [
            'getPrizeMoneyReceipt',
            'clearProgress'
        ]),
        refresh() {
            this.loadPayments();
        },
        onClickDownload(tournamentNumber) {
            let request = {
                playerNumber: this.playerNbr,
                year: this.year,
                tournamentNumber: tournamentNumber
            };
            this.downloadPrizeMoneyReceiptPdf(this.getPrizeMoneyReceipt, 'Prize_Money_Receipt.pdf', request);
        },
        downloadPrizeMoneyReceiptPdf(fDownload, filename, request) {
            this.requestError = null;
            fDownload(request)
                    .then((result) => {
                        const file = new Blob([result], { type: 'application/pdf' });
                        saveAs(file, filename);
                        this.$notify({
                            title: 'PDF download successful',
                            message: filename + ' downloaded',
                            type: 'success',
                            duration: 5000,
                        });
                    })
                    .catch((error) => {
                        this.requestError = error;
                        this.$notify({
                            title: 'PDF download failed',
                            message: filename + ': ',
                            type: 'error',
                            duration: 7000,
                        });
                    });
        },
        dollarTournaments() {
            return this.playerPayments.filter((p) => p.dlrEuro === 'D');
        },
        euroTournaments() {
            return this.playerPayments.filter((p) => p.dlrEuro === 'E');
        },
        australianDollarTournaments() {
            return this.playerPayments.filter((p) => p.dlrEuro === 'U');
        },
        pundTournaments() {
            return this.playerPayments.filter((p) => p.dlrEuro === 'P');
        },
        loadYears() {
            let params = {
                self: this,
                fRetrieve: this.findPlayerPaymentYears,
                options: this.playerNbr,
                dtosName: 'Player Payment Years',
                callback: this.yearsCallback,
            };
            retrieveDtos(params);
        },
        yearsCallback(result) {
            if (!result.length) return;
            this.year = result[0];
            this.loadPayments();
        },
        loadPayments() {
            let params = {
                self: this,
                fRetrieve: this.findPlayerPayments,
                options: {
                    playerNbr: this.playerNbr,
                    year: this.year,
                },
                dtosName: 'Player Payments',
            };
            retrieveDtos(params);
        },
        toCurrency(value, currency) {
            if (currency === null || currency === undefined) {
                return '';
            }
            let currencyCode;
            switch (currency) {
                case 'D':
                    currencyCode = 'USD';
                    break;
                case 'E':
                    currencyCode = 'EUR';
                    break;
                case 'U':
                    currencyCode = 'AUD';
                    break;
                case 'P':
                    currencyCode = 'GBP';
                    break;
                case 'N':
                    return 'No';
                default:
                    return '';
            }

            let options = {
                style: 'currency',
                currency: currencyCode
            };
            
            let formatted = new Intl.NumberFormat('en-US', options).format(value);
            return formatted;
        },
        onChange() {},
        clearError() {
            this.requestError = null;
        },
        title() {
            return 'WTA Player Prize Money Summary - ' + this.year;
        },

        onExportPdf() {
            this.downloadPdf(this.downloadPdfPlayerPayments, `WTA_Player_Prize_Money_Summary_${this.year}_${this.playerNbr}.pdf`);
        },
        downloadPdf(fDownload, filename) {
            let options = {
                playerNbr: this.playerNbr,
                year: this.year,
            };
            this.requestError = null;
            fDownload(options)
                .then((result) => {
                    const file = new Blob([result], { type: 'application/pdf' });
                    saveAs(file, filename);
                    this.$notify({
                        title: 'WTA Player Prize Money Summary: PDF download successful',
                        message: filename + ' downloaded',
                        type: 'success',
                        duration: 5000,
                    });
                })
                .catch((error) => {
                    this.requestError = error;
                    console.error(error);
                    this.$notify({
                        title: 'WTA Player Prize Money Summary: PDF download failed',
                        message: filename + ': ',
                        type: 'error',
                        duration: 7000,
                    });
                });
        },
    },
};
</script>

<style>
.no-data-message {
    text-align: center;
    padding: 16px;
    color: #aaa;
}
</style>
