import axios from 'axios';

export default {
    namespaced: true,

    state: {
        mixedDoubles: [],
        loadingMixedDoubles: false,
        loadingError: null,
    },

    getters: {
        mixedDoubles: (state) => state.mixedDoubles,
        loadingMixedDoubles: (state) => state.loadingMixedDoubles,
        loadingError: (state) => state.loadingError,
    },

    mutations: {
        setMixedDoubles(state, data) {
            state.mixedDoubles = data;
        },

        startLoadingMixedDoubles(state) {
            state.loadingMixedDoubles = true;
        },

        stopLoadingMixedDoubles(state) {
            state.loadingMixedDoubles = false;
        },

        setLoadingError(state, error) {
            state.loadingError = error;
        },

        clearLoadingError(state) {
            state.loadingError = null;
        },
    },

    actions: {
        async findMixedDoubles(context, options = {}) {
            if (!options.tournYear || !options.tournNbr) {
                return;
            }
            context.commit('startLoadingMixedDoubles');
            let tournYear = options.tournYear;
            let tournNbr = options.tournNbr;
            try {
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/Edition/${tournYear}/${tournNbr}/MixedDoubles`);
                context.commit('stopLoadingMixedDoubles');
                context.commit('setMixedDoubles', result.data);
                context.commit('clearLoadingError');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('stopLoadingMixedDoubles');
                context.commit('setLoadingError', error);
                return Promise.reject(error);
            }
        },

        async createMixedDoubles(context, data = {}) {
            if (!data.data.tournamentYear || !data.data.tournamentId) {
                return;
            }
            context.commit('startLoadingMixedDoubles');
            let tournYear = data.data.tournamentYear;
            let tournNbr = data.data.tournamentId;
            try {
                const result = await axios.post(`${process.env.VUE_APP_API_URL}/Edition/${tournYear}/${tournNbr}/MixedDoubles`, data.data);
                context.commit('stopLoadingMixedDoubles');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('stopLoadingMixedDoubles');
                context.commit('setLoadingError', error);
                return Promise.reject(error);
            }
        },

        async updateMixedDoubles(context, data = {}) {
            if (!data.data.tournamentYear || !data.data.tournamentId) {
                return;
            }
            context.commit('startLoadingMixedDoubles');
            let tournYear = data.data.tournamentYear;
            let tournNbr = data.data.tournamentId;
            try {
                const result = await axios.put(`${process.env.VUE_APP_API_URL}/Edition/${tournYear}/${tournNbr}/MixedDoubles`, data.data);
                context.commit('stopLoadingMixedDoubles');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('stopLoadingMixedDoubles');
                context.commit('setLoadingError', error);
                return Promise.reject(error);
            }
        },

        async deleteMixedDoubles(context, data = {}) {
            if (!data.data.tournamentYear || !data.data.tournamentId || !data.data.playerId) {
                return;
            }
            context.commit('startLoadingMixedDoubles');
            let tournYear = data.data.tournamentYear;
            let tournNbr = data.data.tournamentId;
            let playerNbr = data.data.playerId;
            try {
                const result = await axios.delete(`${process.env.VUE_APP_API_URL}/Edition/${tournYear}/${tournNbr}/MixedDoubles/${playerNbr}`);
                context.commit('stopLoadingMixedDoubles');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('stopLoadingMixedDoubles');
                context.commit('setLoadingError', error);
                return Promise.reject(error);
            }
        },
    },
};
