import {Model, isValid} from './model';

export class AgeEligibilityTracking extends Model {
    isValid() {
        return isValid(AgeEligibilityTracking.schema, this);
    }

    isValidForAdd() {
        return isValid(AgeEligibilityTracking.schemaAdd, this);
    }

    isValidForEdit() {
        return isValid(AgeEligibilityTracking.schema, this);
    }
}

AgeEligibilityTracking.layout = {
    children: [
        {
            title: 'Player Information',
            children: ['playerNbr', {id: 'fullName', width: 2}, 'nationality', 'rankSingles', 'rankDoubles',
                'playerAge', 'dateOfBirth',],
        },
    ],
};

AgeEligibilityTracking.tableFieldsReportFull = [
    'playerNbr',
    'fullName',
    'rankSingles',
    'rankDoubles',
    'playerAge',
    'dateOfBirth',
    'nationality',
    'allotmentLeftTournaments',
    'allotmentLeftWc',
    'fedCupMainResults',
    'fedCupQualiResults',
    'wtaChampionshipsAllotment',
    'premierMandatoryAllotment',
    'premierMandatoryWcMainAllotment',
    'premierMandatoryWcQualiAllotment',
    'premierAllotment',
    'premierWcMainAllotment',
    'premierWcQualiAllotment',
    'internationalAllotment',
    'internationalWcMainAllotment',
    'internationalWcQualiAllotment',
    'wta125KAllotment',
    'wta125KWcMainAllotment',
    'wta125KWcQualiAllotment',
    'grandSlamAllotment',
    'grandSlamWcMainAllotment',
    'grandSlamWcQualiAllotment',
    'itf1Allotment',
    'itf2Allotment',
    'itfWcMainAllotment',
    'itfWcQualiAllotment',
];

AgeEligibilityTracking.tableFieldsReportPreview = [
    'playerNbr',
    'fullName',
    'rankSingles',
    'rankDoubles',
    'playerAge',
    'dateOfBirth',
    'nationality',
    'allotmentLeftTournaments',
    'tournamentCountAllotment',
    'allotmentLeftWc',
    'wcCountAllotment',
];

AgeEligibilityTracking.warningViolationReport = [
    'playerNbr',
    'fullName',
    'rankSingles',
    'rankDoubles',
    'playerAge',
    'dateOfBirth',
    'nationality',
    'warningViolation',
    'remainingNumberOfWCsTourns',
    'lastTournamentString',
];

AgeEligibilityTracking.schema = {
    fields: {
        playerNbr: {
            type: 'number',
            displayName: 'Player Nbr',
            columnName: 'Nbr',
            required: true,
            readonly: true,
            sortable: true
        },
        fullName: {
            type: 'string',
            displayName: 'Player Name',
            columnName: 'Name',
            required: true,
            readonly: true,
            sortable: true
        },
        rankSingles: {
            type: 'number',
            displayName: 'Singles Rank',
            columnNames: ['Rank', 'Singles'],
            required: true,
            readonly: true,
            sortable: true
        },
        rankDoubles: {
            type: 'number',
            displayName: 'Doubles Rank',
            columnNames: ['Rank', 'Doubles'],
            required: true,
            readonly: true,
            sortable: true
        },
        playerAge: {
            type: 'number',
            displayName: 'Age',
            columnName: 'Age',
            required: true,
            readonly: true,
            sortable: true
        },
        dateOfBirth: {
            type: 'us-date',
            displayName: 'Birth Date',
            columnName: 'Birthdate',
            required: true,
            readonly: true,
            sortable: true
        },
        nationality: {
            type: 'nationality',
            displayName: 'Nationality',
            columnName: 'Nationality',
            required: true,
            readonly: true,
            sortable: true
        },

        tournamentCount: {type: 'number', displayName: 'Tournaments', columnNames: ['Trns', 'used'], readonly: true},
        wcSinglesCount: {type: 'number', displayName: 'WC Singles', readonly: true},
        wcDoublesCount: {type: 'number', displayName: 'WC Doubles', readonly: true},
        wcMainCount: {type: 'number', displayName: 'WC Main', readonly: true},
        wcQualiCount: {type: 'number', displayName: 'WC Quali', readonly: true},
        wcCount: {type: 'number', displayName: 'WC', readonly: true},
        tournamentCountAllotment: {
            type: 'number',
            displayName: 'Tournament',
            columnName: 'Trns',
            columnNames: ['Trns', 'used'],
            readonly: true,
            sortable: true
        },
        wcSinglesCountAllotment: {type: 'number', displayName: ' WC Singles', columnName: 'WC Singles', readonly: true},
        wcDoublesCountAllotment: {type: 'number', displayName: ' WC Doubles', columnName: 'WC Doubles', readonly: true},
        wcMainCountAllotment: {type: 'number', displayName: 'WC Main', columnName: 'WC Main', readonly: true},
        wcQualiCountAllotment: {type: 'number', displayName: 'WC Quali', columnName: 'WC Quali', readonly: true},
        wcCountAllotment: {
            type: 'number',
            displayName: 'WCs used',
            columnNames: ['WCs', 'used'],
            readonly: true,
            sortable: true
        },

        fedCupMainResults: {
            type: 'number',
            displayName: 'Fed Cup Main',
            columnName: 'FC M',
            columnNames: ['FC', 'M'],
            readonly: true
        },
        fedCupQualiResults: {
            type: 'number',
            displayName: 'Fed Cup Quali',
            columnName: 'FC Q',
            columnNames: ['FC', 'Q'],
            readonly: true
        },
        wtaChampionships: {type: 'number', displayName: 'Championships', columnName: 'CH', readonly: true},
        wtaChampionshipsAllotment: {type: 'number', displayName: 'Championships', columnName: 'CH', readonly: true},

        premierMandatory: {type: 'number', displayName: 'WTA 1000 (PM)', readonly: true},
        premierMandatoryWcMain: {type: 'number', displayName: 'WTA 1000 (PM) WC Main', readonly: true},
        premierMandatoryWcQuali: {type: 'number', displayName: 'WTA 1000 (PM) WC Quali', readonly: true},
        premier: {type: 'number', displayName: 'WTA 500 (P)', readonly: true},
        premierWcMain: {type: 'number', displayName: 'WTA 500 (P) WC Main', readonly: true},
        premierWcQuali: {type: 'number', displayName: 'WTA 500 (P) WC Quali', readonly: true},
        international: {type: 'number', displayName: 'WTA 250 (IS)', readonly: true},
        internationalWcMain: {type: 'number', displayName: 'WTA 250 (IS) WC Main', readonly: true},
        internationalWcQuali: {type: 'number', displayName: 'WTA 250 (IS) WC Quali', readonly: true},
        wta125K: {type: 'number', displayName: 'WTA 125', readonly: true},
        wta125KWcMain: {type: 'number', displayName: 'WTA 125 WC Main', readonly: true},
        wta125KWcQuali: {type: 'number', displayName: 'WTA 125 WC Quali', readonly: true},
        grandSlam: {type: 'number', displayName: 'GrandSlam', readonly: true},
        grandSlamWcMain: {type: 'number', displayName: 'GrandSlam WC Main', readonly: true},
        grandSlamWcQuali: {type: 'number', displayName: 'GrandSlam WC Quali', readonly: true},
        itf1: {type: 'number', displayName: 'ITF < 60K', readonly: true},
        itf2: {type: 'number', displayName: 'ITF >= 60K', readonly: true},
        itfWcMain: {type: 'number', displayName: 'ITF WC Main', readonly: true},
        itfWcQuali: {type: 'number', displayName: 'ITF WC Quali', readonly: true},

        premierMandatoryAllotment: {type: 'number', displayName: 'WTA 1000M', columnName: '1000M', readonly: true},
        premierMandatoryWcMainAllotment: {
            type: 'number',
            displayName: 'WTA 1000M WC Main',
            columnName: 'M',
            columnNames: ['1000M', 'M'],
            readonly: true
        },
        premierMandatoryWcQualiAllotment: {
            type: 'number',
            displayName: 'WTA 1000 WC Quali',
            columnName: 'Q',
            columnNames: ['1000M', 'Q'],
            readonly: true
        },
        premierAllotment: {type: 'number', displayName: 'WTA 500 (P)', columnName: '500', readonly: true},
        premierWcMainAllotment: {
            type: 'number',
            displayName: 'WTA 500 (P) WC Main',
            columnName: 'M',
            columnNames: ['500', 'M'],
            readonly: true
        },
        premierWcQualiAllotment: {
            type: 'number',
            displayName: 'WTA 500 (P) WC Quali',
            columnName: 'Q',
            columnNames: ['500', 'Q'],
            readonly: true
        },
        internationalAllotment: {type: 'number', displayName: 'WTA 250 (IS)', columnName: '250', readonly: true},
        internationalWcMainAllotment: {
            type: 'number',
            displayName: 'WTA 250 (IS) WC Main',
            columnName: 'M',
            columnNames: ['250', 'M'],
            readonly: true
        },
        internationalWcQualiAllotment: {
            type: 'number',
            displayName: 'WTA 250 (IS) WC Quali',
            columnName: 'Q',
            columnNames: ['250', 'Q'],
            readonly: true
        },
        wta125KAllotment: {type: 'number', displayName: 'WTA 125', columnName: '125', readonly: true},
        wta125KWcMainAllotment: {
            type: 'number',
            displayName: 'WTA 125 WC Main',
            columnName: 'M',
            columnNames: ['125', 'M'],
            readonly: true
        },
        wta125KWcQualiAllotment: {
            type: 'number',
            displayName: 'WTA 125 WC Quali',
            columnName: 'Q',
            columnNames: ['125', 'Q'],
            readonly: true
        },
        grandSlamAllotment: {type: 'number', displayName: 'GrandSlam', columnName: 'GS', readonly: true},
        grandSlamWcMainAllotment: {
            type: 'number',
            displayName: 'GrandSlam WC Main',
            columnName: 'M',
            columnNames: ['GS', 'M'],
            readonly: true
        },
        grandSlamWcQualiAllotment: {
            type: 'number',
            displayName: 'GrandSlam WC Quali',
            columnName: 'Q',
            columnNames: ['GS', 'Q'],
            readonly: true
        },
        itf1Allotment: {
            type: 'number',
            displayName: 'ITF < 60K',
            columnName: '<60',
            columnNames: ['ITF', '<60'],
            readonly: true
        },
        itf2Allotment: {
            type: 'number',
            displayName: 'ITF >= 60K',
            columnName: '60+',
            columnNames: ['ITF', '60+'],
            readonly: true
        },
        itfWcMainAllotment: {
            type: 'number',
            displayName: 'ITF WC Main',
            columnName: 'M',
            columnNames: ['ITF', 'M'],
            readonly: true
        },
        itfWcQualiAllotment: {
            type: 'number',
            displayName: 'ITF WC Quali',
            columnName: 'Q',
            columnNames: ['ITF', 'Q'],
            readonly: true
        },

        allotmentLeftWc: {
            type: 'number',
            displayName: 'WCs Left',
            columnName: 'Q',
            columnNames: ['WCs', 'left'],
            readonly: true,
            sortable: true
        },
        allotmentLeftTournaments: {
            type: 'number',
            displayName: 'Tourns Left',
            columnName: 'Q',
            columnNames: ['Trns', 'left'],
            readonly: true,
            sortable: true
        },
        hasWarning: {type: 'boolean', displayName: 'Has Warning', columnName: 'Warn', readonly: true},
        hasViolation: {type: 'boolean', displayName: 'Has Violation', columnName: 'Viol', readonly: true},

        warningViolationTournaments: {
            type: 'string',
            displayName: 'Warning/ Violation Tournaments',
            readonly: true,
            clearable: true
        },
        warningViolationWCs: {type: 'string', displayName: 'Warning/ Violation WCs', readonly: true, clearable: true},
        warningViolation: {
            type: 'string',
            displayName: 'Warning/ Violation',
            readonly: true,
            clearable: true,
            sortable: true
        },
        remainingNumberOfTournaments: {
            type: 'string',
            displayName: 'Remaining Tournaments',
            readonly: true,
            clearable: true
        },
        remainingNumberOfWCs: {type: 'string', displayName: 'Remaining WCs', readonly: true, clearable: true},
        remainingNumberOfWCsTourns: {
            type: 'string',
            displayName: 'Remaining WCs /Tournaments',
            readonly: true,
            clearable: true,
            sortable: true
        },
        lastTournamentString: {type: 'string', displayName: 'Last Tournament', readonly: true, clearable: true},
    },
};