import * as _ from "lodash";

import axios from "axios";

export default {
    namespaced: true,
    state: {
        paymentData: {},
        loadingPaymentData: false,
        loadingError: null,
        options: {
            role: null,
            queryString: null,
            fromDate: null,
            requestDateFrom: null,
            requestDateTo: null,
            owesMoney: false,
            sortBy: '',
            sortDir: 'descending',
            page: 0,
            pageSize: 20,
        },
    },
    getters: {
        paymentData: (state) => state.paymentData,
        loadingPaymentData: (state) => state.loadingPaymentData,
        loadingError: (state) => state.loadingError,
        options: (state) => state.options,
    },
    mutations: {
        setPaymentData(state, data) {
            state.paymentData = data;
        },

        startLoadingPaymentData(state) {
            state.loadingPaymentData = true;
        },

        stopLoadingPaymentData(state) {
            state.loadingPaymentData = false;
        },

        setLoadingError(state, error) {
            state.loadingError = error;
        },

        clearLoadingError(state) {
            state.loadingError = null;
        },
    },
    actions: {
        async searchPaymentData(context, options = {}) {
            context.commit('startLoadingPaymentData');
            context.commit('clearLoadingError');
            try {
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/PaymentReports/Search`, {
                    params: {
                        queryString: options.queryString || '',
                        pageSize: options.pageSize || 20,
                        sortBy: options.sortBy || '',
                        sortDir: options.sortDir === 'descending' ? -1 : 1,
                        page: options.page,
                        role: options.role,
                        requestDateFrom: options.requestDateFrom,
                        requestDateTo: options.requestDateTo,
                        owesMoney: options.owesMoney,
                    },
                });
                context.commit('setPaymentData', result.data);
                context.commit('stopLoadingPaymentData');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('setLoadingError', error);
                context.commit('stopLoadingPaymentData');
                return Promise.reject(error);
            }
        },
        async exportCsv(context, options = {}) {
            context.commit('startLoadingPaymentData');
            try {
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/PaymentReports/export/csv`, {
                    params: {
                        queryString: options.queryString || '',
                        sortBy: options.sortBy || '',
                        sortDir: options.sortDir === 'descending' ? -1 : 1,
                        role: options.role,
                        requestDateFrom: options.requestDateFrom,
                        requestDateTo: options.requestDateTo,
                        owesMoney: options.owesMoney,
                    },
                });
                context.commit('stopLoadingPaymentData');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('stopLoadingPaymentData');
                return Promise.reject(error);
            }
        },
        async exportPdf(context, options = {}) {
            context.commit('startLoadingPaymentData');
            try {
                var paramOptions = {
                    queryString: options.queryString || '',
                    sortBy: options.sortBy || '',
                    sortDir: options.sortDir === 'descending' ? -1 : 1,
                    role: options.role,
                    requestDateFrom: options.requestDateFrom,
                    requestDateTo: options.requestDateTo,
                    owesMoney: options.owesMoney,
                }

                const result = await axios.get(`${process.env.VUE_APP_API_URL}/PaymentReports/export/pdf`, {
                    params: paramOptions,
                    responseType: 'blob',
                });

                context.commit('stopLoadingPaymentData');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('setLoadingErrorAgeEligibilityWarningsViolations', error);
                context.commit('stopLoadingPdfAgeEligibilityWarningsViolations');
                return Promise.reject(error);
            }
        },
    },
};