import { Model, isValid } from './model';
import { icoCountries } from '../utils/country-codes';

export class CurrentRanking extends Model {
    isValidForAdd() {
        return isValid(CurrentRanking.schemaAdd, this);
    }

    isValidForEdit() {
        return isValid(CurrentRanking.schema, this);
    }
    static adaptDisplayNames(rankDateString) {
        if (rankDateString < '2024-01-01') {
            CurrentRanking.schema.fields.points16ThSingles.displayName = '16th Tourn';
            CurrentRanking.schema.fields.points17ThSingles.displayName = '17th Tourn';
            CurrentRanking.schema.fields.points11ThDoubles.displayName = '11th Tourn';
            CurrentRanking.schema.fields.points12ThDoubles.displayName = '12th Tourn';
        } else {
            CurrentRanking.schema.fields.points16ThSingles.displayName = '18th Tourn';
            CurrentRanking.schema.fields.points17ThSingles.displayName = '19th Tourn';
            CurrentRanking.schema.fields.points11ThDoubles.displayName = '12th Tourn';
            CurrentRanking.schema.fields.points12ThDoubles.displayName = '13th Tourn';

        }
    }
}

CurrentRanking.layout = {
    children: [
        {
            children: ['playerId', 'fullName', 'nationality', 'rankDate'],
        },
        {
            title: 'Singles',
            children: ['rankSingles', 'priorRankSingles', 'pointsSingles', 'rankSinglesCd'],
        },
        {
            children: ['tournamentCountSingles', 'points16ThSingles', 'nextOnSingles', 'nextOffSingles', 'points17ThSingles'],
        },
        {
            children: ['highestSinglesRank', 'highestSinglesRankDate', 'qualityPointsSingles'],
        },
        {
            title: 'Doubles',
            children: ['rankDoubles', 'priorRankDoubles', 'pointsDoubles', 'rankDoublesCd'],
        },
        {
            children: ['tournamentCountDoubles', 'points11ThDoubles', 'nextOnDoubles', 'nextOffDoubles', 'points12ThDoubles'],
        },
        {
            children: ['highestDoublesRank', 'highestDoublesRankDate', 'qualityPointsDoubles'],
        },
        {
            title: 'Championship Singles',
            children: ['rankChampionshipSingles', 'pointsChampionshipSingles', 'rankChampionshipSinglesCd', 'tournamentCountChampionshipSingles'],
        },
        {
            title: 'Championship Doubles',
            children: ['rankChampionshipDoubles', 'pointsChampionshipDoubles', 'rankChampionshipDoublesCd', 'tournamentCountChampionshipDoubles'],
        },
    ],
};

CurrentRanking.layoutAdd = {
    children: [
        {
            children: ['playerId', 'rankDate'],
        },
        {
            title: 'Singles',
            children: ['rankSingles', 'priorRankSingles', 'pointsSingles', 'rankSinglesCd'],
        },
        {
            children: ['tournamentCountSingles', 'points16ThSingles', 'nextOnSingles', 'nextOffSingles', 'points17ThSingles'],
        },
        {
            children: ['highestSinglesRank', 'highestSinglesRankDate', 'qualityPointsSingles'],
        },
        {
            title: 'Doubles',
            children: ['rankDoubles', 'priorRankDoubles', 'pointsDoubles', 'rankDoublesCd'],
        },
        {
            children: ['tournamentCountDoubles', 'points11ThDoubles', 'nextOnDoubles', 'nextOffDoubles', 'points12ThDoubles'],
        },
        {
            children: ['highestDoublesRank', 'highestDoublesRankDate', 'qualityPointsDoubles'],
        },
        {
            title: 'Championship Singles',
            children: ['rankChampionshipSingles', 'pointsChampionshipSingles', 'rankChampionshipSinglesCd', 'tournamentCountChampionshipSingles'],
        },
        {
            title: 'Championship Doubles',
            children: ['rankChampionshipDoubles', 'pointsChampionshipDoubles', 'rankChampionshipDoublesCd', 'tournamentCountChampionshipDoubles'],
        },
    ],
};

CurrentRanking.schema = {
    fields: {
        playerId: { type: 'number', displayName: 'Player Id', readonly: true, sortable: true, required: true },
        firstName: { type: 'string', displayName: 'First Name', readonly: true },
        lastName: { type: 'string', displayName: 'Last Name', readonly: true },
        nationality: { type: 'nationality', displayName: 'Nationality', lookup: Object.keys(icoCountries), readonly: true, sortable: true },
        rankDate: { type: 'us-date', displayName: 'Rank Date', required: true },
        rankSingles: { type: 'number', displayName: 'Rank', sortable: true, required: true },
        rankSinglesCd: { type: 'string', displayName: 'Tie' },
        rankDoubles: { type: 'number', displayName: 'Rank', sortable: true, required: true },
        rankDoublesCd: { type: 'string', displayName: 'Tie' },
        rankChampionshipSingles: { type: 'number', displayName: 'Rank', required: true },
        rankChampionshipSinglesCd: { type: 'string', displayName: 'Tie' },
        rankChampionshipDoubles: { type: 'number', displayName: 'Rank' },
        rankChampionshipDoublesCd: { type: 'string', displayName: 'Tie' },
        pointsSingles: { type: 'number', displayName: 'Points', sortable: true, required: true },
        pointsDoubles: { type: 'number', displayName: 'Points', sortable: true, required: true },
        pointsChampionshipSingles: { type: 'number', displayName: 'Points', required: true },
        pointsChampionshipDoubles: { type: 'number', displayName: 'Points' },
        highestSinglesRank: { type: 'number', displayName: 'Highest Rank', required: true },
        highestSinglesRankDate: { type: 'us-date', displayName: 'Highest Rank Date', required: true },
        highestDoublesRank: { type: 'number', displayName: 'Highest Rank', required: true },
        highestDoublesRankDate: { type: 'us-date', displayName: 'Highest Rank Date', required: true },
        qualityPointsSingles: { type: 'number', displayName: 'Quality Points Singles' },
        qualityPointsDoubles: { type: 'number', displayName: 'Quality Points Doubles' },
        tournamentCountSingles: { type: 'number', displayName: 'Tourn Count', required: true },
        tournamentCountDoubles: { type: 'number', displayName: 'Tourn Count', required: true },
        tournamentCountChampionshipSingles: { type: 'number', displayName: 'Tourn Count', required: true },
        tournamentCountChampionshipDoubles: { type: 'number', displayName: 'Tourn Count' },
        priorRankSingles: { type: 'number', displayName: 'Prior Rank', required: true },
        priorRankDoubles: { type: 'number', displayName: 'Prior Rank', required: true },
        nextOnSingles: { type: 'number', displayName: 'Points added' },
        nextOnDoubles: { type: 'number', displayName: 'Points added' },
        points17ThSingles: { type: 'number', displayName: '17th Tourn' },
        points12ThDoubles: { type: 'number', displayName: '12th Tourn' },
        nextOffSingles: { type: 'number', displayName: 'Next Off' },
        nextOffDoubles: { type: 'number', displayName: 'Next Off' },
        points16ThSingles: { type: 'number', displayName: '16th Tourn' },
        points11ThDoubles: { type: 'number', displayName: '11th Tourn' },
        fullName: { type: 'string', displayName: 'Player', readonly: true },
    },
};

CurrentRanking.schemaAdd = {
    fields: {
        playerId: { type: 'player', displayName: 'Player Id', sortable: true, required: true },
        firstName: { type: 'string', displayName: 'First Name', readonly: true },
        lastName: { type: 'string', displayName: 'Last Name', readonly: true },
        nationality: { type: 'nationality', displayName: 'Nationality', lookup: Object.keys(icoCountries), readonly: true },
        rankDate: { type: 'us-date', displayName: 'Rank Date', required: true },
        rankSingles: { type: 'number', displayName: 'Rank', sortable: true, required: true },
        rankSinglesCd: { type: 'string', displayName: 'Tie' },
        rankDoubles: { type: 'number', displayName: 'Rank', sortable: true, required: true },
        rankDoublesCd: { type: 'string', displayName: 'Tie' },
        rankChampionshipSingles: { type: 'number', displayName: 'Rank', required: true },
        rankChampionshipSinglesCd: { type: 'string', displayName: 'Tie' },
        rankChampionshipDoubles: { type: 'number', displayName: 'Rank' },
        rankChampionshipDoublesCd: { type: 'string', displayName: 'Tie' },
        pointsSingles: { type: 'number', displayName: 'Points', sortable: true, required: true },
        pointsDoubles: { type: 'number', displayName: 'Points', sortable: true, required: true },
        pointsChampionshipSingles: { type: 'number', displayName: 'Points', required: true },
        pointsChampionshipDoubles: { type: 'number', displayName: 'Points' },
        highestSinglesRank: { type: 'number', displayName: 'Highest Rank', required: true },
        highestSinglesRankDate: { type: 'us-date', displayName: 'Highest Rank Date', required: true },
        highestDoublesRank: { type: 'number', displayName: 'Highest Rank', required: true },
        highestDoublesRankDate: { type: 'us-date', displayName: 'Highest Rank Date', required: true },
        qualityPointsSingles: { type: 'number', displayName: 'Quality Points' },
        qualityPointsDoubles: { type: 'number', displayName: 'Quality Points' },
        tournamentCountSingles: { type: 'number', displayName: 'Tournament Count', required: true },
        tournamentCountDoubles: { type: 'number', displayName: 'Tournament Count', required: true },
        tournamentCountChampionshipSingles: { type: 'number', displayName: 'Tournament Count', required: true },
        tournamentCountChampionshipDoubles: { type: 'number', displayName: 'Tournament Count' },
        priorRankSingles: { type: 'number', displayName: 'Prior Rank', required: true },
        priorRankDoubles: { type: 'number', displayName: 'Prior Rank', required: true },
        nextOnSingles: { type: 'number', displayName: 'Next On' },
        nextOnDoubles: { type: 'number', displayName: 'Next On' },
        points17ThSingles: { type: 'number', displayName: '17th Tourn' },
        points12ThDoubles: { type: 'number', displayName: '12th Tourn' },
        nextOffSingles: { type: 'number', displayName: 'Next Off' },
        nextOffDoubles: { type: 'number', displayName: 'Next Off' },
        points16ThSingles: { type: 'number', displayName: '16th Tourn' },
        points11ThDoubles: { type: 'number', displayName: '11th Tourn' },
        fullName: { type: 'string', displayName: 'Player', readonly: true },
    },
};
