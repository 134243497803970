import axios from 'axios';
import { buildParams } from '../../utils/query-param-builder';

export default {
    namespaced: true,

    state: {
        requiredTournaments: [],
        loadingRequiredTournaments: false,
        loadingTournamentsError: null,
        options: {
            queryString: null,
            sortBy: null,
            sortDir: null,
            page: 0,
            pageSize: process.env.VUE_APP_PAGE_SIZE,
        },
    },

    getters: {
        requiredTournaments: (state) => state.requiredTournaments,
        loadingRequiredTournaments: (state) => state.loadingRequiredTournaments,
        loadingTournamentsError: (state) => state.loadingTournamentsError,
        options: (state) => state.options,
    },

    mutations: {
        setRequiredTournaments(state, data) {
            state.requiredTournaments = data;
            state.loadingTournamentsError = null;
        },

        startLoadingRequiredTournaments(state) {
            state.loadingRequiredTournaments = true;
        },

        stopLoadingRequiredTournaments(state) {
            state.loadingRequiredTournaments = false;
        },

        setLoadingRequiredTournamentsError(state, error) {
            state.loadingTournamentsError = error;
            state.requiredTournaments = [];
        }
    },

    actions: {
        async retrieveTournaments(context, options = {}) {
            context.commit('startLoadingRequiredTournaments');
            let params = buildParams(options);
            try {
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/RequiredTournaments`, { params });
                context.commit('setRequiredTournaments', result.data);
                context.commit('stopLoadingRequiredTournaments');
                return Promise.resolve();
            } catch (error) {
                context.commit('stopLoadingRequiredTournaments');
                context.commit('setLoadingRequiredTournamentsError', error);
                return Promise.reject(error);
            }
        },
    }
};
