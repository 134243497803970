<template>
    <div>
        <center>
            <h3>sta files</h3>
        </center>
        <div v-loading="loading">
            <el-upload
                class="mt-32"
                drag
                multiple
                :headers="{ Authorization: token }"
                :action="action"
                :show-file-list="false"
                :file-list="fileList"
                :auto-upload="true"
                :on-success="success"
                :on-error="error"
                :before-upload="start"
                :data="uploadData()"
            >
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">Drop .sta file here or <em>click to upload</em></div>
            </el-upload>
            <el-checkbox v-model="checked">Import regardless tourn_year and tourn_nbr</el-checkbox>
        </div>
    </div>
</template>

<script>
export default {
    props: ['tournYear', 'tournNbr'],
    data() {
        return {
            token: `Bearer ${localStorage.getItem('idToken')}`,
            action: `${process.env.VUE_APP_API_URL}/Edition/${this.tournYear}/${this.tournNbr}/Round/Import/Sta`,
            fileList: [],
            loading: false,
            checked: false,
        };
    },
    mounted() {
        this.loading = false;
        this.checked = false;
    },
    methods: {
        success(data) {
            this.loading = false;
            this.$notify({
                title: 'STA File Import',
                message: 'File import finished!',
                type: 'success',
                duration: 5000,
            });
            this.$emit('success', data);
        },
        error(error, file, fileList) {
            this.loading = false;
            this.$notify({
                title: 'STA File Import',
                message: 'File import failed!',
                type: 'error',
                duration: 5000,
            });
            const requestError = JSON.parse(error.message);
            this.$emit('error', { response: { data: requestError } });
        },
        start() {
            this.loading = true;
        },
        uploadData() {
            let data = {
                importAnyway: this.checked,
            };
            return data;
        },
    },
};
</script>

<style></style>
