<template>
    <div>
        <div class="flex-row mt-0">
            <el-radio-group v-model="sDFlag" size="mini" class="mr-32">
                <el-radio-button label="s">Singles</el-radio-button>
                <el-radio-button label="d">Doubles</el-radio-button>
            </el-radio-group>
            <span class="spacer"></span>
            <!-- <el-button @click="addEntry" plain type="primary" size="mini">
                Add entry
            </el-button> -->
        </div>
        <TournamentSinglesEntries :hidden="!showSingles" :tournamentId="tournamentId" :tournamentYear="tournamentYear" />
        <TournamentDoublesEntries :hidden="showSingles" :tournamentId="tournamentId" :tournamentYear="tournamentYear" />
    </div>
</template>

<script>
export default {
    props: ['tournamentId', 'tournamentYear'],
    data() {
        return {
            sDFlag: 's',
            showDialog: false,
        };
    },
    computed: {
        showSingles() {
            return this.sDFlag === 's';
        },
    },
};
</script>

<style></style>
