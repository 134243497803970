import { Model, isValid } from './model';

export class TournamentRound extends Model {
    isValid() {
        return isValid(TournamentRound.schema, this);
    }
    isValidForAdd() {
        return isValid(TournamentRound.schemaAdd, this);
    }
    isValidForEdit() {
        return isValid(TournamentRound.schema, this);
    }
}

const SCORE_WIDTH = 0.15;
const DASH_WIDTH = 0.1;
const OPEN_BRACKET_WIDTH = 0.1;
const CLOSE_BRACKET_WIDTH = 0.1;

TournamentRound.layout = {
    children: [
        {
            title: 'General',
            children: ['roundName', 'roundNumber', 'numberOfMatchInRound', 'qpmFlag', 'sdFlag'],
        },
        {
            title: 'Team 1',
            children: [{ id: 'player1Id', width: 2 }, { id: 'player1Name', width: 3 }, 'player1Age', 'player1Rank', 'player1SpecialRank', 'draw1', 'seed1', 'team1TeamEntryType'],
        },
        {
            children: [{ id: 'player3Id', width: 2 }, { id: 'player3Name', width: 3 }, 'player3Age', 'player3Rank', 'player2SpecialRank', { type: 'spacer', width: 3 }],
        },
        {
            title: 'Team 2',
            children: [{ id: 'player2Id', width: 2 }, { id: 'player2Name', width: 3 }, 'player2Age', 'player2Rank', 'player3SpecialRank', 'draw2', 'seed2', 'team2TeamEntryType'],
        },
        {
            children: [{ id: 'player4Id', width: 2 }, { id: 'player4Name', width: 3 }, 'player4Age', 'player4Rank', 'player4SpecialRank', { type: 'spacer', width: 3 }],
        },
        {
            title: 'Match Result',
            id: 'score-input',
            children: [{ id: 'set1ScoreWinner', width: SCORE_WIDTH, noPadding: true }, { type: 'spacer', width: DASH_WIDTH, text: '-', class: 'score-dash align-center', noPadding: true }, { id: 'set1ScoreLoser', width: SCORE_WIDTH },
            { type: 'spacer', width: OPEN_BRACKET_WIDTH, text: '(', class: 'score-bracket align-right', noPadding: true }, { id: 'tie1ScoreWinner', width: SCORE_WIDTH, noPadding: true },
            { type: 'spacer', width: DASH_WIDTH, text: '-', class: 'score-dash align-center', noPadding: true }, { id: 'tie1ScoreLoser', width: SCORE_WIDTH, noPadding: true },
            { type: 'spacer', width: CLOSE_BRACKET_WIDTH, text: ')', class: 'score-bracket', noPadding: true },


            { id: 'set2ScoreWinner', width: SCORE_WIDTH, noPadding: true }, { type: 'spacer', width: DASH_WIDTH, text: '-', class: 'score-dash align-center', noPadding: true }, { id: 'set2ScoreLoser', width: SCORE_WIDTH, noPadding: true },
            { type: 'spacer', width: OPEN_BRACKET_WIDTH, text: '(', class: 'score-bracket align-right', noPadding: true }, { id: 'tie2ScoreWinner', width: SCORE_WIDTH, noPadding: true },
            { type: 'spacer', width: DASH_WIDTH, text: '-', class: 'score-dash align-center', noPadding: true }, { id: 'tie2ScoreLoser', width: SCORE_WIDTH, noPadding: true },
            { type: 'spacer', width: CLOSE_BRACKET_WIDTH, text: ')', class: 'score-bracket', noPadding: true },

            { id: 'set3ScoreWinner', width: SCORE_WIDTH, noPadding: true }, { type: 'spacer', width: DASH_WIDTH, text: '-', class: 'score-dash align-center', noPadding: true }, { id: 'set3ScoreLoser', width: SCORE_WIDTH, noPadding: true },
            { type: 'spacer', width: OPEN_BRACKET_WIDTH, text: '(', class: 'score-bracket align-right', noPadding: true }, { id: 'tie3ScoreWinner', width: SCORE_WIDTH, noPadding: true },
            { type: 'spacer', width: DASH_WIDTH, text: '-', class: 'score-dash align-center', noPadding: true }, { id: 'tie3ScoreLoser', width: SCORE_WIDTH, noPadding: true },
            { type: 'spacer', width: CLOSE_BRACKET_WIDTH, text: ')', class: 'score-bracket', noPadding: true }, { type: 'spacer' }, 'matchWinner', 'reasonCode', { type: 'spacer' }],
        },
    ],
};

TournamentRound.layoutAdd = {
    children: [
        {
            title: 'General',
            children: ['roundNumber', 'numberOfMatchInRound', 'qpmFlag', 'sdFlag'],
        },
        {
            title: 'Team 1',
            children: [{ id: 'player1Id' }, 'seed1', 'team1TeamEntryType', { id: 'player3Id' }],
        },
        {
            title: 'Team 2',
            children: [{ id: 'player2Id' }, 'seed2', 'team1TeamEntryType', { id: 'player4Id' }],
        },
        {
            title: 'Match Result',
            id: 'score-input',
            children: [{ id: 'set1ScoreWinner', width: SCORE_WIDTH }, { type: 'spacer', width: DASH_WIDTH, text: '-', class: 'score-dash' }, { id: 'set1ScoreLoser', width: SCORE_WIDTH },
            { type: 'spacer', width: OPEN_BRACKET_WIDTH, text: '(', class: 'score-bracket align-right' }, { id: 'tie1ScoreWinner', width: SCORE_WIDTH },
            { type: 'spacer', width: DASH_WIDTH, text: '-', class: 'score-dash' }, { id: 'tie1ScoreLoser', width: SCORE_WIDTH },
            { type: 'spacer', width: CLOSE_BRACKET_WIDTH, text: ')', class: 'score-bracket' },


            { id: 'set2ScoreWinner', width: SCORE_WIDTH }, { type: 'spacer', width: DASH_WIDTH, text: '-', class: 'score-dash' }, { id: 'set2ScoreLoser', width: SCORE_WIDTH },
            { type: 'spacer', width: OPEN_BRACKET_WIDTH, text: '(', class: 'score-bracket align-right' }, { id: 'tie2ScoreWinner', width: SCORE_WIDTH },
            { type: 'spacer', width: DASH_WIDTH, text: '-', class: 'score-dash' }, { id: 'tie2ScoreLoser', width: SCORE_WIDTH },
            { type: 'spacer', width: CLOSE_BRACKET_WIDTH, text: ')', class: 'score-bracket' },

            { id: 'set3ScoreWinner', width: SCORE_WIDTH }, { type: 'spacer', width: DASH_WIDTH, text: '-', class: 'score-dash' }, { id: 'set3ScoreLoser', width: SCORE_WIDTH },
            { type: 'spacer', width: OPEN_BRACKET_WIDTH, text: '(', class: 'score-bracket align-right' }, { id: 'tie3ScoreWinner', width: SCORE_WIDTH },
            { type: 'spacer', width: DASH_WIDTH, text: '-', class: 'score-dash' }, { id: 'tie3ScoreLoser', width: SCORE_WIDTH },
            { type: 'spacer', width: CLOSE_BRACKET_WIDTH, text: ')', class: 'score-bracket' }, { type: 'spacer' }, 'matchWinner', 'reasonCode', { type: 'spacer' }],
        },
    ],
};

TournamentRound.schema = {
    fields: {
        tournamentId: { type: 'number', displayName: 'tournamentId', required: true, readonly: true },
        year: { type: 'number', displayName: 'year', required: true, readonly: true },
        roundNumber: { type: 'number', displayName: 'Round', required: true, readonly: true, },
        roundName: { type: 'string', displayName: 'Round name', placeholder: 'populated', required: true },
        numberOfMatchInRound: { type: 'string', displayName: 'Match', required: true, readonly: true },
        qpmFlag: {
            displayName: 'Q/M',
            type: 'string',
            group: 'general',
            required: true,
            readonly: true,
            lookup: [
                { id: 'M', displayName: 'Main' },
                { id: 'Q', displayName: 'Quali' },
            ],
        },
        sdFlag: {
            type: 'string',
            displayName: 'S/D',
            required: true,
            readonly: true,
            lookup: [
                { id: 'S', displayName: 'Singles' },
                { id: 'D', displayName: 'Doubles' },
            ],
        },
        entryBy: { type: 'string', displayName: 'Entered By', readonly: true, clearable: true },
        entryDate: { type: 'date', displayName: 'Entry Date', readonly: true, clearable: true },
        player1Id: {
            type: 'player', displayName: 'Id 1', required: true, clearable: true,
            playerName: 'player1Name',
            playerAge: 'player1Age',
            playerRank: 'player1Rank',
            playerSpecialRank: 'player1SpecialRank'
        },
        team1TeamName: { type: 'round-player', displayName: 'Player / Team Name 1', placeholder: 'populated', team: '1', clearable: true },
        player1Age: { type: 'number', displayName: 'Age 1', placeholder: 'populated', clearable: true },
        player2Id: {
            type: 'player', displayName: 'Id 2', required: true, clearable: true,
            playerName: 'player2Name',
            playerAge: 'player2Age',
            playerRank: 'player2Rank',
            playerSpecialRank: 'player2SpecialRank'
        },
        team2TeamName: { type: 'round-player', displayName: 'Player / Team Name 2', placeholder: 'populated', team: '2', clearable: true },
        player2Age: { type: 'number', displayName: 'Age 2', placeholder: 'populated', clearable: true },
        seed1: { type: 'number', displayName: 'Seed 1', clearable: true },
        team1TeamEntryType: {
            type: 'string',
            displayName: 'Entry Type 1', clearable: true,
            lookup: [
                { id: ' ', displayName: '-' },
                { id: 'P', displayName: 'Use Of Protected Rank' },
                { id: 'E', displayName: 'Elimination' },
                { id: 'L', displayName: 'Lucky Loser' },
                { id: 'Q', displayName: 'Qualifier' },
                { id: 'S', displayName: 'Special Exempt' },
                { id: 'A', displayName: 'Alternate' },
                { id: 'W', displayName: 'Wildcard' },
            ],
        },
        seed2: { type: 'number', displayName: 'Seed 2', clearable: true },
        team2TeamEntryType: {
            type: 'string',
            displayName: 'Entry Type 2', clearable: true,
            lookup: [
                { id: ' ', displayName: '-' },
                { id: 'P', displayName: 'Use Of Protected Rank' },
                { id: 'E', displayName: 'Elimination' },
                { id: 'L', displayName: 'Lucky Loser' },
                { id: 'Q', displayName: 'Qualifier' },
                { id: 'S', displayName: 'Special Exempt' },
                { id: 'A', displayName: 'Alternate' },
                { id: 'W', displayName: 'Wildcard' },
            ],
        },
        player3Id: {
            type: 'player', displayName: 'Id 3', clearable: true,
            playerName: 'player3Name',
            playerAge: 'player3Age',
            playerRank: 'player3Rank',
            playerSpecialRank: 'player3SpecialRank'
        },
        player3Age: { type: 'number', displayName: 'Age 3', placeholder: 'populated', clearable: true },
        player4Id: {
            type: 'player', displayName: 'Id 4', clearable: true,
            playerName: 'player4Name',
            playerAge: 'player34ge',
            playerRank: 'player4Rank',
            playerSpecialRank: 'player4SpecialRank'
        },
        player4Age: { type: 'number', displayName: 'Age 4', placeholder: 'populated', clearable: true },
        draw1: { type: 'number', displayName: 'Draw 1', placeholder: 'populated', clearable: true },
        draw2: { type: 'number', displayName: 'Draw 2', placeholder: 'populated', clearable: true },
        rank1: { type: 'number', displayName: 'Rank 1', placeholder: 'populated', clearable: true },
        rank2: { type: 'number', displayName: 'Rank 2', placeholder: 'populated', clearable: true },
        specialRank1: { type: 'number', displayName: 'Spcl Rank 1', placeholder: 'populated', clearable: true },
        specialRank2: { type: 'number', displayName: 'Spcl Rank 2', placeholder: 'populated', clearable: true },
        set1Score1: { type: 'number-no-arrows', displayName: 'Team 1 Set 1', clearable: true },
        set2Score1: { type: 'number-no-arrows', displayName: 'Team 1 Set 2', clearable: true },
        set3Score1: { type: 'number-no-arrows', displayName: 'Team 1 Set 3', clearable: true },
        set4Score1: { type: 'number-no-arrows', displayName: 'Team 1 Set 4', clearable: true },
        set5Score1: { type: 'number-no-arrows', displayName: 'Team 1 Set 5', clearable: true },
        tie1Score1: { type: 'number-no-arrows', displayName: 'Team 1 Tie-Break 1', clearable: true },
        tie2Score1: { type: 'number-no-arrows', displayName: 'Team 1 Tie-Break 2', clearable: true },
        tie3Score1: { type: 'number-no-arrows', displayName: 'Team 1 Tie-Break 3', clearable: true },
        tie4Score1: { type: 'number-no-arrows', displayName: 'Team 1 Tie-Break 4', clearable: true },
        tie5Score1: { type: 'number-no-arrows', displayName: 'Team 1 Tie-Break 5', clearable: true },
        set1Score2: { type: 'number-no-arrows', displayName: 'Team 2 Set 1', clearable: true },
        set2Score2: { type: 'number-no-arrows', displayName: 'Team 2 Set 2', clearable: true },
        set3Score2: { type: 'number-no-arrows', displayName: 'Team 2 Set 3', clearable: true },
        set4Score2: { type: 'number-no-arrows', displayName: 'Team 2 Set 4', clearable: true },
        set5Score2: { type: 'number-no-arrows', displayName: 'Team 2 Set 5', clearable: true },
        tie1Score2: { type: 'number-no-arrows', displayName: 'Team 2 Tie-Break 1', clearable: true },
        tie2Score2: { type: 'number-no-arrows', displayName: 'Team 2 Tie-Break 2', clearable: true },
        tie3Score2: { type: 'number-no-arrows', displayName: 'Team 2 Tie-Break 3', clearable: true },
        tie4Score2: { type: 'number-no-arrows', displayName: 'Team 2 Tie-Break 4', clearable: true },
        tie5Score2: { type: 'number-no-arrows', displayName: 'Team 2 Tie-Break 5', clearable: true },
        set1ScoreWinner: { type: 'number-no-arrows', displayName: 'Set', clearable: true },
        set2ScoreWinner: { type: 'number-no-arrows', displayName: 'Set', clearable: true },
        set3ScoreWinner: { type: 'number-no-arrows', displayName: 'Set', clearable: true },
        set4ScoreWinner: { type: 'number-no-arrows', displayName: 'Set', clearable: true },
        set5ScoreWinner: { type: 'number-no-arrows', displayName: 'Set', clearable: true },
        tie1ScoreWinner: { type: 'number-no-arrows', displayName: 'TB', clearable: true },
        tie2ScoreWinner: { type: 'number-no-arrows', displayName: 'TB', clearable: true },
        tie3ScoreWinner: { type: 'number-no-arrows', displayName: 'TB', clearable: true },
        tie4ScoreWinner: { type: 'number-no-arrows', displayName: 'TB', clearable: true },
        tie5ScoreWinner: { type: 'number-no-arrows', displayName: 'TB', clearable: true },
        set1ScoreLoser: { type: 'number-no-arrows', displayName: '1', clearable: true },
        set2ScoreLoser: { type: 'number-no-arrows', displayName: '2', clearable: true },
        set3ScoreLoser: { type: 'number-no-arrows', displayName: '3', clearable: true },
        set4ScoreLoser: { type: 'number-no-arrows', displayName: '4', clearable: true },
        set5ScoreLoser: { type: 'number-no-arrows', displayName: '5', clearable: true },
        tie1ScoreLoser: { type: 'number-no-arrows', displayName: '1', clearable: true },
        tie2ScoreLoser: { type: 'number-no-arrows', displayName: '2', clearable: true },
        tie3ScoreLoser: { type: 'number-no-arrows', displayName: '3', clearable: true },
        tie4ScoreLoser: { type: 'number-no-arrows', displayName: '4', clearable: true },
        tie5ScoreLoser: { type: 'number-no-arrows', displayName: '5', clearable: true },
        scoreAsString: { type: 'string', displayName: 'Score', fieldName: 'scoreAsString', readonly: true, placeholder: 'populated', clearable: true },
        scores: { type: 'string', displayName: 'Score', fieldName: 'scores', readonly: true, placeholder: 'populated', clearable: true },
        matchWinner: { type: 'string', displayName: 'Winner', required: true, clearable: true },
        reasonCode: {
            type: 'string',
            displayName: 'Method of Win',
            required: true, clearable: true,
            lookup: [
                { id: 'W', displayName: '-' },
                { id: 'R', displayName: 'Retirement' },
                { id: 'V', displayName: 'Default' },
                { id: 'B', displayName: 'Bye' },
                { id: 'D', displayName: 'Walkover' },
                { id: '', displayName: ' ' }, // HACK
            ],
        },
        matchTimeInMinutes: { type: 'string', displayName: 'matchTimeInMinutes', clearable: true },
        matchForm: { type: 'string', displayName: 'matchForm', clearable: true },
        player1Name: { type: 'round-player', displayName: 'Player 1 Name', readonly: true, placeholder: 'populated', team: '1', clearable: true },
        player2Name: { type: 'round-player', displayName: 'Player 2 Name', readonly: true, placeholder: 'populated', team: '2', clearable: true },
        player3Name: { type: 'round-player', displayName: 'Player 3 Name', readonly: true, placeholder: 'populated', team: '1', clearable: true },
        player4Name: { type: 'round-player', displayName: 'Player 4 Name', readonly: true, placeholder: 'populated', team: '2', clearable: true },
        player1Rank: { type: 'number', displayName: 'Rank 1', readonly: true, placeholder: 'populated', clearable: true },
        player2Rank: { type: 'number', displayName: 'Rank 2', readonly: true, placeholder: 'populated', clearable: true },
        player3Rank: { type: 'number', displayName: 'Rank 3', readonly: true, placeholder: 'populated', clearable: true },
        player4Rank: { type: 'number', displayName: 'Rank 4', readonly: true, placeholder: 'populated', clearable: true },
        player1SpecialRank: { type: 'number', displayName: 'Spcl Rank 1', readonly: true, placeholder: 'populated', clearable: true },
        player2SpecialRank: { type: 'number', displayName: 'Spcl Rank 2', readonly: true, placeholder: 'populated', clearable: true },
        player3SpecialRank: { type: 'number', displayName: 'Spcl Rank 3', readonly: true, placeholder: 'populated', clearable: true },
        player4SpecialRank: { type: 'number', displayName: 'Spcl Rank 4', readonly: true, placeholder: 'populated', clearable: true },
        /*
        aces1: { type: 'string', displayName: 'Aces1' },
        doubleFaults1: { type: 'string', displayName: 'DoubleFaults1' },
        firstServesPlayed1: { type: 'string', displayName: 'FirstServesPlayed1' },
        firstServesWon1: { type: 'string', displayName: 'FirstServesWon1' },
        secondServesPlayed1: { type: 'string', displayName: 'SecondServesPlayed1' },
        secondServesWon: { type: 'string', displayName: 'SecondServesWon' },
        serviceGamesPlayed1: { type: 'string', displayName: 'ServiceGamesPlayed1' },
        breakPointChances1: { type: 'string', displayName: 'BreakPointChances1' },
        breakPointsConverted1: { type: 'string', displayName: 'BreakPointsConverted1' },
        firstServiceReturnChances1: { type: 'string', displayName: 'FirstServiceReturnChances1' },
        firstServiceReturnPointsConverted1: { type: 'string', displayName: 'FirstServiceReturnPointsConverted1' },
        secondServiceReturnChances1: { type: 'string', displayName: 'SecondServiceReturnChances1' },
        secondServiceReturnPointsConverted1: { type: 'string', displayName: 'SecondServiceReturnPointsConverted1' },
        breakPointsFaced1: { type: 'string', displayName: 'BreakPointsFaced1' },
        breakPointsLost1: { type: 'string', displayName: 'BreakPointsLost1' },
        returnGamesPlayed1: { type: 'string', displayName: 'ReturnGamesPlayed1' },
        aces2: { type: 'string', displayName: 'Aces2' },
        doubleFaults2: { type: 'string', displayName: 'DoubleFaults2' },
        firstServesPlayed2: { type: 'string', displayName: 'FirstServesPlayed2' },
        firstServesWon2: { type: 'string', displayName: 'FirstServesWon2' },
        secondServesPlayed2: { type: 'string', displayName: 'SecondServesPlayed2' },
        secondServesWon2: { type: 'string', displayName: 'SecondServesWon2' },
        serviceGamesPlayed2: { type: 'string', displayName: 'ServiceGamesPlayed2' },
        breakPointChances2: { type: 'string', displayName: 'BreakPointChances2' },
        breakPointsConverted2: { type: 'string', displayName: 'BreakPointsConverted2' },
        firstServiceReturnChances2: { type: 'string', displayName: 'FirstServiceReturnChances2' },
        firstServiceReturnPointsConverted2: { type: 'string', displayName: 'FirstServiceReturnPointsConverted2' },
        secondServiceReturnChances2: { type: 'string', displayName: 'SecondServiceReturnChances2' },
        secondServiceReturnPointsConverted2: { type: 'string', displayName: 'SecondServiceReturnPointsConverted2' },
        breakPointsFaced2: { type: 'string', displayName: 'BreakPointsFaced2' },
        breakPointsLost2: { type: 'string', displayName: 'BreakPointsLost2' },
        returnGamesPlayed2: { type: 'string', displayName: 'ReturnGamesPlayed2' },
        */
    },
};

TournamentRound.schemaAdd = {
    fields: {
        tournamentId: { type: 'number', displayName: 'tournamentId', required: true, readonly: true },
        year: { type: 'number', displayName: 'year', required: true, readonly: true },
        roundNumber: {
            type: 'number',
            displayName: 'Round',
            required: true,
            sysCode: 'HIGHEST_ROUND',
            lookup: [
                { id: 1, displayName: 'R128' },
                { id: 2, displayName: 'R64' },
                { id: 3, displayName: 'R32' },
                { id: 4, displayName: 'R16' },
                { id: 5, displayName: 'QF' },
                { id: 6, displayName: 'SF' },
                { id: 7, displayName: 'F' },
                { id: 8, displayName: 'W' },
            ],
        },
        roundName: { type: 'string', displayName: 'Round name' },
        numberOfMatchInRound: { type: 'string', displayName: 'Match', required: true },
        qpmFlag: {
            displayName: 'Q/M',
            type: 'string',
            group: 'general',
            required: true,
            lookup: [
                { id: 'M', displayName: 'Main' },
                { id: 'Q', displayName: 'Quali' },
            ],
        },
        sdFlag: {
            type: 'string',
            displayName: 'S/D',
            required: true,
            lookup: [
                { id: 'S', displayName: 'Singles' },
                { id: 'D', displayName: 'Doubles' },
            ],
            fieldName: 'sdFlag',
        },
        entryBy: { type: 'string', displayName: 'Entered By' },
        entryDate: { type: 'date', displayName: 'Entry Date' },
        player1Id: { type: 'player', displayName: 'Id 1', required: true, fieldName: 'player1Id' },
        team1TeamName: { type: 'string', displayName: 'Player / Team Name 1' },
        player1Age: { type: 'number', displayName: 'Age 1', fieldName: 'player1Age' },
        player2Id: { type: 'player', displayName: 'Id 2', required: true, fieldName: 'player2Id' },
        team2TeamName: { type: 'string', displayName: 'Player / Team Name 2' },
        player2Age: { type: 'number', displayName: 'Age 2', fieldName: 'player2Age' },
        seed1: { type: 'number', displayName: 'Seed 1' },
        team1TeamEntryType: {
            type: 'string',
            displayName: 'Entry Type 1',
            lookup: [
                { id: ' ', displayName: '-' },
                { id: 'P', displayName: 'Use Of Protected Rank' },
                { id: 'E', displayName: 'Elimination' },
                { id: 'L', displayName: 'Lucky Loser' },
                { id: 'Q', displayName: 'Qualifier' },
                { id: 'S', displayName: 'Special Exempt' },
                { id: 'A', displayName: 'Alternate' },
                { id: 'W', displayName: 'Wildcard' },
            ],
        },
        seed2: { type: 'number', displayName: 'Seed 2' },
        team2TeamEntryType: {
            type: 'string',
            displayName: 'Entry Type 2',
            lookup: [
                { id: ' ', displayName: '-' },
                { id: 'P', displayName: 'Use Of Protected Rank' },
                { id: 'E', displayName: 'Elimination' },
                { id: 'L', displayName: 'Lucky Loser' },
                { id: 'Q', displayName: 'Qualifier' },
                { id: 'S', displayName: 'Special Exempt' },
                { id: 'A', displayName: 'Alternate' },
                { id: 'W', displayName: 'Wildcard' },
            ],
        },
        player3Id: { type: 'player', displayName: 'Id 3' },
        player3Age: { type: 'number', displayName: 'Age 3' },
        player4Id: { type: 'player', displayName: 'Id 4' },
        player4Age: { type: 'number', displayName: 'Age 4' },
        draw1: { type: 'number', displayName: 'Draw 1' },
        draw2: { type: 'number', displayName: 'Draw 2' },
        rank1: { type: 'number', displayName: 'Rank 1' },
        rank2: { type: 'number', displayName: 'Rank 2' },
        specialRank1: { type: 'number', displayName: 'Spcl Rank 1' },
        specialRank2: { type: 'number', displayName: 'Spcl Rank 2' },
        set1Score1: { type: 'number-no-arrows', displayName: 'Team 1 Set 1' },
        set2Score1: { type: 'number-no-arrows', displayName: 'Team 1 Set 2' },
        set3Score1: { type: 'number-no-arrows', displayName: 'Team 1 Set 3' },
        set4Score1: { type: 'number-no-arrows', displayName: 'Team 1 Set 4' },
        set5Score1: { type: 'number-no-arrows', displayName: 'Team 1 Set 5' },
        tie1Score1: { type: 'number-no-arrows', displayName: 'Team 1 Tie-Break 1' },
        tie2Score1: { type: 'number-no-arrows', displayName: 'Team 1 Tie-Break 2' },
        tie3Score1: { type: 'number-no-arrows', displayName: 'Team 1 Tie-Break 3' },
        tie4Score1: { type: 'number-no-arrows', displayName: 'Team 1 Tie-Break 4' },
        tie5Score1: { type: 'number-no-arrows', displayName: 'Team 1 Tie-Break 5' },
        set1Score2: { type: 'number-no-arrows', displayName: 'Team 2 Set 1' },
        set2Score2: { type: 'number-no-arrows', displayName: 'Team 2 Set 2' },
        set3Score2: { type: 'number-no-arrows', displayName: 'Team 2 Set 3' },
        set4Score2: { type: 'number-no-arrows', displayName: 'Team 2 Set 4' },
        set5Score2: { type: 'number-no-arrows', displayName: 'Team 2 Set 5' },
        tie1Score2: { type: 'number-no-arrows', displayName: 'Team 2 Tie-Break 1' },
        tie2Score2: { type: 'number-no-arrows', displayName: 'Team 2 Tie-Break 2' },
        tie3Score2: { type: 'number-no-arrows', displayName: 'Team 2 Tie-Break 3' },
        tie4Score2: { type: 'number-no-arrows', displayName: 'Team 2 Tie-Break 4' },
        tie5Score2: { type: 'number-no-arrows', displayName: 'Team 2 Tie-Break 5' },
        set1ScoreWinner: { type: 'number-no-arrows', displayName: 'Set' },
        set2ScoreWinner: { type: 'number-no-arrows', displayName: 'Set' },
        set3ScoreWinner: { type: 'number-no-arrows', displayName: 'Set' },
        set4ScoreWinner: { type: 'number-no-arrows', displayName: 'Set' },
        set5ScoreWinner: { type: 'number-no-arrows', displayName: 'Set' },
        tie1ScoreWinner: { type: 'number-no-arrows', displayName: 'TB' },
        tie2ScoreWinner: { type: 'number-no-arrows', displayName: 'TB' },
        tie3ScoreWinner: { type: 'number-no-arrows', displayName: 'TB' },
        tie4ScoreWinner: { type: 'number-no-arrows', displayName: 'TB' },
        tie5ScoreWinner: { type: 'number-no-arrows', displayName: 'TB' },
        set1ScoreLoser: { type: 'number-no-arrows', displayName: '1' },
        set2ScoreLoser: { type: 'number-no-arrows', displayName: '2' },
        set3ScoreLoser: { type: 'number-no-arrows', displayName: '3' },
        set4ScoreLoser: { type: 'number-no-arrows', displayName: '4' },
        set5ScoreLoser: { type: 'number-no-arrows', displayName: '5' },
        tie1ScoreLoser: { type: 'number-no-arrows', displayName: '1' },
        tie2ScoreLoser: { type: 'number-no-arrows', displayName: '2' },
        tie3ScoreLoser: { type: 'number-no-arrows', displayName: '3' },
        tie4ScoreLoser: { type: 'number-no-arrows', displayName: '4' },
        tie5ScoreLoser: { type: 'number-no-arrows', displayName: '5' },
        scoreAsString: { type: 'string', displayName: 'Score', fieldName: 'scoreAsString' },
        scores: { type: 'string', displayName: 'Score', fieldName: 'scores' },
        matchWinner: { type: 'string', displayName: 'Winner', fieldName: 'matchWinner', required: true },
        reasonCode: {
            type: 'string',
            displayName: 'Method of Win',
            required: true,
            lookup: [
                { id: 'W', displayName: '-' },
                { id: 'R', displayName: 'Retirement' },
                { id: 'V', displayName: 'Default' },
                { id: 'B', displayName: 'Bye' },
                { id: 'D', displayName: 'Walkover' },
                { id: '', displayName: ' ' }, // HACK
            ],
        },
        matchTimeInMinutes: { type: 'string', displayName: 'matchTimeInMinutes' },
        matchForm: { type: 'string', displayName: 'matchForm' },
        aces1: { type: 'string', displayName: 'Aces1' },
        doubleFaults1: { type: 'string', displayName: 'DoubleFaults1' },
        firstServesPlayed1: { type: 'string', displayName: 'FirstServesPlayed1' },
        firstServesWon1: { type: 'string', displayName: 'FirstServesWon1' },
        secondServesPlayed1: { type: 'string', displayName: 'SecondServesPlayed1' },
        secondServesWon: { type: 'string', displayName: 'SecondServesWon' },
        serviceGamesPlayed1: { type: 'string', displayName: 'ServiceGamesPlayed1' },
        breakPointChances1: { type: 'string', displayName: 'BreakPointChances1' },
        breakPointsConverted1: { type: 'string', displayName: 'BreakPointsConverted1' },
        firstServiceReturnChances1: { type: 'string', displayName: 'FirstServiceReturnChances1' },
        firstServiceReturnPointsConverted1: { type: 'string', displayName: 'FirstServiceReturnPointsConverted1' },
        secondServiceReturnChances1: { type: 'string', displayName: 'SecondServiceReturnChances1' },
        secondServiceReturnPointsConverted1: { type: 'string', displayName: 'SecondServiceReturnPointsConverted1' },
        breakPointsFaced1: { type: 'string', displayName: 'BreakPointsFaced1' },
        breakPointsLost1: { type: 'string', displayName: 'BreakPointsLost1' },
        returnGamesPlayed1: { type: 'string', displayName: 'ReturnGamesPlayed1' },
        aces2: { type: 'string', displayName: 'Aces2' },
        doubleFaults2: { type: 'string', displayName: 'DoubleFaults2' },
        firstServesPlayed2: { type: 'string', displayName: 'FirstServesPlayed2' },
        firstServesWon2: { type: 'string', displayName: 'FirstServesWon2' },
        secondServesPlayed2: { type: 'string', displayName: 'SecondServesPlayed2' },
        secondServesWon2: { type: 'string', displayName: 'SecondServesWon2' },
        serviceGamesPlayed2: { type: 'string', displayName: 'ServiceGamesPlayed2' },
        breakPointChances2: { type: 'string', displayName: 'BreakPointChances2' },
        breakPointsConverted2: { type: 'string', displayName: 'BreakPointsConverted2' },
        firstServiceReturnChances2: { type: 'string', displayName: 'FirstServiceReturnChances2' },
        firstServiceReturnPointsConverted2: { type: 'string', displayName: 'FirstServiceReturnPointsConverted2' },
        secondServiceReturnChances2: { type: 'string', displayName: 'SecondServiceReturnChances2' },
        secondServiceReturnPointsConverted2: { type: 'string', displayName: 'SecondServiceReturnPointsConverted2' },
        breakPointsFaced2: { type: 'string', displayName: 'BreakPointsFaced2' },
        breakPointsLost2: { type: 'string', displayName: 'BreakPointsLost2' },
        returnGamesPlayed2: { type: 'string', displayName: 'ReturnGamesPlayed2' },
    },
};
