<template>
    <div>
        <DialogAddItem
            :visibleSync="addDialogVisible"
            dtoName="Tournament Result"
            :selected="selectedTournamentResult"
            :schema="schema"
            :layout="layout"
            @save="saveChanges"
            @change="onChange"
            @close="addDialogVisible = false"
        />

        <DialogEditItem
            :visibleSync="editDialogVisible"
            dtoName="Tournament Result"
            :selected="selectedTournamentResult"
            :schema="schema"
            :layout="layout"
            :widthPerc="90"
            showNav="true"
            @save="saveChanges"
            @change="onChange"
            @delete="deleteCurrentTournamentResult"
            @close="onClose"
            @prev="gotoPrevTournamentResult"
            @next="gotoNextTournamentResult"
        />

        <ErrorDisplay :requestError="this.requestError" @closed="clearError" />

        <div class="flex-row mt-0">
            <span class="spacer"></span>
            <el-button v-if="!isBaselineReader" @click="addTournamentResults" plain type="primary" size="mini"> Add tournament result </el-button>
            <el-button v-if="!isBaselineReader" @click="createResults" size="mini" plain type="primary" v-loading="loadingTournamentResults"
                >Create tournament results</el-button
            >
            <el-button v-if="!isBaselineReader" @click="deleteAllTournamentResults" plain type="danger" size="mini"> Delete ALL tournament results </el-button>
            <el-button @click="exportCsv" plain class="ml-8" size="mini">Export CSV</el-button>
        </div>

        <div class="mt-8">
            <el-collapse>
                <el-collapse-item title="Draw, prize, point information. Click to show/ hide">
                    <FormGroup :schema="schemaTournament" :model="tournament" :layout="layoutTournament" class="mt-8" />

                    <DataTable
                        v-loading="loadingTournamentResults"
                        :items="tournamentPrizes"
                        :fields="['draw', 'prize256', 'prize128', 'prize64', 'prize32', 'prize16', 'prizeQtr', 'prizeSemi', 'prizeRun', 'prizeWin']"
                        :schema="schemaPrizes"
                        :autoSort="false"
                        @item-clicked="prizeClicked"
                    />
                    <DataTable
                        v-loading="loadingTournamentResults"
                        :items="tournamentPoints"
                        :fields="['draw', 'points256', 'points128', 'points64', 'points32', 'points16', 'pointsQtr', 'pointsSemi', 'pointsRun', 'pointsWin']"
                        :schema="schemaPoints"
                        :autoSort="false"
                        @item-clicked="pointsClicked"
                    />
                </el-collapse-item>
            </el-collapse>
        </div>

        <DataTable
            v-if="!loadingError"
            v-loading="loadingTournamentResults"
            class="mr-8"
            :items="tournamentResults"
            :fields="[
                'fullName',
                'rank',
                'partnerFullName',
                'sdFlag',
                'qpmFlag',
                'round',
                'seed',
                'entryMethod',
                'entryMethodQualifying',
                'points',
                'prizeMoney',
            ]"
            :schema="schema"
            :autoSort="false"
            @item-clicked="itemClicked"
        />

        <div class="flex-row mt-0 mr-32">
            <span class="spacer"></span>
            <DataTable
                v-if="!loadingError"
                v-loading="loadingTournamentResults"
                :items="sums"
                :fields="['pointsChampionship', 'points', 'prizeMoney']"
                :schema="schemaSums"
                :autoSort="false"
            />
        </div>
    </div>
</template>

<script>
import { TournamentResult, TournamentResultSums } from '../models/tournament-result';
import { TournamentPoint } from '../models/tournament-point';
import { TournamentPrize } from '../models/tournament-prize';
import { Tournament } from '../models/tournament';
import { mapGetters, mapActions } from 'vuex';
import { csvExporter } from '../utils/csv-exporter';
import moment from 'moment';
import FileSaver from 'file-saver';
import { retrieveDtos, deleteConfirmDto, createConfirmDto, updateConfirmDto } from '../utils/crud-confirm';
import { isBaselineReader } from '../services/authGroups';

export default {
    props: ['tournamentId', 'tournamentYear'],
    data() {
        return {
            schema: TournamentResult.schema,
            layout: TournamentResult.layout,
            schemaSums: TournamentResultSums.schema,
            schemaPoints: TournamentPoint.schema,
            schemaPrizes: TournamentPrize.schema,
            schemaTournament: Tournament.schema,
            layoutTournament: Tournament.layoutDrawInformation,
            editDialogVisible: false,
            addDialogVisible: false,
            selectedTournamentResult: null,
            tournamentResultsPointer: null,
            mode: null,
            requestError: null,
            isBaselineReader: isBaselineReader(),
        };
    },
    mounted() {
        this.refresh();
    },
    computed: {
        ...mapGetters('tournamentResults', ['tournamentResults', 'loadingTournamentResults', 'loadingError']),
        ...mapGetters('tournamentDetails', ['tournament', 'loadingTournament']),
        ...mapGetters('tournamentPoints', ['tournamentPoints', 'loadingTournamentPoints']),
        ...mapGetters('tournamentPrizes', ['tournamentPrizes', 'loadingTournamentPrizes']),
        tournaments() {
            return [this.tournament.data];
        },
        sums() {
            let sums = {
                points: 0.0,
                pointsChampionship: 0.0,
                prizeMoney: 0.0,
                currency: 'No',
            };
            this.tournamentResults.forEach((r) => {
                sums.points += r.points;
                sums.pointsChampionship += r.pointsChampionship;
                sums.prizeMoney += r.prizeMoney;
                sums.currency = r.currency;
            });
            return [sums];
        },
    },
    methods: {
        exportCsv() {
            const data = csvExporter(this.schema, this.tournamentResults);
            const file = new File([data], `tourn_results_${moment().format('DD_MM_YYYY HH_mm_SS')}.csv`, { type: 'text/csv' });
            FileSaver.saveAs(file);
        },
        ...mapActions('tournamentResults', [
            'findTournamentResults',
            'createTournamentResult',
            'updateTournamentResult',
            'deleteTournamentResult',
            'createTournamentResults',
            'deleteTournamentResults',
        ]),
        ...mapActions('tournamentPoints', ['findTournamentPointsForTournament']),
        ...mapActions('tournamentPrizes', ['findTournamentPrizesForTournament']),
        refresh() {
            let options = {
                id: this.tournamentId,
                year: this.tournamentYear,
            };
            let params = {
                self: this,
                fRetrieve: this.findTournamentResults,
                options: options,
                dtosName: 'Tournament Results',
            };
            retrieveDtos(params);
            params = {
                self: this,
                fRetrieve: this.findTournamentPointsForTournament,
                options: options,
                dtosName: 'Tournament Points',
            };
            retrieveDtos(params);
            params = {
                self: this,
                fRetrieve: this.findTournamentPrizesForTournament,
                options: options,
                dtosName: 'Tournament Prizes',
            };
            retrieveDtos(params);
        },
        createResults() {
            this.$confirm('This will create Tournament Results. Please confirm.', {
                confirmButtonText: 'Create Tournament Results',
                cancelButtonText: 'Cancel',
                type: 'warning',
            }).then(() => {
                this.createTournamentResults({ season: this.tournamentYear, tournamentId: this.tournamentId })
                    .then(() => {
                        this.crudCallback();
                        this.$notify({
                            title: 'Create Tournament Results',
                            message: 'Tournament Results successfully created',
                            type: 'success',
                            duration: 5000,
                        });
                    })
                    .catch((error) => {
                        this.requestError = error;
                    });
            });
        },
        itemClicked(item, index) {
            this.selectedTournamentResult = new TournamentResult(item);
            this.mode = 'edit';
            this.editDialogVisible = true;
            this.tournamentResultsPointer = parseInt(index);
        },
        pointsClicked(item) {
            let routeData = this.$router.resolve({ name: 'tournament-points', query: { queryString: item.tournamentPointId } });
            window.open(routeData.href, '_blank');
        },
        prizeClicked(item, index) {
            let routeData = this.$router.resolve({ name: 'tournament-prizes', query: { queryString: item.prizeId } });
            window.open(routeData.href, '_blank');
        },
        addTournamentResults() {
            this.selectedTournamentResult = new TournamentResult({
                season: this.tournamentYear,
                tournamentId: this.tournamentId,
            });
            this.mode = 'add';
            this.addDialogVisible = true;
        },
        gotoPrevTournamentResult() {
            if (this.tournamentResultsPointer == 0) {
                return;
            }
            this.tournamentResultsPointer -= 1;
            this.selectedTournamentResult = new TournamentResult(this.tournamentResults[this.tournamentResultsPointer]);
        },
        gotoNextTournamentResult() {
            if (this.tournamentResultsPointer == this.tournamentResults.length - 1) {
                return;
            }
            this.tournamentResultsPointer += 1;
            this.selectedTournamentResult = new TournamentResult(this.tournamentResults[this.tournamentResultsPointer]);
        },
        saveChanges() {
            if (this.mode === 'add') {
                let params = {
                    self: this,
                    fCreate: this.createTournamentResult,
                    dto: this.selectedTournamentResult,
                    dtoName: 'Tournament Result',
                    callback: this.crudCallback,
                };
                createConfirmDto(params);
            } else {
                let params = {
                    self: this,
                    fUpdate: this.updateTournamentResult,
                    dto: this.selectedTournamentResult,
                    dtoName: 'Tournament Result',
                    callback: this.crudCallback,
                };
                updateConfirmDto(params);
            }
        },
        onChange() {},
        onClose() {
            this.addDialogVisible = false;
            this.editDialogVisible = false;
            this.componentKey += 1;
        },
        deleteCurrentTournamentResult() {
            let params = {
                self: this,
                fDelete: this.deleteTournamentResult,
                vConfirm: this.selectedTournamentResult.data.tournamentId,
                dto: this.selectedTournamentResult,
                dtoName: 'Tournament Result',
                vConfirmName: 'Tournament ID',
                callback: this.crudCallback,
            };
            deleteConfirmDto(params);
        },
        deleteAllTournamentResults() {
            let dto = {
                season: this.tournamentYear,
                tournamentId: this.tournamentId,
            };
            let params = {
                self: this,
                fDelete: this.deleteTournamentResults,
                vConfirm: this.tournamentId,
                dto: dto,
                dtoName: 'ALL Tournament Results',
                vConfirmName: 'Tournament ID',
                callback: this.crudCallback,
            };
            deleteConfirmDto(params);
        },
        crudCallback() {
            this.refresh();
            this.requestError = null;
            this.addDialogVisible = false;
            this.editDialogVisible = false;
        },
        clearError() {
            this.requestError = null;
        },
    },
};
</script>

<style scoped></style>
>
