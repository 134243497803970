var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('el-dialog',{attrs:{"close-on-click-modal":false,"show-close":false,"visible":_vm.showLinkDialog,"title":"Map to existing player","top":"10vh","width":"65%"},on:{"update:visible":function($event){_vm.showLinkDialog=$event}}},[_c('FormGroup',{attrs:{"layout":_vm.layoutPlayerLink,"model":_vm.selectedItfPlayerLink,"schema":_vm.schemaPlayerLink,"suppressPlayerForSearch":_vm.selectedItfPlayerLink ? _vm.selectedItfPlayerLink.original.playerNbr : null},on:{"change":_vm.onChange,"player-selected":_vm.onPlayerSelected}}),_c('div',{staticClass:"dialog-footer"},[_c('el-button',{attrs:{"disabled":_vm.disabledSaveButton,"type":"primary"},on:{"click":_vm.onSave}},[_vm._v(" Save Mapping")]),_c('el-button',{on:{"click":_vm.onClose}},[_vm._v("Close")]),_c('span',{staticClass:"spacer"})],1)],1),_c('div',{staticClass:"flex-row mt-0 mb-32"},[_c('h2',[_vm._v("ITF Import Summary for "+_vm._s(_vm.itfName()))])]),_c('div',{staticClass:"flex-row mt-0 mb-16"},[_c('h3',[_vm._v("Imported Tournament")]),_c('span',{staticClass:"spacer"}),(!_vm.isDryRun)?_c('el-button',{attrs:{"plain":"","size":"mini","type":"primary"},on:{"click":_vm.editTournament}},[_vm._v(" Edit tournament ")]):_vm._e()],1),_c('FormGroup',{attrs:{"allReadonly":true,"layout":_vm.layoutTournament,"model":_vm.importedTournament,"schema":_vm.schemaTournament},on:{"change":_vm.onChange}}),_c('div',{staticClass:"flex-row mt-32 mb-0"},[_c('h3',[_vm._v("Imported Round Results: "+_vm._s(_vm.itfImportResult.importedRounds.length))])]),_vm._m(0),(_vm.isDryRun)?_c('DataTable',{attrs:{"autoSort":false,"fields":[
                       'itfPlayerNbr',
                       'playerNbr',
                       'wtaNameFirst',
                       'wtaNameLast',
                       'wtaNationality',
                       'wtaBirthDate',
                       'mapToPlayerButton'
                       ],"items":_vm.itfImportResult.importedPlayerLinks,"schema":_vm.schemaPlayerLink},on:{"edit-player":_vm.onEditPlayer,"show-itf-link-dialog":_vm.onShowLinkDialog}}):_c('DataTable',{attrs:{"autoSort":false,"fields":[
                       'itfPlayerNbr',
                       'playerNbr',
                       'wtaNameFirst',
                       'wtaNameLast',
                       'wtaNationality',
                       'wtaBirthDate',
                       'mapToPlayerButton',
                       'openPlayerButton'
                       ],"items":_vm.itfImportResult.importedPlayerLinks,"schema":_vm.schemaPlayerLink},on:{"edit-player":_vm.onEditPlayer,"show-itf-link-dialog":_vm.onShowLinkDialog}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex-row mt-32 mb-16"},[_c('h3',[_vm._v("Imported Player Links")])])
}]

export { render, staticRenderFns }