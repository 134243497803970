<template>
    <div>
        <DialogAddItem
            :visibleSync="addDialogVisible"
            dtoName="Team Points"
            :selected="selectedTeamRanking"
            :schema="schemaAdd"
            :layout="layoutAdd"
            @save="saveChanges"
            @change="onChange"
            @close="addDialogVisible = false"
        />

        <DialogEditItem
            :visibleSync="editDialogVisible"
            dtoName="Team Points"
            :selected="selectedTeamRanking"
            :schema="schema"
            :layout="layout"
            @save="saveChanges"
            @change="onChange"
            @delete="deleteTeamPoints"
            @close="editDialogVisible = false"
        />

        <ErrorDisplay :requestError="this.loadingError" @closed="clearError" />

        <div v-loading="loadingRanking">
            <DataTable
                :items="teamRankings.data"
                :schema="schema"
                :fields="[
                    'player1Id',
                    'player1ConcatName',
                    'player1Nationality',
                    'player2Id',
                    'player2ConcatName',
                    'player2Nationality',
                    'tournamentCount',
                    'points',
                ]"
                @item-clicked="selectTeamPoints"
                @header-clicked="setSort"
                :autoSort="false"
            />

            <Paginator
                v-if="teamRankings.data"
                :data="teamRankings"
                :elementsPerPage="options.pageSize"
                @prev-page="prevPage"
                @next-page="nextPage"
                @set-page-size="setPageSize"
            />
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { TeamRanking } from '../../models/team-ranking';
import * as moment from 'moment';
import FileSaver from 'file-saver';
import { retrieveDtos, deleteConfirmDto, createConfirmDto, updateConfirmDto } from '../../utils/crud-confirm';

export default {
    props: ['playerId'],
    watch: {
        playerId() {
            this.playerSelected({ id: this.playerId });
        },
    },
    data() {
        return {
            schema: TeamRanking.schema,
            schemaAdd: TeamRanking.schemaAdd,
            layout: TeamRanking.layout,
            layoutAdd: TeamRanking.layoutAdd,
            editDialogVisible: false,
            addDialogVisible: false,
            mode: 'add',
            selectedTeamRanking: null,
        };
    },
    mounted() {
        this.refresh();
    },
    computed: {
        ...mapGetters('currentTeamPoints', ['teamRankings', 'loadingRanking', 'options', 'loadingError']),
    },
    methods: {
        ...mapActions('currentTeamPoints', ['createTeamRanking', 'updateTeamRanking', 'findTeamRankings', 'deleteTeamRanking', 'exportCsv']),
        exportCsvClick() {
            this.exportCsv({
                queryString: this.options.queryString || '',
                sortBy: this.options.sortBy || '',
                sortDir: this.options.sortDir === 'descending' ? -1 : 1,
            })
                .then((result) => {
                    const file = new File([result], `current_ranking_TeamPoints_${moment().format('DD_MM_YYYY HH_mm_SS')}.csv`, { type: 'text/csv' });
                    FileSaver.saveAs(file);
                })
                .catch((error) => {
                    this.requestError = error;
                    this.$notify({
                        title: 'CSV export',
                        message: 'CSV export failed',
                        type: 'error',
                        duration: 5000,
                    });
                });
        },
        refresh() {
            let params = {
                self: this,
                fRetrieve: this.findTeamRankings,
                options: this.options,
                dtosName: 'Team Rankings',
            };
            retrieveDtos(params);
        },
        playerSelected(player) {
            this.options.page = 0;
            this.options.queryString = player.id;
            this.refresh();
        },
        reset() {
            this.options.page = 0;
            this.options.queryString = '';
            this.refresh();
        },
        prevPage() {
            this.options.page--;
            this.refresh();
        },
        nextPage() {
            this.options.page++;
            this.refresh();
        },
        setPageSize(size) {
            this.options.pageSize = size;
            this.refresh();
        },
        setSort(event) {
            this.options.sortBy = event.property;
            this.options.sortDir = event.order;
            this.refresh();
        },
        saveChanges() {
            if (this.mode === 'edit') {
                let params = {
                    self: this,
                    fUpdate: this.updateTeamRanking,
                    dto: this.selectedTeamRanking,
                    dtoName: 'Team Ranking',
                    callback: this.crudCallback,
                };
                updateConfirmDto(params);
            } else if (this.mode === 'add') {
                let params = {
                    self: this,
                    fCreate: this.createTeamRanking,
                    dto: this.selectedTeamRanking,
                    dtoName: 'Team Ranking',
                    callback: this.crudCallback,
                };
                createConfirmDto(params);
            }
        },
        onChange() {},
        deleteTeamPoints() {
            let params = {
                self: this,
                fDelete: this.deleteTeamRanking,
                vConfirm: this.selectedTeamRanking.data.player1Id,
                dto: this.selectedTeamRanking,
                dtoName: 'Team Ranking',
                vConfirmName: 'Player 1 ID',
                    callback: this.crudCallback,
            };
            deleteConfirmDto(params);
        },
        addItem() {
            this.mode = 'add';
            this.addDialogVisible = true;
            this.selectedTeamRanking = new TeamRanking({});
        },
        selectTeamPoints(item) {
            this.mode = 'edit';
            this.editDialogVisible = true;
            this.selectedTeamRanking = new TeamRanking(item);
        },
        crudCallback() {
            this.refresh();
            this.$emit('refresh');
            this.requestError = null;
            this.addDialogVisible = false;
            this.editDialogVisible = false;
        },
        clearError() {
            this.loadingError = null;
        },
    },
};
</script>
