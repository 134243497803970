import axios from 'axios';

export default {
    namespaced: true,

    state: {
        loadingPdf: false,
        loadingSinglesNumeric: false,
        loadingDoublesNumeric: false,
        loadingChampionshipLeaders: false,
        loadingYtdPrizeMoneyLeaders: false,
        loadingCareerPrizeMoneyLeaders: false,
        loadingShadowSinglesNumeric: false,
        loadingShadowDoublesNumeric: false,
        loadingShadowChampionshipLeaders: false,
        loadingPrizeMoneyReceipt: false,
        loadingError: null,
        progress: 0,
        currentStep: 0,
        totalSteps: 5,
    },

    getters: {
        loadingPdf: (state) => state.loadingPdf,
        loadingSinglesNumeric: (state) => state.loadingSinglesNumeric,
        loadingDoublesNumeric: (state) => state.loadingDoublesNumeric,
        loadingChampionshipLeaders: (state) => state.loadingChampionshipLeaders,
        loadingYtdPrizeMoneyLeaders: (state) => state.loadingYtdPrizeMoneyLeaders,
        loadingCareerPrizeMoneyLeaders: (state) => state.loadingCareerPrizeMoneyLeaders,
        loadingShadowSinglesNumeric: (state) => state.loadingShadowSinglesNumeric,
        loadingShadowDoublesNumeric: (state) => state.loadingShadowDoublesNumeric,
        loadingShadowChampionshipLeaders: (state) => state.loadingShadowChampionshipLeaders,
        loadingPrizeMoneyReceipt: (state) => state.loadingPrizeMoneyReceipt,
        loadingError: (state) => state.loadingError,
        pdfDownloadProgress: (state) => state.progress
    },

    mutations: {

        startLoadingPdf(state) {
            state.loadingPdf = true;
        },

        stopLoadingPdf(state) {
            state.loadingPdf = false;
        },

        startLoadingSinglesNumericPdf(state) {
            state.loadingSinglesNumeric = true;
        },

        stopLoadingSinglesNumericPdf(state) {
            state.loadingSinglesNumeric = false;
        },

        startLoadingDoublesNumericPdf(state) {
            state.loadingDoublesNumeric = true;
        },

        stopLoadingDoublesNumericPdf(state) {
            state.loadingDoublesNumeric = false;
        },

        startLoadingChampionshipLeadersPdf(state) {
            state.loadingChampionshipLeaders = true;
        },

        stopLoadingChampionshipLeadersPdf(state) {
            state.loadingChampionshipLeaders = false;
        },

        startLoadingShadowSinglesNumericPdf(state) {
            state.loadingShadowSinglesNumeric = true;
        },

        stopLoadingShadowSinglesNumericPdf(state) {
            state.loadingShadowSinglesNumeric = false;
        },

        startLoadingShadowDoublesNumericPdf(state) {
            state.loadingShadowDoublesNumeric = true;
        },

        stopLoadingShadowDoublesNumericPdf(state) {
            state.loadingShadowDoublesNumeric = false;
        },

        startLoadingShadowChampionshipLeadersPdf(state) {
            state.loadingShadowChampionshipLeaders = true;
        },

        stopLoadingShadowChampionshipLeadersPdf(state) {
            state.loadingShadowChampionshipLeaders = false;
        },

        startLoadingYtdPrizeMoneyLeadersPdf(state) {
            state.loadingYtdPrizeMoneyLeaders = true;
        },

        stopLoadingYtdPrizeMoneyLeadersPdf(state) {
            state.loadingYtdPrizeMoneyLeaders = false;
        },

        startLoadingCareerPrizeMoneyLeadersPdf(state) {
            state.loadingCareerPrizeMoneyLeaders = true;
        },

        stopLoadingCareerPrizeMoneyLeadersPdf(state) {
            state.loadingCareerPrizeMoneyLeaders = false;
        },

        startLoadingPrizeMoneyReceiptPdf(state) {
            state.loadingPrizeMoneyReceipt = true;
        },

        stopLoadingPrizeMoneyReceiptPdf(state) {
            state.loadingPrizeMoneyReceipt = false;
        },

        setLoadingError(state, error) {
            state.loadingError = error;
        },

        clearLoadingError(state) {
            state.loadingError = null;
        },

        setProgress(state) {
            state.currentStep = state.currentStep + 1;
            state.progress = (state.currentStep / state.totalSteps * 100);
        },

        clearProgress(state) {
            state.progress = 0;
            state.currentStep = 0;
        },
    },

    actions: {
        async getYtdPrizeMoneyLeaders(context) {
            context.commit('startLoadingYtdPrizeMoneyLeadersPdf');
            try {
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/PdfReports/YtdPrizeMoneyLeaders`, { responseType: 'blob' });
                context.commit('clearLoadingError');
                context.commit('stopLoadingYtdPrizeMoneyLeadersPdf');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('setLoadingError', error);
                context.commit('stopLoadingYtdPrizeMoneyLeadersPdf');
                return Promise.reject(error);
            }
        },
        async getCareerPrizeMoneyLeaders(context) {
            context.commit('startLoadingCareerPrizeMoneyLeadersPdf');
            try {
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/PdfReports/CareerPrizeMoneyLeaders`, { responseType: 'blob' });
                context.commit('clearLoadingError');
                context.commit('stopLoadingCareerPrizeMoneyLeadersPdf');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('setLoadingError', error);
                context.commit('stopLoadingCareerPrizeMoneyLeadersPdf');
                return Promise.reject(error);
            }
        },
        async getChampionshipLeaders(context) {
            context.commit('startLoadingChampionshipLeadersPdf');
            try {
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/PdfReports/ChampionshipLeaders`, { responseType: 'blob' });
                context.commit('clearLoadingError');
                context.commit('stopLoadingChampionshipLeadersPdf');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('setLoadingError', error);
                context.commit('stopLoadingChampionshipLeadersPdf');
                return Promise.reject(error);
            }
        },
        async getSinglesNumeric(context) {
            context.commit('startLoadingSinglesNumericPdf');
            try {
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/PdfReports/SinglesNumeric`, { responseType: 'blob' });
                context.commit('clearLoadingError');
                context.commit('stopLoadingSinglesNumericPdf');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('setLoadingError', error);
                context.commit('stopLoadingSinglesNumericPdf');
                return Promise.reject(error);
            }
        },
        async getDoublesNumeric(context) {
            context.commit('startLoadingDoublesNumericPdf');
            try {
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/PdfReports/DoublesNumeric`, { responseType: 'blob' });
                context.commit('clearLoadingError');
                context.commit('stopLoadingDoublesNumericPdf');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('setLoadingError', error);
                context.commit('stopLoadingDoublesNumericPdf');
                return Promise.reject(error);
            }
        },
        async getShadowSinglesNumeric(context) {
            context.commit('startLoadingShadowSinglesNumericPdf');
            try {
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/PdfReports/ShadowSinglesNumeric`, { responseType: 'blob' });
                context.commit('clearLoadingError');
                context.commit('stopLoadingShadowSinglesNumericPdf');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('setLoadingError', error);
                context.commit('stopLoadingShadowSinglesNumericPdf');
            }
        },
        async getShadowDoublesNumeric(context) {
            context.commit('startLoadingShadowDoublesNumericPdf');
            try {
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/PdfReports/ShadowDoublesNumeric`, { responseType: 'blob' });
                context.commit('clearLoadingError');
                context.commit('stopLoadingShadowDoublesNumericPdf');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('setLoadingError', error);
                context.commit('stopLoadingShadowDoublesNumericPdf');
            }
        },
        async getShadowChampionshipLeaders(context) {
            context.commit('startLoadingShadowChampionshipLeadersPdf');
            try {
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/PdfReports/ShadowChampionshipLeaders`, { responseType: 'blob' });
                context.commit('clearLoadingError');
                context.commit('stopLoadingShadowChampionshipLeadersPdf');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('setLoadingError', error);
                context.commit('stopLoadingShadowChampionshipLeadersPdf');
            }
        },
        async getPrizeMoneyReceipt(context, request = {}) {
            const playerNumber = request.playerNumber;
            const season = request.year;
            const tournamentNumber = request.tournamentNumber;
            context.commit('startLoadingPrizeMoneyReceiptPdf');
            try {
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/PdfReports/PrizeMoneyReceipt/Player/${playerNumber}/Year/${season}/Tournament/${tournamentNumber}`, { responseType: 'blob' });
                context.commit('clearLoadingError');
                context.commit('stopLoadingPrizeMoneyReceiptPdf');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('setLoadingError', error);
                context.commit('stopLoadingPrizeMoneyReceiptPdf');
            }
        },
        clearProgress(context) {
            context.commit('clearProgress');
        }
    },
};
