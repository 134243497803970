var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"flex-row mb-32"},[_c('h2',[_c('i',{staticClass:"el-icon-medal-1 mr-16"}),_vm._v("Shadow Ranking Summaries "+_vm._s(_vm.options.rankingDate ? 'for ' + _vm.options.rankingDate : ''))]),_c('span',{staticClass:"spacer"}),_c('el-select',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loadingShadowRankings),expression:"loadingShadowRankings"}],staticClass:"mr-16",attrs:{"placeholder":"Select Shadow Ranking"},on:{"change":_vm.shadowRankIdChanged},model:{value:(_vm.options.shadowRankId),callback:function ($$v) {_vm.$set(_vm.options, "shadowRankId", $$v)},expression:"options.shadowRankId"}},_vm._l((_vm.shadowRankings),function(item){return _c('el-option',{key:item.shadowRankId,attrs:{"label":item.name,"value":item.shadowRankId}})}),1),_c('span',{staticClass:"mr-8"},[_vm._v("Date")]),_c('el-date-picker',{staticClass:"mr-16 date-select",attrs:{"clearable":false,"firstDayOfWeek":1,"format":"yyyy-MM-dd","placeholder":"Pick a day","type":"week"},on:{"change":_vm.selectDate},model:{value:(_vm.options.rankingDate),callback:function ($$v) {_vm.$set(_vm.options, "rankingDate", $$v)},expression:"options.rankingDate"}}),_c('span',{staticClass:"mr-8"},[_vm._v("Player")]),_c('SelectPlayer',{staticClass:"mr-8",attrs:{"value":this.options.playerId},on:{"palyer-selected":_vm.selectPlayer}})],1),_c('ErrorDisplay',{attrs:{"requestError":this.requestError},on:{"closed":_vm.clearError}}),_c('DetailedInformation',{attrs:{"informationArray":this.informationArray(),"screenName":"Shadow Ranking Summaries"}}),_c('el-tabs',{attrs:{"active-name":"shadow-singles-summary"}},[_c('el-tab-pane',{attrs:{"label":"Shadow Singles Summary by Player","name":"shadow-singles-summary"}},[_c('ShadowRankingSummarySingles',{attrs:{"dataFields":[
                    'startDate',
                    'useForRankingDate',
                    'tournamentName',
                    'season',
                    'tournamentId',
                    'isRequired',
                    'points',
                    'isIncludedIntoRanking',
                    'rankingLabel',
                ]}})],1),_c('el-tab-pane',{attrs:{"label":"Shadow Doubles Summary by Player","name":"shadow-doubles-summary"}},[_c('ShadowRankingSummaryDoubles',{attrs:{"dataFields":[
                    'startDate',
                    'useForRankingDate',
                    'tournamentName',
                    'season',
                    'tournamentId',
                    'isRequired',
                    'points',
                    'isIncludedIntoRankingDoubles',
                    'rankingLabel',
                ]}})],1),_c('el-tab-pane',{attrs:{"label":"Shadow Champ Singles Summary by Player","name":"shadow-race-singles-summary"}},[_c('ShadowRankingSummaryChampSingles')],1),_c('el-tab-pane',{attrs:{"label":"Shadow Champ Team Summaries by Teams","name":"shadow-race-teams-summaries"}},[_c('ShadowRankingSummaryChampTeams')],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }