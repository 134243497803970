import { Model, isValid } from './model';

export class ImportEntry extends Model {
    isValid() {
        return isValid(ImportEntry.schema, this);
    }
}

ImportEntry.layout = {
    children: [
        {
            children: ['createdAt', 'fileName'],
        },
    ],
};

ImportEntry.schema = {
    fields: {
        createdAt: { type: 'datetime', displayName: 'Created at', sortable: true, readonly: true },
        filename: { type: 'string', displayName: 'File name', sortable: true, readonly: true },
        fileType: { type: 'string', displayName: 'File Type', sortable: true, readonly: true },
        tournamentName: { type: 'string', displayName: 'Tournament name', sortable: true, readonly: true },
        tournamentYear: { type: 'number', displayName: 'Tournament year', sortable: true, readonly: true },
        tournamentId: { type: 'number', displayName: 'Tournament ID', sortable: true, readonly: true },
    },
};
