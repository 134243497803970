<template>
    <div>
        <div class="flex-row mt-16 mb-32">
            <h2>ITF Import</h2>
            <span class="spacer"></span>
        </div>

        <ErrorDisplay :requestError="this.requestError" @closed="clearError" />

        <DetailedInformation :informationArray="this.informationArray()" screenName="ITF Import" />

        <el-dialog :close-on-click-modal="false" :show-close="false" :visible.sync="editDialogVisible" title="ITF Import" width="65%">
            <FormGroup :layout="layout" :model="selectedItfImport" :readonlyForEdit="true" :schema="schema" @change="onChange" />

            <div class="dialog-footer">
                <el-button @click="onClose">Close</el-button>
                <span class="spacer"></span>
                <el-button @click="gotoPrevItfImport">Prev</el-button>
                <el-button @click="gotoNextItfImport">Next</el-button>
                <span class="spacer"></span>
                <el-button
                    v-if="!isBaselineReader"
                    v-loading="this.loadingItfImportResults"
                    :disabled="!selectedItfImport || !selectedItfImport.isValid()"
                    @click="onDryRun"
                >
                    Dry Run
                </el-button>
                <el-button
                    v-if="!isBaselineReader"
                    v-loading="this.loadingItfImportResults"
                    :disabled="!selectedItfImport || !selectedItfImport.isValid()"
                    type="primary"
                    @click="onImport"
                >
                    Import
                </el-button>
            </div>

            <ErrorDisplay :requestError="this.loadingErrorItfImportResults" class="mt-16" @closed="clearLoadingErrorItfImportResults" />
        </el-dialog>

        <div class="flex-row mt-0 mb-32">
            <h3>ITF Calendar</h3>
            <span class="spacer"></span>

            <el-date-picker
                v-model="itfOptions.fromDate"
                class="mr-16"
                firstDayOfWeek="1"
                format="yyyy-MM-dd"
                placeholder="From Date"
                type="date"
            ></el-date-picker>
            <el-date-picker v-model="itfOptions.toDate" class="mr-16" firstDayOfWeek="1" format="yyyy-MM-dd" placeholder="To Date" type="date"></el-date-picker>

            <el-button icon="el-icon-search" @click="search"></el-button>
        </div>

        <div v-loading="loadingItfImports" class="mt-0 mb-32">
            <DataTable
                :autoSort="false"
                :fields="[
                    'startDate',
                    'providerTournamentId',
                    'officialName',
                    'wtaTournamentNumber',
                    'wtaTournamentName',
                    'tournamentDataPresent',
                    'resultDataPresent',
                ]"
                :items="itfImports.data"
                :schema="schema"
                :sortDir="itfOptions.sortDir"
                :sortField="itfOptions.sortBy"
                @item-clicked="itemClicked"
            />

            <Paginator
                v-if="itfImports.data"
                :data="itfImports"
                :elementsPerPage="itfOptions.pageSize"
                @prev-page="prevPage"
                @next-page="nextPage"
                @set-page-size="setPageSize"
            />
        </div>

        <el-tabs v-model="activeName" closable @tab-remove="onTabRemove">
            <el-tab-pane v-for="(itfImportResult, index, name) in itfImportResults" :key="index" :label="itfImportResult.label" :name="itfImportResult.name">
                <ItfImportSummary :itf-import-result="itfImportResult" />
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { ItfImport } from '@/models/itf-import';
import { retrieveDtos } from '@/utils/crud-confirm';
import ItfImportSummary from '@/widgets/ItfImportSummary.vue';
import ItfPlayerLink from '@/components/ItfPlayerLink.vue';
import { isBaselineReader } from '../services/authGroups';

export default {
    components: { ItfImportSummary, ItfPlayerLink },
    data() {
        return {
            schema: ItfImport.schema,
            layout: ItfImport.layout,
            editDialogVisible: false,
            itfImportPointer: null,
            selectedItfImport: null,
            requestError: null,
            activeName: null,
            isBaselineReader: isBaselineReader(),
        };
    },
    mounted() {
        if (this.itfImportResults.length > 0) {
            this.activeName = this.itfImportResults[0].name;
        }
    },
    computed: {
        ...mapGetters('itfImports', [
            'itfImports',
            'loadingItfImports',
            'loadingErrorItfImports',
            'itfImportResults',
            'loadingItfImportResults',
            'loadingErrorItfImportResults',
            'itfOptions',
        ]),
    },
    methods: {
        ...mapActions('itfImports', [
            'findItfImports',
            'importItfTournament',
            'importItfTournamentDryRun',
            'clearLoadingErrorItfImportResults',
            'removeItfImportResult',
        ]),
        ...mapActions('tournamentRounds', ['propagateData']),
        search() {
            if (!this.itfOptions.fromDate || !this.itfOptions.toDate) {
                this.requestError = {
                    message: 'From-date and to-date needed to perform search.',
                };
                return;
            }
            let params = {
                self: this,
                fRetrieve: this.findItfImports,
                options: this.itfOptions,
                dtosName: 'ITF Imports',
            };
            retrieveDtos(params);
        },
        itemClicked(item, index) {
            this.clearLoadingErrorItfImportResults();
            this.selectedItfImport = new ItfImport(item);
            this.editDialogVisible = true;
            this.itfImportPointer = index;
        },
        onTabRemove(tabName) {
            this.removeItfImportResult(tabName);
        },
        onImport() {
            this.$confirm('You are about to import an ITF tournament. Please confirm.', 'Import ITF tournament', {
                confirmButtonText: 'Import',
                cancelButtonText: 'Cancel',
                type: 'warning',
            })
                .then(() => {
                    this.importItfTournament(this.selectedItfImport)
                        .then((data) => {
                            this.activeName = data.name;
                            this.$notify({
                                title: 'Import ITF tournament',
                                message: 'Import successfully finished.',
                                type: 'success',
                                duration: 5000,
                            });
                            let params = {
                                season: data.importedTournament.season,
                                tournamentId: data.importedTournament.tournamentId,
                            };
                            this.propagateData(params);
                            return Promise.resolve(data);
                        })
                        .catch((error) => {
                            this.requestError = error;
                            this.$notify({
                                title: 'Import ITF tournament Error',
                                message: error.response.data.Message,
                                type: 'error',
                                duration: 5000,
                            });
                            return Promise.reject(error);
                        });
                })
                .catch(() => {
                    this.requestError = null;
                    return Promise.reject();
                });
        },
        onDryRun() {
            this.$confirm(
                'You are about to import an ITF tournament in Dry-Run mode. No data will be written to database. Please confirm.',
                'Import ITF tournament Dry-Run',
                {
                    confirmButtonText: 'Dry-Run',
                    cancelButtonText: 'Cancel',
                    type: 'warning',
                },
            )
                .then(() => {
                    this.importItfTournamentDryRun(this.selectedItfImport)
                        .then((data) => {
                            this.activeName = data.name;
                            this.$notify({
                                title: 'Import ITF tournament Dry-Run',
                                message: 'Dry-Run successfully finished.',
                                type: 'success',
                                duration: 5000,
                            });
                            return Promise.resolve(data);
                        })
                        .catch((error) => {
                            this.requestError = error;
                            this.$notify({
                                title: 'Import ITF tournament Dry-Run Error',
                                message: error.response.data.Message,
                                type: 'error',
                                duration: 5000,
                            });
                            return Promise.reject(error);
                        });
                })
                .catch(() => {
                    this.requestError = null;
                    return Promise.reject();
                });
        },
        onClose() {
            this.editDialogVisible = false;
            this.search();
        },
        gotoPrevItfImport() {
            if (this.itfImportPointer === 0) {
                return;
            }
            this.itfImportPointer -= 1;
            this.selectedItfImport = new ItfImport(this.itfImports.data[this.itfImportPointer]);
        },
        gotoNextItfImport() {
            if (this.itfImportPointer === this.itfImports.data.length - 1) {
                return;
            }
            this.itfImportPointer += 1;
            this.selectedItfImport = new ItfImport(this.itfImports.data[this.itfImportPointer]);
        },
        setSort(event) {
            this.itfOptions.sortBy = event.property;
            this.itfOptions.sortDir = event.order;
            this.search();
        },
        prevPage() {
            this.itfOptions.page--;
            this.search();
        },
        nextPage() {
            this.itfOptions.page++;
            this.search();
        },
        setPageSize(size) {
            this.itfOptions.pageSize = size;
            this.search();
        },
        resetPage() {
            this.itfOptions.page = 0;
            this.search();
        },
        clearError() {
            this.requestError = null;
        },
        onChange() {},
        informationArray() {
            return [
                'This screen is used to import ITF tournaments, round results and new players.',
                'Select a from-date, a to-date and click on magnifying glass to retrieve overview of available ITF tournaments.',
                "Click on a new tournament to edit. Provide a WTA tournament number, you can also adjust tournament's name.",
            ];
        },
    },
};
</script>

<style scoped></style>
