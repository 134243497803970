<template>
    <div class="mt-0 mb-32">
        <h2>Acceptance List Procedures</h2>
        <div>
            <el-dialog title="Run procedure" :close-on-click-modal="false" :visible.sync="editDialogVisible" v-if="runMode">
                <span
                    >You are about to run <b>{{ runModeMapping[runMode].title }}</b> procedure for the tournament(s)
                    <b>{{ tournamentNames(runMode).join(',') }}</b
                    >. <br /><br />
                    This procedure will create the Acceptance List based on current entries for all tournaments starting in the same week. It will take each
                    tournament, get its starting week and then run the procedure for that week.</span
                >
                <div v-if="!isBaselineReader" class="dialog-footer" slot="footer">
                    <el-button type="warning" @click="runProcedure">Run Procedure</el-button>
                    <el-button @click="editDialogVisible = false">Close</el-button>
                </div>
            </el-dialog>
            <el-alert type="info" :closable="false" :show-icon="true">
                This view shows you all available acceptance list procedures to run this week. You might select also one of the next three weeks if you are
                absolutely sure to know what you do. ATTENTION: Make absolutely sure to never run QLF procedure before Main as this might have effects that are
                not easily recoverable.
            </el-alert>
            <el-card class="box-card" v-loading="runningProcedure" element-loading-text="Running procedure... this might take around 2 minutes per tournament">
                <div slot="header" class="clearfix">
                    <span>Tournaments with deadlines falling into week </span>
                    <el-select v-model="week" @change="refresh">
                        <el-option v-for="item in weeks" :key="item" :label="item" :value="item"></el-option>
                    </el-select>
                </div>
                <div class="table-wrapper" v-loading="loading">
                    <div class="draw">
                        <h4>Singles Main</h4>
                        <el-row
                            :gutter="20"
                            v-for="edition in singlesMain"
                            :key="edition.tournamentId"
                            v-bind:class="{ has_accepted_entries: edition.mainAcceptedCount > 0 }"
                        >
                            <el-col :span="6">{{ edition.name }}</el-col>
                            <el-col :span="6">Start {{ convertTime(edition.startDate) }}</el-col>
                            <el-col :span="12">Main Entry Deadline {{ convertTime(edition.single.main.entryDeadline) }} </el-col>
                        </el-row>
                        <div v-if="singlesMain.length === 0" class="no-data-message">No deadline for any tournament with singles main this week.</div>
                        <div style="float: right; margin-bottom: 10px" v-if="singlesMain.length !== 0">
                            <el-button size="medium" type="primary" :disabled="isBaselineReader" @click="showRunProcedureDialog('singlesMain')"
                                >Run Main Procedure
                            </el-button>
                        </div>
                    </div>
                    <div class="draw">
                        <h4>Singles Qualification</h4>
                        <el-row
                            :gutter="20"
                            v-for="edition in singlesQuali"
                            :key="edition.tournamentId"
                            v-bind:class="{ has_accepted_entries: edition.qualiAcceptedCount > 0 }"
                        >
                            <el-col :span="6">{{ edition.name }}</el-col>
                            <el-col :span="6">Start {{ convertTime(edition.startDate) }}</el-col>
                            <el-col :span="12">Quali Entry Deadline {{ convertTime(edition.single.qualification.entryDeadline) }} </el-col>
                        </el-row>
                        <div v-if="singlesQuali.length === 0" class="no-data-message">Singles Qualification has no deadline for a tournament this week.</div>
                        <div style="float: right; margin-bottom: 10px" v-if="singlesQuali.length !== 0">
                            <el-button size="medium" type="primary" :disabled="isBaselineReader" @click="showRunProcedureDialog('singlesQualification')"
                                >Run Qualification Procedure
                            </el-button>
                        </div>
                    </div>
                    <div class="draw">
                        <h4>Doubles</h4>
                        <el-row
                            :gutter="20"
                            v-for="edition in doublesMain"
                            :key="edition.tournamentId"
                            v-bind:class="{ has_accepted_entries: edition.doublesAcceptedCount > 0 }"
                        >
                            <el-col :span="6">{{ edition.name }}</el-col>
                            <el-col :span="6">Start {{ convertTime(edition.startDate) }}</el-col>
                            <el-col :span="12">Main Entry Deadline {{ convertTime(edition.doublesCutoffRankDate) }} </el-col>
                        </el-row>
                        <div v-if="doublesMain.length === 0" class="no-data-message">Nothing to report for a Doubles deadline this week.</div>
                        <div style="float: right; margin-bottom: 10px" v-if="doublesMain.length !== 0">
                            <el-button size="medium" type="primary" :disabled="isBaselineReader" @click="showRunProcedureDialog('doublesMain')"
                                >Run Doubles Procedure
                            </el-button>
                        </div>
                    </div>
                    <div class="draw">
                        <h4>Grand Slam Main</h4>
                        <el-row
                            :gutter="20"
                            v-for="edition in grandSlamMain"
                            :key="edition.tournamentId"
                            v-bind:class="{ has_accepted_entries: edition.mainAcceptedCount > 0 }"
                        >
                            <el-col :span="6">{{ edition.name }}</el-col>
                            <el-col :span="6">Start {{ convertTime(edition.startDate) }}</el-col>
                            <el-col :span="12">Main Entry Deadline {{ convertTime(edition.single.main.entryDeadline) }} </el-col>
                        </el-row>
                        <div v-if="grandSlamMain.length === 0" class="no-data-message">No Grand Slam MD deadlines this week.</div>
                        <div style="float: right; margin-bottom: 10px" v-if="grandSlamMain.length !== 0">
                            <el-button size="medium" type="primary" :disabled="isBaselineReader" @click="showRunProcedureDialog('grandSlamMain')"
                                >Run Grand Slam MD Procedure
                            </el-button>
                        </div>
                    </div>
                    <div class="draw">
                        <h4>Grand Slam Qualification</h4>
                        <el-row
                            :gutter="20"
                            v-for="edition in grandSlamQlf"
                            :key="edition.tournamentId"
                            v-bind:class="{ has_accepted_entries: edition.qualiAcceptedCount > 0 }"
                        >
                            <el-col :span="6">{{ edition.name }}</el-col>
                            <el-col :span="6">Start {{ convertTime(edition.startDate) }}</el-col>
                            <el-col :span="12">Main Entry Deadline {{ convertTime(edition.single.main.entryDeadline) }} </el-col>
                        </el-row>
                        <div v-if="grandSlamQlf.length === 0" class="no-data-message">No Grand Slam QLF deadlines this week.</div>
                        <div style="float: right; margin-bottom: 10px" v-if="grandSlamQlf.length !== 0">
                            <el-button size="medium" type="primary" :disabled="isBaselineReader" @click="showRunProcedureDialog('grandSlamQlf')"
                                >Run Grand Slam QLF Procedure
                            </el-button>
                        </div>
                    </div>
                </div>
            </el-card>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
import axios from 'axios';
import { isBaselineReader } from '../services/authGroups';

export default {
    data() {
        return {
            loading: false,
            singlesMain: [],
            singlesQuali: [],
            doublesMain: [],
            grandSlamQlf: [],
            grandSlamMain: [],
            originalWeek: moment().isoWeek(),
            week: moment().isoWeek(),
            editDialogVisible: false,
            runMode: '',
            runModeMapping: {
                singlesMain: {
                    title: 'Singles Main',
                    name: 'main-procedure',
                },
                singlesQualification: {
                    title: 'Singles Qualification',
                    name: 'quali-procedure',
                },
                doublesMain: {
                    title: 'Doubles',
                    name: 'doubles-procedure',
                },
                grandSlamQlf: {
                    title: 'Grand Slam Qlf',
                    name: 'grand-slam-procedure-qlf',
                },
                grandSlamMain: {
                    title: 'Grand Slam Main',
                    name: 'grand-slam-procedure-main',
                },
            },
            isBaselineReader: isBaselineReader(),
        };
    },

    methods: {
        convertTime: function (d) {
            return moment(d).format('MMMM Do YYYY');
        },
        refresh: function () {
            this.loading = true;
            axios
                .get(`${process.env.VUE_APP_API_URL}/Edition/ByDeadlineWeek/${moment().year()}/${this.week}`)
                .then((data) => {
                    this.singlesMain = data.data.item1;
                    this.singlesQuali = data.data.item2;
                    this.doublesMain = data.data.item3;
                    this.grandSlamQlf = data.data.item4;
                    this.grandSlamMain = data.data.item5;
                    this.loading = false;
                })
                .catch((e) => {
                    this.loading = false;
                });
        },
        showRunProcedureDialog: function (mode) {
            this.runMode = mode;
            this.editDialogVisible = true;
        },
        runProcedure: function () {
            this.editDialogVisible = false;
            let procedureInformation = this.runModeMapping[this.runMode];
            this.$store.commit('procedure/procedureStarted');
            axios({
                method: 'post',
                url: `${process.env.VUE_APP_API_URL}/AcceptanceList/${moment().year()}/${this.week}/${procedureInformation.name}`,
            })
                .then(() => {
                    this.$store.commit('procedure/procedureFinished');
                })
                .catch(() => {
                    this.$store.commit('procedure/procedureFinished');
                });
        },
        tournamentNames: function (mode) {
            if (mode === 'singlesMain') {
                return this.singlesMain.map((item) => item.name);
            } else if (mode === 'singlesQualification') {
                return this.singlesQuali.map((item) => item.name);
            } else if (mode === 'doublesMain') {
                return this.doublesMain.map((item) => item.name);
            } else if (mode === 'grandSlamMain') {
                return this.grandSlamMain.map((item) => item.name);
            } else if (mode === 'grandSlamQlf') {
                return this.grandSlamQlf.map((item) => item.name);
            }
        },
    },

    mounted() {
        this.refresh();
    },

    computed: {
        ...mapGetters('procedure', ['runningProcedure']),
        weeks() {
            return [
                this.originalWeek,
                this.originalWeek + 1,
                this.originalWeek + 2,
                this.originalWeek + 3,
                this.originalWeek - 1,
                this.originalWeek - 2,
                this.originalWeek - 3,
                this.originalWeek - 4,
                this.originalWeek - 5,
                this.originalWeek - 6,
            ];
        },
    },
};
</script>

<style scoped lang="scss">
.el-card__body {
    padding: 0 20px;
}

.table-wrapper .draw {
    margin-top: 35px;
    border: 1px solid #cecece;
    padding: 15px;
}

.table-wrapper .draw h4 {
    margin-top: 0;
}

table {
    width: 100%;
    border-collapse: collapse;
}

tbody tr {
    cursor: pointer;
    height: 32px;

    &:nth-of-type(even) {
        background-color: #f8f8f8;
    }

    &:hover {
        background-color: #eee;
    }

    &:not(:last-of-type) {
        border-bottom: 1px solid #eee;
    }
}

thead tr {
    height: 48px;
}

th {
    text-align: left;
    padding-right: 16px;
    padding-left: 16px;
    font-size: 13px;
    border-bottom: 2px solid #ddd;
    white-space: nowrap;
    vertical-align: middle;

    &.sortable {
        cursor: pointer;

        &:hover {
            background-color: #eaeaea;
        }
    }
}

// .alignRight {
//     text-align: right;
// }

td {
    padding-right: 16px;
    padding-left: 16px;
    font-size: 13px;
    white-space: nowrap;
}

.no-data-message {
    text-align: center;
    padding: 16px;
    color: #aaa;
}

.has_accepted_entries:before {
    content: ' ';
    position: absolute;
    top: 50%;
    left: 0;
    border-bottom: 1px solid #111;
    width: 70%;
}
</style>
