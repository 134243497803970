<template>
    <div>
        <el-tooltip :content="field.description" :disabled="!field.description" :tabindex="-1" placement="top-start">
            <div slot="content" style="max-width: 450px; overflow: auto">
                {{ field.description }}
            </div>
            <label v-if="field.type !== 'boolean' && field.type !== 'string-array'" :class="{ error: hasError }"
                   :for="fieldName">
                {{ field.displayName || fieldName }} {{ field.required ? ' *' : '' }} <i v-if="field.description"
                                                                                         class="el-icon-question"></i>
            </label>
        </el-tooltip>

        <el-select
                v-if="field.lookup && (!field.type || (field.type !== 'number' && field.type !== 'nationality'))"
                :id="fieldName"
                v-model="model.data[fieldName]"
                :class="{ unsaved: model.isFieldChanged(fieldName), error: hasError, warning: hasWarning }"
                :clearable="field.clearable || false"
                :disabled="field.readonly || allReadonly || field.readonlyForEdit && readonlyForEdit"
                :placeholder="field.placeholder ? field.placeholder : field.displayName"
                @change="change(fieldName)"
        >
            <el-option
                    v-for="(item, i) in field.lookup"
                    :key="i"
                    :disabled="item.disabled && item.disabled(model)"
                    :label="item.displayName || item"
                    :value="item.id"
                    class="dropdown-option"
            >
                <!-- <span class="ml-8">{{ item.displayName || item }}</span>-->
            </el-option>
        </el-select>

        <el-autocomplete
                v-else-if="field.type && field.type === 'autocomplete'"
                :id="fieldName"
                v-model="model.data[fieldName]"
                :class="{ unsaved: model.isFieldChanged(fieldName), error: hasError, warning: hasWarning }"
                :clearable="field.clearable || false"
                :disabled="field.readonly || allReadonly || field.readonlyForEdit && readonlyForEdit"
                :fetch-suggestions="(str, cb) => cb(field.autocomplete || [])"
                :maxlength="field.maxLength || null"
                :minlength="field.minLength || null"
                :placeholder="field.placeholder ? field.placeholder : field.displayName"
                :trigger-on-focus="true"
                :value="model.data[fieldName]"
                @select="change(fieldName)"
        ></el-autocomplete>

        <SelectNationality
                v-else-if="field.type && field.type === 'nationality'"
                v-model="model.data[fieldName]"
                :id="fieldName"
                :disabled="field.readonly || allReadonly|| field.readonlyForEdit && readonlyForEdit"
                :class="{ unsaved: model.isFieldChanged(fieldName), error: hasError, warning: hasWarning }"
                :clearable="field.clearable || false"
                :minlength="field.minLength || null"
                :maxlength="field.maxLength || null"
                :placeholder="field.placeholder"
                :lookup="field.lookup"
                @select="change(fieldName)"
                @clear="model.data[fieldName] = null"
        />

        <SelectPlayer
                v-else-if="field.type && field.type === 'player'"
                v-model="model.data[fieldName]"
                :ageAt="ageAt"
                :clearable="field.clearable || false"
                :disabled="field.readonly  || field.readonlyForEdit && readonlyForEdit || false"
                :rankAt="rankAt"
                :suppressPlayerForSearch="suppressPlayerForSearch"
                @change="change(fieldName)"
                @clear="model.data[fieldName] = null"
                @palyer-selected="playerSelected"
        />

        <SelectPlayer2
                v-else-if="field.type && field.type === 'player2'"
                :ageAt="ageAt"
                :clearable="field.clearable || false"
                :disabled="field.readonly  || field.readonlyForEdit && readonlyForEdit || false"
                :model="model.data[fieldName]"
                :value="model.data[fieldName]"
                :rankAt="rankAt"
                :suppressPlayerForSearch="suppressPlayerForSearch"
                @change="change(fieldName)"
                @clear="model.data[fieldName] = null"
                @player-selected="playerSelected"
        />
        <SelectPlayerSupport
                v-else-if="field.type && field.type === 'player-support'"
                v-model="model.data[fieldName]"
                :ageAt="ageAt"
                :clearable="field.clearable || false"
                :disabled="field.readonly || allReadonly || field.readonlyForEdit && readonlyForEdit"
                :rankAt="rankAt"
                @change="change(fieldName)"
                @clear="model.data[fieldName] = null"
                @support-selected="playerSupportSelected"
        />

        <el-input
                v-else-if="field.type && field.type === 'number'"
                :id="fieldName"
                v-model="model.data[fieldName]"
                :class="{ unsaved: model.isFieldChanged(fieldName), error: hasError, warning: hasWarning }"
                :clearable="field.clearable || false"
                :max="field.max || null"
                :min="field.min || null"
                :placeholder="field.placeholder ? field.placeholder : field.displayName"
                :readonly="field.readonly || allReadonly || field.readonlyForEdit && readonlyForEdit"
                :value="field.lookup && field.lookup[model.data[fieldName]] ? field.lookup && field.lookup[model.data[fieldName]] : model.data[fieldName]"
                step="1"
                type="number"
                @input="change(fieldName)"
        ></el-input>

        <el-input
                v-else-if="field.type && field.type === 'number-no-arrows'"
                :id="fieldName"
                v-model="model.data[fieldName]"
                :class="{ unsaved: model.isFieldChanged(fieldName), error: hasError, warning: hasWarning, 'no-arrows': true }"
                :clearable="field.clearable || false"
                :max="field.max || null"
                :min="field.min || null"
                :placeholder="field.placeholder ? field.placeholder : field.displayName"
                :readonly="field.readonly || allReadonly || field.readonlyForEdit && readonlyForEdit"
                step="1"
                type="number"
                @input="change(fieldName)"
        ></el-input>

        <el-input
                v-else-if="field.type && field.type === 'multiline'"
                :id="fieldName"
                v-model="model.data[fieldName]"
                :class="{ unsaved: model.isFieldChanged(fieldName), error: hasError, warning: hasWarning }"
                :clearable="field.clearable || false"
                :maxlength="field.maxLength || null"
                :minlength="field.minLength || null"
                :placeholder="field.placeholder ? field.placeholder : field.displayName"
                :readonly="field.readonly || allReadonly || field.readonlyForEdit && readonlyForEdit"
                type="textarea"
                @input="change(fieldName)"
        ></el-input>

        <el-date-picker
                v-else-if="field.type && field.type === 'date'"
                :id="fieldName"
                v-model="model.data[fieldName]"
                :class="{ unsaved: model.isFieldChanged(fieldName), error: hasError, warning: hasWarning }"
                :clearable="field.clearable || false"
                :placeholder="field.placeholder ? field.placeholder : field.displayName"
                :readonly="field.readonly || allReadonly || field.readonlyForEdit && readonlyForEdit"
                format="yyyy-MM-dd"
                type="date"
                value-format="yyyy-MM-ddT00:00:00"
                @change="change(fieldName)"
        >
        </el-date-picker>

        <el-date-picker
                v-else-if="field.type && field.type === 'us-date'"
                :id="fieldName"
                v-model="model.data[fieldName]"
                :class="{ unsaved: model.isFieldChanged(fieldName), error: hasError, warning: hasWarning }"
                :clearable="field.clearable || false"
                :placeholder="field.placeholder ? field.placeholder : field.displayName"
                :readonly="field.readonly || allReadonly || field.readonlyForEdit && readonlyForEdit"
                format="yyyy-MM-dd"
                type="date"
                value-format="yyyy-MM-ddT00:00:00"
                @change="change(fieldName)"
        >
        </el-date-picker>

        <el-checkbox
                v-else-if="field.lookup && field.type && field.type === 'boolean'"
                :id="fieldName"
                v-model="model.data[fieldName]"
                :class="{ checkbox: true, unsaved: model.isFieldChanged(fieldName), error: hasError, warning: hasWarning }"
                :clearable="field.clearable || false"
                :readonly="field.readonly || allReadonly || field.readonlyForEdit && readonlyForEdit"
                @change="change(fieldName)"
        >
            {{ field.displayName || fieldName }}
        </el-checkbox>

        <el-checkbox
                v-else-if="field.type && field.type === 'boolean'"
                :id="fieldName"
                v-model="model.data[fieldName]"
                :class="{ checkbox: true, unsaved: model.isFieldChanged(fieldName), error: hasError, warning: hasWarning }"
                :clearable="field.clearable || false"
                :readonly="field.readonly || allReadonly || field.readonlyForEdit && readonlyForEdit"
                @change="change(fieldName)"
        >
            {{ field.displayName || fieldName }}
        </el-checkbox>
        <el-input
                v-else-if="field.type && field.type === 'currency'"
                :id="fieldName"
                v-model.number="model.data[fieldName]"
                :class="{ unsaved: model.isFieldChanged(fieldName), error: hasError, warning: hasWarning }"
                :clearable="field.clearable || false"
                :max="field.max || null"
                :min="field.min || null"
                :placeholder="field.placeholder ? field.placeholder : field.displayName"
                :readonly="field.readonly || allReadonly || field.readonlyForEdit && readonlyForEdit"
                step="0.01"
                type="number"
                value="0.00"
                @input="change(fieldName)"
        >
            <template slot="prepend" :v-text="currencyDisplay(fieldName)"></template>
            <template v-if="currencyDisplay(fieldName) === 'USD'" slot="prepend">$</template>
            <template v-if="currencyDisplay(fieldName) === 'GBP'" slot="prepend">£</template>
            <template v-if="currencyDisplay(fieldName) === 'AUD'" slot="prepend">A$</template>
            <template v-if="currencyDisplay(fieldName) === 'EUR'" slot="prepend">€</template>
        </el-input>

        <el-input
                v-else-if="field.type && field.type === 'decimal'"
                :id="fieldName"
                v-model.number="model.data[fieldName]"
                :class="{ unsaved: model.isFieldChanged(fieldName), error: hasError, warning: hasWarning }"
                :clearable="field.clearable || false"
                :max="field.max || null"
                :min="field.min || null"
                :placeholder="field.placeholder ? field.placeholder : field.displayName"
                :readonly="field.readonly || allReadonly || field.readonlyForEdit && readonlyForEdit"
                step="0.01"
                type="number"
                @input="change(fieldName)"
        >
        </el-input>

        <el-input
                v-else-if="field.type && field.type === 'round-player'"
                :id="fieldName"
                v-model="model.data[fieldName]"
                :class="{ unsaved: model.isFieldChanged(fieldName), error: hasError, warning: hasWarning }"
                :clearable="field.clearable || false"
                :maxlength="field.maxLength || null"
                :minlength="field.minLength || null"
                :placeholder="field.placeholder ? field.placeholder : field.displayName"
                :readonly="field.readonly || allReadonly || field.readonlyForEdit && readonlyForEdit"
                type="text"
                @input="change(fieldName)"
        >
            <template v-if="isWinnerTeam(fieldName)" slot="prepend"><i class="el-icon-trophy"></i></template>
            <template v-if="isQualifier(fieldName)" slot="prepend"><i class="el-icon-circle-check"></i></template>
        </el-input>


        <el-input
                v-else-if="field.type === 'string'"
                :id="fieldName"
                v-model="model.data[fieldName]"
                :class="{ unsaved: model.isFieldChanged(fieldName), error: hasError, warning: hasWarning }"
                :clearable="field.clearable || false"
                :maxlength="field.maxLength || null"
                :minlength="field.minLength || null"
                :placeholder="field.placeholder ? field.placeholder : field.displayName"
                :readonly="field.readonly || allReadonly"
                type="text"
                @input="change(fieldName)"
        ></el-input>
        
        <el-input
                v-else-if="field.type === 'email'"
                :id="fieldName"
                v-model="model.data[fieldName]"
                :class="{ unsaved: model.isFieldChanged(fieldName), error: hasError, warning: hasWarning }"
                :clearable="field.clearable || false"                
                :placeholder="field.placeholder ? field.placeholder : field.displayName"
                :readonly="field.readonly || allReadonly"
                type="email"
                @input="change(fieldName)"
        ></el-input>
        <div v-else-if="field.type === 'password'" class="container-password">
        <div class="half-password"> <el-input           
                :id="fieldName"
                v-model="model.data[fieldName]"
                :class="{ unsaved: model.isFieldChanged(fieldName), error: hasError, warning: hasWarning }"
                :clearable="field.clearable || false"
                :maxlength="field.maxLength || null"
                :minlength="field.minLength || null"
                :placeholder="field.placeholder ? field.placeholder : field.displayName"
                :readonly="field.readonly || allReadonly"
                :type="fieldType"
                @input="change(fieldName)"
        ></el-input></div> 
        <div class="half-password">
            <button @click="obfuscateToggle" class="ml-auto pl-sm _eye">
                <div>
                <img :src="require(`@/assets/${eyeSvg}.svg`)" alt=""  style="height: 23px;"/>
                </div>
            </button>
        </div>
        </div>
        <div v-else-if="field.type === 'string-array' && field.displayType === 'player-development'">
            <el-alert :closable="false" :title="field.displayName" :type="field.alertType" show-icon>
                <el-tag v-for="requirement in toDevelopmentRequirements(model.data[fieldName])" :key="requirement"
                        :type="field.tagType">
                    {{ toDevelopmentRequirement(requirement) }}
                </el-tag>
            </el-alert>
        </div>

        <div v-else-if="field.type === 'string-array' && field.displayType === 'support-development'">
            <el-alert :closable="false" :title="field.displayName" :type="field.alertType" show-icon>
                <el-tag v-for="requirement in toDevelopmentRequirements(model.data[fieldName])" :key="requirement"
                        :type="field.tagType">
                    {{ toSupportRequirement(requirement) }}
                </el-tag>
            </el-alert>
        </div>

        <el-switch
                v-else-if="field.type === 'switch'"
                :id="fieldName"
                v-model="model.data[fieldName]"
                :active-text="field.activeText"
                :active-value="field.activeValue"
                :inactive-text="field.inactiveText"
                :inactive-value="field.inactiveValue"
                @change="change(fieldName)"
        >
        </el-switch>

        <p v-if="field.hint" class="control-hint">{{ field.hint(model) }}</p>
        <p v-if="hasError" class="error-hint">{{ hasError }}</p>
        <p v-if="hasWarning" class="warning-hint">{{ hasWarning }}</p>
    </div>
</template>

<script>

import { convertIocCode, icoCountries } from '@/utils/country-codes';
import _ from 'lodash';
import { PlayerDevelopment } from '@/models/player-development';
import { PlayerSupport } from '@/models/player-support';

export default {
    name: 'FormControl',
    props: ['model', 'schema', 'fieldName', 'ageAt', 'rankAt', 'readonlyForEdit', 'allReadonly', 'suppressPlayerForSearch'],
    mounted() {
        this.field = this.schema.fields[this.fieldName];
        if (true) return;
        console.log('Mounted');
        console.log(this.fieldName);
        console.log(this.ageAt);
        console.log(this.rankAt);
        console.log(this.readonlyForEdit);
        console.log(this.allReadonly);
        console.log(this.model);
    },
    data() {
        return {
            field: {},
            passwordVisible: false,
            eyeSvg: "eye",
            fieldType: "password",
        };
    },
    computed: {
        
        icoCountries() {
            return icoCountries
        },
        hasError() {
            if (this.field.validate) {
                return this.field.validate(this.model);
            }

            if (!this.model) {
                console.log(this.field);
            }

            if (this.field.required && (this.model.data[this.fieldName] === undefined || this.model.data[this.fieldName] === null)) {
                return 'This field is required!';
            }

            if (this.field.min !== null && this.model && this.model.data && this.model.data[this.fieldName] && parseInt(this.model.data[this.fieldName], 10) < this.field.min) {
                return 'Min value: ' + this.field.min;
            }

            if (this.field.max !== null && this.model.data && this.model.data[this.fieldName] && parseInt(this.model.data[this.fieldName], 10) > this.field.max) {
                return 'Max value: ' + this.field.max;
            }

            if (this.field.minLength !== null && this.model.data && this.model.data[this.fieldName] && ('' + this.model.data[this.fieldName]).length < this.field.minLength) {
                return 'Min length: ' + this.field.minLength;
            }

            if (this.field.maxLength !== null && this.model.data && this.model.data[this.fieldName] && ('' + this.model.data[this.fieldName]).length > this.field.maxLength) {
                return 'Max length: ' + this.field.maxLength;
            }

            if(this.model.data[this.fieldName] != null){
                if (this.hasSpecialCharacters(this.model.data[this.fieldName]) && this.field.specialCharacterAllowed !== undefined && this.field.specialCharacterAllowed === false && (this.field.type === 'string' || this.field.type === 'nationality')) {
                return 'Field cannot contain special characters.';
                }          
            }

            if(this.model.data[this.fieldName] != null){
                if (this.field.type === 'email' && !this.emailValidation(this.model.data[this.fieldName])) {
                return 'Valid email required.';
                }          
            }

            return null;
        },
        hasWarning() {
            if (this.model.data.outstandingRequirements !== undefined && this.model.data.overdueRequirements !== undefined) {
                if (this.field.developmentRequirement && this.model.data.overdueRequirements.includes(_.upperFirst(this.fieldName))) {
                    return 'Overdue Requirement';
                }
                if (this.field.developmentRequirement && this.model.data.outstandingRequirements.includes(_.upperFirst(this.fieldName))) {
                    return 'Outstanding Requirement';
                }
            }

            return null;
        },
    },
    methods: {
         obfuscateToggle(){
            if (this.eyeSvg === "eye-closed") {
               this.eyeSvg = "eye";
               this.fieldType = "password";
            } else {
               this.eyeSvg = "eye-closed";
               this.fieldType = "text"
            }
        },
        emailValidation(email){
            var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(email); 
        },
        hasSpecialCharacters(value) {
            var specialCharRegex = /^(?!^[a-zA-Z0-9 -]+$).*/;
            return specialCharRegex.test(value);
        },      
        toIso3166(code) {
            return code ? convertIocCode(code).iso3 : '';
        },
        change(fieldId) {
            this.$emit('change', fieldId);
        },
        toDevelopmentRequirements(strings) {
            var result = [];
            for (let i = 0; i < strings.length; i++) {
                result.push(strings[i]);
            }
            return result;
        },
        toDevelopmentRequirement(string) {
            if (string === '...') return string;
            return PlayerDevelopment.schema.fields[_.lowerFirst(string)].displayName;
        },
        toSupportRequirement(string) {
            if (string === '...') return string;
            return PlayerSupport.schema.fields[_.lowerFirst(string)].displayName;
        },
        playerSelected(player) {
            this.$emit('player-selected', player);
            if (this.field.playerName) {
                if (player) this.model.data[this.field.playerName] = player.fullName;
                else this.model.data[this.field.playerName] = null;
            }
            if (this.field.playerAge) {
                if (player) this.model.data[this.field.playerAge] = player.playerAge;
                else this.model.data[this.field.playerAge] = null;
            }
            if (this.field.playerSpecialRank) {
                if (player) this.model.data[this.field.playerSpecialRank] = player.specialRank;
                else this.model.data[this.field.playerSpecialRank] = null;
            }
            if (this.field.playerRank) {
                if (player && this.model.data.sdFlag == 'S') this.model.data[this.field.playerRank] = player.singlesRank;
                else if (player && this.model.data.sdFlag == 'D') this.model.data[this.field.playerRank] = player.doublesRank;
                else this.model.data[this.field.playerRank] = null;
            }
            if (this.field.toClear) {
                this.field.toClear.forEach((fieldName) => {
                    this.model.data[fieldName] = null;
                });
            }
        },
        playerSupportSelected(support) {
            if (this.field.playerSupporterName) {
                if (support) this.model.data[this.field.playerSupporterName] = support.fullName;
                else this.model.data[this.field.playerSupporterName] = null;
            }
        },
        isWinnerTeam(fieldName) {
            let winner = this.field.winner ? this.field.winner : 'matchWinner';
            let winnerValue = this.model.data[winner] + '';
            if (!winnerValue) {
                return false;
            }
            if (winnerValue === this.schema.fields[fieldName].team && this.model.data[fieldName]) {
                return true;
            }
            return false;
        },
        isQualifier(fieldName) {
            let winner = this.field.winner ? this.field.winner : 'matchWinner';
            let winnerValue = this.model.data[winner] + '';
            if (!winnerValue) {
                return false;
            }
            if (winnerValue === '0' && this.model.data[fieldName]) {
                return true;
            }
            return false;
        },
        currencyDisplay(fieldName) {
            let currency = this.field.currency ? this.field.currency : 'currency';
            let currValue = this.model.data[currency];
            if (!currValue) {
                return '';
            }
            let lookups = this.schema.fields[currency].lookup;
            if (!lookups && currValue !== 'No') {
                return currValue;
            }
            for (var i in lookups) {
                if (lookups[i].id == currValue) {
                    let lookup = lookups[i].displayName;
                    return lookup;
                }
            }
            return '';
        },
    },
};
</script>

<style lang="scss" scoped>
label {
    font-size: 12px;
    color: #444;
    display: block;
    padding-left: 8px;
}

.form-field {
    padding-bottom: 16px;
    padding-right: 16px;
    display: inline-block;
}

.checkbox {
    padding-top: 24px;
}

.el-date-editor,
.el-autocomplete,
.el-select {
    width: 100% !important;
}

.unsaved input,
.unsaved textarea {
    background-color: #f9fbe7 !important;
}

.dropdown-option {
    display: flex;
    align-items: center;
}

.control-hint {
    font-size: 11px;
    text-align: right;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: #777;
}

.warning-hint {
    font-size: 11px;
    text-align: right;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: #e65100;
    min-height: 15px;
}

.error-hint {
    font-size: 11px;
    text-align: right;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: red;
    min-height: 15px;
}

.el-icon-trophy,
.el-icon-circle-check,
.el-icon-success {
    color: #944cff;
}

.container-password {
    display: grid;
    width: 100%;
    grid-template-columns: 1.8fr .2fr; /* Two equal columns */
}

.half-password {
    border: 0px solid #000;
    padding: 0px;
    box-sizing: border-box;
}
</style>
