import axios from 'axios';
import { buildParams } from '@/utils/query-param-builder';

export default {
    namespaced: true,

    state: {
        shadowDoublesRankings: [],
        loadingShadowDoublesRanking: false,
        loadingErrorShadowDoublesRanking: null,
    },

    getters: {
        shadowDoublesRankings: (state) => state.shadowDoublesRankings,
        loadingShadowDoublesRanking: (state) => state.loadingShadowDoublesRanking,
        loadingErrorShadowDoublesRanking: (state) => state.loadingErrorShadowDoublesRanking,
    },

    mutations: {
        setShadowDoublesRankings(state, data) {
            state.shadowDoublesRankings = data;
        },

        startLoadingShadowDoublesRankings(state) {
            state.loadingShadowDoublesRanking = true;
        },

        stopLoadingShadowDoublesRankings(state) {
            state.loadingShadowDoublesRanking = false;
        },

        setLoadingErrorShadowDoublesRanking(state, error) {
            state.loadingErrorShadowDoublesRanking = error;
        },

        clearLoadingErrorShadowDoublesRanking(state) {
            state.loadingErrorShadowDoublesRanking = null;
        },
    },

    actions: {
        async retrieveCurrentDoublesShadowRankings(context, options = {}) {
            context.commit('startLoadingShadowDoublesRankings');
            context.commit('clearLoadingErrorShadowDoublesRanking');
            let params = buildParams(options);
            try {
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/ShadowRankingCurrent/Doubles`,
                    { params });
                context.commit('setShadowDoublesRankings', result.data);
                context.commit('stopLoadingShadowDoublesRankings');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('setLoadingErrorShadowDoublesRanking', error);
                context.commit('stopLoadingShadowDoublesRankings');
                return Promise.reject(error);
            }
        }
    },
};
