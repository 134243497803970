<template>
    <div>
        <DialogAddItem
            :layout="layoutAdd"
            :schema="schemaAdd"
            :selected="selectedRanking"
            :visibleSync="addDialogVisible"
            dtoName="Historic Player Ranking"
            @change="onChange"
            @close="addDialogVisible = false"
            @save="saveChanges"
        />

        <DialogEditItem
            :layout="layout"
            :schema="schema"
            :selected="selectedRanking"
            :visibleSync="editDialogVisible"
            dtoName="Historic Player Ranking"
            @change="onChange"
            @close="editDialogVisible = false"
            @delete="deleteItem"
            @save="saveChanges"
        />

        <div class="flex-row mt-0 mb-32">
            <h2><i class="el-icon-medal-1 mr-16"></i>Ranking History {{ rankingDate ? ' for ' + rankingDate : '' }}</h2>
            <span class="spacer"></span>

            <el-select :value="wtaTourYear" class="mr-16 year-select" placeholder="Select TourYear" size="mini" @change="tourYearChanged">
                <el-option v-for="(item, i) in wtaTourYears" :key="i" :label="item.tourYear" v-bind:value="item"></el-option>
            </el-select>

            <el-select :value="rankingDate" class="mr-16" placeholder="Select RankingDate" size="mini" @change="rankingDateChanged">
                <el-option v-for="(item, i) in rankingDates" :key="i" :label="item" :value="item"></el-option>
            </el-select>

            <SelectPlayer :value="selectedPlayerFullName" class="mr-8" @palyer-selected="playerSelected" />

            <el-button plain size="mini" type="primary" :disabled="isBaselineReader" @click="reset">Reset</el-button>

            <el-button plain size="mini" type="primary" v-if="!isBaselineReader" @click="addRanking">Add Ranking</el-button>

            <el-button class="ml-8" plain size="mini" @click="exportCsvClick">Export CSV</el-button>
        </div>

        <ErrorDisplay :requestError="this.requestError" @closed="clearError" />

        <DetailedInformation :informationArray="this.informationArray()" screenName="Current Rankings" />

        <el-tabs v-show="isBefore2024RuleChangesDate" v-model="activeTab">
            <el-tab-pane label="Singles Rankings" name="singles">
                <RankingHistorySingles ref="singles" :queryString="queryString" :rankingDate="rankingDate" @rankingSelected="rankingSelected" />
            </el-tab-pane>

            <el-tab-pane label="Doubles Rankings" name="doubles">
                <RankingHistoryDoubles ref="doubles" :queryString="queryString" :rankingDate="rankingDate" @rankingSelected="rankingSelected" />
            </el-tab-pane>

            <el-tab-pane label="Championship Singles" name="champSingles">
                <RankingHistoryChampSingles ref="champSingles" :queryString="queryString" :rankingDate="rankingDate" @rankingSelected="rankingSelected" />
            </el-tab-pane>

            <el-tab-pane label="Team Rankings" name="teamRankings">
                <RankingHistoryTeamPoints ref="teamRankings" :queryString="queryString" :rankingDate="rankingDate" />
            </el-tab-pane>
        </el-tabs>

        <el-tabs v-show="!isBefore2024RuleChangesDate" v-model="activeTab">
            <el-tab-pane label="Singles Rankings" name="singles2">
                <RankingHistorySingles ref="singles2" :queryString="queryString" :rankingDate="rankingDate" @rankingSelected="rankingSelected" />
            </el-tab-pane>

            <el-tab-pane label="Doubles Rankings" name="doubles2">
                <RankingHistoryDoubles ref="doubles2" :queryString="queryString" :rankingDate="rankingDate" @rankingSelected="rankingSelected" />
            </el-tab-pane>

            <el-tab-pane label="Race Singles" name="raceSinglesWtaAndGs">
                <RankingHistoryRaceSinglesWtaAndGs ref="raceSinglesWtaAndGs" :queryString="queryString" :rankingDate="rankingDate" />
            </el-tab-pane>

            <el-tab-pane label="Team Rankings" name="teamRankings2">
                <RankingHistoryTeamPoints ref="teamRankings2" :queryString="queryString" :rankingDate="rankingDate" />
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
import * as moment from 'moment';
import { mapGetters, mapActions } from 'vuex';
import { Ranking } from '../../models/ranking';
import { retrieveDtos, deleteConfirmDto, createConfirmDto, updateConfirmDto } from '../../utils/crud-confirm';
import RankingHistoryRaceSinglesWtaAndGs from './RankingHistoryRaceSinglesWtaAndGs.vue';
import { isBaselineReader } from '../../services/authGroups';

export default {
    components: {
        RankingHistoryRaceSinglesWtaAndGs,
    },
    data() {
        return {
            schema: Ranking.schema,
            schemaAdd: Ranking.schemaAdd,
            layout: Ranking.layout,
            layoutAdd: Ranking.layoutAdd,
            editDialogVisible: false,
            addDialogVisible: false,
            mode: 'add',
            selectedRanking: null,
            selectedPlayerFullName: null,
            activeTab: this.isBefore2024RuleChangesDateF() ? 'singles' : 'singles2',
            requestError: null,
            queryString: '',
            isBaselineReader: isBaselineReader(),
        };
    },
    mounted() {
        if (this.wtaTourYear && this.rankingDate) {
            return;
        }
        this.retrieveValidWtaTourYears()
            .then(() => {
                this.requestError = null;
                if (this.rankingDate) {
                    return;
                }
                this.tourYearChanged(this.wtaTourYears[0]);
            })
            .catch((error) => {
                this.requestError = error;
                this.$notify({
                    title: 'Get WTA Tour Years Error',
                    message: error.response.data.Message,
                    type: 'error',
                    duration: 5000,
                });
            });
    },
    computed: {
        ...mapGetters('rankingHistoryOverview', ['loadingRanking', 'rankingDates', 'rankingDate']),
        ...mapGetters('wtaTourYears', ['wtaTourYears', 'wtaTourYear', 'wtaTourYearsLoadingError']),
        isBefore2024RuleChangesDate() {
            if (!this.rankingDate) {
                return false;
            }
            let rankDateString = moment(this.rankingDate).format('YYYY-MM-DD');
            return rankDateString < '2024-01-01';
        },
    },
    methods: {
        ...mapActions('rankingHistoryOverview', ['create', 'update', 'delete', 'retrieveRankigDatesForTourYear', 'setRankingDate']),
        ...mapActions('wtaTourYears', ['retrieveValidWtaTourYears', 'setWtaTourYear']),
        tourYearChanged(param) {
            this.setWtaTourYear(param.tourYear);
            this.retrieveRankigDatesForTourYear(param)
                .then(() => {
                    this.requestError = null;
                    if (this.rankingDates.length) {
                        this.setRankingDate(this.rankingDates[0]);
                        this.adaptActiveTab();
                    }
                })
                .catch((error) => {
                    this.requestError = error;
                    this.$notify({
                        title: 'Get Ranking Dates Error',
                        message: error.response.data.Message,
                        type: 'error',
                        duration: 5000,
                    });
                });
        },
        rankingDateChanged(param) {
            this.setRankingDate(param);
            this.adaptActiveTab();
        },
        exportCsvClick() {
            this.$refs[this.activeTab].exportCsvClick();
        },
        playerSelected(player) {
            this.queryString = player.id;
            this.selectedPlayerFullName = player.fullName;
        },
        reset() {
            if (this.queryString === '') {
                this.$refs.singles.reset();
                this.$refs.doubles.reset();
                this.$refs.champSingles.reset();
                this.$refs.teamRankings.reset();
                this.$refs.raceSinglesWtaAndGs.reset();
                this.$refs.singles2.reset();
                this.$refs.doubles2.reset();
                this.$refs.teamRankings2.reset();
            } else {
                this.queryString = '';
                this.selectedPlayerFullName = null;
            }
        },
        refresh() {
            this.$refs.singles.refresh();
            this.$refs.doubles.refresh();
            this.$refs.champSingles.refresh();
            this.$refs.teamRankings.refresh();
            this.$refs.raceSinglesWtaAndGs.refresh();
            this.$refs.singles2.refresh();
            this.$refs.doubles2.refresh();
            this.$refs.teamRankings2.refresh();
        },
        adaptActiveTab() {
            if (this.isBefore2024RuleChangesDateF()) {
                if (this.activeTab && this.activeTab.endsWith('2')) {
                    this.activeTab = this.activeTab.substr(0, this.activeTab.length - 1);
                }
                if (['raceSinglesWtaAndGs'].includes(this.activeTab)) {
                    this.activeTab = 'champSingles';
                }
            } else {
                if (this.activeTab && !this.activeTab.endsWith('2')) {
                    this.activeTab = this.activeTab + '2';
                }
                if (this.activeTab == 'champSingles2') {
                    this.activeTab = 'raceSinglesWtaAndGs';
                }
            }
        },
        isBefore2024RuleChangesDateF() {
            if (!this.rankingDate) {
                return false;
            }
            let rankDateString = moment(this.rankingDate).format('YYYY-MM-DD');
            return rankDateString < '2024-01-01';
        },
        addRanking() {
            if (['teamRankings', 'raceSinglesWtaAndGs'].includes(this.activeTab)) {
                this.$refs[this.activeTab].addRanking();
                return;
            }
            this.selectedRanking = new Ranking({});
            this.mode = 'add';
            this.addDialogVisible = true;
        },
        rankingSelected(item) {
            this.selectedRanking = new Ranking(item);
            this.mode = 'edit';
            this.editDialogVisible = true;
        },
        saveChanges() {
            if (this.mode === 'edit') {
                let params = {
                    self: this,
                    fUpdate: this.update,
                    dto: this.selectedRanking,
                    dtoName: 'Historic Player Ranking',
                    callback: this.crudCallback,
                };
                updateConfirmDto(params);
            } else if (this.mode === 'add') {
                let params = {
                    self: this,
                    fCreate: this.create,
                    dto: this.selectedRanking,
                    dtoName: 'Historic Player Ranking',
                    callback: this.crudCallback,
                };
                createConfirmDto(params);
            }
        },
        onChange() {},
        deleteItem() {
            let params = {
                self: this,
                fDelete: this.delete,
                vConfirm: this.selectedRanking.data.playerId,
                dto: this.selectedRanking,
                dtoName: 'Historic Player Ranking',
                vConfirmName: 'Player ID',
                callback: this.crudCallback,
            };
            deleteConfirmDto(params);
        },
        clearError() {
            this.requestError = null;
        },
        crudCallback() {
            this.refresh();
            this.requestError = null;
            this.addDialogVisible = false;
            this.editDialogVisible = false;
        },
        informationArray() {
            return [
                'This screen is used to display Historical Rankings for players.',
                'Historical Rankings are fetched from table player_rank_history and view TeamPointsHistory.',
                'Season selection is fetched from table year_end. Ranking Date selection is fetched from table player_rank_history.',
                'Searching is possible by player id <b>or</b> last name <b>or</b> first name. Combined search is not possible.',
                'Adding a new Historical Ranking is possible by clicking the button "Add ranking". Adding a new Historic Ranking if data is already present will result in an error.',
                'Editing a present Historical Ranking is possible by clicking on an entry in the table below.',
                'By default Historical Rankings are generated by Ranking Procedure.',
                'CSV Export of each table is possible by clicking respective button.',
            ];
        },
    },
};
</script>

<style scoped>
.input-search {
    max-width: 256px;
}

.year-select {
    max-width: 96px;
}
</style>
