<template>
    <div v-loading="loadingNextOnTournaments">
        <div class="flex-row mt-0 mb-32">
            <span class="spacer"></span>
        </div>
        <ErrorDisplay :requestError="this.nextOnTournamentsError" @closed="clearError" />
        <DataTable
            :items="nextOnTournaments"
            :schema="schemaTournament"
            :fields="['name', 'startDate', 'sponsor', 'tournamentId', 'season']"
            :autoSort="false"
        />
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { Tournament } from '../../models/tournament';
import moment from 'moment';

export default {
    props: ['rankingDate'],
    data() {
        return {
            schemaTournament: Tournament.schema,
        };
    },
    mounted() {
    },
    computed: {
        ...mapGetters('rankingProcedure', ['nextOnTournaments', 'loadingNextOnTournaments', 'nextOnTournamentsError']),
    },
    methods: {
        clearError() {
            this.nextOnTournamentsError = null;
        }
    },
};
</script>

<style scoped></style>
