<template>
    <div>
        <el-dialog
            :visible.sync="visibleSync"
            :close-on-click-modal="false"
            title="Outstanding Support Requirements"
            width="65%"
            top="10vh"
            :show-close="false"
        >
            <div class="dialog-main">
                <FormGroup :model="selectedPlayer" :schema="schema" :layout="layoutContact" @change="onChange" :allReadonly="true" />
                <FormGroup :model="selectedPlayer" :schema="schema" :layout="layout" @change="onChange" :allReadonly="true" />
            </div>

            <div class="dialog-footer">
                <el-button @click="onClose" plain type="primary">Close</el-button>
                <span class="spacer"></span>
                <el-button @click="gotoPrevPlayer" plain type="primary">Prev</el-button>
                <el-button @click="gotoNextPlayer" plain type="primary">Next</el-button>
                <span class="spacer"></span>
            </div>
        </el-dialog>
        <div class="flex-row mt-16 mb-16">
            <h3>Outstanding Support Requirements {{ this.formattedUpToDate }}</h3>
            <span class="spacer"></span>

            <el-input
                placeholder="Filter by Id or Name"
                v-model="options.queryString"
                class="input-search mr-16"
                @change="resetPage"
                @keydown.esc.native="reset"
                @keydown.enter.native="resetPage"
            >
                <el-button slot="append" icon="el-icon-search" @click="resetPage"></el-button>
            </el-input>
            <el-button icon="el-icon-document" @click="onClickExportPdf" v-loading="loadingPdfOutstandingSupportRequirements" class="mr-16"
                >Export PDF</el-button
            >
        </div>

        <div v-loading="loadingOutstandingSupportRequirements || computingRequirementsReports">
            <DataTable
                v-if="!loadingErrorOutstandingSupportRequirements"
                :schema="schema"
                :items="outstandingSupportRequirements.data"
                :fields="['contactId', 'fullName', 'supporterType', 'eligibleDate', 'deadlineDate', 'outstandingRequirements']"
                :autoSort="false"
                @item-clicked="selectPlayer"
                @header-clicked="setSort"
            />

            <Paginator
                v-if="!loadingErrorOutstandingSupportRequirements"
                :data="outstandingSupportRequirements"
                :elementsPerPage="options.pageSize"
                @prev-page="prevPage"
                @next-page="nextPage"
                @set-page-size="setPageSize"
            />
        </div>
    </div>
</template>


<script>
import { mapGetters, mapActions } from 'vuex';
import { PlayerSupport } from '../../models/player-support';
import { retrieveDtos } from '../../utils/crud-confirm';
import moment from 'moment';

export default {
    data() {
        return {
            schema: PlayerSupport.schema,
            layoutContact: PlayerSupport.layoutRequirementsContact,
            layout: PlayerSupport.layoutDevelopmentOutstandingRequriements,
            requestError: null,
            visibleSync: false,
            selectedPlayer: null,
            playerPointer: null,
            options: {
                queryString: '',
                pageSize: 20,
                sortBy: null,
                sortDir: 'ascending',
                page: 0,
                nationality: '',
                ageAt: null,
            },
        };
    },
    mounted() {
        this.search();
    },
    computed: {
        ...mapGetters('playerSupportReports', [
            'supportRequirementsUpToDate',
            'outstandingSupportRequirements',
            'loadingRequirementsUpToDate',
            'loadingOutstandingSupportRequirements',
            'loadingErrorRequirementsUpToDate',
            'loadingErrorOutstandingSupportRequirements',
            'computingRequirementsReports',
            'loadingPdfOutstandingSupportRequirements',
        ]),
        formattedUpToDate() {
            return this.supportRequirementsUpToDate ? ' up to ' + moment(this.supportRequirementsUpToDate).format('YYYY-MM-DD') : ' - please select Up to Date';
        },
    },
    methods: {
        ...mapActions('playerSupportReports', [
            'findOutstandingSupportRequirements',
            'retieveSupportRequirementsUpToDate',
            'exportPdfOutstandingSupportRequirements',
        ]),
        refresh() {
            this.retieveSupportRequirementsUpToDate();
            this.search();
        },
        search() {
            let params = {
                self: this,
                fRetrieve: this.findOutstandingSupportRequirements,
                options: this.options,
                dtosName: 'Outstanding Support Requirements',
            };
            retrieveDtos(params);
        },
        selectPlayer(item, index) {
            this.playerPointer = index;
            this.selectedPlayer = new PlayerSupport(item);
            this.visibleSync = true;
        },
        setSort(event) {
            this.options.sortBy = event.property;
            this.options.sortDir = event.order;
            this.search();
        },
        prevPage() {
            this.options.page--;
            this.search();
        },
        nextPage() {
            this.options.page++;
            this.search();
        },
        setPageSize(size) {
            this.options.pageSize = size;
            this.search();
        },
        resetPage() {
            this.options.page = 0;
            this.search();
        },
        reset() {
            this.options.queryString = null;
            this.options.page = 0;
            this.search();
        },
        onClose() {
            this.visibleSync = false;
        },
        gotoPrevPlayer() {
            if (this.playerPointer == 0) {
                return;
            }
            this.playerPointer -= 1;
            this.selectedPlayer = new PlayerSupport(this.outstandingSupportRequirements.data[this.playerPointer]);
        },
        gotoNextPlayer() {
            if (this.playerPointer == this.outstandingSupportRequirements.data.length - 1) {
                return;
            }
            this.playerPointer += 1;
            this.selectedPlayer = new PlayerSupport(this.outstandingSupportRequirements.data[this.playerPointer]);
        },
        onChange() {},

        onClickExportPdf() {
            this.downloadPdf(this.exportPdfOutstandingSupportRequirements, 'PST_Requirements_Outstanding.pdf');
        },

        downloadPdf(fDownload, filename) {
            this.requestError = null;
            fDownload(this.options)
                .then((result) => {
                    const file = new Blob([result], { type: 'application/pdf' });
                    saveAs(file, filename);
                    this.$notify({
                        title: 'PDF Export: Outstanding PST Requirements download successful',
                        message: filename + ' downloaded',
                        type: 'success',
                        duration: 5000,
                    });
                })
                .catch((error) => {
                    this.requestError = error;
                    this.$notify({
                        title: 'PDF Export: Outstanding PST Requirements download failed',
                        message: filename + ': failed',
                        type: 'error',
                        duration: 7000,
                    });
                });
        },
    },
};
</script>

<style>
</style>
