import { checkAuthentication, getProfile, login } from './authService';
import axios from 'axios';
import store from '../store/index';

async function initializeApp() {
    try {
        const accessToken = await checkAuthentication();
        if (accessToken) {
            const profile = await getProfile();

            await setupAxiosInterceptors();
            await setupFetchInterceptor();

            store.dispatch('auth/login', profile);
        }
    } catch (error) {
        // eslint-disable-next-line
        console.error('Authentication error:', error);
        login();
    }
}

export const setupAxiosInterceptors = async () => {
    axios.interceptors.request.use((config) => {
        const idToken = localStorage.getItem('idToken');
        const accessToken = localStorage.getItem('accessToken');
        if (idToken) config.headers['Authorization'] = `Bearer ${idToken}`;
        if (accessToken) config.headers['X-Access-Token'] = `Bearer ${accessToken}`;
        return config;
    });
};

export const setupFetchInterceptor = async () => {
    const originalFetch = window.fetch;
    window.fetch = async (...args) => {
        let [resource, config] = args;

        if (!config) config = {};

        if (!config.headers) config.headers = new Headers();

        if (resource.startsWith(process.env.VUE_APP_HOSTNAME)) {
            const idToken = localStorage.getItem('idToken');
            const accessToken = localStorage.getItem('accessToken');
            if (idToken) config.headers.set('Authorization', `Bearer ${idToken}`);
            if (accessToken) config.headers.set('X-Access-Token', `Bearer ${accessToken}`);
        }

        return await originalFetch(resource, config);
    };
};

initializeApp();
