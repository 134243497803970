import axios from 'axios';
import { WtaSystem } from '../models/wta-system';

export default {
    namespaced: true,

    state: {
        wtaSystem: new WtaSystem(),
        loadingWtaSystem: false,
        loadingWtaSystemError: null,
    },

    getters: {
        wtaSystem: (state) => state.wtaSystem,
        loadingWtaSystem: (state) => state.loadingWtaSystem,
        loadingWtaSystemError: (state) => state.loadingWtaSystemError,
    },

    mutations: {
        setWtaSystem(state, data) {
            state.wtaSystem = data;
        },
        clearWtaSystem(state) {
            state.wtaSystem = new WtaSystem();
        },
        startLoading(state) {
            state.loadingWtaSystem = true;
        },
        stopLoading(state) {
            state.loadingWtaSystem = false;
        },
        setLoadingError(state, error) {
            state.loadingWtaSystemError = error;
        },
        clearLoadingError(state) {
            state.loadingWtaSystemError = null;
        },
    },

    actions: {
        async retrieveWtaSystem(context) {
            context.commit('startLoading');
            try {
                var result = await axios.get(`${process.env.VUE_APP_API_URL}/WtaSystem`);
                context.commit('setWtaSystem', new WtaSystem(result.data));
                context.commit('clearLoadingError');
                context.commit('stopLoading');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('setLoadingError', error);
                context.commit('clearWtaSystem');
                context.commit('stopLoading');
                return Promise.reject(error);
            }
        },
        async updateWtaSystem(context, wtaSystem = {}) {
            context.commit('startLoading');
            try {
                var result = await axios.put(`${process.env.VUE_APP_API_URL}/WtaSystem`, wtaSystem.data);
                context.commit('clearLoadingError');
                context.commit('stopLoading');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('setLoadingError', error);
                context.commit('stopLoading');
                return Promise.reject(error);
            }
        },

        async clearWtaLoadingError(context) {
            context.commit('clearLoadingError');
        },
    },
};
