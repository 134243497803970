<template>
    <div>
        <table class="summary-table mb-16" v-if="summary && summary.allResults">
            <tr>
                <td>
                    <div>Combined Total Points</div>
                    <div>from Grand Slams, Premier Mandatory and Premier 5</div>
                </td>
                <td>{{ summary.combinedPointsGsPmP5 }}</td>
            </tr>
            <tr>
                <td>
                    <div>Total International Points</div>
                    <div>from Grand Slams, Premier and International</div>
                </td>
                <td>{{ summary.totalInternationalPoints }}</td>
            </tr>
        </table>
    </div>
</template>

<script>
export default {
    props: ['summary'],
    data() {
        return {};
    },
    mounted() {},
    computed: {},
    methods: {},
};
</script>
