<template>
    <div>
        <DialogAddItem
                :layout="layoutAdd"
                :schema="schemaAdd"
                :selected="selectedTeamRanking"
                :visibleSync="addDialogVisible"
                dtoName="Historic Team Ranking"
                @change="onChange"
                @close="addDialogVisible = false"
                @save="saveChanges"
        />

        <DialogEditItem
                :layout="layout"
                :schema="schema"
                :selected="selectedTeamRanking"
                :visibleSync="editDialogVisible"
                dtoName="Historic Team Ranking"
                @change="onChange"
                @close="editDialogVisible = false"
                @delete="deleteItem"
                @save="saveChanges"
        />

        <ErrorDisplay :requestError="this.loadingError" @closed="clearError"/>

        <div v-loading="loadingRanking">
            <DataTable
                    :autoSort="false"
                    :fields="['player1Id', 'player1ConcatName', 'player1Nationality', 'player2Id', 'player2ConcatName', 'player2Nationality', 'rank', 'points']"
                    :items="rankings.data"
                    :schema="schema"
                    @item-clicked="selectRanking"
                    @header-clicked="setSort"
            />

            <Paginator
                    v-if="rankings.data"
                    :data="rankings"
                    :elementsPerPage="options.pageSize"
                    @prev-page="prevPage"
                    @next-page="nextPage"
                    @set-page-size="setPageSize"
            />
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { TeamRanking } from '../../models/team-ranking';
import * as moment from 'moment';
import { retrieveDtos, deleteConfirmDto, createConfirmDto, updateConfirmDto } from '../../utils/crud-confirm';
import FileSaver from 'file-saver';

export default {
    props: ['rankingDate', 'queryString'],
    watch: {
        rankingDate() {
            this.reset();
        },
        queryString() {
            this.queryStringChanged(this.queryString);
            this.reset();
        },
    },
    data() {
        return {
            schema: TeamRanking.schema,
            layout: TeamRanking.layoutHistory,
            schemaAdd: TeamRanking.schemaAdd,
            layoutAdd: TeamRanking.layoutHistoryAdd,
            editDialogVisible: false,
            addDialogVisible: false,
            mode: 'add',
            selectedTeamRanking: null,
        };
    },
    mounted() {
    },
    computed: mapGetters('historyChampDoubles', ['rankings', 'loadingRanking', 'loadingError', 'options']),
    methods: {
        ...mapActions('historyChampDoubles', ['findTeamRankings', 'createTeamRanking', 'updateTeamRanking', 'deleteTeamRanking', 'exportCsv']),
        refresh() {
            let findParams = {
                options: this.options,
                date: this.rankingDate,
            };
            let params = {
                self: this,
                fRetrieve: this.findTeamRankings,
                options: findParams,
                dtosName: 'Historic Team Rankings',
            };
            retrieveDtos(params);
        },
        reset() {
            this.options.page = 0;
            this.refresh();
        },
        prevPage() {
            this.options.page--;
            this.refresh();
        },
        nextPage() {
            this.options.page++;
            this.refresh();
        },
        setPageSize(size) {
            this.options.pageSize = size;
            this.refresh();
        },
        setSort(event) {
            this.options.sortBy = event.property;
            this.options.sortDir = event.order;
            this.refresh();
        },
        queryStringChanged(queryString) {
            this.options.queryString = queryString;
            this.refresh();
        },
        selectRanking(item) {
            console.log(item);
            this.selectedTeamRanking = new TeamRanking(item);
            this.mode = 'edit';
            this.editDialogVisible = true;
        },
        addRanking() {
            this.selectedTeamRanking = new TeamRanking({});
            this.mode = 'add';
            this.addDialogVisible = true;
        },
        clearError() {
            this.loadingError = null;
        },
        saveChanges() {
            if (this.mode === 'edit') {
                let params = {
                    self: this,
                    fUpdate: this.updateTeamRanking,
                    dto: this.selectedTeamRanking,
                    dtoName: 'Historic Team Ranking',
                    callback: this.crudCallback,
                };
                updateConfirmDto(params);
            } else if (this.mode === 'add') {
                let params = {
                    self: this,
                    fCreate: this.createTeamRanking,
                    dto: this.selectedTeamRanking,
                    dtoName: 'Historic Team Ranking',
                    callback: this.crudCallback,
                };
                createConfirmDto(params);
            }
        },
        onChange() {
        },
        deleteItem() {
            console.log(this.selectedTeamRanking);
            let params = {
                self: this,
                fDelete: this.deleteTeamRanking,
                vConfirm: this.selectedTeamRanking.data.player1Id,
                dto: this.selectedTeamRanking,
                dtoName: 'Historic Team Ranking',
                vConfirmName: 'Player 1 ID',
                callback: this.crudCallback,
            };
            deleteConfirmDto(params);
        },
        crudCallback() {
            this.refresh();
            this.requestError = null;
            this.addDialogVisible = false;
            this.editDialogVisible = false;
        },
        exportCsvClick() {
            const date = moment(this.rankingDate).format('YYYY-MM-DD');
            this.exportCsv({
                date: date,
                queryString: this.options.queryString || '',
                sortBy: this.options.sortBy || 'rank',
                sortDir: this.options.sortDir || 1,
            })
                    .then((result) => {
                        const file = new File([result], `team_points_history_${moment(date).format('YYYY_MM_DD')}.csv`, { type: 'text/csv' });
                        FileSaver.saveAs(file);
                    })
                    .catch((error) => {
                        console.log(error);
                        this.$notify({
                            title: 'CSV export',
                            message: 'CSV export failed',
                            type: 'error',
                            duration: 5000,
                        });
                    });
        },
    },
};
</script>
