import axios from 'axios';
import moment from 'moment';

export default {
    namespaced: true,

    state: {
        shadowTourYears: [],
        loadingShadowTourYears: false,
        shadowRankingDates: [],
        loadingShadowRankingDates: false,
        loadingError: null,
    },

    getters: {
        shadowTourYears: (state) => state.shadowTourYears,
        loadingShadowTourYears: (state) => state.loadingShadowTourYears,
        shadowRankingDates: (state) => state.shadowRankingDates,
        loadingShadowRankingDates: (state) => state.loadingShadowRankingDates,
        loadingError: (state) => state.loadingError,
    },
    mutations: {
        setShadowTourYears(state, data) {
            state.shadowTourYears = data;
        },
        startLoadingShadowTourYears(state) {
            state.loadingShadowTourYears = true;
        },
        stopLoadingShadowTourYears(state) {
            state.loadingShadowTourYears = false;
        },

        setShadowRankingDates(state, data) {
            state.shadowRankingDates = data;
        },
        startLoadingShadowRankingDates(state) {
            state.loadingShadowRankingDates = true;
        },
        stopLoadingShadowRankingDates(state) {
            state.loadingShadowRankingDates = false;
        },

        setLoadingError(state, error) {
            state.loadingError = error;
        },
        clearLoadingError(state) {
            state.loadingError = null;
        },
    },

    actions: {
        async retrieveValidShadowTourYears(context, shadowRankId) {
            context.commit('startLoadingShadowTourYears');
            context.commit('clearLoadingError');
            try {
                let result = await axios.get(`${process.env.VUE_APP_API_URL}/ShadowRanking/ValidShadowTourYears/${shadowRankId}`);
                context.commit('setShadowTourYears', result.data);
                context.commit('stopLoadingShadowTourYears');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('setLoadingError', error);
                context.commit('stopLoadingShadowTourYears');
                return Promise.reject(error);
            }
        },
        async retrieveShadowRankingDatesForTourYear(context, options) {
            context.commit('startLoadingShadowRankingDates');
            context.commit('clearLoadingError');
            try {
                let startDate = moment(options.startDate).format('YYYY-MM-DD');
                let endDate = moment(options.endDate).format('YYYY-MM-DD');
                let srId = options.shadowRankId;
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/ShadowRankingHistory/ShadowRankingDatesBetween/${startDate}/${endDate}/ShadowRankId/${srId}`);
                let dates = result.data.map((d) => {
                    return moment(d).format('YYYY-MM-DD');
                });
                context.commit('setShadowRankingDates', dates);
                context.commit('stopLoadingShadowRankingDates');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('setLoadingError', error);
                context.commit('stopLoadingShadowRankingDates');
                return Promise.reject(error);
            }
        },
    },
};
