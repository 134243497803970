<template>
    <el-dialog :visible.sync="visibleSync" :close-on-click-modal="false" :title="this.title" width="65%" :show-close="false">
        <ErrorDisplay :requestError="this.requestError" @closed="clearError" />

        <el-collapse v-model="activeName" accordion>
            <el-collapse-item title="Direct Upload to Azure BLOB storage" name="1">
                <h4>Upload CSV Files directly to Azure BLOB Storage</h4>
                <div class="flex-row mt-32 mb-16">Step 1 - Select destination BLOB container on Azure BLOB Storage:</div>
                <div class="flex-row mt-0 mb-32">
                    <el-radio v-model="radio1" label="ssis">SSIS (Production)</el-radio>
                    <el-radio v-model="radio1" label="ssis-baseline-test">SSIS Baseline test (Development)</el-radio>
                </div>
                <div class="flex-row mt-32 mb-16">Step 2 - Select CSV bundle for direct upload:</div>
                <div class="flex-row mt-0 mb-32">
                    <el-button
                        :disabled="isBaselineReader"
                        @click="onDirectUploadAllWtaTourReports"
                        size="big"
                        plain
                        type="primary"
                        v-loading="this.loadingAllWtaTourReports"
                        >All WTA Tour Reports</el-button
                    >
                    <el-button
                        :disabled="isBaselineReader"
                        @click="onDirectUploadOtherWebCsvFiles"
                        size="big"
                        plain
                        type="primary"
                        v-loading="this.loadingOtherWebCsvFiles"
                        >Other Web CSV Files</el-button
                    >
                </div>
            </el-collapse-item>
            <el-collapse-item title="Manual Donwload and Upload of CSV Files" name="2">
                <div class="mt-32 mb-32">
                    <h4>Step 1a - Download weekly CSV Export: WTA Tour</h4>
                    <div class="flex-row mt-0 mb-0 wrap">
                        <el-button @click="onDownloadTournRequired" size="mini" plain type="primary" v-loading="this.loadingTournRequired"
                            >Tourn Required</el-button
                        >
                        <el-button @click="onDownloadBios" size="mini" plain type="primary" v-loading="this.loadingBios">Bios</el-button>
                        <el-button @click="onDownloadTournMast" size="mini" plain type="primary" v-loading="this.loadingTournMast">Tourn Mast</el-button>
                        <el-button @click="onDownloadCareerResults" size="mini" plain type="primary" v-loading="this.loadingCareerResults"
                            >Career Results</el-button
                        >
                        <el-button @click="onDownloadPlayers" size="mini" plain type="primary" v-loading="this.loadingPlayers">Players</el-button>
                        <el-button @click="onDownloadRankHistory" size="mini" plain type="primary" v-loading="this.loadingRankHistory">Rank History</el-button>
                        <el-button @click="onDownloadRankings" size="mini" plain type="primary" v-loading="this.loadingRankings">Rankings</el-button>
                        <el-button @click="onDownloadSinglesActivity" size="mini" plain type="primary" v-loading="this.loadingSinglesActivity"
                            >Singles Activty</el-button
                        >
                        <el-button @click="onDownloadDoublesActivity" size="mini" plain type="primary" v-loading="this.loadingDoublesActivity"
                            >Doubles Activiy</el-button
                        >
                        <el-button @click="onDownloadTeamChamps" size="mini" plain type="primary" v-loading="this.loadingTeamChamps">Team Champs</el-button>
                        <el-button @click="onDownloadTournPoints" size="mini" plain type="primary" v-loading="this.loadingTournPoints">Tourn Points</el-button>
                        <el-button @click="onDownloadYtdResults" size="mini" plain type="primary" v-loading="this.loadingYtdResults">YTD Results</el-button>
                        <el-button @click="onDownloadTournResults" size="mini" plain type="primary" v-loading="this.loadingTournResults"
                            >Tourn Results</el-button
                        >
                    </div>

                    <div class="flex-row mt-0 mb-32">
                        <el-button @click="onDownloadAllWtaTourReports" size="big" plain type="primary">All WTA Tour Reports</el-button>
                    </div>
                </div>

                <div class="mt-0 mb-32">
                    <h4>Step 1b - Download CSV Export after Grand Slams: YTD Titles</h4>
                    <div class="flex-row mt-0 mb-8 wrap">
                        <el-button @click="onDownloadTourChamps" v-loading="this.loadingTourChamps" size="mini" plain type="primary">Tour Champs</el-button>
                        <el-button @click="onDownloadGrandSlamChamps" v-loading="this.loadingGrandSlamChamps" size="mini" plain type="primary"
                            >Grand Slam Champs</el-button
                        >
                        <el-button @click="onDownloadGsWlRecord" v-loading="this.loadingGsWlRecord" size="mini" plain type="primary">GS WL Record</el-button>
                        <el-button @click="onDownloadDoublesTitlesSummary" v-loading="this.loadingDoublesTitlesSummary" size="mini" plain type="primary"
                            >Doubles Titles Summary</el-button
                        >
                        <el-button @click="onDownloadTournRoundsQ" v-loading="this.loadingTournRoundsQ" size="mini" plain type="primary"
                            >Tourn Rounds Q</el-button
                        >
                    </div>

                    <div class="flex-row mt-0 mb-32">
                        <el-button @click="onDownloadAllOtherReports" size="big" plain type="primary">Other Web CSV Files</el-button>
                    </div>

                    <div v-if="!isBaselineReader">
                        <div>
                            <h4>Step 2 - Upload CSV Files to Azure BLOB Storage</h4>
                            <div class="flex-row mt-8 mb-8">Select destination BLOB container on Azure BLOB Storage</div>
                            <div class="flex-row mt-0 mb-32">
                                <el-radio v-model="radio2" label="ssis">SSIS (Production)</el-radio>
                                <el-radio v-model="radio2" label="ssis-baseline-test">SSIS Baseline test (Development)</el-radio>
                            </div>
                            <div v-loading="uploading">
                                <el-upload
                                    class="mt-32"
                                    drag
                                    :multiple="true"
                                    :action="action"
                                    :show-file-list="true"
                                    :file-list="fileList"
                                    :auto-upload="true"
                                    :on-success="onUploadSuccess"
                                    :on-error="onUploadError"
                                    :before-upload="beforeUpload"
                                    :data="uploadData()"
                                >
                                    <i class="el-icon-upload"></i>
                                    <div class="el-upload__text">Drop .csv file here or <em>click to upload</em></div>
                                </el-upload>
                            </div>
                        </div>
                    </div>

                    <div class="dialog-footer">
                        <el-button @click="fileList = []">Clear List</el-button>
                    </div>
                </div>
            </el-collapse-item>
        </el-collapse>

        <div class="dialog-footer">
            <el-button @click="onClose">Close</el-button>
        </div>
    </el-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import FileSaver from 'file-saver';
import { isBaselineReader } from '../services/authGroups';

export default {
    props: ['visibleSync'],
    data() {
        return {
            requestError: null,
            fileList: [],
            uploading: false,
            action: `${process.env.VUE_APP_API_URL}/WtaTourCsvFiles/WtaTourCsvToBlobStorage`,
            requestError: null,
            title: 'WTA Tour CSV Files',
            activeName: '1',
            radio1: 'ssis',
            radio2: 'ssis',
            isBaselineReader: isBaselineReader(),
        };
    },
    mounted() {
        this.refresh();
    },
    computed: {
        ...mapGetters('csvExporter', [
            'loadingCsv',
            'loadingError',
            'loadingBios',
            'loadingTournMast',
            'loadingCareerResults',
            'loadingPlayers',
            'loadingRankHistory',
            'loadingRankings',
            'loadingSinglesActivity',
            'loadingDoublesActivity',
            'loadingTeamChamps',
            'loadingTournPoints',
            'loadingYtdResults',
            'loadingTournResults',
            'loadingTournRequired',
            'loadingTourChamps',
            'loadingGrandSlamChamps',
            'loadingGsWlRecord',
            'loadingDoublesTitlesSummary',
            'loadingTournRoundsQ',
            'loadingAllWtaTourReports',
            'loadingOtherWebCsvFiles',
        ]),
    },
    methods: {
        ...mapActions('csvExporter', [
            'downloadBios',
            'downloadCareerResults',
            'downloadDoublesActivity',
            'downloadPlayers',
            'downloadRankHistory',
            'downloadRankings',
            'downloadSinglesActivity',
            'downloadTeamChamps',
            'downloadTournMast',
            'downloadTournPoints',
            'downloadTournRequired',
            'downloadTournResults',
            'downloadYtdResults',
            'downloadTourChamps',
            'downloadGrandSlamChamps',
            'downloadGsWlRecord',
            'downloadDoublesTitlesSummary',
            'downloadTournRoundsQ',
            'directUploadAllWtaTourReports',
            'directUploadOtherWebCsvFiles',
        ]),
        refresh() {},

        onDirectUploadAllWtaTourReports() {
            this.directUpload('All WTA Tour Reports', this.directUploadAllWtaTourReports);
        },
        onDirectUploadOtherWebCsvFiles() {
            this.directUpload('Other Web CSV Files', this.directUploadOtherWebCsvFiles);
        },
        directUpload(bundle, fDownload) {
            this.$confirm('You are about to upload CSV file bundle "' + bundle + '" to Azure BLOB storage. Please confirm.', {
                confirmButtonText: 'Upload',
                cancelButtonText: 'Cancel',
                type: 'warning',
            })
                .then(() => {
                    let options = { container: this.radio1 };
                    fDownload(options)
                        .then((result) => {
                            this.$notify({
                                title: this.title + ': Direct upload successful',
                                message: bundle + ' uploaded',
                                type: 'success',
                                duration: 5000,
                            });
                        })
                        .catch((error) => {
                            this.requestError = error;
                            this.$notify({
                                title: this.title + ': Direct upload failed',
                                message: bundle + ': ' + error.response.data.Message,
                                type: 'error',
                                duration: 7000,
                            });
                        });
                })
                .catch(() => {});
        },

        onDownloadBios() {
            this.downloadAndSaveCsvFile(this.downloadBios, 'BIOS.csv');
        },
        onDownloadCareerResults() {
            this.downloadAndSaveCsvFile(this.downloadCareerResults, 'Career_Results.csv');
        },
        onDownloadDoublesActivity() {
            this.downloadAndSaveCsvFile(this.downloadDoublesActivity, 'Doubles_Activity.csv');
        },
        onDownloadPlayers() {
            this.downloadAndSaveCsvFile(this.downloadPlayers, 'Players.csv');
        },
        onDownloadRankHistory() {
            this.downloadAndSaveCsvFile(this.downloadRankHistory, 'Rank_History.csv');
        },
        onDownloadRankings() {
            this.downloadAndSaveCsvFile(this.downloadRankings, 'Rankings.csv');
        },
        onDownloadSinglesActivity() {
            this.downloadAndSaveCsvFile(this.downloadSinglesActivity, 'Singles_Activity.csv');
        },
        onDownloadTeamChamps() {
            this.downloadAndSaveCsvFile(this.downloadTeamChamps, 'TeamChamps.csv');
        },
        onDownloadTournMast() {
            this.downloadAndSaveCsvFile(this.downloadTournMast, 'Tourn_Mast.csv');
        },
        onDownloadTournPoints() {
            this.downloadAndSaveCsvFile(this.downloadTournPoints, 'Tourn_Points.csv');
        },
        onDownloadTournRequired() {
            this.downloadAndSaveCsvFile(this.downloadTournRequired, 'Tourn_Required.csv');
        },
        onDownloadTournResults() {
            this.downloadAndSaveCsvFile(this.downloadTournResults, 'Tourn_Results.csv');
        },
        onDownloadYtdResults() {
            this.downloadAndSaveCsvFile(this.downloadYtdResults, 'YTD_Results.csv');
        },
        onDownloadTourChamps() {
            this.downloadAndSaveCsvFile(this.downloadTourChamps, 'TourChamps.csv');
        },
        onDownloadGrandSlamChamps() {
            this.downloadAndSaveCsvFile(this.downloadGrandSlamChamps, 'GrandSlamChamps.csv');
        },
        onDownloadGsWlRecord() {
            this.downloadAndSaveCsvFile(this.downloadGsWlRecord, 'GS_WL_Record.csv');
        },
        onDownloadDoublesTitlesSummary() {
            this.downloadAndSaveCsvFile(this.downloadDoublesTitlesSummary, 'Doubles_Titles_Summary.csv');
        },
        onDownloadTournRoundsQ() {
            this.downloadAndSaveCsvFile(this.downloadTournRoundsQ, 'Tourn_Rounds_Q.csv');
        },

        onDownloadAllWtaTourReports() {
            this.onDownloadBios();
            this.onDownloadTournMast();
            this.onDownloadCareerResults();
            this.onDownloadPlayers();
            this.onDownloadRankHistory();
            this.onDownloadRankings();
            this.onDownloadSinglesActivity();
            this.onDownloadDoublesActivity();
            this.onDownloadTeamChamps();
            this.onDownloadTournPoints();
            this.onDownloadYtdResults();
            this.onDownloadTournResults();
            this.onDownloadTournRequired();
        },
        onDownloadAllOtherReports() {
            this.onDownloadTourChamps();
            this.onDownloadGrandSlamChamps();
            this.onDownloadGsWlRecord();
            this.onDownloadDoublesTitlesSummary();
            this.onDownloadTournRoundsQ();
        },
        onUploadSuccess(response, file, fileList) {
            this.$notify({
                title: this.title + ': CSV Upload successful',
                message: file.name + ' uploaded to Azure BLOB Storage',
                type: 'success',
                duration: 5000,
            });
        },
        onUploadError(error, file, fileList) {
            let parsed = JSON.parse(error.message);
            this.requestError = {
                response: {
                    data: parsed,
                },
            };
            this.$notify({
                title: this.title + ': CSV Upload failed',
                message: file.name + ': ' + parsed.Message,
                type: 'error',
                duration: 7000,
            });
        },
        beforeUpload(file) {
            if (
                file.name.match(/bios(\s*\(\d+\))?\.csv/i) ||
                file.name.match(/Career_Results(\s*\(\d+\))?\.csv/i) ||
                file.name.match(/Doubles_Activity(\s*\(\d+\))?\.csv/i) ||
                file.name.match(/Players(\s*\(\d+\))?\.csv/i) ||
                file.name.match(/Rank_History(\s*\(\d+\))?\.csv/i) ||
                file.name.match(/Rankings(\s*\(\d+\))?\.csv/i) ||
                file.name.match(/Singles_Activity(\s*\(\d+\))?\.csv/i) ||
                file.name.match(/TeamChamps(\s*\(\d+\))?\.csv/i) ||
                file.name.match(/Tourn_Mast(\s*\(\d+\))?\.csv/i) ||
                file.name.match(/Tourn_Points(\s*\(\d+\))?\.csv/i) ||
                file.name.match(/Tourn_Required(\s*\(\d+\))?\.csv/i) ||
                file.name.match(/Tourn_Results(\s*\(\d+\))?\.csv/i) ||
                file.name.match(/YTD_Results(\s*\(\d+\))?\.csv/i) ||
                file.name.match(/Tourn_Rounds_Q(\s*\(\d+\))?\.csv/i) ||
                file.name.match(/TourChamps(\s*\(\d+\))?\.csv/i) ||
                file.name.match(/GrandSlamChamps(\s*\(\d+\))?\.csv/i) ||
                file.name.match(/GS_WL_Record(\s*\(\d+\))?\.csv/i) ||
                file.name.match(/Doubles_Titles_Summary(\s*\(\d+\))?\.csv/i)
            )
                return true;
            this.$notify({
                title: this.title + ': CSV Download failed',
                message: file.name + ': File is not content of WTA CSV Export',
                type: 'error',
                duration: 7000,
            });
            return false;
        },
        uploadData() {
            let options = { container: this.radio2 };
            return options;
        },

        clearError() {
            this.requestError = null;
        },
        onClose() {
            this.$emit('close');
        },
        downloadAndSaveCsvFile(fdownload, filename, options = {}) {
            this.requestError = null;
            fdownload(options)
                .then((result) => {
                    const file = new File([result], filename);
                    FileSaver.saveAs(file);
                    this.$notify({
                        title: this.title + ': CSV Download successful',
                        message: filename + ' downloaded',
                        type: 'success',
                        duration: 5000,
                    });
                })
                .catch((error) => {
                    this.requestError = error;
                    this.$notify({
                        title: this.title + ': CSV Download failed',
                        message: filename + ': ' + error.response.data.Message,
                        type: 'error',
                        duration: 7000,
                    });
                });
        },
    },
};
</script>

<style>
.wrap {
    flex-wrap: wrap;
}
.wrap button {
    margin-bottom: 16px;
}
</style>
