import { checkAuthorizedGroups } from '../services/authGroups';

// store/modules/auth.js
export default {
    namespaced: true,

    state: {
        isAuthenticated: false,
        isLoading: true,
        authorized: false,
        account: {},
        profile: {},
        groups: [],
        error: null,
    },

    getters: {
        isAuthenticated(state) {
            return state.isAuthenticated;
        },
        authIsLoading(state) {
            return state.isLoading;
        },
        authorized(state) {
            return state.authorized;
        },
        errorMessage(state) {
            return state.error.code;
        },

        isError(state) {
            return state.error !== null;
        },
    },

    mutations: {
        setAuthentication(state, value) {
            state.isAuthenticated = value;
        },
        setAccount(state, accountData = {}) {
            state.account = accountData;
        },
        setProfile(state, data = {}) {
            state.profile = data;
        },
        setGroups(state, data = []) {
            state.groups = data;
        },
        setAuthorized(state, authorized = null) {
            state.authorized = authorized;
        },
        setError(state, error = null) {
            state.error = error;
        },
        setLoading(state, value) {
            state.isLoading = value;
        },
    },

    actions: {
        async login({ commit, dispatch, state }, payload) {
            commit('setLoading', true);
            commit('setAccount', payload);
            await dispatch('setGroups');

            commit('setLoading', false);
            commit('setAuthentication', state.error === null);
            return state.isAuthenticated;
        },

        logout({ commit, dispatch }, payload) {
            commit('setLoading', true);
            commit('setAuthentication', false);
            commit('setAuthorized', false);
            commit('setAccount', {});
            commit('setProfile', {});
            commit('setGroups', []);
            commit('setLoading', false);
        },

        async setGroups({ state, commit }) {
            const groupsResponse = await checkAuthorizedGroups();
            commit('setAuthorized', groupsResponse.authorized);
            commit('setGroups', groupsResponse.groups);
        },
    },
};
