var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('DialogAddItem',{attrs:{"visibleSync":_vm.addDialogVisible,"dtoName":"Player Ranking","selected":_vm.selected,"schema":_vm.schemaAdd,"layout":_vm.layoutAdd},on:{"save":_vm.saveChanges,"change":_vm.onChange,"close":function($event){_vm.addDialogVisible = false}}}),_c('DialogEditItem',{attrs:{"visibleSync":_vm.editDialogVisible,"dtoName":"Player Ranking","selected":_vm.selected,"schema":_vm.schema,"layout":_vm.layout},on:{"save":_vm.saveChanges,"change":_vm.onChange,"delete":_vm.deleteItem,"close":function($event){_vm.editDialogVisible = false}}}),_c('ErrorDisplay',{attrs:{"requestError":this.loadingError},on:{"closed":_vm.clearError}}),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loadingRanking),expression:"loadingRanking"}]},[_c('DataTable',{attrs:{"items":_vm.rankings.data,"schema":_vm.schema,"fields":[
                'rankDoubles',
                'priorRankDoubles',
                'playerId',
                'fullName',
                'nationality',
                'pointsDoubles',
                'tournamentCountDoubles',
                'nextOnDoubles',
                'nextOffDoubles',
                'points11ThDoubles',
                'points12ThDoubles',
                'highestDoublesRank',
                'highestDoublesRankDate',
            ],"autoSort":false},on:{"item-clicked":_vm.selectItem,"header-clicked":_vm.setSort}}),(_vm.rankings.data)?_c('Paginator',{attrs:{"data":_vm.rankings,"elementsPerPage":_vm.options.pageSize},on:{"prev-page":_vm.prevPage,"next-page":_vm.nextPage,"set-page-size":_vm.setPageSize}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }