import axios from 'axios';
import { Tournament } from '../models/tournament';
import { flatten } from 'flat';

export default {
    namespaced: true,

    state: {
        tournament: new Tournament(),
        loadingTournament: false,
        loadingError: null,
        savingTournament: false,
        savingError: null,
    },

    getters: {
        tournament: (state) => state.tournament,
        loadingTournament: (state) => state.loadingTournament,
        loadingError: (state) => state.loadingError,
        savingTournament: (state) => state.savingTournament,
        savingError: (state) => state.savingError,
    },

    mutations: {
        setTournament(state, data) {
            state.tournament = data;
        },

        startLoadingTournament(state) {
            state.loadingTournament = true;
        },

        stopLoadingTournament(state) {
            state.loadingTournament = false;
        },

        setLoadingError(state, error) {
            state.loadingError = error;
        },

        clearLoadingError(state) {
            state.loadingError = null;
        },

        startSavingTournament(state) {
            state.savingTournament = true;
        },

        stopSavingTournament(state) {
            state.savingTournament = false;
        },

        setSavingError(state, error) {
            state.savingError = error;
        },

        clearSavingError(state) {
            state.savingError = null;
        },
    },

    actions: {
        async findTournament(context, id) {
            context.commit('startLoadingTournament');
            try {
                const result = id ? await axios.get(`${process.env.VUE_APP_API_URL}/Edition/${id.year}/${id.tournamentId}`) : { data: {} };
                context.commit('stopLoadingTournament');
                context.commit('setTournament', new Tournament(flatten(result.data)));
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('stopLoadingTournament');
                context.commit('setLoadingError', error);
                return Promise.reject(error);
            }
        },

        async saveTournament(context) {
            context.commit('startSavingTournament');
            try {
                const result = await axios.post(`${process.env.VUE_APP_API_URL}/Edition`, context.state.tournament.data);
                context.commit('stopSavingTournament');
                //context.commit('setTournament', new Tournament(flatten(result.data)));
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('stopSavingTournament');
                context.commit('setSavingError', error);
                return Promise.reject(error);
            }
        },

        async deleteTournament(context, tournament) {
            context.commit('startLoadingTournament');
            return axios
                .delete(`${process.env.VUE_APP_API_URL}/Edition/${tournament.year}/${tournament.tournamentId}`)
                .then(() => {
                    context.commit('stopLoadingTournament');
                    return Promise.resolve();
                })
                .catch((error) => {
                    context.commit('stopLoadingTournament');
                    context.commit('setLoadingError', error);
                    return Promise.reject(error);
                });
        },
    },
};
