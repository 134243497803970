import axios from 'axios';
import { buildParams } from '@/utils/query-param-builder';

export default {
    namespaced: true,

    state: {
        shadowTeamRankings: {},
        loadingShadowTeamRanking: false,
        loadingErrorShadowTeamRanking: null,
    },

    getters: {
        shadowTeamRankings: (state) => state.shadowTeamRankings,
        loadingShadowTeamRanking: (state) => state.loadingShadowTeamRanking,
        loadingErrorShadowTeamRanking: (state) => state.loadingErrorShadowTeamRanking,
    },

    mutations: {
        setShadowTeamRankings(state, data) {
            state.shadowTeamRankings = data;
        },

        startLoadingShadowTeamRanking(state) {
            state.loadingShadowTeamRanking = true;
        },

        stopLoadingShadowTeamRanking(state) {
            state.loadingShadowTeamRanking = false;
        },

        setLoadingErrorShadowTeamRanking(state, error) {
            state.loadingErrorShadowTeamRanking = error;
        },

        clearLoadingErrorShadowTeamRanking(state) {
            state.loadingErrorShadowTeamRanking = null;
        },
    },

    actions: {
        async findShadowTeamRankings(context, options) {
            context.commit('startLoadingShadowTeamRanking');
            context.commit('clearLoadingErrorShadowTeamRanking');
            let params = buildParams(options);
            try {
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/ShadowRankingTeamPoint`, { params });
                context.commit('setShadowTeamRankings', result.data);
                context.commit('stopLoadingShadowTeamRanking');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('setLoadingErrorShadowTeamRanking', error);
                context.commit('stopLoadingShadowTeamRanking');
                return Promise.reject(error);
            }
        }
    },
};
