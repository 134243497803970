import { Model, isValid } from './model';

export class TournamentPrize extends Model {
    constructor(data) {
        if (data && !data.currency) {
            data.currency = 'USD';
        }
        super(data);
    }
    isValid() {
        return isValid(TournamentPrize.schemaAdd, this);
    }
    isValidForEdit() {
        return isValid(TournamentPrize.schema, this);
    }
    isValidForAdd() {
        return isValid(TournamentPrize.schema, this);
    }
}

TournamentPrize.layout = {
    children: [
        {
            children: ['prizeId', 'category', 'tableName', 'totalPrize', { type: 'spacer' }],
        },
        {
            children: ['prizeWin', 'prizeRun', 'prizeSemi', 'prizeQtr', { type: 'spacer' }],
        },
        {
            children: ['prize16', 'prize32', 'prize64', 'prize128', 'prize256'],
        },
    ],
};

TournamentPrize.schema = {
    fields: {
        prizeId: { type: 'string', displayName: 'Id', sortable: true, readonly: true, maxLength: 6 },
        category: { type: 'string', displayName: 'Category', sortable: true, maxLength: 2, clearable: true },
        tableName: { type: 'string', displayName: 'Table Name', sortable: true, maxLength: 40, clearable: true },
        totalPrize: { type: 'currency', displayName: 'Total Prize', sortable: true, min: 0, max: 99999999.99, clearable: true },
        prizeWin: { type: 'currency', displayName: 'Prize Win', min: 0, max: 99999999.99, clearable: true },
        prizeRun: { type: 'currency', displayName: 'Prize Run', min: 0, max: 99999999.99, clearable: true },
        prizeSemi: { type: 'currency', displayName: 'Prize Semi', min: 0, max: 99999999.99, clearable: true },
        prizeQtr: { type: 'currency', displayName: 'Prize Qtr', min: 0, max: 99999999.99, clearable: true },
        prize16: { type: 'currency', displayName: 'Prize 16', min: 0, max: 99999999.99, clearable: true },
        prize32: { type: 'currency', displayName: 'Prize 32', min: 0, max: 99999999.99, clearable: true },
        prize64: { type: 'currency', displayName: 'Prize 64', min: 0, max: 99999999.99, clearable: true },
        prize128: { type: 'currency', displayName: 'Prize 128', min: 0, max: 99999999.99, clearable: true },
        prize256: { type: 'currency', displayName: 'Prize 256', min: 0, max: 99999999.99, clearable: true },
        currency: { type: 'string', displayName: 'Currency', readonly: true },
        draw: { type: 'string', displayName: 'Draw', readonly: true, },
    },
};

TournamentPrize.schemaAdd = {
    fields: {
        prizeId: { type: 'string', displayName: 'Id', sortable: true, required: true, maxLength: 6 },
        category: { type: 'string', displayName: 'Category', sortable: true, maxLength: 2, clearable: true },
        tableName: { type: 'string', displayName: 'Table Name', sortable: true, maxLength: 40, clearable: true },
        totalPrize: { type: 'currency', displayName: 'Total Prize', sortable: true, min: 0, max: 99999999.99, clearable: true },
        prizeWin: { type: 'currency', displayName: 'Prize Win', min: 0, max: 99999999.99, clearable: true },
        prizeRun: { type: 'currency', displayName: 'Prize Run', min: 0, max: 99999999.99, clearable: true },
        prizeSemi: { type: 'currency', displayName: 'Prize Semi', min: 0, max: 99999999.99, clearable: true },
        prizeQtr: { type: 'currency', displayName: 'Prize Qtr', min: 0, max: 99999999.99, clearable: true },
        prize16: { type: 'currency', displayName: 'Prize 16', min: 0, max: 99999999.99, clearable: true },
        prize32: { type: 'currency', displayName: 'Prize 32', min: 0, max: 99999999.99, clearable: true },
        prize64: { type: 'currency', displayName: 'Prize 64', min: 0, max: 99999999.99, clearable: true },
        prize128: { type: 'currency', displayName: 'Prize 128', min: 0, max: 99999999.99, clearable: true },
        prize256: { type: 'currency', displayName: 'Prize 256', min: 0, max: 99999999.99, clearable: true },
        currency: { type: 'string', displayName: 'Currency', readonly: true },
    },
};
