import { Model, isValid } from './model';

export class TournamentPoint extends Model {
    isValid() {
        return isValid(TournamentPoint.schema, this);
    }
    isValidForEdit() {
        return isValid(TournamentPoint.schema, this);
    }
    isValidForAdd() {
        return isValid(TournamentPoint.schema, this);
    }
}

TournamentPoint.layout = {
    children: [
        {
            children: ['pointId', 'tableName', { type: 'spacer', width: 0.5 }],
        },
        {
            children: ['pointsWin', 'pointsRun', 'pointsSemi', 'pointsQtr', { type: 'spacer' }],
        },
        {
            children: ['points16', 'points32', 'points64', 'points128', 'points256'],
        },
    ],
};

TournamentPoint.schema = {
    fields: {
        pointId: { type: 'string', displayName: 'Id', sortable: true, readonly: true, maxLength: 6 },
        tableName: { type: 'string', displayName: 'Table Name', sortable: true, maxLength: 40, clearable: true },
        pointsWin: { type: 'number', displayName: 'Points Win', sortable: true, min: 0, max: 9999.9999, clearable: true },
        pointsRun: { type: 'number', displayName: 'Points Run', sortable: true, min: 0, max: 9999.9999, clearable: true },
        pointsSemi: { type: 'number', displayName: 'Points Semi', sortable: true, min: 0, max: 9999.9999, clearable: true },
        pointsQtr: { type: 'number', displayName: 'Points Qtr', sortable: true, min: 0, max: 9999.9999, clearable: true },
        points16: { type: 'number', displayName: 'Points 16', sortable: true, min: 0, max: 9999.9999, clearable: true },
        points32: { type: 'number', displayName: 'Points 32', sortable: true, min: 0, max: 9999.9999, clearable: true },
        points64: { type: 'number', displayName: 'Points 64', sortable: true, min: 0, max: 9999.9999, clearable: true },
        points128: { type: 'number', displayName: 'Points 128', sortable: true, min: 0, max: 9999.9999, clearable: true },
        points256: { type: 'number', displayName: 'Points 256', sortable: true, min: 0, max: 9999.9999, clearable: true },
        draw: { type: 'string', displayName: 'Draw', readonly: true, },
    },
};

TournamentPoint.schemaAdd = {
    fields: {
        pointId: { type: 'string', displayName: 'Id', sortable: true, required: true, maxLength: 6 },
        tableName: { type: 'string', displayName: 'Table Name', sortable: true, maxLength: 40, clearable: true },
        pointsWin: { type: 'number', displayName: 'Points Win', sortable: true, min: 0, max: 9999.9999, clearable: true },
        pointsRun: { type: 'number', displayName: 'Points Run', sortable: true, min: 0, max: 9999.9999, clearable: true },
        pointsSemi: { type: 'number', displayName: 'Points Semi', sortable: true, min: 0, max: 9999.9999, clearable: true },
        pointsQtr: { type: 'number', displayName: 'Points Qtr', sortable: true, min: 0, max: 9999.9999, clearable: true },
        points16: { type: 'number', displayName: 'Points 16', sortable: true, min: 0, max: 9999.9999, clearable: true },
        points32: { type: 'number', displayName: 'Points 32', sortable: true, min: 0, max: 9999.9999, clearable: true },
        points64: { type: 'number', displayName: 'Points 64', sortable: true, min: 0, max: 9999.9999, clearable: true },
        points128: { type: 'number', displayName: 'Points 128', sortable: true, min: 0, max: 9999.9999, clearable: true },
        points256: { type: 'number', displayName: 'Points 256', sortable: true, min: 0, max: 9999.9999, clearable: true },
    },
};
