<template>
    <div v-loading="loadingDoublesSummary">
        <ErrorDisplay :requestError="this.doublesSummaryError" @closed="clearError" />

        <div class="flex-row">
            <RankingSummaryPointTable :summary="this.doublesSummary" />
            <span class="spacer"></span>
            <RankingSummaryTieBreakingPoints :summary="this.doublesSummary" />
            <span class="spacer"></span>
            <span class="spacer"></span>
        </div>

        <DataTable
            v-if="doublesSummary"
            :items="doublesSummary.allResults"
            :schema="schemaTournResult"
            :fields="this.dataFields"
            :autoSort="false"
        />
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { TournamentResult } from '../../models/tournament-result';

export default {
    props: ['dataFields'],
    data() {
        return {
            schemaTournResult: TournamentResult.schema,
        };
    },
    mounted() {},
    computed: {
        ...mapGetters('rankingProcedure', ['doublesSummary', 'loadingDoublesSummary', 'doublesSummaryError']),
    },
    methods: {
        clearError() {
            this.doublesSummaryError = null;
        },
    },
};
</script>

<style scoped></style>
