<template>
  <div>
    <TournamentResultsForPlayerDevelopment :player="selected" :visibleSync="visibleSync" @close="onItemClose"/>

    <div class="flex-row mt-16 mb-16">
      <h3>Player Development Eligibility Dates up to {{ this.formattedRankDate }}</h3>
      <span class="spacer"></span>

      <SelectNationality
          v-model="options.nationality"
          :lookup="nationalities"
          :clearable="true"
          @select="resetPage"
          @keydown.enter.native="resetPage"
      />

      <el-input
          placeholder="Filter by Id or Name"
          v-model="options.queryString"
          class="input-search mr-16"
          @change="resetPage"
          @keydown.esc.native="reset"
          @keydown.enter.native="resetPage"
      >
        <el-button slot="append" icon="el-icon-search" @click="resetPage"></el-button>
      </el-input>
      <span class="spacer"></span>
      <!--
      <el-button plain type="primary" @click="onComputeDates" v-loading="updatingEligibilityDates">Compute Eligibility Dates</el-button>
      <el-button plain type="primary" @click="onUpdateDates" v-loading="updatingEligibilityDates">Update Eligibility Dates</el-button>
      -->
      <el-button plain type="primary" @click="onExportCsv" v-loading="downloadingEligibilityDates">Export CSV
      </el-button>
    </div>
    <el-alert type="info" :closable="false" :show-icon="true">
      Click on a player in table below to open an overlay where you can select which tournament results to consider for
      calculating Intro and Rookie
      Eligibility Dates.
    </el-alert>
    <DataTable
        v-if="!loadingDatesError"
        v-loading="loadingEligibilityDates"
        :items="eligibilityDates.data"
        :fields="[
                'id',
                'fullName',
                'playerAge',
                'dateOfBirth',
                'nationality',
                'ph1EligibleDateComputed',
                'ph1EligibleDate',
                'ph2EligibleDateComputed',
                'ph2EligibleDate',
                'ph3EligibleDateComputed',
                'ph3EligibleDate',
                'ph4EligibleDateComputed',
                'ph4EligibleDate',
            ]"
        :schema="schema"
        :autoSort="false"
        @header-clicked="setSort"
        @item-clicked="onItemClicked"
    />

    <Paginator
        v-if="!loadingDatesError"
        :data="eligibilityDates"
        :elementsPerPage="options.pageSize"
        @prev-page="prevPage"
        @next-page="nextPage"
        @set-page-size="setPageSize"
    />
  </div>
</template>


<script>
import {mapGetters, mapActions} from 'vuex';
import {Player} from '@/models/player';
import {retrieveDtos} from '@/utils/crud-confirm';
import moment from 'moment';
import FileSaver from 'file-saver';
import {icoCountries} from "@/utils/country-codes";

export default {
  props: ['playerId'],
  data() {
    return {
      schema: Player.schema,
      layout: Player.layout,
      nationalities: Object.keys(icoCountries),
      options: {
        queryString: '',
        pageSize: 20,
        sortBy: null,
        sortDir: 'ascending',
        page: 0,
        nationality: '',
        ageAt: null,
      },
      visibleSync: false,
      selected: null,
    };
  },
  mounted() {
    this.refresh();
  },
  computed: {
    ...mapGetters('playerDevelopments', [
      'eligibilityDates',
      'loadingEligibilityDates',
      'loadingDatesError',
      'downloadingEligibilityDates',
      'updatingEligibilityDates',
    ]),
    ...mapGetters('wtaSystem', ['wtaSystem', 'loadingWtaSystem', 'loadingWtaSystemError']),
    formattedRankDate() {
      return moment(this.wtaSystem.data.currentRankDate).format('YYYY-MM-DD');
    },
  },
  methods: {
    ...mapActions('playerDevelopments', ['findEligibilityDates', 'downloadEligibilityDatesCsv', 'updateEligibilityDates', 'updateEligibilityDatesDryRun']),
    ...mapActions('wtaSystem', ['retrieveWtaSystem']),
    refresh() {
      this.search();
      this.retrieveWtaSystem();
    },
    search() {
      let params = {
        self: this,
        fRetrieve: this.findEligibilityDates,
        options: this.options,
        dtosName: 'Eligibility Dates',
      };
      retrieveDtos(params);
    },
    prevPage() {
      this.options.page--;
      this.search();
    },
    nextPage() {
      this.options.page++;
      this.search();
    },
    setPageSize(size) {
      this.options.pageSize = size;
      this.search();
    },
    resetPage() {
      this.options.page = 0;
      this.search();
    },
    reset() {
      this.options.queryString = null;
      this.options.page = 0;
      this.search();
    },
    setSort(event) {
      this.options.sortBy = event.property;
      this.options.sortDir = event.order;
      this.search();
    },
    onItemClicked(row, index) {
      this.selected = new Player(row);
      this.visibleSync = true;
    },
    onItemClose(event) {
      this.visibleSync = false;
      if (event.itemClicked) {
        this.onComputeDates();
      }
    },
    onComputeDates() {
      this.updateEligibilityDatesDryRun().then(() => {
        this.refresh();
      });
    },
    onUpdateDates() {
      this.updateEligibilityDates().then(() => {
        this.refresh();
      });
    },
    onExportCsv() {
      this.downloadAndSaveCsvFile(this.downloadEligibilityDatesCsv, `PD_Eligibility_Dates_${this.formattedRankDate}.csv`, this.options);
    },
    downloadAndSaveCsvFile(fdownload, filename, options = {}) {
      fdownload(options)
          .then((result) => {
            const file = new File([result], filename, {type: 'text/csv'});
            FileSaver.saveAs(file);
            this.$notify({
              title: 'PD Eligibility Dates: CSV Download successful',
              message: filename + ' downloaded',
              type: 'success',
              duration: 5000,
            });
          })
          .catch((error) => {
            this.requestError = error;
            this.$notify({
              title: 'PD Eligibility Dates: CSV Download failed',
              message: filename + ': ' + error,
              type: 'error',
              duration: 7000,
            });
          });
    },
  },
};
</script>

<style></style>
