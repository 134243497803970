import { isValid, Model } from './model';
import { icoCountries } from '../utils/country-codes';

export class MixedDouble extends Model {
    isValid() {
        return isValid(MixedDouble.schema, this);
    }
    isValidForAdd() {
        return isValid(MixedDouble.schemaAdd, this);
    }
    isValidForEdit() {
        return isValid(MixedDouble.schema, this);
    }
}

MixedDouble.layout = {
    children: [
        {
            children: ['playerId', 'fullName', 'nationality'],
        },
        {
            children: ['partnerName', 'prizeMoneyMixed', {type: 'spacer'}],
        },
    ],
};

MixedDouble.layoutAdd = {
    children: [
        {
            children: ['playerId', 'partnerName', 'prizeMoneyMixed'],
        },
    ],
};

MixedDouble.schema = {
    fields: {
        playerId: { displayName: 'Player Nbr', type: 'player', readonly: true },
        fullName: { displayName: 'Name', type: 'string', readonly: true },
        nationality: { displayName: 'Nationality', type: 'nationality', readonly: true ,lookup: Object.keys(icoCountries)},
        tournamentId: { displayName: 'Tourn Nbr', type: 'number', readonly: true },
        tournamentYear: { displayName: 'Tourn Year', type: 'number', readonly: true },
        partnerName: { displayName: 'Partner', type: 'string' },
        prizeMoneyMixed: { displayName: 'Prize Money', type: 'currency' },
        currency: { type: 'string', displayName: 'Currency', readonly: true, },
    },
};

MixedDouble.schemaAdd = {
    fields: {
        playerId: { displayName: 'Player Nbr', type: 'player' },
        tournamentId: { displayName: 'Tourn Nbr', type: 'number', readonly: true },
        tournamentYear: { displayName: 'Tourn Year', type: 'number', readonly: true },
        partnerName: { displayName: 'Partner', type: 'string' },
        prizeMoneyMixed: { displayName: 'Prize Money', type: 'currency' },
        currency: { type: 'string', displayName: 'Currency', readonly: true, },
    },
};