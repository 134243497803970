import axios from 'axios';
import * as moment from "moment/moment";

export default {
    namespaced: true,

    state: {
        shadowRankings: [],
        loadingShadowRankings: false,
        loadingErrorShadowRankings: null,
        runningShadowRankingProcedure: false,
        creatingShadowTournamentResults: false,
        creatingErrorShadowTournamentResults: null,
    },

    getters: {
        shadowRankings: (state) => state.shadowRankings,
        loadingShadowRankings: (state) => state.loadingShadowRankings,
        loadingErrorShadowRankings: (state) => state.loadingErrorShadowRankings,
        runningShadowRankingProcedure: (state) => state.runningShadowRankingProcedure,
        creatingShadowTournamentResults: (state) => state.creatingShadowTournamentResults,
        creatingErrorShadowTournamentResults: (state) => state.creatingErrorShadowTournamentResults,
    },

    mutations: {
        setShadowRankings(state, data) {
            state.shadowRankings = data
        },

        startLoadingShadowRankings(state) {
            state.loadingShadowRankings = true
        },

        stopLoadingShadowRankings(state) {
            state.loadingShadowRankings = false
        },

        setLoadingError(state, error) {
            state.loadingErrorShadowRankings = error
        },

        clearLoadingError(state) {
            state.loadingErrorShadowRankings = null;
        },

        startRunningShadowRankingProcedure(state) {
            state.runningShadowRankingProcedure = true
        },

        stopRunningShadowRankingProcedure(state) {
            state.runningShadowRankingProcedure = false
        },

        startCreatingShadowTournamentResults(state) {
            state.creatingShadowTournamentResults = true;
        },

        stopCreatingShadowTournamentResults(state) {
            state.creatingShadowTournamentResults = false;
        },

        setCreatingErrorShadowTournamentResults(state, error) {
            state.creatingErrorShadowTournamentResults = error
        },

        clearCreatingErrorShadowTournamentResults(state) {
            state.creatingErrorShadowTournamentResults = null;
        },

    },

    actions: {
        async getAllShadowRankings(context) {
            context.commit('startLoadingShadowRankings');
            context.commit('clearLoadingError');
            try {
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/ShadowRanking`);
                context.commit('setShadowRankings', result.data);
                context.commit('stopLoadingShadowRankings');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('setLoadingError', error);
                context.commit('stopLoadingShadowRankings');
                return Promise.reject(error);
            }
        },

        async createShadowRanking(context, data) {
            context.commit('startLoadingShadowRankings');
            context.commit('clearLoadingError');
            try {
                const result = await axios.post(`${process.env.VUE_APP_API_URL}/ShadowRanking`, data.data);
                context.commit('stopLoadingShadowRankings');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('setLoadingError', error);
                context.commit('stopLoadingShadowRankings');
                return Promise.reject(error);
            }
        },

        async updateShadowRanking(context, data) {
            context.commit('startLoadingShadowRankings');
            context.commit('clearLoadingError');
            try {
                const result = await axios.put(`${process.env.VUE_APP_API_URL}/ShadowRanking`, data.data);
                context.commit('stopLoadingShadowRankings');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('setLoadingError', error);
                context.commit('stopLoadingShadowRankings');
                return Promise.reject(error);
            }
        },

        async deleteShadowRanking(context, data) {
            context.commit('startLoadingShadowRankings');
            context.commit('clearLoadingError');
            try {
                const result = await axios.delete(`${process.env.VUE_APP_API_URL}/ShadowRanking/${data.data.shadowRankId}`);
                context.commit('stopLoadingShadowRankings');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('setLoadingError', error);
                context.commit('stopLoadingShadowRankings');
                return Promise.reject(error);
            }
        },

        async createShadowRankingTournamentResults(context, options) {
            let fromDate = moment(options.fromDate).format('YYYY-MM-DD');
            let toDate = moment(options.toDate).format('YYYY-MM-DD');
            context.commit('startCreatingShadowTournamentResults');
            context.commit('clearCreatingErrorShadowTournamentResults');
            try {
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/ShadowRankingTournamentResult/CreateResults/From/${fromDate}/To/${toDate}`);
                context.commit('stopCreatingShadowTournamentResults');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('setCreatingErrorShadowTournamentResults', error);
                context.commit('stopCreatingShadowTournamentResults');
                return Promise.reject(error);
            }
        },

        async runShadowRankingProcedure(context, data) {
            let rankingDate = moment(data.rankingDate).format('YYYY-MM-DD');
            let nextRankingDate = moment(data.nextRankingDate).format('YYYY-MM-DD');
            context.commit('startRunningShadowRankingProcedure');
            context.commit('clearLoadingError');
            try {
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/ShadowRanking/RankingProcedure/Run/Date/${rankingDate}/NextDate/${nextRankingDate}`);
                context.commit('stopRunningShadowRankingProcedure');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('setLoadingError', error);
                context.commit('stopRunningShadowRankingProcedure');
                return Promise.reject(error);
            }
        }
    }
}