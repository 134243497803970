import { callApi } from './authService';

async function callMSGraph(endpoint) {
    const accessToken = localStorage.getItem('accessToken');
    if (!accessToken) {
        throw new Error('No access token found in localStorage');
    }

    const graphEndpoint = `https://graph.microsoft.com/v1.0/${endpoint}`;
    return await callApi(graphEndpoint, accessToken);
}

export async function checkAuthorizedGroups() {
    try {
        const groupsJson = await callMSGraph('me/memberOf');
        const groupsArray = groupsJson.value || [];
        const assignedGroups = groupsArray.map(({ displayName, id, description }) => ({ displayName, id, description }));
        localStorage.setItem('assignedGroups', JSON.stringify(assignedGroups));

        const allowedGroups = process.env.VUE_APP_ACCESS_GROUPS.split(',');
        const isUserInAllowedGroup = groupsArray.some((userGroup) => allowedGroups.includes(userGroup.displayName));

        localStorage.setItem('authorized', isUserInAllowedGroup ? 'true' : 'false');
        return { authorized: isUserInAllowedGroup, groups: assignedGroups };
    } catch (error) {
        console.error('Error acquiring token silently', error);
    }
}

export function isBaselineReader() {
    var groups = localStorage.getItem('assignedGroups');

    if (!groups) {
        return false;
    }

    try {
        groups = JSON.parse(groups);
    } catch (e) {
        console.error('Error parsing assignedGroups:', e);
        return false;
    }

    for (let group of groups) {
        if (group.displayName === 'Baseline_Reader') {
            return true;
        }
    }

    return false;
}
