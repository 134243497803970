import axios from 'axios';
import Vue from 'vue';

export default {
    namespaced: true,

    state: {
        loadingShadowSinglesNumeric: {},
        loadingShadowDoublesNumeric: {},
        loadingShadowChampionshipLeaders: {},
        loadingError: null,
    },

    getters: {
        loadingShadowSinglesNumeric: (state) => state.loadingShadowSinglesNumeric,
        loadingShadowDoublesNumeric: (state) => state.loadingShadowDoublesNumeric,
        loadingShadowChampionshipLeaders: (state) => state.loadingShadowChampionshipLeaders,
        loadingError: (state) => state.loadingError,
    },

    mutations: {
        startLoadingShadowSinglesNumericPdf(state, srId) {
            Vue.set(state.loadingShadowSinglesNumeric, srId.toString(), true);
        },
        stopLoadingShadowSinglesNumericPdf(state, srId) {
            Vue.delete(state.loadingShadowSinglesNumeric, srId.toString());
        },

        startLoadingShadowDoublesNumericPdf(state, srId) {
            Vue.set(state.loadingShadowDoublesNumeric, srId.toString(), true);
        },
        stopLoadingShadowDoublesNumericPdf(state, srId) {
            Vue.delete(state.loadingShadowDoublesNumeric, srId.toString());
        },

        startLoadingShadowChampionshipLeadersPdf(state, srId) {
            Vue.set(state.loadingShadowChampionshipLeaders, srId.toString(), true);
        },
        stopLoadingShadowChampionshipLeadersPdf(state, srId) {
            Vue.delete(state.loadingShadowChampionshipLeaders, srId.toString());
        },

        setLoadingError(state, error) {
            state.loadingError = error;
        },

        clearLoadingError(state) {
            state.loadingError = null;
        },
    },

    actions: {
        async getShadowSinglesNumeric(context, options) {
            let srId = options.shadowRankId;
            context.commit('startLoadingShadowSinglesNumericPdf', srId);
            try {
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/ShadowRanking/${srId}/PdfReports/ShadowSinglesNumeric`, {
                    responseType: 'blob',
                });
                context.commit('clearLoadingError');
                context.commit('stopLoadingShadowSinglesNumericPdf', srId);
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('setLoadingError', error);
                context.commit('stopLoadingShadowSinglesNumericPdf', srId);
                return Promise.reject(error);
            }
        },
        async getShadowDoublesNumeric(context, options) {
            let srId = options.shadowRankId;
            context.commit('startLoadingShadowDoublesNumericPdf', srId);
            try {
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/ShadowRanking/${srId}/PdfReports/ShadowDoublesNumeric`, {
                    responseType: 'blob',
                });
                context.commit('clearLoadingError');
                context.commit('stopLoadingShadowDoublesNumericPdf', srId);
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('setLoadingError', error);
                context.commit('stopLoadingShadowDoublesNumericPdf', srId);
                return Promise.reject(error);
            }
        },
        async getShadowChampionshipLeaders(context, options) {
            let srId = options.shadowRankId;
            context.commit('startLoadingShadowChampionshipLeadersPdf', srId);
            try {
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/ShadowRanking/${srId}/PdfReports/ShadowChampionshipLeaders`, {
                    responseType: 'blob',
                });
                context.commit('clearLoadingError');
                context.commit('stopLoadingShadowChampionshipLeadersPdf', srId);
                return Promise.resolve(result.data);
            } catch (error) {
                const text = await error.response.data.text();
                const jsonError = JSON.parse(text);
                context.commit('setLoadingError', jsonError);
                context.commit('stopLoadingShadowChampionshipLeadersPdf', srId);
                return Promise.reject(error);
            }
        },
    },
};
