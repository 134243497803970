import { Model, isValid } from './model';

export class SysCode extends Model {
    isValid() {
        return isValid(SysCode.schema, this);
    }
}

SysCode.layout = {
    children: [
        {
            children: ['tableName', 'code', 'status', 'ref', { type: 'spacer' }],
        },
        {
            children: ['crsCode1', 'crsCode2', 'crsCode3', 'crsCode4', 'crsCode5'],
        },
        {
            children: ['description'],
        },
    ],
};

SysCode.schema = {
    fields: {
        tableName: { type: 'string', displayName: 'Table', sortable: true, required: true },
        code: { type: 'string', displayName: 'Code', sortable: true, required: true },
        status: { type: 'string', displayName: 'Status' },
        ref: { type: 'string', displayName: 'Reference', sortable: true },
        description: { type: 'multiline', displayName: 'Description', sortable: true },
        crsCode1: { type: 'string', displayName: 'Crs code 1' },
        crsCode2: { type: 'string', displayName: 'Crs code 2' },
        crsCode3: { type: 'string', displayName: 'Crs code 3' },
        crsCode4: { type: 'string', displayName: 'Crs code 4' },
        crsCode5: { type: 'string', displayName: 'Crs code 5' },
    },
};
