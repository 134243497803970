import { render, staticRenderFns } from "./PlayerDevelopment.vue?vue&type=template&id=3400007b&scoped=true"
import script from "./PlayerDevelopment.vue?vue&type=script&lang=js"
export * from "./PlayerDevelopment.vue?vue&type=script&lang=js"
import style0 from "./PlayerDevelopment.vue?vue&type=style&index=0&id=3400007b&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3400007b",
  null
  
)

export default component.exports