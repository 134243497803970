import axios from 'axios';

export default {
    namespaced: true,

    state: {
        loadingCsv: false,
        loadingError: null,
        loadingBios: false,
        loadingTournMast: false,
        loadingCareerResults: false,
        loadingPlayers: false,
        loadingRankHistory: false,
        loadingRankings: false,
        loadingSinglesActivity: false,
        loadingDoublesActivity: false,
        loadingTeamChamps: false,
        loadingTournPoints: false,
        loadingYtdResults: false,
        loadingTournResults: false,
        loadingTournRequired: false,
        loadingTourChamps: false,
        loadingGrandSlamChamps: false,
        loadingGsWlRecord: false,
        loadingDoublesTitlesSummary: false,
        loadingTournRoundsQ: false,
        loadingAllWtaTourReports: false,
        loadingOtherWebCsvFiles: false,

    },

    getters: {
        loadingCsv: (state) => state.loadingCsv,
        loadingError: (state) => state.loadingError,
        loadingBios: (state) => state.loadingBios,
        loadingTournMast: (state) => state.loadingTournMast,
        loadingCareerResults: (state) => state.loadingCareerResults,
        loadingPlayers: (state) => state.loadingPlayers,
        loadingRankHistory: (state) => state.loadingRankHistory,
        loadingRankings: (state) => state.loadingRankings,
        loadingSinglesActivity: (state) => state.loadingSinglesActivity,
        loadingDoublesActivity: (state) => state.loadingDoublesActivity,
        loadingTeamChamps: (state) => state.loadingTeamChamps,
        loadingTournPoints: (state) => state.loadingTournPoints,
        loadingYtdResults: (state) => state.loadingYtdResults,
        loadingTournResults: (state) => state.loadingTournResults,
        loadingTournRequired: (state) => state.loadingTournRequired,
        loadingTourChamps: (state) => state.loadingTourChamps,
        loadingGrandSlamChamps: (state) => state.loadingGrandSlamChamps,
        loadingGsWlRecord: (state) => state.loadingGsWlRecord,
        loadingDoublesTitlesSummary: (state) => state.loadingDoublesTitlesSummary,
        loadingTournRoundsQ: (state) => state.loadingTournRoundsQ,
        loadingAllWtaTourReports: (state) => state.loadingAllWtaTourReports,
        loadingOtherWebCsvFiles: (state) => state.loadingOtherWebCsvFiles,
    },

    mutations: {

        startLoadingCsv(state) {
            state.loadingCsv = true;
        },

        stopLoadingCsv(state) {
            state.loadingCsv = false;
        },

        setLoadingError(state, error) {
            state.loadingError = error;
        },

        clearLoadingError(state) {
            state.loadingError = null;
        },
        setLoadingBios(state, bool) {
            state.loadingBios = bool;
        },
        setLoadingTournMast(state, bool) {
            state.loadingTournMast = bool;
        },
        setLoadingCareerResults(state, bool) {
            state.loadingCareerResults = bool;
        },
        setLoadingPlayers(state, bool) {
            state.loadingPlayers = bool;
        },
        setLoadingRankHistory(state, bool) {
            state.loadingRankHistory = bool;
        },
        setLoadingRankings(state, bool) {
            state.loadingRankings = bool;
        },
        setLoadingSinglesActivity(state, bool) {
            state.loadingSinglesActivity = bool;
        },
        setLoadingDoublesActivity(state, bool) {
            state.loadingDoublesActivity = bool;
        },
        setLoadingTeamChamps(state, bool) {
            state.loadingTeamChamps = bool;
        },
        setLoadingTournPoints(state, bool) {
            state.loadingTournPoints = bool;
        },
        setLoadingYtdResults(state, bool) {
            state.loadingYtdResults = bool;
        },
        setLoadingTournResults(state, bool) {
            state.loadingTournResults = bool;
        },
        setLoadingTournRequired(state, bool) {
            state.loadingTournRequired = bool;
        },
        setLoadingTourChamps(state, bool) {
            state.loadingTourChamps = bool;
        },
        setLoadingGrandSlamChamps(state, bool) {
            state.loadingGrandSlamChamps = bool;
        },
        setLoadingGsWlRecord(state, bool) {
            state.loadingGsWlRecord = bool;
        },
        setLoadingDoublesTitlesSummary(state, bool) {
            state.loadingDoublesTitlesSummary = bool;
        },
        setLoadingTournRoundsQ(state, bool) {
            state.loadingTournRoundsQ = bool;
        },
        setLoadingAllWtaTourReports(state, bool) {
            state.loadingAllWtaTourReports = bool;
        },
        setLoadingOtherWebCsvFiles(state, bool) {
            state.loadingOtherWebCsvFiles = bool;
        },
    },

    actions: {
        async downloadBios(context, options = {}) {
            context.commit('setLoadingBios', true);
            try {
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/WtaTourCsvFiles/Bios`, { responseType: 'blob' });
                context.commit('setLoadingBios', false);
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('setLoadingBios', false);
                return Promise.reject(error);
            }
        },
        async downloadCareerResults(context, options = {}) {
            context.commit('setLoadingCareerResults', true);
            try {
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/WtaTourCsvFiles/CareerResults`, { responseType: 'blob' });
                context.commit('setLoadingCareerResults', false);
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('setLoadingCareerResults', false);
                return Promise.reject(error);
            }
        },
        async downloadDoublesActivity(context, options = {}) {
            context.commit('setLoadingDoublesActivity', true);
            try {
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/WtaTourCsvFiles/DoublesActivity`, { responseType: 'blob' });
                context.commit('setLoadingDoublesActivity', false);
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('setLoadingDoublesActivity', false);
                return Promise.reject(error);
            }
        },
        async downloadPlayers(context, options = {}) {
            context.commit('setLoadingPlayers', true);
            try {
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/WtaTourCsvFiles/Players`, { responseType: 'blob' });
                context.commit('setLoadingPlayers', false);
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('setLoadingPlayers', false);
                return Promise.reject(error);
            }
        },
        async downloadRankHistory(context, options = {}) {
            context.commit('setLoadingRankHistory', true);
            try {
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/WtaTourCsvFiles/RankHistory`, { responseType: 'blob' });
                context.commit('setLoadingRankHistory', false);
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('setLoadingRankHistory', false);
                return Promise.reject(error);
            }
        },
        async downloadRankings(context, options = {}) {
            context.commit('setLoadingRankings', true);
            try {
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/WtaTourCsvFiles/Rankings`, { responseType: 'blob' });
                context.commit('setLoadingRankings', false);
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('v', false);
                return Promise.reject(error);
            }
        },
        async downloadSinglesActivity(context, options = {}) {
            context.commit('setLoadingSinglesActivity', true);
            try {
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/WtaTourCsvFiles/SinglesActivity`, { responseType: 'blob' });
                context.commit('setLoadingSinglesActivity', false);
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('setLoadingSinglesActivity', false);
                return Promise.reject(error);
            }
        },
        async downloadTeamChamps(context, options = {}) {
            context.commit('setLoadingTeamChamps', true);
            try {
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/WtaTourCsvFiles/TeamChamps`, { responseType: 'blob' });
                context.commit('setLoadingTeamChamps', false);
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('setLoadingTeamChamps', false);
                return Promise.reject(error);
            }
        },
        async downloadTournMast(context, options = {}) {
            context.commit('setLoadingTournMast', true);
            try {
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/WtaTourCsvFiles/TournMast`, { responseType: 'blob' });
                context.commit('setLoadingTournMast', false);
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('setLoadingTournMast', false);
                return Promise.reject(error);
            }
        },
        async downloadTournPoints(context, options = {}) {
            context.commit('setLoadingTournPoints', true);
            try {
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/WtaTourCsvFiles/TournPoints`, { responseType: 'blob' });
                context.commit('setLoadingTournPoints', false);
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('setLoadingTournPoints', false);
                return Promise.reject(error);
            }
        },
        async downloadTournRequired(context, options = {}) {
            context.commit('setLoadingTournRequired', true);
            try {
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/WtaTourCsvFiles/TournRequired`, { responseType: 'blob' });
                context.commit('setLoadingTournRequired', false);
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('setLoadingTournRequired', false);
                return Promise.reject(error);
            }
        },
        async downloadTournResults(context, options = {}) {
            context.commit('setLoadingTournResults', true);
            try {
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/WtaTourCsvFiles/TournResults`, { responseType: 'blob' });
                context.commit('setLoadingTournResults', false);
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('setLoadingTournResults', false);
                return Promise.reject(error);
            }
        },
        async downloadYtdResults(context, options = {}) {
            context.commit('setLoadingYtdResults', true);
            try {
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/WtaTourCsvFiles/YtdResults`, { responseType: 'blob' });
                context.commit('setLoadingYtdResults', false);
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('setLoadingYtdResults', false);
                return Promise.reject(error);
            }
        },


        async downloadTourChamps(context, options = {}) {
            context.commit('setLoadingTourChamps', true);
            try {
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/WtaTourCsvFiles/TourChamps`, { responseType: 'blob' });
                context.commit('setLoadingTourChamps', false);
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('setLoadingTourChamps', false);
                return Promise.reject(error);
            }
        },
        async downloadGrandSlamChamps(context, options = {}) {
            context.commit('setLoadingGrandSlamChamps', true);
            try {
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/WtaTourCsvFiles/GrandSlamChamps`, { responseType: 'blob' });
                context.commit('setLoadingGrandSlamChamps', false);
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('setLoadingGrandSlamChamps', false);
                return Promise.reject(error);
            }
        },
        async downloadGsWlRecord(context, options = {}) {
            context.commit('setLoadingGsWlRecord', true);
            try {
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/WtaTourCsvFiles/GsWlRecord`, { responseType: 'blob' });
                context.commit('setLoadingGsWlRecord', false);
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('setLoadingGsWlRecord', false);
                return Promise.reject(error);
            }
        },
        async downloadDoublesTitlesSummary(context, options = {}) {
            context.commit('setLoadingDoublesTitlesSummary', true);
            try {
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/WtaTourCsvFiles/DoublesTitlesSummary`, { responseType: 'blob' });
                context.commit('setLoadingDoublesTitlesSummary', false);
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('setLoadingDoublesTitlesSummary', false);
                return Promise.reject(error);
            }
        },
        async downloadTournRoundsQ(context, options = {}) {
            context.commit('setLoadingTournRoundsQ', true);
            try {
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/WtaTourCsvFiles/TournRoundsQ`, { responseType: 'blob' });
                context.commit('setLoadingTournRoundsQ', false);
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('setLoadingTournRoundsQ', false);
                return Promise.reject(error);
            }
        },
        async directUploadAllWtaTourReports(context, options = {}) {
            if (!options.container) return;
            context.commit('setLoadingAllWtaTourReports', true);
            try {
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/WtaTourCsvFiles/DirectUploadAllWtaTourReports`, { params: options });
                context.commit('setLoadingAllWtaTourReports', false);
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('setLoadingAllWtaTourReports', false);
                return Promise.reject(error);
            }
        },
        async directUploadOtherWebCsvFiles(context, options = {}) {
            if (!options.container) return;
            let container = options.container;
            context.commit('setLoadingOtherWebCsvFiles', true);
            try {
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/WtaTourCsvFiles/DirectUploadOtherWebCsvFiles`, { params: options });
                context.commit('setLoadingOtherWebCsvFiles', false);
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('setLoadingOtherWebCsvFiles', false);
                return Promise.reject(error);
            }
        },
    },
};
