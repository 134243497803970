<template>
  <div>
    <div class="flex-row mt-16 mb-16">
      <h3>Age Eligibility Warning and Violation Report {{ this.formattedReportDate }}</h3>
      <div class="spacer"></div>

      <el-input
          placeholder="Filter by Warning/ Violation"
          v-model="options.warningViolation"
          class="input-search ml-32"
          @change="resetPage"
          @keydown.esc.native="reset"
      >
        <el-button slot="append" icon="el-icon-search" @click="resetPage"></el-button>
      </el-input>
      <div class="spacer"></div>

      <p>Max away from Limit</p>
      <el-radio-group v-model="options.aerLimit" @change="resetPage" size="mini" class="ml-16">
        <el-radio-button label="1"> 1</el-radio-button>
        <el-radio-button label="2"> 2</el-radio-button>
        <el-radio-button label="3"> 3</el-radio-button>
        <el-radio-button label="4"> 4</el-radio-button>
      </el-radio-group>
      <div class="spacer"></div>

      <el-button plain type="primary" @click="downloadWarningViolationPdf" v-loading="loadingPdfAgeEligibilityWarningsViolations">Export PDF
      </el-button>
    </div>

    <el-alert
        title="info"
        type="info"
        :closable="false"
        description="The following players are at or near their limits of Wild Cards (total WCs or limit at certain levels/ circuits) and/or number of professional tournaments (WTA & ITF Women’s Circuit) until their next birthday."
        show-icon
    >
    </el-alert>

    <ErrorDisplay :requestError="this.requestError" @closed="clearError"/>

    <div v-loading="loadingAgeEligibilityWarningsViolations">
      <DataTable
          v-if="!loadingErrorAgeEligibilityWarningsViolations"
          :schema="schema"
          :items="ageEligibilityWarningsViolations.data"
          :fields="fields"
          :autoSort="false"
          :sortField="options.sortBy"
          :sortDir="options.sortDir"
          @header-clicked="setSort"
      />

      <Paginator
          v-if="!loadingErrorAgeEligibilityWarningsViolations"
          :data="ageEligibilityWarningsViolations"
          :elementsPerPage="options.pageSize"
          @prev-page="prevPage"
          @next-page="nextPage"
          @set-page-size="setPageSize"
      />
    </div>
  </div>
</template>


<script>
import {mapGetters, mapActions} from 'vuex';
import {AgeEligibilityTracking} from '@/models/age-eligibility-tracking';
import {retrieveDtos} from '@/utils/crud-confirm';
import moment from 'moment';
import FileSaver from 'file-saver';

export default {
  data() {
    return {
      schema: AgeEligibilityTracking.schema,
      fields: AgeEligibilityTracking.warningViolationReport,
      requestError: null,
      options: {
        queryString: '',
        pageSize: 20,
        sortBy: null,
        sortDir: 'ascending',
        page: 0,
        aerLimit: 2,
      },
    };
  },
  mounted() {
    this.refresh();
  },
  computed: {
    ...mapGetters('ageEligibilityTrackings', [
      'ageEligibilityUpToDate',
      'ageEligibilityWarningsViolations',
      'loadingAgeEligibilityWarningsViolations',
      'loadingErrorAgeEligibilityWarningsViolations',
      'loadingPdfAgeEligibilityWarningsViolations',
    ]),
    formattedReportDate() {
      return this.ageEligibilityUpToDate ? ' up to ' + moment(this.ageEligibilityUpToDate).format('YYYY-MM-DD') : ' - please select Up to Date';
    },
  },
  methods: {
    ...mapActions('ageEligibilityTrackings', [
      'findAgeEligibilityWarningViolations',
      'retieveAgeEligibilityUpToDate',
      'downloadPdfAgeEligibilityWarningViolations',
    ]),
    refresh() {
      this.search();
    },
    search() {
      let params = {
        self: this,
        fRetrieve: this.findAgeEligibilityWarningViolations,
        options: this.options,
        dtosName: 'AER Warning/ Violation',
      };
      retrieveDtos(params);
      this.retieveAgeEligibilityUpToDate();
    },
    setSort(event) {
      this.options.sortBy = event.property;
      this.options.sortDir = event.order;
      this.search();
    },
    prevPage() {
      this.options.page--;
      this.search();
    },
    nextPage() {
      this.options.page++;
      this.search();
    },
    setPageSize(size) {
      this.options.pageSize = size;
      this.search();
    },
    resetPage() {
      this.options.page = 0;
      this.search();
    },
    reset() {
      this.options.queryString = null;
      this.options.page = 0;
      this.search();
    },
    onChange() {
    },
    clearError() {
      this.requestError = null;
    },
    onExportCsv() {
      this.downloadAndSaveCsvFile(this.downloadCsvEligibilitTracking, `Age_Eligibility_Tracking_${this.formattedReportDate}.csv`, this.options);
    },
    downloadAndSaveCsvFile(fdownload, filename, options = {}) {
      fdownload(options)
          .then((result) => {
            const file = new File([result], filename, {type: 'text/csv'});
            FileSaver.saveAs(file);
            this.$notify({
              title: 'Age Eligibility Tracking: CSV Download successful',
              message: filename + ' downloaded',
              type: 'success',
              duration: 5000,
            });
          })
          .catch((error) => {
            this.requestError = error;
            this.$notify({
              title: 'Age Eligibility Tracking: CSV Download failed',
              message: filename + ': ' + error,
              type: 'error',
              duration: 7000,
            });
          });
    },

    downloadWarningViolationPdf() {
      this.requestError = null;
      let filename = "WTA_AER_Warning_Violation_Report_" + moment(this.ageEligibilityUpToDate).format("YYYY_MM_DD") + `_${this.options.aerLimit}_away.pdf`;
      this.downloadPdfAgeEligibilityWarningViolations(this.options)
          .then((result) => {
            const file = new Blob([result], {type: 'application/pdf'});
            saveAs(file, filename);
            this.$notify({
              title: 'AER Warnings/ Violation report: PDF download successful',
              message: filename + ' downloaded',
              type: 'success',
              duration: 5000,
            });
          })
          .catch((error) => {
            this.requestError = error;
            this.$notify({
              title: 'AER Warnings/ Violation report: PDF download failed',
              message: filename + ': ',
              type: 'error',
              duration: 7000,
            });
          });
    },
  },
};
</script>
