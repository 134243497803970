import { Model, isValid } from './model';

export class WtaTourYear extends Model {
    isValid() {
        return isValid(WtaTourYear.schema, this);
    }
    isValidForAdd() {
        return isValid(WtaTourYear.schemaAdd, this);
    }
    isValidForEdit() {
        return isValid(WtaTourYear.schema, this);
    }
}

WtaTourYear.layout = {
    children: [
        {
            title: 'Entry',
            children: ['tourYear', 'endDate'],
        },
    ],
};

WtaTourYear.layoutAdd = {
    children: [
        {
            children: ['tourYear', 'endDate'],
        },
    ],
};

WtaTourYear.schema = {
    fields: {
        tourYear: { type: 'number', displayName: 'WTA Tour Year', required: true, readonly: true },
        startDate: { type: 'us-date', displayName: 'Start Date', readonly: true },
        endDate: { type: 'us-date', displayName: 'End Date', required: true },
        isValid: {
            type: 'boolean',
            displayName: 'Is Valid',
            readonly: true,
            lookup: [
                { id: true, displayName: 'Valid' },
                { id: false, displayName: 'Start Date missing' },
            ],
        },
    },
};

WtaTourYear.schemaAdd = {
    fields: {
        tourYear: { type: 'number', displayName: 'WTA Tour Year', required: true },
        endDate: { type: 'us-date', displayName: 'End Date', required: true },
    },
};
