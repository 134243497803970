var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('DialogAddItem',{attrs:{"visibleSync":_vm.addDialogVisible,"dtoName":"Player Payment","selected":_vm.selectedPlayerPayment,"schema":_vm.schemaAdd,"layout":_vm.layout},on:{"save":_vm.onSave,"change":_vm.onChange,"close":function($event){_vm.addDialogVisible = false}}}),_c('DialogEditItem',{attrs:{"visibleSync":_vm.editDialogVisible,"dtoName":"Player Payment","selected":_vm.selectedPlayerPayment,"schema":_vm.schemaAdd,"layout":_vm.layout,"showNav":"true","widthPerc":90},on:{"save":_vm.onSave,"change":_vm.onChange,"delete":_vm.onDelete,"close":function($event){_vm.editDialogVisible = false},"prev":_vm.gotoPrevPlayerPayment,"next":_vm.gotoNextPlayerPayment}}),_c('PlayerPaymentImportCsv',{attrs:{"visibleSync":_vm.importDialogVisible,"tournYear":_vm.tournYear,"tournNbr":_vm.tournNbr},on:{"close":function($event){_vm.importDialogVisible = false},"success":_vm.onImport}}),(!_vm.isBaselineReader)?_c('div',{staticClass:"flex-row mt-0"},[_c('span',{staticClass:"spacer"}),_c('el-button',{attrs:{"plain":"","type":"primary","size":"mini"},on:{"click":function($event){_vm.importDialogVisible = true}}},[_vm._v(" Import Player Payments ")]),_c('el-button',{attrs:{"plain":"","type":"danger","size":"mini"},on:{"click":_vm.onDeleteAll}},[_vm._v("Delete ALL Player Payments ")])],1):_vm._e(),_c('ErrorDisplay',{staticClass:"mt-16",attrs:{"requestError":this.requestError},on:{"closed":_vm.clearError}}),_c('DataTable',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loadingPlayerPayments),expression:"loadingPlayerPayments"}],attrs:{"items":_vm.playerPayments,"fields":[
            'idp',
            'playerNbr',
            'playerName',
            'nationality',
            'singlesRound',
            'doublesRound',
            'singlePriceMoney',
            'doublePriceMoney',
            'luckyLoserQualifyingRound',
            'luckyLoserQualifyingAmount',
            'luckyLoserDeductRound',
            'luckyLoserDeductAmount',
            'subtotal',
            'tax',
            'totalDeduction',
            'netPayment'
        ],"schema":_vm.schema},on:{"item-clicked":_vm.onItemClicked}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }