import { icoCountries } from '../utils/country-codes';
import { Model, isValid } from './model';

export class RequiredTournament extends Model {
    isValidForAdd() {
        return isValid(RequiredTournament.schemaAdd, this);
    }

    isValidForEdit() {
        return isValid(RequiredTournament.schema, this);
    }
}

RequiredTournament.layout = {
    children: [
        {
            children: ['playerId', 'fullName', 'playerSinglesRank', 'season', 'tournamentId'],
        },
        {
            children: ['tournamentCategory', 'tournamentCategoryR', 'tournamentCategoryM'],
        },
    ],
};

RequiredTournament.layoutAdd = {
    children: [
        {
            children: ['playerId', 'playerSinglesRank', 'season', 'tournamentId'],
        },
        {
            children: ['tournamentCategory', 'tournamentCategoryR', 'tournamentCategoryM'],
        },
    ],
};

RequiredTournament.schema = {
    fields: {
        season: { type: 'number', displayName: 'Season', readonly: true, sortable: true },
        tournamentId: { type: 'number', displayName: 'Tournament Id', readonly: true, sortable: true },
        playerId: { type: 'number', displayName: 'Player Id', readonly: true, sortable: true },
        fullName: { type: 'string', displayName: 'Player', readonly: true },
        firstName: { type: 'string', displayName: 'First Name', readonly: true },
        lastName: { type: 'string', displayName: 'Last Name', readonly: true },
        playerNationality: { type: 'nationality', displayName: 'Nationality', readonly: true, lookup: Object.keys(icoCountries) },
        playerSinglesRank: { type: 'number', displayName: 'Singles Rank', sortable: true },
        tournamentCategory: { type: 'string', displayName: 'Tournament Category' },
        tournamentCategoryR: { type: 'string', displayName: 'Tournament Category R' },
        tournamentCategoryM: { type: 'string', displayName: 'Tournament Category M' },
    },
};

RequiredTournament.schemaAdd = {
    fields: {
        season: { type: 'number', displayName: 'Season', sortable: true },
        tournamentId: { type: 'number', displayName: 'Tournament Id', sortable: true },
        playerId: { type: 'player', displayName: 'Player Id', sortable: true },
        fullName: { type: 'string', displayName: 'Player', readonly: true },
        firstName: { type: 'string', displayName: 'First Name', readonly: true },
        lastName: { type: 'string', displayName: 'Last Name', readonly: true },
        playerSinglesRank: { type: 'number', displayName: 'Singles Rank', sortable: true },
        tournamentCategory: { type: 'string', displayName: 'Tournament Category' },
        tournamentCategoryR: { type: 'string', displayName: 'Tournament Category R' },
        tournamentCategoryM: { type: 'string', displayName: 'Tournament Category M' },
    },
};
