<template>
    <div>
        <DialogAddItem
                :visibleSync="addDialogVisible"
                dtoName="Historic Race Singles"
                :selected="selectedRanking"
                :schema="schemaAdd"
                :layout="layoutAdd"
                @save="saveChanges"
                @change="onChange"
                @close="addDialogVisible = false"
        />

        <DialogEditItem
                :visibleSync="editDialogVisible"
                dtoName="Historic Race Singles"
                :selected="selectedRanking"
                :schema="schema"
                :layout="layout"
                @save="saveChanges"
                @change="onChange"
                @delete="deleteItem"
                @close="editDialogVisible = false"
        />

        <ErrorDisplay :requestError="this.loadingErrorWtaAndGs" @closed="clearError" />

        <div v-loading="loadingRankingWtaAndGs">
            <DataTable
                    :items="rankingsWtaAndGs.data"
                    :schema="schema"
                    :fields="['playerId', 'fullName', 'nationality', 'rankChampSingles', 'pointsCs']"
                    @item-clicked="selectRanking"
                    @header-clicked="setSort"
                    :autoSort="false"
            />

            <Paginator
                    v-if="rankingsWtaAndGs.data"
                    :data="rankingsWtaAndGs"
                    :elementsPerPage="options.pageSize"
                    @prev-page="prevPage"
                    @next-page="nextPage"
                    @set-page-size="setPageSize"
            />
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { RaceRankingHistory } from '../../models/race-ranking-history';
import * as moment from 'moment';
import { retrieveDtos, deleteConfirmDto, createConfirmDto, updateConfirmDto } from '../../utils/crud-confirm';
import FileSaver from 'file-saver';

export default {
    props: ['rankingDate', 'queryString'],
    watch: {
        rankingDate() {
            this.reset();
        },
        queryString() {
            this.queryStringChanged(this.queryString);
            this.reset();
        },
    },
    data() {
        return {
            schema: RaceRankingHistory.schema,
            layout: RaceRankingHistory.layout,
            schemaAdd: RaceRankingHistory.schemaAdd,
            layoutAdd: RaceRankingHistory.layoutAdd,
            editDialogVisible: false,
            addDialogVisible: false,
            mode: 'add',
            selectedRanking: null,
            options: {
                queryString: '',
                sortBy: '',
                sortDir: '',
                pageSize: process.env.VUE_APP_PAGE_SIZE,
                page: 0,
            },
        };
    },
    mounted() {
    },
    computed: mapGetters('historyRaceSingles', ['rankingsWtaAndGs', 'loadingRankingWtaAndGs', 'loadingErrorWtaAndGs']),
    methods: {
        ...mapActions('historyRaceSingles', ['findRaceSinglesRankingsWtaAndGs', 'createRaceSinglesRanking', 'update', 'delete']),
        refresh() {
            let findParams = {
                options: this.options,
                date: this.rankingDate,
            };
            let params = {
                self: this,
                fRetrieve: this.findRaceSinglesRankingsWtaAndGs,
                options: findParams,
                dtosName: 'Historic Race Singles',
            };
            retrieveDtos(params);
        },
        reset() {
            this.options.page = 0;
            this.refresh();
        },
        prevPage() {
            this.options.page--;
            this.refresh();
        },
        nextPage() {
            this.options.page++;
            this.refresh();
        },
        setPageSize(size) {
            this.options.pageSize = size;
            this.refresh();
        },
        setSort(event) {
            this.options.sortBy = event.property;
            this.options.sortDir = event.order;
            this.refresh();
        },
        queryStringChanged(queryString) {
            this.options.queryString = queryString;
            this.refresh();
        },
        selectRanking(item) {
            console.log(item);
            this.selectedRanking = new RaceRankingHistory(item);
            this.mode = 'edit';
            this.editDialogVisible = true;
        },
        addRanking() {
            this.selectedRanking = new RaceRankingHistory({raceId: 'wta_and_gs', rankDate: this.rankingDate});
            this.mode = 'add';
            this.addDialogVisible = true;
        },
        clearError() {
            this.loadingErrorWtaAndGs = null;
        },
        saveChanges() {
            if (this.mode === 'edit') {
                let params = {
                    self: this,
                    fUpdate: this.update,
                    dto: this.selectedRanking,
                    dtoName: 'Historic Race Singles',
                    callback: this.crudCallback,
                };
                updateConfirmDto(params);
            } else if (this.mode === 'add') {
                let params = {
                    self: this,
                    fCreate: this.createRaceSinglesRanking,
                    dto: this.selectedRanking,
                    dtoName: 'Historic Race Singles',
                    callback: this.crudCallback,
                };
                createConfirmDto(params);
            }
        },
        onChange() {},
        deleteItem() {
            console.log(this.selectedRanking);
            let params = {
                self: this,
                fDelete: this.delete,
                vConfirm: this.selectedRanking.data.playerId,
                dto: this.selectedRanking,
                dtoName: 'Historic Race Singles',
                vConfirmName: 'Player ID',
                callback: this.crudCallback,
            };
            deleteConfirmDto(params);
        },
        crudCallback() {
            this.refresh();
            this.requestError = null;
            this.addDialogVisible = false;
            this.editDialogVisible = false;
        },
        // exportCsvClick() {
        //     const date = moment(this.rankingDate).format('YYYY-MM-DD');
        //     this.exportCsv({
        //         date: date,
        //         queryString: this.options.queryString || '',
        //         sortBy: this.options.sortBy || 'rank',
        //         sortDir: this.options.sortDir || 1,
        //     })
        //             .then((result) => {
        //                 const file = new File([result], `team_points_history_${moment(date).format('YYYY_MM_DD')}.csv`, { type: 'text/csv' });
        //                 FileSaver.saveAs(file);
        //             })
        //             .catch((error) => {
        //                 console.log(error);
        //                 this.$notify({
        //                     title: 'CSV export',
        //                     message: 'CSV export failed',
        //                     type: 'error',
        //                     duration: 5000,
        //                 });
        //             });
        // },
    },
};
</script>
