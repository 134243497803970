import axios from 'axios';

export default {
    namespaced: true,

    state: {
        tournaments: {},
        loadingTournaments: false,
        loadingError: null,
        options: {
            queryString: null,
            sortBy: 'startDate',
            sortDir: 'descending',
            page: 0,
            pageSize: 20,
        },
    },

    getters: {
        tournaments: (state) => state.tournaments,
        loadingTournaments: (state) => state.loadingTournaments,
        loadingError: (state) => state.loadingError,
        options: (state) => state.options,
    },

    mutations: {
        setTournaments(state, data) {
            state.tournaments = data;
        },

        startLoadingTournaments(state) {
            state.loadingTournaments = true;
        },

        stopLoadingTournaments(state) {
            state.loadingTournaments = false;
        },

        setLoadingError(state, error) {
            state.loadingError = error;
        },

        clearLoadingError(state) {
            state.loadingError = null;
        },
    },

    actions: {
        async findTournaments(context, options = {}) {
            context.commit('startLoadingTournaments');
            try {
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/Edition`, {
                    params: {
                        queryString: options.queryString || '',
                        pageSize: options.pageSize || 50,
                        sortBy: options.sortBy || 'Name',
                        sortDir: options.sortDir === 'descending' ? -1 : 1,
                        page: options.page,
                    },
                });
                context.commit('stopLoadingTournaments');
                context.commit('setTournaments', result.data);
                context.commit('setLoadingError', null);
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('stopLoadingTournaments');
                context.commit('setLoadingError', error);
                return Promise.reject(error);
            }
        },

        async exportCsv(context, options = {}) {
            context.commit('startLoadingTournaments');
            try {
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/Edition/export/csv`, {
                    params: {
                        queryString: options.queryString || '',
                        sortBy: options.sortBy || 'Name',
                        sortDir: options.sortDir === 'descending' ? -1 : 1,
                    },
                });
                context.commit('stopLoadingTournaments');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('stopLoadingTournaments');
                return Promise.reject(error);
            }
        },
    },
};
