<template>
    <el-dialog :visible.sync="visibleSync" :close-on-click-modal="false" :title="title()" :width="width()" :show-close="false" top="10vh">
        <FormGroup
            :model="selected"
            :schema="schema"
            :layout="layout"
            :ageAt="ageAt"
            :rankAt="rankAt"
            :readonlyForEdit="true"
            :allReadonly="allReadonly"
            @change="onChange"
        />

        <div class="dialog-footer" v-if="!isBaselineReader">
            <el-button v-if="!allReadonly" type="primary" @click="onSave" :disabled="!selected || !selected.isValidForEdit() || !selected.hasChanges()"
                >Save changes
            </el-button>
            <el-button v-if="!allReadonly" type="primary" @click="onDiscard" :disabled="!selected || !selected.hasChanges()">Discard changes </el-button>
            <el-button @click="onClose">Close</el-button>
            <span class="spacer"></span>
            <el-button v-if="showNav" @click="onPrev">Prev</el-button>
            <el-button v-if="showNav" @click="onNext">Next</el-button>
            <span v-if="showNav" class="spacer"></span>
            <el-button v-if="!allReadonly" plain type="danger" @click="onDelete">Delete {{ this.dtoName }}</el-button>
        </div>
        <div v-if="selected && selected.data.errorFound" class="mt-32">
            <div v-for="(item, i) of selected.data.errors" :key="i">{{ item }}</div>
        </div>
    </el-dialog>
</template>

<script>
import { isBaselineReader } from '../services/authGroups';

export default {
    props: ['visibleSync', 'dtoName', 'selected', 'schema', 'layout', 'showNav', 'widthPerc', 'ageAt', 'rankAt', 'allReadonly', 'disableDelete'],
    data() {
        return {
            isBaselineReader: isBaselineReader(),
        };
    },
    methods: {
        onSave() {
            this.selected.saveChanges();
            this.$emit('save');
        },
        onDiscard() {
            this.selected.discardChanges();
        },
        onChange(data) {
            this.$emit('change', data);
        },
        onDelete() {
            this.$emit('delete');
        },
        onClose() {
            this.$emit('close');
        },
        onPrev() {
            this.$emit('prev');
        },
        onNext() {
            this.$emit('next');
        },
        title() {
            return 'Edit ' + this.dtoName;
        },
        width() {
            return this.widthPerc ? this.widthPerc.toString() + '%' : '65%';
        },
    },
};
</script>

<style scoped>
.score-bracket {
    margin-left: -9px;
}

.score-dash {
    margin-left: -11px;
}
</style>
