import { Model, isValid } from './model';
import { icoCountries } from '../utils/country-codes';

export class RaceRankingHistory extends Model {
    isValid() {
        return isValid(RaceRankingHistory.schema, this);
    }

    isValidForAdd() {
        return isValid(RaceRankingHistory.schemaAdd, this);
    }

    isValidForEdit() {
        return isValid(RaceRankingHistory.schema, this);
    }
}

RaceRankingHistory.layout = {
    children: [
        {
            children: ['raceId', 'playerId', 'rankDate', { type: 'spacer', width: 1 }],
        },
        {
            children: ['fullName', 'nationality', { type: 'spacer', width: 2 }],
        },
        {
            title: 'Championship Singles',
            children: ['rankChampSingles', 'pointsCs', 'rankChampSinglesCd'],
        },
    ],
};

RaceRankingHistory.layoutAdd = {
    children: [
        {
            children: ['raceId', 'playerId', 'rankDate', { type: 'spacer', width: 1 }],
        },
        {
            title: 'Championship Singles',
            children: ['rankChampSingles', 'pointsCs', 'rankChampSinglesCd'],
        },
    ],
};

RaceRankingHistory.schema = {
    fields: {
        raceId: {
            type: 'string', displayName: 'Race Id', readonly: true, sortable: true, required: true,
            lookup: [
                { id: 'wta_and_gs', displayName: 'WTA & GS' },
            ],
        },
        playerId: { type: 'player', displayName: 'Player Id', readonly: true, sortable: true, required: true },
        rankDate: { type: 'date', displayName: 'Rank Date', readonly: true, required: true },
        rankChampSingles: { type: 'number', displayName: 'Rank Championship Singles', sortable: true },
        rankChampSinglesCd: { type: 'string', displayName: 'Rank Championship Singles Cd' },
        pointsCs: { type: 'number', displayName: 'Points Championship Singles', sortable: true },
        firstName: { type: 'string', displayName: 'First Name', readonly: true },
        lastName: { type: 'string', displayName: 'Last Name', readonly: true },
        nationality: {
            type: 'nationality',
            displayName: 'Nationality',
            lookup: Object.keys(icoCountries),
            readonly: true,
            sortable: true
        },
        fullName: { type: 'string', displayName: 'Player', readonly: true },
    },
};

RaceRankingHistory.schemaAdd = {
    fields: {
        raceId: {
            type: 'string', displayName: 'Race Id', required: true, readonly: true,
            lookup: [
                { id: 'wta_and_gs', displayName: 'WTA & GS' },
            ],
        },
        playerId: { type: 'player', displayName: 'Player Id', required: true },
        rankDate: { type: 'date', displayName: 'Rank Date', required: true },
        rankChampSingles: { type: 'number', displayName: 'Rank Championship Singles' },
        rankChampSinglesCd: { type: 'string', displayName: 'Rank Championship Singles Cd' },
        pointsCs: { type: 'number', displayName: 'Points Championship Singles' },
    },
};
