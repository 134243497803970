import axios from 'axios';
import { buildParams } from '@/utils/query-param-builder';

export default {
    namespaced: true,

    state: {
        historicShadowTeamRankings: {},
        loadingHistoricShadowTeamRankingsRanking: false,
        loadingErrorHistoricShadowTeamRankingsRanking: null,
    },

    getters: {
        historicShadowTeamRankings: (state) => state.historicShadowTeamRankings,
        loadingHistoricShadowTeamRankingsRanking: (state) => state.loadingHistoricShadowTeamRankingsRanking,
        loadingErrorHistoricShadowTeamRankingsRanking: (state) => state.loadingErrorHistoricShadowTeamRankingsRanking,
    },

    mutations: {
        setHistoricShadowTeamRankingsRanking(state, data) {
            state.historicShadowTeamRankings = data;
        },

        startLoadingHistoricShadowTeamRankingsRanking(state) {
            state.loadingHistoricShadowTeamRankingsRanking = true;
        },

        stopLoadingHistoricShadowTeamRankingsRanking(state) {
            state.loadingHistoricShadowTeamRankingsRanking = false;
        },

        setLoadingErrorHistoricShadowTeamRankingsRanking(state, error) {
            state.loadingErrorHistoricShadowTeamRankingsRanking = error;
        },

        clearLoadingErrorHistoricShadowTeamRankingsRanking(state) {
            state.loadingErrorHistoricShadowTeamRankingsRanking = null;
        },
    },

    actions: {
        async findHistoricShadowTeamRankings(context, options = {}) {
            context.commit('startLoadingHistoricShadowTeamRankingsRanking');
            context.commit('clearLoadingErrorHistoricShadowTeamRankingsRanking');
            let date = options.rankingDate;
            let params = buildParams(options);
            try {
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/ShadowRankingTeamPointHistory/ByDate/${date}`, { params });
                context.commit('setHistoricShadowTeamRankingsRanking', result.data);
                context.commit('stopLoadingHistoricShadowTeamRankingsRanking');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('stopLoadingHistoricShadowTeamRankingsRanking');
                context.commit('setLoadingErrorHistoricShadowTeamRankingsRanking', error);
                return Promise.reject(error);
            }
        },
        clearLoadingErrorHistoricShadowTeamRankingsRanking(context) {
            context.commit('clearLoadingErrorHistoricShadowTeamRankingsRanking');
        }
    },
};
