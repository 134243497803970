<template>
    <div v-loading="loadingShadowRankingSummaries">

        <el-tabs v-if="shadowTeamRaceSummaries" value="0">
            <el-tab-pane v-for="(shadowTeamRaceSummary, index) in this.shadowTeamRaceSummaries"
                         :key="shadowTeamRaceSummary.teamName"
                         :label="shadowTeamRaceSummary.teamName"
                         :name="index.toString()">
            <div class="flex-row">
                <RankingSummaryPointTable :summary="shadowTeamRaceSummary" />
                <span class="spacer"></span>
                <span class="spacer"></span>
                <span class="spacer"></span>
                <div>
                    <table class="summary-table mb-16" v-if="shadowTeamRaceSummary && shadowTeamRaceSummary.allResults">
                        <tr>
                            <td>
                                <div>Combined Points</div>
                                <div>from all WTA 1000 Mandatory Tournaments</div>
                            </td>
                            <td>{{ shadowTeamRaceSummary.totalPointsPm }}</td>
                        </tr>
                        <tr>
                            <td>
                                <div>Highest Ranking Point Result</div>
                                <div>from any WTA 1000 Mandatory Tournament</div>
                            </td>
                            <td>{{ shadowTeamRaceSummary.highestPointsPm }}</td>
                        </tr>
                    </table>
                </div>
                <span class="spacer"></span>
                <div>
                    <table class="summary-table mb-16" v-if="shadowTeamRaceSummary && shadowTeamRaceSummary.allResults">
                        <tr>
                            <td>
                                <div>Combined Points</div>
                                <div>from all WTA 1000 Non-Mandatory Tournaments</div>
                            </td>
                            <td>{{ shadowTeamRaceSummary.totalPointsP5 }}</td>
                        </tr>
                        <tr>
                            <td>
                                <div>Highest Ranking Point Result</div>
                                <div>from any WTA 1000 Non-Mandatory Tournament</div>
                            </td>
                            <td>{{ shadowTeamRaceSummary.highestPointsP5 }}</td>
                        </tr>
                    </table>
                </div>
                <span class="spacer"></span>
                <div>
                    <table class="summary-table mb-16" v-if="shadowTeamRaceSummary && shadowTeamRaceSummary.allResults">
                        <tr>
                            <td>
                                <div>Highest Ranking Point Result</div>
                                <div>from any WTA 500 Tournament</div>
                            </td>
                            <td>{{ shadowTeamRaceSummary.highestPointsP }}</td>
                        </tr>
                        <tr>
                            <td>
                                <div>Highest Ranking Point Result</div>
                                <div>from any WTA 250 Tournament</div>
                            </td>
                            <td>{{ shadowTeamRaceSummary.highestPointsIs }}</td>
                        </tr>
                    </table>
                </div>
            </div>

            <DataTable
                v-if="shadowTeamRaceSummary"
                :items="shadowTeamRaceSummary.allResults"
                :schema="schemaTournResult"
                :fields="[
                    'startDate',
                    'useForRankingDate',
                    'tournamentName',
                    'season',
                    'tournamentId',
                    //'tournamentType',
                    //'tournamentLevel',
                    'isRequired',
                    'points',
                    'isIncludedIntoRankingDoubles',
                    'rankingLabel',
                ]"
                :autoSort="false"
            />
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { TournamentResult } from '../../models/tournament-result';

export default {
    props: ['dataFields'],
    data() {
        return {
            schemaTournResult: TournamentResult.schema,
            selectedTab: null,
        };
    },
    mounted() {},
    computed: {
        ...mapGetters('shadowRankingSummaries', ['shadowTeamRaceSummaries', 'loadingShadowRankingSummaries']),
    },
    methods: {
    },
};
</script>

<style scoped></style>
