<template>
    <div>
        <DialogEditItem
                :disableDelete="true"
                :layout="layout"
                :schema="schema"
                :selected="selectedShadowRankingPoints"
                :showNav="true"
                :visibleSync="editDialogVisible"
                dtoName="Shadow Ranking Points"
                @change="onChange"
                @close="onClose"
                @next="gotoNextShadowRankingPoints"
                @prev="gotoPrevShadowRankingPoints"
                @save="saveChanges"
        />

        <div class="mt-8">
            <DataTable
                    v-loading="loadingShadowRankingPoints"
                    :autoSort="false"
                    :fields="[
                  'tableName',
                  'useRegularPoints',
                  'pointsWin',
                  'pointsRun',
                  'pointsSemi',
                  'pointsQtr',
                  'points16',
                  'points32',
                  'points64',
                  'points128',
                  'points256'
              ]"
                    :items="shadowRankingPoints[shadowRankId]"
                    :schema="schema"
                    @item-clicked="itemClicked"
            />
        </div>
    </div>
</template>

<script>
import { ShadowRankingPoints } from '@/models/shadow-ranking-points';
import { mapActions, mapGetters } from 'vuex';
import { deleteConfirmDto, retrieveDtos, updateConfirmDto } from '@/utils/crud-confirm';

export default {
    props: ['shadowRankId'],
    data() {
        return {
            schema: ShadowRankingPoints.schema,
            layout: ShadowRankingPoints.layout,
            editDialogVisible: false,
            selectedShadowRankingPoints: null,
            requestError: null,
        };
    },
    mounted() {
    },
    computed: {
        ...mapGetters('shadowRankingPoints', [
            'shadowRankingPoints',
            'loadingErrorShadowRankingsPoints',
            'loadingShadowRankingPoints'
        ]),
    },
    methods: {
        ...mapActions('shadowRankingPoints', [
            'findShadowRankingPoints',
            'updateShadowRankingPoints',
            'deleteShadowRankingPoints',
        ]),
        refresh() {
            let params = {
                self: this,
                fRetrieve: this.findShadowRankingPoints,
                dtosName: 'ShadowRankingPoints',
                options: { shadowRankId: this.shadowRankId },
            };
            retrieveDtos(params);
        },

        clearError() {
            this.requestError = null;
        },
        itemClicked(item, index) {
            this.selectedShadowRankingPoints = new ShadowRankingPoints(item);
            this.editDialogVisible = true;
            this.shadowRankingPointsPointer = index;
        },
        onChange() {
        },
        saveChanges() {
            let params = {
                self: this,
                fUpdate: this.updateShadowRankingPoints,
                dto: this.selectedShadowRankingPoints,
                dtoName: 'Shadow Ranking Points',
                callback: this.editCallback,
            };
            updateConfirmDto(params);
        },

        editCallback() {
            this.editDialogVisible = false;
            this.refresh();
        },

        onClose() {
            this.editDialogVisible = false;
        },

        deleteShadowRankingPointsClick() {
            let params = {
                self: this,
                fDelete: this.deleteShadowRankingPoints,
                vConfirm: this.selectedShadowRankingPoints.data.pointId,
                dto: this.selectedShadowRankingPoints,
                dtoName: 'Shadow Ranking Points',
                vConfirmName: 'Point Id',
                callback: this.editCallback,
            };
            deleteConfirmDto(params);
        },

        gotoPrevShadowRankingPoints() {
            if (this.shadowRankingPointsPointer === 0) {
                return;
            }
            this.shadowRankingPointsPointer -= 1;
            this.selectedShadowRankingPoints = new ShadowRankingPoints(this.shadowRankingPoints[this.shadowRankId][this.shadowRankingPointsPointer]);
        },

        gotoNextShadowRankingPoints() {
            if (this.shadowRankingPointsPointer === this.shadowRankingPoints[this.shadowRankId].length - 1) {
                return;
            }
            this.shadowRankingPointsPointer += 1;
            this.selectedShadowRankingPoints = new ShadowRankingPoints(this.shadowRankingPoints[this.shadowRankId][this.shadowRankingPointsPointer]);
        },
    },
};
</script>

<style scoped>
</style>
   