var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('ErrorDisplay',{attrs:{"requestError":this.loadingErrorShadowSinglesRanking},on:{"closed":_vm.clearError}}),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loadingShadowSinglesRanking),expression:"loadingShadowSinglesRanking"}]},[_c('DataTable',{attrs:{"autoSort":false,"fields":[
                'rankSingles',
                'priorRankSingles',
                'playerId',
                'fullName',
                'nationality',
                'playerBirthDate',
                'pointsSingles',
                'tournamentCountSingles',
                'nextOnSingles',
                'nextOffSingles',
                'points16ThSingles',
                'points17ThSingles',
                'highestSinglesRank',
                'highestSinglesRankDate',
            ],"items":_vm.shadowSinglesRankings.data,"schema":_vm.schema},on:{"header-clicked":_vm.setSort}}),(_vm.shadowSinglesRankings.data)?_c('Paginator',{attrs:{"data":_vm.shadowSinglesRankings,"elementsPerPage":_vm.options.pageSize},on:{"prev-page":_vm.prevPage,"next-page":_vm.nextPage,"set-page-size":_vm.setPageSize}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }