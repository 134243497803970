import axios from 'axios';

export default {
    namespaced: true,

    state: {
        runningProcedure: false
    },

    getters: {
        runningProcedure: (state) => state.runningProcedure
    },

    mutations: {
        procedureStarted(state) {
            state.runningProcedure = true;
        },

        procedureFinished(state) {
            state.runningProcedure = false;
        }
    }
};
