<template>
    <div>
        <DialogAddItem
                :layout="layoutAdd"
                :schema="schemaAdd"
                :selected="selected"
                :visibleSync="addDialogVisible"
                dtoName="Player Race Ranking"
                @change="onChange"
                @close="addDialogVisible = false"
                @save="saveChanges"
        />

        <DialogEditItem
                :layout="layout"
                :schema="schema"
                :selected="selected"
                :visibleSync="editDialogVisible"
                dtoName="Player Race Ranking"
                @change="onChange"
                @close="editDialogVisible = false"
                @delete="deleteItem"
                @save="saveChanges"
        />

        <ErrorDisplay :requestError="this.loadingErrorWtaAndGs" @closed="clearError"/>

        <div v-loading="loadingRankingWtaAndGs">
            <DataTable
                    :autoSort="false"
                    :fields="['rankChampSingles', 'playerId', 'fullName', 'nationality', 'pointsCs']"
                    :items="rankingsWtaAndGs.data"
                    :schema="schema"
                    @item-clicked="selectItem"
                    @header-clicked="setSort"
            />

            <Paginator
                    v-if="rankingsWtaAndGs.data"
                    :data="rankingsWtaAndGs"
                    :elementsPerPage="options.pageSize"
                    @prev-page="prevPage"
                    @next-page="nextPage"
                    @set-page-size="setPageSize"
            />
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { RaceRankingCurrent } from '../../models/race-ranking-current';
import * as moment from 'moment';
import FileSaver from 'file-saver';
import { retrieveDtos, deleteConfirmDto, createConfirmDto, updateConfirmDto } from '../../utils/crud-confirm';

export default {
    props: ['playerId'],
    watch: {
        playerId() {
            this.playerSelected({ id: this.playerId });
        },
    },
    data() {
        return {
            schema: RaceRankingCurrent.schema,
            schemaAdd: RaceRankingCurrent.schemaAdd,
            layout: RaceRankingCurrent.layout,
            layoutAdd: RaceRankingCurrent.layoutAdd,
            editDialogVisible: false,
            addDialogVisible: false,
            mode: 'add',
            selected: null,
            options: {
                queryString: '',
                sortBy: '',
                sortDir: '',
                pageSize: process.env.VUE_APP_PAGE_SIZE,
                page: 0,
            },
        };
    },
    mounted() {
        this.refresh();
    },
    computed: {
        ...mapGetters('currentRaceSingles', ['rankingsWtaAndGs', 'loadingRankingWtaAndGs', 'loadingErrorWtaAndGs']),
    },
    methods: {
        ...mapActions('currentRaceSingles', [
            'findRaceSinglesRankingsWtaAndGs',
            'createRaceSinglesRanking',
            'update',
            'delete',
            'exportCsv'
        ]),
        refresh() {
            let params = {
                self: this,
                fRetrieve: this.findRaceSinglesRankingsWtaAndGs,
                options: this.options,
                dtosName: 'Player Race Ranking',
            };
            console.log(params);
            retrieveDtos(params);
        },
        playerSelected(player) {
            this.options.page = 0;
            this.options.queryString = player.id;
            this.refresh();
        },
        reset() {
            this.options.page = 0;
            this.options.queryString = '';
            this.refresh();
        },
        prevPage() {
            this.options.page--;
            this.refresh();
        },
        nextPage() {
            this.options.page++;
            this.refresh();
        },
        setPageSize(size) {
            this.options.pageSize = size;
            this.refresh();
        },
        setSort(event) {
            this.options.sortBy = event.property;
            this.options.sortDir = event.order;
            this.refresh();
        },
        saveChanges() {
            if (this.mode === 'edit') {
                let params = {
                    self: this,
                    fUpdate: this.update,
                    dto: this.selected,
                    dtoName: 'Player Race Ranking',
                    callback: this.crudCallback,
                };
                updateConfirmDto(params);
            } else if (this.mode === 'add') {
                let params = {
                    self: this,
                    fCreate: this.createRaceSinglesRanking,
                    dto: this.selected,
                    dtoName: 'Player Race Ranking',
                    callback: this.crudCallback,
                };
                createConfirmDto(params);
            }
        },
        onChange() {
        },
        deleteItem() {
            let params = {
                self: this,
                fDelete: this.delete,
                vConfirm: this.selected.data.playerId,
                dto: this.selected,
                dtoName: 'Player Race Ranking',
                vConfirmName: 'Player ID',
                callback: this.crudCallback,
            };
            deleteConfirmDto(params);
        },
        addItem() {
            this.mode = 'add';
            this.addDialogVisible = true;
            this.selected = new RaceRankingCurrent({raceId: 'wta_and_gs'});
        },
        selectItem(item) {
            this.mode = 'edit';
            this.editDialogVisible = true;
            this.selected = new RaceRankingCurrent(item);
        },
        crudCallback() {
            this.refresh();
            this.$emit('refresh');
            this.requestError = null;
            this.addDialogVisible = false;
            this.editDialogVisible = false;
        },
        clearError() {
            this.loadingError = null;
        },
        // exportCsvClick() {
        //     this.exportCsv({
        //         queryString: this.options.queryString || '',
        //         sortBy: this.options.sortBy || 'rankChampionshipSingles',
        //         sortDir: 1,
        //     })
        //             .then((result) => {
        //                 const file = new File([result], `current_ranking_ChampSingles_${moment().format('DD_MM_YYYY HH_mm_SS')}.csv`, { type: 'text/csv' });
        //                 FileSaver.saveAs(file);
        //             })
        //             .catch((error) => {
        //                 this.$notify({
        //                     title: 'CSV export',
        //                     message: 'CSV export failed',
        //                     type: 'error',
        //                     duration: 5000,
        //                 });
        //             });
        // },
    },
};
</script>
