<template>
    <div v-loading="savingPlayerSupporter && !isBaselineReader">
        <div v-if="playerSupport">
            <div class="flex-row mt-0 mb-32">
                <h2><i class="el-icon-user mr-16"></i>Adding new Player Support</h2>
                <span class="spacer"></span>
                <el-button @click="closeSupportAdd">Close</el-button>
            </div>
            <el-tabs value="general">
                <el-tab-pane label="General" name="general">
                    <FormGroup :model="playerSupport" :schema="schema" :layout="layout" @change="onChange" />
                </el-tab-pane>
            </el-tabs>

            <SaveChanges :model="playerSupport" @save="saveChanges" @discard="discardChanges" />
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import * as _ from 'lodash';
import { PlayerSupport } from '../models/player-support';
import { isBaselineReader } from '../services/authGroups';

export default {
    data() {
        return {
            schema: PlayerSupport.schema,
            layout: PlayerSupport.layout,
            playerSupport: null,
            isBaselineReader: isBaselineReader(),
        };
    },
    mounted() {
        this.playerSupport = new PlayerSupport();
    },
    computed: {
        ...mapGetters('playerSupportDetails', ['savingPlayerSupporter']),
    },
    methods: {
        ...mapActions('playerSupportDetails', ['createPlayerSupport', 'savePlayerSupporter', 'deletePlayerSupporter']),
        saveChanges() {
            this.createPlayerSupport(this.playerSupport).then((result) => {
                this.$router.push(`/player-supporter/${result.contactId}`);
            });
        },
        discardChanges() {
            this.playerSupport.discardChanges();
        },
        closeSupportAdd() {
            this.$router.push(`/players?activeTab=player-supporters`);
        },
        onChange() {},
    },
};
</script>
