import axios from 'axios';
import { buildParams } from '@/utils/query-param-builder';

export default {
    namespaced: true,

    state: {
        historicSinglesShadowRankings: {},
        loadingHistoricSinglesShadowRankings: false,
        loadingErrorHistoricSinglesShadowRankings: null,
    },

    getters: {
        historicSinglesShadowRankings: (state) => state.historicSinglesShadowRankings,
        loadingHistoricSinglesShadowRankings: (state) => state.loadingHistoricSinglesShadowRankings,
        loadingErrorHistoricSinglesShadowRankings: (state) => state.loadingErrorHistoricSinglesShadowRankings,
    },

    mutations: {
        setHistoricSinglesShadowRankings(state, data) {
            state.historicSinglesShadowRankings = data;
        },

        startLoadingHistoricSinglesShadowRankings(state) {
            state.loadingHistoricSinglesShadowRankings = true;
        },

        stopLoadingHistoricSinglesShadowRankings(state) {
            state.loadingHistoricSinglesShadowRankings = false;
        },

        setLoadingErrorHistoricSinglesShadowRankings(state, error) {
            state.loadingErrorHistoricSinglesShadowRankings = error;
        },

        clearLoadingErrorHistoricSinglesShadowRankings(state) {
            state.loadingErrorHistoricSinglesShadowRankings = null;
        },
    },

    actions: {
        async findHistoricSinglesShadowRankings(context, options) {
            context.commit('startLoadingHistoricSinglesShadowRankings');
            context.commit('clearLoadingErrorHistoricSinglesShadowRankings');
            let date = options.rankingDate;
            let params = buildParams(options);
            try {
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/ShadowRankingHistory/${date}/Singles`, { params });
                context.commit('setHistoricSinglesShadowRankings', result.data);
                context.commit('stopLoadingHistoricSinglesShadowRankings');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('setLoadingErrorHistoricSinglesShadowRankings', error);
                context.commit('stopLoadingHistoricSinglesShadowRankings');
                return Promise.reject(error);
            }
        },
    },
};
