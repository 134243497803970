<template>
    <div>
        <DialogAddItem
            :visibleSync="addDialogVisible"
            dtoName="Player Ranking"
            :selected="selected"
            :schema="schemaAdd"
            :layout="layoutAdd"
            @save="saveChanges"
            @change="onChange"
            @close="addDialogVisible = false"
        />

        <DialogEditItem
            :visibleSync="editDialogVisible"
            dtoName="Player Ranking"
            :selected="selected"
            :schema="schema"
            :layout="layout"
            @save="saveChanges"
            @change="onChange"
            @delete="deleteItem"
            @close="editDialogVisible = false"
        />

        <ErrorDisplay :requestError="this.loadingError" @closed="clearError" />

        <div v-loading="loadingRanking">
            <DataTable
                :items="rankings.data"
                :schema="schema"
                :fields="[
                    'rankSingles',
                    'priorRankSingles',
                    'playerId',
                    'fullName',
                    'nationality',
                    'pointsSingles',
                    'tournamentCountSingles',
                    'nextOnSingles',
                    'nextOffSingles',
                    'points16ThSingles',
                    'points17ThSingles',
                    'highestSinglesRank',
                    'highestSinglesRankDate',
                ]"
                @item-clicked="selectItem"
                @header-clicked="setSort"
                :autoSort="false"
            />

            <Paginator
                v-if="rankings.data"
                :data="rankings"
                :elementsPerPage="options.pageSize"
                @prev-page="prevPage"
                @next-page="nextPage"
                @set-page-size="setPageSize"
            />
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { CurrentRanking } from '../../models/current-ranking';
import * as moment from 'moment';
import FileSaver from 'file-saver';
import { retrieveDtos, deleteConfirmDto, createConfirmDto, updateConfirmDto } from '../../utils/crud-confirm';

export default {
    props: ['playerId'],
    watch: {
        playerId() {
            this.playerSelected({ id: this.playerId });
        },
    },
    data() {
        return {
            schema: CurrentRanking.schema,
            schemaAdd: CurrentRanking.schemaAdd,
            layout: CurrentRanking.layout,
            layoutAdd: CurrentRanking.layoutAdd,
            editDialogVisible: false,
            addDialogVisible: false,
            mode: 'add',
            selected: null,
        };
    },
    mounted() {
        this.refresh();
    },
    computed: {
        ...mapGetters('currentSingles', ['rankings', 'loadingRanking', 'options', 'loadingError']),
    },
    methods: {
        ...mapActions('currentSingles', ['find', 'create', 'update', 'delete', 'exportCsv']),
        exportCsvClick() {
            this.exportCsv({
                queryString: this.options.queryString || '',
                sortBy: this.options.sortBy || 'rankSingles',
                sortDir: 1,
            })
                .then((result) => {
                    const file = new File([result], `current_ranking_Singles_${moment().format('DD_MM_YYYY HH_mm_SS')}.csv`, { type: 'text/csv' });
                    FileSaver.saveAs(file);
                })
                .catch((error) => {
                    this.$notify({
                        title: 'CSV export',
                        message: 'CSV export failed',
                        type: 'error',
                        duration: 5000,
                    });
                });
        },
        refresh() {
            let params = {
                self: this,
                fRetrieve: this.find,
                options: this.options,
                dtosName: 'Singles Rankings',
            };
            retrieveDtos(params);
        },
        playerSelected(player) {
            this.options.page = 0;
            this.options.queryString = player.id;
            this.refresh();
        },
        reset() {
            this.options.page = 0;
            this.options.queryString = '';
            this.refresh();
        },
        prevPage() {
            this.options.page--;
            this.refresh();
        },
        nextPage() {
            this.options.page++;
            this.refresh();
        },
        setPageSize(size) {
            this.options.pageSize = size;
            this.refresh();
        },
        setSort(event) {
            this.options.sortBy = event.property;
            this.options.sortDir = event.order;
            this.refresh();
        },
        saveChanges() {
            if (this.mode === 'edit') {
                let params = {
                    self: this,
                    fUpdate: this.update,
                    dto: this.selected,
                    dtoName: 'Player Ranking',
                    callback: this.crudCallback,
                };
                updateConfirmDto(params);
            } else if (this.mode === 'add') {
                let params = {
                    self: this,
                    fCreate: this.create,
                    dto: this.selected,
                    dtoName: 'Player Ranking',
                    callback: this.crudCallback,
                };
                createConfirmDto(params);
            }
        },
        onChange() {},
        deleteItem() {
            let params = {
                self: this,
                fDelete: this.delete,
                vConfirm: this.selected.data.playerId,
                dto: this.selected,
                dtoName: 'Player Ranking',
                vConfirmName: 'Player ID',
                callback: this.crudCallback,
            };
            deleteConfirmDto(params);
        },
        addItem() {
            this.mode = 'add';
            this.addDialogVisible = true;
            this.selected = new CurrentRanking({});
        },
        selectItem(item) {
            this.mode = 'edit';
            this.editDialogVisible = true;
            this.selected = new CurrentRanking(item);
        },
        crudCallback() {
            this.$emit('refresh');
            this.refresh();
            this.addDialogVisible = false;
            this.editDialogVisible = false;
        },
        clearError() {
            this.loadingError = null;
        },
    },
};
</script>
