import store from './store';
import * as signalR from "@microsoft/signalr";

const connection = new signalR.HubConnectionBuilder()
    .withUrl(process.env.VUE_APP_WS_URL, {
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets
    })
    .build();

// ranking procedure events
connection.on('RankingProcedureProgress', (progress) => {
    store.commit('rankingProcedure/startRankingProcedure');
    store.commit('rankingProcedure/setProgress', progress);

});

connection.on('RankingProcedureFinished', () => {
    store.commit('rankingProcedure/stopRankingProcedure');
});

connection.on('RankingProcedureFailed', (error) => {
    store.commit('rankingProcedure/stopRankingProcedure');
    store.commit('rankingProcedure/setRankingProcedureError', error)
});

// Round Result events
connection.on('RoundResultProgress', (progress) => {
    store.commit('tournamentRounds/startTournRoundSplit');
    store.commit('tournamentRounds/setProgress', progress);
});

// Update YTD Totals
connection.on('UpdateYtdTotalsProgress', (progress) => {
    store.commit('playerStats/startUpdateYtdTotals');
    store.commit('playerStats/setProgress', progress);
});

connection.on('UpdateYtdTotalsFinished', () => {
    store.commit('playerStats/stopUpdateYtdTotals');
});

// Download Pdf files
connection.on('DownloadPdfFilesProgress', () => {
    store.commit('pdfReports/setProgress');
});

// Data Export Events
connection.on('dataExportProgress', (progress) => {
    store.commit('dataExporter/setProgress', progress);
});

// main procedure events
connection.on('CurrentProcedureState', (currentState) => {
    if (currentState.rankingProcedureRunning) {
        store.commit('rankingProcedure/startRankingProcedure');
    } else {
        store.commit('rankingProcedure/stopRankingProcedure');
    }
});

async function start() {
    try {
       await connection.start();
    } catch (err) {
       setTimeout(() => start(), 5000);
    }
}

connection.onclose(async () => { 
    await start();
});

start();
