import { Model, isValid } from './model';

export class TournamentResultCoronaDropDate extends Model {
    isValid() {
        return isValid(TournamentResultCoronaDropDate.schema, this);
    }
    isValidForAdd() {
        return isValid(TournamentResultCoronaDropDate.schemaAdd, this);
    }

    isValidForEdit() {
        return isValid(TournamentResultCoronaDropDate.schema, this);
    }
}

TournamentResultCoronaDropDate.layout = {
    children: [
        {
            children: ['season', 'tournamentId', 'sdFlag'],
        },
        {
            children: [ 'playerId','fullName','dropAtDate'],
        },
    ],
};

TournamentResultCoronaDropDate.layoutAdd = {
    children: [
        {
            children: ['season', 'tournamentId', 'sdFlag'],
        },
        {
            children: ['playerId', 'dropAtDate', { type: 'spacer' }],
        },
    ],
};

TournamentResultCoronaDropDate.schema = {
    fields: {
        season: { type: 'number', displayName: 'Year', required: true, readonly: true },
        tournamentId: { type: 'number', displayName: 'Tournament Id', required: true, readonly: true },
        tournamentName: { type: 'string', displayName: 'Tournament Name', readonly: true },
        startDate: { type: 'us-date', displayName: 'Start Date', readonly: true },
        useForRankingDate: { type: 'us-date', displayName: ' Rank Date', readonly: true },
        playerId: { type: 'string', displayName: 'Player Id', required: true, readonly: true },
        firstName: { type: 'string', displayName: 'First name', readonly: true },
        lastName: { type: 'string', displayName: 'Last name', readonly: true },
        fullName: { displayName: 'Player Name', readonly: true },
        sdFlag: {
            type: 'string',
            displayName: 'S/D',
            required: true,
            readonly: true,
            lookup: [
                { id: 'S', displayName: 'Singles' },
                { id: 'D', displayName: 'Doubles' },
            ],
        },
        dropAtDate: { type: 'us-date', displayName: 'Drop Date', required: true },
    },
};

TournamentResultCoronaDropDate.schemaAdd = {
    fields: {
        season: { type: 'number', displayName: 'Year', required: true },
        tournamentId: { type: 'number', displayName: 'Tournament Id', required: true },
        tournamentName: { type: 'string', displayName: 'Name', readonly: true },
        startDate: { type: 'us-date', displayName: 'Start Date', readonly: true },
        useForRankingDate: { type: 'us-date', displayName: ' Rank Date', readonly: true },
        playerId: { type: 'player', displayName: 'Player', required: true },
        firstName: { type: 'string', displayName: 'First name', readonly: true },
        lastName: { type: 'string', displayName: 'Last name', readonly: true },
        fullName: { displayName: 'Name', readonly: true },
        sdFlag: {
            type: 'string',
            displayName: 'S/D',
            required: true,
            lookup: [
                { id: 'S', displayName: 'Singles' },
                { id: 'D', displayName: 'Doubles' },
            ],
        },
        dropAtDate: { type: 'us-date', displayName: 'Drop Date', required: true },
    },
};
