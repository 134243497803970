<template>
    <div>
        <h2>Baseline Core {{ version || '' }}</h2>
        <p>
            Welcome to Baseline Core - The Management System for everything Players, Tournaments, Rankings and Acceptance List. Please select from the left menu
            which operation you want to execute. Please be reminded that this application is not designed for use on smartphones or devices with a screen size
            of less than 1024px.
        </p>
        <h2>Server mode</h2>
        <div>
            <p v-if="mode === 'review'" style="color: orange">
                This server is running in REVIEW mode! All operations are running against a separate database and will not affect main ICAD database. The
                backend server is running at <a v-bind:href="url">{{ url }}</a
                >. Please make sure to be connected to WTA network as the server is restricting access. Please report issues at
                <a href="https://wtatour.visualstudio.com/Baseline/_workitems/create/Bug">WTA Tour DevOps</a>.
            </p>
            <p v-if="mode === 'production'" style="color: red">
                This server is running in PRODUCTION mode! All operations are running against the ICAD database. Changes are permanent and can only be rolled
                back on a day to day basis. If you notice any issues then check if they might have been already fixed on the
                <a href="https://frontend.blstaging.wtatennis.com">Review Deployment</a>. If not then please follow the guidelines for filing a bug on the start
                page of the review deployment.
            </p>
            <p v-if="mode === 'development'" style="color: green">
                This server is running in DEVELOPMENT mode! All operations are running against the SAP HANA Development database. The backend server is running
                at <a v-bind:href="url">{{ url }}</a> Please make sure to be connected to WTA network as the server is restricting access. Please report issues
                at <a href="https://wtatour.visualstudio.com/Baseline/_workitems/create/Bug">WTA Tour DevOps</a>.
            </p>
            <p v-if="mode === 'local'" style="color: blue">This server is running in LOCAL mode against url {{ url }}!</p>
        </div>
        <h2>Connectivity</h2>
        <p v-loading="connecting" element-loading-text="Connecting to server">
            <b v-if="connected" style="color: green">Connected to backend server</b>
            <i v-else style="color: red">
                Could not connect to server. This might be related to you not being connected to the VPN network. Please check your connection, clear your cache
                and reload the page to try again.
            </i>
        </p>
        <h2 v-if="database_date">Time Point</h2>
        <p v-if="database_date">
            The database is set to {{ database_date }}. This will affect rankings and acceptance lists. In most cases this also means that there is no data
            after this time point available. If you want to have the time point changed please contact the development team.
        </p>

        <h2 class="mt-32">Version History</h2>
        <vue-markdown v-if="changeLog" :source="changeLog"></vue-markdown>
    </div>
</template>

<script>
import axios from 'axios';
import VueMarkdown from 'vue-markdown';

export default {
    components: {
        VueMarkdown,
    },
    data() {
        return {
            mode: process.env.VUE_APP_APPLICATION_MODE,
            url: process.env.VUE_APP_API_URL,
            database_date: process.env.VUE_APP_DATABASE_DATE,
            connecting: true,
            connected: false,
            version: null,
            changeLog: null,
        };
    },
    mounted() {
        this.checkConnectivity();
        this.loadChangeLog();
        this.loadVersion();
    },
    methods: {
        checkConnectivity() {
            axios
                .get(`${process.env.VUE_APP_API_URL}/SysCode`)
                .then((data) => {
                    this.connecting = false;
                    this.connected = data.status === 200;
                })
                .catch(() => {
                    this.connecting = false;
                });
        },
        loadVersion() {
            axios
                .get(`${process.env.VUE_APP_API_URL}/ChangeLog/Version`)
                .then((result) => {
                    this.version = result.data;
                })
                .catch(() => {
                    this.version = 'N/A';
                });
        },
        loadChangeLog() {
            axios
                .get(`${process.env.VUE_APP_API_URL}/ChangeLog`)
                .then((result) => {
                    this.changeLog = result.data;
                })
                .catch(() => {
                    this.$notify({
                        title: 'Change log',
                        message: 'Change log could not be loaded',
                        type: 'error',
                        duration: 5000,
                    });
                });
        },
    },
};
</script>
