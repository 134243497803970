<template>
    <div>
        <ErrorDisplay :requestError="this.requestError" @closed="clearError" />

        <div v-if="playerSupportFound">
            <FormGroup :model="playerSupport" :schema="schema" :layout="layoutContact" @change="onChange" :allReadonly="false" class="mt-16" />
            <FormGroup :model="playerSupport" :schema="schema" :layout="layout" @change="onChange" class="mt-16" />

            <div class="dialog-footer" v-if="!isBaselineReader">
                <el-button
                    type="primary"
                    @click="onSave"
                    :disabled="!playerSupportFound || !playerSupport.isValidForSupportEdit() || !playerSupport.hasChanges()"
                    >Save changes</el-button
                >
                <el-button
                    type="primary"
                    @click="onDiscard"
                    :disabled="!playerSupportFound || !playerSupport.isValidForSupportEdit() || !playerSupport.hasChanges()"
                    >Discard changes</el-button
                >
            </div>
        </div>
        <div v-else><el-alert type="info" :closable="false" :show-icon="true"> Player does not have a coach in the system. </el-alert></div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { PlayerSupport } from '../../models/player-support';
import { deleteConfirmDto, createConfirmDto, updateConfirmDto } from '../../utils/crud-confirm';
import { isBaselineReader } from '../../services/authGroups';
import _ from 'lodash';
import moment from 'moment';

export default {
    props: ['contactId'],
    data() {
        return {
            schema: PlayerSupport.schema,
            layout: PlayerSupport.layoutCoachRookie,
            layoutContact: PlayerSupport.layoutContact,
            requestError: null,
            isBaselineReader: isBaselineReader(),
        };
    },
    mounted() {
        this.refresh();
    },
    computed: {
        ...mapGetters('playerSupportCoach', ['playerSupport', 'loadingError', 'playerSupportFound']),
    },
    methods: {
        ...mapActions('playerSupportCoach', ['findPlayerSupport', 'updatePlayerSupport']),
        refresh() {
            let self = this;
            this.findPlayerSupport(this.contactId)
                .then(() => {})
                .catch((error) => {
                    if (error.response.data.StatusCode === 404) {
                        return;
                    }
                    self.requestError = error;
                    self.$notify({
                        title: 'Get Player Support: Coach Error',
                        message: error.response.data.Message,
                        type: 'error',
                        duration: 5000,
                    });
                });
        },
        onChange(event) {
            let field = event.field;
            let value = event.value;
            if (field === 'coachEnroll1Date') {
                this.playerSupport.data.coachDeadl1Date = moment(value).add(90, 'd').utcOffset(0, true).toDate();
            }
            if (field === 'coachEnroll2Date') {
                this.playerSupport.data.coachDeadl2Date = moment(value).add(90, 'd').utcOffset(0, true).toDate();
            }
        },
        onSave() {
            let params = {
                self: this,
                fUpdate: this.updatePlayerSupport,
                dto: this.playerSupport,
                dtoName: 'Player Support: Coach',
                callback: this.saveCallback,
            };
            updateConfirmDto(params);
        },
        saveCallback() {
            this.$emit('coach-updated');
            this.requestError = null;
        },
        onDiscard() {
            this.playerSupport.discardChanges();
        },
        clearError() {
            this.requestError = null;
        },
    },
};
</script>

<style scoped></style>
