import axios from 'axios';
import moment from 'moment';

export default {
    namespaced: true,

    state: {
        rankingDates: [],
        rankingDate: null,
        queryString: '',
        loadingRankings: false,
        loadingError: null,
    },

    getters: {
        rankingDates: (state) => state.rankingDates,
        rankingDate: (state) => state.rankingDate,
        queryString: (state) => state.queryString,
    },
    mutations: {
        setRankingDates(state, data) {
            state.rankingDates = data;
        },
        setRankingDate(state, data) {
            state.rankingDate = data;
        },
        setQueryString(state, data) {
            state.queryString = data;
        },
        setLoadingError(state, error) {
            state.loadingError = error;
        },
        clearLoadingError(state) {
            state.loadingError = null;
        },
        startLoadingRankings(state) {
            state.loadingRankings = true;
        },
        stopLoadingRankings(state) {
            state.loadingRankings = false;
        },
    },

    actions: {
        async create(context, data) {
            context.commit('startLoadingRankings');
            try {
                const result = await axios.post(`${process.env.VUE_APP_API_URL}/ranking/history`, data.data);
                context.commit('stopLoadingRankings');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('stopLoadingRankings');
                context.commit('setLoadingError', error);
                return Promise.reject(error);
            }
        },
        async update(context, data) {
            context.commit('startLoadingRankings');
            try {
                const result = axios.put(`${process.env.VUE_APP_API_URL}/ranking/history`, data.data);
                context.commit('stopLoadingRankings');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('stopLoadingRankings');
                context.commit('setLoadingError', error);
                return Promise.reject(error);
            }
        },
        async delete(context, selectedRanking) {
            let date = moment(selectedRanking.data.rankDate).format('YYYY-MM-DD');
            let playerId = selectedRanking.data.playerId;
            context.commit('startLoadingRankings');
            try {
                await axios.delete(`${process.env.VUE_APP_API_URL}/ranking/history/${date}/${playerId}`);
                context.commit('stopLoadingRankings');
                return Promise.resolve();
            } catch (error) {
                context.commit('stopLoadingRankings');
                context.commit('setLoadingError', error);
                return Promise.reject(error);
            }
        },
        async retrieveRankigDatesForTourYear(context, tourYear) {
            try {
                let startDate = moment(tourYear.startDate).format('YYYY-MM-DD');
                let endDate = moment(tourYear.endDate).format('YYYY-MM-DD');
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/ranking/history/ranking-dates-between/${startDate}/${endDate}`);
                let dates = result.data.map((d) => {
                    return moment(d).format('YYYY-MM-DD');
                });
                context.commit('setRankingDates', dates);
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('setRankingDate', null);
                context.commit('setRankingDates', []);
                context.commit('setLoadingError', error);
                return Promise.reject(error);
            }
        },
        setRankingDate(context, data) {
            context.commit('setRankingDate', data);
        },
        setQueryString(context, data) {
            context.commit('setQueryString', data);
        },
    },
};
