import moment from 'moment';

export const RULE_CHANGES_2024 = moment('2024-01-01');

export function getMondayOfWeek(d) {
    d = new Date(d);

    const day = d.getDay();
    const diff = d.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday

    return new Date(d.setDate(diff));
}

export function getWeekNumber(d) {
    d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));

    // Set to nearest Thursday: current date + 4 - current day number
    // Make Sunday's day number 7
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));

    // Get first day of year
    var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
    // Calculate full weeks to nearest Thursday

    var week = Math.ceil(((d - yearStart) / 86400000 + 1) / 7);
    // Return array of year and week number

    return {
        year: d.getUTCFullYear(),
        week,
    };
}

export function getCurrentSeasonYear() {
    let month = moment().month();
    if (month >= 11) {
        return moment().year() + 1;
    }
    return moment().year();
}


