import axios from 'axios';
import flatten from 'flat';
import {DoublesEntry} from '../models/doubles-entry';

export default {
    namespaced: true,

    state: {
        doublesEntries: [],
        loadingDoublesEntries: false,
        loadingError: null,
        savingDoublesEntry: false,
        savingError: null,
        deletingDoublesEntry: false,
        deletingError: null,
        updatingDoublesEntryRanks: false,
        updatingErrorDoublesEntryRanks: null,
    },

    getters: {
        doublesEntries: (state) => state.doublesEntries,
        loadingDoublesEntries: (state) => state.loadingDoublesEntries,
        loadingError: (state) => state.loadingError,
        savingDoublesEntry: (state) => state.savingDoublesEntry,
        savingError: (state) => state.savingError,
        deletingDoublesEntry: (state) => state.deletingDoublesEntry,
        deletingError: (state) => state.deletingError,
        updatingDoublesEntryRanks: (state) => state.updatingDoublesEntryRanks,
        updatingErrorDoublesEntryRanks: (state) => state.updatingErrorDoublesEntryRanks,
    },

    mutations: {
        setDoublesEntries(state, data) {
            state.doublesEntries = data;
        },

        startLoadingDoublesEntries(state) {
            state.loadingDoublesEntries = true;
        },

        stopLoadingDoublesEntries(state) {
            state.loadingDoublesEntries = false;
        },

        setLoadingError(state, error) {
            state.loadingError = error;
        },

        clearLoadingError(state) {
            state.loadingError = null;
        },

        startSavingDoublesEntry(state) {
            state.savingDoublesEntry = true;
        },

        stopSavingDoublesEntry(state) {
            state.savingDoublesEntry = false;
        },

        setSavingError(state, error) {
            state.savingError = error;
        },

        clearSavingError(state) {
            state.savingError = null;
        },

        startDeletingDoublesEntry(state) {
            state.deletingDoublesEntry = true;
        },

        stopDeletingDoublesEntry(state) {
            state.deletingDoublesEntry = false;
        },

        setDeletingError(state, error) {
            state.deletingError = error;
        },

        clearDeletingError(state) {
            state.deletingError = null;
        },

        startUpdatingDoublesEntryRanks(state) {
            state.updatingDoublesEntryRanks = true;
        },

        stopUpdatingDoublesEntryRanks(state) {
            state.updatingDoublesEntryRanks = false;
        },

        setUpdatingErrorDoublesEntryRanks(state, error) {
            state.updatingErrorDoublesEntryRanks = error;
        },

        clearUpdatingErrorDoublesEntryRanks(state) {
            state.updatingErrorDoublesEntryRanks = null;
        },
    },

    actions: {
        async findDoublesEntries(context, tournament) {
            context.commit('startLoadingDoublesEntries');
            try {
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/Edition/${tournament.year}/${tournament.id}/Entry/Doubles`);
                context.commit('setDoublesEntries', result.data);
                context.commit('stopLoadingDoublesEntries');
                return Promise.resolve();
            } catch (error) {
                context.commit('setLoadingError', error);
                context.commit('stopLoadingDoublesEntries');
                return Promise.reject();
            }
        },

        async createDoublesEntry(context, data) {
            context.commit('startSavingDoublesEntry');
            try {
                const result = await axios.post(
                    `${process.env.VUE_APP_API_URL}/Edition/${data.data.season}/${data.data.tournamentId}/Entry/Doubles`,
                    data.data,
                );
                context.commit('stopSavingDoublesEntry');
                // context.commit('setDoublesEntries', new DoublesEntry(flatten(result.data)));
                return Promise.resolve();
            } catch (error) {
                context.commit('stopSavingDoublesEntry');
                context.commit('setSavingError', error);
                return Promise.reject();
            }
        },

        async updateDoublesEntry(context, data) {
            context.commit('startSavingDoublesEntry');
            try {
                const result = await axios.put(`${process.env.VUE_APP_API_URL}/Edition/${data.data.season}/${data.data.tournamentId}/Entry/Doubles`, {
                    oldEntry: data.original,
                    newEntry: data.data,
                });
                context.commit('stopSavingDoublesEntry');
                // context.commit('setDoublesEntry', new DoublesEntry(flatten(result.data)));
                return Promise.resolve();
            } catch (error) {
                context.commit('stopSavingDoublesEntry');
                context.commit('setSavingError', error);
                return Promise.reject();
            }
        },

        async withdrawDoublesEntry(context, data) {
            context.commit('startSavingDoublesEntry');
            try {
                const result = await axios.put(`${process.env.VUE_APP_API_URL}/Edition/${data.data.season}/${data.data.tournamentId}/Entry/Doubles/Withdraw`, {
                    oldEntry: data.original,
                    newEntry: data.data,
                });
                context.commit('stopSavingDoublesEntry');
                await context.dispatch('findDoublesEntries', {id: data.data.tournamentId, year: data.data.season});
                return result.data;
            } catch (error) {
                context.commit('stopSavingDoublesEntry');
                context.commit('setSavingError', error);
                return null;
            }
        },

        async deleteDoublesEntry(context, data) {
            context.commit('startDeletingDoublesEntry');
            try {
                const result = await axios.post(
                    `${process.env.VUE_APP_API_URL}/Edition/${data.data.season}/${data.data.tournamentId}/Entry/Doubles/Delete`,
                    data.data,
                );
                context.commit('stopDeletingDoublesEntry');
                // context.commit('setDoublesEntries', new DoublesEntry(flatten(result.data)));
                return Promise.resolve();
            } catch (error) {
                context.commit('stopDeletingDoublesEntry');
                context.commit('setDeletingError', error);
                return Promise.reject();
            }
        },

        async updateDoublesEntryRanks(context) {
            context.commit('clearUpdatingErrorDoublesEntryRanks');
            context.commit('startUpdatingDoublesEntryRanks');
            try {
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/DoublesEntry/UpdateEntryRanks`);
                context.commit('stopUpdatingDoublesEntryRanks');
                return Promise.resolve(result);
            } catch (error) {
                context.commit('stopUpdatingDoublesEntryRanks');
                context.commit('setUpdatingErrorDoublesEntryRanks', error);
                return Promise.reject(error);
            }
        },
    },
};
