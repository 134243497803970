import axios from 'axios';
import omit from 'lodash/omit';

export default {
    namespaced: true,

    state: {
        allowedGroups: ['Baseline_Banking_Report'],
        data: {},
        items: {},
        loading: false,
        enabled: false,
        options: {
            queryString: null,
            sortBy: 'firstName',
            sortDir: 'ascending',
            page: 0,
            pageSize: 20,
        },
        schema: {
            fields: {
                //id: { type: 'number', displayName: 'ID', sortable: true, group: 'general' },
                playerNumber: { type: 'string', displayName: 'Player Number', sortable: true, group: 'general' },
                firstName: { type: 'string', displayName: 'First Name', sortable: true, group: 'general' },
                lastName: { type: 'string', displayName: 'Last Name', sortable: true, group: 'general' },
                prize: { type: 'string', displayName: 'Career Prize', sortable: false, group: 'general' },
                passportCity: { type: 'string', displayName: 'Passport City', sortable: true, group: 'general' },
                paymentAddress1: {
                    type: 'string',
                    displayName: 'Payment Address 1',
                    sortable: false,
                    group: 'general',
                },
                paymentAddress2: {
                    type: 'string',
                    displayName: 'Payment Address 2',
                    sortable: false,
                    group: 'general',
                },
                paymentCity: { type: 'string', displayName: 'Payment City', sortable: false, group: 'general' },
                paymentEmail: { type: 'string', displayName: 'Payment Email', sortable: false, group: 'general' },
                paymentPreference: {
                    type: 'string',
                    displayName: 'Payment Preference',
                    sortable: false,
                    group: 'general',
                },
                bankName1: { type: 'string', displayName: 'Bank Name 1', sortable: false, group: 'general' },
                bankAccount1: { type: 'string', displayName: 'Bank Account 1', sortable: false, group: 'general' },
                bankAccountName1: {
                    type: 'string',
                    displayName: 'Bank Account Name 1',
                    sortable: false,
                    group: 'general',
                },
                bankAddress1: { type: 'string', displayName: 'Bank Address 1', sortable: false, group: 'general' },
                bankCity1: { type: 'string', displayName: 'Bank City 1', sortable: false, group: 'general' },
                bankPostalCode1: {
                    type: 'string',
                    displayName: 'Bank Postal Code 1',
                    sortable: false,
                    group: 'general',
                },
                bankCountry1: { type: 'string', displayName: 'Bank Country 1', sortable: false, group: 'general' },
                bankCode1: { type: 'string', displayName: 'Bank Code 1', sortable: false, group: 'general' },
                bankIban1: { type: 'string', displayName: 'Bank IBAN 1', sortable: false, group: 'general' },
                bankSwft1: { type: 'string', displayName: 'Bank SWIFT 1', sortable: false, group: 'general' },
                bankOther1: { type: 'string', displayName: 'Bank Other 1', sortable: false, group: 'general' },
                bankName2: { type: 'string', displayName: 'Bank Name 2', sortable: false, group: 'general' },
                bankAccount2: { type: 'string', displayName: 'Bank Account 2', sortable: false, group: 'general' },
                bankAccountName2: {
                    type: 'string',
                    displayName: 'Bank Account Name 2',
                    sortable: false,
                    group: 'general',
                },
                bankAddress2: { type: 'string', displayName: 'Bank Address 2', sortable: false, group: 'general' },
                bankCity2: { type: 'string', displayName: 'Bank City 2', sortable: false, group: 'general' },
                bankPostalCode2: {
                    type: 'string',
                    displayName: 'Bank Postal Code 2',
                    sortable: false,
                    group: 'general',
                },
                bankCountry2: { type: 'string', displayName: 'Bank Country 2', sortable: false, group: 'general' },
                bankCode2: { type: 'string', displayName: 'Bank Code 2', sortable: false, group: 'general' },
                bankIban2: { type: 'string', displayName: 'Bank IBAN 2', sortable: false, group: 'general' },
                bankSwft2: { type: 'string', displayName: 'Bank SWIFT 2', sortable: false, group: 'general' },
                bankOther2: { type: 'string', displayName: 'Bank Other 2', sortable: false, group: 'general' },
                bankName3: { type: 'string', displayName: 'Bank Name 3', sortable: false, group: 'general' },
                bankAccount3: { type: 'string', displayName: 'Bank Account 3', sortable: false, group: 'general' },
                bankAccountName3: {
                    type: 'string',
                    displayName: 'Bank Account Name 3',
                    sortable: false,
                    group: 'general',
                },
                bankAddress3: { type: 'string', displayName: 'Bank Address 3', sortable: false, group: 'general' },
                bankCity3: { type: 'string', displayName: 'Bank City 3', sortable: false, group: 'general' },
                bankPostalCode3: {
                    type: 'string',
                    displayName: 'Bank Postal Code 3',
                    sortable: false,
                    group: 'general',
                },
                bankCountry3: { type: 'string', displayName: 'Bank Country 3', sortable: false, group: 'general' },
                bankCode3: { type: 'string', displayName: 'Bank Code 3', sortable: false, group: 'general' },
                bankIban3: { type: 'string', displayName: 'Bank IBAN 3', sortable: false, group: 'general' },
                bankSwft3: { type: 'string', displayName: 'Bank SWIFT 3', sortable: false, group: 'general' },
                bankOther3: { type: 'string', displayName: 'Bank Other 3', sortable: false, group: 'general' },
                bankName4: { type: 'string', displayName: 'Bank Name 4', sortable: false, group: 'general' },
                bankAccount4: { type: 'string', displayName: 'Bank Account 4', sortable: false, group: 'general' },
                bankAccountName4: {
                    type: 'string',
                    displayName: 'Bank Account Name 4',
                    sortable: false,
                    group: 'general',
                },
                bankAddress4: { type: 'string', displayName: 'Bank Address 4', sortable: false, group: 'general' },
                bankCity4: { type: 'string', displayName: 'Bank City 4', sortable: false, group: 'general' },
                bankPostalCode4: {
                    type: 'string',
                    displayName: 'Bank Postal Code 4',
                    sortable: false,
                    group: 'general',
                },
                bankCountry4: { type: 'string', displayName: 'Bank Country 4', sortable: false, group: 'general' },
                bankCode4: { type: 'string', displayName: 'Bank Code 4', sortable: false, group: 'general' },
                bankIban4: { type: 'string', displayName: 'Bank IBAN 4', sortable: false, group: 'general' },
                bankSwft4: { type: 'string', displayName: 'Bank SWIFT 4', sortable: false, group: 'general' },
                bankOther4: { type: 'string', displayName: 'Bank Other 4', sortable: false, group: 'general' },
            },
        },
    },

    getters: {
        fields(state) {
            return Object.keys(state.schema.fields);
        },

        access(state, getters, rootState) {
            return rootState.auth.groups.some((userGroup) => state.allowedGroups.includes(userGroup.displayName));
        },
    },

    mutations: {
        setData(state, data) {
            state.data = data;
        },

        setItems(state, data) {
            state.items = data;
        },

        setLoading(state, status) {
            state.loading = status === undefined ? !state.loading : status;
        },

        setEnabled(state, enabled) {
            state.enabled = enabled;
        },
    },

    actions: {
        async getItems({ state, commit }) {
            commit('setLoading');

            const response = await axios.get(`${process.env.VUE_APP_API_URL}/BankInformationReport`, {
                params: {
                    queryString: state.options.queryString || '',
                    pageSize: state.options.pageSize || 50,
                    sortBy: state.options.sortBy || 'firstName',
                    sortDir: state.options.sortDir === 'descending' ? -1 : 1,
                    page: state.options.page,
                },
            });

            commit('setData', { ...omit(response.data, 'data') });
            commit('setItems', response.data.data);
            commit('setLoading', false);
        },

        async exportCsv({ state, commit }) {
            commit('setLoading');

            try {
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/BankInformationReport/export/csv`, {
                    params: {
                        queryString: state.options.queryString || '',
                        sortBy: state.options.sortBy || 'LastName',
                        sortDir: state.options.sortDir === 'descending' ? -1 : 1,
                    },
                });
                commit('setLoading');
                return Promise.resolve(result.data);
            } catch (error) {
                commit('setLoading');
                return Promise.reject(error);
            }
        },

        async validateIp({ state, commit }) {
            commit('setLoading');
            const response = await axios.get(`${process.env.VUE_APP_API_URL}/BankInformationReport/AuthorizeData`);
            commit('setEnabled', response.status === 200);
            commit('setLoading', false);
        },
    },
};
