import { isValid, Model } from './model';
import { icoCountries } from '../utils/country-codes';

export class PlayerDevelopment extends Model {
    isValid() {
        return isValid(PlayerDevelopment.schema, this);
    }
    isValidForEdit() {
        return isValid(PlayerDevelopment.schema, this);
    }
    adaptDisplayNames() {
        if (this.data.ppStatus === 'INTRO') {
            PlayerDevelopment.schema.fields.extraTrns.displayName = 'Tournament Merited Increase (MI) - only WTA1000 Mandatory/ GS';
            PlayerDevelopment.schema.fields.extraWc.displayName = 'WC Merited Increase (MI) - only WTA1000 Mandatory/ GS';
        } else if (this.data.ppStatus === 'PROPATH') {
            PlayerDevelopment.schema.fields.extraTrns.displayName = 'Tournament Merited Increase (MI)';
            PlayerDevelopment.schema.fields.extraWc.displayName = 'WC Merited Increase (MI)';
        }
    }
}

PlayerDevelopment.layoutMain = {
    children: [
        {
            children: [{ type: 'spacer' }, 'extraTrns', 'extraWc', { type: 'spacer' },],
        },
    ],
};

PlayerDevelopment.layoutIntro = {
    children: [
        {
            children: ['ph1EligibleDate', 'ph1InviteDate', 'ideadlDate', 'iicmplDate',],
        },
        {
            children: ['ph2PhysDate', 'rkHrs1Date', 'ph2EducDate', 'edType'],
        },
        {
            children: ['gettingStartedDate', 'safe1Date', 'safe2Date', 'onlSzlDate',],
        },
        {
            children: ['gettingStartedScore', 'safe1Score', 'safe2Score', 'onlSzlScore',],
        },
    ],
};

PlayerDevelopment.layoutIntroSub = {
    children: [
        {
            children: ['introComs'],
        },
    ],
};

PlayerDevelopment.layoutProPath = {
    children: [
        {
            children: ['ppEligibleDate', 'ppInviteDate', 'szApprvDate', 'ppCmplDate',],
        },
        {
            children: ['ph2PhysDate', 'rkHrs1Date', 'ph2EducDate', 'edType',],
        },
        {
            children: ['pickPstDate', 'safe1Date', { type: 'spacer' }, 'onlSzlDate',],
        },
        {
            children: ['pickPstScore', 'safe1Score', { type: 'spacer' }, 'onlSzlScore',],
        },
    ],
};

PlayerDevelopment.layoutProPathSub = {
    children: [
        {
            children: ['coachId', { id: 'coachName', width: 2 }, 'coachppCmplDate'],
        },
        {
            children: ['ppComs'],
        },
    ],
};

PlayerDevelopment.layoutRookie = {
    children: [
        {
            children: ['ph2EligibleDate', 'ph2InviteDate', 'rdeadlDate', 'rcmplDate',],
        },
        {
            children: ['rkHrs5Date', 'rkHrs2Date', 'rkHrs3Date', 'aaDate', 'rkHrs4Date',],
        },
        {
            children: ['pickPstDate', 'rookieHoursDate', 'health2Date', 'health3Date',],
        },
        {
            children: ['pickPstScore', 'rookieHoursScore', 'health2Score', 'health3Score',],
        },
    ],
};

PlayerDevelopment.layoutRookieSub = {
    children: [
        {
            children: ['coachId', { id: 'coachName', width: 2 }, 'coachrkCmplDate'],
        },
        {
            children: ['rComs'],
        },
    ],
};

PlayerDevelopment.layoutElite = {
    children: [
        {
            children: ['ph3EligibleDate', 'ph3InviteDate', 'edeadlDate', 'ecmplDate', 'scheduleDate',],
        },
        {
            children: ['mediaDate', 'bjkDate', 'plyrMtgDate', 'financial1Date', 'tiuDate',],
        },
        {
            children: ['historyDate', { type: 'spacer', width: 3 },],
        },
        {
            children: ['historyScore', { type: 'spacer', width: 3 },],
        },
    ],
};

PlayerDevelopment.layoutEliteSub = {
    children: [
        {
            children: ['parentId', { id: 'parentName', width: 2 }, 'parentCmplDate'],
        },
        {
            children: ['eComs'],
        },
    ],
};

PlayerDevelopment.layoutPremier = {
    children: [
        {
            children: ['ph4EligibleDate', 'ph4InviteDate', 'pdeadlDate', 'pcmplDate',],
        },
        {
            children: ['ph4CeoDate', 'financialDate', 'alumnaeDate', 'fitReq',],
        },
    ],
};

PlayerDevelopment.layoutPremierSub = {
    children: [
        {
            children: ['agentId', { id: 'agentName', width: 2 }, 'agentCmplDate',],
        },
        {
            children: ['pComs'],
        },
    ],
};

PlayerDevelopment.layoutArchivedRookieHours = {
    children: [
        {
            children: ['rookieHoursDate', 'ssmOnlDate', 'plrRelOnlDate', 'onSiteOnlDate',],
        },
        {
            children: ['rookieHoursScore', 'ssmOnlScore', 'plrRelOnlScore', 'onSiteOnlScore',],
        },
        {
            children: ['marketOnlDate', 'commOnlDate', 'aaOnlDate', 'tdOnlDate',],
        },
        {
            children: ['marketOnlScore', 'commOnlScore', 'aaOnlScore', 'tdOnlScore',],
        },
    ],
};

PlayerDevelopment.layoutContact = {
    children: [
        {
            children: ['playerNbr', 'nameFirst', 'nameMiddle', 'nameLast'],
        },
        {
            children: ['nameTitle', 'nationality', 'nationalityDual', { type: 'spacer' }],
        },
        {
            children: ['eMailAddress', 'birthDate', 'playerAge', { type: 'spacer' }],
        },
        {
            children: ['addrLine1', 'addrLine2', 'addrLine3', 'addrLine4'],
        },
        {
            children: ['city', 'state', 'nationCode', 'mailCode'],
        },
        {
            children: ['phone1', 'phone1Type', 'phone2', 'phone2Type'],
        },
    ],
};

PlayerDevelopment.layoutAdditional = {
    children: [
        {
            children: ['lastEducDate', 'nextEducDate', { type: 'spacer' }, 'currPhase', 'completeDate'],
        },
    ],
};

PlayerDevelopment.layoutCompletedRequriements = {
    children: [
        {
            children: ['completedRequirements'],
        },
    ],
};

PlayerDevelopment.layoutOutstandingRequriements = {
    children: [
        {
            children: ['outstandingRequirements'],
        },
    ],
};

PlayerDevelopment.layoutOverdueRequriements = {
    children: [
        {
            children: ['overdueRequirements'],
        },
    ],
};

PlayerDevelopment.schema = {
    fields: {
        playerNbr: { type: 'string', displayName: 'Player Id', readonly: true, sortable: true, required: true },
        mentorNbr: { type: 'string', displayName: 'MentorNbr' },
        currPhase: {
            type: 'string', displayName: 'Current Phase', required: true,
            lookup: [
                { id: '1', displayName: 'Intro' },
                { id: '2', displayName: 'Rookie' },
                { id: '3', displayName: 'Elite' },
                { id: '4', displayName: 'Premier' },
            ],
        }, // used
        completeDate: { type: 'date', displayName: 'Player Dev Completed', clearable: true, }, // used
        ph1EligibleDate: { type: 'date', displayName: 'Eligible', clearable: true, columnName: 'Intro Eligible Date', sortable: true }, // used
        ph1InviteDate: { type: 'date', displayName: 'Enroll', clearable: true, }, // used
        ph1CertDate: { type: 'date', displayName: 'Ph1 Cert Date' },
        ph1RegsDate: { type: 'date', displayName: 'Ph1 Regs Date' },
        ph1RelsDate: { type: 'date', displayName: 'Ph1 Rels Date' },
        ph1LetterDate: { type: 'date', displayName: 'Ph1 Letter Date' },
        ph2EligibleDate: { type: 'date', displayName: 'Eligible', clearable: true, columnName: 'Rookie Eligible Date', sortable: true }, // used
        ph2InviteDate: { type: 'date', displayName: 'Enroll', clearable: true, }, // used
        ph2PhysDate: { type: 'date', displayName: '1st Physical', clearable: true, developmentRequirement: true, }, // used
        ph2EducDate: { type: 'date', displayName: 'Proof of Education', clearable: true, developmentRequirement: true, }, // used
        ph2CoachDate: { type: 'date', displayName: 'Ph2 Coach Date' },
        ph2AgentDate: { type: 'date', displayName: 'Ph2 Agent Date' },
        ph2OrientDate: { type: 'date', displayName: 'Ph2 Orient Date' },
        ph2PhotoDate: { type: 'date', displayName: 'Ph2 Photo Date' },
        ph2InfoDate: { type: 'date', displayName: 'Ph2 Info Date' },
        ph2LetterDate: { type: 'date', displayName: 'Ph2 Letter Date' },
        lastPhysDate: { type: 'date', displayName: 'LastPhys Date' },
        nextPhysDate: { type: 'date', displayName: 'NextPhys Date' },
        lastEducDate: { type: 'date', displayName: 'Last Education', clearable: true, }, // used
        nextEducDate: { type: 'date', displayName: 'Next Education', clearable: true, }, // used
        ph3EligibleDate: { type: 'date', displayName: 'Eligible', clearable: true, columnName: 'Elite Eligible Date', sortable: true }, // used
        mentorAssnDate: { type: 'date', displayName: 'MentorAssn Date' },
        evaluationDate: { type: 'date', displayName: 'Evaluation Date' },
        rkHrsStartDate: { type: 'date', displayName: 'RkHrsStart Date' },
        rkHrs1Date: { type: 'date', displayName: 'SS&M Practicals', clearable: true, developmentRequirement: true, }, // used
        rkHrs2Date: { type: 'date', displayName: 'Sups Practical', clearable: true, developmentRequirement: true, }, // used
        rkHrs3Date: { type: 'date', displayName: 'Comms Practical', clearable: true, developmentRequirement: true, }, // used
        rkHrs4Date: { type: 'date', displayName: 'TD Practical', clearable: true, developmentRequirement: true, }, // used
        rkHrs5Date: { type: 'date', displayName: 'Player Relation Practical', clearable: true, developmentRequirement: true, }, // used
        bkGivenDate: { type: 'date', displayName: 'BkGiven Date' },
        bkPassDate: { type: 'date', displayName: 'BkPass Date' },
        bkGrade: { type: 'number', displayName: 'BkGrade' },
        mediaDate: { type: 'date', displayName: 'Media Training', clearable: true, developmentRequirement: true, }, // used
        carDevDate: { type: 'date', displayName: 'CarDev Date' },
        ph3InviteDate: { type: 'date', displayName: 'Enroll', clearable: true, }, // used
        coachOrtDate: { type: 'date', displayName: 'CoachOrt Date' },
        coachEdDate: { type: 'date', displayName: 'CoachEd Date' },
        coachLastDate: { type: 'date', displayName: 'CoachLast Date' },
        coachNextDate: { type: 'date', displayName: 'CoachNext Date' },
        scheduleDate: { type: 'date', displayName: 'Schedule Zone', clearable: true, developmentRequirement: true, }, // used
        scheduleLastDate: { type: 'date', displayName: 'ScheduleLast Date' },
        scheduleNextDate: { type: 'date', displayName: 'ScheduleNext Date' },
        pfsCdDate: { type: 'date', displayName: 'PfsCd Date' },
        pfsManDate: { type: 'date', displayName: 'PfsMan Date' },
        gtkyDate: { type: 'date', displayName: 'Gtky Date' },
        ceoIntDate: { type: 'date', displayName: 'CeoInt Date' },
        parentOrDate: { type: 'date', displayName: 'ParentOr Date' },
        medTrnDate: { type: 'date', displayName: 'MedTrn Date' },
        wtaIntDate: { type: 'date', displayName: 'WtaInt Date' },
        rhrsManDate: { type: 'date', displayName: 'RhrsMan Date' },
        mktgPrmDate: { type: 'date', displayName: 'MktgPrm Date' },
        rkieWalkDate: { type: 'date', displayName: 'RkieWalk Date' },
        ph2EvalDate: { type: 'date', displayName: 'Ph2 Eval Date' },
        edType: {
            type: 'string', displayName: 'Education Type', clearable: true,
            lookup: [
                { id: 1, displayName: 'Min Ed Required' },
                { id: 2, displayName: 'Complete High Sch' },
                { id: 3, displayName: 'Some College/ Unv' },
                { id: 4, displayName: 'Complete College/ Unv ' },
                { id: 5, displayName: 'Graduate Degree ' },
                { id: 0, displayName: 'Unknown' },
            ],
        }, // used
        rComs: { type: 'string', displayName: 'Comments', maxLength: 200, clearable: true, }, // used
        eComs: { type: 'string', displayName: 'Comments', maxLength: 200, clearable: true, }, // used
        pComs: { type: 'string', displayName: 'Comments', maxLength: 200, clearable: true, }, // used
        ph4EligibleDate: { type: 'date', displayName: 'Eligible', clearable: true, columnName: 'Premier Eligible Date', sortable: true }, // used
        ph4InviteDate: { type: 'date', displayName: 'Enroll', clearable: true, }, // used
        ph4AplDate: { type: 'date', displayName: 'Ph4 Apl Date' },
        ph4EducDate: { type: 'date', displayName: 'Ph4 Educ Date' },
        ph4CeoDate: { type: 'date', displayName: 'CEO Meeting', clearable: true, developmentRequirement: true, }, // used
        ph4AgentDate: { type: 'date', displayName: 'Ph4 Agent Date' },
        ph4CoachDate: { type: 'date', displayName: 'Ph4 Coach Date' },
        ph4ParentDate: { type: 'date', displayName: 'Ph4 Parent Date' },
        ph4OtherDate: { type: 'date', displayName: 'Ph4 Other Date' },
        ph4EvalDate: { type: 'date', displayName: 'Ph4 Eval Date' },
        extTrn1: { type: 'string', displayName: 'ExtTrn1' },
        extAuth1: { type: 'number', displayName: 'ExtAuth1' },
        extTrn2: { type: 'string', displayName: 'ExtTrn2' },
        extAuth2: { type: 'number', displayName: 'ExtAuth2' },
        mentEvalDate: { type: 'date', displayName: 'MentEval Date' },
        brdcstDate: { type: 'date', displayName: 'Brdcst Date' },
        rcmplDate: { type: 'date', displayName: 'Completed', clearable: true, }, // used
        ecmplDate: { type: 'date', displayName: 'Completed', clearable: true, }, // used
        pcmplDate: { type: 'date', displayName: 'Completed', clearable: true, }, // used
        extTrn1Date: { type: 'date', displayName: 'ExtTrn1 Date' },
        extTrn2Date: { type: 'date', displayName: 'ExtTrn2 Date' },
        gsOk1: {
            type: 'string', displayName: 'GS Ok 1', clearable: true,
            lookup: [
                { id: 'Y', displayName: 'Yes' },
            ],
        }, // used
        gsOk2: {
            type: 'string', displayName: 'GS Ok 2', clearable: true,
            lookup: [
                { id: 'Y', displayName: 'Yes' },
            ],
        }, // used
        gsOk3: {
            type: 'string', displayName: 'GS Ok 3', clearable: true,
            lookup: [
                { id: 'Y', displayName: 'Yes' },
            ],
        }, // used
        gsOk4: {
            type: 'string', displayName: 'GS Ok 4', clearable: true,
            lookup: [
                { id: 'Y', displayName: 'Yes' },
            ],
        }, // used
        exmptTrn: { type: 'date', displayName: 'ExmptTrn' },
        exmptTrnDate: { type: 'date', displayName: 'ExmptTrn Date' },
        exmptType: { type: 'date', displayName: 'ExmptType' },
        introProDate: { type: 'date', displayName: 'IntroPro Date' },
        safeSecDate: { type: 'date', displayName: 'SafeSec Date' },
        aaDate: { type: 'date', displayName: 'MH&W Practical', clearable: true, developmentRequirement: true, }, // used
        officiatingDate: { type: 'date', displayName: 'Officiating Date' },
        bjkDate: { type: 'date', displayName: 'BJK Day', clearable: true, developmentRequirement: true, }, // used
        mentorActDate: { type: 'date', displayName: 'MentorAct Date' },
        onlRhDate: { type: 'date', displayName: 'OnlRh Date' },
        plyrMtgDate: { type: 'date', displayName: 'Player Meeting', clearable: true, developmentRequirement: true, }, // used
        iicmplDate: { type: 'date', displayName: 'Completed', clearable: true, }, // used
        ideadlDate: { type: 'date', displayName: 'Deadline', clearable: true, }, // used
        rdeadlDate: { type: 'date', displayName: 'Deadline', clearable: true, }, // used
        edeadlDate: { type: 'date', displayName: 'Deadline', clearable: true, }, // used
        pdeadlDate: { type: 'date', displayName: 'Deadline', clearable: true, }, // used
        deadlDate: { type: 'date', displayName: 'Deadl Date' },
        pmOk1: {
            type: 'string', displayName: 'PM Ok 1', clearable: true,
            lookup: [
                { id: 'Y', displayName: 'Yes' },
            ],
        }, // used
        pmOk2: {
            type: 'string', displayName: 'PM Ok 2', clearable: true,
            lookup: [
                { id: 'Y', displayName: 'Yes' },
            ],
        }, // used
        pmOk3: {
            type: 'string', displayName: 'PM Ok 3', clearable: true,
            lookup: [
                { id: 'Y', displayName: 'Yes' },
            ],
        }, // used
        pmOk4: {
            type: 'string', displayName: 'PM Ok 4', clearable: true,
            lookup: [
                { id: 'Y', displayName: 'Yes' },
            ],
        }, // used
        antiDopeDate: { type: 'date', displayName: 'Anti Dope', clearable: true, developmentRequirement: true, }, // used
        health1Date: { type: 'date', displayName: 'Health1 Date' },
        health2Date: { type: 'date', displayName: 'PPP', clearable: true, developmentRequirement: true, }, // used
        health3Date: { type: 'date', displayName: 'RZ', clearable: true, developmentRequirement: true, }, // used
        antiDopeScore: { type: 'number', displayName: 'Anti Dope Score', clearable: true, }, // used
        health1Score: { type: 'number', displayName: 'Health1 Score' },
        health2Score: { type: 'number', displayName: 'PPP Score', clearable: true, }, // used
        health3Score: { type: 'number', displayName: 'RZ Score', clearable: true, }, // used
        coachReq: { type: 'string', displayName: 'CoachReq' },
        agentReq: { type: 'string', displayName: 'AgentReq' },
        parentReq: { type: 'string', displayName: 'ParentReq' },
        fitReq: {
            type: 'string', displayName: 'Fit/ PST', clearable: true, developmentRequirement: true,
            lookup: [
                { id: 'NA', displayName: 'N/A' },
                { id: 'NO', displayName: 'No Attempt' },
                { id: 'IN', displayName: 'Incomplete' },
                { id: 'CM', displayName: 'Complete' },
            ],
        }, // used
        itp1Date: { type: 'date', displayName: 'Intro to Pros', clearable: true, }, // used
        itp2Date: { type: 'date', displayName: 'Itp2 Date' },
        itp3Date: { type: 'date', displayName: 'ProPath', clearable: true, }, // used
        itp1Score: { type: 'number', displayName: 'Intro to Pros Score', clearable: true, }, // used
        itp2Score: { type: 'number', displayName: 'Itp2 Score' },
        itp3Score: { type: 'number', displayName: 'ProPath Score', clearable: true, }, // used
        safe1Date: { type: 'date', displayName: 'Safety', clearable: true, developmentRequirement: true, }, // used
        safe2Date: { type: 'date', displayName: 'Safeguarding', clearable: true, developmentRequirement: true, }, // used
        safe1Score: { type: 'number', displayName: 'Safety Score', clearable: true, }, // used
        safe2Score: { type: 'number', displayName: 'Safeguarding Score', clearable: true, }, // used
        ssmOnlDate: { type: 'date', displayName: 'SS&M', clearable: true, developmentRequirement: true, }, // used
        plrRelOnlDate: { type: 'date', displayName: 'Player Rel', clearable: true, developmentRequirement: true, }, // used
        onSiteOnlDate: { type: 'date', displayName: 'Sups', clearable: true, developmentRequirement: true, }, // used
        tourOpsOnlDate: { type: 'date', displayName: 'TourOpsOnl Date' },
        marketOnlDate: { type: 'date', displayName: 'Marketing', clearable: true, developmentRequirement: true, }, // used
        commOnlDate: { type: 'date', displayName: 'Comm', clearable: true, developmentRequirement: true, }, // used
        aaOnlDate: { type: 'date', displayName: 'AA', clearable: true, developmentRequirement: true, }, // used
        tdOnlDate: { type: 'date', displayName: 'TD', clearable: true, developmentRequirement: true, }, // used
        ssmOnlScore: { type: 'number', displayName: 'SS&M Score', clearable: true, }, // used
        plrRelOnlScore: { type: 'number', displayName: 'Player Rel Score', clearable: true, }, // used
        onSiteOnlScore: { type: 'number', displayName: 'Sups Score', clearable: true, }, // used
        tourOpsOnlScore: { type: 'number', displayName: 'TourOpsOnl Score' },
        marketOnlScore: { type: 'number', displayName: 'Marketing Score', clearable: true, }, // used
        commOnlScore: { type: 'number', displayName: 'Comm Score', clearable: true, }, // used
        aaOnlScore: { type: 'number', displayName: 'AA Score', clearable: true, }, // used
        tdOnlScore: { type: 'number', displayName: 'TD Score', clearable: true, }, // used
        historyDate: { type: 'date', displayName: 'History', clearable: true, developmentRequirement: true, }, // used
        historyScore: { type: 'number', displayName: 'History Score', clearable: true, }, // used
        financialDate: { type: 'date', displayName: 'Financial Behaviour', clearable: true, developmentRequirement: true, }, // used
        cmUser: { type: 'string', displayName: 'CourseMill User', clearable: true, }, // used
        cmPass: { type: 'string', displayName: 'CourseMill Pass', clearable: true, }, // used
        introComs: { type: 'string', displayName: 'Comments', maxLength: 200, clearable: true, }, // used
        onlSzlDate: { type: 'date', displayName: 'Scheduling Lesson', clearable: true, developmentRequirement: true, }, // used
        onlSztDate: { type: 'date', displayName: 'SZ Tool', clearable: true, developmentRequirement: true, }, // used
        parentId: { type: 'player-support', displayName: 'Parent Id', clearable: true, playerSupporterName: 'parentName' }, // used
        agentId: { type: 'player-support', displayName: 'Agent Id', clearable: true, playerSupporterName: 'agentName' }, // used
        coachId: { type: 'player-support', displayName: 'Coach Id', clearable: true, playerSupporterName: 'coachName' }, // used
        pickPstDate: { type: 'date', displayName: 'PST', clearable: true, developmentRequirement: true, }, // used
        pickPstScore: { type: 'number', displayName: 'PST Score', clearable: true, }, // used
        alumnaeDate: { type: 'date', displayName: 'Alumnae/ Network', clearable: true, developmentRequirement: true, }, // used
        onlSzlScore: { type: 'number', displayName: 'Scheduling Lesson Score', clearable: true, }, // used
        onlSztScore: { type: 'number', displayName: 'SZ Tool Score' }, // NOT USED!
        ppEligibleDate: { type: 'date', displayName: 'Eligible', clearable: true, }, // used
        ppInviteDate: { type: 'date', displayName: 'Enroll', clearable: true, }, // used
        ppDeadlDate: { type: 'date', displayName: 'Deadline', clearable: true, }, // used
        ppCmplDate: { type: 'date', displayName: 'Completed', clearable: true, }, // used
        coachrkCmplDate: { type: 'date', displayName: 'Coach Completed', clearable: true, }, // used
        coachppCmplDate: { type: 'date', displayName: 'Coach Completed', clearable: true, }, // used
        agentCmplDate: { type: 'date', displayName: 'Agent Completed', clearable: true, }, // used
        parentCmplDate: { type: 'date', displayName: 'Parent Completed', clearable: true, }, // used
        szApprvDate: { type: 'date', displayName: 'SZ Approval', clearable: true, developmentRequirement: true, }, // used
        ppComs: { type: 'string', displayName: 'Comments', maxLength: 200, clearable: true, }, // used
        extraTrns: { type: 'number', displayName: 'Tournament Merited Increase (MI)', clearable: true }, // used
        bybDate: { type: 'date', displayName: 'B Y B', clearable: true, developmentRequirement: true, }, // used
        financial1Date: { type: 'date', displayName: 'Intro to Financial', clearable: true, developmentRequirement: true, }, // used
        tiuDate: { type: 'date', displayName: 'TIPP Practical', clearable: true, developmentRequirement: true }, // used
        extraWc: { type: 'number', displayName: 'WC Merited Increase (MI)', clearable: true }, // used
        agentName: { type: 'string', displayName: 'Agent', readonly: true }, // used
        coachName: { type: 'string', displayName: 'Coach', readonly: true }, // used
        parentName: { type: 'string', displayName: 'Parent', readonly: true }, // used
        gettingStartedDate: { type: 'date', displayName: 'Getting Started', clearable: true, developmentRequirement: true }, // used
        gettingStartedScore: { type: 'number', displayName: 'Getting Started Score', clearable: true }, // used
        rookieHoursDate: { type: 'date', displayName: 'Online Rookie Hours', clearable: true, developmentRequirement: true }, // used
        rookieHoursScore: { type: 'number', displayName: 'Rookie Hours Score', clearable: true }, // used
        ppStatus: {
            type: 'string', displayName: 'Intro/ Pro Path', maxLength: 16, required: true,
            lookup: [
                { id: 'INTRO', displayName: 'Intro' },
                { id: 'PROPATH', displayName: 'Pro Path' },
            ],
        }, // used



        //
        // CONTACTS
        //
        nameLast: { type: 'string', displayName: 'Last name',  clearable: true, maxLength: 40, readonly: true, },
        nameFirst: { type: 'string', displayName: 'First name',  clearable: true,  maxLength: 15 , readonly: true,},
        nameMiddle: { type: 'string', displayName: 'Middle name',  clearable: true, maxLength: 15 , readonly: true,},
        nameTitle: { type: 'string', displayName: 'Title', clearable: true, maxLength: 6 , readonly: true,},

        //
        // CONTACT_DATA
        //
        addrLine1: { displayName: 'Address line 1', type: 'string', clearable: true, maxLength: 40, readonly: true, },
        addrLine2: { displayName: 'Address line 2', type: 'string', clearable: true, maxLength: 40, readonly: true, },
        addrLine3: { displayName: 'Address line 3', type: 'string', clearable: true, maxLength: 40, readonly: true, },
        addrLine4: { displayName: 'Address line 4', type: 'string', clearable: true, maxLength: 40, readonly: true, },
        phone1: { type: 'string', displayName: 'Phone 1', maxLength: 15, clearable: true, readonly: true, },
        phone1Type: { type: 'string', displayName: 'Phone 1 Type', maxLength: 15, clearable: true, readonly: true, },
        phone2: { type: 'string', displayName: 'Phone 2', maxLength: 15, clearable: true, readonly: true, },
        phone2Type: { type: 'string', displayName: 'Phone 2 Type', maxLength: 15, clearable: true, readonly: true, },
        mailCode: { type: 'string', displayName: 'Mail Code', maxLength: 15, clearable: true, readonly: true, },
        eMailAddress: { displayName: 'Email address', type: 'string', group: 'general', clearable: true, maxLength: 40, readonly: true, },
        nationCode: { type: 'string', displayName: 'Nation code', clearable: true, maxLength: 4, readonly: true, },
        city: { type: 'string', displayName: 'City', group: 'general', clearable: true, maxLength: 40, readonly: true, },
        state: { type: 'string', displayName: 'State', clearable: true, maxLength: 2, readonly: true, },

        //
        // PLAYER_MASTER
        //
        birthDate: { type: 'date', displayName: 'Birthdate', sortable: true, clearable: true , readonly: true, },
        nationality: { displayName: 'Nationality', type: 'nationality', lookup: Object.keys(icoCountries), sortable: true, clearable: true , readonly: true, },
        nationalityDual: { displayName: '2nd Nationality', type: 'nationality', lookup: Object.keys(icoCountries), clearable: true , readonly: true, },

        completedRequirements: { displayName: 'Completed Requirements', type: 'string-array', displayType: 'player-development', tagType: 'success', alertType: 'success', readonly: true },
        outstandingRequirements: { displayName: 'Outstanding Requirements', type: 'string-array', displayType: 'player-development', tagType: 'warning', alertType: 'warning', readonly: true },
        overdueRequirements: { displayName: 'Overdue Requirements', type: 'string-array', displayType: 'player-development', tagType: 'danger', alertType: 'error', readonly: true },

        playerAge: { type: 'number', displayName: 'Age',  sortable: true, clearable: true , readonly: true,}, // used
        fullName: { type: 'string', displayName: 'Name', sortable: true, readonly: true, },
    },
};