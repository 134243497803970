<template>
  <div>
    <div class="flex-row mt-16 mb-32">
      <h2>Payment Report</h2>
      <span class="spacer"></span>
      <!--<el-checkbox v-model="options.owesMoney">Owes Money</el-checkbox>-->
      <span class="p-16"></span>
      <el-tooltip class="item" effect="dark" content="Select Role" placement="top">
        <el-select v-model="options.role" placeholder="Select Role">
          <el-option
              v-for="item in schema.fields.role.lookup"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </el-tooltip>
      <el-tooltip class="item" effect="dark" content="Payment Date (start/end)" placement="top">
        <el-date-picker
            v-model="options.fromDate"
            type="daterange"
            range-separator="To"
            start-placeholder="Payment Date Start"
            end-placeholder="Payment Date End"
        ></el-date-picker>
      </el-tooltip>
      <el-button slot="append" icon="el-icon-search" @click="resetPage"></el-button>
      <el-button @click="exportCsvClick" plain class="ml-8" size="mini">Export CSV</el-button>
      <el-button @click="exportPdfClick" plain class="ml-8" size="mini">Export PDF</el-button>
    </div>
    <ErrorDisplay :requestError="this.loadingError" @closed="clearError"/>
    <div v-loading="loadingPaymentData">
      <DataTable
          :items="paymentData.data"
          :schema="schema"
          :fields="['userId', 'lastName', 'firstName', 'paymentType', 'paymentDate', 'memberCost', 'insurancePaymentDate', 'insuranceCost', 'insuranceType', ]"
          :sortField="options.sortBy"
          :sortDir="options.sortDir"
          @header-clicked="setSort"
          :autoSort="false"
      />

      <Paginator
          v-if="paymentData.data"
          :data="paymentData"
          :elementsPerPage="options.pageSize"
          @prev-page="prevPage"
          @next-page="nextPage"
          @set-page-size="setPageSize"
      />
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {retrieveDtos} from "@/utils/crud-confirm";
import {PaymentReports} from "@/models/payment-reports";
import moment from "moment/moment";
import FileSaver from "file-saver";

export default {
  data() {
    return {
      schema: PaymentReports.schema,
      nationality: null,
      activeTab:  this.$route.query.activeTab || 'paymentData',
    };
  },
  mounted() {
    this.options.role = 'P';
    this.search();
  },
  computed: mapGetters('paymentReports', ['paymentData', 'loadingPaymentData', 'options', 'loadingError']),
  methods: {
    ...mapActions('paymentReports', ['searchPaymentData', 'exportCsv', 'exportPdf']),
    exportCsvClick() {
      this.exportCsv(this.options)
          .then((result) => {
            const file = new File([result], `payment_reports_${moment().format('DD_MM_YYYY HH_mm_SS')}.csv`, {type: 'text/csv'});
            FileSaver.saveAs(file);
          })
          .catch((error) => {
            this.$notify({
              title: 'CSV export',
              message: 'CSV export failed',
              type: 'error',
              duration: 5000,
            });
          });
    },
    exportPdfClick() {
      this.requestError = null;
      let filename = `payment_reports_${moment().format('DD_MM_YYYY HH_mm_SS')}.pdf`;
      this.exportPdf(this.options)
          .then((result) => {
            const file = new Blob([result], {type: 'application/pdf'});
            saveAs(file, filename);
            this.$notify({
              title: 'Payment Report: PDF download successful',
              message: filename + ' downloaded',
              type: 'success',
              duration: 5000,
            });
          })
          .catch((error) => {
            this.requestError = error;
            this.$notify({
              title: 'Payment Report: : PDF download failed',
              message: filename + ': ',
              type: 'error',
              duration: 7000,
            });
          });
    },
    search() {
      let params = {
        self: this,
        fRetrieve: this.searchPaymentData,
        options: this.options,
      };

      retrieveDtos(params);
    },
    resetSearch() {
      this.options.queryString = null;
      this.search();
    },
    setPageSize(size) {
      this.options.pageSize = size;
      this.search();
    },
    resetPage() {
      this.options.requestDateFrom = this.options.fromDate !== null ? this.options.fromDate[0] : null;
      this.options.requestDateTo = this.options.fromDate !== null ? new Date(new Date(this.options.fromDate[1]).setDate(this.options.fromDate[1].getDate() + 1 )) : null;
      this.options.page = 0;
      this.search();
    },
    reset() {
      this.options.requestDateFrom = null;
      this.options.requestDateTo = null;
      this.options.fromDate = null;
      this.options.role = null;
      this.options.queryString = null;
      this.options.owesMoney = false;
      this.options.page = 0;
    },
    setSort(event) {
      this.options.sortBy = event.property;
      this.options.sortDir = event.order;
      this.search();
    },
    prevPage() {
      this.options.page--;
      this.search();
    },
    nextPage() {
      this.options.page++;
      this.search();
    },
    clearError() {
      this.loadingError = null;
    },
  },
}
</script>

<style scoped>

</style>