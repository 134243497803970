import axios from 'axios';
import { buildParams } from '../../utils/query-param-builder';
import moment from 'moment';

export default {
    namespaced: true,

    state: {
        players: [],
        tournament: null,
        loadingPlayers: false,
        loadingTournament: false,
        loadingPlayersError: null,
        loadingTournamentError: null,
        options: {
            queryString: null,
            sortBy: null,
            sortDir: null,
            page: 0,
            pageSize: process.env.VUE_APP_PAGE_SIZE,
        },
    },

    getters: {
        players: (state) => state.players,
        tournament: (state) => state.tournament,
        loadingPlayers: (state) => state.loadingPlayers,
        loadingTournament: (state) => state.loadingTournament,
        loadingPlayersError: (state) => state.loadingPlayersError,
        loadingTournamentError: (state) => state.loadingTournamentError,
        options: (state) => state.options,
    },

    mutations: {
        setPlayers(state, data) {
            state.players = data;
            state.loadingPlayersError = null;
        },

        setTournament(state, data) {
            state.tournament = data;
            state.loadingTournamentError = null;
        },

        startLoadingPlayers(state) {
            state.loadingPlayers = true;
        },

        stopLoadingPlayers(state) {
            state.loadingPlayers = false;
        },

        startLoadingTournament(state) {
            state.loadingTournament = true;
        },

        stopLoadingTournament(state) {
            state.loadingTournament = false;
        },

        setLoadingPlayersError(state, error) {
            state.loadingPlayersError = error;
            state.players = [];
        },

        setLoadingTournamentError(state, error) {
            state.loadingTournamentError = error;
            state.players = null;
        },
    },

    actions: {
        async retrieveTournament(context, options = {}) {
            context.commit('startLoadingTournament');
            let season = options.season;
            let tournamentId = options.tournamentId;
            try {
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/Edition/${season}/${tournamentId}`);
                context.commit('setTournament', result.data);
                context.commit('stopLoadingTournament');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('stopLoadingTournament');
                context.commit('setLoadingTournamentError', error);
                return Promise.reject(error);
            }
        },
        async retrievePlayers(context, options = {}) {
            context.commit('startLoadingPlayers');
            let params = buildParams(options);
            let season = options.season;
            let tournamentId = options.tournamentId;
            try {
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/RequiredTournaments/PlayerList/${season}/${tournamentId}`, { params });
                context.commit('setPlayers', result.data);
                context.commit('stopLoadingPlayers');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('stopLoadingPlayers');
                context.commit('setLoadingPlayersError', error);
                return Promise.reject(error);
            }
        },
        async insertPlayersByMainDraw(context, options = {}) {
            context.commit('startLoadingPlayers');
            let season = options.season;
            let tournamentId = options.tournamentId;
            try {
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/RequiredTournaments/InsertPlayersByMainDraw/${season}/${tournamentId}`);
                context.commit('stopLoadingPlayers');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('stopLoadingPlayers');
                context.commit('setLoadingPlayersError', error);
                return Promise.reject(error);
            }
        },
        async insertPlayersByRank(context, options = {}) {
            context.commit('startLoadingPlayers');
            let season = options.season;
            let tournamentId = options.tournamentId;
            let rankDate = moment(options.rankDate).format('YYYY-MM-DD');
            let cutoffRank = options.cutoffRank;
            try {
                const result = await axios.get(
                    `${process.env.VUE_APP_API_URL}/RequiredTournaments/InsertPlayersByRank/${season}/${tournamentId}/${rankDate}/${cutoffRank}`,
                );
                context.commit('stopLoadingPlayers');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('stopLoadingPlayers');
                context.commit('setLoadingPlayersError', error);
                return Promise.reject(error);
            }
        },
        async insertPlayerByHand(context, dto = {}) {
            context.commit('startLoadingPlayers');
            try {
                const result = await axios.post(`${process.env.VUE_APP_API_URL}/RequiredTournaments/`, dto);
                context.commit('stopLoadingPlayers');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('stopLoadingPlayers');
                context.commit('setLoadingPlayersError', error);
                return Promise.reject(error);
            }
        },
        async updateRequiredTournament(context, dto = {}) {
            context.commit('startLoadingPlayers');
            try {
                const result = await axios.put(`${process.env.VUE_APP_API_URL}/RequiredTournaments/`, dto);
                context.commit('stopLoadingPlayers');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('stopLoadingPlayers');
                context.commit('setLoadingPlayersError', error);
                return Promise.reject(error);
            }
        },
        async deletePlayerByTournament(context, options = {}) {
            context.commit('startLoadingPlayers');
            let season = options.data.season;
            let tournamentId = options.data.tournamentId;
            let playerId = options.data.playerId;
            try {
                const result = await axios.delete(`${process.env.VUE_APP_API_URL}/RequiredTournaments/${season}/${tournamentId}/${playerId}`);
                context.commit('stopLoadingPlayers');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('stopLoadingPlayers');
                context.commit('setLoadingPlayersError', error);
                return Promise.reject(error);
            }
        },
    },
};
