import axios from 'axios';

export default {
    namespaced: true,

    state: {
        loading: false,
        loadingError: null,
        runningWeeklyDataExport: false,
        runningTournamentDataExport: false,
        runningPlayerBioDataExport: false,
        runningMessagingRecipientsDataExport: false,
        runningPlayersDataExport: false,
        progress: 0,
    },

    getters: {
        dataExporterLoadingError: (state) => state.loadingError,
        runningWeeklyDataExport: (state) => state.runningWeeklyDataExport,
        runningTournamentDataExport: (state) => state.runningTournamentDataExport,
        runningPlayerBioDataExport: (state) => state.runningPlayerBioDataExport,
        runningMessagingRecipientsDataExport: (state) => state.runningMessagingRecipientsDataExport,
        runningPlayersDataExport: (state) => state.runningPlayersDataExport,
        dataExportProgress: (state) => state.progress,
    },

    mutations: {
        startLoading(state) {
            state.loading = true;
        },
        stopLoading(state) {
            state.loading = false;
        },
        setLoadingError(state, error) {
            state.loadingError = error;
        },
        clearLoadingError(state) {
            state.loadingError = null;
        },
        startWeeklyDataExport(state) {
            state.runningWeeklyDataExport = true;
        },
        stopWeeklyDataExport(state) {
            state.runningWeeklyDataExport = false;
        },
        startTournamentDataExport(state) {
            state.runningTournamentDataExport = true;
        },
        stopTournamentDataExport(state) {
            state.runningTournamentDataExport = false;
        },
        startPlayerBioDataExport(state) {
            state.runningPlayerBioDataExport = true;
        },
        stopPlayerBioDataExport(state) {
            state.runningPlayerBioDataExport = false;
        },
        startMessagingRecipientsDataExport(state) {
            state.runningMessagingRecipientsDataExport = true;
        },
        stopMessagingRecipientsDataExport(state) {
            state.runningMessagingRecipientsDataExport = false;
        },
        startPlayersDataExport(state) {
            state.runningPlayersDataExport = true;
        },
        stopPlayersDataExport(state) {
            state.runningPlayersDataExport = false;
        },
        setProgress(state, progress) {
            state.progress = progress;
        },
        clearProgress(state) {
            state.progress = 0;
        },
    },

    actions: {
        async weeklyDataExport(context, useTestForWeeklyDataExport) {
            context.commit('startLoading');
            context.commit('startWeeklyDataExport');
            context.commit('clearProgress');
            try {
                var result = await axios.get(`${process.env.VUE_APP_API_URL}/DataExporter/WeeklyDataExport/Run/${useTestForWeeklyDataExport}`);
                context.commit('setLoadingError', null);
                context.commit('stopLoading');
                context.commit('stopWeeklyDataExport');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('setLoadingError', error);
                context.commit('stopLoading');
                context.commit('stopWeeklyDataExport');
                return Promise.reject(error);
            }
        },
        async tournamentDataExport(context, useTestForWeeklyDataExport) {
            context.commit('startLoading');
            context.commit('startTournamentDataExport');
            context.commit('clearProgress');
            try {
                var result = await axios.get(`${process.env.VUE_APP_API_URL}/DataExporter/TournamentDataExport/Run/${useTestForWeeklyDataExport}`);
                context.commit('setLoadingError', null);
                context.commit('stopLoading');
                context.commit('stopTournamentDataExport');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('setLoadingError', error);
                context.commit('stopLoading');
                context.commit('stopTournamentDataExport');
                return Promise.reject(error);
            }
        },
        async playerBioDataExport(context, useTestForWeeklyDataExport) {
            context.commit('startLoading');
            context.commit('startPlayerBioDataExport');
            context.commit('clearProgress');
            try {
                var result = await axios.get(`${process.env.VUE_APP_API_URL}/DataExporter/PlayerBioDataExport/Run/${useTestForWeeklyDataExport}`);
                context.commit('setLoadingError', null);
                context.commit('stopLoading');
                context.commit('stopPlayerBioDataExport');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('setLoadingError', error);
                context.commit('stopLoading');
                context.commit('stopPlayerBioDataExport');
                return Promise.reject(error);
            }
        },
        async messagingRecipientsDataExport(context, useTestForWeeklyDataExport) {
            context.commit('startLoading');
            context.commit('startMessagingRecipientsDataExport');
            context.commit('clearProgress');
            try {
                var result = await axios.get(`${process.env.VUE_APP_API_URL}/DataExporter/MessagingRecipientsDataExport/Run/${useTestForWeeklyDataExport}`);
                context.commit('setLoadingError', null);
                context.commit('stopLoading');
                context.commit('stopMessagingRecipientsDataExport');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('setLoadingError', error);
                context.commit('stopLoading');
                context.commit('stopMessagingRecipientsDataExport');
                return Promise.reject(error);
            }
        },
        async playersDataExport(context, useTestForWeeklyDataExport) {
            context.commit('startLoading');
            context.commit('startPlayersDataExport');
            context.commit('clearProgress');
            try {
                var result = await axios.get(`${process.env.VUE_APP_API_URL}/DataExporter/PlayersDataExport/Run/${useTestForWeeklyDataExport}`);
                context.commit('setLoadingError', null);
                context.commit('stopLoading');
                context.commit('stopPlayersDataExport');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('setLoadingError', error);
                context.commit('stopLoading');
                context.commit('stopPlayersDataExport');
                return Promise.reject(error);
            }
        },
    },
};