<template>
    <div class="flex-row">
        <el-select
                :clearable="clearable"
                :disabled="disabled"
                :loading="loading"
                :remote-method="remoteMethod"
                :v-model="model"
                :value="value"
                filterable
                placeholder="Search by name or ID"
                remote
                reserve-keyword
                @change="onChange"
                @clear="onClear"
                @focus="onFocus"
        >
            <el-option v-for="(item, i) in options" :key="i" :label="item.fullName" :value="item.id"></el-option>
        </el-select>
    </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment';

export default {
    props: ['model', 'disabled', 'clearable', 'ageAt', 'rankAt', 'suppressPlayerForSearch', 'value'],
    data() {
        return {
            options: [],
            loading: false,
        };
    },
    mounted() {
        if (this.value) {
            this.remoteMethod(this.value);
        }
    },
    methods: {
        onChange(item) {
            let p = this.options.find((p) => p.id === item);
            this.$emit('player-selected', p);
            this.$emit('input', item);
            this.$emit('change', item);
        },
        remoteMethod(queryString) {
            if (typeof queryString === 'string') {
                if (queryString.toUpperCase() === 'BYE') {
                    // "BYE" used as player_nbr and  team_name in tourn_rounds_m
                    this.options = [{ id: 'BYE', fullName: 'BYE', firstName: 'BYE', lastName: 'BYE' }];
                } else if (queryString.length >= 3) {
                    this.loading = true;
                    let ageAt = this.ageAt ? '&ageAt=' + moment(this.ageAt).format('YYYY-MM-DD') : '';
                    let rankAt = this.rankAt ? '&rankAt=' + moment(this.rankAt).format('YYYY-MM-DD') : '';
                    axios
                            .get(`${process.env.VUE_APP_API_URL}/Player/Find?recordType=PL&queryString=${queryString || ''}${ageAt}${rankAt}`)
                            .then((result) => {
                                this.options = this.suppressPlayerForSearch ? result.data.data.filter((player) => player.id !== this.suppressPlayerForSearch) : result.data.data;
                                this.loading = false;
                            })
                            .catch((error) => {
                                this.loading = false;
                            });
                }
            } else {
                this.options = [];
            }
        },
        onClear() {
            this.$emit('clear');
        },
        onFocus() {
            this.options = [];
        },
    },
};
</script>

<style></style>
