import axios from 'axios';
import { PlayerSupport } from '../models/player-support';

export default {
    namespaced: true,

    state: {
        playerSupport: new PlayerSupport(),
        loadingPlayerSupporter: false,
        loadingError: null,
        savingPlayerSupporter: false,
        savingError: null,
    },

    getters: {
        playerSupport: (state) => state.playerSupport,
        loadingPlayerSupporter: (state) => state.loadingPlayerSupporter,
        loadingError: (state) => state.loadingError,
        savingPlayerSupporter: (state) => state.savingPlayerSupporter,
        savingError: (state) => state.savingError,
    },

    mutations: {
        setPlayerSupport(state, data) {
            state.playerSupport = data;
        },

        startLoadingPlayerSupporter(state) {
            state.loadingPlayerSupporter = true;
        },

        stopLoadingPlayerSupporter(state) {
            state.loadingPlayerSupporter = false;
        },

        setLoadingError(state, error) {
            state.loadingError = error;
        },

        clearLoadingError(state) {
            state.loadingError = null;
        },

        startSavingPlayerSupporter(state) {
            state.savingPlayerSupporter = true;
        },

        stopSavingPlayerSupporter(state) {
            state.savingPlayerSupporter = false;
        },

        setSavingError(state, error) {
            state.savingError = error;
        },

        clearSavingError(state) {
            state.savingError = null;
        },
    },

    actions: {
        async findPlayerSupport(context, contactId) {
            context.commit('startLoadingPlayerSupporter');
            try {
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/PlayerSupport/${contactId}`);
                context.commit('setPlayerSupport', new PlayerSupport(result.data));
                context.commit('clearLoadingError');
                context.commit('stopLoadingPlayerSupporter');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('setLoadingError', error);
                context.commit('stopLoadingPlayerSupporter');
                return Promise.reject(error);
            }
        },

        async createPlayerSupport(context, playerSupport) {
            context.commit('startSavingPlayerSupporter');
            try {
                const result = await axios.post(`${process.env.VUE_APP_API_URL}/PlayerSupport`, playerSupport.data);
                context.commit('setPlayerSupport', new PlayerSupport(result.data));
                context.commit('clearLoadingError');
                context.commit('stopSavingPlayerSupporter');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('setLoadingError', error);
                context.commit('stopSavingPlayerSupporter');
                return Promise.reject(error);
            }
        },

        async savePlayerSupporter(context) {
            context.commit('startSavingPlayerSupporter');
            try {
                const result = await axios.put(`${process.env.VUE_APP_API_URL}/PlayerSupport`, context.state.playerSupport.data);
                context.commit('clearSavingError');
                context.commit('stopSavingPlayerSupporter');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('setSavingError', error);
                context.commit('stopSavingPlayerSupporter');
                return Promise.reject(error);
            }
        },

        async deletePlayerSupporter(context, contactId) {
            context.commit('startSavingPlayerSupporter');
            try {
                await axios.delete(`${process.env.VUE_APP_API_URL}/PlayerSupport/${contactId}`);
                context.commit('clearSavingError');
                context.commit('stopSavingPlayerSupporter');
                return Promise.resolve();
            } catch (error) {
                context.commit('setSavingError', error);
                context.commit('stopSavingPlayerSupporter');
                return Promise.reject(error);
            }
        },
    },
};
