import axios from 'axios';
import Vue from "vue";

export default {
    namespaced: true,

    state: {
        shadowRankingPoints: {},
        loadingShadowRankingPoints: false,
        loadingErrorShadowRankingsPoints: null,
    },

    getters: {
        shadowRankingPoints: (state) => state.shadowRankingPoints,
        loadingShadowRankingPoints: (state) => state.loadingShadowRankingPoints,
        loadingErrorShadowRankingsPoints: (state) => state.loadingErrorShadowRankingsPoints,
    },

    mutations: {
        setShadowRankingPoints(state, data) {
            // Below statement stores points correctly, but changes are not seen by Components - using Vue.set() for this
            // state.shadowRankingPoints[data.name] = data.data;
            Vue.set(state.shadowRankingPoints, data.shadowRankId, data.data);
        },

        clearShadowRankingPoints(state) {
            state.shadowRankingPoints = {};
        },

        startLoadingShadowRankingPoints(state) {
            state.loadingShadowRankingPoints = true;
        },

        stopLoadingShadowRankingPoints(state) {
            state.loadingShadowRankingPoints = false;
        },

        setLoadingErrorShadowRankingsPoints(state, error) {
            state.loadingErrorShadowRankingsPoints = error;
        },

        clearLoadingErrorShadowRankingsPoints(state) {
            state.loadingErrorShadowRankingsPoints = null;
        },

    },

    actions: {
        async findShadowRankingPoints(context, shadowRanking) {
            context.commit('startLoadingShadowRankingPoints');
            context.commit('clearLoadingErrorShadowRankingsPoints');
            try {
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/ShadowRankingPoint/${shadowRanking.shadowRankId}`);
                let data = {
                    shadowRankId: shadowRanking.shadowRankId,
                    data: result.data,
                }
                context.commit('setShadowRankingPoints', data);
                context.commit('stopLoadingShadowRankingPoints');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('stopLoadingShadowRankingPoints');
                context.commit('setLoadingErrorShadowRankingsPoints', error);
                return Promise.reject(error);
            }
        },

        async updateShadowRankingPoints(context, data) {
            context.commit('startLoadingShadowRankingPoints');
            context.commit('clearLoadingErrorShadowRankingsPoints');
            try {
                const result = await axios.put(`${process.env.VUE_APP_API_URL}/ShadowRankingPoint`, data.data);
                context.commit('stopLoadingShadowRankingPoints');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('stopLoadingShadowRankingPoints');
                context.commit('setLoadingErrorShadowRankingsPoints', error);
                return Promise.reject(error);
            }
        },

        async deleteShadowRankingPoints(context, options = {}) {
            context.commit('startLoadingShadowRankingPoints');
            context.commit('clearLoadingErrorShadowRankingsPoints');
            try {
                const shadowRankId = options.data.shadowRankId;
                const pointId = options.data.pointId;
                const result = await axios.delete(`${process.env.VUE_APP_API_URL}/ShadowRankingPoint/${shadowRankId}/${pointId}`);
                context.commit('stopLoadingShadowRankingPoints');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('stopLoadingShadowRankingPoints');
                context.commit('setLoadingErrorShadowRankingsPoints', error);
                return Promise.reject(error);
            }
        },

        clearShadowRankingPoints(context) {
            context.commit('clearShadowRankingPoints');
        }
    }
}