import axios from 'axios';

export default {
    namespaced: true,

    state: {
        itfPlayerLinks: {},
        loadingItfPlayerLinks: false,
        loadingErrorItfPlayerLinks: null,
    },

    getters: {
        itfPlayerLinks: (state) => state.itfPlayerLinks,
        loadingItfPlayerLinks: (state) => state.loadingItfPlayerLinks,
        loadingErrorItfPlayerLinks: (state) => state.loadingErrorItfPlayerLinks,
    },

    mutations: {
        setItfPlayerLinks(state, data) {
            state.itfPlayerLinks = data;
        },
        startLoadingItfPlayerLinks(state) {
            state.loadingItfPlayerLinks = true;
        },
        stopLoadingItfPlayerLinks(state) {
            state.loadingItfPlayerLinks = false;
        },
        setLoadingErrorItfPlayerLinks(state, data) {
            state.loadingErrorItfPlayerLinks = data;
        },
        clearLoadingErrorItfPlayerLinks(state) {
            state.loadingErrorItfPlayerLinks = null;
        },
    },

    actions: {
        async retrieveItfPlayerLinks(context, options) {
            context.commit('startLoadingItfPlayerLinks');
            context.commit('clearLoadingErrorItfPlayerLinks');
            try {
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/ItfPlayerLink/Retrieve`, {
                    params: {
                        queryString: options.queryString || '',
                        sortBy: options.sortBy || 'player_nbr',
                        sortDir: options.sortDir === 'descending' ? -1 : 1,
                        pageSize: options.pageSize || 20,
                        page: options.page,
                        onlyInsecureItfPlayerLinks: options.onlyInsecureItfPlayerLinks,
                    }
                });
                context.commit('setItfPlayerLinks', result.data);
                context.commit('stopLoadingItfPlayerLinks');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('setLoadingErrorItfPlayerLinks', error);
                context.commit('stopLoadingItfPlayerLinks');
                return Promise.reject(error);
            }
        },

        async updateItfPlayerLink(context, itfPlayerLink) {
            context.commit('startLoadingItfPlayerLinks');
            context.commit('clearLoadingErrorItfPlayerLinks');
            try {
                const result = await axios.put(`${process.env.VUE_APP_API_URL}/ItfPlayerLink/Update`, itfPlayerLink);
                context.commit('stopLoadingItfPlayerLinks');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('setLoadingErrorItfPlayerLinks', error);
                context.commit('stopLoadingItfPlayerLinks');
                return Promise.reject(error);
            }
        },

        async addOrUpdateItfPlayerLink(context, itfPlayerLink) {
            context.commit('startLoadingItfPlayerLinks');
            context.commit('clearLoadingErrorItfPlayerLinks');
            try {
                const result = await axios.put(`${process.env.VUE_APP_API_URL}/ItfPlayerLink/AddOrUpdate`, itfPlayerLink);
                context.commit('stopLoadingItfPlayerLinks');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('setLoadingErrorItfPlayerLinks', error);
                context.commit('stopLoadingItfPlayerLinks');
                return Promise.reject(error);
            }
        },

        async mapImportedToExitingPlayer(context, options) {
            console.log(options);
            context.commit('startLoadingItfPlayerLinks');
            context.commit('clearLoadingErrorItfPlayerLinks');
            try {
                const result = await axios.put(`${process.env.VUE_APP_API_URL}/ItfPlayerLink/MapImportedToExitingPlayer`, options);
                context.commit('stopLoadingItfPlayerLinks');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('setLoadingErrorItfPlayerLinks', error);
                context.commit('stopLoadingItfPlayerLinks');
                return Promise.reject(error);
            }
        },

        async deleteItfPlayerLink(context, playerNbr) {
            context.commit('startLoadingItfPlayerLinks');
            context.commit('clearLoadingErrorItfPlayerLinks');
            try {
                const result = await axios.delete(`${process.env.VUE_APP_API_URL}/ItfPlayerLink/Delete/${playerNbr}`);
                context.commit('stopLoadingItfPlayerLinks');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('setLoadingErrorItfPlayerLinks', error);
                context.commit('stopLoadingItfPlayerLinks');
                return Promise.reject(error);
            }
        }
    }
}