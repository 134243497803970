<template>
    <div>
        <DialogAddItem
            :visibleSync="addDialogVisible"
            dtoName="WTA Tour Year"
            :selected="selectedWtaTourYear"
            :schema="schemaAdd"
            :layout="layoutAdd"
            @save="saveChanges"
            @change="onChange"
            @close="addDialogVisible = false"
        />

        <DialogEditItem
            :visibleSync="editDialogVisible"
            dtoName="WTA Tour Year"
            :selected="selectedWtaTourYear"
            :schema="schema"
            :layout="layout"
            @save="saveChanges"
            @change="onChange"
            @delete="deleteItem"
            @close="editDialogVisible = false"
        />

        <div v-if="!isBaselineReader" class="flex-row mb-32">
            <h2><i class="el-icon-date mr-16"></i>WTA Tour Years</h2>

            <span class="spacer"></span>

            <el-button @click="addWtaTourYear" plain type="primary" size="mini" class="ml-16">Add WTA Tour Year</el-button>
        </div>

        <ErrorDisplay :requestError="this.wtaTourYearsLoadingError" @closed="clearError" />

        <DetailedInformation screenName="WTA Tour Years" :informationArray="this.informationArray()" />

        <div v-loading="loadingWtaTourYears">
            <DataTable
                :items="wtaTourYears.data"
                :schema="schema"
                :fields="['tourYear', 'startDate', 'endDate', 'isValid']"
                @item-clicked="selectWtaTourYear"
                :autoSort="false"
            />

            <Paginator
                v-if="wtaTourYears.data"
                :data="wtaTourYears"
                :elementsPerPage="wtaTourYearsOptions.pageSize"
                @prev-page="prevPage"
                @next-page="nextPage"
                @set-page-size="setPageSize"
            />
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { WtaTourYear } from '../models/wta-tour-year';
import { retrieveDtos, deleteConfirmDto, createConfirmDto, updateConfirmDto } from '../utils/crud-confirm';
import { isBaselineReader } from '../services/authGroups';

export default {
    data() {
        return {
            schema: WtaTourYear.schema,
            schemaAdd: WtaTourYear.schemaAdd,
            layout: WtaTourYear.layout,
            layoutAdd: WtaTourYear.layoutAdd,
            editDialogVisible: false,
            addDialogVisible: false,
            mode: 'add',
            selectedWtaTourYear: null,
            isBaselineReader: isBaselineReader(),
        };
    },
    mounted() {
        this.refresh();
    },
    computed: {
        ...mapGetters('wtaTourYears', ['wtaTourYears', 'loadingWtaTourYears', 'wtaTourYearsLoadingError', 'wtaTourYearsOptions']),
    },
    methods: {
        ...mapActions('wtaTourYears', ['retrieveWtaTourYears', 'createWtaTourYear', 'updateWtaTourYear', 'deleteWtaTourYear']),
        refresh() {
            let params = {
                self: this,
                fRetrieve: this.retrieveWtaTourYears,
                options: this.wtaTourYearsOptions,
                dtosName: 'WTA Tour Years',
            };
            retrieveDtos(params);
        },
        reset() {
            this.wtaTourYearsOptions.page = 0;
            this.refresh();
        },
        saveChanges() {
            if (this.mode === 'edit') {
                let params = {
                    self: this,
                    fUpdate: this.updateWtaTourYear,
                    dto: this.selectedWtaTourYear,
                    dtoName: 'WTA Tour Year',
                    callback: this.crudCallback,
                };
                updateConfirmDto(params);
            } else if (this.mode === 'add') {
                let params = {
                    self: this,
                    fCreate: this.createWtaTourYear,
                    dto: this.selectedWtaTourYear,
                    dtoName: 'WTA Tour Year',
                    callback: this.crudCallback,
                };
                createConfirmDto(params);
            }
        },
        onChange() {},
        deleteItem() {
            let params = {
                self: this,
                fDelete: this.deleteWtaTourYear,
                vConfirm: this.selectedWtaTourYear.data.tourYear,
                dto: this.selectedWtaTourYear,
                dtoName: 'WTA Tour Year',
                vConfirmName: 'Tour Year',
                callback: this.crudCallback,
            };
            deleteConfirmDto(params);
        },
        addWtaTourYear() {
            this.mode = 'add';
            this.addDialogVisible = true;
            this.selectedWtaTourYear = new WtaTourYear({});
        },
        selectWtaTourYear(wtaTourYear) {
            this.mode = 'edit';
            this.editDialogVisible = true;
            this.selectedWtaTourYear = new WtaTourYear(wtaTourYear);
        },
        clearError() {
            this.wtaTourYearsLoadingError = null;
        },
        prevPage() {
            this.wtaTourYearsOptions.page--;
            this.refresh();
        },
        nextPage() {
            this.wtaTourYearsOptions.page++;
            this.refresh();
        },
        setPageSize(size) {
            this.wtaTourYearsOptions.pageSize = size;
            this.refresh();
        },
        crudCallback() {
            this.refresh();
            this.requestError = null;
            this.addDialogVisible = false;
            this.editDialogVisible = false;
        },
        informationArray() {
            return [
                'This screen is used for management of WTA Tour Years.',
                'WTA Tour Years are fetched from table year_end.',
                'Entries in the database table only holds end dates for each WTA Tour Year.',
                'Internally, Baseline computes start dates based upon preceding WTA Tour Year.',
                'Therefore, "valid" WTA Tour Years are those with computed start date and entered end date.',
                '&nbsp;',
                'WTA Tour Years are used',
                '<ul>',
                '<li> by Ranking Procedure to compute Championship Points and played P5s',
                '<li> for Ranking History to organize Ranking Dates',
                '<ul>',
            ];
        },
    },
};
</script>

<style scoped></style>
