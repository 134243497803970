<template>
    <div>
        <ErrorDisplay :requestError="this.loadingErrorShadowDoublesRanking" @closed="clearError"/>

        <div v-loading="loadingShadowDoublesRanking">
            <DataTable
                    :autoSort="false"
                    :fields="[
                    'rankDoubles',
                    'priorRankDoubles',
                    'playerId',
                    'fullName',
                    'nationality',
                        'playerBirthDate',
                    'pointsDoubles',
                    'tournamentCountDoubles',
                    'nextOnDoubles',
                    'nextOffDoubles',
                    'points11ThDoubles',
                    'points12ThDoubles',
                    'highestDoublesRank',
                    'highestDoublesRankDate',
                ]"
                    :items="shadowDoublesRankings.data"
                    :schema="schema"
                    @header-clicked="setSort"
            />

            <Paginator
                    v-if="shadowDoublesRankings.data"
                    :data="shadowDoublesRankings"
                    :elementsPerPage="options.pageSize"
                    @prev-page="prevPage"
                    @next-page="nextPage"
                    @set-page-size="setPageSize"
            />
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { retrieveDtos } from '@/utils/crud-confirm';
import { ShadowPlayerRanking } from "@/models/shadow-player-ranking";

export default {
    props: ['playerId', 'shadowRankId'],
    watch: {
        playerId() {
            this.playerSelected({ id: this.playerId });
        },
        shadowRankId() {
            this.shadowRankingSelected({ shadowRankId: this.shadowRankId });
        },
    },
    data() {
        return {
            schema: ShadowPlayerRanking.schema,
            layout: ShadowPlayerRanking.layoutCurrent,
            options: {
                shadowRankId: null,
                queryString: '',
                sortBy: '',
                sortDir: '',
                pageSize: process.env.VUE_APP_PAGE_SIZE,
                page: 0,
            },
        };
    },
    mounted() {
    },
    computed: {
        ...mapGetters('shadowCurrentDoubles', [
            'shadowDoublesRankings',
            'loadingShadowDoublesRanking',
            'loadingErrorShadowDoublesRanking'
        ]),
    },
    methods: {
        ...mapActions('shadowCurrentDoubles', ['retrieveCurrentDoublesShadowRankings']),
        refresh() {
            let params = {
                self: this,
                fRetrieve: this.retrieveCurrentDoublesShadowRankings,
                options: this.options,
                dtosName: 'Shadow Doubles Rankings',
            };
            retrieveDtos(params);
        },
        playerSelected(player) {
            this.options.page = 0;
            this.options.queryString = player.id;
            if (this.options.shadowRankId)
                this.refresh();
        },
        shadowRankingSelected(ranking) {
            this.options.page = 0;
            this.options.shadowRankId = ranking.shadowRankId;
            this.refresh();
        },
        reset() {
            this.options.page = 0;
            this.options.queryString = '';
            this.refresh();
        },
        prevPage() {
            this.options.page--;
            this.refresh();
        },
        nextPage() {
            this.options.page++;
            this.refresh();
        },
        setPageSize(size) {
            this.options.pageSize = size;
            this.refresh();
        },
        setSort(event) {
            this.options.sortBy = event.property;
            this.options.sortDir = event.order;
            this.refresh();
        },
        crudCallback() {
            this.refresh();
            this.$emit('refresh');
            this.requestError = null;
        },
        clearError() {
            this.loadingError = null;
        },
    },
};
</script>
