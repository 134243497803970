<template>
    <div v-loading="loadingShadowRankingSummaries">

        <div class="flex-row">
            <RankingSummaryPointTable :summary="this.shadowDoublesSummary" />
            <span class="spacer"></span>
            <RankingSummaryTieBreakingPoints :summary="this.shadowDoublesSummary" />
            <span class="spacer"></span>
            <span class="spacer"></span>
        </div>

        <DataTable
            v-if="shadowDoublesSummary"
            :items="shadowDoublesSummary.allResults"
            :schema="schema"
            :fields="this.dataFields"
            :autoSort="false"
        />
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { TournamentResult } from '../../models/tournament-result';

export default {
    props: ['dataFields'],
    data() {
        return {
            schema: TournamentResult.schema,
        };
    },
    mounted() {},
    computed: {
        ...mapGetters('shadowRankingSummaries', ['shadowDoublesSummary', 'loadingShadowRankingSummaries']),
    },
    methods: {
    },
};
</script>

<style scoped></style>
