import axios from 'axios';
import { PlayerBioDet } from '../models/player-bio-det';

export default {
    namespaced: true,

    state: {
        playerBioDet: new PlayerBioDet(),
        bioDetFound: false,
        loadingBioDet: false,
        loadingError: null,
    },

    getters: {
        playerBioDet: (state) => state.playerBioDet,
        bioDetFound: (state) => state.bioDetFound,
        loadingBioDet: (state) => state.loadingBioDet,
        loadingError: (state) => state.loadingError,
    },

    mutations: {
        setPlayerBioDet(state, data) {
            state.playerBioDet = data;
        },
        setBioDetFound(state) {
            state.bioDetFound = true;
        },
        clearBioDetFound(state) {
            state.bioDetFound = false;
        },

        startLoadingBioDet(state) {
            state.loadingBioDet = true;
        },

        stopLoadingBioDet(state) {
            state.loadingBioDet = false;
        },

        setLoadingError(state, error) {
            state.loadingError = error;
        },

        clearLoadingError(state) {
            state.loadingError = null;
        },
    },

    actions: {
        async findPlayerBioDet(context, playerNbr) {
            if (!playerNbr) {
                return;
            }
            context.commit('startLoadingBioDet');
            try {
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/Player/${playerNbr}/BioDet`);
                context.commit('stopLoadingBioDet');
                context.commit('setPlayerBioDet', new PlayerBioDet(result.data));
                context.commit('setBioDetFound');
                context.commit('clearLoadingError');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('stopLoadingBioDet');
                context.commit('clearBioDetFound');
                context.commit('setLoadingError', error);
                return Promise.reject(error);
            }
        },

        async createPlayerBioDet(context, data = {}) {
            if (!data.data.playerNbr) {
                return;
            }
            context.commit('startLoadingBioDet');
            let playerNbr = data.data.playerNbr;
            try {
                const result = await axios.post(`${process.env.VUE_APP_API_URL}/Player/${playerNbr}/BioDet`, data.data);
                context.commit('stopLoadingBioDet');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('stopLoadingBioDet');
                context.commit('setLoadingError', error);
                return Promise.reject(error);
            }
        },

        async updatePlayerBioDet(context, data = {}) {
            if (!data.data.playerNbr) {
                return;
            }
            context.commit('startLoadingBioDet');
            let playerNbr = data.data.playerNbr;
            try {
                const result = await axios.put(`${process.env.VUE_APP_API_URL}/Player/${playerNbr}/BioDet`, data.data);
                context.commit('stopLoadingBioDet');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('stopLoadingBioDet');
                context.commit('setLoadingError', error);
                return Promise.reject(error);
            }
        },

        async deletePlayerBioDet(context, data = {}) {
            if (!data.data.playerNbr) {
                return;
            }
            context.commit('startLoadingBioDet');
            let playerNbr = data.data.playerNbr;
            try {
                const result = await axios.delete(`${process.env.VUE_APP_API_URL}/Player/${playerNbr}/BioDet`);
                context.commit('stopLoadingBioDet');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('stopLoadingBioDet');
                context.commit('setLoadingError', error);
                return Promise.reject(error);
            }
        },
    },
};
