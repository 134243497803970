import axios from 'axios';

export default {
    namespaced: true,

    state: {
        pzMembershipSubscriptions: [],
        loadingPzMembershipSubscriptions: false,
        loadingErrorPzMembershipSubscriptions: null,
    },

    getters: {
        pzMembershipSubscriptions: (state) => state.pzMembershipSubscriptions,
        loadingPzMembershipSubscriptions: (state) => state.loadingPzMembershipSubscriptions,
        loadingErrorPzMembershipSubscriptions: (state) => state.loadingErrorPzMembershipSubscriptions,
    },

    mutations: {
        setPzMembershipSubscriptions(state, data) { state.pzMembershipSubscriptions = data; },

        startLoadingPzMembershipSubscriptions(state) { state.loadingPzMembershipSubscriptions = true; },
        stopLoadingPzMembershipSubscriptions(state) { state.loadingPzMembershipSubscriptions = false; },

        setLoadingErrorPzMembershipSubscriptions(state, error) { state.loadingErrorPzMembershipSubscriptions = error; },
        clearLoadingErrorPzMembershipSubscriptions(state) { state.loadingErrorPzMembershipSubscriptions = null; },
    },

    actions: {
        async getPzMembershipSubscriptions(context) {
            context.commit('startLoadingPzMembershipSubscriptions');
            context.commit('clearLoadingErrorPzMembershipSubscriptions');
            try {
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/PzMembershipSubscription/`);
                context.commit('setPzMembershipSubscriptions', result.data);
                context.commit('stopLoadingPzMembershipSubscriptions');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('setLoadingErrorPzMembershipSubscriptions', error);
                context.commit('stopLoadingPzMembershipSubscriptions');
                return Promise.reject(error);
            }
        },

        async createPzMembershipSubscription(context, data) {
            context.commit('startLoadingPzMembershipSubscriptions');
            try {
                const result = await axios.post(`${process.env.VUE_APP_API_URL}/PzMembershipSubscription/`, data.data);
                context.commit('stopLoadingPzMembershipSubscriptions');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('setLoadingErrorPzMembershipSubscriptions', error);
                context.commit('stopLoadingPzMembershipSubscriptions');
                return Promise.reject(error);
            }
        },

        async updatePzMembershipSubscription(context, data) {
            context.commit('startLoadingPzMembershipSubscriptions');
            try {
                const result = await axios.put(`${process.env.VUE_APP_API_URL}/PzMembershipSubscription/`, data.data);
                context.commit('stopLoadingPzMembershipSubscriptions');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('setLoadingErrorPzMembershipSubscriptions', error);
                context.commit('stopLoadingPzMembershipSubscriptions');
                return Promise.reject(error);
            }
        },

        async deletePzMembershipSubscription(context, code) {
            context.commit('startLoadingPzMembershipSubscriptions');
            try {
                const result = await axios.delete(`${process.env.VUE_APP_API_URL}/PzMembershipSubscription/${code}`);
                context.commit('stopLoadingPzMembershipSubscriptions');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('setLoadingErrorPzMembershipSubscriptions', error);
                context.commit('stopLoadingPzMembershipSubscriptions');
                return Promise.reject(error);
            }
        },
    },
};
