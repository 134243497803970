var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('DialogEditItem',{attrs:{"disableDelete":true,"layout":_vm.layout,"schema":_vm.schema,"selected":_vm.selectedShadowRankingPoints,"showNav":true,"visibleSync":_vm.editDialogVisible,"dtoName":"Shadow Ranking Points"},on:{"change":_vm.onChange,"close":_vm.onClose,"next":_vm.gotoNextShadowRankingPoints,"prev":_vm.gotoPrevShadowRankingPoints,"save":_vm.saveChanges}}),_c('div',{staticClass:"mt-8"},[_c('DataTable',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loadingShadowRankingPoints),expression:"loadingShadowRankingPoints"}],attrs:{"autoSort":false,"fields":[
              'tableName',
              'useRegularPoints',
              'pointsWin',
              'pointsRun',
              'pointsSemi',
              'pointsQtr',
              'points16',
              'points32',
              'points64',
              'points128',
              'points256'
          ],"items":_vm.shadowRankingPoints[_vm.shadowRankId],"schema":_vm.schema},on:{"item-clicked":_vm.itemClicked}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }