import axios from 'axios';
import moment from "moment";
import {ItfImport} from "@/models/itf-import";

export default {
    namespaced: true,

    state: {
        itfImports: {},
        itfImportResults: [], // TODO: maybe object better than array?
        loadingItfImports: false,
        loadingItfImportResults: false,
        loadingErrorItfImports: null,
        loadingErrorItfImportResults: null,
        itfOptions: {
            fromDate: null,
            toDate: null,
            sortDir: "ascending",
            sortBy: null,
            page: 0,
            pageSize: 20,
        },
    },

    getters: {
        itfImports: (state) => state.itfImports,
        itfImportResults: (state) => state.itfImportResults,
        loadingItfImports: (state) => state.loadingItfImports,
        loadingItfImportResults: (state) => state.loadingItfImportResults,
        loadingErrorItfImports: (state) => state.loadingErrorItfImports,
        loadingErrorItfImportResults: (state) => state.loadingErrorItfImportResults,
        itfOptions: (state) => state.itfOptions,
    },

    mutations: {
        setItfImports(state, data) {
            state.itfImports = data;
            // data.data.forEach(dto => state.itfImports.push(new ItfImport(dto)));
        },
        clearItfImports(state) {
            state.itfImports = {};
        },
        startLoadingItfImports(state) {
            state.loadingItfImports = true;
        },
        stopLoadingItfImports(state) {
            state.loadingItfImports = false;
        },
        setLoadingErrorItfImports(state, data) {
            state.loadingErrorItfImports = data;
        },
        clearLoadingErrorItfImports(state) {
            state.loadingErrorItfImports = null;
        },

        addItfImportResult(state, data) {
            state.itfImportResults.push(data);
        },
        removeItfImportResult(state, name) {
            state.itfImportResults = state.itfImportResults.filter((result) => {
                return result.name !== name;
            });
        },
        startLoadingItfImportResult(state) {
            state.loadingItfImportResults = true;
        },
        stopLoadingItfImportResult(state) {
            state.loadingItfImportResults = false;
        },
        setLoadingErrorItfImportResults(state, data) {
            state.loadingErrorItfImportResults = data;
        },
        clearLoadingErrorItfImportResults(state) {
            state.loadingErrorItfImportResults = null;
        },
    },

    actions: {
        async findItfImports(context, itfOptions) {
            context.commit('startLoadingItfImports');
            context.commit('clearLoadingErrorItfImports');
            try {
                let fromDate = moment(itfOptions.fromDate).format("yyyy-MM-DD");
                let toDate = moment(itfOptions.toDate).format("yyyy-MM-DD");
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/ItfImport/Calendar/From/${fromDate}/To/${toDate}`, {
                    params: {
                        sortBy: itfOptions.sortBy || 'StartDate',
                        sortDir: itfOptions.sortDir === 'descending' ? -1 : 1,
                        pageSize: itfOptions.pageSize || 20,
                        page: itfOptions.page,
                    }
                });
                context.commit('clearItfImports');
                context.commit('setItfImports', result.data);
                context.commit('stopLoadingItfImports');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('setLoadingErrorItfImports', error);
                context.commit('stopLoadingItfImports');
                return Promise.reject(error);
            }
        },

        async importItfTournamentDryRun(context, itfImport) {
            context.commit('startLoadingItfImportResult');
            context.commit('clearLoadingErrorItfImportResults');
            try {
                const result = await axios.post(`${process.env.VUE_APP_API_URL}/ItfImport/Tournament/DryRun`, itfImport.data);
                context.commit('addItfImportResult', result.data);
                context.commit('stopLoadingItfImportResult');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('setLoadingErrorItfImportResults', error);
                context.commit('stopLoadingItfImportResult');
                return Promise.reject(error);
            }
        },

        async importItfTournament(context, itfImport) {
            context.commit('startLoadingItfImportResult');
            context.commit('clearLoadingErrorItfImportResults');
            try {
                const result = await axios.post(`${process.env.VUE_APP_API_URL}/ItfImport/Tournament`, itfImport.data);
                context.commit('addItfImportResult', result.data);
                context.commit('stopLoadingItfImportResult');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('setLoadingErrorItfImportResults', error);
                context.commit('stopLoadingItfImportResult');
                return Promise.reject(error);
            }
        },

        removeItfImportResult(context, name) {
            context.commit('removeItfImportResult', name);

        },

        clearLoadingErrorItfImportResults(context) {
            context.commit('clearLoadingErrorItfImportResults');
        }
    },
};
