import { render, staticRenderFns } from "./NotAllowed.vue?vue&type=template&id=61216718&scoped=true"
var script = {}
import style0 from "./NotAllowed.vue?vue&type=style&index=0&id=61216718&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61216718",
  null
  
)

export default component.exports