<template>
    <div>
        <div class="flex-row mb-32">
            <h2><i class="el-icon-medal-1 mr-16"></i>Shadow Ranking Summaries
                {{ options.rankingDate ? 'for ' + options.rankingDate : '' }}</h2>
            <span class="spacer"></span>

            <el-select v-model="options.shadowRankId"
                       v-loading="loadingShadowRankings"
                       class="mr-16"
                       placeholder="Select Shadow Ranking"
                       @change="shadowRankIdChanged">
                <el-option v-for="item in shadowRankings"
                           :key="item.shadowRankId"
                           :label="item.name"
                           :value="item.shadowRankId">
                </el-option>
            </el-select>

            <span class="mr-8">Date</span>
            <el-date-picker
                    v-model="options.rankingDate"
                    :clearable="false"
                    :firstDayOfWeek="1"
                    class="mr-16 date-select"
                    format="yyyy-MM-dd"
                    placeholder="Pick a day"
                    type="week"
                    @change="selectDate"
            ></el-date-picker>

            <span class="mr-8">Player</span>
            <SelectPlayer :value="this.options.playerId" class="mr-8" @palyer-selected="selectPlayer"/>
        </div>

        <ErrorDisplay :requestError="this.requestError" @closed="clearError"/>

        <DetailedInformation :informationArray="this.informationArray()" screenName="Shadow Ranking Summaries"/>

        <el-tabs active-name="shadow-singles-summary">
            <el-tab-pane label="Shadow Singles Summary by Player" name="shadow-singles-summary">
                <ShadowRankingSummarySingles
                        :dataFields="[
                        'startDate',
                        'useForRankingDate',
                        'tournamentName',
                        'season',
                        'tournamentId',
                        'isRequired',
                        'points',
                        'isIncludedIntoRanking',
                        'rankingLabel',
                    ]"
                />
            </el-tab-pane>

            <el-tab-pane label="Shadow Doubles Summary by Player" name="shadow-doubles-summary">
                <ShadowRankingSummaryDoubles
                        :dataFields="[
                        'startDate',
                        'useForRankingDate',
                        'tournamentName',
                        'season',
                        'tournamentId',
                        'isRequired',
                        'points',
                        'isIncludedIntoRankingDoubles',
                        'rankingLabel',
                    ]"
                />
            </el-tab-pane>

            <el-tab-pane label="Shadow Champ Singles Summary by Player" name="shadow-race-singles-summary">
                <ShadowRankingSummaryChampSingles/>
            </el-tab-pane>

            <el-tab-pane label="Shadow Champ Team Summaries by Teams" name="shadow-race-teams-summaries">
                <ShadowRankingSummaryChampTeams/>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { getMondayOfWeek } from '../../utils/date-utils';
import moment from 'moment';

export default {
    data() {
        return {
            requestError: null,
            playerName: null,
            options: {
                shadowRankId: null,
                rankingDate: null,
                playerId: null,

            },
        };
    },
    mounted() {
        if (this.options.shadowRankId && this.options.rankingDate && this.options.playerId) {
            return;
        }
        this.retrieveWtaSystem()
                .then(() => {
                    this.selectDate(this.wtaSystem.data.currentRankDate);
                })
                .catch((error) => {
                    this.requestError = error;
                    this.$notify({
                        title: 'Get WTA System Error',
                        message: error.response.data.Message,
                        type: 'error',
                        duration: 7000,
                    });
                });
        this.getAllShadowRankings()
                .then(() => {
                    if (!this.shadowRankings.length) return;
                    this.shadowRankIdChanged(this.shadowRankings[0].shadowRankId);
                })
                .catch((error) => {
                    this.requestError = error;
                    this.$notify({
                        title: 'Get Shadow Rankings Error',
                        message: error.response.data.Message,
                        type: 'error',
                        duration: 5000,
                    });
                });
    },
    computed: {
        ...mapGetters('shadowRankings', [
            'shadowRankings',
            'loadingShadowRankings',
            'loadingErrorShadowRankings']),
        ...mapGetters('wtaSystem', ['wtaSystem', 'loadingWtaSystem']),
        ...mapGetters('shadowRankingSummaries', ['loadingErrorShadowRankingSummaries']),
    },
    methods: {
        ...mapActions('wtaSystem', ['retrieveWtaSystem']),
        ...mapActions('shadowRankings', ['getAllShadowRankings']),
        ...mapActions('shadowRankingSummaries', ['dryRunShadowRankingProcedure']),
        refresh() {
            if (!this.options.shadowRankId || !this.options.rankingDate || !this.options.playerId) {
                return;
            }
            this.dryRunShadowRankingProcedure(this.options)
                    .catch((error) => {
                        this.requestError = error;
                        this.$notify({
                            title: 'Get Shadow Ranking Summaries Error',
                            message: error.response.data.Message,
                            type: 'error',
                            duration: 7000,
                        });
                    });
        },
        shadowRankIdChanged(value) {
            this.options.shadowRankId = value;
            this.refresh();
        },
        selectPlayer(player) {
            this.options.playerId = player ? player.id : null;
            this.refresh();
        },
        selectDate(date) {
            this.options.rankingDate = date ? getMondayOfWeek(date) : null;
            this.options.rankingDate = moment(this.options.rankingDate).format('YYYY-MM-DD')
            this.refresh();
        },
        clearError() {
            this.requestError = null;
        },
        informationArray() {
            return [
                'This screen is used for insights into Shadow Ranking details.',
                'Date selection always picks Monday of selected week as Ranking Date.',
                'Selecting past and future Rankings is possible and will not result in any change of database.',
            ];
        },
    },
};
</script>

<style scoped>
.date-select {
    max-width: 128px;
}
</style>
