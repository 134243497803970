import axios from 'axios';

export default {
    namespaced: true,

    state: {
        playerPayments: [],
        loadingPlayerPayments: false,
        loadingError: null,
        savingPlayerPayment: false,
        savingError: null,
        deletingPlayerPayment: false,
        deletingError: null,
    },

    getters: {
        playerPayments: (state) => state.playerPayments,
        loadingPlayerPayments: (state) => state.loadingPlayerPayments,
        loadingError: (state) => state.loadingError,
        savingPlayerPayment: (state) => state.savingPlayerPayment,
        savingError: (state) => state.savingError,
        deletingPlayerPayment: (state) => state.deletingPlayerPayment,
        deletingError: (state) => state.deletingError,
    },

    mutations: {
        setPlayerPayments(state, data) {
            state.playerPayments = data;
        },

        startLoadingPlayerPayments(state) {
            state.loadingPlayerPayments = true;
        },

        stopLoadingPlayerPayments(state) {
            state.loadingPlayerPayments = false;
        },

        setLoadingError(state, error) {
            state.loadingError = error;
        },

        clearLoadingError(state) {
            state.loadingError = null;
        },

        startSavingPlayerPayment(state) {
            state.savingPlayerPayment = true;
        },

        stopSavingPlayerPayment(state) {
            state.savingPlayerPayment = false;
        },

        setSavingError(state, error) {
            state.savingError = error;
        },

        clearSavingError(state) {
            state.savingError = null;
        },

        startDeletingPlayerPayment(state) {
            state.deletingPlayerPayment = true;
        },

        stopDeletingPlayerPayment(state) {
            state.deletingPlayerPayment = false;
        },

        setDeletingError(state, error) {
            state.deletingError = error;
        },

        clearDeletingError(state) {
            state.deletingError = null;
        },
    },

    actions: {
        async findPlayerPayments(context, options = {}) {
            if (!options.tournYear || !options.tournNbr) {
                return;
            }
            context.commit('startLoadingPlayerPayments');
            let tournYear = options.tournYear;
            let tournNbr = options.tournNbr;
            try {
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/PlayerPayment/Edition/${tournYear}/${tournNbr}`);
                context.commit('stopLoadingPlayerPayments');
                context.commit('setPlayerPayments', result.data);
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('stopLoadingPlayerPayments');
                context.commit('setLoadingError', error);
                return Promise.reject(error);
            }
        },

        async createPlayerPayment(context, data) {
            if (!data.data.tournYear || !data.data.tournNbr) {
                return;
            }
            context.commit('startSavingPlayerPayment');
            let tournYear = data.data.tournYear;
            let tournNbr = data.data.tournNbr;
            try {
                const result = await axios.post(`${process.env.VUE_APP_API_URL}/PlayerPayment/Edition/${tournYear}/${tournNbr}`, data.data);
                context.commit('stopSavingPlayerPayment');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('stopSavingPlayerPayment');
                context.commit('setSavingError', error);
                return Promise.reject(error);
            }
        },

        async updatePlayerPayment(context, data) {
            if (!data.data.tournYear || !data.data.tournNbr) {
                return;
            }
            context.commit('startSavingPlayerPayment');
            let tournYear = data.data.tournYear;
            let tournNbr = data.data.tournNbr;
            try {
                const result = await axios.put(`${process.env.VUE_APP_API_URL}/PlayerPayment/Edition/${tournYear}/${tournNbr}`, data.data);
                context.commit('stopSavingPlayerPayment');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('stopSavingPlayerPayment');
                context.commit('setSavingError', error);
                return Promise.reject(error);
            }
        },

        async deletePlayerPayment(context, data) {
            if (!data.data.tournYear || !data.data.tournNbr || !data.data.idp || !data.data.playerNbr) {
                return;
            }
            context.commit('startDeletingPlayerPayment');
            let tournYear = data.data.tournYear;
            let tournNbr = data.data.tournNbr;
            let idp = data.data.idp;
            let playerNbr = data.data.playerNbr;
            try {
                await axios.delete(`${process.env.VUE_APP_API_URL}/PlayerPayment/Edition/${tournYear}/${tournNbr}/Player/${idp}/${playerNbr}`);
                context.commit('stopDeletingPlayerPayment');
                return Promise.resolve();
            } catch (error) {
                context.commit('stopDeletingPlayerPayment');
                context.commit('setDeletingError', error);
                return Promise.reject(error);
            }
        },

        async deletePlayerPayments(context, options) {
            if (!options.tournYear || !options.tournNbr) {
                return;
            }
            context.commit('startDeletingPlayerPayment');
            let tournYear = options.tournYear;
            let tournNbr = options.tournNbr;
            try {
                await axios.delete(`${process.env.VUE_APP_API_URL}/PlayerPayment/Edition/${tournYear}/${tournNbr}/Players`);
                context.commit('stopDeletingPlayerPayment');
                return Promise.resolve();
            } catch (error) {
                context.commit('stopDeletingPlayerPayment');
                context.commit('setDeletingError', error);
                return Promise.reject(error);
            }
        },
    },
};
