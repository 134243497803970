import { isValid, Model } from './model';
import { icoCountries } from '@/utils/country-codes';

export class ShadowTeamRanking extends Model {
    isValid() {
        return isValid(ShadowTeamRanking.schema, this);
    }
}

ShadowTeamRanking.layout = {
    children: [
        {
            children: ['player1Id', 'player1ConcatName', 'player1Nationality', 'player1BirthDate'],
        },
        {
            children: ['player2Id', 'player2ConcatName', 'player2Nationality', 'player2BirthDate'],
        },
        {
            children: ['rank', 'tournamentCount', 'points'],
        },
    ],
};

ShadowTeamRanking.layoutHistory = {
    children: [
        {
            children: ['player1Id', 'player1ConcatName', 'player1Nationality', 'player1BirthDate'],
        },
        {
            children: ['player2Id', 'player2ConcatName', 'player2Nationality', 'player2BirthDate'],
        },
        {
            children: ['rank', 'tournamentCount', 'points'],
        },
        {
            children: ['rankPrev', 'rankMove', 'pointsPrev'],
        },
    ],
};

ShadowTeamRanking.schema = {
    fields: {
        shadowRankId: { type: 'number', displayName: 'Shadow Rank Id', readonly: true, sortable: true, required: true },
        rankingDate: { type: 'us-date', displayName: 'Rank Date', required: true, readonly: true },
        player1Id: { type: 'player', displayName: 'Player 1 Id', readonly: true, sortable: true, required: true },
        player1ConcatName: { type: 'string', displayName: 'Player 1', readonly: true },
        player1Nationality: {
            type: 'nationality',
            displayName: 'Nationality',
            readonly: true,
            lookup: Object.keys(icoCountries)
        },
        player1BirthDate: { type: 'us-date', displayName: 'Player 1 Birthdate', readonly: true , sortable: true},
        player1AgeAtRankDate: { type: 'number', displayName: ' Player 1 Age', sortable: true, readonly: true },
        player2Id: { type: 'player', displayName: 'Player 2 Id', readonly: true, sortable: true, required: true },
        player2ConcatName: { type: 'string', displayName: 'Player 2', readonly: true },
        player2Nationality: {
            type: 'nationality',
            displayName: 'Nationality',
            readonly: true,
            lookup: Object.keys(icoCountries)
        },
        player2BirthDate: { type: 'us-date', displayName: 'Player 2 Birthdate', readonly: true, sortable: true },
        player2AgeAtRankDate: { type: 'number', displayName: ' Player 2 Age', sortable: true, readonly: true },
        teamName: { type: 'string', displayName: 'Team', required: true },
        rank: { type: 'number', displayName: 'Rank', sortable: true, required: true },
        rankCd: { type: 'string', displayName: 'Tie' },
        points: { type: 'number', displayName: 'Points', sortable: true, required: true },
        tournamentCount: { type: 'number', displayName: 'Tournament Count', sortable: true, required: true },
        pointsPrev: { type: 'number', displayName: 'Prior Points', readonly: true },
        rankPrev: { type: 'number', displayName: 'Prior Rank', readonly: true },
        rankMove: { type: 'number', displayName: 'Rank Move', readonly: true },
    },
};

