<template>
    <div>
        <el-tabs value="tournaments">
            <el-tab-pane label="Tournaments" name="tournaments">
                <div class="flex-row mt-16 mb-32">
                    <h2>Tournaments</h2>
                    <span class="spacer"></span>

                    <el-input
                        v-model="options.queryString"
                        class="input-search mr-16"
                        placeholder="Filter by Id, Year or Name"
                        @change="resetPage"
                        @keydown.esc.native="reset"
                    >
                        <el-button slot="append" icon="el-icon-search" @click="resetPage"></el-button>
                    </el-input>

                    <el-button plain type="primary" v-if="!isBaselineReader" @click="addTournament"> Add tournament </el-button>
                    <el-button class="ml-8" plain size="mini" @click="exportCsvClick">Export CSV</el-button>
                </div>

                <ErrorDisplay :requestError="this.loadingError" @closed="clearError" />

                <DetailedInformation :informationArray="this.informationArray()" screenName="Required Tournaments" />

                <div v-loading="loadingTournaments">
                    <DataTable
                        :autoSort="false"
                        :fields="['tournamentId', 'name', 'season', 'countryCode', 'type', 'level', 'startDate', 'endDate']"
                        :items="tournaments.data"
                        :schema="schema"
                        :sortDir="options.sortDir"
                        :sortField="options.sortBy"
                        @item-clicked="selectTournament"
                        @header-clicked="setSort"
                    />

                    <Paginator
                        v-if="tournaments.data"
                        :data="tournaments"
                        :elementsPerPage="options.pageSize"
                        @prev-page="prevPage"
                        @next-page="nextPage"
                        @set-page-size="setPageSize"
                    />
                </div>
            </el-tab-pane>
            <el-tab-pane label="ITF Import" name="itf-import">
                <ItfImport></ItfImport>
            </el-tab-pane>
            <el-tab-pane label="ITF Player Links" name="itfPlayerLinks">
                <ItfPlayerLink />
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { Tournament } from '@/models/tournament';
import moment from 'moment';
import FileSaver from 'file-saver';
import { retrieveDtos } from '@/utils/crud-confirm';
import ItfImport from '@/components/ItfImport.vue';
import { isBaselineReader } from '../services/authGroups';

export default {
    components: { ItfImport },
    data() {
        return {
            schema: Tournament.schema,
            isBaselineReader: isBaselineReader(),
        };
    },
    mounted() {
        this.search();
    },
    computed: {
        ...mapGetters('tournaments', ['tournaments', 'loadingTournaments', 'options', 'loadingError']),
    },
    methods: {
        exportCsvClick() {
            this.exportCsv(this.options)
                .then((result) => {
                    const file = new File([result], `tournaments_${moment().format('DD_MM_YYYY HH_mm_SS')}.csv`, { type: 'text/csv' });
                    FileSaver.saveAs(file);
                })
                .catch((error) => {
                    this.$notify({
                        title: 'CSV export',
                        message: 'CSV export failed',
                        type: 'error',
                        duration: 5000,
                    });
                });
        },
        ...mapActions('tournaments', ['findTournaments', 'exportCsv']),
        search() {
            let params = {
                self: this,
                fRetrieve: this.findTournaments,
                options: this.options,
                dtosName: 'Tournaments',
            };
            retrieveDtos(params);
        },
        selectTournament(row) {
            this.$router.push(`/tournaments/${row.season}/${row.tournamentId}`);
        },
        addTournament() {
            this.$router.push('/tournaments/add');
        },
        resetSearch() {
            this.options.queryString = null;
            this.search();
        },
        resetPage() {
            this.options.page = 0;
            this.search();
        },
        setPageSize(size) {
            this.options.pageSize = size;
            this.search();
        },
        reset() {
            this.options.queryString = null;
            this.options.page = 0;
            this.search();
        },
        setSort(event) {
            console.log(event);
            this.options.sortBy = event.property;
            this.options.sortDir = event.order;
            this.search();
        },
        prevPage() {
            this.options.page--;
            this.search();
        },
        nextPage() {
            this.options.page++;
            this.search();
        },
        clearError() {
            this.loadingError = null;
        },
        informationArray() {
            return [
                'This screen is used to display tournament overview.',
                'Tournaments are fetched from table tourn_table.',
                'Combined Search is possible by tourn_nbr <b>and</b> tourn_name <b>and</b> tourn_year <b>and</b> country_code <b>and</b> tourn_sponsor.',
                'Adding a new tournament is possible by clicking button "Add tournament".',
                'Be careful when adding new tournament. If tournament is already present no error is thrown but data is overriden.',
                'Click on a tournament to get to tournament details.',
            ];
        },
    },
};
</script>

<style scoped>
.input-search {
    max-width: 512px;
}
</style>
