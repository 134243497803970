export function buildParams(options) {
    let params = {
        queryString: options.queryString || '',
        pageSize: options.pageSize || 10,
        page: options.page,
        sortBy: options.sortBy || '',
        sortDir: 0,
        recordType: options.recordType,
        ageAt: options.ageAt,
        rankAt: options.rankAt,
        nationality: options.nationality,
        shadowRankId: options.shadowRankId,
    }
    if (options.sortDir === 'ascending') params.sortDir = 1;
    if (options.sortDir === 'descending') params.sortDir = -1;

    return params;
}