<template>
    <div>
        <DialogAddItem
            :visibleSync="addMembershipSubscriptionVisible"
            dtoName="Membership Subscription"
            :selected="selectedMembershipSubscription"
            :schema="schemaMembershipSubscription"
            :layout="layoutMembershipSubscription"
            @save="onCreateMembershipSubscription"
            @change="onChange"
            @close="addMembershipSubscriptionVisible = false"
        />

        <DialogEditItem
            :visibleSync="editMembershipSubscriptionVisible"
            dtoName="Membership Subscription"
            :selected="selectedMembershipSubscription"
            :schema="schemaMembershipSubscription"
            :layout="layoutMembershipSubscription"
            @save="onUpdateMembershipSubscription"
            @change="onChange"
            @delete="onDeleteMembershipSubscription"
            @close="editMembershipSubscriptionVisible = false"
        />

        <DialogAddItem
            :visibleSync="addPaymentDateVisible"
            dtoName="Payment Date"
            :selected="selectedPaymentDate"
            :schema="schemaPaymentDate"
            :layout="layoutPaymentDate"
            @save="onCreatePaymentDate"
            @change="onChange"
            @close="addPaymentDateVisible = false"
        />

        <DialogEditItem
            :visibleSync="editPaymentDateVisible"
            dtoName="Payment Date"
            :selected="selectedPaymentDate"
            :schema="schemaPaymentDate"
            :layout="layoutPaymentDate"
            @save="onUpdatePaymentDate"
            @change="onChange"
            @delete="onDeletePaymentDate"
            @close="editPaymentDateVisible = false"
        />

        <ErrorDisplay :requestError="this.requestError" @closed="clearError" />

        <div class="mt-16 mb-32">
            <h3>Payment System</h3>
        </div>
        <div class="flex-row mb-0 mt-16">
            <span class="spacer"></span>
            <el-alert :closable="false" :show-icon="true" class="mr-32" type="info">
                <div>
                    Payment can not be activated at the end of the year. The current functionality is as such that the system checks for the last date of the
                    payment of a player or coach. If the date is older than 356 days then the person will be asked to pay. For players one can see the payment
                    date in the profile and there in the tab Membership Information. Resetting the value in this field to a date older than 365 days will have
                    the player pay. But this will then have the player pay for more than 356 days. If you really want to have all players and coaches pay now
                    then one needs to set the field payment_date in player_master to Now() - 357 AND the field owesmoney in pzadm_access to NULL manually.
                </div>
            </el-alert>
        </div>
        <div class="flex-row mb-0">
            <h4>Membership Subscriptions</h4>
        </div>
        <div class="flex-row mb-0 mt-16">
            <span class="spacer"></span>
            <el-alert :closable="false" :show-icon="true" class="mr-32" type="info">
                <div>
                    If a value is not set for a payment then this option will not show up. So, if for a Full Member there is no disability insurance set, then
                    this option will not show up in the payment screen.
                </div>
            </el-alert>
        </div>
        <div class="mb-8">
            <DataTable
                v-loading="loadingPzMembershipSubscriptions"
                :items="pzMembershipSubscriptions"
                :schema="schemaMembershipSubscription"
                :fields="['role', 'code', 'name', 'fee', 'insurance', 'disabilityInsurance']"
                @item-clicked="onClickMembershipSubscription"
            />
        </div>
        <div class="flex-row mb-32">
            <span class="spacer"></span>
            <el-button v-if="!isBaselineReader" @click="onAddMembershipSubscription" size="mini" plain type="primary">Add Membership Subscription</el-button>
        </div>

        <div class="flex-row mb-0 mt-16">
            <h4>Payment Dates</h4>
        </div>
        <div class="flex-row mb-0 mt-16">
            <span class="spacer"></span>
            <el-alert :closable="false" :show-icon="true" class="mr-32" type="info">
                <div>
                    These dates are only relevant for later payments. That means that later payments are allowed in the timeframe specificed. Setting the start
                    date does NOT open payments for all players.
                </div>
            </el-alert>
        </div>

        <div class="mb-8">
            <DataTable
                v-loading="loadingPzPaymentDates"
                :items="pzPaymentDates"
                :schema="schemaPaymentDate"
                :fields="['name', 'date']"
                @item-clicked="onClickPaymentDate"
            />
        </div>
        <div class="flex-row mb-16">
            <span class="spacer"></span>
            <el-button v-if="!isBaselineReader" @click="onAddPaymentDate" size="mini" plain type="primary">Add Payment Date</el-button>
        </div>

        <div>
            <WtaSystemPayment />
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { updateConfirmDto } from '../utils/crud-confirm';
import { PzMembershipSubscription } from '../models/pz-membership-subscription';
import { PzPaymentDate } from '../models/pz-payment-date';
import { retrieveDtos, deleteConfirmDto, createConfirmDto } from '../utils/crud-confirm';
import WtaSystemPayment from './WtaSystemPayment.vue';
import { isBaselineReader } from '../services/authGroups';

export default {
    components: {
        WtaSystemPayment,
    },

    data() {
        return {
            schemaMembershipSubscription: PzMembershipSubscription.schema,
            layoutMembershipSubscription: PzMembershipSubscription.layout,
            schemaPaymentDate: PzPaymentDate.schema,
            layoutPaymentDate: PzPaymentDate.layout,
            addMembershipSubscriptionVisible: false,
            editMembershipSubscriptionVisible: false,
            addPaymentDateVisible: false,
            editPaymentDateVisible: false,
            selectedMembershipSubscription: null,
            selectedPaymentDate: null,
            requestError: null,
            isBaselineReader: isBaselineReader(),
        };
    },
    mounted() {
        this.refresh();
    },
    computed: {
        ...mapGetters('pzMembershipSubscriptions', ['pzMembershipSubscriptions', 'loadingPzMembershipSubscriptions', 'loadingErrorPzMembershipSubscriptions']),
        ...mapGetters('pzPaymentDates', ['pzPaymentDates', 'loadingPzPaymentDates', 'loadingErrorPzPaymentDates']),
    },
    methods: {
        ...mapActions('pzMembershipSubscriptions', [
            'getPzMembershipSubscriptions',
            'createPzMembershipSubscription',
            'updatePzMembershipSubscription',
            'deletePzMembershipSubscription',
        ]),
        ...mapActions('pzPaymentDates', ['getPzPaymentDates', 'createPzPaymentDate', 'updatePzPaymentDate', 'deletePzPaymentDate']),
        refresh() {
            this.getPzMembershipSubscriptions();
            this.getPzPaymentDates();
        },
        reset() {
            this.refresh();
        },
        onClickMembershipSubscription(item) {
            this.selectedMembershipSubscription = new PzMembershipSubscription(item);
            this.editMembershipSubscriptionVisible = true;
        },
        onClickPaymentDate(item) {
            this.selectedPaymentDate = new PzPaymentDate(item);
            this.editPaymentDateVisible = true;
        },
        onAddMembershipSubscription() {
            this.selectedMembershipSubscription = new PzMembershipSubscription({});
            this.addMembershipSubscriptionVisible = true;
        },
        onAddPaymentDate() {
            this.selectedPaymentDate = new PzPaymentDate({});
            this.addPaymentDateVisible = true;
        },
        onCreateMembershipSubscription() {
            let params = {
                self: this,
                fCreate: this.createPzMembershipSubscription,
                dto: this.selectedMembershipSubscription,
                dtoName: 'Membership Subscription',
                callback: this.crudCallback,
            };
            createConfirmDto(params);
        },
        onCreatePaymentDate() {
            let params = {
                self: this,
                fCreate: this.createPzPaymentDate,
                dto: this.selectedPaymentDate,
                dtoName: 'Payment Date',
                callback: this.crudCallback,
            };
            createConfirmDto(params);
        },
        onUpdateMembershipSubscription() {
            let params = {
                self: this,
                fUpdate: this.updatePzMembershipSubscription,
                dto: this.selectedMembershipSubscription,
                dtoName: 'Membership Subscription',
                callback: this.crudCallback,
            };
            updateConfirmDto(params);
        },
        onUpdatePaymentDate() {
            let params = {
                self: this,
                fCreate: this.updatePzPaymentDate,
                dto: this.selectedPaymentDate,
                dtoName: 'Payment Date',
                callback: this.crudCallback,
            };
            createConfirmDto(params);
        },
        onDeleteMembershipSubscription() {
            let params = {
                self: this,
                fDelete: this.deletePzMembershipSubscription,
                vConfirm: this.selectedMembershipSubscription.data.code,
                dto: this.selectedMembershipSubscription.data.code,
                dtoName: 'Membership Subscription',
                vConfirmName: 'Code',
                callback: this.crudCallback,
            };
            deleteConfirmDto(params);
        },
        onDeletePaymentDate() {
            let id = this.selectedPaymentDate.data.id;
            let params = {
                self: this,
                fDelete: this.deletePzPaymentDate,
                vConfirm: this.selectedPaymentDate.data.name,
                dto: id,
                dtoName: 'Payment Date',
                vConfirmName: 'Name',
                callback: this.crudCallback,
            };
            deleteConfirmDto(params);
        },
        crudCallback() {
            console.log('Callback');
            this.addMembershipSubscriptionVisible = false;
            this.editMembershipSubscriptionVisible = false;
            this.addPaymentDateVisible = false;
            this.editPaymentDateVisible = false;
            this.addPaymentTypeVisible = false;
            this.editPaymentTypeVisible = false;
            this.clearError();
            this.refresh();
        },
        clearError() {
            this.requestError = null;
        },
        onChange() {},
    },
};
</script>

<style scoped></style>
