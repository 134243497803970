var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loadingShadowRankingSummaries),expression:"loadingShadowRankingSummaries"}]},[_c('div',{staticClass:"flex-row"},[_c('RankingSummaryPointTable',{attrs:{"summary":this.shadowSinglesRaceSummary}}),_c('span',{staticClass:"spacer"}),_c('RankingSummaryChampionshipTieBreakingPoints',{attrs:{"summary":this.shadowSinglesRaceSummary}}),_c('span',{staticClass:"spacer"}),_c('span',{staticClass:"spacer"})],1),(_vm.shadowSinglesRaceSummary)?_c('DataTable',{attrs:{"items":_vm.shadowSinglesRaceSummary.allResults,"schema":_vm.schema,"fields":[
            'startDate',
            'useForRankingDate',
            'tournamentName',
            'season',
            'tournamentId',
            'isRequired',
            'points',
            'isIncludedIntoRanking',
            'rankingLabel',
        ],"autoSort":false}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }