<template>
    <div>
        <ErrorDisplay :requestError="this.loadingErrorShadowTeamRanking" @closed="clearError"/>

        <div v-loading="loadingShadowTeamRanking">
            <DataTable
                    :autoSort="false"
                    :fields="[
                    'player1Id',
                    'player1ConcatName',
                    'player1Nationality',
                    'player1BirthDate',
                    'player2Id',
                    'player2ConcatName',
                    'player2Nationality',
                    'player2BirthDate',
                    'tournamentCount',
                    'points',
                ]"
                    :items="shadowTeamRankings.data"
                    :schema="schema"
                    @header-clicked="setSort"
            />

            <Paginator
                    v-if="shadowTeamRankings.data"
                    :data="shadowTeamRankings"
                    :elementsPerPage="options.pageSize"
                    @prev-page="prevPage"
                    @next-page="nextPage"
                    @set-page-size="setPageSize"
            />
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { ShadowTeamRanking } from '@/models/shadow-team-ranking';
import { retrieveDtos } from '@/utils/crud-confirm';

export default {
    props: ['playerId', 'shadowRankId'],
    watch: {
        playerId() {
            this.playerSelected({ id: this.playerId });
        },
        shadowRankId() {
            this.shadowRankingSelected({ shadowRankId: this.shadowRankId });
        },
    },
    data() {
        return {
            schema: ShadowTeamRanking.schema,
            layout: ShadowTeamRanking.layout,
            options: {
                queryString: '',
                sortBy: '',
                sortDir: '',
                pageSize: process.env.VUE_APP_PAGE_SIZE,
                page: 0,
            },
        };
    },
    mounted() {
    },
    computed: {
        ...mapGetters('shadowCurrentTeamPoints', [
            'shadowTeamRankings',
            'loadingShadowTeamRanking',
            'loadingErrorShadowTeamRanking'
        ]),
    },
    methods: {
        ...mapActions('shadowCurrentTeamPoints', ['findShadowTeamRankings']),
        refresh() {
            let params = {
                self: this,
                fRetrieve: this.findShadowTeamRankings,
                options: this.options,
                dtosName: 'Shadow Team Rankings',
            };
            retrieveDtos(params);
        },
        playerSelected(player) {
            this.options.page = 0;
            this.options.queryString = player.id;
            if (this.options.shadowRankId)
                this.refresh();
        },
        shadowRankingSelected(ranking) {
            this.options.page = 0;
            this.options.shadowRankId = ranking.shadowRankId;
            this.refresh();
        },
        reset() {
            this.options.page = 0;
            this.options.queryString = '';
            this.refresh();
        },
        prevPage() {
            this.options.page--;
            this.refresh();
        },
        nextPage() {
            this.options.page++;
            this.refresh();
        },
        setPageSize(size) {
            this.options.pageSize = size;
            this.refresh();
        },
        setSort(event) {
            this.options.sortBy = event.property;
            this.options.sortDir = event.order;
            this.refresh();
        },
        crudCallback() {
            this.refresh();
            this.$emit('refresh');
            this.requestError = null;
        },
        clearError() {
            this.loadingErrorShadowTeamRanking = null;
        },
    },
};
</script>
