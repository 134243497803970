<template>
    <div>
        <DialogAddItem
            :visibleSync="addDialogVisible"
            dtoName="Tournament Point"
            :selected="selectedTournamentPoint"
            :schema="schemaAdd"
            :layout="layout"
            @save="addItem"
            @change="onChange"
            @close="addDialogVisible = false"
        />

        <DialogEditItem
            :visibleSync="editDialogVisible"
            dtoName="Tournament Point"
            :selected="selectedTournamentPoint"
            :schema="schema"
            :layout="layout"
            @save="updateItem"
            @change="onChange"
            @delete="deleteItem"
            @close="editDialogVisible = false"
        />

        <div class="flex-row mt-0 mb-32">
            <h2><i class="el-icon-medal mr-16"></i>Tournament Points</h2>
            <span class="spacer"></span>
            <el-input
                placeholder="Filter by Id or table name"
                v-model="options.queryString"
                class="input-search mr-16"
                @change="resetPage"
                @keydown.esc.native="reset"
                size="mini"
            >
                <el-button slot="append" icon="el-icon-search" @click="resetPage"></el-button>
            </el-input>
            <el-button v-if="!isBaselineReader" @click="addTournamentPoint" plain size="mini" type="primary"> Add Tournament Point </el-button>
            <el-button @click="exportCsvClick" plain class="ml-8" size="mini">Export CSV</el-button>
        </div>

        <ErrorDisplay :requestError="this.requestError" @closed="clearError" />

        <div v-loading="loadingTournamentPoints">
            <DataTable
                :items="tournamentPoints.data"
                :schema="schema"
                :autoSort="false"
                @header-clicked="setSort"
                :fields="[
                    'pointId',
                    'tableName',
                    'pointsWin',
                    'pointsRun',
                    'pointsSemi',
                    'pointsQtr',
                    'points16',
                    'points32',
                    'points64',
                    'points128',
                    'points256',
                ]"
                :sortField="options.sortBy"
                :sortDir="options.sortDir"
                @item-clicked="itemClicked"
            />

            <Paginator
                v-if="tournamentPoints.data"
                :data="tournamentPoints"
                :elementsPerPage="options.pageSize"
                @prev-page="prevPage"
                @next-page="nextPage"
                @set-page-size="setPageSize"
            />
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { TournamentPoint } from '../models/tournament-point';
import moment from 'moment';
import FileSaver from 'file-saver';
import { retrieveDtos, deleteConfirmDto, createConfirmDto, updateConfirmDto } from '../utils/crud-confirm';
import { isBaselineReader } from '../services/authGroups';

export default {
    data() {
        return {
            schema: TournamentPoint.schema,
            schemaAdd: TournamentPoint.schemaAdd,
            layout: TournamentPoint.layout,
            editDialogVisible: false,
            selectedTournamentPoint: null,
            addDialogVisible: false,
            mode: null,
            requestError: null,
            isBaselineReader: isBaselineReader(),
        };
    },
    mounted() {
        if (this.$route.query.queryString) this.options.queryString = this.$route.query.queryString;
        this.refresh();
    },
    computed: mapGetters('tournamentPoints', ['tournamentPoints', 'loadingTournamentPoints', 'savingTournamentPoint', 'deletingTournamentPoint', 'options']),
    methods: {
        ...mapActions('tournamentPoints', ['findTournamentPoints', 'createTournamentPoint', 'updateTournamentPoint', 'deleteTournamentPoint', 'exportCsv']),
        exportCsvClick() {
            this.exportCsv(this.options)
                .then((result) => {
                    const file = new File([result], `ranking_points_${moment().format('DD_MM_YYYY HH_mm_SS')}.csv`, { type: 'text/csv' });
                    FileSaver.saveAs(file);
                })
                .catch((error) => {
                    this.$notify({
                        title: 'CSV export',
                        message: 'CSV export failed',
                        type: 'error',
                        duration: 5000,
                    });
                });
        },
        refresh() {
            this.search();
        },
        itemClicked(item) {
            this.selectedTournamentPoint = new TournamentPoint(item);
            this.editDialogVisible = true;
        },
        addItem() {
            let params = {
                self: this,
                fCreate: this.createTournamentPoint,
                dto: this.selectedTournamentPoint,
                dtoName: 'Tournament Point',
                callback: this.addCallback,
            };
            createConfirmDto(params).catch((error) => {
                this.requestError = error;
                this.addDialogVisible = false;
            });
        },
        updateItem() {
            let params = {
                self: this,
                fUpdate: this.updateTournamentPoint,
                dto: this.selectedTournamentPoint,
                dtoName: 'Tournament Point',
                callback: this.addCallback,
            };
            updateConfirmDto(params);
        },
        addCallback() {
            this.refresh();
            this.addDialogVisible = false;
            this.editDialogVisible = false;
            this.requestError = null;
        },
        onChange() {},
        search() {
            let params = {
                self: this,
                fRetrieve: this.findTournamentPoints,
                options: this.options,
                dtosName: 'Tournament Points',
            };
            retrieveDtos(params);
        },
        resetSearch() {
            this.options.queryString = null;
            this.search();
        },
        resetPage() {
            this.options.page = 0;
            this.search();
        },
        setPageSize(size) {
            this.options.pageSize = size;
            this.search();
        },
        reset() {
            this.options.queryString = null;
            this.options.page = 0;
            this.search();
        },
        setSort(event) {
            this.options.sortBy = event.property;
            this.options.sortDir = event.order;
            this.search();
        },
        prevPage() {
            this.options.page--;
            this.search();
        },
        nextPage() {
            this.options.page++;
            this.search();
        },
        deleteItem() {
            let params = {
                self: this,
                fDelete: this.deleteTournamentPoint,
                vConfirm: this.selectedTournamentPoint.data.tournamentPointId,
                dto: this.selectedTournamentPoint,
                dtoName: 'Tournament Point',
                vConfirmName: 'Tournament Point ID',
                callback: this.addCallback,
            };
            deleteConfirmDto(params);
        },
        addTournamentPoint() {
            this.selectedTournamentPoint = new TournamentPoint({});
            this.addDialogVisible = true;
        },
        clearError() {
            this.requestError = null;
        },
    },
};
</script>

<style scoped>
.input-search {
    max-width: 384px;
}
</style>
