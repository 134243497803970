<template>
    <div v-loading="loadingShadowRankingSummaries">

        <div class="flex-row">
            <RankingSummaryPointTable :summary="this.shadowSinglesRaceSummary" />
            <span class="spacer"></span>
            <RankingSummaryChampionshipTieBreakingPoints :summary="this.shadowSinglesRaceSummary" />
            <span class="spacer"></span>
            <span class="spacer"></span>
        </div>

        <DataTable
            v-if="shadowSinglesRaceSummary"
            :items="shadowSinglesRaceSummary.allResults"
            :schema="schema"
            :fields="[
                'startDate',
                'useForRankingDate',
                'tournamentName',
                'season',
                'tournamentId',
                'isRequired',
                'points',
                'isIncludedIntoRanking',
                'rankingLabel',
            ]"
            :autoSort="false"
        />
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { TournamentResult } from '../../models/tournament-result';

export default {
    data() {
        return {
            schema: TournamentResult.schema,
        };
    },
    mounted() {},
    computed: {
        ...mapGetters('shadowRankingSummaries', ['shadowSinglesRaceSummary', 'loadingShadowRankingSummaries']),
    },
    methods: {
    },
};
</script>

<style scoped>
</style>
