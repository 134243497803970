<template>
    <el-dialog :visible.sync="visibleSync" :close-on-click-modal="false" :title="title" width="65%" :show-close="false" top="10vh" @open="onOpen">
        <div class="mt-0 mb-0">
            <h3>{{ fullName() }}</h3>
        </div>
        <el-alert type="info" :closable="false" :show-icon="true">
            Click on a tournament result in table below to change value in column 'Include'. This column indicates whether the tournament result is considered
            for calculating Intro and Rookie Eligibility Dates.
        </el-alert>
        <div>
            <DataTable
                v-if="!loadingError"
                v-loading="loadingTournamentResults"
                :items="tournamentResults"
                :fields="[
                    'season',
                    'tournamentName',
                    'startDate',
                    'sdFlag',
                    'qpmFlag',
                    'entryMethod',
                    'entryMethodQualifying',
                    'points',
                    'includeForPdEligibleDates',
                ]"
                :schema="schema"
                :autoSort="false"
                @item-clicked="onItemClicked"
            />
        </div>
        <div class="dialog-footer">
            <el-button @click="onClose">Close</el-button>
            <span class="spacer"></span>
            <el-button @click="onEditPlayerDevelopment" plain type="primary">Open Player Development</el-button>
        </div>
    </el-dialog>
</template>

<script>
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
import { TournamentResult } from '../../models/tournament-result';
import { retrieveDtos, updateConfirmDto } from '../../utils/crud-confirm';
export default {
    props: ['visibleSync', 'player'],
    data() {
        return {
            title: 'Select Tournament Results for Eligibility Dates computation',
            schema: TournamentResult.schema,
            layout: TournamentResult.layout,
            item: null,
            itemClicked: false,
        };
    },
    computed: {
        ...mapGetters('tournamentResults', ['tournamentResults', 'loadingTournamentResults', 'loadingError', 'savingTournamentResult', 'savingError']),
    },
    methods: {
        ...mapActions('tournamentResults', ['findTournamentResultsForPlayerDevelopment', 'updateTournamentResult']),
        ...mapActions('playerDevelopments', ['updateEligibilityDatesDryRun']),
        onOpen() {
            let data = {
                playerId: this.player.data.id,
            };
            let params = {
                self: this,
                fRetrieve: this.findTournamentResultsForPlayerDevelopment,
                options: data,
                dtosName: 'Tournament Results',
            };
            retrieveDtos(params);
        },
        onItemClicked(item, index) {
            let result = new TournamentResult(item);
            result.data.includeForPdEligibleDates= !item.includeForPdEligibleDates;
            this.item = item;
            let params = {
                self: this,
                fUpdate: this.updateTournamentResult,
                dto: result,
                dtoName: 'Tournament Result',
                callback: this.addCallback,
            };
            updateConfirmDto(params);
        },
        addCallback() {
            this.item.includeForPdEligibleDates = !this.item.includeForPdEligibleDates;
            this.itemClicked = true;
        },
        onSave() {
            this.$emit('save');
        },
        onDiscard() {},
        onClose() {
            let data = { itemClicked: this.itemClicked };
            this.$emit('close', data);
            this.itemClicked = false;
        },
        saveDisabled() {},
        fullName() {
            return this.player ? this.player.data.fullName + ' - ' + this.player.data.id : '';
        },
        birthDate() {
            return this.player ? moment(this.player.data.dateOfBirth).format('YYYY-MM-DD') : '';
        },
        onEditPlayerDevelopment() {
            let route = this.$router.resolve({ path: `/players/${this.player.data.id}?activeTab=playerDevelopment` });
            window.open(route.href, '_blank');
        },
    },
};
</script>



<style scoped>
.score-bracket {
    margin-left: -9px;
}
.score-dash {
    margin-left: -11px;
}
.table-wrapper {
    max-height: 320px;
    overflow: auto;
}
</style>