<template>
    <div class="SelectEdition">
        <el-select v-model="edition" placeholder="Select tournament" class="mr-16" v-loading.fullscreen.lock="loadingEditions" :disabled="disabled">
            <el-option v-for="(e, i) in editions" :key="i" :label="e.season + ' - ' + e.name" :value="e.season + '/' + e.tournamentId" />
        </el-select>

        <el-switch :disabled="disabled" v-model="useCurrentRanks" class="mr-16" active-text="Use Current Ranks" />

        <el-button :disabled="stateIsValid || disabled" @click="emitInput" type="primary" class="mr-16">Load acceptance list</el-button
        >
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import * as moment from 'moment';

export default {
    props: {
        disabled: Boolean
    },
    data() {
        return {
            edition: null,
            useCurrentRanks: false,
            loadedEdition: null,
            loadedUseCurrentRanks: false,
            editions: null,
            loadingEditions: true
        };
    },
    mounted() {
        this.findEditionsForAcceptanceList(moment().format('YYYY-MM-DD')).then((eds) => {
            this.editions = eds;
            this.loadingEditions = false;
        });
    },
    watch: {
        useCurrentRanks: function() {
            this.emitChange();
        },
        edition: function() {
            this.emitChange();
        }
    },
    computed: {
        stateIsValid: function() {
            return this.edition === this.loadedEdition && this.useCurrentRanks === this.loadedUseCurrentRanks;
        }
    },
    methods: {
        ...mapActions('acceptanceList', ['findEditionsForAcceptanceList']),
        emitInput() {
            if (this.edition) {
                this.$emit('input', this.edition, this.useCurrentRanks);
                this.loadedEdition = this.edition;
                this.loadedUseCurrentRanks = this.useCurrentRanks;
            }
        },
        emitChange() {
            this.$emit('change', this.edition, this.useCurrentRanks);
        }
    },
};
</script>
