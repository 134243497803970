<template>
    <div>
        <ErrorDisplay :requestError="this.loadingError" @closed="clearError" />

        <div v-loading="loadingRanking">
            <DataTable
                :items="rankings.data"
                :schema="schema"
                :fields="['playerId', 'fullName', 'nationality', 'rankChampionshipSingles', 'pointsChampionshipSingles']"
                @item-clicked="selectRanking"
                @header-clicked="setSort"
                :autoSort="false"
            />

            <Paginator
                v-if="rankings.data"
                :data="rankings"
                :elementsPerPage="options.pageSize"
                @prev-page="prevPage"
                @next-page="nextPage"
                @set-page-size="setPageSize"
            />
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { Ranking } from '../../models/ranking';
import * as moment from 'moment';
import { retrieveDtos } from '../../utils/crud-confirm';
import FileSaver from 'file-saver';

export default {
    props: ['rankingDate', 'queryString'],
    watch: {
        rankingDate() {
            this.reset();
        },
        queryString() {
            this.queryStringChanged(this.queryString);
            this.reset();
        },
    },
    data() {
        return {
            schema: Ranking.schema,
            layout: Ranking.layout,
        };
    },
    mounted() {},
    computed: mapGetters('historyChampSingles', ['rankings', 'loadingRanking', 'loadingError', 'options']),
    methods: {
        ...mapActions('historyChampSingles', ['find', 'exportCsv']),
        refresh() {
            let findParams = {
                options: this.options,
                date: this.rankingDate,
            };
            let params = {
                self: this,
                fRetrieve: this.find,
                options: findParams,
                dtosName: 'Historic Championship Singles Rankings',
            };
            retrieveDtos(params);
        },
        reset() {
            this.options.page = 0;
            this.refresh();
        },
        prevPage() {
            this.options.page--;
            this.refresh();
        },
        nextPage() {
            this.options.page++;
            this.refresh();
        },
        setPageSize(size) {
            this.options.pageSize = size;
            this.refresh();
        },
        setSort(event) {
            this.options.sortBy = event.property;
            this.options.sortDir = event.order;
            this.refresh();
        },
        queryStringChanged(queryString) {
            this.options.queryString = queryString;
            this.refresh();
        },
        selectRanking(ranking) {
            this.$emit('rankingSelected', ranking);
        },
        clearError() {
            this.loadingError = null;
        },
        exportCsvClick() {
            const date = moment(this.rankingDate).format('YYYY-MM-DD');
            this.exportCsv({
                date: date,
                rankingContext: 'championship-singles',
                queryString: this.options.queryString || '',
                sortBy: this.options.sortBy || 'rankChampionshipSingles',
                sortDir: this.options.sortDir || 1,
            })
                .then((result) => {
                    const file = new File([result], `ranking_championship-singles${moment(date).format('YYYY_MM_DD')}.csv`, { type: 'text/csv' });
                    FileSaver.saveAs(file);
                })
                .catch((error) => {
                    console.log(error);
                    this.$notify({
                        title: 'CSV export',
                        message: 'CSV export failed',
                        type: 'error',
                        duration: 5000,
                    });
                });
        },
    },
};
</script>
