import CryptoJS from 'crypto-js';

function base64URLEncode(str) {
    return str.toString(CryptoJS.enc.Base64).replace(/=/g, '').replace(/\+/g, '-').replace(/\//g, '_');
}

function sha256(buffer) {
    return CryptoJS.SHA256(buffer);
}

export function generateCodeVerifier() {
    const array = new Uint32Array(56 / 2);
    window.crypto.getRandomValues(array);
    return Array.from(array, (dec) => ('0' + dec.toString(16)).substr(-2)).join('');
}

export function generateCodeChallenge(codeVerifier) {
    return base64URLEncode(sha256(codeVerifier));
}
