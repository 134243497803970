<template>
    <div v-loading="loadingNextOffTournaments">
        <div class="flex-row mt-0 mb-32">
            <span class="spacer"></span>
        </div>
        <ErrorDisplay :requestError="this.nextOffTournamentsError" @closed="clearError" />
        <DataTable
            :items="nextOffTournaments"
            :schema="schemaTournament"
            :fields="['name', 'startDate', 'sponsor', 'tournamentId', 'season']"
            :autoSort="false"
        />
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { Tournament } from '../../models/tournament';
import moment from 'moment';

export default {
    props: ['rankingDate'],
    data() {
        return {
            schemaTournament: Tournament.schema,
        };
    },
    mounted() {},
    computed: {
        ...mapGetters('rankingProcedure', ['nextOffTournaments', 'loadingNextOffTournaments', 'nextOffTournamentsError']),
    },
    methods: {
        clearError() {
            this.nextOffTournamentsError = null;
        },
    },
};
</script>

<style scoped></style>
