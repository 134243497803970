<template>
    <div>
        <DialogAddItem
            :visibleSync="addDialogVisible"
            dtoName="Tournament Prize"
            :selected="selectedTournamentPrize"
            :schema="schemaAdd"
            :layout="layout"
            @save="addItem"
            @change="onChange"
            @close="addDialogVisible = false"
        />

        <DialogEditItem
            :visibleSync="editDialogVisible"
            dtoName="Tournament Prize"
            :selected="selectedTournamentPrize"
            :schema="schema"
            :layout="layout"
            @save="updateItem"
            @change="onChange"
            @delete="deleteItem"
            @close="editDialogVisible = false"
        />

        <div class="flex-row mt-0 mb-32">
            <h2><i class="el-icon-coin mr-16"></i>Tournament Prizes</h2>
            <span class="spacer"></span>
            <el-input
                placeholder="Filter by Id, category or table name"
                v-model="options.queryString"
                class="input-search mr-16"
                @change="resetPage"
                @keydown.esc.native="reset"
                size="mini"
            >
                <el-button slot="append" icon="el-icon-search" @click="resetPage"></el-button>
            </el-input>
            <el-button v-if="!isBaselineReader" @click="addTournamentPrize" plain size="mini" type="primary"> Add Tournament Prize </el-button>
            <el-button @click="exportCsvClick" plain class="ml-8" size="mini">Export CSV</el-button>
        </div>

        <ErrorDisplay :requestError="this.requestError" @closed="clearError" />

        <div v-loading="loadingTournamentPrizes">
            <DataTable
                :items="tournamentPrizes.data"
                :schema="schema"
                :autoSort="false"
                @header-clicked="setSort"
                :fields="['prizeId', 'category', 'tableName', 'totalPrize']"
                :sortField="options.sortBy"
                :sortDir="options.sortDir"
                @item-clicked="itemClicked"
            />

            <Paginator
                v-if="tournamentPrizes.data"
                :data="tournamentPrizes"
                :elementsPerPage="options.pageSize"
                @prev-page="prevPage"
                @next-page="nextPage"
                @set-page-size="setPageSize"
            />
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { TournamentPrize } from '../models/tournament-prize';
import moment from 'moment';
import FileSaver from 'file-saver';
import { retrieveDtos, deleteConfirmDto, createConfirmDto, updateConfirmDto } from '../utils/crud-confirm';
import { isBaselineReader } from '../services/authGroups';

export default {
    data() {
        return {
            schema: TournamentPrize.schema,
            schemaAdd: TournamentPrize.schemaAdd,
            layout: TournamentPrize.layout,
            editDialogVisible: false,
            addDialogVisible: false,
            selectedTournamentPrize: null,
            requestError: null,
            isBaselineReader: isBaselineReader(),
        };
    },
    mounted() {
        if (this.$route.query.queryString) this.options.queryString = this.$route.query.queryString;
        this.refresh();
    },
    computed: mapGetters('tournamentPrizes', ['tournamentPrizes', 'loadingTournamentPrizes', 'savingTournamentPrize', 'deletingTournamentPrize', 'options']),
    methods: {
        ...mapActions('tournamentPrizes', ['createTournamentPrize', 'findTournamentPrizes', 'updateTournamentPrize', 'deleteTournamentPrize', 'exportCsv']),
        exportCsvClick() {
            this.exportCsv(this.options)
                .then((result) => {
                    const file = new File([result], `tournament_prizess_${moment().format('DD_MM_YYYY HH_mm_SS')}.csv`, { type: 'text/csv' });
                    FileSaver.saveAs(file);
                })
                .catch((error) => {
                    this.$notify({
                        title: 'CSV export',
                        message: 'CSV export failed',
                        type: 'error',
                        duration: 5000,
                    });
                });
        },
        refresh() {
            this.search();
        },
        itemClicked(item) {
            this.selectedTournamentPrize = new TournamentPrize(item);
            this.editDialogVisible = true;
        },
        addItem() {
            let params = {
                self: this,
                fCreate: this.createTournamentPrize,
                dto: this.selectedTournamentPrize,
                dtoName: 'Tournament Prize',
                callback: this.addCallback,
            };
            createConfirmDto(params);
        },
        updateItem() {
            let params = {
                self: this,
                fUpdate: this.updateTournamentPrize,
                dto: this.selectedTournamentPrize,
                dtoName: 'Tournament Prize',
                callback: this.addCallback,
            };
            updateConfirmDto(params);
        },
        addCallback() {
            this.refresh();
            this.addDialogVisible = false;
            this.editDialogVisible = false;
            this.requestError = null;
        },
        onChange() {},
        search() {
            let params = {
                self: this,
                fRetrieve: this.findTournamentPrizes,
                options: this.options,
                dtosName: 'Tournament Prizes',
            };
            retrieveDtos(params);
        },
        resetSearch() {
            this.options.queryString = null;
            this.search();
        },
        resetPage() {
            this.options.page = 0;
            this.search();
        },
        setPageSize(size) {
            this.options.pageSize = size;
            this.search();
        },
        reset() {
            this.options.queryString = null;
            this.options.page = 0;
            this.search();
        },
        setSort(event) {
            this.options.sortBy = event.property;
            this.options.sortDir = event.order;
            this.search();
        },
        prevPage() {
            this.options.page--;
            this.search();
        },
        nextPage() {
            this.options.page++;
            this.search();
        },
        deleteItem() {
            let params = {
                self: this,
                fDelete: this.deleteTournamentPrize,
                vConfirm: this.selectedTournamentPrize.data.prizeId,
                dto: this.selectedTournamentPrize,
                dtoName: 'Tournament Prize',
                vConfirmName: 'Prize ID',
                callback: this.addCallback,
            };
            deleteConfirmDto(params);
        },
        addTournamentPrize() {
            this.selectedTournamentPrize = new TournamentPrize({});
            this.addDialogVisible = true;
        },
        clearError() {
            this.requestError = null;
        },
    },
};
</script>

<style scoped>
.input-search {
    max-width: 384px;
}
</style>
