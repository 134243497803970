import axios from 'axios';

export default {
    namespaced: true,

    state: {
        runningRankingProcedure: false,
        rankingProcedureResult: null,
        rankingProcedureError: null,
        mostRecentAddedTournaments: [],
        nextOnTournaments: [],
        nextOffTournaments: [],
        loadingMostRecentAddedTournaments: false,
        loadingNextOnTournaments: false,
        loadingNextOffTournaments: false,
        mostRecentAddedTournamentsError: null,
        nextOnTournamentsError: null,
        nextOffTournamentsError: null,
        singlesSummary: {},
        doublesSummary: {},
        singlesRaceSummary: {},
        teamRaceSummaries: [],
        loadingSinglesSummary: false,
        loadingDoublesSummary: false,
        loadingSinglesRaceSummary: false,
        loadingTeamRaceSummaries: false,
        singlesSummaryError: null,
        doublesSummaryError: null,
        singlesRaceSummaryError: null,
        teamRaceSummaryError: null,
        progress: 0
    },

    getters: {
        runningRankingProcedure: (state) => state.runningRankingProcedure,
        rankingProcedureResult: (state) => state.rankingProcedureResult,
        rankingProcedureError: (state) => state.rankingProcedureError,
        nextOnTournaments: (state) => state.nextOnTournaments,
        mostRecentAddedTournaments: (state) => state.mostRecentAddedTournaments,
        nextOffTournaments: (state) => state.nextOffTournaments,
        loadingNextOnTournaments: (state) => state.loadingNextOnTournaments,
        loadingNextOffTournaments: (state) => state.loadingNextOffTournaments,
        loadingMostRecentAddedTournaments: (state) => state.loadingMostRecentAddedTournaments,
        mostRecentAddedTournamentsError: (state) => state.mostRecentAddedTournamentsError,
        nextOnTournamentsError: (state) => state.nextOnTournamentsError,
        nextOffTournamentsError: (state) => state.nextOffTournamentsError,
        singlesSummary: (state) => state.singlesSummary,
        doublesSummary: (state) => state.doublesSummary,
        singlesRaceSummary: (state) => state.singlesRaceSummary,
        teamRaceSummaries: (state) => state.teamRaceSummaries,
        loadingSinglesSummary: (state) => state.loadingSinglesSummary,
        loadingDoublesSummary: (state) => state.loadingDoublesSummary,
        loadingSinglesRaceSummary: (state) => state.loadingSinglesRaceSummary,
        loadingTeamRaceSummaries: (state) => state.loadingTeamRaceSummaries,
        singlesSummaryError: (state) => state.singlesSummaryError,
        doublesSummaryError: (state) => state.doublesSummaryError,
        singlesRaceSummaryError: (state) => state.singlesRaceSummaryError,
        teamRaceSummaryError: (state) => state.teamRaceSummaryError,
        rankingProcedureProgress: (state) => state.progress
    },

    mutations: {
        setRankingProcedureResult(state, data) {
            state.rankingProcedureResult = data;
        },
        startRankingProcedure(state) {
            state.runningRankingProcedure = true;
        },
        stopRankingProcedure(state) {
            state.runningRankingProcedure = false;
        },
        setRankingProcedureError(state, error) {
            state.rankingProcedureError = error;
        },
        clearRankingProcedureError(state) {
            state.rankingProcedureError = null;
        },
        setMostRecentAddedTournaments(state, data) {
            state.mostRecentAddedTournaments = data;
        },
        setNextOnTournaments(state, data) {
            state.nextOnTournaments = data;
        },
        setNextOffTournaments(state, data) {
            state.nextOffTournaments = data;
        },
        setLoadingMostRecentAddedTournaments(state, data) {
            state.loadingMostRecentAddedTournaments = data;
        },
        setLoadingNextOnTournaments(state, data) {
            state.loadingNextOnTournaments = data;
        },
        setLoadingNextOffTournaments(state, data) {
            state.loadingNextOffTournaments = data;
        },
        setMostRecentAddedTournamentsError(state, data) {
            state.mostRecentAddedTournamentsError = data;
        },
        setNextOnTournamentsError(state, data) {
            state.nextOnTournamentsError = data;
        },
        setNextOffTournamentsError(state, data) {
            state.nextOffTournamentsError = data;
        },
        setSinglesSummary(state, data) {
            state.singlesSummaryError = null;
            state.singlesSummary = data;
        },
        setDoublesSummary(state, data) {
            state.doublesSummaryError = null;
            state.doublesSummary = data;
        },
        setSinglesRaceSummary(state, data) {
            state.singlesRaceSummaryError = null;
            state.singlesRaceSummary = data;
        },
        setTeamRaceSummaries(state, data) {
            state.teamRaceSummaryError = null;
            state.teamRaceSummaries = data;
        },
        setSinglesSummaryError(state, error) {
            state.singlesSummaryError = error;
            state.singlesSummary = {};
        },
        setDoublesSummaryError(state, error) {
            state.doublesSummaryError = error;
            state.doublesSummary = {};
        },
        setTeamSummaryError(state, error) {
            state.teamRaceSummaryError = error;
            state.teamRaceSummaries = [];
        },
        setSinglesRaceSummaryError(state, error) {
            state.singlesRaceSummaryError = error;
            state.singlesRaceSummary = {};
        },
        setLoadingSinglesSummary(state, data) {
            state.loadingSinglesSummary = data;
        },
        setLoadingDoublesSummary(state, data) {
            state.loadingDoublesSummary = data;
        },
        setLoadingSinglesRaceSummary(state, data) {
            state.loadingSinglesRaceSummary = data;
        },
        setLoadingTeamRaceSummaries(state, data) {
            state.loadingTeamRaceSummaries = data;
        },
        setProgress(state, progress) {
            state.progress = progress;
        },
        clearProgress(state) {
            state.progress = 0;
        },
    },

    actions: {
        async startRankingProcedure(context, options) {
            let rankingDate = options.rankingDate;
            let nextRankingDate = options.nextRankingDate;
            context.commit('startRankingProcedure');
            context.commit('clearProgress');
            try {
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/Ranking/RankingProcedure/Run/${rankingDate}/${nextRankingDate}`);
                context.commit('setRankingProcedureError', null);
                context.commit('stopRankingProcedure');
                context.commit('setRankingProcedureResult', result.data);
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('stopRankingProcedure');
                context.commit('setRankingProcedureError', error);
                return Promise.reject(error);
            }
        },
        async dryRunRankingProcedure(context, options) {
            let rankingDate = options.rankingDate;
            let playerId = options.playerId;
            context.commit('setLoadingSinglesSummary', true);
            context.commit('setLoadingDoublesSummary', true);
            context.commit('setLoadingSinglesRaceSummary', true);
            context.commit('setLoadingTeamRaceSummaries', true);
            try {
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/Ranking/RankingProcedure/DryRun/${rankingDate}/${playerId}`);
                context.commit('setSinglesSummary', result.data.singlesComputationResults[0]);
                context.commit('setDoublesSummary', result.data.doublesComputationResults[0]);
                context.commit('setSinglesRaceSummary', result.data.raceSinglesComputationResults[0]);
                context.commit('setTeamRaceSummaries', result.data.raceDoublesComputationResults);
                context.commit('setLoadingSinglesSummary', false);
                context.commit('setLoadingDoublesSummary', false);
                context.commit('setLoadingSinglesRaceSummary', false);
                context.commit('setLoadingTeamRaceSummaries', false);
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('setLoadingSinglesSummary', false);
                context.commit('setLoadingDoublesSummary', false);
                context.commit('setLoadingSinglesRaceSummary', false);
                context.commit('setLoadingTeamRaceSummaries', false);
                context.commit('setSinglesSummaryError', error);
                context.commit('setDoublesSummaryError', error);
                context.commit('setSinglesRaceSummaryError', error);
                context.commit('setTeamRaceSummaryError', error);
                return Promise.reject(error);
            }
        },
        async getNextOnTournaments(context, rankingDate) {
            context.commit('setLoadingNextOnTournaments', true);
            try {
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/Ranking/RankingProcedure/${rankingDate}/NextOnEditions`);
                context.commit('setNextOnTournamentsError', null);
                context.commit('setNextOnTournaments', result.data);
                context.commit('setLoadingNextOnTournaments', false);
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('setNextOnTournaments', []);
                context.commit('setNextOffTournamentsError', error);
                context.commit('setLoadingNextOnTournaments', false);
                return Promise.reject(error);
            }
        },
        async getMostRecentAddedTournaments(context, rankingDate) {
            context.commit('setLoadingMostRecentAddedTournaments', true);
            try {
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/Ranking/RankingProcedure/${rankingDate}/MostRecentAdded`);
                context.commit('setMostRecentAddedTournamentsError', null);
                context.commit('setMostRecentAddedTournaments', result.data);
                context.commit('setLoadingMostRecentAddedTournaments', false);
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('setMostRecentAddedTournaments', []);
                context.commit('setMostRecentAddedTournamentsError', error);
                context.commit('setLoadingMostRecentAddedTournaments', false);
                return Promise.reject(error);
            }
        },
        async getNextOffTournaments(context, rankingDate) {
            context.commit('setLoadingNextOffTournaments', true);
            try {
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/Ranking/RankingProcedure/${rankingDate}/NextOffEditions`);
                context.commit('setNextOffTournamentsError', null);
                context.commit('setNextOffTournaments', result.data);
                context.commit('setLoadingNextOffTournaments', false);
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('setNextOffTournaments', []);
                context.commit('setNextOffTournamentsError', error);
                context.commit('setLoadingNextOffTournaments', false);
                return Promise.reject(error);
            }
        },
        async clearSummeries(context) {
            context.commit('setSinglesSummary', {});
            context.commit('setDoublesSummary', {});
            context.commit('setSinglesRaceSummary', {});
        },
        getTeamId(team) {
            if (team.length < 2) {
                return;
            }
            var teamId = {
                item1: Math.min(team[0].id, team[1].id),
                item2: Math.max(team[0].id, team[1].id),
            };
            return teamId;
        },
    },
};
