import { isValid, Model } from './model';
import { icoCountries } from '../utils/country-codes';

export class DoublesEntry extends Model {
    isValid() {
        return isValid(DoublesEntry.schema, this);
    }
}

DoublesEntry.layout = {
    children: [
        {
            children: ['recordKey', 'combinedRank', { type: 'spacer' }],
        },
        {
            children: ['playerId', 'playerFullName', 'playerNationality'],
        },
        {
            children: ['partnerId', 'partnerFullName', 'partnerNationality'],
        },
        {
            children: ['oneOfBothPlayersEntryType', 'entryDate', 'entryStatus'],
        },
        {
            children: ['playerEntryDoublesRank', 'playerEntrySinglesRank'],
        },
        {
            children: ['playerEntryType', 'playerEntrySpecialRank'],
        },
        {
            children: ['partnerEntryDoublesRank', 'partnerEntrySinglesRank'],
        },
        {
            children: ['partnerEntryType', 'partnerEntrySpecialRank'],
        },
        {
            children: ['entryPreference', 'within'],
        },
    ],
};

DoublesEntry.schema = {
    fields: {
        // TOURN_ENTRY_D
        recordKey: { displayName: 'Record key', type: 'number', readonly: true, placeholder: 'Will be populated' },
        tournamentId: { displayName: 'Tournament', type: 'number', readonly: true, required: true },
        season: { displayName: 'Year', type: 'number', readonly: true, required: true },
        playerId: { displayName: 'Player', type: 'player', readonlyForEdit: true, required: true },
        partnerId: { displayName: 'Partner', type: 'player', readonlyForEdit: true, required: true },
        entryDate: { displayName: 'Entry date', type: 'date', required: true, clearable: true },
        entryStatus: {
            displayName: 'Entry Status', type: 'string', required: true, clearable: true,
            lookup: [
                { id: 'EN', displayName: 'Entered' },
                { id: 'NE', displayName: 'NotEntered' },
                { id: 'MD', displayName: 'Accepted Into Main' },
                { id: 'WM', displayName: 'Withdrawn From Main Draw (after deadline)' },
                { id: 'WD', displayName: 'Withdrawn Before Entry Deadline' },
                { id: 'WE', displayName: 'Withdrawn After Entry Deadline' },
                { id: 'WT', displayName: 'Withdrawn By Wta Office' },
                { id: 'OW', displayName: 'Obsolete Withdraw' },
                { id: 'OE', displayName: 'Obsolete Enter' },
            ],
        },
        entryMethod: { displayName: 'Entry Method', type: 'string', clearable: true },
        oneOfBothPlayersEntryType: {
            displayName: 'Entry type', type: 'string', clearable: true,
            lookup: [
                { id: 'S', displayName: 'One entry rank is a special rank' },
                { id: ' ', displayName: 'All ranks are normal ranks' },
            ],
        },
        entryPreference: { displayName: 'Entry Preference', type: 'string', clearable: true },
        entryMode: { displayName: 'Entry Method', type: 'string', clearable: true },
        comLevel: { displayName: 'Com Level', type: 'string', clearable: true },
        wdCode: { displayName: 'Withdraw Code', type: 'string', clearable: true },
        wdDate: { displayName: 'Withdraw Date', type: 'date', clearable: true },
        queMd: { displayName: 'Que MD', type: 'number', clearable: true },
        queQual: { displayName: 'Que Qual', type: 'number', clearable: true },
        age: { displayName: 'Age', type: 'number', clearable: true },
        orgStatus: {
            displayName: 'Original Status', type: 'string', clearable: true,
            lookup: [
                { id: 'EN', displayName: 'Entered' },
                { id: 'NE', displayName: 'NotEntered' },
                { id: 'MD', displayName: 'Accepted Into Main' },
                { id: 'WM', displayName: 'Withdrawn From Main Draw (after deadline)' },
                { id: 'WD', displayName: 'Withdrawn Before Entry Deadline' },
                { id: 'WE', displayName: 'Withdrawn After Entry Deadline' },
                { id: 'WT', displayName: 'Withdrawn By Wta Office' },
                { id: 'OW', displayName: 'Obsolete Withdraw' },
                { id: 'OE', displayName: 'Obsolete Enter' },
            ],
        },
        tranDesc: { displayName: 'Withdraw Reason', type: 'string', clearable: true },
        enteredBy: { displayName: 'Entered By', type: 'string', clearable: true },
        enterTime: { displayName: 'Enter Time', type: 'string', clearable: true },
        within: { displayName: 'Within', type: 'string', clearable: true },
        playerEntrySinglesRank: { displayName: 'Player Singles Rank', type: 'number', clearable: true },
        playerEntryDoublesRank: { displayName: 'Player Doubles Rank', type: 'number', clearable: true },
        playerEntrySpecialRank: { displayName: 'Player SR', type: 'number', clearable: true },
        playerEntryType: {
            displayName: 'Player Entry type', type: 'string', clearable: true,
            lookup: [
                { id: 'S', displayName: 'Player Entry Rank is Special Rank' },
                { id: ' ', displayName: 'Player Entry Rank is Normal Rank' },
            ],
        },
        partnerEntrySinglesRank: { displayName: 'Partner Singles Rank', type: 'number', clearable: true },
        partnerEntryDoublesRank: { displayName: 'Partner Doubles Rank', type: 'number', clearable: true },
        partnerEntrySpecialRank: { displayName: 'Partner SR', type: 'number', clearable: true },
        partnerEntryType: {
            displayName: 'Partner Entry type', type: 'string', clearable: true,
            lookup: [
                { id: 'S', displayName: 'Partner Entry Rank is Special Rank' },
                { id: ' ', displayName: 'Partner Entry Rank is Normal Rank' },
            ],
        },
        teamRank: { displayName: 'Team Rank', type: 'number', clearable: true },
        modifiedBy: { displayName: 'Modified By', type: 'string', clearable: true },
        withdrawingPlayerNbr: { displayName: 'Withdrawing Player', type: 'player', clearable: true },
        blComment: { displayName: 'BlComment', type: 'string' },
        combinedRank: { displayName: 'Combined Rank', type: 'number', required: true },

        // TOURN_TABLE
        tournamentStartDate: { displayName: 'Tournament Start Date', type: 'date', readonly: true },
        tournamentName: { displayName: 'Tournament Name', type: 'string', readonly: true },

        // CONTACTS
        playerFirstName: { displayName: 'First name', type: 'string', readonly: true, clearable: true },
        playerLastName: { displayName: 'Last name', type: 'string', readonly: true, clearable: true },
        playerFullName: { displayName: 'Player Name', type: 'string', readonly: true, clearable: true, placeholder: 'Will be populated' },
        playerNationality: { displayName: 'Player nationality', type: 'nationality', lookup: Object.keys(icoCountries), readonly: true, clearable: true , placeholder: 'Will be populated' },
        playerEmail: { displayName: 'Email', type: 'string', readonly: true, clearable: true },
        playerDateOfBirth: { displayName: 'Date of birth', type: 'date', readonly: true, clearable: true },

        partnerFirstName: { displayName: 'Partner first name', type: 'string', readonly: true, clearable: true },
        partnerLastName: { displayName: 'Partner last name', type: 'string', readonly: true, clearable: true },
        partnerFullName: { displayName: 'Partner Name', type: 'string', readonly: true, clearable: true, placeholder: 'Will be populated' },
        partnerNationality: { displayName: 'Partner nationality', type: 'nationality', lookup: Object.keys(icoCountries), readonly: true, clearable: true, placeholder: 'Will be populated'  },
        partnerEmail: { displayName: 'Partner email', type: 'string', readonly: true, clearable: true },
        partnerDateOfBirth: { displayName: 'Partner date of birth', type: 'date', readonly: true, clearable: true },

        // PLAYER_RANK_CURRENT
        playerCurrentDoublesRank: { displayName: 'Doubles Rank', type: 'int', readonly: true, clearable: true },
        partnerCurrentDoublesRank: { displayName: 'Doubles Rank', type: 'int', readonly: true, clearable: true },

        // OWN
        playerAgeCurrent: { displayName: 'Current age', type: 'number', readonly: true, clearable: true },
        playerAgeAtTournamentStart: { displayName: 'Player age', type: 'number', readonly: true, clearable: true },
        partnerAgeCurrent: { displayName: 'Partner current age', type: 'number', readonly: true, clearable: true },
        partnerAgeAtTournamentStart: { displayName: 'Partner age', type: 'number', readonly: true, clearable: true },

    },
};
