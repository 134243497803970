<template>
    <div>
        <DialogAddItem
            :visibleSync="addDialogVisible"
            dtoName="Player Payment"
            :selected="selectedPlayerPayment"
            :schema="schemaAdd"
            :layout="layout"
            @save="onSave"
            @change="onChange"
            @close="addDialogVisible = false"
        />

        <DialogEditItem
            :visibleSync="editDialogVisible"
            dtoName="Player Payment"
            :selected="selectedPlayerPayment"
            :schema="schemaAdd"
            :layout="layout"
            showNav="true"
            :widthPerc="90"
            @save="onSave"
            @change="onChange"
            @delete="onDelete"
            @close="editDialogVisible = false"
            @prev="gotoPrevPlayerPayment"
            @next="gotoNextPlayerPayment"
        />

        <PlayerPaymentImportCsv
            :visibleSync="importDialogVisible"
            :tournYear="tournYear"
            :tournNbr="tournNbr"
            @close="importDialogVisible = false"
            @success="onImport"
        />

        <div v-if="!isBaselineReader" class="flex-row mt-0">
            <span class="spacer"></span>
            <!-- <el-button @click="onAdd" plain type="primary" size="mini"> Add Player Payment </el-button> -->
            <el-button @click="importDialogVisible = true" plain type="primary" size="mini"> Import Player Payments </el-button>
            <el-button @click="onDeleteAll" plain type="danger" size="mini">Delete ALL Player Payments </el-button>
        </div>

        <ErrorDisplay :requestError="this.requestError" @closed="clearError" class="mt-16" />

        <DataTable
            v-loading="loadingPlayerPayments"
            :items="playerPayments"
            :fields="[
                'idp',
                'playerNbr',
                'playerName',
                'nationality',
                'singlesRound',
                'doublesRound',
                'singlePriceMoney',
                'doublePriceMoney',
                'luckyLoserQualifyingRound',
                'luckyLoserQualifyingAmount',
                'luckyLoserDeductRound',
                'luckyLoserDeductAmount',
                'subtotal',
                'tax',
                'totalDeduction',
                'netPayment'
            ]"
            :schema="schema"
            @item-clicked="onItemClicked"
        />
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { PlayerPayment } from '../models/player-payment';
import { retrieveDtos, deleteConfirmDto, createConfirmDto, updateConfirmDto } from '../utils/crud-confirm';
import PlayerPaymentImportCsv from '../widgets/PlayerPaymentImportCsv.vue';
import { isBaselineReader } from '../services/authGroups';

export default {
    components: { PlayerPaymentImportCsv },
    props: ['tournNbr', 'tournYear'],
    data() {
        return {
            schema: PlayerPayment.schema,
            schemaAdd: PlayerPayment.schemaAdd,
            layout: PlayerPayment.layout,
            addDialogVisible: false,
            editDialogVisible: false,
            importDialogVisible: false,
            selectedPlayerPayment: null,
            playerPaymentPointer: null,
            mode: null,
            requestError: null,
            isBaselineReader: isBaselineReader(),
        };
    },
    mounted() {
        this.refresh();
    },
    computed: {
        ...mapGetters('tournamentsPlayerPayments', ['playerPayments', 'loadingPlayerPayments', 'loadingError']),
    },
    methods: {
        ...mapActions('tournamentsPlayerPayments', [
            'findPlayerPayments',
            'createPlayerPayment',
            'updatePlayerPayment',
            'deletePlayerPayment',
            'deletePlayerPayments',
        ]),
        refresh() {
            let params = {
                self: this,
                fRetrieve: this.findPlayerPayments,
                options: {
                    tournYear: this.tournYear,
                    tournNbr: this.tournNbr,
                },
                dtosName: 'Player Payments',
            };
            retrieveDtos(params);
        },
        onItemClicked(item, index) {
            this.selectedPlayerPayment = new PlayerPayment(item);
            this.mode = 'edit';
            this.editDialogVisible = true;
            this.playerPaymentPointer = index;
        },
        gotoPrevPlayerPayment() {
            if (this.playerPaymentPointer == 0) {
                return;
            }
            this.playerPaymentPointer -= 1;
            this.selectedPlayerPayment = new PlayerPayment(this.playerPayments[this.playerPaymentPointer]);
        },
        gotoNextPlayerPayment() {
            if (this.playerPaymentPointer == this.playerPayments.length - 1) {
                return;
            }
            this.playerPaymentPointer += 1;
            this.selectedPlayerPayment = new PlayerPayment(this.playerPayments[this.playerPaymentPointer]);
        },
        onImport(response) {
            console.log(response);
            this.refresh();
        },
        onAdd() {
            this.selectedPlayerPayment = new PlayerPayment({
                tournYear: this.tournYear,
                tournNbr: this.tournNbr,
            });
            this.mode = 'add';
            this.addDialogVisible = true;
        },
        onSave() {
            if (this.mode === 'add') {
                let params = {
                    self: this,
                    fCreate: this.createPlayerPayment,
                    dto: this.selectedPlayerPayment,
                    dtoName: 'Player Payment',
                    callback: this.crudCallback,
                };
                createConfirmDto(params);
            } else {
                let params = {
                    self: this,
                    fUpdate: this.updatePlayerPayment,
                    dto: this.selectedPlayerPayment,
                    dtoName: 'Player Payment',
                    callback: this.crudCallback,
                };
                updateConfirmDto(params);
            }
        },
        onChange() {},
        onDelete() {
            let params = {
                self: this,
                fDelete: this.deletePlayerPayment,
                vConfirm: this.tournNbr,
                dto: this.selectedPlayerPayment,
                dtoName: 'Player Payment',
                vConfirmName: 'Tournament ID',
                callback: this.crudCallback,
            };
            deleteConfirmDto(params);
        },
        onDeleteAll() {
            let params = {
                self: this,
                fDelete: this.deletePlayerPayments,
                vConfirm: this.tournNbr,
                dto: {
                    tournYear: this.tournYear,
                    tournNbr: this.tournNbr,
                },
                dtoName: 'ALL Player Payments',
                vConfirmName: 'Tournament ID',
                callback: this.crudCallback,
            };
            deleteConfirmDto(params);
        },
        crudCallback() {
            this.refresh();
            this.requestError = null;
            this.addDialogVisible = false;
            this.editDialogVisible = false;
        },
        clearError() {
            this.requestError = null;
        },
    },
};
</script>

<style></style>
