var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('ErrorDisplay',{attrs:{"requestError":this.loadingErrorShadowTeamRanking},on:{"closed":_vm.clearError}}),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loadingShadowTeamRanking),expression:"loadingShadowTeamRanking"}]},[_c('DataTable',{attrs:{"autoSort":false,"fields":[
                'player1Id',
                'player1ConcatName',
                'player1Nationality',
                'player1BirthDate',
                'player2Id',
                'player2ConcatName',
                'player2Nationality',
                'player2BirthDate',
                'tournamentCount',
                'points',
            ],"items":_vm.shadowTeamRankings.data,"schema":_vm.schema},on:{"header-clicked":_vm.setSort}}),(_vm.shadowTeamRankings.data)?_c('Paginator',{attrs:{"data":_vm.shadowTeamRankings,"elementsPerPage":_vm.options.pageSize},on:{"prev-page":_vm.prevPage,"next-page":_vm.nextPage,"set-page-size":_vm.setPageSize}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }