<template>
    <div>
        <el-dialog :close-on-click-modal="false"
                   :show-close="false"
                   :visible.sync="showLinkDialog"
                   title="Map to existing player"
                   top="10vh"
                   width="65%">
            <FormGroup :layout="layoutPlayerLink"
                       :model="selectedItfPlayerLink"
                       :schema="schemaPlayerLink"
                       :suppressPlayerForSearch="selectedItfPlayerLink ? selectedItfPlayerLink.original.playerNbr : null"
                       @change="onChange"
                       @player-selected="onPlayerSelected"/>
            <div class="dialog-footer">
                <el-button :disabled="disabledSaveButton" type="primary" @click="onSave"> Save Mapping</el-button>
                <el-button @click="onClose">Close</el-button>
                <span class="spacer"></span>
            </div>

        </el-dialog>

        <!-- ------------------------------------------------------------------------------------------------------- -->

        <div class="flex-row mt-0 mb-32">
            <h2>ITF Import Summary for {{ itfName() }}</h2>
        </div>


        <div class="flex-row mt-0 mb-16">
            <h3>Imported Tournament</h3>
            <span class="spacer"></span>

            <el-button v-if="!isDryRun" plain size="mini" type="primary" @click="editTournament">
                Edit tournament
            </el-button>
        </div>
        <FormGroup :allReadonly="true" :layout="layoutTournament" :model="importedTournament"
                   :schema="schemaTournament" @change="onChange"/>

        <div class="flex-row mt-32 mb-0">
            <h3>Imported Round Results: {{ itfImportResult.importedRounds.length }}</h3>
        </div>

        <div class="flex-row mt-32 mb-16">
            <h3>Imported Player Links</h3>
        </div>

        <DataTable v-if="isDryRun"
                   :autoSort="false"
                   :fields="[
                           'itfPlayerNbr',
                           'playerNbr',
                           'wtaNameFirst',
                           'wtaNameLast',
                           'wtaNationality',
                           'wtaBirthDate',
                           'mapToPlayerButton'
                           ]"
                   :items="itfImportResult.importedPlayerLinks"
                   :schema="schemaPlayerLink"
                   @edit-player="onEditPlayer"
                   @show-itf-link-dialog="onShowLinkDialog"
        />

        <DataTable v-else
                   :autoSort="false"
                   :fields="[
                           'itfPlayerNbr',
                           'playerNbr',
                           'wtaNameFirst',
                           'wtaNameLast',
                           'wtaNationality',
                           'wtaBirthDate',
                           'mapToPlayerButton',
                           'openPlayerButton'
                           ]"
                   :items="itfImportResult.importedPlayerLinks"
                   :schema="schemaPlayerLink"
                   @edit-player="onEditPlayer"
                   @show-itf-link-dialog="onShowLinkDialog"
        />

    </div>
</template>

<script>
import { Tournament } from "@/models/tournament";
import { Player } from "@/models/player";
import { ItfPlayerLink } from "@/models/itf-player-link";
import { mapActions } from "vuex";
import { updateConfirmDto } from "@/utils/crud-confirm";
import Vue from "vue";
import _ from 'lodash';


export default {
    props: ['itfImportResult'],
    data() {
        return {
            schemaTournament: Tournament.schema,
            layoutTournament: Tournament.layoutItfImport,
            schemaPlayer: Player.schema,
            schemaPlayerLink: ItfPlayerLink.schema,
            layoutPlayerLink: ItfPlayerLink.layoutMapPlayer,
            isDryRun: this.itfImportResult.request.isDryRun,
            importedTournament: new Tournament(this.itfImportResult.importedTournament),
            showLinkDialog: false,
            selectedItfPlayerLink: null,
            selectedItfPlayerLinkIndex: null,
        };
    },
    mounted() {
        for (let i = 0; i < this.itfImportResult.importedPlayerLinks.length; i++) {
            if (this.itfImportResult.importedPlayerLinks[i].data && this.itfImportResult.importedPlayerLinks[i].original)
                continue;
            Vue.set(this.itfImportResult.importedPlayerLinks, i, new ItfPlayerLink(this.itfImportResult.importedPlayerLinks[i]));
        }
    },
    computed: {
        disabledSaveButton() {
            return !this.selectedItfPlayerLink ||
                    !this.selectedItfPlayerLink.isValid() ||
                    !this.selectedItfPlayerLink.hasChanges();
        }
    },
    methods: {
        ...mapActions('itfPlayerLinks', [
            'addOrUpdateItfPlayerLink',
            'mapImportedToExitingPlayer',
            'deleteItfPlayerLink',
        ]),
        ...mapActions('tournamentRounds', ['propagateData',]),
        editTournament() {
            let tournYear = this.importedTournament.data.season;
            let tournNbr = this.importedTournament.data.tournamentId;
            let route = this.$router.resolve({ path: `/tournaments/${tournYear}/${tournNbr}` });
            window.open(route.href, '_blank');

        },
        itfName() {
            return this.itfImportResult.request.officialName + ', ' + this.itfImportResult.request.providerTournamentId
                    + (this.isDryRun ? ' - Dry Run' : '');
        },
        onChange(event) {
        },
        onShowLinkDialog(event) {
            this.selectedItfPlayerLink = _.cloneDeep(this.itfImportResult.importedPlayerLinks[event.index]);
            this.selectedItfPlayerLink.data.selectedPlayerNbr = null;
            this.selectedItfPlayerLink.data.selectedNameFirst = null;
            this.selectedItfPlayerLink.data.selectedNameLast = null;
            this.selectedItfPlayerLink.data.selectedBirthDate = null;
            this.selectedItfPlayerLink.data.selectedNationality = null;
            this.selectedItfPlayerLinkIndex = event.index;
            this.showLinkDialog = true;
        },
        onEditPlayer(event) {
            let route = this.$router.resolve({ path: `/players/${event.item.data.playerNbr}` });
            window.open(route.href, '_blank');

        },
        onSave() {
            let oldDto = _.clone(this.selectedItfPlayerLink.data);
            let playersToDelete = this.itfImportResult.importedPlayers
                    .filter((player) => player.id === this.selectedItfPlayerLink.original.playerNbr);
            let toDelete = playersToDelete.length === 1 ? playersToDelete[0] : null;
            this.deleteItfPlayerLink(this.selectedItfPlayerLink.data.playerNbr);
            this.selectedItfPlayerLink.data.playerNbr = this.selectedItfPlayerLink.data.selectedPlayerNbr;
            this.selectedItfPlayerLink.data.wtaNameFirst = this.selectedItfPlayerLink.data.selectedNameFirst;
            this.selectedItfPlayerLink.data.wtaNameLast = this.selectedItfPlayerLink.data.selectedNameLast;
            this.selectedItfPlayerLink.data.wtaBirthDate = this.selectedItfPlayerLink.data.selectedBirthDate;
            this.selectedItfPlayerLink.data.wtaNationality = this.selectedItfPlayerLink.data.selectedNationality;
            if (this.isDryRun) {
                let params = {
                    self: this,
                    fUpdate: this.addOrUpdateItfPlayerLink,
                    dto: this.selectedItfPlayerLink.data,
                    dtoName: 'ITF Player Link',
                    callback: this.updateCallback,
                };
                updateConfirmDto(params);
            } else {
                let options = {
                    tournamentYear: this.itfImportResult.importedTournament.season,
                    tournamentNumber: this.itfImportResult.importedTournament.tournamentId,
                    oldDto: oldDto,
                    newDto: this.selectedItfPlayerLink.data,
                    toDelete: toDelete,
                };
                let params = {
                    self: this,
                    fUpdate: this.mapImportedToExitingPlayer,
                    dto: options,
                    dtoName: 'ITF Player Link',
                    callback: this.updateCallback,
                };
                updateConfirmDto(params);
            }
        },
        updateCallback() {
            if (!this.isDryRun) {
                let params = {
                    season: this.itfImportResult.importedTournament.season,
                    tournamentId: this.itfImportResult.importedTournament.tournamentId,
                };
                this.propagateData(params);
            }
            Vue.set(this.itfImportResult.importedPlayerLinks, this.selectedItfPlayerLinkIndex, this.selectedItfPlayerLink);
            this.onClose();
        },
        onClose() {
            this.showLinkDialog = false;
        },
        onPlayerSelected(event) {
            this.selectedItfPlayerLink.data.selectedPlayerNbr = event.id;
            this.selectedItfPlayerLink.data.selectedNameFirst = event.firstName;
            this.selectedItfPlayerLink.data.selectedNameLast = event.lastName;
            this.selectedItfPlayerLink.data.selectedBirthDate = event.dateOfBirth;
            this.selectedItfPlayerLink.data.selectedNationality = event.nationality;
        }
    },
};
</script>

<style scoped>
</style>
