<template>
    <el-dialog :visible.sync="visibleSync" :close-on-click-modal="false" :title="title()" :width="width()" :show-close="false" top="10vh">
        <FormGroup :model="selected" :schema="schema" :layout="layout" @change="onChange" />

        <div class="dialog-footer" v-if="!isBaselineReader">
            <el-button type="primary" @click="onSave" :disabled="!selected || !selected.isValidForAdd() || !selected.hasChanges()">Add {{ dtoName }}</el-button>
            <el-button @click="onClose">Close</el-button>
            <span class="spacer"></span>
        </div>
    </el-dialog>
</template>

<script>
import { isBaselineReader } from '../services/authGroups';

export default {
    props: ['visibleSync', 'dtoName', 'selected', 'schema', 'layout', 'widthPerc'],
    data() {
        return {
            isBaselineReader: isBaselineReader(),
        };
    },
    methods: {
        onSave() {
            this.$emit('save');
        },
        onChange(data) {
            this.$emit('change', data);
        },
        onClose() {
            this.$emit('close');
        },
        title() {
            return 'Add ' + this.dtoName;
        },
        width() {
            return this.widthPerc ? this.widthPerc.toString() + '%' : '65%';
        },
    },
};
</script>
