import axios from 'axios';
import { TournamentResult } from '../models/tournament-result';
import flatten from 'flat';

export default {
    namespaced: true,

    state: {
        tournamentResults: [],
        loadingTournamentResults: false,
        loadingError: null,
        savingTournamentResult: false,
        savingError: null,
        deletingTournamentResult: false,
        deletingError: null,
    },

    getters: {
        tournamentResults: (state) => state.tournamentResults,
        loadingTournamentResults: (state) => state.loadingTournamentResults,
        loadingError: (state) => state.loadingError,
        savingTournamentResult: (state) => state.savingTournamentResult,
        savingError: (state) => state.savingError,
        deletingTournamentResult: (state) => state.deletingTournamentResult,
        deletingError: (state) => state.deletingError,
    },

    mutations: {
        setTournamentResults(state, data) {
            state.tournamentResults = data;
        },

        startLoadingTournamentResults(state) {
            state.loadingTournamentResults = true;
        },

        stopLoadingTournamentResults(state) {
            state.loadingTournamentResults = false;
        },

        setLoadingError(state, error) {
            state.loadingError = error;
        },

        clearLoadingError(state) {
            state.loadingError = null;
        },

        startSavingTournamentResult(state) {
            state.savingTournamentResult = true;
        },

        stopSavingTournamentResult(state) {
            state.savingTournamentResult = false;
        },

        setSavingError(state, error) {
            state.savingError = error;
        },

        clearSavingError(state) {
            state.savingError = null;
        },

        startDeletingTournamentResult(state) {
            state.deletingTournamentResult = true;
        },

        stopDeletingTournamentResult(state) {
            state.deletingTournamentResult = false;
        },

        setDeletingError(state, error) {
            state.deletingError = error;
        },

        clearDeletingError(state) {
            state.deletingError = null;
        },
    },

    actions: {
        async findTournamentResults(context, tournament) {
            context.commit('startLoadingTournamentResults');
            try {
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/Edition/${tournament.year}/${tournament.id}/Result`);
                context.commit('stopLoadingTournamentResults');
                context.commit('setTournamentResults', result.data);
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('stopLoadingTournamentResults');
                context.commit('setTournamentResults', []);
                context.commit('setLoadingError', error);
                return Promise.reject(error);
            }
        },

        async createTournamentResult(context, data) {
            context.commit('startSavingTournamentResult');
            try {
                const result = await axios.post(`${process.env.VUE_APP_API_URL}/Edition/${data.data.season}/${data.data.tournamentId}/Result`, data.data);
                context.commit('stopSavingTournamentResult');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('stopSavingTournamentResult');
                context.commit('setSavingError', error);
                return Promise.reject(error);
            }
        },

        async updateTournamentResult(context, data) {
            context.commit('startSavingTournamentResult');
            try {
                const result = await axios.put(`${process.env.VUE_APP_API_URL}/Edition/${data.data.season}/${data.data.tournamentId}/Result`, data.data);
                context.commit('stopSavingTournamentResult');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('stopSavingTournamentResult');
                context.commit('setSavingError', error);
                return Promise.reject(error);
            }
        },

        async deleteTournamentResult(context, data) {
            context.commit('startDeletingTournamentResult');
            try {
                const result = await axios.post(`${process.env.VUE_APP_API_URL}/Edition/${data.data.season}/${data.data.tournamentId}/Result/Delete`, data.data);
                context.commit('stopDeletingTournamentResult');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('stopDeletingTournamentResult');
                context.commit('setDeletingError', error);
                return Promise.reject(error);
            }
        },

        async deleteTournamentResults(context, data) {
            context.commit('startDeletingTournamentResult');
            try {
                const result = await axios.delete(`${process.env.VUE_APP_API_URL}/Edition/${data.season}/${data.tournamentId}/Result`);
                context.commit('stopDeletingTournamentResult');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('stopDeletingTournamentResult');
                context.commit('setDeletingError', error);
                return Promise.reject(error);
            }
        },

        async createTournamentResults(context, data) {
            context.commit('startLoadingTournamentResults');
            try {
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/Edition/${data.season}/${data.tournamentId}/Result/CreateResults`);
                context.commit('setTournamentResults', result.data);
                context.commit('stopLoadingTournamentResults');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('stopLoadingTournamentResults');
                return Promise.reject(error);
            }
        },

        async findTournamentResultsForPlayerDevelopment(context, data) {
            if (!data.playerId)
                return;
            context.commit('startLoadingTournamentResults');
            try {
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/Edition/Result/PlayerDevelopment/${data.playerId}`);
                context.commit('stopLoadingTournamentResults');
                context.commit('setTournamentResults', result.data);
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('stopLoadingTournamentResults');
                context.commit('setTournamentResults', []);
                context.commit('setLoadingError', error);
                return Promise.reject(error);
            }
        },
    },
};
