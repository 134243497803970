import { Model, isValid } from './model';
import { icoCountries } from '../utils/country-codes';

export class PlayerPayment extends Model {
    isValid() {
        return isValid(PlayerPayment.schema, this);
    }
    isValidForAdd() {
        return isValid(PlayerPayment.schemaAdd, this);
    }
    isValidForEdit() {
        return isValid(PlayerPayment.schema, this);
    }
}

PlayerPayment.layout = {
    children: [
        {
            title: 'Player Information',
            children: ['playerNbr', { id: 'playerName', width: 2 }, 'nationality',],
        },
        {
            children: ['dlrEuro', 'currency', 'singlesRound', 'doublesRound'],
        },
        {
            title: 'Payment Details',
            children: ['singlePriceMoney', 'doublePriceMoney', 'subtotal', 'tax', 'wtaMembership', 'wtaAdminFee', 'wtaMassageFee',],
        },
        {
            children: ['wtaFines', 'ustaFines', 'itfFines', 'stringerFees', 'other', 'totalDeduction', 'netPayment'],
        },
        {
            children: ['luckyLoserQualifyingRound', 'luckyLoserQualifyingAmount', 'luckyLoserDeductRound', 'luckyLoserDeductAmount'],
        },
        {
            children: ['wtaFineDescription'],
        },
    ],
};

PlayerPayment.schema = {
    fields: {
        idp: { type: 'number', displayName: 'Number', required: true, readonly: true },
        tournYear: { type: 'number', displayName: 'Tourn Year', required: true, readonly: true },
        playerNbr: { type: 'player', displayName: 'Player', required: true, readonly: true },
        tournNbr: { type: 'number', displayName: 'Tourn Nbr', required: true, readonly: true },
        currency: { type: 'string', displayName: 'Player Currency', readonly: true, placeholder: 'not used' },
        playerName: { type: 'string', displayName: 'Player Name', readonly: true },
        nationality: { type: 'nationality', displayName: 'Nationality', readonly: true, lookup: Object.keys(icoCountries) },
        singlesRound: { type: 'string', displayName: 'Singles Round', readonly: true },
        doublesRound: { type: 'string', displayName: 'Doubles Round', readonly: true },
        singlePriceMoney: { type: 'currency', displayName: 'Single Prize Money', currency: 'dlrEuro', readonly: true },
        doublePriceMoney: { type: 'currency', displayName: 'Double Prize Money', currency: 'dlrEuro', readonly: true },
        mixedPriceMoney: { type: 'currency', displayName: 'Mixed Prize Money', currency: 'dlrEuro', readonly: true, placeholder: 'not used' },
        perDiem: { type: 'currency', displayName: 'Per Diem', currency: 'dlrEuro', readonly: true, placeholder: 'not used' },
        subtotal: { type: 'currency', displayName: 'Subtotal', currency: 'dlrEuro', readonly: true },
        tax: { type: 'currency', displayName: 'Tax', currency: 'dlrEuro', readonly: true },
        wtaMembership: { type: 'currency', displayName: 'WTA Membership', currency: 'dlrEuro', readonly: true },
        wtaInsurance: { type: 'currency', displayName: 'WTA Insurance', currency: 'dlrEuro', readonly: true, placeholder: 'not used' },
        wtaAdminFee: { type: 'currency', displayName: 'WTA Admin Fee', currency: 'dlrEuro', readonly: true },
        wtaMassageFee: { type: 'currency', displayName: 'WTA Massage Fee', currency: 'dlrEuro', readonly: true },
        wtaFines: { type: 'currency', displayName: 'WTA Fines', currency: 'dlrEuro', readonly: true },
        itfFines: { type: 'currency', displayName: 'WTA Other', currency: 'dlrEuro', readonly: true },
        ustaFines: { type: 'currency', displayName: 'WTA DME', currency: 'dlrEuro', readonly: true },
        cashAdvances: { type: 'currency', displayName: 'Cash Advances', currency: 'dlrEuro', readonly: true, placeholder: 'not used' },
        stringerFees: { type: 'currency', displayName: 'Stringer Fees', currency: 'dlrEuro', readonly: true },
        workPermitFee: { type: 'currency', displayName: 'Work Permit Fee', currency: 'dlrEuro', readonly: true, placeholder: 'not used' },
        hotel: { type: 'currency', displayName: 'Hotel', currency: 'dlrEuro', readonly: true },
        other: { type: 'currency', displayName: 'Tournament Other', currency: 'dlrEuro', readonly: true },
        totalDeduction: { type: 'currency', displayName: 'Total Deduction', currency: 'dlrEuro', readonly: true },
        netPayment: { type: 'currency', displayName: 'Net Payment', currency: 'dlrEuro', readonly: true },
        luckyLoserQualifyingRound: { type: 'string', displayName: 'Lucky Loser Qualifying Round', readonly: true },
        luckyLoserQualifyingAmount: { type: 'currency', displayName: 'Lucky Loser Qualifying Amount', currency: 'dlrEuro', readonly: true },
        luckyLoserDeductRound: { type: 'string', displayName: 'Lucky Loser Deduct Round', currency: 'dlrEuro', readonly: true },
        luckyLoserDeductAmount: { type: 'currency', displayName: 'Lucky Loser Deduct Amount', currency: 'dlrEuro', readonly: true },
        wtaFineDescription: { type: 'string', displayName: 'WTA Fine Description', readonly: true },
        dlrEuro: {
            type: 'string', displayName: 'Currency', readonly: true,
            lookup: [
                { id: 'D', displayName: 'USD' },
                { id: 'E', displayName: 'EUR' },
                { id: 'U', displayName: 'AUD' },
                { id: 'P', displayName: 'GBP' },
                { id: 'N', displayName: 'No' },
            ],
        },
        correla: { type: 'number', displayName: 'Correl', readonly: true },
        tournamentEndDate: { type: 'us-date', displayName: 'Date', readonly: true, clearble: true },
        tournamentName: { type: 'string', displayName: 'Tournament', readonly: true },

    },
};

PlayerPayment.schemaAdd = {
    fields: {
        idp: { type: 'number', displayName: 'Idp', required: true, },
        tournYear: { type: 'number', displayName: 'Tourn Year', required: true, },
        playerNbr: { type: 'player', displayName: 'Player', required: true, },
        tournNbr: { type: 'number', displayName: 'Tourn Nbr', required: true, },
        currency: { type: 'string', displayName: 'Player Currency', },
        playerName: { type: 'string', displayName: 'Player Name', },
        nationality: { type: 'string', displayName: 'Nationality', },
        singlesRound: { type: 'string', displayName: 'Singles Round', },
        doublesRound: { type: 'string', displayName: 'Doubles Round', },
        singlePriceMoney: { type: 'currency', displayName: 'Single Prize Money', currency: 'dlrEuro', },
        doublePriceMoney: { type: 'currency', displayName: 'Double Prize Money', currency: 'dlrEuro', },
        mixedPriceMoney: { type: 'currency', displayName: 'Mixed Prize Money', currency: 'dlrEuro', },
        perDiem: { type: 'currency', displayName: 'Per Diem', currency: 'dlrEuro', },
        subtotal: { type: 'currency', displayName: 'Subtotal', currency: 'dlrEuro', },
        tax: { type: 'currency', displayName: 'Tax', currency: 'dlrEuro', },
        wtaMembership: { type: 'currency', displayName: 'WTA Membership', currency: 'dlrEuro', },
        wtaInsurance: { type: 'currency', displayName: 'WTA Insurance', currency: 'dlrEuro', },
        wtaAdminFee: { type: 'currency', displayName: 'WTA Admin Fee', currency: 'dlrEuro', },
        wtaMassageFee: { type: 'currency', displayName: 'WTA Massage Fee', currency: 'dlrEuro', },
        wtaFines: { type: 'currency', displayName: 'WTA Fine', currency: 'dlrEuro', },
        itfFines: { type: 'currency', displayName: 'WTA Other', currency: 'dlrEuro', },
        ustaFines: { type: 'currency', displayName: 'WTA DME', currency: 'dlrEuro', },
        cashAdvances: { type: 'currency', displayName: 'Cash Advances', currency: 'dlrEuro', },
        stringerFees: { type: 'currency', displayName: 'Stringer Fee', currency: 'dlrEuro', },
        workPermitFee: { type: 'currency', displayName: 'Work Permit Fee', currency: 'dlrEuro', },
        hotel: { type: 'currency', displayName: 'Hotel', currency: 'dlrEuro', },
        other: { type: 'currency', displayName: 'Tournament Other', currency: 'dlrEuro', },
        totalDeduction: { type: 'currency', displayName: 'Total Deduction', currency: 'dlrEuro', },
        netPayment: { type: 'currency', displayName: 'Net Payment', currency: 'dlrEuro', },
        luckyLoserQualifyingRound: { type: 'string', displayName: 'Lucky Loser Qualifying Round' },
        luckyLoserQualifyingAmount: { type: 'currency', displayName: 'Lucky Loser Qualifying Amount', currency: 'dlrEuro' },
        luckyLoserDeductRound: { type: 'string', displayName: 'Lucky Loser Deduct Round', currency: 'dlrEuro' },
        luckyLoserDeductAmount: { type: 'currency', displayName: 'Lucky Loser Deduct Amount', currency: 'dlrEuro' },
        wtaFineDescription: { type: 'string', displayName: 'WTA Fine Description' },
        dlrEuro: { type: 'string', displayName: 'Currency', },
        correla: { type: 'number', displayName: 'Correl', },

    },
};
