<template>
    <div class="tournament_info_general" style="margin-top: 1em; padding-top: 3em">
        <table width="100%">
            <tr>
                <td>
                    <div>
                        {{ editionData.name }}, <span class="level"> {{ editionData.countryCode }}</span
                        ><br />
                        <span>{{ startDate }}</span
                        ><br />
                        <span>{{ longLevel }}</span>
                    </div>
                </td>
                <td>
                    <span class="printText">{{ prizeMoney }}&nbsp;({{ totalCommitment }})&nbsp;{{ expandedCurrency }}</span
                    ><br />
                    <span>Tournament Director: {{ editionData.director }}</span>
                </td>
                <td>
                    <div class="wtaLogo">
                        <img src="Hologic_WTATour_Stacked Color.png" style="object-fit: cover; width: 100%; height: 100%" />
                    </div>
                </td>
            </tr>
        </table>
    </div>
</template>
<script>
export default {
    name: 'tournament-info-general',
    props: {
        prizeMoney: {},
        totalCommitment: {},
        startDate: {},
        editionData: {},
        expandedCurrency: {},
        expandedRound: {},
        shortLevel: {},
        longLevel: {},
    },
};
</script>
<style>
.AL h3 {
    margin-top: 25px;
}

.tournament_info_details table {
    border-spacing: 0px 0px;
    display: inline-block;
    vertical-align: top;
    margin-right: 5%;
}

.tournament_info_details tr td {
    height: 25px;
    border-top: thin solid rgba(34, 36, 38, 0.1);
    padding: 5px;
}

.tournament_info_details tr th {
    padding: 5px;
    height: 25px;
}

.tournament_info_details th {
    color: #33135b;
    font-weight: 900;
}

.tournament_info_details tr th:first-child {
    border-right: thin solid rgba(34, 36, 38, 0.1);
}

.tournament_info_details tr td:first-child {
    border-right: thin solid rgba(34, 36, 38, 0.1);
    font-weight: 900;
}

.tournament_info_details td,
.tournament_info_details th {
    padding: 10px;
    text-align: center;
}

.cutoffDates tr:nth-child(2) td {
    height: 25px;
    border-top: thin solid rgba(34, 36, 38, 0.1);
}

.cutoffDates tr td:first-child {
    font-weight: 900;
    color: #33135b;
    text-align: left;
}

.cutoffDates td,
.cutoffDates th {
    padding: 10px;
    text-align: center;
}

.startDates tr:nth-child(1) td {
    height: 25px;
    border-top: 0;
}

.startDates tr td {
    height: 25px;
    border-top: thin solid rgba(34, 36, 38, 0.1);
}

.startDates tr td:first-child {
    font-weight: 900;
    color: #33135b;
    text-align: left;
}

.startDates td,
.startDates th {
    padding: 10px;
    text-align: center;
}

.list h3 {
    text-align: center;
    line-height: 1.28571429em;
    font-weight: 900;
    font-size: 24px;
}

.tournament_info_general {
    margin-top: 25px;
    margin-bottom: 20px;
    padding-top: 25px;
}

.tournament_info_general .name {
    font-size: 1.875rem;
    color: #666;
    font-weight: 900;
    padding-bottom: 0px;
}

.tournament_info_general .level {
    margin-top: 10px;
}

.tournament_info_general .details {
    font-size: 1rem;
    margin-top: 10px;
}

.tournament_info_general .details span:not(:last-child) {
    margin-right: 10px;
    padding-right: 10px;
    border-right: thin solid #666;
}

.cutoffDates tr:first-child td {
    border-top: none;
}

.noscreen {
    display: none;
}

#allLists h3 {
    margin-top: 50px !important;
}
</style>
