import axios from 'axios';
import { buildParams } from '@/utils/query-param-builder';

export default {
    namespaced: true,

    state: {
        shadowChampionshipSinglesRankings: {},
        loadingShadowChampionshipSinglesRankings: false,
        loadingErrorShadowChampionshipSinglesRankings: null,
    },

    getters: {
        shadowChampionshipSinglesRankings: (state) => state.shadowChampionshipSinglesRankings,
        loadingShadowChampionshipSinglesRankings: (state) => state.loadingShadowChampionshipSinglesRankings,
        loadingErrorShadowChampionshipSinglesRankings: (state) => state.loadingErrorShadowChampionshipSinglesRankings,
    },

    mutations: {
        setShadowChampionshipSinglesRankings(state, data) {
            state.shadowChampionshipSinglesRankings = data;
        },

        startLoadingShadowChampionshipSinglesRankings(state) {
            state.loadingShadowChampionshipSinglesRankings = true;
        },

        stopLoadingShadowChampionshipSinglesRankings(state) {
            state.loadingShadowChampionshipSinglesRankings = false;
        },

        setLoadingErrorShadowChampionshipSinglesRankings(state, error) {
            state.loadingErrorShadowChampionshipSinglesRankings = error;
        },

        clearLoadingErrorShadowChampionshipSinglesRankings(state) {
            state.loadingErrorShadowChampionshipSinglesRankings = null;
        },
    },

    actions: {
        async loadShadowChampionshipShinglesRankings(context, options = {}) {
            context.commit('startLoadingShadowChampionshipSinglesRankings');
            context.commit('clearLoadingErrorShadowChampionshipSinglesRankings');
            let params = buildParams(options);
                try {
                    var result = await axios.get(`${process.env.VUE_APP_API_URL}/ShadowRankingCurrent/ChampionshipSingles`, { params });
                    context.commit('setShadowChampionshipSinglesRankings', result.data);
                    context.commit('stopLoadingShadowChampionshipSinglesRankings');
                    return Promise.resolve(result.data);
                } catch (error) {
                    context.commit('stopLoadingShadowChampionshipSinglesRankings');
                    context.commit('setLoadingErrorShadowChampionshipSinglesRankings', error);
                    return Promise.reject(error);
                }
        }
    },
};
