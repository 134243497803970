import axios from 'axios';

export default {
    namespaced: true,

    state: {
        pzAgentAccesses: {},
        loadingPzAgentAccesses: false,
        loadingErrorPzAgentAccesses: null,
    },

    getters: {
        pzAgentAccesses: (state) => state.pzAgentAccesses,
        loadingPzAgentAccesses: (state) => state.loadingPzAgentAccesses,
        loadingErrorPzAgentAccesses: (state) => state.loadingErrorPzAgentAccesses,
    },

    mutations: {
        setPzAgentAccesses(state, data) {
            state.pzAgentAccesses = data;
        },

        startLoadingPzAgentAccesses(state) {
            state.loadingPzAgentAccesses = true;
        },

        stopLoadingPzAgentAccesses(state) {
            state.loadingPzAgentAccesses = false;
        },

        setLoadingErrorPzAgentAccesses(state, error) {
            state.loadingErrorPzAgentAccesses = error;
        },

        clearLoadingErrorPzAgentAccesses(state) {
            state.loadingErrorPzAgentAccesses = null;
        },
    },

    actions: {
        async findPzAccessesForAgents(context, options = {}) {
            context.commit('startLoadingPzAgentAccesses');
            context.commit('clearLoadingErrorPzAgentAccesses');
            try {
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/access/RetrieveAgents`, {
                    params: {
                        queryString: options.queryString || '',
                        pageSize: options.pageSize || 50,
                        sortBy: options.sortBy || 'first_name',
                        sortDir: options.sortDir === 'descending' ? -1 : 1,
                        page: options.page
                    },
                });
                context.commit('setPzAgentAccesses', result.data);
                context.commit('stopLoadingPzAgentAccesses');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('setLoadingErrorPzAgentAccesses', error);
                context.commit('stopLoadingPzAgentAccesses');
                return Promise.reject(error);
            }
        },

        async createPzAccessForAgents(context, data) {
            context.commit('startLoadingPzAgentAccesses');
            context.commit('clearLoadingErrorPzAgentAccesses');
            try {
                const result = await axios.post(`${process.env.VUE_APP_API_URL}/access/CreatePzAccessForAgents`, data.data)
                context.commit('stopLoadingPzAgentAccesses');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('setLoadingError', error);
                context.commit('stopLoadingPzAgentAccesses');
                return Promise.reject(error);
            }
        },

        async updatePzAgentAccess(context, data) {
            context.commit('startLoadingPzAgentAccesses');
            context.commit('clearLoadingErrorPzAgentAccesses');
            try {
                const result = axios.put(`${process.env.VUE_APP_API_URL}/access/UpdatePzAccess`, data.data);
                context.commit('stopLoadingPzAccesses');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('stopLoadingPzAccesses');
                context.commit('setLoadingError', error);
                return Promise.reject(error);
            }
        },

        async deletePzAgentAccess(context, data) {
            context.commit('startLoadingPzAgentAccesses');
            context.commit('clearLoadingErrorPzAgentAccesses');
            try {
                axios.delete(`${process.env.VUE_APP_API_URL}/access/DeletePzAccess/${data.data.username}`);
                context.commit('stopLoadingPzAccesses');
                return Promise.resolve();
            } catch (error) {
                context.commit('stopLoadingPzAccesses');
                context.commit('setLoadingError', error);
                return Promise.reject(error);
            }
        }
    }
}