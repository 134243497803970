import { isValid, Model } from './model';

export class WtaSystem extends Model {
    isValid() {
        return isValid(WtaSystem.schema, this);
    }

    isValidForEdit() {
        return isValid(WtaSystem.schema, this);
    }
}

WtaSystem.layoutRankingDates = {
    title: 'Current Ranking Dates',
    children: [
        {
            children: ['previousRankDate', 'currentRankDate', 'nextRankDate'],
        },
    ],
};

WtaSystem.layoutShadowRankingDates = {
    title: 'Current Ranking Dates',
    children: [
        {
            children: ['currentShadowRankDate', 'nextShadowRankDate'],
        },
    ],
};

WtaSystem.layoutEntrySystem = {
    title: 'Entry System',
    children: [
        {
            title: 'Controls',
            children: ['isDoublesGsEntryEnabled', 'showSinglesEntryNote', 'showDoublesEntryNote'],
        },
        {
            title: 'Singles Entry Note',
            children: ['singlesEntryNote'],
        },
        {
            title: 'Doubles Entry Note',
            children: ['doublesEntryNote'],
        },
    ],
};

WtaSystem.layoutEntrySystemPayment = {
    title: 'Entry System Payment',
    children: [
        {
            title: 'Full Members Payment Note',
            children: ['paymentFullMembersNote'],
        },
        {
            title: 'Associate Members Payment Note',
            children: ['paymentAssociateMembersNote'],
        },
    ],
};

WtaSystem.schema = {
    fields: {
        wtaId: { type: 'string', displayName: 'WtaId', readonly: true, required: true },
        version: { type: 'string', displayName: 'Version', clearable: true },
        currentRankDate: { type: 'us-date', displayName: 'Current Ranking Date', readonly: true, clearable: true },
        previousRankDate: { type: 'us-date', displayName: 'Previous Ranking Date', readonly: true, clearable: true },
        nextRankDate: { type: 'us-date', displayName: 'Next Ranking Date', readonly: true, clearable: true },
        icadText1: { type: 'string', displayName: 'Icad Text 1', clearable: true },
        icadText2: { type: 'string', displayName: 'Icad Text 2', clearable: true },
        locationBios: { type: 'string', displayName: 'Location Bios', clearable: true },
        locationImages: { type: 'string', displayName: 'Location Images', clearable: true },
        reportLogo: { type: 'string', displayName: 'Report Logo', clearable: true },
        rankingLogo: { type: 'string', displayName: 'Ranking Logo', clearable: true },
        reportCredit1: { type: 'string', displayName: 'Report Credit 1', clearable: true },
        reportCredit2: { type: 'string', displayName: 'Report Credit 2', clearable: true },
        rankingCredit: { type: 'string', displayName: 'Ranking Credit', clearable: true },
        rankingText1: { type: 'string', displayName: 'Ranking Text1', clearable: true },
        rankingText2: { type: 'string', displayName: 'Ranking Text2', clearable: true },
        rankingText3: { type: 'string', displayName: 'Ranking Text3', clearable: true },
        systemLocked: { type: 'string', displayName: 'System Locked', clearable: true },
        wtaYear: { type: 'number', displayName: 'Wta Year', clearable: true },
        singlesEntryNote: { type: 'multiline', displayName: 'Singles Entry Note', clearable: true },
        doublesEntryNote: { type: 'multiline', displayName: 'Doubles Entry Note', clearable: true },
        isDoublesGsEntryEnabled: {
            type: 'switch',
            displayName: 'Enable Doubles GS Entry',
            clearable: true,
            inactiveText: 'Disabled',
            activeText: 'Enabled',
            inactiveValue: false,
            activeValue: true,
        },
        showSinglesEntryNote: {
            type: 'switch',
            displayName: 'Show Singles Entry Note',
            clearable: true,
            inactiveText: 'Do not show',
            activeText: 'Show',
            inactiveValue: false,
            activeValue: true,
        },
        showDoublesEntryNote: {
            type: 'switch',
            displayName: 'Show Doubles Entry Note',
            clearable: true,
            inactiveText: 'Do not show',
            activeText: 'Show',
            inactiveValue: false,
            activeValue: true,
        },
        currentShadowRankDate: { type: 'date', displayName: 'Current Shadow Ranking Date', clearable: true },
        nextShadowRankDate: { type: 'date', displayName: 'Next Shadow Ranking Date', clearable: true },
        paymentFullMembersNote: { type: 'multiline', displayName: 'Full Members Payment Note', clearable: true },
        paymentAssociateMembersNote: { type: 'multiline', displayName: 'Associate Members Payment Note', clearable: true },
    },
};
