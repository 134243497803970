<template>
    <div>

        <ErrorDisplay :requestError="this.loadingErrorHistoricShadowTeamRankingsRanking" @closed="clearError"/>

        <div v-loading="loadingHistoricShadowTeamRankingsRanking">
            <DataTable
                    :autoSort="false"
                    :fields="['rank',
                              'player1Id',
                              'player1ConcatName',
                              'player1Nationality',
                              'player1BirthDate',
                              'player2Id',
                              'player2ConcatName',
                              'player2Nationality',
                              'player2BirthDate',
                              'points']"
                    :items="historicShadowTeamRankings.data"
                    :schema="schema"
                    @item-clicked="selectRanking"
                    @header-clicked="setSort"
            />

            <Paginator
                    v-if="historicShadowTeamRankings.data"
                    :data="historicShadowTeamRankings"
                    :elementsPerPage="options.pageSize"
                    @prev-page="prevPage"
                    @next-page="nextPage"
                    @set-page-size="setPageSize"
            />
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { ShadowTeamRanking } from '@/models/shadow-team-ranking';
import { retrieveDtos } from '@/utils/crud-confirm';

export default {
    props: ['shadowRankId', 'rankingDate', 'playerId'],
    watch: {
        shadowRankId() {
            this.shadowRankIdSelected(this.shadowRankId);
        },
        rankingDate() {
            this.rankingDateSelected(this.rankingDate);
        },
        playerId() {
            this.playerIdSelected(this.playerId);
        },
    },
    data() {
        return {
            schema: ShadowTeamRanking.schema,
            layout: ShadowTeamRanking.layoutHistory,
            options: {
                shadowRankId: null,
                rankingDate: null,
                queryString: '',
                sortBy: '',
                sortDir: '',
                pageSize: process.env.VUE_APP_PAGE_SIZE,
                page: 0,
            },
        };
    },
    mounted() {
    },
    computed: mapGetters('shadowHistoryChampDoubles', [
        'historicShadowTeamRankings',
        'loadingHistoricShadowTeamRankingsRanking',
        'loadingErrorHistoricShadowTeamRankingsRanking'
    ]),
    methods: {
        ...mapActions('shadowHistoryChampDoubles', [
            'findHistoricShadowTeamRankings',
            'clearLoadingErrorHistoricShadowTeamRankingsRanking'
        ]),
        refresh() {
            let params = {
                self: this,
                fRetrieve: this.findHistoricShadowTeamRankings,
                options: this.options,
                dtosName: 'Historic Shadow Team Rankings',
            };
            retrieveDtos(params);
        },
        shadowRankIdSelected(value) {
            this.options.shadowRankId = value;
            if (this.options.rankingDate)
                this.reset();
        },
        rankingDateSelected(value) {
            this.options.rankingDate = value;
            if (this.options.shadowRankId)
                this.reset();
        },
        playerIdSelected(value) {
            this.options.queryString = value;
            if (this.options.shadowRankId && this.options.rankingDate)
                this.reset();
        },
        reset() {
            this.options.page = 0;
            this.refresh();
        },
        setSort(event) {
            this.options.sortBy = event.property;
            this.options.sortDir = event.order;
            this.refresh();
        },
        clearError() {
            this.loadingErrorHistoricShadowTeamRankingsRanking = null;
        },
    },
};
</script>
