var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"flex-row mt-16 mb-16"},[_c('h3',[_vm._v("Age Eligibility Detail Tracking "+_vm._s(this.formattedReportDate)+" "+_vm._s(this.formattedPlayerName))]),_c('span',{staticClass:"spacer"})]),_c('div',{staticClass:"mb-32"},[_c('el-alert',{attrs:{"title":"info","type":"info","closable":false,"description":"This table contains all tournaments the player has participated at with points earned and WCs used. Table contains real numbers and numbers after application of AER: adj - adjusted.","show-icon":""}}),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loadingAgeEligibilityTournamentTrackings),expression:"loadingAgeEligibilityTournamentTrackings"}]},[(!_vm.loadingErrorAgeEligibilityTournamentTrackings)?_c('DataTable',{attrs:{"schema":_vm.schema,"items":_vm.ageEligibilityTournamentTrackings,"fields":[
                      'tournamentStartDate',
                      'tournamentName',
                      'tournamentType',
                      'tournamentLevel',
                      'tournamentPrizeMoney',
                      'rankingPointsSingles',
                      'rankingPointsDoubles',
                      'tournamentCountReal',
                      'tournamentCountAdjusted',
                      'singlesWcCountReal',
                      'singlesWcCountAdjusted',
                      'doublesWcCountReal',
                      'doublesWcCountAdjusted',
                      'mainWcCountReal',
                      'mainWcCountAdjusted',
                      'qualiWcCountReal',
                      'qualiWcCountAdjusted',
                  ],"autoSort":false,"showTotals":true}}):_vm._e()],1)],1),_c('div',{staticClass:"mb-16"},[_c('el-alert',{attrs:{"title":"info","type":"info","closable":false,"description":"This table contains an overview of left and used tournaments and WCs. It contains only adjusted numbers after application of AER. It is the same entry as in AER Tracking report.","show-icon":""}}),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loadingAgeEligibilityTracking),expression:"loadingAgeEligibilityTracking"}]},[(!_vm.loadingErrorAgeEligibilityTracking)?_c('DataTable',{attrs:{"schema":_vm.schemaTracking,"items":_vm.ageEligibilityTracking.data,"fields":_vm.tableFieldsPreview,"autoSort":false}}):_vm._e()],1)],1),_c('div',{staticClass:"mb-16"},[_c('el-alert',{attrs:{"title":"info","type":"info","closable":false,"description":"This table contains an overview of Warnings and Violations for player. It contains only adjusted numbers after application of AER. It is the same entry as in AER Warning/ Violation report.","show-icon":""}}),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loadingAgeEligibilityTracking),expression:"loadingAgeEligibilityTracking"}]},[_c('DataTable',{attrs:{"schema":_vm.schemaTracking,"items":_vm.ageEligibilityTracking.data,"fields":_vm.tableFieldsWarning,"autoSort":false}})],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }