<template>
    <div v-loading="loadingShadowRankingSummaries">
        <div class="flex-row">
            <RankingSummaryPointTable :summary="shadowSinglesSummary" />
            <span class="spacer"></span>
            <RankingSummaryTieBreakingPoints :summary="shadowSinglesSummary" />
            <span class="spacer"></span>
            <span class="spacer"></span>
        </div>

        <DataTable
            v-if="shadowSinglesSummary"
            :items="shadowSinglesSummary ? shadowSinglesSummary.allResults : []"
            :schema="schema"
            :fields="this.dataFields"
            :autoSort="false"
        />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { TournamentResult } from '@/models/tournament-result';

export default {
    props: ['dataFields'],
    data() {
        return {
            schema: TournamentResult.schema,
        };
    },
    mounted() {},
    computed: {
        ...mapGetters('shadowRankingSummaries', ['shadowSinglesSummary', 'loadingShadowRankingSummaries']),
    },
    methods: {
    },
};
</script>
