<template>
    <div v-loading="loadingPlayerSupporter || savingPlayerSupporter">
        <div v-if="playerSupport">
            <div class="flex-row mt-0 mb-32">
                <h2><i class="el-icon-user mr-16"></i>{{ playerSupport.original.fullName }} - {{ playerSupport.original.contactId }}</h2>
                <span class="spacer"></span>
                <el-button plain type="danger" v-if="!isBaselineReader" @click="deletePlayerSupportClick">Delete Player Support</el-button>
            </div>

            <ErrorDisplay :requestError="this.requestError" @closed="clearError" />

            <el-tabs v-model="activeTab">
                <el-tab-pane label="Contact Details" name="details">
                    <FormGroup :model="playerSupport" :schema="schema" :layout="layoutContact" @change="onChange" />
                </el-tab-pane>

                <el-tab-pane label="Coach Requirements" name="coach">
                    <FormGroup :model="playerSupport" :schema="schema" :layout="layoutCoachRookie" @change="onChange" />
                </el-tab-pane>

                <el-tab-pane label="Agent Requirements" name="agent">
                    <FormGroup :model="playerSupport" :schema="schema" :layout="layoutAgent" @change="onChange" />
                </el-tab-pane>

                <el-tab-pane label="Parent Requirements" name="parent">
                    <FormGroup :model="playerSupport" :schema="schema" :layout="layoutParent" @change="onChange" />
                </el-tab-pane>
            </el-tabs>

            <SaveChanges :model="playerSupport" @save="saveChanges" @discard="discardChanges" />
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { PlayerSupport } from '../models/player-support';
import { deleteConfirmDto, retrieveDtos } from '../utils/crud-confirm';
import { isBaselineReader } from '../services/authGroups';

export default {
    data() {
        return {
            playerSupportId: this.$route.params.id,
            schema: PlayerSupport.schema,
            layoutContact: PlayerSupport.layoutContact,
            layoutCoachRookie: PlayerSupport.layoutCoachRookie,
            layoutAgent: PlayerSupport.layoutAgent,
            layoutParent: PlayerSupport.layoutParent,
            requestError: null,
            activeTab: this.$route.query.activeTab || 'details',
            isBaselineReader: isBaselineReader(),
        };
    },
    mounted() {
        this.refresh();
    },
    computed: {
        ...mapGetters('playerSupportDetails', ['playerSupport', 'loadingPlayerSupporter', 'savingPlayerSupporter', 'loadingError', 'savingError']),
    },
    methods: {
        ...mapActions('playerSupportDetails', ['findPlayerSupport', 'savePlayerSupporter', 'deletePlayerSupporter']),
        refresh() {
            let params = {
                self: this,
                fRetrieve: this.findPlayerSupport,
                options: this.playerSupportId,
                dtosName: 'PlayerSupport',
            };
            retrieveDtos(params);
        },
        saveChanges() {
            this.savePlayerSupporter()
                .then(() => {
                    this.playerSupport.saveChanges();
                    this.$notify({
                        title: 'Save changes',
                        message: 'Changes successfully saved!',
                        type: 'success',
                        duration: 5000,
                    });
                })
                .catch((error) => {
                    this.requestError = error;
                });
        },
        discardChanges() {
            this.playerSupport.discardChanges();
        },
        onChange(event) {
            // this is to prevent saving empty strings to the DB
            if (event.value === '') {
                this.playerSupport.data[event.field] = null;
            }
        },
        deletePlayerSupportClick() {
            let params = {
                self: this,
                fDelete: this.deletePlayerSupporter,
                vConfirm: this.playerSupportId,
                dto: this.playerSupportId,
                dtoName: 'PlayerSupport',
                vConfirmName: 'Player Support ID',
                callback: this.deleteCallback,
            };
            deleteConfirmDto(params);
        },
        deleteCallback() {
            this.$router.push('/players?activeTab=player-supporters');
        },
        clearError() {
            this.requestError = null;
        },
    },
};
</script>
