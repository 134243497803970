<template>
    <div>
        <div class="flex-row mt-0 mb-32">
            <h2><i class="el-icon-medal-1 mr-16"></i>Required Tournaments for Ranking</h2>
            <span class="spacer"></span>

            <el-input
                placeholder="Filter by Id, Year or Name"
                v-model="options.queryString"
                class="input-search mr-16"
                @change="resetPage"
                @keydown.esc.native="reset"
            >
                <el-button slot="append" icon="el-icon-search" @click="resetPage"></el-button>
            </el-input>
        </div>

        <ErrorDisplay :requestError="this.requestError" @closed="clearError" />

        <DetailedInformation screenName="Required Tournaments" :informationArray="this.informationArray()" />

        <div v-loading="loadingRequiredTournaments">
            <DataTable
                :items="requiredTournaments.data"
                :schema="schemaTournament"
                :fields="['season', 'tournamentId', 'name', 'countryCode', 'type', 'level', 'startDate', 'requiredForRankingPlayerCount']"
                :sortField="options.sortBy"
                :sortDir="options.sortDir"
                @item-clicked="selectTournament"
                @header-clicked="setSort"
                :autoSort="false"
            />

            <Paginator
                v-if="requiredTournaments.data"
                :data="requiredTournaments"
                :elementsPerPage="options.pageSize"
                @prev-page="prevPage"
                @next-page="nextPage"
                @set-page-size="setPageSize"
            />
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { Tournament } from '../../models/tournament';

export default {
    data() {
        return {
            schemaTournament: Tournament.schema,
            requestError: null,
        };
    },
    mounted() {
        this.retrieve();
    },
    computed: {
        ...mapGetters('requiredTournamentsMain', ['requiredTournaments', 'loadingRequiredTournaments', 'loadingTournamentsError', 'options']),
    },
    methods: {
        ...mapActions('requiredTournamentsMain', ['retrieveTournaments']),
        retrieve() {
            this.clearError();
            this.retrieveTournaments(this.options).catch((error) => {
                this.requestError = error;
            });
        },
        selectTournament(row) {
            this.$router.push(`/ranking/required-tournaments/${row.season}/${row.tournamentId}`);
        },
        resetPage() {
            this.options.page = 0;
            this.retrieve();
        },
        setPageSize(size) {
            this.options.pageSize = size;
            this.retrieve();
        },
        reset() {
            this.options.queryString = null;
            this.options.page = 0;
            this.retrieve();
        },
        setSort(event) {
            console.log(event);
            this.options.sortBy = event.property;
            this.options.sortDir = event.order;
            this.retrieve();
        },
        prevPage() {
            this.options.page--;
            this.retrieve();
        },
        nextPage() {
            this.options.page++;
            this.retrieve();
        },
        clearError() {
            this.requestError = null;
        },
        informationArray() {
            return [
                'This screen is used to display an overview of all tournaments that are required for ranking.',
                'Column "Player Count" shows how many players need to incorporate their result into ranking.',
                'Required tournaments are fetched from tables tourn_table and tourn_required.',
                'Combined search is possible by tourn_nbr <b>and</b> tourn_name <b>and</b> tourn_year <b>and</b> country_code <b>and</b> tourn_sponsor. These fields are always searched.',
                'When no search is performed only required tournaments are listed.',
                'Click on a tournament to manage players who need to incorporate their result into ranking.',
            ];
        },
    },
};
</script>

<style scoped>
.input-search {
    max-width: 512px;
}
</style>
