<template>
    <div>
        <div class="flex-row mt-0 mb-32">
            <h2><i class="el-icon-medal-1 mr-16"></i>{{ title() }}</h2>

            <span class="spacer"></span>

            <el-select v-model="shadowRankId"
                       v-loading="loadingShadowRankings"
                       class="mr-16"
                       placeholder="Select Shadow Ranking">
                <el-option
                        v-for="item in shadowRankings"
                        :key="item.shadowRankId"
                        :label="item.name"
                        :value="item.shadowRankId">
                </el-option>
            </el-select>

            <SelectPlayer :clearable="true" :value="playerId" class="mr-8" @clear="reset"
                          @palyer-selected="playerSelected"/>
            <el-button plain size="mini" type="primary" @click="reset">Reset</el-button>
        </div>

        <DetailedInformation :informationArray="this.informationArray()" screenName="Current Shadow Rankings"/>

        <el-tabs value="shadowSingles">
            <el-tab-pane label="Shadow Singles Rankings" name="shadowSingles">
                <ShadowSinglesRankings ref="shadowSingles" :playerId="playerId" :shadowRankId="shadowRankId"/>
            </el-tab-pane>

            <el-tab-pane label="Shadow Doubles Rankings" name="shadowDoubles">
                <ShadowDoublesRankings ref="shadowDoubles" :playerId="playerId" :shadowRankId="shadowRankId"/>
            </el-tab-pane>

            <el-tab-pane label="Shadow Championship Singles" name="shadowChampSingles">
                <ShadowChampSinglesRankings ref="shadowChampSingles" :playerId="playerId" :shadowRankId="shadowRankId"/>
            </el-tab-pane>

            <el-tab-pane label="Shadow Team Rankings" name="shadowTeamPoints">
                <ShadowTeamPoints ref="shadowTeamPoints" :playerId="playerId" :shadowRankId="shadowRankId"/>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import * as moment from 'moment';

export default {
    data() {
        return {
            shadowRankId: null,
            playerId: null,
            playerName: null,
        };
    },
    mounted() {
        this.getAllShadowRankings().then(() => {
            if (this.shadowRankings.length && !this.shadowRankId)
                this.shadowRankId = this.shadowRankings[0].shadowRankId;
        });
        this.retrieveWtaSystem();
    },
    computed: {
        ...mapGetters('wtaSystem', ['wtaSystem']),
        ...mapGetters('shadowRankings', [
            'shadowRankings',
            'loadingShadowRankings',
            'loadingErrorShadowRankings',
        ]),
    },
    methods: {
        ...mapActions('wtaSystem', ['retrieveWtaSystem']),
        ...mapActions('shadowRankings', ['getAllShadowRankings']),
        playerSelected(player) {
            this.playerId = player.id;
            this.playerName = player.fullName;
        },
        reset() {
            this.playerId = null;
            this.playerName = null;
        },
        title() {
            let title = 'Current Shadow Rankings';
            if (this.wtaSystem && this.wtaSystem.data.currentShadowRankDate)
                title += (' ' + moment(this.wtaSystem.data.currentShadowRankDate).format('YYYY-MM-DD'));
            return title;
        },
        informationArray() {
            return [
                'This screen is used to display Current Shadow Rankings for players and teams.',
                'Current Shadow Player Rankings (tabs <b>Shadow Singles Rankings</b>, <b>Shadow Doubles Rankings</b>, <b>Shadow Championship Singles</b>) are fetched from table shadow_rank_current.',
                'Current Shadow Team Rankings (tabs <b>Shadow Team Rankings</b>) are fetched from table shadow_team_points.',
                'Current Shadow Player Rankings with Rank of 9999 are not displayed. All Current Team Rankings are displayed.',
                'Search for player dropdown is possible by player number, first name and last name.',
            ];
        },
    },
};
</script>
