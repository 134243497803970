import axios from 'axios';
import { buildParams } from '@/utils/query-param-builder';

export default {
    namespaced: true,

    state: {
        historicDoublesShadowRankings: {},
        loadingHistoricDoublesShadowRankings: false,
        loadingErrorHistoricDoublesShadowRankings: null,
    },

    getters: {
        historicDoublesShadowRankings: (state) => state.historicDoublesShadowRankings,
        loadingHistoricDoublesShadowRankings: (state) => state.loadingHistoricDoublesShadowRankings,
        loadingErrorHistoricDoublesShadowRankings: (state) => state.loadingErrorHistoricDoublesShadowRankings,
    },

    mutations: {
        setHistoricDoublesShadowRankings(state, data) {
            state.historicDoublesShadowRankings = data;
        },

        startLoadingHistoricDoublesShadowRankings(state) {
            state.loadingHistoricDoublesShadowRankings = true;
        },

        stopLoadingHistoricDoublesShadowRankings(state) {
            state.loadingHistoricDoublesShadowRankings = false;
        },

        setLoadingErrorHistoricDoublesShadowRankings(state, error) {
            state.loadingErrorHistoricDoublesShadowRankings = error;
        },

        clearLoadingErrorHistoricDoublesShadowRankings(state) {
            state.loadingErrorHistoricDoublesShadowRankings = null;
        },
    },

    actions: {
        async findHistoricDoublesShadowRankings(context, options) {
            context.commit('startLoadingHistoricDoublesShadowRankings');
            context.commit('clearLoadingErrorHistoricDoublesShadowRankings');
            let date = options.rankingDate;
            let params = buildParams(options);
            try {
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/ShadowRankingHistory/${date}/Doubles`, { params });
                context.commit('setHistoricDoublesShadowRankings', result.data);
                context.commit('stopLoadingHistoricDoublesShadowRankings');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('setLoadingErrorHistoricDoublesShadowRankings', error);
                context.commit('stopLoadingHistoricDoublesShadowRankings');
                return Promise.reject(error);
            }
        },
    },
};
