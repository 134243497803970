<script>
import { mapActions, mapGetters } from 'vuex';
import { isBaselineReader } from '../../services/authGroups';

export default {
    name: 'PlayersChangeRequests',

    data() {
        return {
            requestError: null,
            validProperties: [],
            statuses: '1',
            showStatusComment: true,
            isBaselineReader: isBaselineReader(),
        };
    },
    mounted() {
        this.retrieve();
    },
    computed: {
        ...mapGetters('playersChangeRequests', ['playerChangeRequests', 'loadingPlayerChangeRequests', 'loadingError', 'options']),
    },
    methods: {
        ...mapActions('playersChangeRequests', ['fetchPlayerChangeRequests', 'approvePlayerChangeRequest', 'rejectPlayerChangeRequest']),
        retrieve() {
            this.clearError();
            this.options.requestDateFrom = this.options.fromDate !== null ? this.options.fromDate[0] : null;
            this.options.requestDateTo =
                this.options.fromDate !== null ? new Date(new Date(this.options.fromDate[1]).setDate(this.options.fromDate[1].getDate() + 1)) : null;
            const params = { options: this.options };
            this.fetchPlayerChangeRequests(params)
                .then(() => {
                    if (this.loadingError) {
                        this.$message({
                            type: 'error',
                            message:
                                'There was an error while querying the information. Please try again. If the problem persists, please contact the support team.',
                        });
                    }
                })
                .catch((error) => {
                    this.requestError = error;
                });
        },
        clearError() {
            this.requestError = null;
        },
        getTagType(status) {
            if (status.toUpperCase() === 'APPROVED') {
                return 'success';
            } else if (status.toUpperCase() === 'SUBMITTED') {
                return 'warning';
            } else if (status.toUpperCase() === 'REJECTED') {
                return 'danger';
            } else {
                return 'info'; // Default background color if status is not recognized
            }
        },
        handlePopoverShow(scope) {
            this.validProperties = {};
            const arrProps = ['id', 'playerNbr', 'zendeskRequestId', 'createdDate', 'statusComment', 'status', 'fullName'];
            for (const prop in scope.row) {
                if (scope.row[prop] !== null) {
                    if (!arrProps.includes(prop))
                        try {
                            const textInfo = this.schema.fields[prop].displayName;
                            if (textInfo !== undefined) this.validProperties[textInfo] = scope.row[prop];
                        } catch (error) {
                            this.validProperties[prop] = scope.row[prop];
                        }
                }
            }
        },
        handleAccept(index, row) {
            this.$confirm('Are you sure you want to approve this request?', 'Warning', {
                confirmButtonText: 'Yes, Approve',
                cancelButtonText: 'Cancel',
                type: 'warning',
            })
                .then(() => {
                    this.approvePlayerChangeRequest(row)
                        .then(() => {
                            let type = 'success';
                            let msg = 'Request approved!';
                            if (this.loadingError) {
                                type = 'error';
                                msg =
                                    'There was an error generating the approval request. Please try again. If the problem persists, please contact the support team.';
                            }
                            this.$message({
                                type: type,
                                message: msg,
                            });
                            this.playerChangeRequests.data.splice(index, 1);
                        })
                        .catch((error) => {
                            this.$message({
                                type: 'error',
                                message:
                                    'There was an error generating the approval request. Please try again. If the problem persists, please contact the support team.',
                            });
                        });
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: 'Request canceled',
                    });
                });
        },
        handleReject(index, row) {
            this.$prompt('Are you sure you want to reject this request?', 'Warning', {
                confirmButtonText: 'Yes, Reject',
                cancelButtonText: 'Cancel',
                inputPlaceholder: 'what is the reason to reject?',
                type: 'warning',
            })
                .then((value) => {
                    row.reasonToReject = value;
                    this.rejectPlayerChangeRequest(row)
                        .then(() => {
                            let type = 'success';
                            let msg = 'Request rejected!';
                            if (this.loadingError) {
                                type = 'error';
                                msg =
                                    'There was an error generating the rejection request. Please try again. If the problem persists, please contact the support team.';
                            }
                            this.$message({
                                type: type,
                                message: msg,
                            });
                            this.playerChangeRequests.data.splice(index, 1);
                        })
                        .catch((error) => {
                            this.$message({
                                type: 'error',
                                message:
                                    'There was an error generating the rejection request. Please try again. If the problem persists, please contact the support team.',
                            });
                        });
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: 'Request canceled',
                    });
                });
        },
        formatCamelCase(text) {
            return text.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase());
        },
        change() {
            this.options.page = 0;
            this.options.pageSize = process.env.VUE_APP_PAGE_SIZE;
            this.retrieve();
        },
        prevPage() {
            this.options.page--;
            this.retrieve();
        },
        nextPage() {
            this.options.page++;
            this.retrieve();
        },
        setPageSize(size) {
            this.options.pageSize = size;
            this.retrieve();
        },
    },
};
</script>

<template>
    <div>
        <div class="container mx-auto">
            <h1 class="text-4xl font-bold">Player Profile Change Requests - Administrative Page</h1>
            <span class="spacer"></span>

            <label class="mr-8 el-small">Status:</label>
            <el-select v-model="options.statuses" placeholder="Select Status" class="mr-16" @change="change">
                <el-option label="All" value="1,2,3"></el-option>
                <el-option label="Submitted" value="1"></el-option>
                <el-option label="Approved" value="2"></el-option>
                <el-option label="Rejected" value="3"></el-option>
            </el-select>
            <label class="mr-8 el-small">Request Date:</label>
            <el-date-picker
                v-model="options.fromDate"
                type="daterange"
                range-separator="To"
                start-placeholder="Start month"
                end-placeholder="End month"
                @change="change"
            ></el-date-picker>
        </div>

        <ErrorDisplay :requestError="this.requestError" @closed="clearError" />

        <el-table v-loading="loadingPlayerChangeRequests" :data="playerChangeRequests.data" :default-sort="{ prop: 'createdDate', order: 'descending' }">
            <el-table-column label="Player Id" width="180">
                <template slot-scope="scope">
                    <i class="el-icon-time"></i>
                    <span style="margin-left: 10px">{{ scope.row.playerNbr }}</span>
                </template>
            </el-table-column>
            <el-table-column label="Zendesk Id" width="180">
                <template slot-scope="scope">
                    <i class="el-icon-time"></i>
                    <span style="margin-left: 10px">{{ scope.row.zendeskRequestId }}</span>
                </template>
            </el-table-column>
            <el-table-column label="Request Date" width="180">
                <template slot-scope="scope">
                    <i class="el-icon-time"></i>
                    <span style="margin-left: 10px">{{ scope.row.createdDate }}</span>
                </template>
            </el-table-column>
            <el-table-column property="status" label="Status" width="140">
                <template slot-scope="scope">
                    <el-tag :type="getTagType(scope.row.status)">{{ scope.row.status }}</el-tag>
                </template>
            </el-table-column>
            <el-table-column v-if="showStatusComment" property="statusComment" label="Status Comment" min-width="50">
                <template slot-scope="scope">
                    <span :style="{ color: scope.row.status === 'Rejected' ? 'red' : 'black' }">{{ scope.row.statusComment }}</span>
                </template>
            </el-table-column>
            <el-table-column property="fullName" label="Player" width="200"></el-table-column>
            <el-table-column label="Request Details" width="160">
                <template slot-scope="scope">
                    <el-popover trigger="click" placement="top" @show="handlePopoverShow(scope)">
                        <ul>
                            <li v-for="(value, propertyName) in validProperties" v-bind:key="propertyName">
                                {{ formatCamelCase(propertyName) }} : {{ value }}
                            </li>
                        </ul>
                        <div slot="reference" class="name-wrapper">
                            <el-tag size="medium" class="clickable-element">Show</el-tag>
                        </div>
                    </el-popover>
                </template>
            </el-table-column>
            <el-table-column label="Operations" width="380" v-if="!isBaselineReader">
                <template slot-scope="scope">
                    <el-button type="primary" size="mini" @click="handleAccept(scope.$index, scope.row)" :disabled="scope.row.status !== 'Submitted'"
                        >Approve</el-button
                    >
                    <el-button type="danger" size="mini" @click="handleReject(scope.$index, scope.row)" :disabled="scope.row.status !== 'Submitted'"
                        >Reject</el-button
                    >
                </template>
            </el-table-column>
        </el-table>

        <Paginator
            v-if="playerChangeRequests.data"
            :data="playerChangeRequests"
            :elementsPerPage="options.pageSize"
            @prev-page="prevPage"
            @next-page="nextPage"
            @set-page-size="setPageSize"
        />
    </div>
</template>

<style scoped></style>
