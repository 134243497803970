<template>
    <div>
        <div class="flex-row mt-0 mb-32">
            <h2><i class="el-icon-medal-1 mr-16"></i>Shadow Ranking History {{
                    shadowRankingDate ? ' for ' + shadowRankingDate : ''
                }}</h2>
            <span class="spacer"></span>

            <el-select v-model="shadowRankId"
                       v-loading="loadingShadowRankings"
                       class="mr-16"
                       placeholder="Select Shadow Ranking"
                       size="mini"
                       @change="shadowRankIdChanged">
                <el-option v-for="item in shadowRankings"
                           :key="item.shadowRankId"
                           :label="item.name"
                           :value="item.shadowRankId">
                </el-option>
            </el-select>

            <el-select v-model="shadowTourYear"
                       v-loading="loadingShadowTourYears"
                       class="mr-16 year-select"
                       placeholder="Select Tour Year"
                       size="mini"
                       @change="tourYearChanged">
                <el-option v-for="(item, i) in shadowTourYears"
                           :key="i"
                           :label="item.tourYear"
                           v-bind:value="item">
                </el-option>
            </el-select>

            <el-select v-model="shadowRankingDate"
                       v-loading="loadingShadowRankingDates"
                       class="mr-16 date-select"
                       placeholder="Select Ranking Date"
                       size="mini"
                       @change="rankingDateChanged">
                <el-option v-for="(item, i) in shadowRankingDates"
                           :key="i"
                           :label="item"
                           :value="item">
                </el-option>
            </el-select>

            <SelectPlayer class="mr-8" :clearable="true" :value="this.playerId" @clear="reset" @palyer-selected="playerSelected"/>

            <el-button plain size="mini" type="primary" @click="reset">Reset</el-button>
        </div>

        <ErrorDisplay :requestError="this.requestError" @closed="clearError"/>

        <DetailedInformation :informationArray="this.informationArray()" screenName="Shadow Current Rankings"/>

        <el-tabs value="shadowSingles">
            <el-tab-pane label="Shadow Singles Rankings" name="shadowSingles">
                <ShadowRankingHistorySingles ref="shadowSingles"
                                             :playerId="playerId"
                                             :rankingDate="shadowRankingDate"
                                             :shadowRankId="shadowRankId"/>
            </el-tab-pane>
            <el-tab-pane label="Shadow Doubles Rankings" name="shadowDoubles">
                <ShadowRankingHistoryDoubles ref="shadowDoubles"
                                             :playerId="playerId"
                                             :rankingDate="shadowRankingDate"
                                             :shadowRankId="shadowRankId"/>
            </el-tab-pane>
            <el-tab-pane label="Shadow Championship Singles" name="shadowChampSingles">
                <ShadowRankingHistoryChampSingles ref="shadowChampSingles"
                                                  :playerId="playerId"
                                                  :rankingDate="shadowRankingDate"
                                                  :shadowRankId="shadowRankId"/>
            </el-tab-pane>
            <el-tab-pane label="Shadow Team Rankings" name="shadowTeamRankings">
                <ShadowRankingHistoryTeamPoints ref="shadowTeamRankings"
                                                :playerId="playerId"
                                                :rankingDate="shadowRankingDate"
                                                :shadowRankId="shadowRankId"/>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    data() {
        return {
            shadowRankId: null,
            shadowTourYear: null,
            shadowRankingDate: null,
            playerId: null,
            playerName: null,
            requestError: null,
        };
    },
    mounted() {
        if (this.shadowRankId && this.shadowTourYear && this.shadowRankingDate) {
            return;
        }
        this.getAllShadowRankings()
                .then(() => {
                    if (!this.shadowRankings.length) return;
                    this.shadowRankIdChanged(this.shadowRankings[0].shadowRankId);
                })
                .catch((error) => {
                    this.requestError = error;
                    this.$notify({
                        title: 'Get Shadow Rankings Error',
                        message: error.response.data.Message,
                        type: 'error',
                        duration: 5000,
                    });
                });
    },
    computed: {
        ...mapGetters('shadowRankings', [
            'shadowRankings',
            'loadingShadowRankings',
            'loadingErrorShadowRankings']),
        ...mapGetters('shadowRankingHistory', [
            'shadowTourYears',
            'loadingShadowTourYears',
            'shadowRankingDates',
            'loadingShadowRankingDates',
            'loadingError']),
    },
    methods: {
        ...mapActions('shadowRankings', ['getAllShadowRankings']),
        ...mapActions('shadowRankingHistory', [
            'retrieveValidShadowTourYears',
            'retrieveShadowRankingDatesForTourYear']),
        shadowRankIdChanged(value) {
            this.shadowRankId = value;
            this.retrieveValidShadowTourYears(value)
                    .then(() => {
                        if (!this.shadowTourYears.length) return;
                        this.tourYearChanged(this.shadowTourYears[0]);
                    })
                    .catch((error) => {
                        this.requestError = error;
                        this.$notify({
                            title: 'Get Valid Tour Years Error',
                            message: error.response.data.Message,
                            type: 'error',
                            duration: 5000,
                        });
                    });
        },
        tourYearChanged(value) {
            this.shadowTourYear = value.tourYear;
            value.shadowRankId = this.shadowRankId;
            this.retrieveShadowRankingDatesForTourYear(value)
                    .then(() => {
                        if (!this.shadowRankingDates.length) return;
                        this.rankingDateChanged(this.shadowRankingDates[0]);
                    })
                    .catch((error) => {
                        this.requestError = error;
                        this.$notify({
                            title: 'Get Ranking Dates Error',
                            message: error.response.data.Message,
                            type: 'error',
                            duration: 5000,
                        });
                    });
        },
        rankingDateChanged(value) {
            this.shadowRankingDate = value;
        },
        playerSelected(value) {
            this.playerId = value.id;
            this.playerName = value.fullName;
        },
        reset() {
            this.playerId = null;
            this.playerName = null;
        },
        clearError() {
            this.requestError = null;
        },
        informationArray() {
            return [
                'This screen is used to display Shadow Historical Rankings for players.',
                'Shadow Historical Rankings are fetched from table shadow_rank_history and view TeamPointsHistory.',
                'Season selection is fetched from table year_end. Ranking Date selection is fetched from table shadow_rank_history.',
            ];
        },
    },
};
</script>

<style scoped>
.input-search {
    max-width: 256px;
}

.year-select {
    max-width: 96px;
}

.date-select {
    max-width: 128px;
}
</style>
