import axios from 'axios';
import { buildParams } from '../../utils/query-param-builder';

export default {
    namespaced: true,

    state: {
        rankings: [],
        loadingRanking: false,
        loadingError: null,
        options: {
            queryString: '',
            sortBy: '',
            sortDir: '',
            pageSize: process.env.VUE_APP_PAGE_SIZE,
            page: 0,
        },
    },

    getters: {
        rankings: (state) => state.rankings,
        loadingRanking: (state) => state.loadingRanking,
        loadingError: (state) => state.loadingError,
        options: (state) => state.options,
    },

    mutations: {
        setRankings(state, data) {
            state.rankings = data;
        },

        startLoadingRankings(state) {
            state.loadingRanking = true;
        },

        stopLoadingRankings(state) {
            state.loadingRanking = false;
        },

        setLoadingError(state, error) {
            state.loadingError = error;
        },

        clearLoadingError(state) {
            state.loadingError = null;
        },
    },

    actions: {
        async find(context, options = {}) {
            context.commit('startLoadingRankings');
            let params = buildParams(options);
            try {
                var result = await axios.get(`${process.env.VUE_APP_API_URL}/ranking/current/doubles`, { params });
                context.commit('setRankings', result.data);
                context.commit('stopLoadingRankings');
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('setRankings', []);
                context.commit('stopLoadingRankings');
                context.commit('setLoadingError', error);
                return Promise.reject(error);
            }
        },
        async create(context, data) {
            context.commit('startLoadingRankings');
            try {
                await axios.post(`${process.env.VUE_APP_API_URL}/ranking/current`, data.data);
                context.commit('stopLoadingRankings');
                context.commit('setLoadingError', null);
                return Promise.resolve();
            } catch (error) {
                context.commit('stopLoadingRankings');
                context.commit('setLoadingError', error);
                return Promise.reject(error);
            }
        },
        async update(context, data) {
            context.commit('startLoadingRankings');
            try {
                await axios.put(`${process.env.VUE_APP_API_URL}/ranking/current`, data.data);
                context.commit('stopLoadingRankings');
                context.commit('setLoadingError', null);
                return Promise.resolve();
            } catch (error) {
                context.commit('stopLoadingRankings');
                context.commit('setLoadingError', error);
                return Promise.reject(error);
            }
        },
        async delete(context, data) {
            context.commit('startLoadingRankings');
            try {
                let playerId = data.data.playerId;
                await axios.delete(`${process.env.VUE_APP_API_URL}/ranking/current/${playerId}`);
                context.commit('stopLoadingRankings');
                context.commit('setLoadingError', null);
                return Promise.resolve(true);
            } catch (error) {
                context.commit('stopLoadingRankings');
                context.commit('setLoadingError', error);
                return Promise.reject(error);
            }
        },
        async exportCsv(context, options = {}) {
            context.commit('startLoadingRankings');
            try {
                const result = await axios.get(`${process.env.VUE_APP_API_URL}/ranking/current/doubles/export/csv`, {
                    params: {
                        queryString: options.queryString,
                        sortBy: options.sortBy,
                        sortDir: options.sortDir,
                    },
                });
                context.commit('stopLoadingRankings');
                context.commit('setLoadingError', null);
                return Promise.resolve(result.data);
            } catch (error) {
                context.commit('stopLoadingRankings');
                return Promise.reject(error);
            }
        },
    },
};
