var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('ErrorDisplay',{attrs:{"requestError":this.loadingErrorShadowDoublesRanking},on:{"closed":_vm.clearError}}),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loadingShadowDoublesRanking),expression:"loadingShadowDoublesRanking"}]},[_c('DataTable',{attrs:{"autoSort":false,"fields":[
                'rankDoubles',
                'priorRankDoubles',
                'playerId',
                'fullName',
                'nationality',
                    'playerBirthDate',
                'pointsDoubles',
                'tournamentCountDoubles',
                'nextOnDoubles',
                'nextOffDoubles',
                'points11ThDoubles',
                'points12ThDoubles',
                'highestDoublesRank',
                'highestDoublesRankDate',
            ],"items":_vm.shadowDoublesRankings.data,"schema":_vm.schema},on:{"header-clicked":_vm.setSort}}),(_vm.shadowDoublesRankings.data)?_c('Paginator',{attrs:{"data":_vm.shadowDoublesRankings,"elementsPerPage":_vm.options.pageSize},on:{"prev-page":_vm.prevPage,"next-page":_vm.nextPage,"set-page-size":_vm.setPageSize}}):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }